import RAS from './views/RAS'
import RegionalAdvisory from './views/RegionalAdvisory.vue'
import AboutALB from '../leaderboard/views/AboutPage'
import FAQALB from '../leaderboard/views/FAQALB'
import GettingStartedALB from '../leaderboard/views/GettingStartedPage'
import HowTo from '../leaderboard/views/HowToPage'

export const RASRoutes = [
  {
    path: '/ras',
    name: 'Regional Advisory Service',
    component: RAS,
    meta: {
      requiresValidTrialUser: true
    }
  },
  {
    path: '/ras/history/:id',
    name: 'Advisory History',
    component: RegionalAdvisory,
    props: true,
    meta: {
      requiresValidTrialUser: true
    }
  },
  {
    path: '/about/alb',
    name: 'About ALB',
    component: AboutALB,
  },
  {
    path: '/faq/alb',
    name: 'FAQ - ALB',
    component: FAQALB,
  },
  {
    path: '/howto/alb',
    name: 'How To - ALB',
    component: HowTo,
  },
  {
    path: '/gettingstarted/alb',
    name: 'Getting Started - ALB',
    component: GettingStartedALB,
  },
]