<template>
  <v-container>
    <v-data-table
      class="py-4"
      :headers="headers"
      hide-default-footer
      :items="features"
    >
      <template v-slot:top>
        <span class="text-h5">Ice Time Features by License Type</span>
      </template>
      <template v-slot:header.free="{ header }">
        {{ header.text }} <span class="gray--text">*</span>
      </template>
      <template v-slot:header.pro="{ header }">
        {{ header.text }}<v-icon class="ml-2 mb-1" color="teal">mdi-shimmer</v-icon>
      </template>
      <template v-slot:item.free="{ item }">
        <v-icon v-if="item.free == true || item.free == false" :color="item.free ? '':'grey'">
          {{ item.free ? 'mdi-check':'mdi-circle-small' }}</v-icon>
        <span v-else>{{ item.free }}</span>
      </template>
      <template v-slot:item.pro="{ item }">
        <v-icon v-if="item.pro == true || item.pro == false" :color="item.pro ? 'teal':'grey'">
          {{ item.pro ? 'mdi-check':'mdi-circle-small' }}</v-icon>
        <span v-else>{{ item.pro }}</span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { align: 'left', text: '',  sortable: false, value: 'name', width: '34%' },
        { align: 'center', class: 'text-h6', text: 'Free License', sortable: false, value: 'free', width: '33%' },
        { align: 'center', class: 'text-h6', text: 'Pro License', sortable: false, value: 'pro', width: '33%' },
      ],
      features: [
        // {
        //   description: '',
        //   free: true,
        //   name: '',
        //   pro: false
        // },
        {
          description: 'pls',
          free: true,
          name: 'Regional Advisory Service',
          pro: true
        },
        {
          description: '',
          free: true,
          name: 'Access Point Conditions',
          pro: true
        },
        {
          description: '',
          free: true,
          name: 'Catch Statistics',
          pro: true
        },
        {
          description: '',
          free: true,
          name: 'Ice Time Academy',
          pro: true
        },
        {
          description: '',
          free: '$ per Map',
          name: 'Radar Imagery',
          pro: '$$ per Region'
        },
        {
          description: '',
          free: '$ per Competition',
          name: 'Basic Angler Competitions',
          pro: true
        },
        {
          description: '',
          free: false,
          name: 'Pro Angler Competitions',
          pro: true
        },
        {
          description: '',
          free: false,
          name: 'Geoposts',
          pro: true
        },
        {
          description: '',
          free: false,
          name: 'Time Series',
          pro: true
        },
        {
          description: '',
          free: false,
          name: 'Ice Thickness Estimation',
          pro: true
        },
        {
          description: '',
          free: false,
          name: 'MyBuddy',
          pro: true
        },
        {
          description: '',
          free: false,
          name: 'Ice Decay Mapping',
          pro: true
        },
      ],
    }
  }
}
</script>
