import Power from './Power'
import Countdowns from './views/PowerCountdowns'
import FastFacts from './views/PowerFastFacts'
import Stories from './views/PowerStories'
import YLRN from './views/PowerYLRN'

export const PowerRoutes = [
  {
    path: '/power',
    name: 'Power',
    component: Power,
    meta: {
      requiresPower: true
    },
    children: [
      {
        path: 'countdowns',
        name: 'Power Countdowns',
        component: Countdowns
      },
      {
        path: 'fastfacts',
        name: 'Fast Facts',
        component: FastFacts
      },
      {
        path: 'stories',
        name: 'Power Stories',
        component: Stories
      },
      {
        path: 'ylrn',
        name: 'Your Lake Right Now',
        component: YLRN
      }
    ]
  }
]