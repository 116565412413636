<template>
  <v-container>
    <v-layout row>
      <v-flex xs12 sm4 offset-sm4>
        <v-card class="pa-4 ma-5">
          <v-card-text>
            <v-card-title>
              <v-img alt="App logo" src="img/icetime_logo_transparent.png"></v-img>
            </v-card-title>
            <v-container>
              <form @submit.prevent="login">
                <v-layout row>
                  <v-flex xs12>
                    <v-text-field ref="email" name="email" label="Email" id="email" v-model="email" type="email" required></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs12>
                    <v-text-field 
                      name="password" 
                      label="Password" 
                      id="password" 
                      v-model="password" 
                      :type="show_pass ? 'text' : 'password'"
                      :append-icon="show_pass ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show_pass = !show_pass" 
                      required>
                    </v-text-field>    
                    <v-card-text class="pa-1">
                      <router-link 
                        to="/forgotpassword"
                        class="text-caption text-decoration-none text-blue">
                          Forgot your password?                        
                      </router-link>    
                    </v-card-text>                    
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs12>
                    <v-btn block type="submit">Login</v-btn>
                  </v-flex>
                </v-layout>
                <v-alert v-if="infoMessage !== ''" type="info" class="mt-8">
                  {{ infoMessage }}
                </v-alert>
              </form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 offset-sm3 pa-6 text-center>
        Need an account? <router-link to="/signup">Sign-up</router-link> instead.
        <br />Can't log in? <a href="mailto:hello@icetime.app">Contact us</a>.
      </v-flex>
      <Footer />
    </v-layout>
  </v-container>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import Footer from '../components/Footer.vue'

export default {
  data() {
    return {
      email: '',
      infoMessage: '',
      password: '',
      show_pass: false,
    }
  },
  computed: {
    ...mapState('map', {
      maps: state => state.maps
    })
  },
  created: function () {
    this.$nextTick(() => this.$refs.email.focus())
  },
  methods: {
    login() {
      this.errorMessage = ''
      let data = {
        email: this.email,
        password: this.password
      }
      this.$store.dispatch('user/login', data)
      .then(()=> {
        this.$store.dispatch('map/getMaps')
        .then(() => {
          // if(this.maps.length === 0) {
          //   this.$router.push('/purchase')
          // } else {
            this.$router.push('/')
          // }
        })
      })
      .then(() => this.$store.dispatch('user/subscribe'))
      .catch(err => this.infoMessage = err.response.data)
    }
  },
  components: {
    Footer
  }
}
</script>
