<template>
    <v-container class="ma-0 pa-0 mb-10">
        <v-card class="blue lighten-5 pb-3 mb-16">
            <v-card-title>Completed Competition Information</v-card-title>
            <v-card
                :class="set_padding_margins"
            >
            <v-tabs
                fixed-tabs
                bg-color="primary"
                class="mb-5"
            >
                <v-tab v-if="!$vuetify.breakpoint.xs">Summary</v-tab>
                <v-tab v-else><v-icon>mdi-information-box</v-icon></v-tab>
                <v-tab v-if="!$vuetify.breakpoint.xs" @click="changePage('finishedroster')">Roster</v-tab>
                <v-tab v-else @click="changePage('finishedroster')"><v-icon>mdi-list-box</v-icon></v-tab>
                <v-tab v-if="!$vuetify.breakpoint.xs" @click="changePage('finishedleaderboard')">Leaderboard</v-tab>
                <v-tab v-else @click="changePage('finishedleaderboard')"><v-icon>mdi-trophy</v-icon></v-tab>
            </v-tabs>   
            <v-row :no-gutters="set_gutters">
                <v-col cols="14">
                    <v-card-text class="py-0">
                        <div>Competition Name</div>
                        <p :class="font_size_value">{{convertSpecial(competition.name)}}</p>                        
                    </v-card-text>                    
                </v-col>               
            </v-row> 
            <v-row :no-gutters="set_gutters">
                <v-col cols="14">
                    <v-card-text class="py-0">
                        <div>Location</div>
                        <p :class="font_size_value">{{competition.location}}</p>                        
                    </v-card-text>                    
                </v-col>
                <v-col cols="14" sm="7">
                    <v-card-text class="py-0">
                        <div>Hosted By</div>
                        <p :class="font_size_value">{{competition.host}}</p>                        
                    </v-card-text>   
                </v-col>
            </v-row>     
            <v-row :no-gutters="set_gutters">
                <v-col cols="14">
                    <v-card-text class="py-0">
                        <div>Started</div>
                        <p :class="font_size_value">{{ formatDates(fixed_start_date) }}</p>                        
                    </v-card-text>                    
                </v-col>
                <v-col cols="14" sm="7">
                    <v-card-text class="py-0">
                        <div>Ended</div>
                        <p :class="font_size_value">{{ formatDates(fixed_end_date) }}</p>                        
                    </v-card-text>   
                </v-col>
            </v-row>     
            <v-row :no-gutters="set_gutters">
                <v-col cols="14">
                    <v-card-text class="py-0">
                        <div>Buy In</div>
                        <p :class="font_size_value">${{competition.buy_in}}</p>                        
                    </v-card-text>                    
                </v-col>
                <!-- <v-col sm="7">
                    <v-card-text class="py-0">
                        <div>Total Approximate Payout</div>
                        <p :class="font_size_value">${{competition.prize_pool}}</p>                        
                    </v-card-text>   
                </v-col> -->
            </v-row>  
            <v-row :no-gutters="set_gutters">
                <v-col cols="14">
                    <v-card-text class="py-0">
                        <div>Scoring Criteria</div>
                        <p :class="font_size_value">{{scoreBy(competition.score_type)}}</p>                        
                    </v-card-text>                    
                </v-col>
                <v-col sm="7">
                    <v-card-text class="py-0">
                        <div>Catch Photos</div>
                        <p :class="font_size_value">{{ require_photo ? "Required" : "Optional" }}</p>                        
                    </v-card-text>   
                </v-col>                    
            </v-row>   
            <v-row :no-gutters="set_gutters">
                <v-col cols="14">
                    <v-card-text class="py-0">
                        <div>Maximum Team Members</div>
                        <p :class="font_size_value">{{competition.team_size}}</p>                        
                    </v-card-text>                    
                </v-col>
                <v-col sm="7">
                    <v-card-text class="py-0">
                        <div>Maximum Competing Teams</div>
                        <p :class="font_size_value">{{competition.team_limit}}</p>                        
                    </v-card-text>   
                </v-col>
            </v-row>
            <v-divider class="mx-2"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="!expanded" icon @click="expand"><v-icon>mdi-arrow-expand</v-icon></v-btn>
                <v-btn v-else icon @click="expand"><v-icon>mdi-arrow-collapse</v-icon></v-btn>
            </v-card-actions>

            <v-expansion-panels 
                v-model="panel" 
                focusable 
                flat 
                multiple
            >
                <v-expansion-panel>
                    <v-expansion-panel-header><h3>Monetary</h3></v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container v-if="this.comp_prizes === null" class="px-0 py-4">
                            <v-row :no-gutters="set_gutters">
                                <v-col cols="14">
                                    <v-card-text class="py-0">No individual prizes have been set</v-card-text> 
                                </v-col>
                            </v-row>    
                        </v-container>   
                        <v-container
                                v-else
                                class="pa-0"
                                v-for="(item,k) in this.comp_prizes"
                                :key="k"
                            >                             
                                <v-row no-gutters>
                                    <v-col cols="6" sm="3">
                                        <v-card-text class="py-0">
                                            <div>Place</div>
                                            <p :class="font_size_value">{{ item.place }}</p>                        
                                        </v-card-text>                    
                                    </v-col>
                                    <v-col cols="6" sm="3">
                                        <v-card-text class="py-0">
                                            <div>Value</div>
                                            <p :class="font_size_value">{{ item.value }}{{ item.type==1 ? "$" : "%"}}</p>                        
                                        </v-card-text>   
                                    </v-col>
                                    <v-col cols="6" sm="3">
                                        <v-card-text class="py-0">
                                            <div>Description</div>
                                            <p :class="font_size_value">{{ item.text }}</p>                        
                                        </v-card-text>                    
                                    </v-col>                                                                      
                                </v-row>
                                <v-divider v-if="k != comp_prizes.length-1" class="mx-2"></v-divider>     
                            </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header><h3>Criteria</h3></v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container v-if="this.comp_species === null" class="px-0 py-4">
                            <v-row :no-gutters="set_gutters">
                                <v-col cols="14">
                                    <v-card-text v-if="competition.rules === ''" class="py-0">No criteria has been set</v-card-text> 
                                    <v-card-text v-else class="py-0">{{ competition.rules }}</v-card-text>                    
                                </v-col>
                            </v-row>    
                        </v-container>   

                        <div v-else>
                            <v-card-title><h5>Species Allowed</h5></v-card-title>   
                            <v-container
                                class="pa-0"
                                v-for="(item,k) in this.comp_species"
                                :key="k"
                            >
                                <v-card-title class="capitalize">{{ item.common_name }}</v-card-title>                                    
                                <v-row no-gutters>
                                    <v-col cols="6" sm="3">
                                        <v-card-text class="py-0">
                                            <div>Minimum Length</div>
                                            <p :class="font_size_value">{{ item.lower_slot }}</p>                        
                                        </v-card-text>                    
                                    </v-col>
                                    <v-col cols="6" sm="3">
                                        <v-card-text class="py-0">
                                            <div>Maximum Length</div>
                                            <p :class="font_size_value">{{ item.upper_slot }}</p>                        
                                        </v-card-text>   
                                    </v-col>
                                    <v-col cols="6" sm="3">
                                        <v-card-text class="py-0">
                                            <div>Minimum Weight</div>
                                            <p :class="font_size_value">{{ item.lower_weight }}</p>                        
                                        </v-card-text>                    
                                    </v-col>
                                    <v-col cols="6" sm="3">
                                        <v-card-text class="py-0">
                                            <div>Maximum Weight</div>
                                            <p :class="font_size_value">{{ item.upper_weight }}</p>                        
                                        </v-card-text>   
                                    </v-col>                                                                                            
                                </v-row>
                                <v-row no-gutters>
                                    <v-col>
                                        <v-card-text class="py-0">
                                            <div>Score By</div>
                                            <p v-if="item.score != ''"  :class="font_size_value">{{ item.score }}</p>     
                                            <p v-else :class="font_size_value">-</p>                        
                                        </v-card-text>        
                                    </v-col>
                                </v-row>
                                <v-divider v-if="k != comp_species.length-1" class="mx-2"></v-divider>     
                            </v-container> 
                        </div>
                                
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header><h3>Rules</h3></v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container class="px-0 py-4">
                            <v-row :no-gutters="set_gutters">
                                <v-col cols="14">
                                    <v-card-text v-if="competition.rules === ''" class="py-0">No rules have been set</v-card-text> 
                                    <v-card-text v-else class="py-0">{{ competition.rules }}</v-card-text>                    
                                </v-col>
                            </v-row>    
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header><h3>Side Pot</h3></v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container v-if="this.comp_sidepot === null" class="px-0 py-4">
                            <v-row :no-gutters="set_gutters">
                                <v-col cols="14">
                                    <v-card-text v-if="competition.rules === ''" class="py-0">No side pot has been set</v-card-text> 
                                    <v-card-text v-else class="py-0">{{ competition.rules }}</v-card-text>                    
                                </v-col>
                            </v-row>    
                        </v-container>                        
                        <v-container v-else v-for="(item,k) in this.comp_sidepot"
                            :key="k"
                            class="px-0 py-4"
                        >
                            <v-row :no-gutters="set_gutters">
                                <v-col cols="14">
                                    <v-card-text class="py-0">
                                        <div>Side Pot Scoring:</div>
                                        <p :class="font_size_value">{{ scoreBy(item.score) }}</p>                        
                                    </v-card-text>                    
                                </v-col>
                                <v-col sm="7">
                                    <v-card-text class="py-0">
                                        <div>Side Pot Payout:</div>
                                        <p :class="font_size_value">${{ item.payout }}</p>                        
                                    </v-card-text>   
                                </v-col>
                            </v-row>  
                        </v-container>                            
                    </v-expansion-panel-content>
                </v-expansion-panel>   
            </v-expansion-panels>
            </v-card>
        </v-card>
    </v-container>

</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('leaderboard', {
            competition: state => state.competition,
            teams: state => state.teams,
            team_members: state => state.team_members,
            comp_species: state => state.comp_species,
            comp_blackout: state => state.comp_blackout,
            comp_sidepot: state => state.comp_sidepot,
            comp_prizes: state => state.comp_prizes,
            joined_list: state => state.joined_list,
        }),
        ...mapState('user', {
            user: state => state.user,
        }),
        // ...mapState('spot_mapper', {
        //     joined_comps: state => state.joined_comps
        // }),
        font_size_title(){
            return !this.$vuetify.breakpoint.xs ? "text-h4 mb-0" : "text-h6 mb-0"
        },       
        font_size_value(){
            return !this.$vuetify.breakpoint.xs ? "text-h5" : "text-h6"
        },    
        set_gutters(){
            return !this.$vuetify.breakpoint.xs ? false : true
        },     
        set_padding_margins(){
            return !this.$vuetify.breakpoint.xs ? "px-4 py-2 ma-3" : "ma-3"
        },   
    },
    data() {
        return {
            comp: '',
            loading: false,
            join_team: true,
            selected_team: '',
            team_error: false,
            rules_error: false,
            team_name: null,
            name_error:false,
            not_joined: true,
            panel:[],
            expanded: false,
            panels: 4,
            create_class: "pt-3 black--text",
            join_class: "pt-3 grey--text",
            success: false,
            err_message: null,
            team_owner: false,
            team_member_list: "",
            fixed_end_date: null,
            fixed_start_date: null,
            require_photo:false,
            team: null,
        }
    },
    methods: {
        // **TODO must limit joining a team or creating a team based off of team amount/people
        joinComp(){
            // console.log(this.teams)
            // console.log(this.competition)
            // console.log(this.comp_species)
            this.team_error = false
            this.loading = true
            if(!this.join_team){
                if(this.team_name !== null){
                    let data = {
                        name: this.team_name,
                        competition: this.competition.id,
                        owner: this.user.alias,
                        status: "1"
                    }
                    // console.log(data)
                    this.$store.dispatch('leaderboard/joinComp', data)
                    .then(() => {
    
                    })
                    .catch((err) => {
                        this.err_message = 'error: ' + err
                        this.team_error = true
                    })
                    .finally(() => {
                        this.loading = false
                        if(!this.team_error){
                            this.team_owner = true
                            this.$store.dispatch('leaderboard/getTeamMembers')
                        }
                    })
                }else{this.name_error = true}
            }
            else{
                if(this.selected_team !== null){
                    let data = {
                        // competition: this.competition.id,
                        team: this.selected_team,
                        status: 1,
                    }
                    // console.log(data)
                    this.$store.dispatch('leaderboard/joinTeam', data)
                    .then(() => {

                    })
                    .catch((err) => {
                        this.err_message = 'Retry: ' + err
                        this.team_error = true
                    })
                    .finally(() => {
                        this.loading = false
                        // console.log(data)
                        this.$store.dispatch('leaderboard/getTeamMembers')
                    })
                }
            }
            if(!this.team_error){
                this.success = true
                this.not_joined = false
            }
            this.loading = false
        },
        changePage(page){
            this.$router.push(("/app/leaderboard/" + page + "/" + this.$route.params.id))
            // this.$store.page = page
            // console.log(this.$store.page)
            // this.$emit('change-page')
            // vm.$forceUpdate()
        },
        expand(){
            if(!this.expanded){
                this.panel = [...Array(this.panels).keys()].map((k,i) => i)
            }else{
                this.panel = []
            }
            // console.log(this.panel)
            this.expanded = !this.expanded
        },
        convertSpecial(text){  
            text = String(text).replace("&lt;" , "<")
            text = String(text).replace("&gt;" , ">")
            text = String(text).replace("&quot;" , "\"")
            text = String(text).replace("&#039;" , "'")
            text = String(text).replace("&amp;" , "&")
            // return htmlStr
            // console.log(text)
            return text
        },
        editComp(){
            // console.log(this.competition)
            this.$store.page = 'edit_comp'
            this.$emit('change-page')
        },
        editTeams(){
            // console.log(this.competition)
            this.$store.page = 'edit_teams'
            this.$emit('change-page')
        },
        formatDates(date){
            let temp = date + ' UTC'
            let value = new Date(temp)
            
            // console.log(date)
            // console.log(value.toString())
            return (value.toLocaleString())
        },
        withdraw(team_id){
            let no_error = true
            // make button be loading when pressed? and maybe disable other button? this.loading = true

            // Should maybe have a message or something if withdrawing as the last member of a team or as the team leader?
            let data = {
                team_id: team_id,
                comp_id: this.competition.id
            }
            this.$store.dispatch('leaderboard/compWithdraw', data)
            .then(() => {

            })
            .catch((err) => {
                // Should make error msg
                // this.team_error = false
                this.err_message = 'Retry: ' + err
                no_error = false
            })
            .finally(() => {
                // this.loading = false
                if(no_error){
                    this.not_joined = true
                    this.team_onwer = false
                    this.success = false
                }
            })
            // console.log('withdraw from comp: ' + this.competition.id + ', Team ID: ' + team_id)
        },
        forfeit(team_id){
            let no_error = true
            // make button be loading when pressed? and maybe disable other button? this.loading = true

            // Should maybe need to double confirm when forfeiting and team contains members other than yourself(owner)?
                // not_joined: true,
                // team_owner: false,
            let data = {
                team_id: team_id,
                comp_id: this.competition.id
            }
            this.$store.dispatch('leaderboard/compForfeit', data)
            .then(() => {

            })
            .catch((err) => {
                // Should make error msg
                // this.team_error = false
                this.err_message = 'Retry: ' + err
                no_error = false
            })
            .finally(() => {
                // this.loading = false
                if(no_error){
                    this.$store.dispatch('leaderboard/getTeams')
                    this.not_joined = true
                    this.team_onwer = false
                    this.success = false
                }
            })
            // console.log('Forfeit for team: ' + team_id)
        },
        scoreBy(score_type){
            let temp = ""
            switch(score_type){
                case '1':
                    return "Total Length";
                case '2':
                    return "Total Weight";
                case '3':
                    return "Total Fish Caught";
                case '6':
                    return "Longest Fish";
                case '7':
                    return "Heaviest Fish";
                case '4':
                    temp = this.competition.amount + " Longest Fish"
                    return temp;
                case '5':
                    temp = this.competition.amount + " Heaviest Fish"
                    return temp;
            }
        },
    },
    mounted(){
        window.scrollTo(0,0);
        let id = this.$route.params.id
        this.$store.selected_competition = id
        this.$store.dispatch('leaderboard/getCompetition')
        .then(() => {
            setTimeout(() => {
                // console.log(this.competition.end_date)
                this.fixed_end_date = this.competition.end_date.substring(0,16)
                this.fixed_start_date = this.competition.start_date.substring(0,16)            
            }, 50) 

            this.$store.dispatch('leaderboard/getTeams')
            this.$store.dispatch('spot_mapper/getJoinedComps')
            .then(() => {
                if(this.competition.require_photo === '1'){
                    this.require_photo = true
                }
                // console.log(this.require_photo)
                // console.log("Joined Comps: " + JSON.stringify(this.joined_list))
                // console.log(this.$store.selected_competition)
                this.joined_list.forEach(item => {
                    if(item.id == this.$store.selected_competition){
                        this.not_joined = false
                        
                        // this.team = item.name
                        // console.log(item)
                    }
                })
                // console.log(this.not_joined)
            })
            .finally(() => {
                // If you have joined
                if(!this.not_joined){
                    // Get Team info and find team leader
                    this.$store.dispatch('leaderboard/getTeamMembers')
                    .then(() => {
                        // console.log(this.team_members)
                        for(let i=0; i<this.team_members.length ; i++){
                            // console.log(this.team_members[i].alias)
                            if(this.team_members[i].owner == this.user.id){
                                this.team_owner = true  
                            }
                            if(i === 0){
                                this.team_member_list = this.team_members[i].alias
                                this.team = this.team_members[i].name
                                
                            }else{
                                this.team_member_list +=", " + this.team_members[i].alias
                            }
                        }
                        // this.team_members.forEach(item => {
                        //     if(item.owner == this.user.id){
                        //         this.team_owner = true
                                
                        //     }
                        // //  console.log(this.team_members.length)
                        // })
                        // console.log(this.team_member_list)
                    })
                }
            })
            this.comp = this.$store.competition
        })
    }
}
</script>

<style>
.capitalize {
    text-transform: capitalize;
}
</style>