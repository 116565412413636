<template>
    <v-container v-if="isLoggedIn">
        <TopNav @change-page="changePage" :selectedPage="selected_page"/>
        <!-- <AddComp></AddComp>
        <Comp-list></Comp-list> -->
        <AddComp v-if="showAddComp" @change-page="changePage"></AddComp>
        <Comp-list v-if="showCompList" @change-page="changePage"></Comp-list>
        <ShowCompetition v-if="showSelectedComp" @change-page="changePage"></ShowCompetition>
        <Showleaderboard v-if="showSelectedLeaderboard" @change-page="changePage"></Showleaderboard>
        <RealTimeLeaderboard v-if="showRealTimeLeaderboard" @change-page="changePage"></RealTimeleaderboard>
        <!-- <UploadTest v-if="showUploadTest" @change-page="changePage"></UploadTest> -->
        <EditComp v-if="showEditComp" @change-page="changePage"></EditComp>
        <EditTeamsAdmin v-if="showEditTeams" @change-page="changePage"></EditTeamsAdmin>
        <CompRoster v-if="showCompRoster" @change-page="changePage"></CompRoster>
        <EndedCompList v-if="showEndedComps" @change-page="changePage"></EndedCompList>
        <FinishedComp v-if="showFinishedComp" @change-page="changePage"></FinishedComp>
        <FinishedRoster v-if="showFinishedRoster" @change-page="changePage"></FinishedRoster>
        <FinishedLeaderboard v-if="showFinishedLeaderboard" @change-page="changePage"></FinishedLeaderboard>
        <PersonalLeaderboard v-if="showPersonalLeaderboard" @change-page="changePage"></PersonalLeaderboard>
        <AdminLeaderboard v-if="showAdminLeaderboard" @change-page="changePage"></AdminLeaderboard>
    </v-container>
</template>

<script>
// import
import { mapGetters, mapState } from 'vuex'
import AddComp from '../components/AddComp';
import TopNav from '../components/TopNav.vue';
import CompList from '../components/CompList.vue';
import ShowCompetition from '../components/ShowCompetition.vue';
import Showleaderboard from '../components/ShowLeaderboard.vue';
import RealTimeLeaderboard from '../components/RealTimeLeaderboard.vue'
import EditComp from '../components/EditComp.vue';
import EditTeamsAdmin from '../components/EditTeamsAdmin.vue';
import CompRoster from '../components/CompRoster.vue';
import EndedCompList from '../components/EndedCompList.vue';
import FinishedComp from '../components/FinishedComp.vue';
import FinishedRoster from '../components/FinishedRoster.vue';
import FinishedLeaderboard from '../components/FinishedLeaderboard.vue';
import PersonalLeaderboard from '../components/PersonalLeaderboard.vue';
import AdminLeaderboard from '../components/AdminLeaderboard.vue';
// import UploadTest from '../components/UploadTest.vue'

export default{
    components: {
    AddComp,
    TopNav,
    CompList,
    ShowCompetition,
    Showleaderboard,
    RealTimeLeaderboard,
    EditComp,
    EditTeamsAdmin,
    CompRoster,
    EndedCompList,
    FinishedComp,
    FinishedRoster,
    FinishedLeaderboard,
    PersonalLeaderboard,
    AdminLeaderboard,
},
computed: {
    ...mapGetters('user', {
      isLoggedIn: 'isLoggedIn',
    }),    
    ...mapState('leaderboard', {
        page: state => state.page,
    }),
},
data() {
    return{
        showAddComp: false,
        showCompList: false,
        showSelectedComp: false,
        showSelectedLeaderboard: false,
        showRealTimeLeaderboard: true,
        showEditComp: false,
        showEditTeams: false,
        showCompRoster: false,
        showEndedComps: false,
        showFinishedComp: false,
        showFinishedRoster: false,
        showFinishedLeaderboard: false,
        showPersonalLeaderboard: false,
        showAdminLeaderboard: false,
        // showuploadTest: false,
        selected_page:'',
    }

},
methods: {
    // pageSelected(page){
    //     console.log(this.page)
    //     if(page == this.$store.page){
    //         return true
    //     }
    // },
    clearShow(){
        this.showAddComp = false
        this.showCompList = false
        this.showSelectedComp = false
        this.showSelectedLeaderboard = false
        this.showRealTimeLeaderboard = false
        this.showEditComp = false
        this.showEditTeams = false
        this.showCompRoster = false
        this.showEndedComps = false
        this.showFinishedComp = false
        this.showFinishedRoster = false
        this.showFinishedLeaderboard = false
        this.showPersonalLeaderboard = false
        this.showAdminLeaderboard = false
        // this.showUploadTest = false
    },
    changePage(){
        // console.log(this.$store.page)
        this.clearShow()
        switch(this.$store.page){
            case 'join_list':
                this.showCompList = true
                this.selected_page = 'join_list'
                break;
            case 'create_comp':
                this.showAddComp = true
                this.selected_page = 'create_comp'
                break;
            case 'selected_comp':
                this.showSelectedComp = true
                break;
            case 'selected_leaderboard':
                this.showSelectedLeaderboard = true
                break;
            case 'real_time_leaderboard':
                this.showRealTimeLeaderboard = true
                this.selected_page = 'real_time_leaderboard'
                break
            case 'edit_comp':
                this.showEditComp = true
                break
            case 'edit_teams':
                this.showEditTeams = true
                break
            case 'comp_roster':
                this.showCompRoster = true
                break
            case 'finished_comp':
                this.showFinishedComp = true
                break
            case 'finished_roster':
                this.showFinishedRoster = true
                break
            case 'finished_leaderboard':
                this.showFinishedLeaderboard = true
                break
            case 'personal_leaderboard':
                this.showPersonalLeaderboard = true
                this.selected_page = 'personal_leaderboard'
                break
            case 'ended_comps':
                this.showEndedComps = true
                this.selected_page = 'ended_comps'
                break
            case 'admin_leaderboard':
                this.showAdminLeaderboard = true
                this.selected_page = 'admin_leaderboard'
                break
            // case 'upload_test':
            //     this.showUploadTest = true
            //     break            
        }
        // this.$forceUpdate();
    },
},
mounted() {
    // this.$store.dispatch('leaderboard/setPage', 'join_list')
    this.$store.dispatch('setMode', 'app')
    this.$store.dispatch('setTab', 'leaderboard')
    this.$store.dispatch('checkToken')
    // TODO: add check for subscriber status
}
}

</script>