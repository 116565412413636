<template>
    <v-card class="ma-4 pa-2">
        <v-card-title>
            Introduction
        </v-card-title>
        <v-card-text v-if="currentPage == 0">
            The Ice Time Academy helps you understand lake ice and how to monitor it using satellite radar images. We start with a short read about satellite radar. 
            Next, we shift into multiple choice questions that will challenge your knowledge about lake ice and in the process illustrate how ice forms and how ice 
            deformations can create hazards. After each section is complete, submit your work and check your score. Review all answers as this is how to maximize your 
            learning, even for the intuitively simple questions. 
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="nextSection">Next Section</v-btn>
        </v-card-actions>
    </v-card>
</template>
    
<script>
import goTo from 'vuetify/lib/services/goto'

export default { 
    data() {
        return {
            currentPage: 0,
            totalPages:3,
        }
    },
    computed: {
    },
    methods: {
        nextSection() {
            this.$emit('next-section')
            goTo(0)
        },
    },
}
</script>

<style>
</style>