<template>
    <v-container>
        <h1 class="text-center">About Anglers Leaderboard</h1>
        <p class="mb-10">Do you angle twice a month or more? If so, you are a strongly interested or avid angler. Congratulations your interests and skills align well with the Anglers Leaderboard&#8482; community!</p>
        <h2>Beta Tester Notice:</h2>
        <p class="mb-10">For question, troubleshooting, or issue reports please contact: <a href= "mailto: cole@nextgenenvironment.ca ?cc=info@nextgenenvironment.ca" >cole@nextgenenvironment.ca and info@nextgenenvironment.ca</a></p>
        <h3>What is ALB?</h3>
        <p class="mb-10">ALB is a real-time angling platform that enables anyone with an internet connection to compete and have fun doing it. You can fish as you always have, you can host small competitions with friends, family, co-workers or participate in tournaments. All three types of angling generate your ALB fish catch statistics. Any one of them or all of them together can put you in the finals. You decide!</p>
        <h3>Who is ALB for?</h3>
        <p class="mb-10">It is for all people that love fishing and those that might think they’re good at it! ALB is the first application that levels the playing field. You don’t have to have an expensive boat and 10 years of tournament experience to win ALB. When we say every catch counts, we mean it! You can be a riverbank or shoreline angler, fish out of a kayak, or your own boat. ALB rewards you for fishing for a diversity of species. ALB is a platform that gives every angler a chance to win, a chance to improve and grow, and to be a member of an on-line and real-time community that looks at angling in a whole new way.</p>
        <h3>How does ALB work?</h3>
        <p>We have developed patent pending technology that simplifies recording of your catches. With this in hand, competitions can be, but are not required to be, event-based anymore. By easily scoring your day to day catches we think ALB is the only competition that can truly show who are the top anglers in your region. We all know that 1- or 2-day tournaments involve quite a bit of chance and so cannot do that.
        </p> <p>ALB is a real-time angling app that automatically:</p>
        <p> ⦁ Maps your fishing spots using SpotMapper&#8482;</p>
        <p> ⦁ Identifies your fish species and measures your fish length using your cell phone camera and our photomeasure&#8482; board</p>
        <p class="mb-10"> ⦁ Keeps track of your catch data and pics on your Personal Leaderboard</p>
        <h3>Are you one of the best anglers in your region?
        </h3>
        <p> ⦁ ALB automatically ranks your catches against other members in your region in real-time, by day, and by year on the Regional Leaderboard. </p>
        <p> ⦁ All you have to do is sign up and take a picture of your fish on the PhotoMeasure&#8482; board.  Let’s go!</p>
        <p class="mb-10"> ⦁ Are you one of the top anglers? If so, tell your friends! Everybody can visit the ALB Regional Leaderboard to see who the top anglers are, check out your bio, your catches, and your stats for this year and previous years.  </p>
        <h3>Every catch counts! </h3>
        <p> ⦁ Your real-time ALB angling score is calculated as follows: your total fish length from all species is combined with the ALB diversity score. All the fish you enter, in or out of competitions or tournaments, count. For those that host competitions with more than 3 participants, we double your catch score earned for that event and add that to your ALB angling score.</p>
        <p> ⦁ There are two challenge periods, Round 1 - May to October and Round 2 - November to April</p>
        <p class="mb-10"> ⦁ Ice and open water angling approaches are valid in both challenge periods</p>
        <h3>The Championships</h3>
        <p>ALB offers three championship competitions in addition to your competitions and tournaments.</p>
        <v-simple-table  class="mb-10">
        <template v-slot:default>
            <thead>
                <tr>
                <th class="text-left">
                    ALB Championships
                </th>
                <th class="text-left">
                    Challenge Period
                </th>
                <th class="text-left bold">
                    Calendar Months
                </th>
                <th class="text-left">
                    Competition criteria
                </th>
                <th class="text-left">
                    Outcome
                </th>
                </tr>
            </thead>
            <tbody>
                <tr
                v-for="item in values"
                :key="item.a"
                >
                <td>{{ item.a }}</td>
                <td>{{ item.b }}</td>
                <td>{{ item.c }}</td>
                <td>{{ item.d }}</td>
                <td>{{ item.e }}</td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
        <h3>Getting to know the PhotoMeasure&#8482; board</h3>
        <p>The 36” ALB PhotoMeasure&#8482; board. No bump required. The PhotoMeasure&#8482; board floats. It is rated to -45°C (-49°F) with a large handle ready for winter gloves. The board is tough but be nice to it. When not in use store it flat. During winter store it inside and avoid prolonged exposure to sunlight.</p>
        <p>
            <v-img
                comtain
                src="/img/Photomeasure QRID splatter beta stress test.png"
            ></v-img>
        </p>
        <p>There are four types of QR codes on the ALB PhotoMeasure&#8482; board. These are the easiest ways to learn about and use ALB while angling. Point your cell phone camera app at each. </p>
        <p> ⦁ AutoSpot: most of the QR’s on the board are AutoSpot. Point your cell phone camera at this QR code after you have taken your PhotoMeasure&#8482; fish photo. Now you are on the map with just one click!</p>
        <p> ⦁ About: a quick overview of ALB. Check this out in the store, or show a friend new to ALB.</p>
        <p> ⦁ MyComps: Wondering what your rank in the comp is? Point your cell phone camera at this to take you directly to the competitions you signed up for.</p>
        <p class="mb-10"> ⦁ Regional: The path well travelled by ALB members and the public. The ranks update every time a fish is entered. Are you the top angler now, today, or this year? Use your phones camera app on the PhotoMeasure&#8482; board to go straight to see who’s on the top of the Regional Leaderboard, their stats, and catches. </p>
        <h3>Using the PhotoMeasure&#8482; board</h3>
        <p>
            <v-img
                comtain
                src="/img/about2.jpg"
            ></v-img>
        </p>
        <p>Take you’re a photo of your fish photo anywhere and at any angle on the board. Upload it. It only takes about 20 - 25 seconds!</p>
        <p>
            <v-img
                comtain
                src="/img/about3.jpg"
            ></v-img>
        </p>
        <p>This photo has seven unobstructed QR codes. This is plenty. You could snap the photo a bit closer and take the two QR’s on the left of frame out if you wanted a better picture of your fish. PhotoMeasure&#8482; works with just one QR code. We recommend at least a total of 4 unobstructed QR codes found near the head and tail.</p>
        <p>
            <v-img
                comtain
                src="/img/about4.jpg"
            ></v-img>
        </p>
        <p>The PhotoMeasure&#8482; board does not have to be neatly aligned with the orientation of the photo. It does have to be on the left to right orientation in general. A photo taken with the board vertical will be rejected. </p>
        <p>
            <v-img
                comtain
                src="/img/about5.jpg"
            ></v-img>
        </p>
        <p>You can steady the fish with your hands. The mouth and tail cannot be obstructed from camera view.</p>
        <p class="mb-10">
            <v-img
                comtain
                src="/img/about6.jpg"
            ></v-img>
        </p>
        <h3>Can you prove the PhotoMeasure&#8482; approach works?</h3>
        <p>Yes, we’ve undertaken extensive trials that includes beta testers on the water. Here’s the statistics from our trials. Our internal testing prior to beta testing is already smaller than ¼”.</p>
        <p>Length Measurement</p>
        <p> ⦁ Data to be provided by beta testers</p>
        <p>Species Detection</p>
        <p class="mb-10"> ⦁ Data to be provided by beta testers</p>
        <h3 class="text-center">It’s time to join the ALB community. Become and be recognized as a top angler.</h3>
        <h3 class="text-center">Win cash and prizes just for fishing where and how you want to!</h3>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            values: [
          {
            a: 'ALB Angler of the year',
            b: 'Annual',
            c: 'Cash and prizes awarded in November',
            d: 'Sum of all catch scores for all species and all angling days during the year.  Independent judges call a winner',
            e: 'The ALB AOY is a competition is for those that fish often. The Regional Leaderboard displays the total score for all fish caught by the user. See the current rankings in real-time. At the end of the comp (year) the finalists are selected. Any angler can win'

          },
          {
            a: 'ALB Top Ten',
            b: 'Annual',
            c: 'Cash and prizes awarded in November',
            d: 'Sum of your catch scores for the 24 best days of angling during the year (12 warm/12 cold). Final Catch Scores are based on local fish limits',
            e: 'The ALB Top Ten is for all anglers but those with consistent big fish and diverse species catches will ride high on the Regional Leaderboard. See the current rankings in real-time. At the end of the comp (year) the final top ten are selected'

          },
          {
            a: 'ALB Top 20',
            b: 'Daily',
            c: 'Scores awarded daily',
            d: 'Sum of daily Catch Scores',
            e: 'Come here daily to find out "who\'s on top?". You can markedly increase your score by  ranking in the daily top 20'

          },

        ],
        }
    },
    methods: {

    }
}
</script>