import axios from 'axios'
import { latLng, latLngBounds } from 'leaflet';
import Vue from 'vue'
import Vuex from 'vuex'

// For API dependencies
Vue.use(Vuex)
axios.defaults.baseUrl = Vue.$apiEndpointUrl

const state = {
  current_footprint: {},
  current_region: null,
  dates: [],
  hazards: [],
  hazardtypes: [],
  footprints: [],
  footprints_hint: '',
  gpsMode: 0,
  hide_buttons: false,
  isAdminHazardVisible: true,
  isIceRoadVisible: true,
  isMapVisible: true,
  isPersonalHazardVisible: true,
  isProHazardVisible: true,
  isUserHazardVisible: true,
  layers: [],
  layers_requested: false,
  legend: [],
  lockMapOn: false,
  map: null,
  maps: [],
  pinsAdmin: [],
  pinsPersonal: [],
  pinsPOI: [],
  pinsPro: [],
  pinsUser: [],
  polygons: [],
  radar_imagery_clicked: false,
  range_value: [ 1, 10 ],
  range_labels: [ 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18 ],
  showHelp: false,
  test: false,
  testLayer: null,
  thickness: false,
  thicknessLayer: null,
  user_reports: [],
  visibleLayer: {},
  selectedLayer: null,
  zoom: 10,
  zoomControl: null,

  all_classes: null,

  iceClassOptions: {   
    // maxZoom: 10,
    pane: 'iceclass',
    vectorTileLayerStyles: {
      colours: {},
      // A plain set of L.Path options.
      iceclass: function(properties) {
        var class_code = properties.value
        if (state.visibleLayer.classColours) {
          return { 
            weight: 0.25,
            color: 'black',
            fillColor: 'rgb(' + state.all_classes[class_code].red + ', ' + state.all_classes[class_code].green + ', ' + state.all_classes[class_code].blue + ')',
            fillOpacity: 100,
            fill: true
          }
        }
        else {
          return { 
            weight: 0.25,
            color: 'black',
            fillOpacity: 100,
            fill: true
          }          
        }
      }
    }, 
  } 
};

const getters = {
  areAllHazardComponentsVisible: state => {
    return state.isPersonalHazardVisible && state.isUserHazardVisible && state.isProHazardVisible && state.isAdminHazardVisible
  },
  // areIceRoadsVisible: state => {
  // },
  areAllMapComponentsVisible: state => {
    return state.isMapVisible && state.isIceRoadVisible
  },
  filteredAdminPins() {
    return state.pinsAdmin.filter(function(el) {
      // return el.layer_id < state.visibleLayer.id || el.layer_id == state.visibleLayer.id
      return el.layer_id == state.visibleLayer.id
    })
    // return state.pinsAdmin
  },
  filteredPersonalPins() {
    return state.pinsPersonal.filter(function(el) {
      // return el.layer_id < state.visibleLayer.id || el.layer_id == state.visibleLayer.id
      return el.layer_id == state.visibleLayer.id
    })
    // return state.pinsPersonal
  },
  filteredPOIPins() {
    // return state.pinsPOI.filter(function(el) {
    //   // return el.layer_id < state.visibleLayer.id || el.layer_id == state.visibleLayer.id
    //   return el.layer_id == state.visibleLayer.id
    // })
    return state.pinsPOI
  },
  filteredProPins() {
    return state.pinsPro.filter(function(el) {
      // return el.layer_id < state.visibleLayer.id || el.layer_id == state.visibleLayer.id
      return el.layer_id == state.visibleLayer.id
    })
    // return state.pinsPro
  },
  filteredUserPins() {
    return state.pinsUser.filter(function(el) {
      // return el.layer_id < state.visibleLayer.id || el.layer_id == state.visibleLayer.id
      return el.layer_id == state.visibleLayer.id
    })
    // return state.pinsUser
  },
};

const mutations = {
  getLegend(state, payload) {
    state.legend = payload
  },
  getFootprints(state, payload) {
    state.footprints = payload
  },
  getHazards(state, payload) {
    state.hazards = payload
  },
  getHazardTypes(state, payload) {
    state.hazardtypes = payload
  },
  getMaps(state, payload) {
    state.maps = payload
  },
  getUserReports(state, payload) {
    state.user_reports = payload
  },
  setDates(state, payload) {
    state.dates = payload
  },
  setLayers(state, payload) {
    state.layers = payload
  },
  setTest(state, payload) {
    state.test = payload
  },
  setTestLayer(state, payload) {
    state.testLayer = payload
  }, 
  setThickness(state, payload) {
    state.thickness = payload
  },
  setThicknessLayer(state, payload) {
    state.thicknessLayer = payload
  },  
  setCurrentFootprint(state, payload) {
    state.current_footprint = payload
  },
  setCurrentRegion(state, payload) {
    state.current_region = payload
  },
  setFootprintsHint(state, payload) {
    state.footprints_hint = payload
  },
  setGPSMode(state, payload) {
    state.gpsMode = payload
  },
  setClassColours(state, colours) {
    state.classColours = colours
  },
  setLayersRequested(state, status) {
    state.layers_requested = status
  },
  setLegend(state, payload) {
    state.legend = payload
  },
  setMap(state, payload) {
    state.map = payload
  },
  setPinsAdmin(state, payload) {
    state.pinsAdmin = payload
  },
  setPinsPersonal(state, payload) {
    state.pinsPersonal = payload
  },
  setPinsPOI(state, payload) {
    state.pinsPOI = payload
  },
  setPinsPro(state, payload) {
    state.pinsPro = payload
  },
  setPinsUser(state, payload) {
    state.pinsUser = payload
  },
  setPolygons(state, payload) {
    state.polygons = payload
  },
  setRadarImageryClicked(state, payload) {
    state.radar_imagery_clicked = payload
  },
  setRangeValue(state, payload) {
    state.range_value = payload
  },
  setShowHelp(state, payload) {
    state.showHelp = payload
  },
  setVisibleLayer(state, payload) {
    state.visibleLayer = payload
  },
  // setSelectedLayer(state, payload) {
  //   state.selectedLayer = payload
  // },  
  setZoom(state, payload) {
    state.zoom = payload
  },
  setZoomControl(state, payload) {
    state.zoomControl = payload
  },
  setHideButtons(state, payload) {
    state.hide_buttons = payload
  },
  toggleAllHazards(state) {
    if (state.isPersonalHazardVisible && state.isUserHazardVisible && state.isProHazardVisible && state.isAdminHazardVisible) {
      state.isAdminHazardVisible = false
      state.isPersonalHazardVisible = false
      state.isProHazardVisible = false
      state.isUserHazardVisible = false
    } else {
      state.isAdminHazardVisible = true
      state.isPersonalHazardVisible = true
      state.isProHazardVisible = true
      state.isUserHazardVisible = true
    }
  },
  toggleAllMapComponents(state) {
    if (state.isMapVisible && state.isIceRoadVisible) {
      state.isMapVisible = false
      state.isIceRoadVisible = false
    } else {
      state.isMapVisible = true
      state.isIceRoadVisible = true
    }
  },
  toggleHazardAdmin(state) {
    state.isAdminHazardVisible = !state.isAdminHazardVisible
  },
  toggleHazardPersonal(state) {
    state.isPersonalHazardVisible = !state.isPersonalHazardVisible
  },
  toggleHazardPro(state) {
    state.isProHazardVisible = !state.isProHazardVisible
  },
  toggleHazardUser(state) {
    state.isUserHazardVisible = !state.isUserHazardVisible
  },
  toggleIceRoad(state) {
    state.isIceRoadVisible = !state.isIceRoadVisible
  },
  toggleMap(state) {
    state.isMapVisible = !state.isMapVisible
  },
  toggleMapLock(state) {
    state.lockMapOn = !state.lockMapOn
  },
  toggleZoomControl(state) {
    state.zoomControl = !state.zoomControl
  },
};

const actions = {
  createHazard({commit}, data) {
    return new Promise((resolve, reject) => {
      axios.post('hazards', data)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        commit('auth_error', err)
        reject(err)
      })
    })
  },
  deleteHazard({commit}, data) {
    return new Promise((resolve, reject) => {
      axios.delete('hazards/' + data)
      .then(resp => {
        commit('getHazards', resp.data)
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getFootprints({commit}, data) {
    return new Promise((resolve, reject) => {
      axios.get('footprints/' + data.id)
      .then(resp => {
        let data = resp.data ? resp.data:[]
        commit('getFootprints', data)
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getHazards({commit}, data) {
    return new Promise((resolve, reject) => {
      axios.get('hazards/' + data.id)
      .then(resp => {
        if(resp.data) {
          commit('getHazards', resp.data)
        }
        else {
          commit('getHazards', [])
          commit('setPinsAdmin', [])
          commit('setPinsPersonal', [])
          commit('setPinsPOI', [])
          commit('setPinsPro', [])
          commit('setPinsUser', [])
        }        
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getHazardTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('hazardtypes')
        .then(resp => {
          commit('getHazardTypes', Object.values(resp.data))
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
    })
  },
  getLayers({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get('layers/' + payload.id)
      .then((layers) => {
        if(layers.data) {
          


          let dates = []
          let filtered_layers = layers.data.filter((layer) => layer.type == '1')
          layers.data.forEach((layer) => {
            if(layer.type == '1' && layer.tilemap_json !== '') {
              if(layer.overlay_geojson) {
                layer.overlay_geojson = JSON.parse(layer.overlay_geojson)
              }
              var info = JSON.parse(layer.tilemap_json)
              var bounds = info.bounds.split(',')
              // layer.tile_url = this.$apiEndpointUrl + 'tile/' + this.id + '/' + layer.id + '/{z}/{x}/{y}.png'
              layer.tile_url = Vue.prototype.$apiEndpointUrl + 'tile/' + payload.id + '/' + layer.id + '/{z}/{x}/{y}.pbf'
              // layer.bounds = new latLngBounds(new latLng(info.BoundingBox['@attributes'].miny,info.BoundingBox['@attributes'].minx),new latLng(info.BoundingBox['@attributes'].maxy,info.BoundingBox['@attributes'].maxx))
              layer.bounds = new latLngBounds(new latLng(bounds[1],bounds[0]),new latLng(bounds[3],bounds[2]))
              layer.center = layer.bounds.getCenter()
              // layer.maxNativeZoom = getMaxZoom(layer.tilemap_json)
              layer.maxNativeZoom = 15
              layer.maxZoom = 24
              layer.tms = true
              if(layer.date !== '' && layer.date !== null) {
                dates.push({
                  date: layer.date,
                  id: layer.id,
                  relative_orbit: layer.relative_orbit,
                  latitude: layer.latitude
                })
              }
            }
            
            if (state.all_classes === null) {
              axios.get('legend/1')
              .then((resp) =>{
                state.all_classes = Object.values(resp.data)
                setClassColours(layer)
              })
            }
            else {
              setClassColours(layer)
            }
            
          })
          commit('setLayers', filtered_layers)
          commit('setDates', dates)
          commit('setVisibleLayer', {})
        } else {
          commit('setLayers', [])
          commit('setDates', [])
          commit('setVisibleLayer', {})
        }
        // commit('setSelectedLayer', null)
        
        resolve(layers)
      }).catch(err => {
        reject(err)
      })
    })
  },
  setTest({commit}, state) {
    return new Promise(() => {
      commit('setTest', state)
    })
  },  
  setThickness({commit}, state) {
    return new Promise(() => {
      commit('setThickness', state)
    })
  },  
  getThicknessLayer({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get('thickness/' + payload.id)
      .then(resp => {
        if(resp.data) {
          var layer = []        
          layer.tile_url = Vue.prototype.$apiEndpointUrl + 'tile/' + resp.data.map_id + '/' + resp.data.id + '/{z}/{x}/{y}.pbf'
          commit('setThicknessLayer', layer)   
          commit('setThickness', true)    
           
        }
        else {
          commit('setThicknessLayer', null)
          commit('setThickness', true)   
        }        
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getLegend({commit}, data) {
    return new Promise((resolve, reject) => {
      axios.get('legend/' + data.image_type)  
      .then(resp => {
        if(resp.data) {
          state.all_classes = Object.values(resp.data)
          let visible_classes = setClassColours(state.visibleLayer)
          commit('setLegend', visible_classes)
        }
        else {
          commit('setLegend', [])
        }
        resolve(resp)         
      }).catch(err => {
        reject(err)
      })
    })
  },  
  getMaps({commit}) {
    return new Promise((resolve, reject) => {
      //axios.get('maps')
      axios.get('subscriptions')
      .then(resp => {
        commit('getMaps', Object.values(resp.data))
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getPolygons({commit}) {
    return new Promise((resolve,reject) => {
      axios.get('polygons')
      .then(resp => {
        let p = []
        resp.data.forEach((polygon) => {
          p.push({
            id: polygon.id,
            radar_id: polygon.radar_id,
            geometry: JSON.parse(polygon.geometry),
          })
        })
        commit('setPolygons', p)
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getUserReports({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('user_reports')
      .then(resp => {
        commit('getUserReports', resp.data)
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  setCurrentFootprint({commit}, footprint) {
    return new Promise((resolve) => {
      commit('setCurrentFootprint', footprint)   
      let x = state.layers.find(layer => {
        return layer.footprint_id == footprint.footprint_id && layer.date == footprint.date
      })
      x = x ? x:{}
      commit('setVisibleLayer', x)
      resolve(x)
    })
  },
  setCurrentRegion({commit}, region) {
    return new Promise(() => {
      commit('setCurrentRegion', region)
    })
  },
  setDates({commit}, dates) {
    return new Promise(() => {
      commit('setDates', dates)
    })
  },
  setGPSMode({commit}, mode) {
    return new Promise(() => {
      commit('setGPSMode', mode)
    })
  },
  setLayers({commit}, layers) {
    return new Promise(() => {
      commit('setLayers', layers)
    })
  },
  setLegend({commit}, legend) {
    return new Promise(() => {
      commit('setLegend', legend)
    })
  },  
  setTestLayer({commit}, layer) {
    return new Promise(() => {
      commit('setTestLayer', layer)
    })
  },  
  setThicknessLayer({commit}, layer) {
    return new Promise(() => {
      commit('setThicknessLayer', layer)
    })
  },  
  setLayersRequested({commit}, status) {
    return new Promise(() => {
      commit('setLayersRequested', status)
    })
  },
  setMap({commit}, map) {
    return new Promise(() => {
      commit('setMap', map)
    })
  },  
  setPinsAdmin({commit}, pins) {
    return new Promise(() => {
      commit('setPinsAdmin', pins)
    })
  },
  setPinsPersonal({commit}, pins) {
    return new Promise(() => {
      commit('setPinsPersonal', pins)
    })
  },
  setPinsPOI({commit}, pins) {
    return new Promise(() => {
      commit('setPinsPOI', pins)
    })
  },
  setPinsPro({commit}, pins) {
    return new Promise(() => {
      commit('setPinsPro', pins)
    })
  },
  setPinsUser({commit}, pins) {
    return new Promise(() => {
      commit('setPinsUser', pins)
    })
  },
  setRadarImageryClicked({commit}, status) {
    return new Promise(() => {
      commit('setRadarImageryClicked', status)
    })
  },
  setRangeValue({commit}, range) {
    return new Promise(() => {
      commit('setRangeValue', range)
    })
  },
  setShowHelp({commit}, status) {
    return new Promise(() => {
      commit('setShowHelp', status)
    })
  },  
  setVisibleLayer({commit}, layer) {
    return new Promise(() => {
      commit('setVisibleLayer', layer)
    })
  },
  // setSelectedLayer({commit}, layer) {
  //   return new Promise(() => {
  //     commit('setSelectedLayer', layer)
  //   })
  // },  
  setZoom({commit}, zoom) {
    return new Promise(() => {
      commit('setZoom', zoom)
    })
  },
  setZoomControl({commit}, zoomControl) {
    return new Promise(() => {
      commit('setZoomControl', zoomControl)
    })
  },
  setHideButtons({commit}, status) {
    return new Promise(() => {
      commit('setHideButtons', status)
    })
  },  
  toggleAllHazards({commit}) {
    return new Promise(() => {
      commit('toggleAllHazards')
    })
  },
  toggleAllMapComponents({commit}) {
    return new Promise(() => {
      commit('toggleAllMapComponents')
    })
  },
  toggleHazardAdmin({commit}) {
    return new Promise(() => {
      commit('toggleHazardAdmin')
    })
  },
  toggleHazardPersonal({commit}) {
    return new Promise(() => {
      commit('toggleHazardPersonal')
    })
  },
  toggleHazardPro({commit}) {
    return new Promise(() => {
      commit('toggleHazardPro')
    })
  },
  toggleHazardUser({commit}) {
    return new Promise(() => {
      commit('toggleHazardUser')
    })
  },
  toggleIceRoad({commit}) {
    return new Promise(() => {
      commit('toggleIceRoad')
    })
  },
  toggleMap({commit}) {
    return new Promise(() => {
      commit('toggleMap')
    })
  },
  toggleMapLock({commit}) {
    return new Promise(() => {
      commit('toggleMapLock')
    })
  },
  toggleZoomControl({commit}) {
    return new Promise(() => {
      commit('toggleZoomControl')
    })
  }
};

function setClassColours(layer) {
  var visible_classes = []
  var current_class
  
  // If current layer has value in 'contains_classes'
  if (layer.contains_classes) {

    // Parse 'contains_classes'
    layer.contains_classes.split(', ').forEach(code => {

      // Find class within all_classes
      current_class = state.all_classes.find(c => c.class_value === code && c.image_type === layer.type)
      if (current_class) {
        visible_classes.push(current_class)
      }
    })
  }  
  let classColours = {}
  visible_classes.forEach(current_class => {
    classColours[current_class.class_value] = {}
    classColours[current_class.class_value].red = current_class.red
    classColours[current_class.class_value].green = current_class.green
    classColours[current_class.class_value].blue = current_class.blue
  })   
  layer.classColours = classColours
  return visible_classes
}

// function getMaxZoom(json) {
//   if(json == '' || json == null) return
//   var info = JSON.parse(json)
//   var max = 1
//   if(!info.TileSets || !info.TileSets.TileSet) return 0
//   info.TileSets.TileSet.forEach(function(zoom) {
//     if(parseInt(zoom['@attributes'].href) > max) max = parseInt(zoom['@attributes'].href)
//     // if(parseInt(zoom['@attributes'].href) < min) min = parseInt(zoom['@attributes'].href)
//   })
//   return max
// }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}