<template>
  <span>
    <l-control 
      class="custom-control pa-1" 
      position="topleft"
      style="padding: 0.1em 0.1em 0.1em 0.1em !important;"
    >
      <v-tooltip right :disabled="filtering" >
        <template v-slot:activator="{ on, attrs }">
          <v-layout class="ma-0" row align-baseline v-bind="attrs" v-on="on">
            <v-slide-x-reverse-transition mode="out-in" :hide-on-leave="true">
              <v-flex
                align-self-center
                class="my-6 ml-5 mr-11"
                v-if="filtering"
              >
                <v-layout row>
                  <span class="mx-2 mt-2 mb-1 text-subtitle-2 deep-orange--text">
                    Filter Geoposts:
                  </span>
                </v-layout>
                <v-layout row>
                  <v-flex 
                    align-self-center 
                    class="pa-1 text-right" 
                    xs4
                  >
                    <span class="text-caption">
                      By theme:
                    </span>
                  </v-flex>
                  <v-flex 
                    class="pa-1" 
                    xs8
                  >
                    <v-btn-toggle
                      class="ma-1"
                      multiple 
                      v-model="filter_themes"
                      @change="themes_changed"
                    >
                      <v-tooltip v-if="tab === 'radar'" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn 
                            class="py-4 px-5"
                            icon
                            small
                            v-bind="attrs"
                            v-on="on"
                            value="access_points"
                          >
                            <v-icon>fa-map-marker</v-icon>
                          </v-btn>
                        </template>
                        <span>Access Points</span>
                      </v-tooltip>
                      <v-tooltip v-if="tab === 'map'" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn 
                            class="py-4 px-5"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            value="fishing_spots"
                            small
                          >
                            <v-icon>fa-fish</v-icon>
                          </v-btn>
                        </template>
                        <span>Fishing Spots</span>
                      </v-tooltip>
                      <v-tooltip v-if="tab === 'radar'" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn 
                            class="py-4 px-5"
                            disabled
                            icon 
                            v-bind="attrs"
                            v-on="on"
                            value="hazards"
                            small
                          >
                            <v-icon>fa-exclamation-triangle</v-icon>
                          </v-btn>
                        </template>
                      </v-tooltip>
                    </v-btn-toggle>
                  </v-flex>
                </v-layout>
                <v-layout v-if="tab === 'radar'" row>
                  <v-flex 
                    align-self-center 
                    class="pa-1 text-right" 
                    xs4
                  >
                    <span class="text-caption">
                      By time:
                    </span>
                  </v-flex>
                  <v-flex class="pa-1" xs8>
                    <v-btn-toggle
                      class="ma-1"
                      v-model="filter_time"
                      @change="time_changed"
                    >
                      <v-btn class="py-3" small value="daily">
                        &lt;24 hrs
                      </v-btn>
                      <v-btn class="py-3" small value="weekly">
                        &lt;7 days
                      </v-btn>
                    </v-btn-toggle>
                  </v-flex>
                </v-layout>
                <v-layout v-if="tab === 'radar' && !filter_themes.includes('fishing_spots')" row>
                  <v-flex 
                    align-self-center 
                    class="pa-1 text-right" 
                    xs4
                  >
                    <span class="text-caption">
                      Access Points by accessibility:
                    </span>
                  </v-flex>
                  <v-flex 
                    class="pa-1" 
                    xs8
                  >
                    <v-btn-toggle
                      class="ma-1"
                      multiple 
                      v-model="filter_accessibility"
                      @change="ap_accessibility_changed"
                    >
                      <v-tooltip v-for="(ap,index) in ap_list" :key="index" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn 
                            class="py-4 px-5"
                            icon
                            small
                            v-bind="attrs"
                            v-on="on"
                            :value="ap.id"
                          >
                            <v-icon v-if="ap.id != 3">{{ ap.icon }}</v-icon>
                            <v-img v-else contain width="23" :src="quad_icon" />
                          </v-btn>
                        </template>
                        <span>{{ ap.name }}</span>
                      </v-tooltip>
                    </v-btn-toggle>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-slide-x-reverse-transition>
            <v-flex shrink style="margin: 13px;">
              <v-layout row>
                <v-btn
                  icon
                  small
                  @click="toggleFilter"
                >
                  <v-icon :color="filtering ? 'deep-orange':'icetimeblue'">
                    {{ filtering ? 'fa-times':'fa-filter' }}
                  </v-icon>
                </v-btn>
              </v-layout>
              <v-layout row class="fill-height">
              </v-layout>
            </v-flex>
          </v-layout>
        </template>
        <span>Filter Geoposts</span>
      </v-tooltip>
    </l-control>
  </span>
</template>

<script>
import { LControl } from 'vue2-leaflet'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      ap_list: [
        { icon: 'fa-ban', id: 1, name: 'Inaccessible' },
        { icon: 'fa-walking', id: 2, name: 'Foot' },
        { icon: 'mdi-atv', id: 3, name: 'Quad' },
        { icon: 'mdi-snowmobile', id: 4, name: 'Snowmobile' },
        { icon: 'fa-truck-pickup', id: 5, name: '2 WD' },
        { icon: 'fa-truck-monster', id: 6, name: '4 WD' },
      ],
      filter_time: '',
      filter_themes: [],
      filtering: false,
      quad_icon: require('/public/img/quad.svg'),
    }
  },
  components: {
    LControl,
  },
  computed: {
    ...mapState(['tab']),
    ...mapState(['mode']),
    ...mapState('access_points', {
      access_points: state => state.access_points,
      ap_accessibility_themes: state => state. access_point_filter_conditions,    
      ap_geopost_filter_themes: state => state.geopost_filter_themes,
    }),    
    ...mapState('spot_mapper', {        
      fishing_spots: state => state.fishing_spots,
      sm_geopost_filter_themes: state => state.geopost_filter_themes,
    }), 
  },
  mounted() {
    this.filter_accessibility = this.ap_accessibility_themes        
    switch (this.tab) {
        case 'map':      
          this.filter_themes = this.sm_geopost_filter_themes
          break
        case 'radar':
        this.filter_themes = this.ap_geopost_filter_themes
          break
      }    
  },
  methods: {
    ap_accessibility_changed() {
      this.$store.dispatch('access_points/setAccessPointFilterConditions', this.filter_accessibility)
    },
    themes_changed() {    
      switch (this.tab) {
        case 'map':      
          this.$store.dispatch('spot_mapper/setGeopostFilterThemes', this.filter_themes) 
          break
        case 'radar':
          this.$store.dispatch('access_points/setGeopostFilterThemes', this.filter_themes)  
          break
      }     
      this.$emit('filter-themes-changed')
      // this.filter_themes = this.geopost_filter_themes
    },
    time_changed() {
      this.$store.dispatch('access_points/setGeopostFilterTime', this.filter_time)
      this.$emit('filter-time-changed')

      if(this.filter_time === 'weekly') {
        this.$store.dispatch('access_points/setVoteFilter', 'weekly')
      } else {
        this.$store.dispatch('access_points/setVoteFilter', 'daily')
      }
    },
    toggleFilter() {
      this.filtering = !this.filtering
    }
  },
  props: [
  ]
}
</script>

<style scoped>
>>>.v-dialog{
  /* background-color: #fff; */
  border-radius: 10px;
  /* bottom: 0;  */
  box-shadow: none;
  left: 0;
  max-height: 77%;
  max-width: 500px;
  overflow-y: auto!important; 
  position: absolute;
  top: 0;
  width: unset;
}
</style>