import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

// If API is needed
Vue.use(Vuex)
axios.defaults.baseUrl = Vue.$apiEndpointUrl

const state = {
  reports: [],
  user_locations: [],
  users: [],
  all_users: [],
  training_catches: [],
  full_species_stats: [],
  average_species_stats: [],
  accuracy_species_stats: [],
  trained_species_stats: [],
  lower_species_stats: [],
  upper_species_stats: [],
};
const getters = {

};

const mutations = {
  getReports(state, payload) {
    state.reports = payload
  },
  getUserLocations(state, payload) {
    state.user_locations = payload
  },
  getUsers(state, payload) {
    state.users = payload
  },
  getAllUsers(state, payload) {
    state.all_users = payload
  },
  setTrainingCatches(state, catches) {
    state.training_catches = catches
  },
  setFullSpeciesStats(state, stats) {
    state.full_species_stats = stats
  },
  setAverageSpeciesStats(state, stats) {
    state.average_species_stats = stats
  },
  setAccuracySpeciesStats(state, stats) {
    state.accuracy_species_stats = stats
  },
  setTrainedSpeciesStats(state, stats) {
    state.trained_species_stats = stats
  },
  setLowerSpeciesStats(state, stats) {
    state.lower_species_stats = stats
  },
  setUpperSpeciesStats(state, stats) {
    state.upper_species_stats = stats
  },
};

const actions = {
  deleteReport(_, item) {
    return new Promise((resolve, reject) => {
      axios.delete('report/' + item)
      .then((resp) => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getRegions() {
    return new Promise((resolve, reject) => {
      axios.get('regions')
      .then(resp => {
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getReports({commit}) {  
    return new Promise((resolve, reject) => {
      axios.get('reports')
      .then(resp => {
        commit('getReports', Object.values(resp.data))
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getSpace() {
    return new Promise((resolve, reject) => {
      axios.get('space')
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getStatus(_, data) {
    if (data == 0) {
      return new Promise((resolve, reject) => {
        axios.get('status')
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    }
    else {
      return new Promise((resolve, reject) => {
        axios.get('status/' + data)
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })      
    }
  },
  getUploads() {
    return new Promise((resolve, reject) => {
      axios.get('uploads')
      .then(resp => {
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getUserLocations({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('user_locations')
      .then(resp => {
        commit('getUserLocations', Object.values(resp.data))
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getUsers({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('users')
      .then(resp => {
        commit('getUsers', Object.values(resp.data))
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getAllUsers({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('all_users')
      .then(resp => {
        commit('getAllUsers', Object.values(resp.data))
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getTrainingCatches({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('training_catches')
      .then(resp => {
        if (resp.data) {
          // console.log(resp.data)
          commit('setTrainingCatches', resp.data)
        } else {
          // console.log(resp.data[0])
          commit('setTrainingCatches', [])
        }
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  // getTeamList({commit}) {
  //   return new Promise((resolve, reject) => {
  //     axios.get('teammemberlist/' + this.selected_competition)
  //     .then(resp => {
  //       if (resp.data) {
  //         // console.log(resp.data)
  //         commit('setTeamList', resp.data)
  //       } else {
  //         // console.log(resp.data[0])
  //         commit('setTeamList', [])
  //       }
  //       resolve(resp)
  //     })
  //     .catch(err => {{
  //       reject(err)
  //     }})
  //   })
  // },
  getFullStatistics({commit}, { species }) {
    return new Promise((resolve, reject) => {
      axios.get('full_species_statistics/' + species )
      .then(resp => {
        if (resp.data) {
          // console.log(resp.data)
          commit('setFullSpeciesStats', resp.data)
        } else {
          // console.log(resp.data[0])
          commit('setFullSpeciesStats', [])
          
        }
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getGeneralStatistics({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('average_species_statistics/' )
      .then(resp => {
        if (resp.data) {
          // console.log(resp.data)
          commit('setAverageSpeciesStats', resp.data["catches"]);
          commit('setAccuracySpeciesStats', resp.data["accuracy"]);
          commit('setTrainedSpeciesStats', resp.data["trained"]);
          commit('setLowerSpeciesStats', resp.data["lower"]);
          commit('setUpperSpeciesStats', resp.data["upper"]);
        } else {
          // console.log(resp.data[0])
          commit('setAverageSpeciesStats', [])
          commit('setAccuracySpeciesStats', [])
          commit('setTrainedSpeciesStats', [])
          commit('setLowerSpeciesStats', [])
          commit('setUpperSpeciesStats', [])          
        }
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  postImport(_, payload) {
    //console.log(payload)
    return new Promise((resolve) => {
      axios.post('import', payload)
      .then(resp => {
        resolve(resp)
      })
    })
  },
  stopImport(_, layer) {
    return new Promise((resolve, reject) => {
      axios.post('stop_import', layer)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  sendTrainingData(_, trainingData) {
    return new Promise((resolve, reject) => {
      axios.post('send_training_species', trainingData)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  sendExcludeData(_, excludeData) {
    return new Promise((resolve, reject) => {
      axios.post('send_exclude_catches', excludeData)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  sendtest(_, testdata) {
    return new Promise((resolve, reject) => {
      axios.post('receive_training_update', testdata)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  updateReport({dispatch}, report) {
      return new Promise((resolve, reject) => {
        axios.put('reports', report)
        .then(resp => {
          dispatch('getReports')
          resolve(resp)
        }).catch(err => {
          // commit('auth_error', err)
          reject(err)
        })
      })
    },
  // testMailChimp() {
  //   return new Promise((resolve, reject) => {
  //     axios.get('mailchimp_ping')
  //     .then(resp => {
  //       resolve(resp)
  //     })
  //     .catch(err => {
  //       reject(err)
  //     })
  //   })
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}