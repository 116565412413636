<template>
  <v-chip
    class="mx-3 pa-4 white--text text-uppercase font-weight-medium"
    color="teal"
    elevation="0"
    rounded
    small
  >
    <v-icon 
      class="mr-2"
      small
    >
      mdi-progress-clock
    </v-icon>
    Coming Fall 2024
  </v-chip>
</template>

<script>
export default {
  setup() {
    
  },
}
</script>
