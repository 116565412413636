<template>
  <v-scroll-y-reverse-transition mode="out-in" :hide-on-leave="true" v-if="fishing_spot">
    <!-- Will Need to make v-if for these so they are hidden when editing/adding catches? -->
      <v-dialog      
        max-width="500px"
        hide-overlay
        :fullscreen="mobile_fullscreen"
        transition="dialog-bottom-transition"
        mode="out-in"
        no-click-animation
        persistent
        value="fishing_spot"
      >
      <v-card class="pb-9" style="overflow-y: auto; ">
        <v-card-actions class="pt-4">
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog" text class="grey--text">
            Close
            <v-icon right color="grey">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>         
        <v-divider></v-divider>

        <v-container class="pa-0 ma-0">
              <v-slide-x-reverse-transition mode="out-in" :hide-on-leave="true">
                <v-btn
                  class="mx-1"
                  color="teal"
                  dark
                  elevation="0"
                  v-if="fishing_spot_editing && isLoggedIn && ((id = fishing_spot.user_id) || isStaffUser)"
                  x-small
                  @click="deleteFishingSpot"
                > 
                <!-- (id = fishing_spot.user_id) || -->
                Delete</v-btn>
              </v-slide-x-reverse-transition>
              <v-slide-x-reverse-transition mode="out-in" :hide-on-leave="true">
                <v-btn 
                  class="mx-1" 
                  color="teal"
                  :dark="fishing_spot_editing"
                  elevation="0"
                  :loading="editing_loading"
                  :outlined="!fishing_spot_editing"
                  :text="!fishing_spot_editing"
                  v-if="fishing_spot_editing && isLoggedIn && ((id == fishing_spot.user_id) || isStaffUser)"
                  x-small
                  @click="saveFishingSpot"
                >
                <!-- (id == fishing_spot.user_id) || -->
                  save
                </v-btn>
              </v-slide-x-reverse-transition>
              <!-- <v-btn 
                class="mx-1" 
                color="teal"
                :dark="fishing_spot_editing"
                elevation="0"
                :outlined="!fishing_spot_editing"
                :text="!fishing_spot_editing"
                v-if="(isLoggedIn && id == fishing_spot.user_id) || isStaffUser"
                x-small
                @click="toggleEdit"
              >
                {{ fishing_spot_editing ? 'cancel':'edit' }}
              </v-btn> -->
        </v-container>
            <v-card-title class="pb-1 mb-0 mx-1" v-if="!fishing_spot_editing">
              {{ fishing_spot.name ? fishing_spot.name:'Fishing Spot' }}
            </v-card-title>
            <v-text-field 
              class="text-h6 mb-2 mt-10 mx-5 teal--text" 
              color="teal"
              dense
              hide-details
              label="Fishing Spot Name" 
              placeholder="Fishing Spot"
              ref="edit_name"
              v-else
              v-model="editing_point.name" 
            />
            <v-card-subtitle class="py-1 my-0 d-flex align-center">
              <v-icon class="ml-1 mr-2" small>fa-crosshairs</v-icon>
              {{ fishing_spot.lat }}, {{ fishing_spot.lng }}
            </v-card-subtitle>
        <v-container>
            <v-flex class="d-flex justify-end mx-2 mt-2 mb-0">
              <v-btn 
                class="mx-1" 
                color="grey darken-1"
                :dark="addingCatch"
                elevation="0"
                :outlined="!addingCatch"
                :text="!addingCatch"
                v-if="isLoggedIn"
                x-small
                @click="toggleFullBreakdown"
              >
                View All Catches
              </v-btn>
              <v-btn 
                class="mx-1 grey--text darken-1" 
                disabled
                outlined 
                text
                v-else
                x-small
              >
                please log in to see full breakdown
              </v-btn>
            </v-flex>
            <v-flex class="d-flex justify-end mx-2 mt-2 mb-0">
              <v-btn 
                class="mx-1" 
                color="grey darken-1"
                :dark="addingCatch"
                elevation="0"
                :outlined="!addingCatch"
                :text="!addingCatch"
                v-if="isLoggedIn"
                x-small
                @click="toggleAddCatch"
              >
                {{ addingCatch ? 'cancel':'add catch' }}
              </v-btn>
              <v-btn 
                class="mx-1 grey--text darken-1" 
                disabled
                outlined 
                text
                v-else
                x-small
              >
                please log in to add a catch
              </v-btn>
            </v-flex>
            <!-- @newCatch="newCatch" add bellow-->
            <AddCatch v-if="addingCatch" @new-catch="newCatch" />
            <v-card-title>
              Catches
            </v-card-title>
            <!-- <v-btn
              v-if="added_to_comp"
              @click="navToComp"
              >View Comp</v-btn> -->
            <v-card
              class="px-2 py-0"
              flat
              v-if="group_catches.length < 1"
            >
              <v-card-text class="my-0 mx-1 pa-0 font-italic text-caption">
                No catches here yet.
              </v-card-text>
            </v-card>
            
            <v-card 
              v-for="item in group_catches"
              class="px-2 py-0"
              flat
              :key="item.id"
            >
            <v-card class="pa-2 black--text text-body-2" :color="'grey lighten-3'" dark flat rounded>
              <!-- {{ item.caught }}
              {{ item.common_name }} caught -->
              <div class="capitalize">{{ item.common_name }}</div>
            </v-card>
            <v-row class="px-2 pt-2" no-gutters>
              <v-col cols="6">
                <v-card-text class="py-0">
                    <div class="text-caption">Total Caught</div>
                    <p class="text-h6">{{ item.caught }}</p>                            
                </v-card-text>   
              </v-col>              
              <v-col cols="6">
                <v-card-text class="py-0">
                    <div class="text-caption">Average Length</div>
                    <p class="text-h6">{{ item.length.substring(0, 5) }} cm.</p>                            
                </v-card-text>   
              </v-col>
              <v-col cols="6">
                <v-card-text class="py-0">
                    <div class="text-caption">Average Weight</div>
                    <p class="text-h6">{{ item.weight.substring(0, 5) }} lbs.</p>                            
                </v-card-text>   
              </v-col>
              <v-col cols="6">
                <v-card-text class="py-0">
                    <div class="text-caption">Average Depth</div>
                    <p class="text-h6">{{ item.depth.substring(0, 7) }} m.</p>                            
                </v-card-text>   
              </v-col>                            
            </v-row>

            <!-- <v-card-text class="my-0 mx-1 pa-0 text-caption">
              Average Length: {{ item.length.substring(0, 5) }}
            </v-card-text>
            <v-card-text class="my-0 mx-1 pa-0 text-caption">
              Average Weight: {{ item.weight.substring(0, 5) }}
            </v-card-text>
            <v-card-text class="my-0 mx-1 pa-0 text-caption">
              Average Depth: {{ item.depth.substring(0, 7) }}
            </v-card-text> -->
            </v-card>
        </v-container>  
      </v-card>
    </v-dialog>
  </v-scroll-y-reverse-transition>
</template>
  
  <script>
  import { mapState, mapGetters } from 'vuex';
  import AddCatch from './AddCatch.vue'
  
  export default {
    data() {
      return {
        addingCatch: false,
        full_breakdown: false,
        editing_loading: false,
        editing_point: {
          name: '',
          lat: '',
          lng: '',
        },
        show_nav: false
        // fishing_spot:[],
      }
    },
    components: {
      AddCatch
  },
    computed: {
      ...mapGetters('user', {
        isLoggedIn: 'isLoggedIn',
        isStaffUser: 'isStaffUser'
      }),
      ...mapState('spot_mapper', {
        selected_fishing_spot: state => state.selected_fishing_spot,
        fishing_spot_editing: state => state.fishing_spot_editing,
        fishing_spots: state => state.fishing_spots,
        group_catches: state => state.group_catches,
        added_to_comp: state => state.added_to_comp
        // catches: state => state.catches
        // selected_catch: state => state.selected_catch
      }),
      ...mapState('user', {
        id: state => state.user.id,
      }),
      fishing_spot() {
        return this.fishing_spots.find(item => item.id == this.selected_fishing_spot)
      },
      mobile_fullscreen() {
        return !this.$vuetify.breakpoint.mobile ? false : true
        // return true
      },      
    },    
    methods: {
      closeDialog() {
        this.$store.dispatch('spot_mapper/setSelectedFishingSpot', null)
        this.$store.dispatch('spot_mapper/setAddedToComp', '')
        // this.editing = false
        this.$store.dispatch('spot_mapper/setFishingSpotEditing', false)
      },
      deleteFishingSpot() {
        // console.log('delete ' + this.access_point.geopost_id)
        // this.$store.dispatch('spot_mapper/deleteFishingSpot', this.fishing_spot.id)
        // .then(() => {
        //   this.closeDialog()
        //   this.$emit('fishingSpotsUpdated')
        // })
        // console.log(this.catches)
      },
      newCatch(){
        this.addingCatch = false
        if (this.selected_fishing_spot){
          this.$store.dispatch('spot_mapper/getGroupCatches', this.selected_fishing_spot)
          if(this.added_to_comp !== ''){
            this.show_nav = true
          }
        }
      },
      toggleEdit() {
        // Update Store for fishing spot editing
        this.$store.dispatch('spot_mapper/setFishingSpotEditing', !this.fishing_spot_editing)
  
        // if (this.editing) {\
        if (this.fishing_spot_editing) {
          this.editing_point.name = this.fishing_spot.name
          // error from focus?
          this.$nextTick(() => this.$refs.edit_name.focus())
        } else {
          this.editing_point.name = ''
          // check store to ensure this is set up right
          this.$store.dispatch('spot_mapper/setFishingSpotNewLocation', [])
        }
      },
      toggleAddCatch() {
      this.addingCatch = !this.addingCatch

      // console.log(this.grouped_catches)
      },
      toggleFullBreakdown() {
        this.full_breakdown = !this.full_breakdown
        this.$emit('fullBreakdown')
      // console.log(this.grouped_catches)
      },
      navToComp() {
        // this.$store.dispatch('')
        // router.push
      }
    },
    mounted(){
      // this.fishing_spot = this.fishing_spots.find(item => item.id == this.selected_fishing_spot)
    },
    props: {
    }
  }
  </script>
  
  <style scoped>
  >>>.sticky {
    position: sticky;
    top: 0;
    z-index: 2;
  }
  
  >>>.v-dialog{
  /* background-color: #fff; */
  bottom: 0; 
  left: 0;
  position: absolute;
}
>>>.card-outter {
  position: relative;
  padding-bottom: 1.5em;
}
  >>>.card-actions {
  position: absolute;
  top: 0;
  right:0;
}
.capitalize {
    text-transform: capitalize;
}
  </style>