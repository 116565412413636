<template>
  <span>
    <span v-if="$vuetify.breakpoint.smAndUp" class="text-caption indigo--text text--lighten-4 pr-2">APP STATUS:</span>
    <v-chip class="py-4 px-1" outlined small>
      <v-btn 
        v-if="swStatus == swStatusList.updated" 
        @click.prevent="completeUpdate" 
        class="mx-0" icon
      >
        <v-icon :color="swStatus.color">{{ swStatus.icon }}</v-icon>
      </v-btn>
      <v-btn 
        v-else 
        :loading="swStatus.loading" 
        class="mx-0" 
        icon
      >
        <v-icon :color="swStatus.color">{{ swStatus.icon }}</v-icon>
      </v-btn>
      <span class="text-subtitle-2 pr-4 ml-0 pl-0 d-none d-sm-flex">{{ swStatus.text }}</span>
    </v-chip>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('user', {
      isLoggedIn: 'isLoggedIn',
      isStaffUser: 'isStaffUser',
      isAdmin: 'isAdmin'
    }),
  },
  created() {
    this.swStatus = this.swStatusList.default
    document.addEventListener('swUpdateFound', this.detectedUpdateFound, { once: true })
    document.addEventListener('swUpdated', this.detectedUpdated, { once: true })
    document.addEventListener('swOffline', this.detectedOffline, { once: true })
    document.addEventListener('swError', this.detectedError, { once: true })
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  },
  data() {
    return {
      refreshing: false,
      swStatus: '',
      swStatusList: {
        default: {
          color: 'light-blue accent-1',
          icon: 'mdi-check-circle-outline',
          loading: false,
          text: 'frosty — AOK!', 
        },
        updatedFound: {
          color: 'yellow',
          icon: 'mdi-refresh',
          loading: true,
          text: 'loading changes', 
        },
        updated: {
          color: 'yellow',
          icon: 'mdi-refresh',
          loading: false,
          text: 'refresh', 
        },
        error: {
          color: 'red lighten-2',
          icon: 'mdi-close-circle-outline',
          loading: false,
          text: 'error', 
        },
        offline: {
          color: 'grey lighten-1',
          icon: 'mdi-alert-circle-outline',
          loading: false,
          text: 'offline', 
        },
      },
    }
  },
  methods: {
    completeUpdate() {
      this.swStatus = this.swStatusList.default
      if(this.registration || this.registration.waiting) {
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      }
    },
    detectedUpdateFound() {
      this.swStatus = this.swStatusList.updatedFound
    },
    detectedUpdated(e) {
      this.registration = e.detail
      this.swStatus = this.swStatusList.updated
    },
    detectedOffline() {
      this.swStatus = this.swStatusList.offline
    },
    detectedError(error) {
      this.swStatusList.error.text += (": " + error)
      this.swStatus = this.swStatusList.error
    },
  },
}
</script>
