<template>
    <v-card class="ma-4 pa-2 mb-16">
        <v-card-title>
            About Satellite Radar
        </v-card-title>
        <v-card-text>
            {{ Math.floor(((currentPage + 1) / totalPages) * 100) }}%
            <v-progress-linear :value="Math.floor(((currentPage + 1) / totalPages) * 100)">
            </v-progress-linear>
        </v-card-text>
        <v-card flat>
            <v-slide-x-transition leave-absolute hide-on-leave>
                <v-card-text v-if="currentPage == 0">
                    Unlike the satellites that image similarly to your digital camera, radar satellites are considered to be “all-weather” mainly because they have their own energy source. 
                    Radar satellites don't need the sun's energy reflected from the surface of the Earth to form images. The basic principle behind a radar is simple. A satellite radar emits 
                    tiny bursts of energy that reflect from the Earth, called backscatter, to the satellite where the echo is measured.
                </v-card-text>
            </v-slide-x-transition>
            <v-slide-x-transition leave-absolute hide-on-leave>
                <v-card-text v-if="currentPage == 1">
                    Radar satellites can “see”, that is image, at night, through clouds and snow, and in the cold of winter through the snow to the ice. This means we don't lose images due to 
                    cloud cover, we can get images day or night, and we can see the ice surface and into the ice volume, too. That's cool! That's also why Ice Time&#8482; works! 
                    
                    <br><br><i>Figure radar image swath at night</i>
                </v-card-text>
            </v-slide-x-transition>
            <v-slide-x-transition leave-absolute hide-on-leave>
                <v-card-text v-if="currentPage == 2">
                    Radar satellites can image this way reliably due to the relatively long wavelength emitted from the satellite. Some radar satellites emit energy with wavelengths of about 5 cm, 
                    or about the length of your fingers. This is long compared to the visible light we see, which has a wavelength of about one-billionth of a meter! To help make sense of the effect 
                    of wavelength and its ability to move forward unaltered, maybe compare it to something more familiar, like sound. Your neighbor's teenager is coming home in his or her car. You 
                    can hear the boom of the bass drum a block away. Meanwhile you don't hear the symbol or the rhythm guitar until the door opens in the nearby driveway. The bass drum wavelength is 
                    long, i.e. it is a low frequency sound, that does not interact well with objects smaller than the wavelength. This means the relatively long wavelength sound moves easily through 
                    branches of trees, and even around corners. It does not interact and so continues to propagate forwards. 
                </v-card-text>
            </v-slide-x-transition>
            <v-slide-x-transition leave-absolute hide-on-leave>
                <v-card-text v-if="currentPage == 3">
                    Short wavelengths, on the other hand, are high frequency scatterers that interact with most things around us because the objects we surround ourselves with are big relative to 
                    the wavelength. The short wavelengths from the high pitch instruments mostly bounce around inside the car. Those that do get outside scatter easily and repeatedly on the nearby 
                    cars, grass, hedges, fences, and trees. This changes the direction of scattering to be mostly away from you. Often you don't hear high frequency/short wavelength sounds well 
                    until you have a direct and unobstructed line of sight. The main takeaway is understanding that the energy emitted, like sound, does not interact easily with objects that are 
                    smaller than the wavelength. Some objects smaller than the wavelength can and do interact with and scatter the energy passing by, but for our purposes here, the principle holds.
                </v-card-text>
            </v-slide-x-transition>
            <v-slide-x-transition leave-absolute hide-on-leave>
                <v-card-text v-if="currentPage == 4">
                    So now you understand how radar satellites, that is those using 5 cm wavelengths in the microwave portion of the electromagnetic spectrum, can see through clouds, rain, and 
                    snow (either falling or that on the ice). The water droplets in clouds or frozen snow grains are tiny compared to the radar wavelength. The radar's energy does not interact with 
                    them. The energy will pass through the atmosphere easily then scatter on the Earth from objects that are larger and bounce back to the satellite where the scattering is measured 
                    and recorded. Other types of radar satellites use even longer wavelengths to measure soil properties. These satellites “see” through the tree and agricultural crop canopy!
                </v-card-text>
            </v-slide-x-transition>
            <v-slide-x-transition leave-absolute hide-on-leave>
                <v-card-text v-if="currentPage == 5">
                    Today's radar satellites operate in constellations located in low Earth orbit, 600 to 700 km above the earth's surface. This means identical satellites are put into the same orbit 
                    offset from one another, so we get images frequently and often for large areas of the Earth. Ice TimeTM automatically uses image data from two such constellations, accessing data 
                    from as many as 5 satellites.
                </v-card-text>
            </v-slide-x-transition>
            <v-slide-x-transition leave-absolute hide-on-leave>
                <v-card-text v-if="currentPage == 6">
                    Radar satellites do not look down at the earth vertically like visible light satellites that image sunlight reflected from the Earth.  Instead, radar satellites are said to be 
                    “side-looking” because the images are formed from microwave energy emitted obliquely from the satellite and then the backscattered energy from the Earth returns to the satellite. 
                    As different materials, or in our case ice types, can scatter energy differently we can use the type of scattering signal to tell us what has been imaged. Scattering of radar 
                    energy by different Earth materials is the center of radar science and is key to understanding the images. It is complex so we will keep it simple. What you will learn will be 
                    enough for you to understand why Ice Time works and to benefit greatly from its use as a tool for monitoring, planning, and travelling.

                    <br><br><i>Forward scattering and back scattering Figures</i>
                </v-card-text>
            </v-slide-x-transition>
        </v-card>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="currentPage != 0 && currentPage != totalPages" @click="prevPage">Prev</v-btn>
            <v-btn v-if="currentPage != totalPages -1 && currentPage != totalPages " @click="nextPage">Next</v-btn>
            <v-btn v-if="currentPage == totalPages -1" @click="nextSection">Next Section</v-btn>
        </v-card-actions>
    </v-card>
</template>
    
<script>
import goTo from 'vuetify/lib/services/goto'

export default { 
    data() {
        return {
            currentPage: 0,
            totalPages: 7,
        }
    },
    computed: {
    },
    methods: {
        nextPage() {
            goTo(0)
            this.currentPage++
        },
        prevPage() {
            goTo(0)
            this.currentPage--
        },
        nextSection() {
            this.$emit('next-section')
            goTo(0)
        },
    },
}
</script>

<style>
</style>