<template>
  <v-container>
    <v-alert v-if="showLoading" type="info">Loading...</v-alert>
    <v-data-table
      class="elevation-1 grey lighten-3"
      :headers="headers"
      item-key="report_id"
      :items="advisories"
      :search="search"
      show-expand
      single-expand
      sort-by="date"
      sort-desc
    >
      <template v-slot:top>
        <v-toolbar class="grey lighten-3" flat>
          <v-toolbar-title>Autogenerated Advisories (Read Only)</v-toolbar-title>
        </v-toolbar>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row class="xs12 pa-6">
            <v-col cols="12" sm="6" md="4">
              <v-img 
                :src="$apiEndpointUrl + 'layerthumb/' + item.layer_id + '/thumb.png'" 
                height="100%"
                max-width="100%"
                max-height="350"
              />
            </v-col>
            <v-col cols="12" sm="6" md="8">
              <!-- <v-chip v-if="data_table_switch" small class="mr-2 my-2 white--text" color="deep-orange">{{ item.region_name }}</v-chip>  -->
              <!-- <v-chip v-else small class="mr-2 my-2" color="primary">{{ item.region_name }}</v-chip> -->
              <v-chip small class="mr-2 my-2" color="primary">{{ item.region_name }}</v-chip>
              <v-chip small class="mr-2 my-2" color="grey lighten-1">{{ item.footprint_name }}</v-chip> 
              <v-chip small class="mr-2 my-2">{{ item.date }}</v-chip>
              <p class="text-h5 py-2">{{ item.title ? item.title:"[ This report has no title ]" }}</p>
              <p class="text-body-1" style="white-space: pre-line">{{ item.description ? item.description:"[ This report has no description ]" }}</p>
            </v-col>
          </v-row>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    data: () => ({
      dialog: false,
      headers: [
        { text: "Last Updated (UTC)", value: "last_update" },
        { text: 'Region', value: 'region_name' },
        { text: 'Footprint', value: 'footprint_name', align: 'start' },
        { text: 'Preview', value: 'data-table-expand' },
      ],
      search: '',
      showLoading: true,
    }),
    computed: {
      ...mapState('ras', {
        advisories: state => state.advisories
      }),
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
    },
    created () {
      this.showLoading = true
      this.$store.dispatch('ras/getAdvisories')
      .then(() => {
        this.showLoading = false
      })
      .catch(() => {
        this.showLoading = false
      })
    },
    methods: {
      goMap(item) {
        // if(this.data_table_switch) {
        //   this.$router.push('/demo/region/' + item.map_id + "/" + item.layer_id)
        // } else {
          this.$router.push('/app/radar/' + item.map_id + "/" + item.layer_id)
        // }
      },
    },
  }
</script>