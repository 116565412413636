<template>
    <v-container>
      <AdminUploader />
    </v-container>
  </template>
  
  <script>
    // import AdminHeader from '../components/AdminHeader.vue'
    import AdminUploader from '../components/AdminUploader.vue'
  
    export default {
      components: {
        // AdminHeader,
        AdminUploader, 
      },
      data() {
        return {}
      },
      mounted() {
        // this.$store.dispatch('setMode', 'admin')
        this.$store.dispatch('setTab', 'users')
      }
    }
  </script>