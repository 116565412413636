import axios from 'axios'
// import { latLng, latLngBounds } from 'leaflet';
import Vue from 'vue'
import Vuex from 'vuex'

// For API dependencies
Vue.use(Vuex)
axios.defaults.baseUrl = Vue.$apiEndpointUrl

const state = {
  advisories: [],
  advisory_history: [],
  entry: false,
  manual_advisories: [],
  regions: [],
  selected_region: '',
  toggle_advisory_create: false,
};

const getters = {
};

const mutations = {
  setAdvisories(state, data) {
    state.advisories = data
  },
  setAdvisoryHistory(state, history) {
    state.advisory_history = history
  },
  setAdvisoryCreate(state, data) { 
    state.toggle_advisory_create = data
  },
  setEntry(state, data) {
    state.entry = data
  },
  setManualAdvisories(state, data) {
    state.manual_advisories = data
  },
  setRegions(state, data) {
    state.regions = data
  },
  setSelectedRegion(state, data) {
    state.selected_region = data
  },
};

const actions = {
  createManualAdvisory(_, data) {
    return new Promise((resolve,reject) => {
      axios.post('manual_advisories', data)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  deleteManualAdvisory(_, id) {
    return new Promise((resolve,reject) => {
      axios.delete('manual_advisories/' + id)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getAdvisories({commit}, geolocation) {
    return new Promise((resolve, reject) => {
      let request = "geolocation" in window ? ('advisories/' + geolocation[0] + '/' + geolocation[1]):'advisories'
      axios.get(request)
      .then((resp) => {
        if(resp.data) commit('setAdvisories', Object.values(resp.data))
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getAdvisory(_, id) {
    return new Promise((resolve,reject) => {
      axios.get('advisory/' + id)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getAdvisoryHistory({commit}, id) {
    return new Promise((resolve,reject) => {
      axios.get('advisories/' + id)
      .then((resp) => {
        if(resp.data) commit('setAdvisoryHistory', Object.values(resp.data))
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getManualAdvisories({commit}) {
    return new Promise((resolve,reject) => {
      axios.get('manual_advisories')
      .then(resp => {
        if (resp.data) commit('setManualAdvisories', Object.values(resp.data))
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getRegionFootprint(_, id) {
    return new Promise((resolve,reject) => {
      axios.get('region_footprint/' + id)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getRegions({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('regions')
      .then(resp => {
        commit('setRegions', Object.values(resp.data))
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  setAdvisoryCreate({commit}, data) {
    return new Promise(() => {
      commit('setAdvisoryCreate', data)
    })
  },
  setEntry({commit}, entry) {
    return new Promise(() => {
      commit('setEntry', entry)
    })
  },
  setSelectedRegion({commit}, region) {
    return new Promise(() => {
      commit('setSelectedRegion', region)
    })
  },
  updateManualAdvisory(_, data) {
    return new Promise((resolve,reject) => {
      axios.post('manual_advisories', data)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  updatePrimaryRegion(_, data) {
    return new Promise((resolve, reject) => {
      axios.post('primary_region', data)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}