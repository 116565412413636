<template>
  <v-card
    class="ma-5 pa-4"
  >
    <ComingFall2023Chip />
    <v-card-title>
      Lake Ice Travel
    </v-card-title>
    <v-card-text class="text-body-1">
      Ice Time&trade; uses real-time or near real-time information to inform you about the lake ice challenges that you cannot see from home or the shore. This is how Ice Time&trade; reduces the risks of lake ice travel: 
    </v-card-text>
    <v-card-text class="text-body-2 px-12">
      <ul>
        <li>Education - Ice Time Academy</li>
        <li>Lake Ice Advisories - Free to Ice Time Community</li>
        <li>Access Point Conditions</li>
        <li>GeoPosts - geographically enabled social media</li>
        <li>Satellite Radar Monitoring of Water, Ice and Ice Movements</li>
        <li>Ice Thickness</li>
      </ul>
    </v-card-text>
    <v-card-actions class="mt-4 justify-center">
      <v-btn class="grey--text" rounded small text>
        <v-icon class="mx-2" x-small>fa-angle-double-down</v-icon>
        Scroll for detail
        <v-icon class="mx-2" x-small>fa-angle-double-down</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
  
<script>
import ComingFall2023Chip from './ComingFall2023Chip.vue';

export default { 
  components: {
    ComingFall2023Chip
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
  