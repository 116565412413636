<template>
    <v-container >
        <TopNav ></TopNav>
        <v-divider></v-divider>
        <v-row class="px-0 ml-0 mt-1">
        <v-col cols="6" sm="3" class="px-0">
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn                    
                    v-bind="attrs"
                    v-on="on"
                    text
                    outlined
                    class="align-self-center"
                    >
                    <v-icon left>mdi-scoreboard-outline</v-icon>
                    Championship Scores
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        v-for="(item, index) in modes"
                        :key="index"
                        @click="changeMode(item.value)"
                    >{{ item.text }}
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-col>
        </v-row>
        <ReagionalLeaderboard v-if="mode=='regional'"></ReagionalLeaderboard>
        <DailyLeaderboard v-if="mode=='daily'"></DailyLeaderboard>
        <Top10Leaderboard v-if="mode=='top'"></Top10Leaderboard>
    </v-container>
</template>

<script>
// import
import { mapGetters,  } from 'vuex'
import ReagionalLeaderboard from '../components/RealTimeLeaderboard.vue';
import TopNav from '../components/TopNav.vue';
import DailyLeaderboard from '../components/DailyLeaderboard.vue';
import Top10Leaderboard from '../components/Top10Leaderboard.vue';


export default{
components: {
    ReagionalLeaderboard,
    DailyLeaderboard,
    Top10Leaderboard,
    TopNav,
},
computed: {
    ...mapGetters('user', {
      isLoggedIn: 'isLoggedIn',
    }),    

},
data() {
    return{
        mode:'regional',
        modes: [
            {value: 'regional', text: "Angler of the Year"},
            {value: 'top', text: "ALB Top 10"},
            {value: 'daily', text: "Daily Top 20"},
        ],
    }

},
methods: {
    changeMode(page){
            // console.log(page)
            this.mode = page
        },

},
mounted() {
    this.$store.dispatch('setMode', 'app')
    this.$store.dispatch('setTab', 'leaderboard')
    this.$store.dispatch('checkToken')
    // console.log('view')
    // TODO: add check for subscriber status
}
}

</script>