<template>
  <v-card
    class="ma-5 pa-4"
  >
    <ComingSoonChip />
    <v-card-title>
      GeoPosts&trade;
    </v-card-title>
    <v-card-text class="text-body-1">
      Tired of scrolling through social media fishing pages hoping for information about the ice conditions but only find others asking the same question? Are you looking for information for a certain area?
    </v-card-text>
    <v-card-text class="text-body-1">
      You are not alone. There is a better way that saves you time and lets you associate with others that are knowledgeable about ice conditions and travel.
    </v-card-text>
    <v-card-text class="text-body-1">
      Join the tribe of contemporary anglers and riders that leverage the power of GeoPosts&trade;, another Ice Time&trade; innovation.  Your social media posts are now connected to a map location. GeoPosts&trade; enable you to focus on information relevant by theme or location. Geoposts&trade; avoid filler that wastes your time.
    </v-card-text>
    <v-card-text>
      ( Figure – access point GeoPost conversation. )
    </v-card-text>
  </v-card>
</template>
  
<script>
import ComingSoonChip from './ComingSoonChip.vue'

export default { 
  components: {
    ComingSoonChip,
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
