<template>
  <v-container>
    <v-layout row>
      <v-flex xs12 sm6 offset-sm3 md8 offset-md2 pa-2>
          Press the button below to open the Billing Portal for your user account. Changes made may not be reflected until the next time you log in.
      </v-flex>
      <v-flex xs12 sm6 offset-sm3 pa-2>
          <v-btn block elevation="2" text @click="openBillingPortal()">Open Billing Portal</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {
    }
  },
  methods: {
    openBillingPortal() {
      this.$store.dispatch('openBillingPortal')
      .catch(err => {
        if(err.response.status == '403') {
          this.$store.dispatch('logout')
          this.$store.dispatch('setDialog', { 
            show: true, 
            message: err.response.data, 
            status: err.response.status })
        }
      })
    }
  },
  mounted() {
    this.$store.dispatch('setTab', 'billing')
  }
}
</script>
