<template>
  <v-scroll-y-reverse-transition mode="out-in" :hide-on-leave="true">
    <router-view />
  </v-scroll-y-reverse-transition>
</template>

<script>

export default {
  data() {
    return {
    }
  },
  mounted() {
    this.$store.dispatch('setMode', 'user')
    this.$store.dispatch('checkToken')
  },
}
</script>
