<template>
  <v-speed-dial
    bottom
    fixed 
    right
    style="z-index: 100;"
    transition="slide-y-reverse-transition"
    v-model="fab"
  >
    <template v-slot:activator>
      <v-btn
        color="icetimeblue"
        dark
        elevation="13"
        fab
        @click.prevent="menu_clicked"
        style="opacity: 0.75;"
      >
        <v-icon v-if="fab">
          mdi-close
        </v-icon>
        <v-icon v-else>
          mdi-menu
        </v-icon>
      </v-btn>
    </template>
    <v-tooltip left v-if="isLoggedIn" :value="showTooltip">
      <template v-slot:activator="{}">
        <v-btn
          color="black"
          fab
          outlined
          small
          @click.prevent="logout"
        >
          <v-icon color="black">
            fa-sign-out-alt
          </v-icon>
        </v-btn>
      </template>
      <span>Logout</span>
    </v-tooltip>
    <v-tooltip left v-if="isLoggedIn" :value="showTooltip">
      <template v-slot:activator="{}">
        <v-btn
          color="grey"
          fab
          outlined
          small
          to="/user/profile"
        >
          <v-icon color="grey">
            fa-user-cog
          </v-icon>
        </v-btn>
      </template>
      <span>User Dashboard</span>
    </v-tooltip>
    <v-tooltip left v-if="isAdmin" :value="showTooltip">
      <template v-slot:activator="{}">
        <v-btn
          color="amber"
          fab
          outlined
          small
          to="/admin/reports"
        >
          <v-icon color="amber">
            fa-tools
          </v-icon>
        </v-btn>
      </template>
      <span>Admin Dashboard</span>
    </v-tooltip>
    <v-tooltip left :value="showTooltip">
      <template v-slot:activator="{}">
        <v-btn
          :color="hasPremium ? 'teal':'icetimeblue'"
          fab
          outlined
          small
          to="/app/map"
        >
          <v-icon :color="hasPremium ? 'teal':'icetimeblue'">
            fa-globe-americas
          </v-icon>
        </v-btn>
      </template>
      <span>Spot Mapper</span>
    </v-tooltip>
    <v-tooltip left :value="showTooltip">
      <template v-slot:activator="{}">
        <v-btn
          :color="hasPremium ? 'teal':'icetimeblue'"
          fab
          outlined
          small
          to="/app/radar"
        >
          <v-icon :color="hasPremium ? 'teal':'icetimeblue'">
            fa-walking
          </v-icon>
        </v-btn>
      </template>
      <span>Lake Ice Travel</span>
    </v-tooltip>    
    <v-tooltip left :value="showTooltip">
    <template v-slot:activator="{}">
        <v-btn
          color="icetimeblue"
          fab
          outlined
          small
          to="/app/leaderboard/all"
        >
          <v-icon color="icetimeblue">
            fa-medal
          </v-icon>
        </v-btn>
      </template>
      <span>Leaderboard</span>
    </v-tooltip>
    <v-tooltip left :value="showTooltip">
      <template v-slot:activator="{}">
        <v-btn
          color="icetimeblue"
          fab
          outlined
          small
          to="/ras"
        >
          <v-icon color="icetimeblue">
            fa-exclamation-triangle
          </v-icon>
        </v-btn>
      </template>
      <span>Regional Advisory Service</span>
    </v-tooltip>
    <v-tooltip left :value="showTooltip">
      <template v-slot:activator="{}">
        <v-btn
          color="icetimeblue"
          fab
          outlined
          small
          to="/"
        >
          <v-icon color="icetimeblue">
            fa-home
          </v-icon>
        </v-btn>
      </template>
      <span>Home</span>
    </v-tooltip>
  </v-speed-dial>
</template>

<script>
import { mapGetters,mapState } from 'vuex'

export default {
  computed: {
    hasPremium() {
      return this.user.status && this.user.status > 1
    },
    ...mapGetters('user', {
      isAdmin: 'isAdmin',
      isLoggedIn: 'isLoggedIn'
    }),
    ...mapState('user', {
      user: state => state.user
    }),
    ...mapState(['showTooltip']),
  },
  data() {
    return {
      fab: false,
      // showTooltip: false,
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout')
      .then(() => {
        this.$router.push('/')
        this.$router.go()
      })
    },
    menu_clicked() {
      setTimeout(() => {
        // this.showTooltip = !this.showTooltip
        this.$store.dispatch('setShowTooltip', !this.showTooltip)
      }, 250) 
    }
  },
  mounted() {
    
  },
  setup() {
  },
}
</script>
