<template>
  <v-container>
    <p class="text-h5 pl-5 pt-8">Ice Time&trade; Fundamentals</p>
    <p class="text-subtitle px-5 pb-3">The top things to know about Ice Time&trade;</p>
    <v-layout class="px-4" cols="12" row>
      <v-flex class="pa-4" md12 lg10 xl8>
        <v-card class="pa-3" height="100%">
          <v-card-title><v-icon class="pr-2" color="#2f4782">mdi-numeric-1-circle</v-icon>Regional Subscriptions</v-card-title>
          <v-card-text class="text-body-1">
            As climate effects where and how we travel on lake ice, the use of Ice Time&trade; is divided into geographic regions. You can use Ice Time&trade; in any region you license when you subscribe.  You can view the region boundaries when you evaluate Ice Time. You will receive an email the moment each image is published. The email notification lets you know new satellite imagery is available for the region(s) you have subscribed to. If you are an Android mobile user, you will also receive a push notification.
          </v-card-text>
          <v-card-text>
            <v-chip small><v-icon small class='mr-1'>mdi-file-document-outline</v-icon> Tip:</v-chip> Read the Lake Ice Reports first. If it is the first time you are accessing new lake ice content, consider reading the short lake ice report for each footprint first. This report covers the main aspects of interest that are widely applicable to the image area and compares to the preceding image to note change. You can launch your map from within the lake ice report. Afterwards, as you review your ice hazard map, you can access the lake ice report by selecting the lake ice report icon found to the right.
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex class="pa-4" md12 lg10 xl8>
        <v-card class="pa-3">
          <v-row>
            <v-col cols="12" xs="12" sm="6" class="ma-0 pa3">
              <v-card-title><v-icon class="pr-2" color="#2f4782">mdi-numeric-2-circle</v-icon>Satelite Footprints</v-card-title>
              <v-card-text class="text-body-1">
                Each geographic region has areas that are targeted with radar satellite imagery. Each satellite image area has a footprint on the ground. The footprints aim to cover the most popular and rewarding areas anglers visit. Over time, as the Ice Time&trade; community grows, the additional footprints added will be decided by the Ice Time&trade; Users.
              </v-card-text>
              <v-card-text>
                <v-chip small><v-icon small class='mr-1'>mdi-file-document-outline</v-icon> Tip:</v-chip> The footprints icon (<v-icon small>mdi-foot-print</v-icon>) will appear at the top right corner of every applicable map. Clicking on this component will list the footprints available to your current region.
              </v-card-text>
            </v-col>
            <v-col cols="12" xs="12" sm="6" class="ma-0 pa-0">
              <v-img 
                src="img/docs/fundamentals_footprint.png"
                height="100%"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-flex>
      <v-flex class="pa-4" height="100%" md12 lg10 xl8>
        <v-card class="pa-3">
          <v-card-title><v-icon class="pr-3" color="#2f4782">mdi-numeric-3-circle</v-icon>Monitoring Over Time</v-card-title>
          <v-card-text class="text-body-1">
            When active radar monitoring begins in your region, Ice Time&trade; will build a time series of three images for each footprint in that region. After that, each new image added bumps the oldest image off. This means you have a time series of three images to show change throughout the ice period. If you subscribe to more than one region then you have at your fingertips ice information from at least 15 images from 5 or more footprints. This is the core message of this platform.
          </v-card-text>
          <v-card-text class="text-body-1">
            These time series image sets are how you learn about lake ice dynamics in the areas you travel. Careful inspection of these images each year is how you gain an understanding of the evolution of lake ice cover. This experience, gained mostly from using Ice Time&trade; from the comfort of home using a large monitor, is how you become more situationally aware on the lake. When you are on the ice that’s where Ice Time&trade; makes your mobile cellular devices shine.
          </v-card-text>
          <v-card-text>
            <v-chip small><v-icon small class='mr-1'>mdi-file-document-outline</v-icon> Tip:</v-chip> When you view images during the monitoring period first select your region, then select your footprint, then use the image advance tools. Use the date shown between the forward and reverse arrows at the bottom of the screen to select a specific image from a pick list to move through images in time use the forward and backward arrow buttons. 
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex class="pa-4" md12 lg10 xl8>
        <v-card class="pa-3">
          <v-row>
            <v-col cols="12" xs="12" sm="6" class="ma-0 pa-3">
              <v-card-title><v-icon class="pr-3" color="deep-orange">mdi-numeric-4-circle</v-icon>Demo Imagery</v-card-title>
              <v-card-text class="text-body-1">
                Most people evaluate Ice Time&trade; in the fall season before imaging begins, so we provide sample data from a previous year. The demo content shown varies by region and by the stage of ice development or decay, so make sure you look at all regions to appreciate the full range of conditions shown. The demo content typically offers smaller footprints than those published to subscribers. The licensed version of Ice Time&trade; also has more functions than the demo mode does.
              </v-card-text>
              <v-card-text>
                <v-btn 
                  to="/demo/reports" 
                  style="text-transform: none!important;"
                  color="deep-orange" 
                  dark 
                  rounded 
                  small
                >
                  Go to Demo Ice Reports
                </v-btn>
              </v-card-text>
            </v-col>
            <v-col cols="12" xs="12" sm="6" class="ma-0 pa-0">
              <v-img 
                src="img/docs/fundamentals_demo.png"
                height="100%"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-flex>
      <v-flex class="pa-4" height="100%" md12 lg10 xl8>
        <v-card class="pa-3">
          <v-card-title><v-icon class="pr-3" color="#2f4782">mdi-numeric-5-circle</v-icon>Due Diligence</v-card-title>
          <v-card-text class="text-body-1">
            A satellite-based tool cannot replace the need for your essential on-ice safety practises. Ice Time is designed to complement and extend your lake ice know how. As your skills grow make sure you improve your on-ice practises and use of Ice Time&trade; together. Remember, your safety and that of your guests depends only on you and the choices you make.
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex class="px-4 pt-4 pb-12" height="100%" md12 lg10 xl8>
        <v-card class="pa-3">
          <v-card-title><v-icon class="pr-3" color="#2f4782">mdi-star-circle-outline</v-icon>About the Use of Ice Time&trade; Software</v-card-title>
          <v-card-text class="text-body-1">
            <b>You can use Ice Time&trade; on all of your devices but not concurrently.</b> We recommend you close Ice Time&trade; after use so the data in your next session will be the most current. If you left Ice Time&trade; running at home and you are now on the lake, no worries. Ice Time&trade; will make your current session the active one.
          </v-card-text>
          <v-card-text class="text-body-1">
            <b>We recommend all Ice Time&trade; users subscribe to unlimited cellular data plans</b>, which are common today. If you are not on such a plan, you must monitor your cellular data consumption closely to avoid going over the limits of your plan.
          </v-card-text>
          <v-card-text class="text-body-1">
            You have already read and understand the End User License Agreement. Still, we politely remind you that <b>redistribution or transfer of Ice Time&trade; content to any other platform or type of media may lead to inappropriate use by others</b>. This may increase the risk and consequences of their lake ice travel. Said simply, spreading Ice Time&trade; content around leaves you open to litigation. Such use violates our agreement. 
          </v-card-text>
          <v-card-text class="text-body-1">
            <b>It is important that you keep your account information private.</b> If your account information is shared you may be kept from using it.
          </v-card-text>
          <v-card-text class="text-body-1">
            <b>Keep your username and password somewhere safe so that you have access to it should you forget it. If you lose your account password, we cannot help you because we never have access to it.</b>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {
    }
  },
}
</script>
