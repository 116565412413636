<template>
  <v-container class="mx-0 px-0 py-0 " style="max-width: none;">

    <v-tabs
        v-model="current_feature"
        active-class="custom-active"
        background-color="grey lighten-1"
        center-active
        class="fill-height"
        color="white"
        :height="$vuetify.breakpoint.lgAndUp ? '100%':'auto'"
        :show-arrows="$vuetify.breakpoint.mdAndDown"
        :hide-slider="!$vuetify.breakpoint.lgAndUp"
        slider-color="grey"
        slider-size="10"
        :vertical="$vuetify.breakpoint.lgAndUp"
      >
      <v-tab
          v-for="tab in tabs"
          :class="$vuetify.breakpoint.lgAndUp ? 'justify-start text-capitalize black--text pl-8 pr-12':'text-capitalize black--text text-subtitle-2 py-3'"
          :key="tab.name"
        >
        {{ tab.name }}
        <v-icon v-if="tab.done" class="mx-2" icon color="green">
          mdi-check-bold
        </v-icon>
      </v-tab>
      <v-tab-item :value="0">
        <Introduction @next-section="nextSection"/>
      </v-tab-item>
      <v-tab-item :value="1">
        <Backstory @next-section="nextSection" />
      </v-tab-item>
      <v-tab-item :value="2">
        <Satellite @next-section="nextSection" />
      </v-tab-item>
      <v-tab-item :value="3">
        <Surface @next-section="nextSection" />
      </v-tab-item>
      <v-tab-item :value="4">
        <Quiz @next-section="nextSection" @clear-done="clearDone" />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>

import Introduction from './components/Introduction.vue';
import Backstory from './components/Backstory.vue';
import Satellite from './components/Satellite.vue';
import Surface from './components/Surface.vue';
import Quiz from './components/Quiz.vue';
import { mapGetters } from 'vuex';
// import { mapState } from 'vuex';

export default {
  components: {
    Introduction,
    Backstory,
    Satellite,
    Surface,
    Quiz
  },
  computed: {
    // ...mapState('discovery', {
    //   interest: state => state.interest
    // })
    ...mapGetters('user', {
      isTrialUser: 'isTrialUser'
    })
  },
  created() {
  },
  data() {
    return {
      current_feature: 0,
      tabs: [
        {name: 'Introduction', done: false },
        {name: 'Backstory', done: false },
        {name: 'About Satellite Radar', done: false },
        {name: 'Surface and Volume', done: false },
        {name: 'Quiz', done: false },
      ]
    }
  },
  methods: {
    nextSection() {
      this.tabs[this.current_feature].done = true
      if (this.current_feature < this.tabs.length - 1) {
        this.current_feature++
      }
    },
    clearDone(section) {
      this.tabs.find((e) => e.name == section).done = false
    },
  },
  mounted() {
    this.$store.dispatch('setMode', 'academy')
    this.$store.dispatch('checkToken')
  },
}
</script>

<style scoped>
>>>.backforth {
  position: absolute;
  top: 0;
  right: 0;
}

>>>.custom-active {
  background-color: #9E9E9E!important;
}
</style>