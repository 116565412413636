<template>
  <v-container>
    <v-scroll-y-reverse-transition mode="out-in" :hide-on-leave="true">
      <MGMTManualAdvisoriesTable />
    </v-scroll-y-reverse-transition>
    <v-scroll-y-reverse-transition mode="out-in" :hide-on-leave="true">
      <MGMTReportAdvisoriesTable />
    </v-scroll-y-reverse-transition>
  </v-container>
</template>

<script>
import MGMTManualAdvisoriesTable from '../components/MGMTManualAdvisoriesTable.vue'
import MGMTReportAdvisoriesTable from '../components/MGMTReportAdvisoriesTable.vue'

export default {
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("setTab", "advisories");
  },
  components: { 
    MGMTManualAdvisoriesTable,
    MGMTReportAdvisoriesTable,
  }
}
</script>