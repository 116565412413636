<template>
  <v-container>
  <v-card
      v-if="!image_processing"
      class="mt-5 mb-3 pa-2" 
      color="grey lighten-3" 
      ref="spot_catches"
      rounded
      flat
    >
    <v-card-title 
        class="mb-2 pt-0 pb-2 px-2 black--text"
        ref="fish_textarea"
      >
        Add Catch
      </v-card-title>
      <v-card 
        class="ma-2" 
        color="grey lighten-2" 
        flat
      >
      <v-autocomplete 
        v-model="selectedSpecies"
        :items="species"
        item-text="common_name"
        item-value="id"
        label="Species"
        hide-details
        class="pb-2 ma-2"
        >
      </v-autocomplete>
    </v-card>
  <v-card-subtitle 
    class="mb-2 pa-2 text-subtitle-2 red--text"
    ref="fish_textarea"
    v-if="error"
  >
    *Must select a species and image*
  </v-card-subtitle>
  <v-card-subtitle class="mb-0 pa-2 text-subtitle-2 black--text" v-if="(joined_comps.length>0)">
    Competition: 
  </v-card-subtitle>
  <v-autocomplete 
    v-if="(joined_comps.length>0)"
    v-model="competition"
    :items="joined_comps"
    item-text="name"
    item-value="id"
    dense
    no-data-text="Not Registered for any Competitions"
    @change="compChanged"
    >
  </v-autocomplete>
  <!-- <v-card-subtitle 
    class="mb-2 pa-2 text-subtitle-2 red--text text-caption"
    ref="fish_textarea"
    v-if="require_photo"
  >
    *Competition Requires Photo*
  </v-card-subtitle> -->
    <v-card-actions>
      <v-btn
          class="py-3 px-2"
          color="grey" 
          dark
          elevation="0" 
          width="100%"
          @click="extra_options"
      > {{ extraOptions ? 'Less Options' : 'More Options'}}
      </v-btn>
    </v-card-actions>
      <v-container v-if="extraOptions" >
        <v-row class="pt-2">
          <v-col class="px-2" cols="12" sm="6">
            <v-text-field
              dense
              hide-details
              label="Length"
              v-model="length"
              type="number"
              suffix="cm.">
            </v-text-field>
          </v-col>
          <v-col class="px-2" cols="12" sm="6">
            <v-text-field
              dense
              hide-details
              label="Weight"
              v-model="weight"
              type="number"
              suffix="lbs.">
            </v-text-field>
          </v-col>
          <v-col class="px-2" cols="12" sm="6">
            <v-text-field
              dense
              hide-details
              label="Depth"
              v-model="depth"
              type="number"
              suffix="m.">
            </v-text-field>
          </v-col>     
          <v-col class="px-1" cols="12" sm="6">
            <v-checkbox
              dense
              hide-details            
              v-model="wasReleased"              
            >
            <template v-slot:label>
              <div class="text-body-2">Catch Released?</div>
            </template>
            </v-checkbox>
          </v-col>           
          <v-col class="px-0" >
            <v-card-text class="py-0 px-2">
                <div class="text-body-1">Catch Photo</div>
                <p class="text-h6"></p>                            
            </v-card-text>  
            <div class="test">
              <!-- <Camera @newPhoto="newPhoto"></Camera> -->
              <!-- <div class="wrapper">
                <video
                    class="video"
                    :class="facingMode === 'user' ? 'front' : ''"
                    ref="video"
                ></video>
                <canvas style="display: none" ref="canva" ></canvas>

                <button
                    v-if="videoDevices.length > 1"
                    class="button is-rounded is-outlined switch-button"
                    @click="switchCamera"
                    :disabled="switchingCamera"
                >
                    <v-icon>mdi-camera-flip</v-icon>
                </button>
                <div class="photo-button-container">
                    <button @click="TakePhoto">
                        <v-icon left>mdi-camera</v-icon>
                    </button>
                </div>
            <photos-gallery class="gallery" :photos="photos" />
            </div> -->
            
            <image-uploader
                id="camera"
                ref="fileUpload"
                :capture="camera_value"
                :debug="0"
                :maxWidth="maxValues"
                :maxSize="maxSize"
                :maxHeight="maxValues"
                :quality="1"
                :autoRotate=false
                outputFormat="verbose"
                :preview=false
                :className="['fileinput', { 'fileinput--loaded' : hasImage }]"
                @input="setImage"
              >
                <label for="camera" slot="upload-label">
                  <div class="text-center mb-2 pa-3" style="border:1px solid black;border-radius: 4px;width:200px;margin:0 auto;background-color: white;font-weight:500;">
                        <div>OPEN CAMERA<v-icon style="margin-left:3px;color:black">mdi-camera</v-icon></div>
                  </div>
                </label>
              </image-uploader>

              <image-uploader
                id="fileSelect"
                ref="fileUpload2"
                :debug="0"
                :maxWidth="maxValues"
                :maxSize="maxSize"
                :maxHeight="maxValues"
                :quality="1"
                :autoRotate=false
                outputFormat="verbose"
                :preview=false
                :className="['fileinput', { 'fileinput--loaded' : hasImage }]"
                @input="setImage"
              >
                <label for="fileSelect" slot="upload-label">
                  <div class="text-center mb-2 pa-3" style="border:1px solid black;border-radius: 4px;width:200px;margin:0 auto;background-color: white;font-weight:500;">
                        <div>SELECT PHOTO<v-icon style="margin-left:3px;color:black">mdi-camera</v-icon></div>
                  </div>
                </label>
              </image-uploader>
              <div v-if="hasImage">
                <div v-if="currentImage && $vuetify.breakpoint.xs">
                  <v-img :src="currentImage.dataUrl"/>
                </div>
                <div v-else-if="currentImage">
                    <v-img :src="currentImage.dataUrl"/>
                </div>
                <div v-if="showMessage">
                    <v-alert :type="messageType">
                        {{message}}
                    </v-alert>
                </div>                     
              </div>

            </div>
          </v-col>                 
        </v-row>
        <!-- <v-file-input
          show-size
          label="Select Image"
          variant="filled"
          accept="image/*"
          prepend-icon="mdi-camera"
          @change="selectImage"
        ></v-file-input> -->

      </v-container>
      <v-alert 
        v-if="err_message != ''" 
        class="mt-2 mb-0" 
        type="error" 
        dense
      >
        {{err_message}}
      </v-alert>
      <v-alert 
        v-if="!required_items" 
        class="mt-2 mb-0" 
        type="error" 
        dense
      >
        {{required_error_msg}}
      </v-alert>
      <v-card-actions>
        
        <v-btn 
          elevation="0"
          x-large
          dark
          color="indigo"
          :loading="loading"
          width="100%"
          @click="save_fish"
        >
          save catch
        </v-btn>
        <!-- <v-row>
                  <v-btn 
          elevation="5"
          rounded
          dark
          color="indigo"
          :loading="loading"
          width="100%"
          @click="update_regional"
        >
          {{ err_message != '' ? err_message:'Update Regional' }}
        </v-btn>
        </v-row> -->

      </v-card-actions>
  </v-card>
  <!-- <v-card
    v-else-if="loading_delay"
  >
  <v-data-table
    dense
    :headers="headers"
    :items="tmp_data"
    :items-per-page="-1"
    class="elevation-1"
    :hide-default-footer=true
  >
  <template v-slot:item.ai_status="{ item }">
    <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
  </template>
  <template v-slot:item.measurement_status="{ item }">
    <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
  </template>
  <template v-slot:item.ai_species="{ item }">
    <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
      
  </template>
  <template v-slot:item.ai_length="{ item }">
    <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
      
  </template>
  </v-data-table>
  </v-card> -->
  <v-card
      v-else-if="(image_processing || !reupload_mode) && !loading_delay"
      class="mt-5 mb-3 mx-3 pa-2" 
      color="grey lighten-3" 
      ref="spot_catches"
      rounded
    >
    <v-card-title 
        class="mb-2 pt-0 pb-2 px-2 black--text"
        ref="fish_textarea"
      >
        Catch Upload Status
      </v-card-title>
      <v-card 
        class="ma-2" 
        color="grey lighten-2" 
        flat
      >
        <v-data-table
          dense
          :headers="headers"
          :items="selected_catch"
          :items-per-page="-1"
          class="elevation-1"
          :hide-default-footer=true
        >
        <template v-slot:item.ai_status="{ item }">
          <v-icon v-if="item.ai_status==2" large color="green">mdi-checkbox-marked</v-icon>
          <v-icon v-else-if="item.ai_status==1" large color="red">mdi-close-box</v-icon>

          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          ></v-progress-circular>
            
        </template>
        <template v-slot:item.measurement_status="{ item }">
          <v-icon v-if="item.measurement_status==2 && item.qr_status==2 && item.board_status==2 && item.fish_status==2" large color="green">mdi-checkbox-marked</v-icon>
          <v-icon v-else-if="item.measurement_status==1 || item.qr_status==1 || item.board_status==1 || item.fish_status==1" large color="red">mdi-close-box</v-icon>

          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          ></v-progress-circular>
            
        </template>
        <template v-slot:item.ai_species="{ item }">
          <v-icon v-if="item.ai_species !== -1 && item.ai_species !== null" large color="green">mdi-checkbox-marked</v-icon>
          <!-- Need adding temp code to display an error if we dont get a responce with the Species ID but the Proccessing is done
           Should not need this as the API should receive -1 in this case -->
          <v-icon v-else-if="item.ai_species== -1 || detected_species_failed || (item.measurement_status==1 || item.qr_status==1 || item.board_status==1 || item.fish_status==1) || (item.measurement_status==2 && item.qr_status==2 && item.board_status==2 && item.fish_status==2)" 
            large color="red">mdi-close-box
          </v-icon>

          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          ></v-progress-circular>
            
        </template>
        <template v-slot:item.ai_length="{ item }">
          <div v-if="item.ai_length !== null" large color="green">{{ Math.round(item.ai_length * 1000) / 1000 }}</div>

          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          ></v-progress-circular>
            
        </template>
        <template v-slot:item.qr_detected="{ item }">
          <div v-if="item.qr_detected !== null" large color="green">{{ Math.round(item.qr_detected * 1000) / 1000 }}</div>

          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          ></v-progress-circular>
            
        </template>
        </v-data-table>
        <v-alert 
          v-if="error_reason != null" 
          class="mt-2 mb-0" 
          type="error" 
          dense
        >
          {{error_reason}}
        </v-alert>
      </v-card>
      <v-btn
        elevation="0"
        small
        dark
        color="indigo"
        class="mb-4 mt-2"
        :loading="loading"
        width="100%"
        @click="closeCatchUpdate()"
      >
        Close
      </v-btn>
      <!-- <v-btn
        elevation="0"
        small
        dark
        color="indigo"
        class=""
        :loading="loading"
        width="100%"
        @click="reuploadMode()"
      >
        Re-Upload
      </v-btn> -->
    </v-card>
    <v-card
      v-else-if="loading_delay"
      class="mt-5 mb-3 mx-3 pa-2" 
      color="grey lighten-3" 
      ref="spot_catches"
      rounded
    >
      <v-card-title 
        class="mb-2 pt-0 pb-2 px-2 black--text"
        ref="fish_textarea"
      >
        Catch Upload Status
      </v-card-title>
      <v-card 
        class="ma-2" 
        color="grey lighten-2" 
        flat
      >
      <v-data-table
          dense
          :headers="headers"
          :items="tmp_data"
          :items-per-page="-1"
          class="elevation-1"
          :hide-default-footer=true
        >
        <template v-slot:item.ai_status="{ item }">
          <v-icon v-if="item.ai_status==2" large color="green">mdi-checkbox-marked</v-icon>
          <v-icon v-else-if="item.ai_status==1" large color="red">mdi-close-box</v-icon>

          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          ></v-progress-circular>
            
        </template>
        <template v-slot:item.measurement_status="{ item }">
          <v-icon v-if="item.measurement_status==2 && item.qr_status==2 && item.board_status==2 && item.fish_status==2" large color="green">mdi-checkbox-marked</v-icon>
          <v-icon v-else-if="item.measurement_status==1 || item.qr_status==1 || item.board_status==1 || item.fish_status==1" large color="red">mdi-close-box</v-icon>

          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          ></v-progress-circular>
            
        </template>
        <template v-slot:item.ai_species="{ item }">
          <v-icon v-if="item.ai_species !== -1 && item.ai_species !== null" large color="green">mdi-checkbox-marked</v-icon>
          <v-icon v-else-if="item.ai_species== -1 || detected_species_failed" large color="red">mdi-close-box</v-icon>

          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          ></v-progress-circular>
            
        </template>
        <template v-slot:item.ai_length="{ item }">
          <div v-if="item.ai_length !== null" large color="green">{{ Math.round(item.ai_length * 1000) / 1000 }}</div>

          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          ></v-progress-circular>
            
        </template>
        <template v-slot:item.qr_detected="{ item }">
          <div v-if="item.qr_detected !== null" large color="green">{{ Math.round(item.qr_detected * 1000) / 1000 }}</div>

          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          ></v-progress-circular>
            
        </template>
        </v-data-table>
        <!-- <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular> -->
      </v-card>
    </v-card>
  </v-container>
</template>
  
<script>
import { mapState } from 'vuex';
import ImageUploader from 'vue-image-upload-resize'
// import PhotosGallery from "../../leaderboard/components/PhotosGallery.vue";
// import Camera from './Camera.vue';
// import Camera from './Camerav2.vue';
  
  export default {
    components: { 
      ImageUploader,
      // PhotosGallery,
      // Camera
    },
    computed: {
      ...mapState('spot_mapper', {
        selected_fishing_spot: state => state.selected_fishing_spot,
        species: state => state.species_with_favorite,
        selected_catch: state => state.selected_catch,
        joined_comps: state => state.joined_comps
      }),
      ...mapState('leaderboard', {
        competitions: state => state.competitions,
      }),
      ...mapState('map', {
        current_region: state => state.current_region,
      }),  
    },
    created() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.panToTextarea()
        })
      })
      
    },
    data() {
      return {
        selectedSpecies: '',
        // fishComment: '',
        length: '',
        weight: '',
        depth: '',
        competition: null,
        wasReleased: '',
        err_message: '',
        error: false,
        loading: false,
        extraOptions: false,
        catch_error: false,
        catch_id: undefined,
        require_photo: true,
        
        currentImage: undefined,
        previewImage: undefined,
        showMessage: false,
        message: "",
        messageType: "",

        image: null,
        hasImage: false,
        originalImage: null,
        blobImage: null,

        image_processing: false,
        headers: [
          { text: 'Upload Status', value: 'ai_status' },
          { text: 'Calculated Length', value: 'ai_length' },
          { text: 'QR Count', value: 'qr_detected' },
          { text: 'image Processing', value: 'measurement_status' },
          { text: 'Species Detection', value: 'ai_species' },
        ],
        tmp_data:[{
            "ai_length": null,
            "qr_detected": null,
            "ai_status": null,
            "measurement_status": null,
            "ai_species": null,
        }],
        temp_data:[{ai_length: 14, qr_detected: 3, ai_status: 2, board_status: 2, qr_status: 2, fish_status: 2, measurement_status: 1}],
        current_catch:[{}],
        interval: null,
        upload_complete: false,
        upload_failed: false,
        reupload_mode: false,
        loading_delay: true,
        error_reason: null,

        temp_region: 16,
        maxValues: 1920, //This is to set the max size (height, width) of the image in Pixels(?), we must have this as the defaults will shrink images and lose quality
        maxSize: 10, //This is in MP, and roughly converts to just below 10 MB at 12 bit depth, but the program does not seem to be converitng as the math should line up...
        detected_species_failed: false,
        // camera_value: "camera",
        camera_value: "environment",

        // photos: {},
        // mediaStream: null,
        // videoDevices: [],
        // facingMode: "environment",
        // counter: 0,
        // switchingCamera: false,
        photo_value:{},
        photo_dataUrl : "",
        required_items: true,
        required_error_msg: "Species, Length, and a Photo are all required.",

      };
    },
    methods: {
      update_regional(){
        let region = this.temp_region
        if(this.current_region == null){
          region = this.current_region
        }
        let data = {
          region: region,
          season_id: '1'
        }
        this.$store.dispatch('spot_mapper/updateRegional', data)
      },
      async newPhoto(value){
        this.photo_value = value
        this.photo_dataUrl = value.src
        this.setImage({dataUrl: value.src})
        // console.log(this.photo_dataUrl)
        this.hasImage = true
        this.require_photo = false
        // fetch(value.src)
        //   .then(res => res.blob())
        //   .then(blob => {
        //     console.log(blob)
        // })
      },
      checkRequired(){
        this.required_items = true
        if(this.length < 1){
          this.required_items = false
        }
        if(!this.selectedSpecies){
          this.required_items = false
        }
        if(!this.hasImage){
          this.required_items = false
        }
      },
      updateImageProcess(){
        // console.log("in update Image Process")
        if(this.catch_id !== undefined && this.image_processing){
          this.$store.dispatch('spot_mapper/getCatch', this.catch_id)
          .then(() =>{
            // console.log(this.selected_catch[0].ai_status)
            // console.log(this.selected_catch[0])
            if(this.selected_catch[0].ai_status === "2" && this.selected_catch[0].measurement_status === "2"){
              // console.log("image processed")
              this.uploadComplete()
              if(this.interval !== null){
                clearInterval(this.interval)
                this.interval = null
              }
            }
            else if(this.selected_catch[0].ai_status === "1" || this.selected_catch[0].measurement_status === "1" ||
            this.selected_catch[0].board_status === "1" || this.selected_catch[0].qr_status === "1"  || this.selected_catch[0].fish_status === "1"){
              //Set option to allow for re-upload.
              switch ("1"){
                case this.selected_catch[0].ai_status:
                  this.error_reason = "The image could not be uploaded."
                  break
                case this.selected_catch[0].board_status:
                  this.error_reason = "The image did not contain an ALB Bump Board."
                  break
                case this.selected_catch[0].qr_status:
                  this.error_reason = "The image does not contain good quality QR codes."
                  break
                case this.selected_catch[0].fish_status:
                  this.error_reason = "A fish could not be found in this image."
                  break
                case this.selected_catch[0].measurement_status:
                  this.error_reason = "The fish could not be measured in this image."
              }
              this.uploadFailed()
              if(this.interval !== null){
                // console.log("clear Interval")
                clearInterval(this.interval)
                this.interval = null
              }
            }
            else{
              clearInterval(this.interval)
              this.interval = null
              this.interval = setTimeout(()=> {
                this.updateImageProcess()
              }, 2000)
            }
          })

        }
      },
      loadingDelay(){
        this.loading_delay = true
        // console.log("Delay: " + this.loading_delay)
        setTimeout(()=> {
          this.loading_delay = false
          // console.log("Delay: " + this.loading_delay)
        }, 4000)
      },
      uploadComplete(){
        this.upload_complete = true
        this.upload_failed = false
        this.error_reason = null
        if(this.selected_catch[0].ai_species == null){
          this.detected_species_failed = true
        }
      },
      uploadFailed(){
        this.upload_complete = true
        this.upload_failed = true
      },
      closeCatchUpdate(){
        this.$emit('new-catch')
        this.$store.selected_catch = []
        this.image_processing = false
        this.reupload_mode = false
        this.upload_complete = false
        this.upload_failed = false
        this.catch_id = undefined
        this.photo_dataUrl = ""
        this.photo_value = {}
      },
      extra_options(){
        // this.fishComment= ''
        // this.length= ''
        // this.weight= ''
        // this.depth= ''
        // this.competition= null
        // this.wasReleased= ''

        this.extraOptions = !this.extraOptions
      },
      panToTextarea() {
        this.$vuetify.goTo(this.$refs.fish_textarea, { container: this.$refs.spot_catches })
      },
      compChanged(){
        this.require_photo = true
        let index = this.joined_comps.findIndex(item => item.id === this.competition)
        // console.log(this.competition)
        // console.log(index)
        // console.log(this.joined_comps[index])
        if(this.joined_comps[index].require_photo === "1"){
          this.require_photo = true
        }
        // console.log(this.require_photo)
      },
      getDBTime(date){
        let localdate = new Date(date)
        return (localdate.toISOString().slice(0, -1))
      },
      save_fish() {
        this.checkRequired()
        this.error_reason = null
        if(this.reupload_mode){
          this.reuploadFile(this.catch_id)
        }else if(this.required_items){
          this.error = false
          this.upload_complete = false
          this.upload_failed = false
          if(this.species === ''){
            this.error = true
          } else {
            if(this.require_photo === true && !this.photo_value){
              // console.log(this.currentImage)
              // this.error = true
            } else{
              this.loading = true
              let date = new Date()
              let fixed_date = this.getDBTime(date)
              let data = {
                species: this.selectedSpecies,
                date: fixed_date,
                // comment: this.fishComment,
                length: this.length,
                weight: this.weight,
                water_depth: this.depth,
                fishing_spot: this.selected_fishing_spot,
                competition: this.competition,
                wasReleased: this.wasReleased // true/false
              }
              // console.log(data)
              this.$store.dispatch('spot_mapper/recordCatch', data)
              .then((response) => {
                if(this.competition !== null){
                  this.$store.dispatch('spot_mapper/setAddedToComp', this.competition)
                }
                this.catch_error = false
                this.catch_id = response["data"]
                // console.log("Response is: " + this.catchId)
                // this.message = response["data"]
                // if(this.message.includes("Error")) {
                //   console.log(this.message)
                //   this.messageType = "error"
                //   this.catch_error = true
                // }else{
                  // console.log(this.message)
                  // this.$emit('new-catch')
                  // this.messageType = "success"
                  // this.catch_error = false
                  // }
              })
              .catch((err) => {
                this.err_message = 'Retry: ' + err
                this.catch_error = true
                // console.log(this.err_message)
                this.catch_id = undefined
              })
              .finally(() => {
                this.loading = false
                if(!this.catch_error){
                  this.messageType = "success"  
                  // console.log("The Response is: " + this.catch_id)
                  if(this.photo_value != undefined && !this.catch_error){
                    // console.log("trigering photo upload")
                    this.loadingDelay()
                    this.image_processing = true
                    this.interval = setTimeout(()=> {
                      this.updateImageProcess()
                    }, 2000)
                    this.uploadFile(this.catch_id)
                  }
                  else{
                    this.$emit('new-catch')
                  }
                }
                this.update_regional()
              })
            }
            
          }
        }
        },
        setImage(output) {
          this.hasImage = true
          this.currentImage = output
          if(typeof this.$refs.fileUpload2.$el.children[1].files[0] != 'undefined') {
            this.originalImage = this.$refs.fileUpload.$el.children[1].files[0]
          }
          else if(typeof this.$refs.fileUpload2.$el.children[1].files[0] != 'undefined') {
            this.originalImage = this.$refs.fileUpload2.$el.children[1].files[0]
          }
          // console.log(this.currentImage)
          // console.log(this.currentImage.dataUrl)
          // console.log('Exif', output.exif)
          // console.log('Original Image:', this.originalImage)
          // this.previewImage = output.dataUrl
          this.require_photo = false
        },
        uploadFile(catchId) {
          this.detected_species_failed = false
          this.reupload_mode = false
          var name
          var type

          if(this.currentImage != undefined && !this.showMessage) {
            let formData = new FormData()
            // var file = new File()
            fetch(this.currentImage.dataUrl)
            .then(response => response.blob())
            .then(blob => {
                if (this.currentImage.hasOwnProperty('info')) {
                  name = this.currentImage.info.name
                  type = this.currentImage.info.type
                }
                else {
                  name = (String(catchId) + "image.jpeg")
                  type = blob.type
                }

                var file = new File([blob], name, {type: type})

                formData.append('file', file)
                formData.append('catch_id', catchId)
                this.$store.dispatch('leaderboard/uploadImage', formData)
                  .then((response) => {
                    //**Need to set a default loading image to prevent confusion. Set it on a small timeout, make new function for it.
                    this.image_processing = true
                    this.currentImage = undefined
                    this.hasImage = false
                    this.message = response["data"]   
                    if(this.message.includes("Error")) {
                        this.messageType = "error"
                        this.showMessage = true
                    }
                    else {
                        this.messageType = "success"
                    }
                  })
                  .catch((err) => {
                      // console.log(err)
                      this.err_message = err
                  })
            })
          }     
        },
        // uploadFile(catchId) {
        //   this.detected_species_failed = false
        //   this.reupload_mode = false
        //   if(this.photo_value != undefined && !this.showMessage) {
        //     let formData = new FormData()
        //     // var file = new File()
        //     fetch(this.photo_value.src)
        //     .then(response => response.blob())
        //     .then(blob => {
        //       // console.log(blob)
        //         var name = (String(catchId) + "image.jpeg")
        //         var type = blob.type
        //         var file = new File([blob], name, {type: type})
        //         //newPhoto
        //         formData.append('file', file)
        //         formData.append('catch_id', catchId)
        //         // console.log(formData)
        //         this.$store.dispatch('leaderboard/uploadImage', formData)
        //           .then((response) => {
        //             //**Need to set a default loading image to prevent confusion. Set it on a small timeout, make new function for it.
        //             this.image_processing = true
        //             this.currentImage = undefined
        //             this.message = response["data"]   
        //             if(this.message.includes("Error")) {
        //                 this.messageType = "error"
        //                 this.showMessage = true
        //             }
        //             else {
        //                 this.messageType = "success"
        //             }
        //           })
        //           .catch((err) => {
        //               // console.log(err)
        //               this.err_message = err
        //           })
        //     })
        //   }     
        // },
        reuploadMode(){
          // console.log("Re upload started")
          this.upload_complete = false
          this.upload_failed = false
          this.reupload_mode = true
          this.image_processing = false
        },
        reuploadFile(catchId) {
          // console.log("In re-upload")
          this.upload_complete = false
          this.upload_failed = false
          this.reupload_mode = true
          if(this.currentImage != undefined && !this.showMessage) {
            let formData = new FormData()
            fetch(this.currentImage.dataUrl)
            .then(response => response.blob())
            .then(blob => {
                var name = this.currentImage.info.name
                var type = this.currentImage.info.type
                var file = new File([blob], name, {type: type})

                formData.append('file', file)
                formData.append('catch_id', catchId)
                this.$store.dispatch('leaderboard/reuploadImage', formData)
                  .then((response) => {
                    this.currentImage = undefined
                    this.message = response["data"]
                    if(this.message.includes("Error")) {
                        this.messageType = "error"
                        this.showMessage = true
                    }
                    else {
                      this.loadingDelay()
                      this.image_processing = true
                      this.interval = setTimeout(()=> {
                        this.updateImageProcess()
                      }, 2000)
                      this.messageType = "success"
                    }
                  })
                  .catch((err) => {
                      // console.log(err)
                      this.err_message = err
                  })
            })
          }     
        },
    },
    async mounted(){
      // if (!navigator.mediaDevices?.enumerateDevices) {
      //   console.log("enumerateDevices() not supported.");
      // }
      // const devices = await navigator.mediaDevices.enumerateDevices();
      // this.videoDevices = devices.filter((d) => d.kind === "videoinput");
      // await this.StartRecording(
      //     this.videoDevices.length === 1 ? "user" : "environment"
      // );

      this.$store.dispatch('spot_mapper/getSpeciesWithFavorite')
      .then(() => {
          // console.log(this.species)
      }),
      this.$store.dispatch('spot_mapper/getJoinedComps')
      .then(() => {
          // console.log("Joined Comps: " + JSON.stringify(this.joined_comps))
          if(this.joined_comps.length > 0){
            this.require_photo = true
            // console.log(this.joined_comps[0])
            this.competition = this.joined_comps[0].id
            if(this.joined_comps[0].require_photo === "1"){
              this.require_photo = true
            }
          }
      })
    }
  }
  </script>
  
  <style scoped>
  </style>