/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      // console.log(
      //   'App is being served from cache by a service worker.\n' +
      //   'For more details, visit https://goo.gl/AFskqB'
      // )
    },
    registered () {
      // console.log('Service worker has been registered.')
    },
    cached () {
      // console.log('Content has been cached for offline use.')
    },
    updatefound (registration) {
      // console.log('New content is downloading.')
      document.dispatchEvent(
        new CustomEvent('swUpdating', { detail: registration })
      )
    },
    updated (registration) {
      // console.log('New content is available; please refresh.')
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      )
    },
    offline (registration) {
      // console.log('App is running in offline mode.')
      document.dispatchEvent(
        new CustomEvent('swOffline', { detail: registration })
      )
    },
    error (error) {
      // console.error('Error during service worker registration:', error)
      document.dispatchEvent(
        new CustomEvent('swError', { detail: error })
      )
    }
  })

  register('firebase-messaging-sw.js');

}
