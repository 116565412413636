<template>
  <span>
    <l-control class="custom-control-2 pa-2 my-4 mx-0" position="bottomleft">

      <v-tooltip top :disabled=is_mobile v-model="help">
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <p v-if="lockMapOn && dates.length > 0" class="text-caption text-center ma-0 prevent-select">{{ timeSeriesLayers[current].date }}</p>
            <v-btn v-if="lockMapOn" icon @mouseover="tooltipText = 'Previous Image'" @click.prevent="prev" class="pa-0" dense :disabled="timeSeriesLoading || noPrev"><v-icon :color="hazardMarkerColor">fa-backward</v-icon></v-btn>
            <v-btn v-if="lockMapOn" icon @mouseover="tooltipText = 'Close Time Series'" @click.prevent="toggle" class="pa-0" dense :disabled="timeSeriesLoading"><v-icon :color="hazardMarkerColor">fa-stop</v-icon></v-btn>
            <v-btn v-if="lockMapOn" icon @mouseover="tooltipText = 'Next Image'" @click.prevent="next" class="pa-0" dense :disabled="timeSeriesLoading || noNext"><v-icon :color="hazardMarkerColor">fa-forward</v-icon></v-btn>
            <v-btn v-else @mouseover="tooltipText = 'Show Time Series'" @click.prevent="toggle" icon dense><v-icon :color="hazardMarkerColor">fa-history</v-icon></v-btn>          
          </div>
        </template>
        <span>{{tooltipText}}</span>
      </v-tooltip>   

    </l-control>
  <l-feature-group v-if="lockMapOn && timeSeriesLayers">
      <!-- <l-tile-layer
        v-for="(value, key) in timeSeriesLayers"
        :key="key"
        :ref="value.id"
        :title="value"
        :id="value.id"
        :name="value.title"
        :url="value.tile_url"
        :bounds="value.bounds"
        :tms="value.tms"
        :maxNativeZoom="value.maxNativeZoom"
        :maxZoom="value.maxZoom"
        @load="bringToFront($event,value)"
      /> -->
      <OverlayLayer 
        v-for="(value, key) in timeSeriesLayers"
        :key="key"
        :ref="value.id"
        :title="value"
        :id="value.id"
        :name="value.title"
        :url="value.tile_url"
        :bounds="value.bounds"
        :tms="value.tms"
        :maxNativeZoom="value.maxNativeZoom"
        :maxZoom="value.maxZoom"
        @load="bringToFront(value)"
      />      
    </l-feature-group>
    <v-dialog v-model="timeSeriesLoading" scrollable max-width="500px" width="unset">
      <v-card class="card-outter pt-16 pb-0" style="overflow-y: auto;">
        <v-divider></v-divider>
        <v-card-title class="pa-7">
          <v-progress-circular
            class="mr-5"
            color="primary"
            size="40"
            indeterminate
          />
          Loading Time Series
        </v-card-title>
        <v-card-actions class="card-actions pt-4">
          <v-btn @click.prevent="toggle" text class="grey--text">
            <v-icon right color="grey">
              mdi-close
            </v-icon>
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { mapState } from 'vuex'
import { LControl, LFeatureGroup } from 'vue2-leaflet'
import OverlayLayer from '../components/OverlayLayer.vue'

export default {
  components: {
    LControl,
    LFeatureGroup,
    OverlayLayer,
  },
  computed: {
    ...mapState('map', {
      dates: state => state.dates,
      layers: state => state.layers,
      lockMapOn: state => state.lockMapOn,
      visibleLayer: state => state.visibleLayer,
      clicked: state => state.radar_imagery_clicked,
      hide_buttons: state => state.hide_buttons,
      showHelp: state => state.showHelp,
      current_region: state => state.current_region,
    }),
    noNext() {
      return this.current == 0 || typeof this.timeSeriesLayers[this.current - 1] == 'undefined'
    },
    noPrev() {
      return this.current == this.timeSeriesLoaded || typeof this.timeSeriesLayers[this.current + 1] == 'undefined'
    },
    timeSeriesLayers() {
      // note that these are ordered from newest to oldest
      return this.layers
    },
    timeSeriesLayerIds() {
      return this.footprint_dates.map(date => date.id)
    },
    timeSeriesLoading() {      
      return this.lockMapOn && this.timeSeriesLoaded < this.timeSeriesLayers.length
    },
    is_mobile() {
        return this.$vuetify.breakpoint.xs ? this.showHelp ? false : true : false
    },     
  },
  data() {    
    return {
      current: 0,
      hazardMarkerColor: 'deep-orange',
      timeSeriesLoaded: 0,
      saved: {},
      help: false,
      tooltipText: 'Show Time Series',
    }
  },
  watch: {
    showHelp() {
        this.help = this.showHelp
    },        
  },
  methods: {
    setCurrent(){ 
      this.current = this.timeSeriesLayers.map(function(e) { return e.id; }).indexOf(this.visibleLayer.id)
    },
    filterLayers(layer) {
      return this.layers.filter((el) => {
        return el.footprint_id == layer.footprint_id
      })      
    },
    bringToFront(layer) {      
      if (layer.id != this.visibleLayer.id) {
        // e.target.bringToBack()
        // this.$parent.mapObject.removeLayer(this.$refs[layer.id][0].mapObject)
        this.$refs[layer.id][0].mapObject.setOpacity(0)
        this.current = this.layers.findIndex(x => x.id === this.visibleLayer.id)
      }
      this.timeSeriesLoaded++
    },

    // TODO: Fix fade in and out of images

    next() {
      // moving forward in time, skip any repeat dates
      var current_layer = this.timeSeriesLayers[this.current]
      var next = this.current - 1
      
      while(next >= 0 && current_layer.date == this.timeSeriesLayers[next].date) {
        next--
      }

      this.current = next
      current_layer = this.timeSeriesLayers[next]

      // hide layers with different date than current
      var hide_layers = this.timeSeriesLayers.filter(layer => layer.date != this.timeSeriesLayers[this.current].date)
      for (var hide_layer in hide_layers) {
        // this.$refs[hide_layers[hide_layer].id][0].mapObject.bringToBack()
        this.$refs[hide_layers[hide_layer].id][0].mapObject.setOpacity(0)
      }
         
      var image_type = this.timeSeriesLayers[this.current].type
      this.$store.dispatch('map/getLegend', {image_type})  
      this.$store.dispatch('map/setVisibleLayer', this.timeSeriesLayers[this.current])

      // show layers with same date as current
      var show_layers = this.timeSeriesLayers.filter(layer => layer.date == this.timeSeriesLayers[this.current].date)
      for (var show_layer in show_layers) {
        // this.$refs[show_layers[show_layer].id][0].mapObject.bringToFront()
        this.$refs[show_layers[show_layer].id][0].mapObject.setOpacity(100)
      }
    },
    prev() {
      // moving backward in time, skip any repeat dates
      var current_layer = []
      var next
      do{
        this.current++  
        next = this.current + 1
        current_layer = this.timeSeriesLayers[this.current]
      }while (next <= this.timeSeriesLayers.length-1 && current_layer.date == this.timeSeriesLayers[next].date)

      // hide layers with different date than current
      var hide_layers = this.timeSeriesLayers.filter(layer => layer.date != this.timeSeriesLayers[this.current].date)
      for (var hide_layer in hide_layers) {
        // this.$refs[hide_layers[hide_layer].id][0].mapObject.bringToBack()
        this.$refs[hide_layers[hide_layer].id][0].mapObject.setOpacity(0)
      }
         
      var image_type = this.timeSeriesLayers[this.current].type
      this.$store.dispatch('map/getLegend', {image_type})  
      this.$store.dispatch('map/setVisibleLayer', this.timeSeriesLayers[this.current])

      // show layers with same date as current
      var show_layers = this.timeSeriesLayers.filter(layer => layer.date == this.timeSeriesLayers[this.current].date)
      for (var show_layer in show_layers) {
        // this.$refs[show_layers[show_layer].id][0].mapObject.bringToFront()
        this.$refs[show_layers[show_layer].id][0].mapObject.setOpacity(100)
      }
      
    },
    toggle() {
      
      if(!this.lockMapOn) {              
        this.$store.dispatch('map/setHideButtons', true)
        this.$store.dispatch('map/setRadarImageryClicked', true)   
        this.getLayersTimeSeries()
        this.$store.dispatch('map/toggleMapLock')
        // this.$emit('toggle-lock-map')     
        // this.timeSeriesLayers = this.filterLayers(this.visibleLayer)
        // this.$refs[this.timeSeriesLayers[2].id].mapObject.bringToFront()   
      } else if (this.lockMapOn) {
        this.$store.dispatch('map/setHideButtons', false)
        // let lml = this.saved
        // this.$store.dispatch('map/setVisibleLayer', lml)
        this.timeSeriesLoaded = 0
        this.$store.dispatch('map/setRadarImageryClicked', false)    
        this.$store.dispatch('map/setDates', [])  
        this.$store.dispatch('map/setVisibleLayer', {})              
        this.$store.dispatch('map/toggleMapLock')
        // this.$emit('toggle-lock-map')
        this.current = 0
        // console.log(this.all_layers)        
      }      
    },
    
    getLayersTimeSeries() {
      var id = this.current_region.radar_id ? this.current_region.radar_id:this.current_region
      this.$store.dispatch('map/getLayers', {id})
      .then(() => {
        this.queryPoly()
      })
    },

    queryPoly() {
      var turf = require('@turf/turf')
      var dates = []
      this.regions_geojson = []
      // var viewable_footprints = []
      this.viewable_layers = []

      this.view_poly = turf.polygon([[
          [this.$parent.mapObject.getBounds().getNorthWest().lng, this.$parent.mapObject.getBounds().getNorthWest().lat],
          [this.$parent.mapObject.getBounds().getNorthEast().lng, this.$parent.mapObject.getBounds().getNorthEast().lat],
          [this.$parent.mapObject.getBounds().getSouthEast().lng, this.$parent.mapObject.getBounds().getSouthEast().lat],
          [this.$parent.mapObject.getBounds().getSouthWest().lng, this.$parent.mapObject.getBounds().getSouthWest().lat],
          [this.$parent.mapObject.getBounds().getNorthWest().lng, this.$parent.mapObject.getBounds().getNorthWest().lat]
        ]])

      if(this.layers.length > 0) {
        for(let layer in this.layers) {
          if (this.layers[layer].geojson != "") {
            var image_poly = turf.polygon(JSON.parse(this.layers[layer].geojson).features[0].geometry.coordinates) 
            
            if (turf.intersect(this.view_poly, image_poly) != null) {
              this.viewable_layers.push(this.layers[layer])
              dates.push({
                date: this.layers[layer].date,
                id: this.layers[layer].id,
                relative_orbit: this.layers[layer].relative_orbit,
                latitude: this.layers[layer].latitude
              })
            }              
          }            
        }
        if (this.viewable_layers.length > 0) {
          this.$store.dispatch('map/setLayers', this.viewable_layers)  
          var vl = null
          if (dates.length > 1) {
            dates.sort()
            vl = this.layers.find(layer => {
              return layer.id == dates[0].id
            })
          } else {
            vl = this.layers[0]
          }    
          // dates = dates.filter((value, index) => dates.findIndex((item) => item.date === value.date) === index)
          this.$store.dispatch('map/setDates', dates) 
          var image_type = vl.type
          this.$store.dispatch('map/getLegend', {image_type})        
          this.$store.dispatch('map/setVisibleLayer', vl)    
        }
        else {
          // console.log('No Intersecting Layers')
          this.$store.dispatch('map/setLayers', [])
          this.$store.dispatch('map/setDates', [])  
          this.$store.dispatch('map/setVisibleLayer', {})  

        }       
        this.$store.dispatch('map/setThickness', false)
      }   
    },

  },
  mounted() {
    // var turf = require('@turf/turf')
    // var poly1 = {
    //   "type": "Feature",
    //   "properties": {
    //     "fill": "#0f0"
    //   },
    //   "geometry": {
    //     "type": "Polygon",
    //     "coordinates": [[
    //       [-122.801742, 45.48565],
    //       [-122.801742, 45.60491],
    //       [-122.584762, 45.60491],
    //       [-122.584762, 45.48565],
    //       [-122.801742, 45.48565]
    //     ]]
    //   }
    // }
    // var poly2 = {
    //   "type": "Feature",
    //   "properties": {
    //     "fill": "#00f"
    //   },
    //   "geometry": {
    //     "type": "Polygon",
    //     "coordinates": [[
    //       [-122.520217, 45.535693],
    //       [-122.64038, 45.553967],
    //       [-122.720031, 45.526554],
    //       [-122.669906, 45.507309],
    //       [-122.723464, 45.446643],
    //       [-122.532577, 45.408574],
    //       [-122.487258, 45.477466],
    //       [-122.520217, 45.535693]
    //     ]]
    //   }
    // }

    // var intersection = turf.intersect(poly1, poly2)
    // console.log(intersection)

  },

  props: ['footprint_dates']
}
</script>

<style scoped>
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>>