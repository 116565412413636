<template>
  <v-container class="pa-10">
    <!-- <AdminHeader title="Radar Importer" /> -->
    <v-alert v-if="loading" type="info">Loading...</v-alert>
    <v-row class="text-right">
      <v-btn 
        @click.prevent="changeRegionList" 
        class="mx-4 mt-5 mb-3"
      >
        {{ showActiveRegions ? "Show All Regions":"Show Active Regions Only" }}
      </v-btn>
      <v-btn
        @click.prevent="checkSpace"
        :disabled = "space.disabled"
        class="mx-4 mt-5 mb-3"
      >
        {{ space.text }}
      </v-btn>
    </v-row>
    <v-list two-line subheader class="py-3">
      <v-list-item v-for="item in activeRegionList" :key="item.id" class="my-2 rounded-lg elevation-3">
        <v-list-item-avatar tile style="align-items: center;">
          <v-icon>fa-map</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.name }}
            <v-chip v-if="item.active == 1" class="mx-1" color="primary" small>Active</v-chip>
            <v-chip v-else class="mx-1" small>Inactive</v-chip>
          </v-list-item-title>
          <v-list-item-subtitle>
            <!-- <v-chip v-if="item.processing_flag" color="primary" small>Processing Queued</v-chip> -->
            {{ item.preabbr }} {{ getCountry(item.country_id) }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action style="align-items: center;">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>fa-ellipsis-v</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="showRegion(item)">Preview Boundary</v-list-item>
              <v-list-item @click="showUploads(item)">Import Layer</v-list-item>
              <v-list-item :to="'/app/radar/' + item.radar_id">Go to Map</v-list-item>
              <!-- <v-list-item @click="showProcessing(item)" :disabled="!item.processing_flag">Processing Queue</v-list-item> -->
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-expansion-panels>
      <v-expansion-panel class="my-2 rounded-lg elevation-3" style="background-color: #E0E0E0;">
        <v-expansion-panel-header>Processing Queue ({{ processing_status && processing_status.length ? processing_status.length:0 }})</v-expansion-panel-header>
        <v-expansion-panel-content v-if="processing_status.length">
          <v-list three-line subheader>
            <v-list-item v-for="item in processing_status" :key="item.id">
              <v-list-item-avatar tile>
                <v-progress-circular v-if="item.status == 8" indeterminate color="primary"></v-progress-circular>
                <v-icon v-else-if="item.status == 9" color="error">fa-alert</v-icon>
                <v-icon v-else>fa-file</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-chip class="ma-1" color="primary">{{ item.region_name }}</v-chip>
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
                  Processing: {{ item.progress ? item.progress + '%' : 'Cancelled' }} <v-progress-linear :value="item.progress" max="100"></v-progress-linear>
                <v-list-item-subtitle>
                  Status: {{ item.status }} {{item.status_description}} - Current storage used: {{ humanFileSize(item.upload_size) }} 
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="cancelProcessing(item)">Cancel Processing</v-list-item>
                    </v-list>
                  </v-menu>
              </v-list-item-action>              
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
        <v-expansion-panel-content v-else class="font-italic ma-6 text-caption">
          No images in queue.
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-dialog v-model="dialogMap">
      <!-- <template v-slot:activator="{on, attrs}">
        <v-btn icon v-bind="attrs" v-on="on"><v-icon>fa-eye</v-icon></v-btn>
      </template> -->
      <v-card>
        <v-card-title class="headline">Preview - {{ selectedRegion.name }} [ Region ID: {{ selectedRegion.radar_id }} ]</v-card-title>
        <v-card-text v-if="previewLoaded == true">
          <l-map ref="map" style="min-width:500px;min-height:500px;">
            <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <l-geo-json ref="geojsonlayer" :geojson="mapGeoJson" />
          </l-map>
        </v-card-text>
        <v-card-text v-else>
          Loading...
        </v-card-text>
      </v-card>
    </v-dialog>    

    <v-dialog v-model="dialogUploads" width="unset">
      <v-card class="pa-6">
        <v-card-title class="headline">Uploads</v-card-title>
        <uploads @import="doImport($event)" :region="selectedRegion.name"></uploads>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirm" max-width="800" persistent>
      <v-card class="pa-6">
        <v-card-title class="headline">Please confirm:</v-card-title>
        <v-alert 
          class="my-3 mx-6"
          color="red"
          type="warning" 
          v-if="spaceAvailable < 30"
        >
          There is only {{ spaceAvailable.toFixed(2) }} GB of storage left on the server.
        </v-alert>
        <v-flex class="pa-3">
          <v-row>
            <v-col cols="12" xs="12" sm="4">
              <v-card-text class="font-weight-bold text-md-right">File Name</v-card-text>
            </v-col>
            <v-col cols="12" xs="12" sm="8">
              <v-card-text>
                {{ selectedFile.filename }}
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="4">
              <v-card-text class="font-weight-bold text-md-right">Image Type</v-card-text>
            </v-col>
            <v-col cols="12" xs="12" sm="8">
              <v-card-text>
                {{ image_type }}
              </v-card-text>
            </v-col>
          </v-row>          
          <v-row>
            <v-col cols="12" xs="12" sm="4">
              <v-card-text class="font-weight-bold text-md-right">Selected Region</v-card-text>
            </v-col>
            <v-col cols="12" xs="12" sm="8">
              <v-card-text class="blue--text font-weight-medium">
                {{ selectedRegion.name }}
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="4">
              <v-card-text class="font-weight-bold text-md-right">Satellite Image ID</v-card-text>
            </v-col>
            <v-col cols="12" xs="12" sm="8">
              <!-- <v-combobox
                v-model="pFootprint"
                class="mx-4 mt-2 pa-0 text-body-2"
                :hint="hint"
                item-text="name"
                :items="footprints"
                persistent-hint
              /> -->
              <v-text-field
                v-model="computed_footprint"
                class="mx-4 mt-2 pa-0 text-body-2"
                disabled
                :hint="hint"
                persistent-hint
              />
            </v-col>

          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="4">
              <v-card-text class="font-weight-bold text-md-right">Demo Image</v-card-text>
            </v-col>
            <v-col cols="12" xs="12" sm="8" class="px-7">
              <v-radio-group 
                v-model="isDemo"
                row
              >
                <v-radio color="black" label="Yes" small :value=1 />
                <v-radio color="black" label="No" small :value=0 />
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-expansion-panels class="px-6 py-0 mb-2" :value="openMetadata">
              <v-expansion-panel style="background-color: #eee; border: 1px solid #BDBDBD;">
                <v-expansion-panel-header>
                  <v-col class="px-3 py-2 ma-0" cols="12" xs="12">
                    <p class="ma-0 py-1">Metadata</p>
                    <p class="text-caption font-italic ma-0 pa-0">Review these values in the event of an import error.</p>
                  </v-col>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-5 mb-3">

                  <v-form ref="form" lazy-validation>

                    <v-row>
                      <v-col cols="12" xs="12">
                        <v-row class="px-4">
                          <v-col cols="12" xs="12" sm="6">
                            <v-text-field
                              v-model="pDate"
                              dense
                              hint="YYYYMMDD, YYYYMMDDT######."
                              label="Image Date"
                              :loading="loading_footprints"
                              persistent-hint
                              :rules="[checkPlaceholderDate]"
                              required
                            />
                          </v-col>
                          <v-col cols="12" xs="12" sm="6">
                            <v-text-field
                              v-model="pSatelite"
                              dense
                              hint="Example: S1"
                              label="Satellite"
                              :loading="loading_footprints"
                              persistent-hint
                              required
                            />
                          </v-col>
                          <v-col cols="12" xs="12" sm="6">
                            <v-text-field
                              v-model="pImageID"
                              dense
                              hint="Examples: D0D3, PK2352061"
                              label="Image ID / Product ID"
                              :loading="loading_footprints"
                              persistent-hint
                              required
                            />
                          </v-col>
                          <v-col cols="12" xs="12" sm="6">
                            <v-row>
                              <v-col>
                                <v-text-field
                                  v-model="pRO"
                                  dense
                                  hint="RO###, RO##"
                                  label="Relative Orbit"
                                  :loading="loading_footprints"
                                  persistent-hint
                                  @change="assignFootprint"
                                  required
                                />
                              </v-col>
                              <v-col>
                                <v-text-field
                                  v-model="pLat"
                                  dense
                                  hint="Examples: 457N, 50N"
                                  label="Latitude"
                                  :loading="loading_footprints"
                                  persistent-hint
                                  @change="assignFootprint"
                                  required
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" xs="12" sm="6">
                            <v-text-field
                              v-model="pResolution"
                              dense
                              hint="Examples: 6m"
                              label="Resolution"
                              :loading="loading_footprints"
                              persistent-hint
                              @change="assignFootprint"
                            />
                          </v-col>
                          <!-- <v-col cols="12" xs="12" sm="6">
                            <v-text-field
                              v-model="pCenter"
                              dense
                              hint="Latitude, Longitude"
                              label="Center"
                              :loading="loading_footprints"
                              persistent-hint
                              prefix="("
                              :rules="[check_center]"
                              suffix=")"
                              @change="assignFootprint"
                            />
                          </v-col> -->
                          <v-col cols="12" xs="12" sm="6">
                            <v-text-field
                              v-model="pName"
                              dense
                              hint="Example: Northern MN - Lake of the Woods"
                              label="Footprint Name"
                              :loading="loading_footprints"
                              persistent-hint
                              :rules="[checkName]"
                              @change="assignFootprint"
                              required
                            />
                          </v-col>                        
                          <!-- <v-col cols="12" xs="6">
                            <v-file-input
                              v-if="!pGeoJSON"
                              v-model="pGeoJSON"
                              accept=".geojson, .json"
                              dense
                              hint="Associated GeoJSON file"
                              label="GeoJSON"
                              :loading="loading_footprints"
                              :rules="[checkGeoJson]"
                              persistent-hint
                              @click="assignFootprint"
                              required
                            />                 
                          </v-col> -->
                        </v-row>
                      </v-col>
                    </v-row>

                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-flex>
        <v-card-actions class="pa-6">
          <v-spacer />
          <v-btn class="mr-3" @click="cancelConfirm">Cancel</v-btn>
          <!-- <v-spacer /> -->
          <v-btn 
            class="white--text"
            color="blue"
            @click="confirmImport"
            :loading="importLoad"
          >
            Continue & Import
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
//import { icon,latLng,latLngBounds } from 'leaflet';
import {LMap,LTileLayer,LGeoJson/*,LMarker,LControl,LPopup*/} from 'vue2-leaflet'
import Uploads from '../components/Uploads.vue'
// import axios from 'axios'
// import AdminHeader from '../components/AdminHeader.vue'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      activeRegionList: [],
      dialogConfirm: false,
      dialogMap: false,
      dialogUploads: false,
      geojson: '',
      hint: '',
      importLoad: false,
      isDemo: 0,
      loading: true,
      loading_footprints: false,
      mapGeoJson: null,
      openMetadata: null,
      image_type: '',
      pCenter: '',
      pDate: '',
      pFootprint: '',
      pImageID: '',
      pGeoJSON: null,
      pLat: '',
      pResolution: '',
      pName: '',
      previewLoaded: false,
      pRO: '',
      processing_status: [],
      product_ids: [],
      pSatelite: '',
      regions: [],
      selectedFile: {
        filename: ""
      },
      selectedRegion: {
        name: "",
        radar_id: ""
      },
      showActiveRegions: true,
      space: {
        value: '',
        text: 'Check disk space',
        disabled: false
      },
      spaceAvailable: 0,

    }
  },
  components: {
    // AdminHeader,
    LMap,
    LTileLayer,
    LGeoJson,
    Uploads,
    // LControl,
    // LMarker,
    // LPopup,
  },
  computed: {
    ...mapState('map', {
      footprints: state => state.footprints,
    }),
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    filteredRegions() {
      return this.regions.filter(region => region.active == 1)
    },
    check_center() {
      var split = this.pCenter.trim().split(',')
      var isLat = !isNaN(split[0]) && Math.abs(split[0]) <= 90.0 && Math.abs(split[0]) >= 0.0
      var isLng = !isNaN(split[1]) && Math.abs(split[1]) <= 180.0 && Math.abs(split[1]) >= 0.0
      return split.length == 2 && isLat && isLng || 'Please enter valid GPS coordinates.'
    },
    checkPlaceholderDate() {
      var date_num = this.pDate.substring(0,8)
      var date_conv = Date.parse(date_num.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3"))
      return () => (date_num.length == 8 && (date_num.length == 8 && !isNaN(date_conv))) || "Value must convert to a Date object."
    },
    checkName() {
      return this.pName.length > 0 || 'Please enter a footprint name'
    },
    checkGeoJson() {
      return this.pGeoJSON != null || 'Please add an associated GeoJSON file'
    },
    computed_footprint: {
      get: function() {
        // return this.pFootprint && this.pFootprint.id ? this.pFootprint.name:this.pImageID
        return this.pImageID
      },
      // set: function(newval) { 
      //   this.pFootprint = newval
      // }
    }
  },
  created() {
    this.getRegions()
  },
  methods: {
    assignFootprint() {
      this.pFootprint = this.footprints.find(footprint => ('RO' + footprint.relative_orbit) == this.pRO && footprint.latitude == this.pLat)
      
      if (this.pFootprint && this.pFootprint.id) {
        this.openMetadata = 0
        this.hint = ''
        this.pName = this.pFootprint.name
        this.pGeoJSON = this.pFootprint.geojson
      } else {
        this.openMetadata = 0
        this.hint = "No matching footprint. Review metadata below before continuing."
      }
      // console.log('assiging footprint to ' + this.pFootprint + '(type: ' + typeof this.pFootprint + ')')
    },
    cancelConfirm() {
      this.importLoad = false
      this.dialogConfirm = false
      this.openMetadata = null
      this.hint = ""

      this.pFootprint = ''
      this.pCenter = ''
      this.pDate = ''
      this.pFootprint = ''
      this.pImageID = ''
      this.pGeoJSON = null
      this.pLat = ''
      this.pRO = ''
      this.pSatelite = ''
      this.pResolution = ''
      this.pName = ''
    },
    cancelProcessing(layer) {
      this.$store.dispatch('admin/stopImport', layer)
    },
    changeRegionList() {
      if(this.showActiveRegions) {
        this.activeRegionList = this.regions
      } else {
        this.activeRegionList = this.filteredRegions
      }
      this.showActiveRegions = !this.showActiveRegions
    },
    checkSpace() {
      this.$store.dispatch('admin/getSpace')
      .then(resp => {
        this.spaceAvailable = resp.data/1024/1024/1024
        this.space.value = resp.data
        this.space.text = resp.data ? ("~ " + this.spaceAvailable.toFixed(2) + " GB of storage is available"):"Check failed; try again?"
      })
    },
    checkStatus(item) {
      this.$store.dispatch('admin/getStatus', item.id)
      .then((response)=> {
        item.progress = response.data.progress
        item.status_description = response.data.status_description
        item.status = response.data.status
        item.upload_size = response.data.upload_size
        if(response.data.tilemap_json !== item.tilemap_json) item.tilemap_json = response.data.tilemap_json
        if(item.status == 8 && (item.type == 1 || item.type == 2)) {
          setTimeout(()=> this.checkStatus(item),5000)
        }
        if(item.status == 1) {
          item.complete = true
          // setTimeout(()=> item.status = 1,10000)
        }
      })
      //console.log('Status flag sent')
    },
    confirmImport() {
      // this.dialogConfirm = false                                                                                                                                                                                                    
      // this.dialogUploads = false
      //console.log("Confirm import file:",this.selectedFile,"To region:",this.selectedRegion)

      if (this.$refs.form.validate()) {
        this.importLoad = true
        let fp = this.pFootprint && this.pFootprint.id ? this.pFootprint.id:this.pImageID
        var importDetails = {
          file: this.selectedFile,
          region: this.selectedRegion,
          new_file_date: this.pDate,
          new_satelite: this.pSatelite,
          new_image_id: this.pImageID,
          new_relative_orbit: this.pRO.replace('RO',''),
          new_latitude: this.pLat,
          is_demo: this.isDemo,
          footprint_id: fp,
          center: this.pCenter,
          resolution: this.pResolution,
          name: this.pName
        }
        
        if (this.pGeoJSON && this.pGeoJSON instanceof File) {
          var reader = new FileReader()
          reader.readAsText(this.pGeoJSON)
          reader.onload = () => {
            this.geojson = reader.result
            // importDetails.geojson = JSON.stringify(this.geojson)
            // importDetails.geojson = this.geojson.replace(/(\r\n|\n|\r)/gm, "")
            
            // console.log(JSON.stringify(this.geojson))
            // console.log(this.geojson)          
            // console.log(importDetails)
            this.startImport(importDetails)
          }        
          // importDetails.geojson = JSON.parse(this.pGeoJSON)
        } else { 
          // importDetails.geojson = ''
          // importDetails.geojson = this.pGeoJSON.replace(/(\r\n|\n|\r)/gm, "")
          // console.log(importDetails)
          this.startImport(importDetails)
        }
      }
    },
    doImport(item) {
      // assuming the following naming structures:
      // Sentinel  projected_S1A_IW_GRDH_1SDV_20221206T123356_20221206T123425_046211_058887_8F6A_RO114_500N_Cal_dB_TC_RGB.tif
      // RCM       projected_RCM1_OK2081002_PK2352061_1_SCLNB_20221122_123044_HH_HV_GRD_RO113_541N_40m_UTM14_WGS84_Cal_dB_TC_RGB.tif
      
      //console.log(item)
      this.selectedFile = item



      // Determine metadat extraction based on radar source:
      let data = item.filename.split("_")
      if (data[1].toUpperCase().includes('S1')) {
        this.pDate = data[5]
        this.pSatelite = data[1]
        this.pImageID = data[9]
        this.pRO = data[10]
        this.pLat = data[11] 
        this.pResolution = data[12]
      } else if (data[1].toUpperCase().includes('RCM')) {
        this.pDate = data[6]
        this.pSatelite = data[1]
        this.pImageID = data[3]
        this.pRO = data[11]
        this.pLat = data[12]
        this.pResolution = data[13]
      }

      if ((item.filename).includes('iceclass_RGB.tif')) {
        this.image_type = 'Ice Class Prediction - RGB'
      } else if ((item.filename).includes('iceclass.geojson')) {
        this.image_type = 'Ice Class Prediction - Vector Format'        
      } else if ((item.filename).includes('RGB.tif')) {
        this.image_type = 'Generic Ice Image - RGB'
      } else if ((item.filename).includes('thickness.geojson')) {
        this.image_type = 'Ice Thickness Measurement'
      } else {
        this.image_type = 'Unknown Type'
      }
      

      // Confirm disk space availability
      this.checkSpace()
  
      // Assign footprint of best fit 
      this.loading_footprints = true
      let id = this.selectedRegion.radar_id
      this.$store.dispatch('map/getFootprints', {id})
      .then(() => {
        this.assignFootprint()
      })
      .finally(() => {
        this.loading_footprints = false
        this.dialogConfirm = true
      })
    },
    getCountry(code) {
      if(code == 1) return "Canada";
      if(code == 2) return "USA";
    },
    getRegions() {
      this.$store.dispatch('admin/getRegions')
      .then((regions)=> {
        this.regions = Object.values(regions.data)
        //console.log(this.regions)
        this.$store.dispatch('admin/getStatus', 0)
        .then((status)=> {
          this.processing_status = Object.values(status.data)
          this.processing_status.forEach(element => {
            //console.log(element)
            var region_index = this.regions.map(function(r) { return r.radar_id }).indexOf(element.map_id)
            // console.log(this.regions[region_index])
            this.regions[region_index].processing_flag = true
            this.regions[region_index].active = 1
            this.checkStatus(element)
          });
        })
        .then(() => {
          this.activeRegionList = this.filteredRegions
          this.loading = false
        })
      })
      .catch(err => {
        if(err.response.status == '403') {
          this.$store.dispatch('logout')
          this.$store.dispatch('setDialog', { 
            show: true, 
            message: err.response.data, 
            status: err.response.status })
        }
      })
    },
    humanFileSize(bytes, si = true) {
      var thresh = si ? 1000 : 1024;
      if(Math.abs(bytes) < thresh) {
          return bytes + ' B';
      }
      var units = si
          ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
          : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
      var u = -1;
      do {
          bytes /= thresh;
          ++u;
      } while(Math.abs(bytes) >= thresh && u < units.length - 1);
      return bytes.toFixed(1)+' '+units[u];
    },
    showRegion(item) {
      var radar_id = item.radar_id
      var ridx = this.regions.findIndex(r=>r.radar_id === radar_id)
      //console.log(ridx)
      if(typeof(this.regions[ridx].geojson) === 'undefined') {
        //console.log('need geojson', radar_id)
        //this.regions[ridx].geojson = "asdf"
        this.$store.dispatch('getRegionGeoJSON', {radar_id})
          .then((region) => {
            // console.log("response", region)
            this.regions[ridx] = region.data
            this.mapGeoJson = JSON.parse(region.data.geojson)
            this.previewLoaded = true
            this.$nextTick().then(()=> {
              this.$refs.map.mapObject.invalidateSize()
              this.$refs.map.mapObject.fitBounds(this.$refs.geojsonlayer.getBounds())
            })
            //this.$refs.map.mapObject.fitBounds(this.$refs.geojsonlayer.mapObject.getBounds())
          })
      } else {
        this.mapGeoJson = JSON.parse(this.regions[ridx].geojson)
        this.$nextTick().then(()=> {
          this.$refs.map.mapObject.invalidateSize()
          this.$refs.map.mapObject.fitBounds(this.$refs.geojsonlayer.getBounds())
        })
      }
      //this.currentRegion = item.name + ' [radar_id: ' + item.radar_id + ']'
      this.previewLoaded = false      
      this.selectedRegion = item
      this.dialogMap = true
    },
    showUploads(item) {
      this.selectedRegion = item
      this.dialogUploads = true
    },
    startImport(importDetails) {
      this.$store.dispatch('admin/postImport', {importDetails})
      .then(() => { 
        this.dialogUploads = false
        this.cancelConfirm()
        this.getRegions()
      })
    }
  },
  mounted() {
    // this.$store.dispatch('setMode', 'admin')
    this.$store.dispatch('setTab', 'importer')
  },
}
</script>

<style scoped>
>>>.custom-placeholer-color input::placeholder {
  color: black!important;
  opacity: 1;
}

>>>.custom-label-color .v-label {
  color: black;
  opacity: 1;
}

>>>div:nth-child(6) > div > div > div.flex.pa-3 > div:nth-child(3) > div.col-sm-8.col-12 > div > div > div.v-text-field__details > div > div > div {
  color: red!important;
}

>>>#input-750 {
  color: #2196F3;
}
</style>