<template>
  <v-scroll-y-reverse-transition mode="out-in" :hide-on-leave="true">
    <!-- <router-view /> -->
    <v-tabs
      center-active
      centered
      :class="$vuetify.breakpoint.lgAndUp ? 'mt-12':''"
      :height="$vuetify.breakpoint.lgAndUp ? '100%':'unset'"
      :show-arrows="!$vuetify.breakpoint.lgAndUp"
      :vertical="$vuetify.breakpoint.lgAndUp"
    >
      <v-tab
        v-for="(tab,index) in tabs"
        :class="$vuetify.breakpoint.lgAndUp ? 'justify-start text-subtitle-1 text-capitalize pl-10 pr-12 py-0':'text-body-2 text-capitalize py-3'"
        :key="tab"
      >
        {{ tab }}
        <v-icon v-if="[3,4,5].includes(index)" class="mx-2" icon small>
          mdi-progress-clock
        </v-icon>
      </v-tab>
      <v-tab-item :class="$vuetify.breakpoint.lgAndUp ? '':'mt-2'"><RAS /></v-tab-item>
      <v-tab-item :class="$vuetify.breakpoint.lgAndUp ? '':'mt-2'"><SpotMapper /></v-tab-item>
      <v-tab-item :class="$vuetify.breakpoint.lgAndUp ? '':'mt-2'"><APConditions /></v-tab-item>
      <v-tab-item :class="$vuetify.breakpoint.lgAndUp ? '':'mt-2'"><IncidentReporting /></v-tab-item>
      <v-tab-item :class="$vuetify.breakpoint.lgAndUp ? '':'mt-2'"><LocalServices /></v-tab-item>
      <v-tab-item :class="$vuetify.breakpoint.lgAndUp ? '':'mt-2'"><OnDemand /></v-tab-item>
    </v-tabs>
  </v-scroll-y-reverse-transition>
</template>
  
  <script>
  import APConditions from './APConditions.vue'
  import IncidentReporting from './IncidentReporting.vue'
  import LocalServices from './LocalServices.vue'
  import OnDemand from './OnDemand.vue'
  import SpotMapper from './SpotMapper.vue'
  import RAS from './RAS.vue'

  export default { 
    components: {
      APConditions,
      IncidentReporting,
      OnDemand,
      LocalServices,
      SpotMapper,
      RAS,
    },
    data() {
      return {
        tabs: [
          'Regional Advisory Service (RAS)',
          'Spot Mapper',
          'Access Points',
          'Incident Reporting',
          'Local Services',
          'On Demand'
        ],
      }
    },
    methods: {
    }
  }
  </script>
  