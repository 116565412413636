<template>
    <v-container class="ma-0 pa-0 mb-10">
        <v-card class="blue lighten-5 pb-3">
            <v-card-title>Competition Leaderboard</v-card-title>
            <v-card
                :class="set_padding_margins"
            >
                <v-tabs
                    fixed-tabs
                    bg-color="primary"
                    class="mb-5"
                    v-model="active_tab"
                >
                <v-tab v-if="!$vuetify.breakpoint.xs" @click="changeSummaryPage($route.params.id)">Summary</v-tab>
                    <v-tab v-else @click="changeSummaryPage($route.params.id)"><v-icon>mdi-information-box</v-icon></v-tab>
                    <v-tab v-if="!$vuetify.breakpoint.xs" @click="changeRosterPage($route.params.id)">Roster</v-tab>
                    <v-tab v-else @click="changeRosterPage($route.params.id)"><v-icon>mdi-list-box</v-icon></v-tab>
                    <v-tab v-if="!$vuetify.breakpoint.xs" @click="changeLeaderboardPage($route.params.id)">Leaderboard</v-tab>
                    <v-tab v-else @click="changeLeaderboardPage($route.params.id)"><v-icon>mdi-trophy</v-icon></v-tab>
                </v-tabs>
                <v-row v-if="((competition.owner === user.id || user.status >= 9))" justify="end" class="ma-1 pa-4">
                    <v-btn 
                        class="justify-center"
                        elevation="5"
                        dark
                        color="indigo"
                        @click="changeAdministratePage($route.params.id)"                        
                    >
                        Administrate Competition
                    </v-btn>
                </v-row>  
                <v-row v-if="fixed_teams.length == 0">
                    <v-container>
                        <v-row :no-gutters="set_gutters">
                            <v-col cols="12">
                                <v-card-text class="py-0">
                                    <p>No catches have been recorded yet!</p>
                                </v-card-text>                                
                            </v-col>
                        </v-row>                                
                    </v-container>                
                </v-row>

                <v-container
                    v-else-if="blackout"
                    class="grey darken-4"
                >
                    
                    <v-row 
                    key="Blackout"
                    justify="center">
                        <v-col md="4">
                            <v-card
                                class="py-8 my-8 grey darken-4 white--text text-center"
                                outlined
                                tile
                            >
                                <v-row>
                                    <v-col class="pa-0" cols="12">
                                        <v-card-text class="pa-0">
                                            <div class="subtitle-1">Blackout Countdown</div>
                                            <div v-if="!$vuetify.breakpoint.xs" class="text-h1">{{  countdownDays }}:{{ countdownHours }}:{{ countdownMins }}:{{ countdownSecs }}</div>                        
                                            <div v-else class="text-h2">{{  countdownDays }}:{{ countdownHours }}:{{ countdownMins }}:{{ countdownSecs }}</div>   
                                        </v-card-text>                    
                                    </v-col>
                                </v-row>                                      
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>

                <div v-else>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                            <v-btn v-if="!$vuetify.breakpoint.xs" width="180" @click="updateCatches()" :disabled="button_disabled">Refresh<v-icon right>mdi-refresh</v-icon></v-btn>
                            <v-btn v-else icon large @click="updateCatches()" :disabled="button_disabled"><v-icon right>mdi-refresh</v-icon></v-btn>     
                         
                            <v-btn v-if="!$vuetify.breakpoint.xs && !expanded" width="180" @click="expand">Expand All<v-icon right>mdi-arrow-expand</v-icon></v-btn>
                            <v-btn v-else-if="!$vuetify.breakpoint.xs" width="180" @click="expand">Collapse All<v-icon right>mdi-arrow-collapse</v-icon></v-btn>                       
                            <v-btn v-else-if="!expanded" icon large @click="expand"><v-icon right>mdi-arrow-expand</v-icon></v-btn>  
                            <v-btn v-else icon large @click="expand"><v-icon right>mdi-arrow-collapse</v-icon></v-btn>
                    </v-card-actions>
                    <v-expansion-panels 
                        v-model="panel"     
                        flat 
                        multiple
                    >
                        <v-expansion-panel v-for="(item, k) in fixed_teams" :key="k" class="py-0">
                            <v-expansion-panel-header class="pl-0">
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="6">                                
                                            <v-card-text class="py-0">
                                                <div>Team Name</div>
                                                <p :class="font_size_value">{{ convertSpecial(item.name) }}</p>                        
                                            </v-card-text>       
                                        </v-col>

                                        <v-col cols="12" sm="6">
                                            <v-card-text class="py-0">
                                                <div>
                                                    {{ ((competition.score_type === "1" && parseFloat(comp_species.length) > 1)? "Total Length - All Species": "" ) }}
                                                    {{ ((competition.score_type === "1" && parseFloat(comp_species.length) === 1)? "Total Length": "" ) }}
                                                    {{ ((competition.score_type === "2" && parseFloat(comp_species.length) > 1)? "Total Weight - All Species": "" ) }}
                                                    {{ ((competition.score_type === "2" && parseFloat(comp_species.length) === 1)? "Total Weight": "" ) }}
                                                    {{ ((competition.score_type === "3")? "Total Catches": "" ) }}    
                                                    {{ ((competition.score_type === "4") && parseFloat(competition.amount) === 1 ? "Longest Catch": "" ) }}
                                                    {{ ((competition.score_type === "4") && parseFloat(competition.amount) > 1 ? "Longest " + competition.amount + " Catches": "" ) }}
                                                    {{ ((competition.score_type === "5") && parseFloat(competition.amount) === 1 ? "Heaviest Catch": "" ) }}
                                                    {{ ((competition.score_type === "5") && parseFloat(competition.amount) > 1 ? "Heaviest " + competition.amount + " Catches": "" ) }}                                                                                               
                                                </div>
                                                <p :class="font_size_value">
                                                    {{ ((competition.score_type === "1")? item.total_length + " cm": "" ) }}
                                                    {{ ((competition.score_type === "2")? item.total_weight + " lbs.": "" ) }}
                                                    {{ ((competition.score_type === "3")? item.total_catches: "" ) }}
                                                    {{ ((competition.score_type === "4")? item.best_catches: "" )}}
                                                    {{ ((competition.score_type === "5")? item.best_catches: "" ) }}</p>                        
                                            </v-card-text>                    
                                        </v-col>
                                    </v-row>   
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-container class="px-0">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-card-text class="py-0">
                                                <div>Totals by Species</div>

                                                <v-row :class="font_size_value_totals" no-gutters>
                                                    <v-col v-for="(sub, s) in item.subtotals" :key="s" cols="12" sm="6">{{ sub.species_name }}: {{ sub.total_length }} cm, {{ sub.total_weight }} lbs.</v-col>
                                                </v-row>
                                            </v-card-text>                    
                                        </v-col>
                                    </v-row>   
                                </v-container>
                                <v-container class="px-0">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-card-text class="py-0">
                                                <div>Catches by Team Member</div>
                                                <v-row v-for="(member, i) in item.members" :key="i" :class="font_size_value_totals" no-gutters>
                                                    <v-col cols="12">{{ member.alias }}</v-col>
                                                    <v-col>
                                                    <v-simple-table>
                                                        <thead>
                                                            <tr>
                                                                <th class="text-left" style="width: 40%">Species</th>
                                                                <th class="text-left">Length (inches)</th>
                                                                <th class="text-left">Weight (pounds)</th>
                                                                <!-- <th class="text-left">Date Caught</th> -->
                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(fish, j) in member.catches" :key="j">
                                                                <td>{{ fish.common_name }}<v-icon right class="px-2" v-if="fish.image" @click="showImage(fish.image)">mdi-image</v-icon></td>
                                                                <td>{{ (fish.length == "")? "-" : fish.length }}</td>
                                                                <td>{{ (fish.weight == "")? "-" : fish.weight }}</td>
                                                                <!-- <td>{{ fish.date.substring(0,16) }}</td> -->
                                                            </tr>
                                                        </tbody>
                                                    </v-simple-table>
                                                </v-col>
                                                </v-row>
                                            </v-card-text>                    
                                        </v-col>
                                    </v-row>   
                                </v-container>                                
                            </v-expansion-panel-content>
                            <v-divider v-if="k != fixed_teams.length-1" class="mx-2"></v-divider>   
                        </v-expansion-panel>
                </v-expansion-panels>             
                <div v-if="this.fixed_sidepot.length > 0">             
                <v-divider class="mx-2"></v-divider>
                    <v-expansion-panels
                        v-model="sidepot_panel"
                        multiple
                        flat
                        focusable
                        v-if="!blackout"
                        class="mt-5"
                    >
                        <v-expansion-panel
                            v-if="(comp_sidepot!==null)"
                        >
                        <v-expansion-panel-header><h3>Side Pot</h3></v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-container v-for="(item,k) in this.fixed_sidepot"
                                :key="k"
                                class="px-0 pt-4"
                                >
                                <v-row :no-gutters="set_gutters">
                                    <v-col cols="14">
                                        <v-card-text class="py-0">
                                            <div>Current Leader</div>
                                            <p :class="font_size_value">{{ item.leader }}</p>                        
                                        </v-card-text>                    
                                    </v-col>
                                    <v-col sm="7">
                                        <v-card-text class="py-0">
                                            <div>Catch {{ item.score=== "1" ? "Length" : "Weight"}}</div>
                                            <p :class="font_size_value">{{ item.fish_value }} {{ item.score=== "1" ? "cm" : "lbs."}}</p>                        
                                        </v-card-text>   
                                    </v-col>
                                </v-row>   
                                <v-row :no-gutters="set_gutters">
                                    <v-col cols="14">
                                        <v-card-text class="py-0">
                                            <div>Side Pot Scoring</div>
                                            <p :class="font_size_value">{{ scoreByValue(item.score) }}</p>                        
                                        </v-card-text>                    
                                    </v-col>
                                    <v-col sm="7">
                                        <v-card-text class="py-0">
                                            <div>Side Pot Payout</div>
                                            <p :class="font_size_value">${{ item.payout }}</p>                        
                                        </v-card-text>   
                                    </v-col>
                                </v-row>                           
                            </v-container>                            
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>

            </div>  
        </v-card>         
    </v-card>
    <v-dialog
        v-model="overlay"
        max-width="500"
        @click:outside="closeOverlay"
    >
        <v-card max-width="500">
            <v-card-title>Ending the Competition?</v-card-title>
            <v-card-text class="pb-0">This will end the competition. Are you sure you wish to publish results?
                <v-row class="pa-4">
                    <v-container>
                        <v-row>
                            <v-col>
                                <h4>Team Placement</h4>
                                You can drag teams to re-order placement
                            </v-col>
                        </v-row>
                        
                        <draggable v-model="fixed_teams" @start="drag=true" @end="drag=false">
                            <!-- <div v-for="(element, index) in fixed_teams" :key="element.id">{{ index + 1 }} {{ element.name }}</div> -->
                            <v-row v-for="(element, index) in fixed_teams" :key="element.id">
                                <v-chip class="white--text blue lighten-1 ma-2" >
                                    <v-icon left class="white--text">
                                        mdi-drag-horizontal-variant
                                    </v-icon>
                                    <div>{{ index + 1 }}<span v-if="index === 0">st</span><span v-if="index === 1">nd</span><span v-if="index === 2">rd</span><span v-if="index > 2">th</span>: {{ element.name }}</div>
                                    
                                </v-chip>
                            </v-row>
                        </draggable>    
                    </v-container>
                </v-row>
                <v-container>
                    <v-row>
                        <v-col class="pl-0">
                            <v-btn
                            class="white--text"
                            color="blue lighten-2"
                            @click="add_delay = !add_delay"
                            
                            >
                                {{add_delay? "Remove Delay" : "Add Publishing Delay"}}
                            </v-btn>    
                        </v-col>
                    </v-row>
                </v-container>
                <v-row v-if="add_delay" no-gutters>
                    <v-col cols="12" sm="6">
                        <v-menu
                            absolute
                            v-model="delay_menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="delay_date"
                                label="Delay Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="delay_date"
                                no-title
                                scrollable
                                @input="delay_menu = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-menu
                            absolute
                            ref="menu"
                            v-model="delay_time_menu"
                            :close-on-content-click="false"
                            :return-value.sync="delay_time"
                            transition="scale-transition"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="delay_time"
                                label="Delay Time"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"                   ></v-text-field>
                            </template>
                            <v-time-picker
                                v-if="delay_time_menu"
                                v-model="delay_time"
                                @click:minute="$refs.menu.save(delay_time)"
                            ></v-time-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="pb-4 justify-center">                    
                <v-btn
                    class="white--text"
                    color="teal"
                    @click="endComp"
                >
                    Confirm
                </v-btn>
                <!-- <v-btn
                    class="white--text"
                    color="teal"
                    @click="testButton()"
                >
                    Test End
                </v-btn> -->
                <v-btn
                    class="white--text"
                    color="red darken-2"
                    @click="closeOverlay()"
                >
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog
        v-model="image_overlay"
        max-width="400"
    >
        <v-card max-width="400">
                
                <v-card-actions class="pa-2">
                    <v-spacer></v-spacer>
                    <v-icon
                        large
                        @click="image_overlay = false"
                    >mdi-close-circle
                    </v-icon>                
                </v-card-actions>
            
                <v-img 
                    v-if="image_name" 
                    :src="(image_start + image_name)" 
                    max-height="500"
                    contain
                >
                    <template v-slot:placeholder>
                        <div class="d-flex align-center justify-center fill-height">
                            <v-progress-circular
                            color="grey-lighten-4"
                            indeterminate
                            ></v-progress-circular>
                        </div>
                    </template>
                </v-img>
            <!-- <v-img src="$apiEndpointUrl + 'catch_image/6478f3c0bfcdd.png'"/> -->
        </v-card>
    </v-dialog>            
    </v-container>
</template>

<script>
//import
import { mapState } from 'vuex';
import draggable  from 'vuedraggable';

export default{
    data(){
        return {
            active_tab: 2,
            fixed_teams:[],
            original_team_order: [],
            fixed_subtotals: [],
            panel:[],
            sidepot_panel:[],
            expanded: false,
            panels: 0,
            scoreBy: "total length",
            weightAsc: false,
            lengthAsc: true,
            ended: false,
            overlay:false,
            button_disabled:true,
            blackout: false,
            blackout_end: '',

            image_overlay:false,
            selected_image: undefined,
            image_file: undefined,
            image_start: this.$apiEndpointUrl + "catch_image/",
            image_name: null,

            add_delay: false,
            delay_date: '',
            delay_menu: false,
            delay_time: '',
            delay_time_menu: false,

            order_mode: false,
            delay_mode: false,

            countdownDays: 0,
            countdownHours: 0,
            countdownMins: 0,
            countdownSecs: 0,
            
            test_sidepot: [{
                "score": "2",
                "winner_id": null,
                "payout": "50.70",
                "leader": "coalbox",
                "leader_id": "15",
                "fish_value": "15.7"
            }],
            fixed_sidepot:[],
        }
    },
    components:{
        draggable,
},
    computed: {
        ...mapState('leaderboard', {
            competition: state => state.competition,
            comp_teams: state => state.comp_teams,
            comp_blackout: state => state.comp_blackout,
            comp_species: state => state.comp_species,
            comp_sidepot: state => state.comp_sidepot,
        }),
        ...mapState('user', {
            user: state => state.user,
        }),        
        _seconds: () => 1000,
        _minutes () {
            return this._seconds * 60
        },
        _hours () {
            return this._minutes * 60
        },
        _days () {
            return this._hours * 24
        },
        set_gutters(){
            return !this.$vuetify.breakpoint.xs ? false : true
        },     
        set_padding_margins(){
            return !this.$vuetify.breakpoint.xs ? "px-4 py-2 ma-3" : "ma-3"
        }, 
        font_size_value(){
            return !this.$vuetify.breakpoint.xs ? "text-h5" : "text-h6"
        },           
        font_size_value_totals(){
            return !this.$vuetify.breakpoint.xs ? "text-h5" : "text-body-1 font-weight-medium"
        },              
    },
    methods: {
        changeSummaryPage(id){
            // window.location.href = "/app/leaderboard/compsummary/" + id
            this.$router.push(("/app/leaderboard/compsummary/" + id))
            // this.$store.page = page
            // console.log(this.$store.page)
            // this.$emit('change-page')
            // vm.$forceUpdate()
        },
        changeRosterPage(id){
            // window.location.href = "/app/leaderboard/comproster/" + id
            this.$router.push(("/app/leaderboard/comproster/" + id))
            // this.$store.page = page
            // console.log(this.$store.page)
            // this.$emit('change-page')
            // vm.$forceUpdate()
        },
        changeLeaderboardPage(id){
            window.location.href = "/app/leaderboard/compleaderboard/" + id
            this.$router.push(("/app/leaderboard/compleaderboard/" + id))
            // this.$store.page = page
            // console.log(this.$store.page)
            // this.$emit('change-page')
            // vm.$forceUpdate()
        },
        changeAdministratePage(id){
            window.location.href = "/app/leaderboard/adminleadeboard/" + id
            this.$router.push(("/app/leaderboard/adminleadeboard/" + id))
            // this.$store.page = page
            // console.log(this.$store.page)
            // this.$emit('change-page')
            // vm.$forceUpdate()
        },
        convertSpecial(text){  
            text = String(text).replace("&lt;" , "<")
            text = String(text).replace("&gt;" , ">")
            text = String(text).replace("&quot;" , "\"")
            text = String(text).replace("&#039;" , "'")
            text = String(text).replace("&amp;" , "&")
            // return htmlStr
            // console.log(text)
            return text
        },
        closeOverlay(){
            this.sortBy(this.competition.score_type)
            this.overlay = false
            this.fixed_teams = this.original_team_order
            this.delay_date = ""
            this.delay_time = ""
        },
        expand(){
            if(!this.expanded){
                this.panel = [...Array(this.panels).keys()].map((k,i) => i)
            }else{
                this.panel = []
            }
            this.expanded = !this.expanded
        },
        // There may be a better way that does not need to sort as much using .reverse? 
        sort(ascending, sortBy){
            // console.log(ascending)
            if(sortBy === "length"){
                if(ascending){
                    this.fixed_teams.sort((a,b) => a.longest_catch - b.longest_catch)
                }else{
                    this.fixed_teams.sort((a,b) => b.longest_catch - a.longest_catch)
                }
                this.lengthAsc = !this.lengthAsc
                this.scoreBy = "Length"
            }else{
                if(ascending){
                    this.fixed_teams.sort((a,b) => a.heaviest_catch - b.heaviest_catch)
                }else{
                    this.fixed_teams.sort((a,b) => b.heaviest_catch - a.heaviest_catch)
                }
                this.weightAsc = !this.weightAsc
                this.scoreBy = "Weight"
            }
        },
        sortBy(score_type){
            // let temp = this.fixed_teams
            var temp = []
            if(score_type === "1"){
                this.fixed_teams.sort((a,b) => b.total_length - a.total_length)
                this.scoreBy = "Total Length"
            }else if(score_type === "2"){
                this.fixed_teams.sort((a,b) => b.total_weight - a.total_weight)
                this.scoreBy = "Total Weight"
            }else if(score_type === "3"){
                this.fixed_teams.sort((a,b) => b.total_catches - a.total_catches)
                this.scoreBy = "Total Catches"
            }else if(score_type === "4"){
                // let temp_scores = []                
                this.fixed_teams.forEach((t,j) => {
                    t.members.forEach(m => {
                        m.catches.forEach(c => {
                            let tmp_catch = {
                                name: c.common_name,
                                length: c.length
                            }
                            temp.push(tmp_catch)
                        })
                    })
                    temp.sort((a,b) => b.length - a.length)
                    let best_catches = 0
                    let c_values = ""
                    if (temp.length < this.competition.amount) {
                        temp.forEach((item, i) => {
                            c_values += item.length + "cm"
                            if (i+1 < temp.length) {
                                c_values += " , "
                            }
                        });
                    }          
                    else {
                        for (let i = 0; i < this.competition.amount; i++) {
                            // best_catches.push(temp[i])
                            best_catches += Number(temp[i].length)
                            c_values += (temp[i].length) + " cm"
                            if(!(Number(this.competition.amount) === Number(i+1))){
                                c_values += " , "
                            }
                        }
                    }         
                    this.fixed_teams[j].best_catches = c_values
                    this.fixed_teams[j].best_total = best_catches
                })
                // console.log(temp_scores)
                this.fixed_teams.sort((a,b) => b.best_total - a.best_total)
                this.scoreBy = "Longest " + this.competition.amount + " Fish"
            }else if(score_type === "5"){
                this.fixed_teams.forEach((t,j) => {
                    t.members.forEach(m => {
                        m.catches.forEach(c => {
                            let tmp_catch = {
                                name: c.common_name,
                                weight: c.weight
                            }
                            temp.push(tmp_catch)
                        })
                    })
                    temp.sort((a,b) => b.weight - a.weight)
                    let best_catches = 0
                    let c_values = ""
                    if (temp.length < this.competition.amount) {
                        temp.forEach((item, i) => {
                            c_values += item.weight + "lbs."
                            if (i+1 < temp.length) {
                                c_values += " , "
                            }
                        });
                    }
                    else {
                        for (let i = 0; i < this.competition.amount; i++) {
                            // best_catches.push(temp[i])
                            best_catches += Number(temp[i].weight)
                            c_values += (temp[i].weight) + " lbs."
                            if(!(Number(this.competition.amount) === Number(i+1))){
                                c_values += " , "
                            }
                        }
                    }
                    this.fixed_teams[j].best_catches = c_values
                    this.fixed_teams[j].best_total = best_catches
                })
                this.fixed_teams.sort((a,b) => b.best_total - a.best_total)
                this.scoreBy = "Heaviest " + this.competition.amount + " Fish"
            }

            // if(score_type === "1"){
            //     temp.sort((a,b) => b.longest_catch - a.longest_catch)
            //     this.scoreBy = "length"
            // }else if(score_type === "2"){
            //     temp.sort((a,b) => b.heaviest_catch - a.heaviest_catch)
            //     this.scoreBy = "weight"
            // }else if(score_type === "3"){
            //     temp.sort((a,b) => b.total_catches - a.total_catches)
            //     this.scoreBy = "total catches"
            // }else if(score_type === "6"){
            //     temp.sort((a,b) => b.total_length - a.total_length)
            //     this.scoreBy = "total length"
            // }else if(score_type === "7"){
            //     temp.sort((a,b) => b.total_weight - a.total_weight)
            //     this.scoreBy = "total weight"
            // }

            // this.fixed_teams = temp
            // console.log(this.fixed_teams)
        },
        testButton(){
            this.subtotal()
        },
        getTotals(teams){
            // There might be a better way then just hoping values line up?
            // console.log(teams)
            for(let x = 0; x < this.fixed_teams.length; x++){
                let species = teams[x].species
                let keys = Object.keys(species)
                keys.forEach(key => {
                    // console.log(`${key}:`)
                    // console.log(species[key])
                    this.fixed_teams[x].subtotals.push(species[key])
                })
                // console.log(keys)
                // this.fixed_teams[x].subtotals = teams[x].species
            }
            // console.log(this.fixed_teams)
        },
        subtotal(){
            let current_team_id = null
            let teams = []
            let temp_team = []

            // console.log(this.comp_teams)
            this.comp_teams.forEach(i => {
                if(current_team_id === null){
                    //first loop, new team
                    
                    // console.log("1st run of loop: " + i.species_id + " name: " + i.common_name)
                    temp_team = {
                        team_id: i.id,
                        species: []
                    }
                    temp_team.species[i.species_id] = {total_length : Number(i.length), total_weight : Number(i.weight), species_id: i.species_id, species_name: i.common_name}
                    current_team_id = i.id
                }else if(current_team_id === i.id){
                    //same team
                    //index
                    let temp_index = Object.keys(temp_team.species).indexOf(i.species_id)
                    // console.log(temp_index)
                    if(temp_index === -1){
                        //New species, same team
                        // console.log("new Species, Same team: " + i.species_id + " name: " + i.common_name)

                        temp_team.species[i.species_id] = {total_length : Number(i.length), total_weight : Number(i.weight), species_id: i.species_id, species_name: i.common_name}
                    }else{
                        // //Species exists in object
                        let new_length = (Number(temp_team.species[i.species_id].total_length) + Number(i.length))
                        let new_weight = (Number(temp_team.species[i.species_id].total_weight) + Number(i.weight))
                        
                        // console.log(new_length)
                        // console.log(new_weight)

                        temp_team.species[i.species_id].total_length = new_length
                        temp_team.species[i.species_id].total_weight = new_weight
                        // // console.log(temp_team.species)
                    }
                    
                }else{
                    //New team

                    //push old team
                    teams.push(temp_team)
                    // this.fixed_teams.forEach(t => {
                    //     if(t.id === temp_team.team_id){
                    //         t.subtotals = temp_team.species
                    //     }
                    // })

                    temp_team = {
                        team_id: i.id,
                        species: []
                    }
                    temp_team.species[i.species_id] = {total_length : Number(i.length), total_weight : Number(i.weight), species_id: i.species_id, species_name: i.common_name}
                    current_team_id = i.id
                }
                
            })
            //save last value
            teams.push(temp_team)

            this.getTotals(teams)
            // this.fixed_teams.forEach(t => {
            //     if(t.id === temp_team.team_id){
            //         t.subtotals = temp_team.species
            //     }
            // })
            
            // for(let x = 0; x < this.fixed_teams.length; x++){
            //     this.fixed_teams[x].subtotals = teams[x].species
            // }

            // console.log(this.fixed_teams)
            // console.log(temp_team.species)
            // console.log(this.fixed_teams)

            // // console.log(this.comp_teams)
            // this.comp_teams.forEach(i => {
            //     if(current_team_id === null){
            //         //first loop, new team
                    
            //         // console.log("1st run of loop: " + i.species_id + " name: " + i.common_name)
            //         temp_team = {
            //             team_id: i.id,
            //             species: []
            //         }
            //         temp_team.species[i.species_id] = {total_length : Number(i.length), total_weight : Number(i.weight)}
            //         current_team_id = i.id
            //     }else if(current_team_id === i.id){
            //         //same team
            //         //index
            //         let temp_index = Object.keys(temp_team.species).indexOf(i.species_id)
            //         // console.log(temp_index)
            //         if(temp_index === -1){
            //             //New species, same team
            //             // console.log("new Species, Same team: " + i.species_id + " name: " + i.common_name)
            //             temp_team.species[i.species_id] = {total_length : Number(i.length), total_weight : Number(i.weight)}
            //         }else{
            //             // //Species exists in object
            //             let new_length = (Number(temp_team.species[i.species_id].total_length) + Number(i.length))
            //             let new_weight = (Number(temp_team.species[i.species_id].total_weight) + Number(i.weight))
                        
            //             // console.log(new_length)
            //             // console.log(new_weight)

            //             temp_team.species[i.species_id].total_length = new_length
            //             temp_team.species[i.species_id].total_weight = new_weight
            //             // // console.log(temp_team.species)
            //         }
                    
            //     }else{
            //         //New team

            //         //push old team
            //         teams.push(temp_team)
            //         this.fixed_teams.forEach(t => {
            //             if(t.id === temp_team.team_id){
            //                 t.subtotals = temp_team.species
            //             }
            //         })

            //         temp_team = {
            //             team_id: i.id,
            //             species: []
            //         }
            //         temp_team.species[i.species_id] = {total_length : Number(i.length), total_weight : Number(i.weight)}
            //         current_team_id = i.id
            //     }
                
            // })
            // //save last value
            // teams.push(temp_team)
            // this.fixed_teams.forEach(t => {
            //     if(t.id === temp_team.team_id){
            //         t.subtotals = temp_team.species
            //     }
            // })
            // // console.log(temp_team.species)
            // // console.log(this.fixed_teams)
        },
        endCompPopup(){
            this.original_team_order = this.fixed_teams
            this.add_delay = false
            this.overlay = true            
        },
        endComp(){
            let info = {
                comp_id: this.competition.id
            }

            if(this.add_delay){
                let fixed_delay = this.getDBTime((this.delay_date + " " + this.delay_time))
                info = {
                    comp_id: this.competition.id,
                    delay_date: fixed_delay
                }
            }
            for (let i = 0; i < this.fixed_teams.length; i++) {
                this.fixed_teams[i].placement = (i+1)
            }
            
           let data = {
                info: info,
                results: this.fixed_teams,
                sidepot: this.fixed_sidepot
            }
            // console.log(data)
            this.$store.dispatch('leaderboard/endComp', data)
            .then(() => {
            })
            .catch((err) => {
                this.error = true
                this.err_message = 'Retry: ' + err
            })
            .finally(() => {
                this.ended = false
                // console.log(data)
                this.overlay = false
                this.changePage('ended_comps')
            })
        },
        getDBTime(date){
            let localdate = new Date(date)
            return (localdate.toISOString().slice(0, -1))
        },
        // testend(){
        //     // let final = []
        //     for (let i = 0; i < this.fixed_teams.length; i++) {
        //         this.fixed_teams[i].placement = (i+1)
        //     }

        //     console.log(this.fixed_teams)
        // },
        updateCatches(){
            this.button_disabled = true
            this.$store.dispatch('leaderboard/getCompTeams')
            .then(() => {
                this.fixed_teams.splice(0)
                var temp_id = []
                var team = {}
                var current_member = {}
                this.comp_teams.forEach(item => {
                    if(item.id !== temp_id){
                        team = {
                            id: item.id,
                            name: item.name,
                            members:[],
                            longest_catch:0,
                            heaviest_catch:0,
                            l_leader_name: null,
                            l_leader_id: null,
                            h_leader_name: null,
                            h_leader_id: null,
                            total_length:Number(0.0),
                            total_weight:Number(0.0),
                            total_catches:0,
                            subtotals: []
                        }
                        current_member = {
                            member_id: item.member,
                            alias: item.alias,
                            catches:[{
                                species_id: item.species_id,
                                common_name: item.common_name,
                                length: item.length,
                                weight: item.weight,
                                date: item.date,
                                image: item.image_name
                            }]
                        }
                        team.longest_catch = item.length
                        team.heaviest_catch = item.weight
                        team.l_leader_name = item.alias
                        team.l_leader_id = item.member
                        team.h_leader_name = item.alias
                        team.h_leader_id = item.member
                        team.total_length += Number(item.length)
                        team.total_weight += Number(item.weight)
                        team.total_catches ++
                        team.members.push(current_member)
                        this.fixed_teams.push(team)
                        this.panels++
                        temp_id = item.id
                    } else if(current_member.member_id !== item.member) {
                        current_member = {
                            member_id: item.member,
                            alias: item.alias,
                            catches:[{
                                species_id: item.species_id,
                                common_name: item.common_name,
                                length: item.length,
                                weight: item.weight,
                                date: item.date,
                                image: item.image_name
                            }]
                        }
                        if(Number(item.length) > Number(team.longest_catch)){
                            team.longest_catch = item.length
                            team.l_leader_name = item.alias
                            team.l_leader_id = item.member
                        }if(Number(item.weight) > Number(team.heaviest_catch)){
                            team.heaviest_catch = item.weight
                            team.h_leader_name = item.alias
                            team.h_leader_id = item.member
                        }
                        team.total_length += Number(item.length)
                        team.total_weight += Number(item.weight)
                        team.total_catches ++
                        team.members.push(current_member)
                    } else {
                        current_member.catches.push({
                            species_id: item.species_id,
                            common_name: item.common_name,
                            length: item.length,
                            weight: item.weight,
                            date: item.date,
                            image: item.image_name
                        })
                        if(Number(item.length) > Number(team.longest_catch)){
                            team.longest_catch = item.length
                            team.l_leader_name = item.alias
                            team.l_leader_id = item.member
                        }if(Number(item.weight) > Number(team.heaviest_catch)){
                            team.heaviest_catch = item.weight
                            team.h_leader_name = item.alias
                            team.h_leader_id = item.member
                            
                        }
                        team.total_length += Number(item.length)
                        team.total_weight += Number(item.weight)
                        team.total_catches ++
                        // console.log(current_member.catches)
                    }
                })
                // when we know better sort by we can sort at beginning?
                // this.fixed_teams.sort((a,b) => b.longest_catch - a.longest_catch)
                this.sortBy(this.competition.score_type)
                this.subtotal()
            })
            .finally(() => {
                setTimeout(()=> {
                    this.button_disabled = false
                }, 3000)
            })
        },
        showImage(image){
            // this.$store.dispatch('leaderboard/setImageName')
            // this.$store.catch_image = this.$store.catch_image
            this.image_name = image
            this.image_overlay = true
            // this.$store.dispatch('leaderboard/setImageName', image)
            // .then(() => {
                // console.log(this.$store.image_name)
                // this.$store.dispatch('leaderboard/getImage', image)
                // .then(() => {
                //     this.image_file = this.$store.catch_image
                // })
            // })
        },
        showRemaining(){
            const timer = setInterval(()=> {
                const now = new Date()
                const end = this.blackout_end
                const distance = end.getTime() - now.getTime()

                if(distance < 0 ){
                    clearInterval(timer)
                    this.blackout = false
                    this.updateCatches()
                    return
                }

                const days = Math.floor((distance / this._days))
                const hours = Math.floor((distance % this._days) / this._hours)
                const minutes = Math.floor((distance % this._hours) / this._minutes)
                const seconds = Math.floor((distance % this._minutes) / this._seconds)
                this.countdownMins = minutes < 10 ? "0" + minutes : minutes
                this.countdownSecs = seconds < 10 ? "0" + seconds : seconds
                this.countdownHours = hours < 10 ? "0" + hours : hours
                this.countdownDays = days < 10 ? "0" + days : days
            }, 1000)
        },
        scoreByValue(score_type){
            let temp = ""
            switch(score_type){
                case '1':
                    return "Total Length";
                case '2':
                    return "Total Weight";
                case '3':
                    return "Total Fish Caught";
                case '6':
                    return "Longest Fish";
                case '7':
                    return "Heaviest Fish";
                case '4':
                    temp = this.competition.amount + " Longest Fish"
                    return temp;
                case '5':
                    temp = this.competition.amount + " Heaviest Fish"
                    return temp;
            }
        },
        findSidePotLeader(sidepot){
            // let leader_name = null
            // let leader_id = null
            // let fish_name = null
            // let fish_value = 0
            let temp_sidepot= {
                score: sidepot.score,
                winner_id: sidepot.winner_id,
                payout: sidepot.payout,
                leader: null,
                leader_id: null,
                fish_value: 0.00,
            }
            this.fixed_teams.forEach(item => {
                // length
                if(Number(sidepot.score) === 1){
                    if(Number(item.longest_catch) > Number(temp_sidepot.fish_value)){
                        temp_sidepot.fish_value = item.longest_catch
                        temp_sidepot.leader = item.l_leader_name
                        temp_sidepot.leader_id = item.l_leader_id
                        // temp_sidepot.temp = "length"
                        
                    }
                }
                // weight
                else{
                    if(Number(item.heaviest_catch) > Number(temp_sidepot.fish_value)){
                        temp_sidepot.fish_value = item.heaviest_catch
                        temp_sidepot.leader = item.h_leader_name
                        temp_sidepot.leader_id = item.h_leader_id
                        // temp_sidepot.temp = "weight"
                    }
                }
            })
            this.fixed_sidepot.push(temp_sidepot)
            // console.log(sidepot)
            // console.log(temp_sidepot)
        },
    },
    mounted() {
        window.scrollTo(0,0);

        let id = this.$route.params.id
        this.$store.selected_competition = id
        let temp_date = new Date()
        if(this.comp_blackout){
            this.comp_blackout.forEach(blackout => {
                
                // console.log(blackout)
                // console.log(temp_date.getTime())
    
                let temp_blackout_start = new Date(blackout.start_date)
                let temp_blackout_end = new Date(blackout.end_date)
                if(temp_date.getTime() >= temp_blackout_start.getTime() && temp_date.getTime() < temp_blackout_end.getTime()){
                    this.blackout = true
                    this.blackout_end = temp_blackout_end
                    this.showRemaining()
    
                    // console.log("the Blackout ends on: " + this.blackout_end)
            }
            })

        }
        if(!this.blackout){
            this.$store.dispatch('leaderboard/getCompTeams')
            .then(() => {
                // console.log(this.comp_teams)
                // Building team objects to use for expansion view
                var temp_id = null
                var team = {}
                var current_member = {}
                this.comp_teams.forEach(item => {
                    if(item.id !== temp_id){
                        team = {
                            id: item.id,
                            name: item.name,
                            members:[],
                            l_leader_name:null,
                            l_leader_id:null,
                            h_leader_name:null,
                            h_leader_id:null,
                            longest_catch:0,
                            heaviest_catch:0,
                            total_length:0.0,
                            total_weight:0.0,
                            total_catches:0,
                            subtotals: []
                        }
                        current_member = {
                            member_id: item.member,
                            alias: item.alias,
                            catches:[{
                                species_id: item.species_id,
                                common_name: item.common_name,
                                length: item.length,
                                weight: item.weight,
                                date: item.date,
                                image: item.image_name
                            }]
                        }
                        team.longest_catch = item.length
                        team.heaviest_catch = item.weight
                        team.l_leader_name = item.alias
                        team.l_leader_id = item.member
                        team.h_leader_name = item.alias
                        team.h_leader_id = item.member
                        team.total_length += Number(item.length)
                        team.total_weight += Number(item.weight)
                        team.total_catches ++
                        team.members.push(current_member)
                        this.fixed_teams.push(team)
                        this.panels++
                        temp_id = item.id
                    } else if(current_member.member_id !== item.member) {
                        current_member = {
                            member_id: item.member,
                            alias: item.alias,
                            catches:[{
                                species_id: item.species_id,
                                common_name: item.common_name,
                                length: item.length,
                                weight: item.weight,
                                date: item.date,
                                image: item.image_name
                            }]
                        }
                        if(Number(item.length) > Number(team.longest_catch)){
                            team.longest_catch = item.length
                            team.l_leader_name = item.alias
                            team.l_leader_id = item.member
                        }if(Number(item.weight) > Number(team.heaviest_catch)){
                            team.heaviest_catch = item.weight
                            team.h_leader_name = item.alias
                            team.h_leader_id = item.member
                        }
                        team.total_length += Number(item.length)
                        team.total_weight += Number(item.weight)
                        team.total_catches ++
                        team.members.push(current_member)
                    } else {
                        current_member.catches.push({
                            species_id: item.species_id,
                            common_name: item.common_name,
                            length: item.length,
                            weight: item.weight,
                            date: item.date,
                            image: item.image_name
                        })
                        if(Number(item.length) > Number(team.longest_catch)){
                            team.longest_catch = item.length
                            team.l_leader_name = item.alias
                            team.l_leader_id = item.member
                        }if(Number(item.weight) > Number(team.heaviest_catch)){
                            team.heaviest_catch = item.weight
                            team.h_leader_name = item.alias
                            team.h_leader_id = item.member
                        }
                        team.total_length += Number(item.length)
                        team.total_weight += Number(item.weight)
                        team.total_catches ++
                        // console.log(current_member.catches)
                    }
                })
                // when we know better sort by we can sort at beginning?
                // this.fixed_teams.sort((a,b) => b.longest_catch - a.longest_catch)

                this.subtotal()
                this.sortBy(this.competition.score_type)
                if(this.comp_sidepot!== null){
                    this.comp_sidepot.forEach(pot => {
                        this.findSidePotLeader(pot)
                    })
                }
                
                // console.log(this.competition)
                // console.log(this.comp_species)
                // console.log(this.comp_teams)
                // console.log(this.fixed_teams)
            })
            .finally(() => {
                setTimeout(()=> {
                    this.button_disabled = false
                }, 3000)
            })
        }

        // let fixed_end = this.competition.end_date + ", " + this.competition.end_time
        //** Will need to come back to this when timezone issue is decided or datetime is changed on DB
        let today = new Date().toLocaleDateString('en-CA',{year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: false, minute:'2-digit' })
        // this.delay_date = today.split(',')[0]

        // let fixed_date = today.toLocaleDateString('en-CA')
        // console.log(fixed_end)
        // console.log(today)
        
        if(today >= this.competition.end_date || this.competition.status == 10){
            this.ended = true
            // console.log("inner if")
        }
    }
}
</script>
