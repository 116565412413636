<template>
  <v-card
    class="ma-5 pa-4"
  >
    <v-card-title>
      User Registration and Subscription
    </v-card-title>
    <v-card-text class="text-body-1">
      All users of Ice Time&trade; register. Registration only needs an email address and a password. Your continued use requires email validation within 14 days of first use. If you order the latest radar ice map on-demand or engage in a competition from within a Ice Time Free&trade; license, or enter a seasonal subscription, you'll provide your name and credit card information at that time. Ice Time&trade; transactions use the trusted Stripe merchant service widely used in most countries of the world today.
    </v-card-text>
    <v-card-text>
      ( Figure – screen grab of registration and subscription plans )
    </v-card-text>
  </v-card>
</template>
  
  <script>
  export default { 
    data() {
      return {
      }
    },
    methods: {
    }
  }
  </script>
  