<template>
  <v-container fluid pa-0 fill-height>
    <l-map 
      v-if="isLoggedIn" 
      ref="map" 
      style="height:100%; z-index: 0!important;"
      :minZoom="minZoom"
      :options="mapOptions"
    >
      <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      <l-control class="custom-control gps-control" position="topright">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon @click.prevent="infoDialog = true"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <span>Information</span>
        </v-tooltip>
      </l-control>

      <l-control class="custom-control gps-control" position="topright">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon @click.prevent="showAll"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-arrow-expand-all</v-icon>
            </v-btn>
          </template>
          <span>Pan to All Regions</span>
        </v-tooltip>
      </l-control>

      <l-control v-if="isLoggedIn && !unsubscribed" class="custom-control gps-control" position="topright">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon @click.prevent="rebound"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-arrow-collapse-all</v-icon>
            </v-btn>
          </template>
          <span>Pan to Available Imagery</span>
        </v-tooltip>
      </l-control>

      <!-- <l-feature-group ref="polygons">
        <l-geo-json
          v-for="region in polygons"
          :key="region.id"
          :geojson="region.geometry.geometry"
          :options="polygonOptions"
        />
      </l-feature-group> -->

      <l-feature-group ref="regions_online" @ready="rebound" v-if="isLoggedIn">
        <l-feature-group ref="subscribed">
          <l-geo-json
            v-for="region in subscribed"
            :key="region.index"
            :geojson="region" 
            :options="overlayOptionsSubbed"
          />
        </l-feature-group>

        <l-feature-group ref="unsubscribed">
          <l-geo-json
            v-for="region in unsubscribed"
            :key="region.index"
            :geojson="region" 
            :options="overlayOptions"
          />
        </l-feature-group>
      </l-feature-group>

      <l-feature-group ref="regions_offline" @ready="rebound" v-else>
        <l-geo-json
          v-for="region in regions_geojson"
          :key="region.index"
          :geojson="region" 
          :options="overlayOptionsOffline"
        />
      </l-feature-group>
    </l-map>

    <v-dialog v-model="infoDialog" scrollable max-width="500px" width="unset">
      <v-card class="card-outter pt-16 pb-9" style="overflow-y: auto;">
        <v-divider></v-divider>
        <v-container class="pb-0" >
          <v-card-text class="text-h6">
            Regions and Footprints for the 2021/2022 Season
          </v-card-text>
          <v-card-text class="pb-0">
            Footprints you are subscribed to are marked in <v-chip color="green" class="white--text font-weight-bold py-0 px-2" small>green</v-chip> and will direct you to a map when clicked, else they are <v-chip color="lightgrey" class="font-weight-bold grey-darken-2--text py-0 px-2" small>greyed-out</v-chip>. <span v-if="isLoggedIn">Consider subscribing to more regions, or voting for increased coverage:</span><span v-else>Register now to start subscribing, or vote for future coverage areas:</span>
          </v-card-text>
        </v-container>

        <v-container class="pb-0 pt-7 px-7">
          <v-btn v-if="!isLoggedIn" class="ma-2" to="/purchase">
            <v-icon class="pr-1" small>fa-sign-in-alt</v-icon> Login
          </v-btn>
          <v-btn v-if="isLoggedIn" class="ma-2" to="/purchase">
            <v-icon class="pr-1" small>mdi-plus</v-icon> Subscribe
          </v-btn>
          <v-btn v-else class="ma-2" to="/signup">
            <v-icon class="pr-1" x-small>fa-user-plus</v-icon> Register
          </v-btn>
          <v-btn class="ma-2" href="https://www.icetime.app/near-you" target="_blank">
            <v-icon class="pr-1" small>mdi-exit-to-app</v-icon> Vote for Coverage</v-btn>
        </v-container>

        <v-card-actions class="card-actions pt-4">
          <v-btn @click.prevent="infoDialog = false" text class="grey--text">
            Close
            <v-icon right color="grey">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { LControl,LMap,LTileLayer,LGeoJson,LFeatureGroup } from 'vue2-leaflet'
// import axios from 'axios'

export default {
  data() {
    return {
      regions_geojson: [],
      infoDialog: false,
      mapOptions: {
        zoomSnap: 0.1,
        wheelPxPerZoomLevel: 120
      },
      minZoom: 2,
      overlayOptions: {
        style: {
          color: '#9E9E9E',
          fillColor: '#9E9E9E',
          fillOpacity: 0.3,
          opacity: 1
        },
        onEachFeature: function onEachFeature(feature, layer) {
          // layer.bindPopup("You are not subscribed to the <i>" + feature.properties.region_name + "</i> region. <a href='/purchase'>Click here to subscribe</a>.")
          layer.bindPopup("You are not subscribed to the <i>" + feature.properties.region_name + "</i> region.<br><a href=''>Click here to subscribe (Coming Soon)</a>.")        
        }
      },
      overlayOptionsOffline: {
        style: {
          color: '#9E9E9E',
          fillColor: '#9E9E9E',
          fillOpacity: 0.3,
          opacity: 1
        },
        onEachFeature: function onEachFeature(feature, layer) {
          layer.bindPopup("<a href='/login'>Log in</a> or <a href='/signup'>register now</a> to subscribe to the <i>" + feature.properties.region_name + "</i> region.")
        }
      },
      overlayOptionsSubbed: {
        style: {
          color: '#4CAF50',
          fillColor: '#00C853',
          fillOpacity: 0.3
        },
        onEachFeature: function onEachFeature(feature, layer) {          
          var text = "<p><b>Footprint Name:</b> " + feature.properties.name
          text += "<br><b>Newest Image Date:</b> " + feature.properties.date
          text += "</p>"
          text += "<a href='/app/radar/" + feature.properties.region + "/" + feature.properties.footprint_id + "'>View Image</a>"
          layer.bindPopup(text)
        }
      },
      polygonOptions: {
        style: {
          color: '#9E9E9E',
          fillColor: '#9E9E9E',
          fillOpacity: 0.3,
          opacity: 1
        },
      },
    }
  },
  components: {
    LControl,
    LMap,
    LGeoJson,
    LTileLayer,
    LFeatureGroup
  },
  computed: {
    ...mapState(['tab']),
    ...mapGetters('user', {
      isLoggedIn: 'isLoggedIn',
      isStaffUser: 'isStaffUser'
    }),
    ...mapState('map', {
      maps: state => state.maps,
      polygons: state => state.polygons,
      layers: state => state.layers,
    }),
    // isLoggedIn() {
    //   return this.$store.getters.isLoggedIn;
    // },
    // isStaffUser() {
    //   return this.$store.getters.isStaffUser;
    // },
    subscribed() {      
      if (this.isStaffUser) {
        return this.regions_geojson
      } else {
        return this.regions_geojson.filter(region => {
          return this.subscriptions_ids.includes(region.features[0].properties.region)
        })
      }
    },
    subscriptions_ids() {
      // Trial Period
      return ["1", "19", "1026", "3000"]
      // return this.maps.map(function(el) {
      //   return parseInt(el.radar_id)
      // })
    },
    unsubscribed() {
      if (this.isStaffUser) {
        return []
      } else {
        return this.regions_geojson.filter(region => {
          return !this.subscriptions_ids.includes(region.features[0].properties.region)
        })
      }
    }
  },
  updated() {
    this.rebound()
  },
  created() {
    // Map IDs for available regions
    var map_ids = [1, 19, 1026, 3000]
    this.$store.dispatch('map/getMaps')

    // Loop though layers, retrieve geojson outline, add to this.regions_geojson
    map_ids.forEach((id) => {
      this.$store.dispatch('map/getLayers', {id})
      .then(() => {
        var newest_layers = this.layers.reduce((a, fp) => {
          if(!(fp.footprint_id in a)) {
            a[fp.footprint_id] = fp;
          } else {
            a[fp.footprint_id] = Date.parse(fp.date) > Date.parse(a[fp.footprint_id].date) ? fp : a[fp.footprint_id]
          }
          return a;
        }, {})
        // console.log(newest_layers)
        for (let layer in newest_layers) {
          if (newest_layers[layer].geojson) {
            // console.log('Pushing: ' + layer.id)
            var image_geojson = JSON.parse(newest_layers[layer].geojson)   
            image_geojson.features[0]["properties"]["region"] = newest_layers[layer].map_id
            image_geojson.features[0]["properties"]["footprint_id"] = newest_layers[layer].id
            image_geojson.features[0]["properties"]["date"] = newest_layers[layer].date     
            image_geojson.features[0]["properties"]["name"] = newest_layers[layer].name    
            image_geojson.features[0]["properties"]["region_name"] = newest_layers[layer].region_name                   
            this.regions_geojson.push(image_geojson)
          }
        }       
      })
    })      
    // this.$store.dispatch('map/getPolygons')
    // .finally(() => {
    //   this.$refs.map.mapObject.fitBounds(this.$refs.polygons.mapObject.getBounds())
    // })
    
  },
  methods: {
    rebound() {      
      if(this.regions_geojson.length > 0) {
        if (this.isLoggedIn) {
          if (this.subscribed.length > 0) {
            this.$refs.map.mapObject.fitBounds(this.$refs.subscribed.mapObject.getBounds())
          } else {
            this.$refs.map.mapObject.fitBounds(this.$refs.unsubscribed.mapObject.getBounds())
          }
        } else {
          this.$refs.map.mapObject.fitBounds(this.$refs.regions_offline.mapObject.getBounds())
        }
      }

    },
    showAll() {
      if(this.regions_geojson.length > 0) {
        if (this.isLoggedIn) {
          this.$refs.map.mapObject.fitBounds(this.$refs.regions_online.mapObject.getBounds())
        } else {
          this.$refs.map.mapObject.fitBounds(this.$refs.regions_offline.mapObject.getBounds())
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch('setMode', 'regions')    
    this.$store.dispatch('setTab', '')    
  }
}
</script>

<style scoped>
>>>.custom-control {
  background: #fff;
  padding: 5px;
  border: 1px solid #aaa;
  border-radius: 5px;
}

>>>.v-dialog {
    position: absolute;
    bottom: 0;
    right: 0;
}

>>>.card-outter {
  position: relative;
  padding-bottom: 1.5em;
}

>>>.card-actions {
  position: absolute;
  top: 0;
  right:0;
}
</style>