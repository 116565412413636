import User from './User.vue'
import Billing from './views/Billing.vue'
import Profile from './views/Profile.vue'
// import Purchase from './views/Purchase.vue'
import UserPreferences from './views/UserPreferences.vue'
import UserCard from './views/UserCard.vue'
import ChangePassword from './views/ChangePassword.vue'
import Verify from './views/Verify.vue'
import CVTest from './views/CVTest.vue'

import store from '../../store'

export const UserRoutes = [
  {
    path: '/user',
    component: User,
    meta: {
      requiresAuth: true
    },
    beforeEnter(to, from, next) {
      if (store.getters['user/isLoggedIn']) {
        next()
        return
      } else {
        next({
          name: "login" // back to safety route //
        })
      }
    },      
    children: [
      {
        path: 'billing',
        name: 'User Billing',
        component: Billing
      },
      {
        path: 'profile',
        name: 'User Account',
        component: Profile
      },
      // {
      //   path: 'purchase',
      //   name: 'User Purchases',
      //   component: Purchase
      // },
      {
        path: 'preferences',
        name: 'User Preferences',
        component: UserPreferences
      },
      {
        path: 'card',
        name: 'User Card',
        component: UserCard
      },
      {
        path: 'fishingtest',
        name: 'CV Test Page',
        component: CVTest
      },
      {
        path: 'password',
        name: 'Change Password',
        component: ChangePassword
      },
      // {
      //   path: 'verify/:token?',
      //   name: 'Account Verification',
      //   component: Verify,
      //   props: true,
      // }
    ]
  },
  {
    path: '/user/verify/:token?',
    name: 'Account Verification',
    component: Verify,
    props: true,
  }
]