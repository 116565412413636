import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

// If API is needed
Vue.use(Vuex)
axios.defaults.baseUrl = Vue.$apiEndpointUrl

const state = {
  // access_point_conditions: [],
  // access_point_editing: false,
  // access_point_new_location: [],
  // access_point_filter_conditions: [],
  // access_points: [],
  clickedLocation: '',
  fishing_spots: [],
  all_fishing_spots: [],
  user_fishing_spots: [],
  closest_spot: {},
  fishing_spot_editing: false,
  catches: [],
  selected_catch: [],
  species_with_favorite: [],
  group_catches: [],
  geopost_filter_themes: [],
  // geopost_filter_time: '',
  pointAddMode: 'none',
  // selected_condition: '',
  selected_geopost: '',
  selected_geopost_comments: [],
  selected_fishing_spot: [],
  species: [],
  selectedCursor: 'default',
  joined_comps: '',
  added_to_comp: '',
};

const getters = {
};

const mutations = {
  // setAccessPointConditions(state, conditions) {
  //   state.access_point_conditions = conditions
  // },
  // setAccessPointEditing(state, editing) {
  //   state.access_point_editing = editing
  // },
  // setAccessPointFilterConditions(state, conditions) {
  //   state.access_point_filter_conditions = conditions
  // },
  // setAccessPointEditing(state, editing) {
  //   state.access_point_editing = editing
  // },
  // setAccessPointNewLocation(state, location) {
  //   state.access_point_new_location = location
  // },
  setFishingSpotEditing(state, editing) {
    state.fishing_spot_editing = editing
  },
  // setAccessPoints(state, access_points) {
  //   access_points.forEach(ap => {
  //     let votes = []
  //     ap['votes_sum'] = 0
  //     ap['data'] = [
  //       { condition_id: 1, name: 'Impassable', votes: parseInt(ap['1']) },
  //       { condition_id: 2, name: 'Foot', votes: parseInt(ap['2']) },  
  //       { condition_id: 3, name: 'Quad', votes: parseInt(ap['3']) }, 
  //       { condition_id: 4, name: 'Snowmobile', votes: parseInt(ap['4']) }, 
  //       { condition_id: 5, name: '2WD', votes: parseInt(ap['5']) }, 
  //       { condition_id: 6, name: '4WD', votes: parseInt(ap['6']) }
  //     ]
  //     ap['data'].forEach(x => {
  //       ap['votes_sum'] += x.votes
  //     })
  //     ap['data'].forEach((y, i) => {
  //       votes[i] = y.votes
  //     })

  //     let max = Math.max(...votes)
  //     let conditions = [] // condition_ids that have the most votes
  //     ap['data'].forEach((item) => item.votes === max ? conditions.push(item.condition_id):null)
  //     ap['popular_votes'] = conditions

  //     delete ap['1']
  //     delete ap['2']
  //     delete ap['3']
  //     delete ap['4']
  //     delete ap['5']
  //     delete ap['6']
  //   })
  //   state.access_points = access_points
  // },
  setFishingSpots(state, fishing_spots) {
    state.fishing_spots = fishing_spots
  },
  setAllFishingSpots(state, all_fishing_spots) {
    state.all_fishing_spots = all_fishing_spots
  },
  setUserFishing(state, fishing_spots) {
    state.user_fishing_spots = fishing_spots
  },
  setClosestSpot(state, closest_spot) {
    state.closest_spot = closest_spot
  },
  setCatches(state, catches) {
    state.catches = catches
  },
  setSelectedCatch(state, selected_catch) {
    state.selected_catch = selected_catch
  },
  setGroupCatches(state, groupCatches) {
    state.group_catches = groupCatches
  },
  setGeopostFilterThemes(state, themes) {
    state.geopost_filter_themes = themes
  },
  setGeopostFilterTime(state, time) {
    state.geopost_filter_time = time
  },
  // setSelectedCondition(state, condition) {
  //   state.selected_condition = condition
  // },
  setSelectedGeopost(state, geopost_id) {
    state.selected_geopost = geopost_id
  },
  setSelectedFishingSpot(state, fishing_spot_id) {
    state.selected_fishing_spot = fishing_spot_id
    // console.log(fishing_spot_id)
  },
  setSelectedGroupCatchs(state, GroupCatches) {
    state.selected_group_catches = GroupCatches
  },
  setSelectedGeopostComments(state, comments) {
    state.selected_geopost_comments = comments
  },
  setSpecies(state, species) {
    state.species = species
  },
  setSelectedCursor(state, cursor){
    state.selectedCursor = cursor
  },
  setClickedLocation(state, location){
    state.clickedLocation = location
  },
  setPointAddMode(state, mode){
    state.pointAddMode = mode
  },
  setJoinedComps(state, joined){
    state.joined_comps = joined
  },
  setAddedToComp(state, comp){
    state.added_to_comp = comp
  },
  setSpeciesWithFavorite(state, species){
    state.species_with_favorite = species
  },
};

const actions = {
  createComment(_, comment) {
    return new Promise((resolve,reject) => {
      axios.post('geopost_comments', comment)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  // createVote(_, vote) {
  //   return new Promise((resolve, reject) => {
  //     axios.post('vote', vote)
  //     .then(resp => {
  //       resolve(resp)
  //     })
  //     .catch(err => {
  //       reject(err)
  //     })
  //   })
  // },
  // deleteAccessPoint(_, geopost_id) {
  //   return new Promise((resolve, reject) => {
  //     axios.delete('apc/' + geopost_id)
  //     .then(resp => {
  //       resolve(resp)
  //     })
  //     .catch(err => {
  //       reject(err)
  //     })
  //   })
  // },
  deleteCatch(_, catchItem) {
    return new Promise((resolve, reject) => {
      axios.delete('catch/' + catchItem.id)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  // getAccessPointConditions({commit}) {
  //   return new Promise((resolve,reject) => {
  //     axios.get('conditions')
  //     .then(resp => {
  //       let data = resp.data ? resp.data:[]
  //       commit('setAccessPointConditions', Object.values(data))
  //       resolve(resp)
  //     })
  //     .catch(err => {
  //       reject(err)
  //     })
  //   })
  // },
  // getAccessPoints({commit,state}) {
  //   return new Promise((resolve,reject) => {
  //     let req = state.geopost_filter_time ? ('/' + state.geopost_filter_time):''
  //     axios.get('apc' + req)
  //     .then(resp => {
  //       let data = resp.data ? resp.data:[]
  //       commit('setAccessPoints', Object.values(data))
  //       resolve(resp)
  //     })
  //     .catch(err => {
  //       reject(err)
  //     })
  //   })
  // },
  getFishingSpots({ commit }) {
    return new Promise((resolve, reject) => {
      // let req = state.geopost_filter_time ? ('/' + state.geopost_filter_time):''
      axios.get('fishing')
      .then(resp => {
        let data = resp.data ? resp.data:[]
        commit('setFishingSpots', Object.values(data))
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getSpeciesWithFavorite({ commit }) {
    return new Promise((resolve, reject) => {
      // let req = state.geopost_filter_time ? ('/' + state.geopost_filter_time):''
      axios.get('species_with_favorite')
      .then(resp => {
        let data = resp.data ? resp.data:[]
        commit('setSpeciesWithFavorite', Object.values(data))
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getAllFishingSpots({ commit }) {
    return new Promise((resolve, reject) => {
      // let req = state.geopost_filter_time ? ('/' + state.geopost_filter_time):''
      axios.get('allfishing')
      .then(resp => {
        let data = resp.data ? resp.data:[]
        commit('setAllFishingSpots', Object.values(data))
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getClosestSpot({ commit }, current_location) {
    return new Promise((resolve, reject) => {
      axios.get('closest_spot/' + current_location)
      .then(resp => {
        let data = resp.data ? resp.data:{}
        commit('setClosestSpot', Object.values(data))
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getUserFishing({ commit }, selected_user) {
    return new Promise((resolve, reject) => {
      axios.get('user_fishing/' + selected_user)
      .then(resp => {
        let data = resp.data ? resp.data:{}
        commit('setUserFishing', Object.values(data))
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getJoinedComps({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('joined_competitions')
      .then(resp => {
        let data = resp.data ? resp.data:[]
        commit('setJoinedComps', Object.values(data))
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  // getCatches({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     let req = state.geopost_filter_time ? ('/' + state.geopost_filter_time):''
  //     axios.get('catches' + req)
  //     .then(resp => {
  //       commit('setCatches', Object.values(resp.data))
  //       resolve(resp)
  //     })
  //     .catch(err => {
  //       reject(err)
  //     })
  //   })
  // },
  getCatches({ commit }, spot_id ) {
    return new Promise((resolve, reject) => {
      axios.get('catches/' + spot_id)
      .then(resp => {
        if (resp.data) {
          commit('setCatches', Object.values(resp.data))
        } else {
          commit('setCatches', [])
        }
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getCatch({ commit }, catch_id ) {
    return new Promise((resolve, reject) => {
      axios.get('catch/' + catch_id)
      .then(resp => {
        if (resp.data) {
          commit('setSelectedCatch', Object.values(resp.data))
        } else {
          commit('setSelectedCatch', [])
        }
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getGroupCatches({ commit }, spot_id ) {
    return new Promise((resolve, reject) => {
      axios.get('group_catches/' + spot_id)
      .then(resp => {
        if (resp.data) {
          commit('setGroupCatches', Object.values(resp.data))
        } else {
          commit('setGroupCatches', [])
        }
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getSelectedGeopostComments({commit}, geopost_id) {
    return new Promise((resolve, reject) => {
      axios.get('geopost_comments/' + geopost_id)
      .then(resp => {
        if (resp.data) {
          commit('setSelectedGeopostComments', Object.values(resp.data))
        } else {
          commit('setSelectedGeopostComments', [])
        }
        resolve(resp)
      })
      .catch(err => {{
        reject(err)
      }})
    })
  },
  getSpecies({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('species')
      .then(resp => {
        commit('setSpecies', Object.values(resp.data))
        resolve(resp)
    })
      .catch(err => {
        reject(err)
      })
    })
  },
  recordAccessPoint(_, apcItem) {
    return new Promise((resolve,reject) => {
      axios.post('apc', apcItem)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  recordFishingSpot({ commit }, spotItem) {
    return new Promise((resolve, reject) => {
      axios.post('fishing', spotItem)
      .then(resp => {
        // console.log(resp)
        // console.log(resp.data)
        // console.log(Object.values(resp.data))
        commit('setSelectedFishingSpot', resp.data)
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  recordGPSSpot(_, gpsItem) {
    return new Promise((resolve, reject) => {
      axios.post('gpsspot', gpsItem)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  recordCatch(_, catchItem) {
    return new Promise((resolve, reject) => {
      axios.post('catch', catchItem)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  uploadCommentImage(_, data) {
    return new Promise((resolve, reject) => {
      axios.post('uploadapcommentimage', data)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  measure(_, id) {
    return new Promise((resolve, reject) => {
      axios.post('testmeasure', id)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  // setAccessPointEditing({commit}, editing) {
  //   return new Promise((resolve) => {
  //     commit('setAccessPointEditing', editing)
  //     resolve(editing)
  //   })
  // },
  setAccessPointFilterConditions({commit}, conditions) {
    return new Promise((resolve) => {
      commit('setAccessPointFilterConditions', conditions)
      resolve(conditions)
    })
  },
  setClickedLocation({commit}, location) {
    return new Promise((resolve) => {
      commit('setClickedLocation', location)
      resolve(location)
    })
  },
  setFishingSpotEditing({commit}, editing) {
    return new Promise((resolve) => {
      commit('setFishingSpotEditing', editing)
      resolve(editing)
    })
  },
  // setAccessPointNewLocation({commit}, location) {
  //   return new Promise((resolve) => {
  //     commit('setAccessPointNewLocation', location)
  //     resolve(location)
  //   })
  // },
  setGeopostFilterThemes({commit}, themes) {
    return new Promise((resolve) => {
      commit('setGeopostFilterThemes', themes)
      resolve(themes)
    })
  },
  setGeopostFilterTime({commit}, time) {
    return new Promise((resolve) => {
      commit('setGeopostFilterTime', time)
      resolve(time)
    })
  },
  setPointAddMode({commit}, mode) {
    return new Promise((resolve) => {
      commit('setPointAddMode', mode)
      resolve(mode)
    })
  },
  // setSelectedCondition({commit}, condition) {
  //   return new Promise((resolve) => {
  //     commit('setSelectedCondition', condition)
  //     resolve(condition)
  //   })
  // },
  setSelectedCursor({commit}, cursor) {
    return new Promise((resolve) => {
      commit('setSelectedCursor', cursor)
      resolve(cursor)
    })
  },
  setSelectedGeopost({commit}, geopost_id) {
    return new Promise((resolve) => {
      commit('setSelectedGeopost', geopost_id)
      resolve(geopost_id)
    })
  },
  setSelectedFishingSpot({commit}, spot_id) {
    return new Promise((resolve) => {
      commit('setSelectedFishingSpot', spot_id)
      resolve(spot_id)
    })
  },
  setAddedToComp({commit}, comp_id) {
    return new Promise((resolve) => {
      commit('setAddedToComp', comp_id)
      resolve(comp_id)
    })
  },
  updateRegional(_, data) {
    return new Promise((resolve, reject) => {
      axios.post('regionalleaderboard', data)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  // updateAccessPoint(_, point_data) {
  //   return new Promise((resolve,reject) => {
  //     axios.post('apc_update', point_data)
  //     .then(resp => {
  //       resolve(resp)
  //     })
  //     .catch(err => {
  //       reject(err)
  //     })
  //   })
  // }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}