<template>
  <span>
    <l-feature-group :visible="isPersonalHazardVisible && isZoomWithinDropInRange">
      <l-marker v-for="pin in filteredPersonalPins" :key="pin.index" :lat-lng="[pin.lat, pin.lng]" :icon="pin.icon" :opacity="pin.opacity">
        <l-popup class="py-2">
          <span class="text-body-1">{{ pin.popup }}</span><br />
          <span class="text-caption">Created by 
            <span v-if="pin.user_id == $store.state.user.id" class="text-subtitle-2 font-italic">You</span>
            <span v-else-if="pin.user_type == 9" class="text-subtitle-2">Admin</span>
          </span>
          <br /><span class="text-caption">Placed on {{ pin.date }}</span>
          <br /><span class="text-caption">Lat {{ pin.lat }}</span>
          <br /><span class="text-caption">Long {{ pin.lng }}</span>
          
          <v-row
            v-if="isAdmin == true || pin.user_id == $store.state.user.id"
            class="mt-1"
          >
            <v-btn
              class="text-caption"
              color="red"
              text
              @click.prevent="deleteHazard(pin)"
              :loading = "delete_loading"
            >
              Delete
            </v-btn>
          </v-row>
        </l-popup>
      </l-marker>
    </l-feature-group>

    <l-feature-group :visible="isUserHazardVisible && isZoomWithinDropInRange">
      <l-marker v-for="pin in filteredUserPins" :key="pin.index" :lat-lng="[pin.lat, pin.lng]" :icon="pin.icon" :opacity="pin.opacity">
        <l-popup class="py-2">
          <span class="text-body-1">{{ pin.popup }}</span><br />
          <span class="text-caption">Created by 
            <span v-if="pin.user_id == $store.state.user.id" class="text-subtitle-2 font-italic">You</span>
            <span v-else class="text-subtitle-2">User</span>
          </span>
          <br /><span class="text-caption">Placed on {{ pin.date }}</span>
          <br /><span class="text-caption">Lat {{ pin.lat }}</span>
          <br /><span class="text-caption">Long {{ pin.lng }}</span>

          <v-row
            v-if="isAdmin == true || pin.user_id == $store.state.user.id"
            class="mt-1"
          >
            <v-btn
              class="text-caption"
              color="red"
              text
              @click.prevent="deleteHazard(pin)"
              :loading = "delete_loading"
            >
              Delete
            </v-btn>
          </v-row>
        </l-popup>
      </l-marker>
    </l-feature-group>

    <l-feature-group :visible="isProHazardVisible && isZoomWithinDropInRange">
      <l-marker v-for="pin in filteredProPins" :key="pin.index" :lat-lng="[pin.lat, pin.lng]" :icon="pin.icon" :opacity="pin.opacity">
        <l-popup class="py-2">
          <span class="text-body-1">{{ pin.popup }}</span><br />
          <span class="text-caption">Created by
            <span v-if="pin.user_id == $store.state.user.id" class="text-subtitle-2 font-italic">You</span>
            <span v-else class="text-subtitle-2">User</span>
          </span>
          <br /><span class="text-caption">Placed on {{ pin.date }}</span>
          <br /><span class="text-caption">Lat {{ pin.lat }}</span>
          <br /><span class="text-caption">Long {{ pin.lng }}</span>

          <v-row
            v-if="isAdmin == true || pin.user_id == $store.state.user.id"
            class="mt-1"
          >
            <v-btn
              class="text-caption"
              color="red"
              text
              @click.prevent="deleteHazard(pin)"
              :loading = "delete_loading"
            >
              Delete
            </v-btn>
          </v-row>
        </l-popup>
      </l-marker>
    </l-feature-group>

    <l-feature-group :visible="isAdminHazardVisible && isZoomWithinDropInRange">
      <l-marker v-for="pin in filteredAdminPins" :key="pin.index" :lat-lng="[pin.lat, pin.lng]" :icon="pin.icon" :opacity="pin.opacity">
        <l-popup class="py-2">
          <span class="text-body-1">{{ pin.popup }}</span><br />
          <span class="text-caption">Created by 
            <span v-if="pin.user_id == $store.state.user.id" class="text-subtitle-2 font-italic">You</span>
            <span v-else class="text-subtitle-2">Admin</span>
          </span>
          <br /><span class="text-caption">Placed on {{ pin.date }}</span>
          <br /><span class="text-caption">Lat {{ pin.lat }}</span>
          <br /><span class="text-caption">Long {{ pin.lng }}</span>

          <v-row
            v-if="isAdmin == true || pin.user_id == $store.state.user.id"
            class="mt-1"
          >
            <v-btn
              class="text-caption"
              color="red"
              text
              @click.prevent="deleteHazard(pin)"
              :loading = "delete_loading"
            >
              Delete
            </v-btn>
          </v-row>
        </l-popup>
      </l-marker>

      <l-feature-group :visible="isAdminHazardVisible">
        <l-marker v-for="pin in filteredPOIPins" :key="pin.index" :lat-lng="[pin.lat, pin.lng]" :icon="pois_icons[pin.icon_index]">
          <l-popup class="py-2">
          <span class="text-caption">Created by 
            <span v-if="pin.user_id == $store.state.user.id" class="text-subtitle-2 font-italic">You</span>
            <span v-else class="text-subtitle-2">Admin</span>
          </span>
          <br /><span class="text-caption">Lat {{ pin.lat }}</span>
          <br /><span class="text-caption">Long {{ pin.lng }}</span>
          
          <v-row
            v-if="isAdmin == true"
            class="mt-1"
          >
            <v-btn
              class="text-caption"
              color="red"
              text
              @click.prevent="deleteHazard(pin)"
              :loading = "delete_loading"
            >
              Delete
            </v-btn>
          </v-row>
        </l-popup>
        </l-marker>
      </l-feature-group>
    </l-feature-group>
  </span>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { LFeatureGroup, LMarker, LPopup } from 'vue2-leaflet'
import { icon } from 'leaflet'

export default {
  components: {
    LFeatureGroup,
    LMarker,
    LPopup,
  },
  computed: {
    ...mapGetters('demo', {
      filteredAdminPins: 'filteredAdminPins',
      filteredPersonalPins: 'filteredPersonalPins',
      filteredPOIPins: 'filteredPOIPins',
      filteredProPins: 'filteredProPins',
      filteredUserPins: 'filteredUserPins',
    }),
    ...mapState('demo', {
      demo_hazards: state => state.demo_hazards,
      isAdminHazardVisible: state => state.isAdminHazardVisible,
      isPersonalHazardVisible: state => state.isPersonalHazardVisible,
      isProHazardVisible: state => state.isProHazardVisibile,
      isUserHazardVisible: state => state.isUserHazardVisible,
      visibleLayer: state => state.visibleLayer,
      
    }),
    ...mapState('user', {
      user: state => state.user
    })
    // filteredAdminPins() {
    //   let id = this.visibleLayer.id
    //   return this.adminPins.filter(function(el) {
    //     return el.layer_id < id || el.layer_id == id
    //   })
    // },
    // filteredPersonalPins() {
    //   let id = this.visibleLayer.id
    //   return this.personalPins.filter(function(el) {
    //     return el.layer_id == id
    //   })
    // },
    // filteredPOIPins() {
    //   let id = this.visibleLayer.id
    //   return this.poiPins.filter(function(el) {
    //     return el.layer_id == id 
    //   })
    // },
    // filteredProPins() {
    //   let id = this.visibleLayer.i
    //   return this.proPins.filter(function(el) {
    //     return el.layer_id == id
    //   })
    // },
    // filteredUserPins() {
    //   let id = this.visibleLayer.id
    //   return this.userPins.filter(function(el) {
    //     return  el.layer_id == id
    //   })
    // },
  },
  created() {
    let id = this.id
    this.$store.dispatch('demo/getDemoHazards', {id})
    .then(() => {
      if (this.demo_hazards.size > 0) {
        // Filter demo_hazards
        let poi_ids = ['19', '20', '21', '22', '23']
        this.demo_hazards_filtered = this.demo_hazards.filter(function(el) {
          return !(poi_ids.includes(el.hazardtype_id))
        })
        this.demo_hazards_pois = this.demo_hazards.filter(function(el) {
          return poi_ids.includes(el.hazardtype_id)
        })
        this.demo_hazards_pois.sort(function(a, b) {
          return parseInt(a.admin_order) - parseInt(b.admin_order)
        })
      
        // build user pin groups
        let pAdmin = []
        let pPersonal = []
        let pPOI = []
        let pPro = []
        let pUser = []

        if (this.demo_hazards_filtered) {
          // Create pin for each hazard
          this.demo_hazards_filtered.forEach((hazard) => {
            // Build iconUrl string
            var hazard_user_string = '';
            if (hazard.usertype_id == 1) {
              hazard_user_string = "user"  
            } else if (hazard.usertype_id == 2) {
              hazard_user_string = "pro"
            } else if (hazard.usertype_id == 9) {
              hazard_user_string = "admin"
            }
            var hazard_iconUrl = "/img/hazard_icons/" + hazard_user_string + "_" + hazard.hazardtype_icon

            // Build pop-up text
            var popup_text = hazard.hazardtype_name
            if(hazard.value) {
              if(this.itemsWithMeasurement.includes(hazard.hazardtype_id)) {
                let x = parseFloat(hazard.value)
                let y = this.user.is_metric ? (parseFloat(x.toFixed(2)) + " m"):(parseFloat((x * 39.3701).toFixed(2)) + " in")
                popup_text += ": " + y
              } else {
                popup_text += ": " + hazard.value
              }
            }

            // Determine opacity of of icon
            let hazard_opacity = 1.0
            if(hazard.is_permanent == 0) {
              var current = new Date()
              var day = 24 * 60 * 60 * 1000;
              var hazard_date =  new Date(hazard.expiration)
              var diff = Math.round((hazard_date.getTime() - current.getTime())/day)
              hazard_opacity = hazard.usertype_id == 9 || diff > 0 ? parseFloat((((diff + 7) / 10.0)).toFixed(2)):0
            }

            var placeholder = {
              lat: hazard.lat,
              lng: hazard.lng,
              opacity: hazard_opacity,
              icon: new icon ({
                iconUrl: hazard_iconUrl,
                iconSize: [64,64],
                iconAnchor: [32,64]
              }),
              popup: popup_text,
              id: hazard.id,
              is_permanent: hazard.is_permanent,
              layer_id: hazard.layer_id,
              user_type: hazard.usertype_id,
              user_id: hazard.user_id
            }
            
            if (hazard.user_id == this.user.id) {
              // this.personalPins.push(placeholder)
              pPersonal.push(placeholder)
            } else if (hazard.usertype_id == 1) {
              // this.userPins.push(placeholder)
              pUser.push(placeholder)
            } else if (hazard.usertype_id == 2) {
              // this.proPins.push(placeholder)
              pPro.push(placeholder)
            } else if (hazard.usertype_id == 9) {
              // this.adminPins.push(placeholder)
              pAdmin.push(placeholder)
            }
          })
        }

        if (this.demo_hazards_pois) {
          // Create pin for each hazard
          this.demo_hazards_pois.forEach((hazard) => {
            var icon_index = ''
            switch(hazard.hazardtype_id) {
              case '19':
                icon_index = 0
                break
              case '20':
                icon_index = 1
                break
              case '21':
                icon_index = 2
                break
              case '22':
                icon_index = 3
                break
              case '23':
                icon_index = 4
                break
            }

            var placeholder = {
              layer_id: hazard.layer_id,
              lat: hazard.lat,
              lng: hazard.lng,
              icon_index: icon_index,
              id: hazard.id,
              user_type: hazard.usertype_id,
              user_id: hazard.user_id
            }
            
            this.poiPins.push(placeholder)
          })
          // console.log(this.poiPins)
        }

        this.$store.dispatch('demo/setPinsAdmin', pAdmin)
        this.$store.dispatch('demo/setPinsPersonal', pPersonal)
        this.$store.dispatch('demo/setPinsPOI', pPOI)
        this.$store.dispatch('demo/setPinsPro', pPro)
        this.$store.dispatch('demo/setPinsUser', pUser)
      }
    })
  },
  data() {
    return {
      adminPins: [],
      personalPins: [],
      poiPins: [],
      pois_icons: [
        new icon ({
          iconUrl: "/img/hazard_icons/admin_point_of_interest_1.png",
          iconSize: [64,64],
          iconAnchor: [32,64]
        }),
        new icon ({
          iconUrl: "/img/hazard_icons/admin_point_of_interest_2.png",
          iconSize: [64,64],
          iconAnchor: [32,64]
        }),
        new icon ({
          iconUrl: "/img/hazard_icons/admin_point_of_interest_3.png",
          iconSize: [64,64],
          iconAnchor: [32,64]
        }),
        new icon ({
          iconUrl: "/img/hazard_icons/admin_point_of_interest_4.png",
          iconSize: [64,64],
          iconAnchor: [32,64]
        }),
        new icon ({
          iconUrl: "/img/hazard_icons/admin_point_of_interest_5.png",
          iconSize: [64,64],
          iconAnchor: [32,64]
        })
      ],
      proPins: [],
      userPins: []
    }
  },
  props: [
    'id',
    // 'isAdminHazardVisible',
    // 'isPersonalHazardVisible',
    // 'isProHazardVisible',
    // 'isUserHazardVisible',
    'isZoomWithinDropInRange',
  ]
}
</script>

<style scoped>
</style>