<template>
  <span>
    <l-control v-if="visibleLayer && visibleLayer.bounds" class="custom-control gps-control" position="topright">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon 
            @click="componentsDialog = !componentsDialog"
            :disabled="lockMapOn"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon :color="hazardMarkerColor">mdi-layers-triple-outline</v-icon>
          </v-btn>
        </template>
          <span>Map Layer Controls</span>
      </v-tooltip>
    </l-control>
    <v-dialog v-model="componentsDialog" scrollable max-width="500px" width="unset">
      <v-card class="card-outter pt-16 pb-9" style="overflow-y: auto;">
        <v-divider></v-divider>
        <v-card-title class="pt-7">Map Layer Controls</v-card-title>
        <v-container>
          <v-row class="px-5">
            <v-col>
              <v-switch 
                @change="updateZoomControl" 
                :color="hazardMarkerColor" 
                v-model="zoomControl"
                hint="When toggled-on, prevents zoom from entering unsupported levels." 
                inset 
                label="Zoom Control" 
                persistent-hint 
              />
            </v-col>
          </v-row>
          <v-row class="px-5">
            <v-col>
              <v-switch 
                @change="switchAllMapComponents" 
                :color="hazardMarkerColor" 
                v-model="areAllMapComponentsVisible" 
                hide-details 
                inset
                label="Map" 
              />
              <v-switch 
                @change="$store.dispatch('demo/toggleMap')" 
                :color="hazardMarkerColor" 
                v-model="isMapVisible" 
                class="pl-7" 
                dense 
                hide-details 
                inset 
                label="Imagery" 
              />
              <v-switch 
                @change="$store.dispatch('demo/toggleIceRoad')" 
                :color="hazardMarkerColor" 
                :disabled="visibleLayer && !visibleLayer.overlay_geojson" 
                v-model="isIceRoadVisible" 
                class="pl-7" 
                dense 
                hide-details 
                inset
                label="Ice Roads" 
              />
              <!-- <v-switch v-model="areIceRoadsVisible" :color="hazardMarkerColor" :disabled="visibleLayer && !visibleLayer.ice_road" class="pl-7" label="Ice Roads" readonly hide-details dense inset />
              <v-switch v-model="arePressureRidgesVisible" :color="hazardMarkerColor" :disabled="visibleLayer && !visibleLayer.pressure_ridge" class="pl-7" label="Pressure Ridges" readonly hide-details dense inset /> -->
            </v-col>
            <v-col>
              <v-switch 
                @change="switchAllHazardComponents" 
                :color="hazardMarkerColor" 
                :disabled="!personalPins.length && !userPins.length && !adminPins.length" 
                v-model="areAllHazardComponentsVisible" 
                hide-details 
                inset 
                label="Hazards" 
              />
              <v-switch 
                @change="$store.dispatch('demo/toggleHazarPersonal')" 
                :color="hazardMarkerColor" 
                :disabled="!personalPins.length" 
                v-model="isPersonalHazardVisible" 
                class="pl-7" 
                dense 
                hide-details 
                inset 
                label="Personal" 
              />
              <v-switch 
                @change="$store.dispatch('demo/toggleHazardUser')" 
                :color="hazardMarkerColor" 
                :disabled="!userPins.length" 
                v-model="isUserHazardVisible" 
                class="pl-7" 
                dense 
                hide-details 
                inset 
                label="User" 
              />
              <v-switch 
                @change="$store.dispatch('demo/toggleHazardPro')" 
                :color="hazardMarkerColor" 
                :disabled="!proPins.length" 
                v-model="isProHazardVisible" 
                class="pl-7" 
                dense 
                hide-details 
                inset
                label="Pro" 
              />
              <v-switch 
                @change="$store.dispatch('demo/toggleHazardAdmin')" 
                :color="hazardMarkerColor" 
                :disabled="!adminPins.length" 
                v-model="isAdminHazardVisible"
                class="pl-7" 
                dense 
                hide-details 
                inset 
                label="Admin" 
              />
            </v-col>
          </v-row>
          <v-card-title class="pt-7">Symbol Display</v-card-title>
          <v-card-subtitle>Control the zoom levels where map symbols are turned on or off.</v-card-subtitle>
          <v-card-text>
            <v-chip class="mr-1" outlined small>Current Zoom: {{ zoom }}x </v-chip> <v-chip @click.prevent="resetRanges" small>Click to Reset Range</v-chip>
          </v-card-text>
          <v-row class="px-3 pt-12" cols="12">
            <v-col xs="11">
              <v-range-slider
                @change="updateRange"
                v-model="range_value"
                color="deep-orange"
                track-color="grey lighten-2"
                track-fill-color="deep-orange accent-1"
                min="0"
                max="10"
                hint="Notes: Map imagery not supported beyond 15x. The higher the zoom level, the 'closer' the view."
                thumb-label="always"
                thumb-size="40"
                prepend-icon="mdi-magnify-minus-outline"
                append-icon="mdi-magnify-plus-outline"
                persistent-hint
              >
                <template v-slot:thumb-label="props">
                    {{ range(props.value) }}
                </template>
              </v-range-slider>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="card-actions pt-4">
          <v-btn @click.prevent="componentsDialog = false" text class="grey--text">
            Close
            <v-icon right color="grey">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </span>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { LControl } from 'vue2-leaflet'

export default {
  components: {
    LControl,
  },
  computed: {
    ...mapGetters('demo', {
      areAllHazardComponentsVisible: 'areAllHazardComponentsVisible',
      areAllMapComponentsVisible: 'areAllMapComponentsVisible',
    }),
    ...mapState('demo', {
      adminPins: state => state.pinsAdmin,
      isAdminHazardVisible: state => state.isAdminHazardVisible,
      isIceRoadVisible: state => state.isIceRoadVisible,
      isMapVisible: state => state.isMapVisible,
      isPersonalHazardVisible: state => state.isPersonalHazardVisible,
      isProHazardVisible: state => state.isProHazardVisibile,
      isUserHazardVisible: state => state.isUserHazardVisible,
      lockMapOn: state => state.lockMapOn,
      personalPins: state => state.pinsPersonal,
      proPins: state => state.pinsPro,
      range_labels: state => state.range_labels,
      range_value: state => state.range_value,
      userPins: state => state.pinsUser,
      visibleLayer: state => state.visibleLayer,
      zoom: state => state.zoom,
      zoomControl: state => state.zoomControl,
    })
  },
  data() {
    return {
      componentsDialog: false,
      controlRange: [],
      hazardMarkerColor: 'deep-orange',
    }
  },
  methods: {
    resetRanges() {
      this.$store.dispatch('demo/setRangeValue', [ 1, 10 ])
    },
    range(val) {
      return this.range_labels[val] + 'x'
    },
    switchAllHazardComponents() {
      this.$store.dispatch('demo/toggleAllHazards')
    },
    switchAllMapComponents() {
      this.$store.dispatch('demo/toggleAllMapComponents')
    },
    updateRange(range) {
      this.$store.dispatch('demo/setRangeValue', range)
    },
    updateZoomControl() {
      this.$store.dispatch('demo/toggleZoomControl')
      this.$emit('update-zoom-control')
      
    }
  },
}

</script>

<style scoped>
>>>.custom-control {
  background: #fff;
  padding: 5px;
  border: 1px solid #aaa;
  border-radius: 5px;
}

>>>.v-dialog {
  position: absolute;
  bottom: 0;
  right: 0;
}

>>>.card-outter {
  position: relative;
  padding-bottom: 1.5em;
}

>>>.card-actions {
  position: absolute;
  top: 0;
  right:0;
}
</style>