<template>
  <span>
    <l-control v-if="dates.length > 2" class="custom-control-2 pa-2 my-4 mx-0" position="bottomleft">
      <p v-if="lockMapOn" class="text-caption text-center ma-0">{{ timeSeriesLayers[current].date }}</p>
      <v-btn v-if="lockMapOn" icon @click.prevent="prev" class="pa-0" dense :disabled="timeSeriesLoading || noPrev"><v-icon :color="hazardMarkerColor">fa-backward</v-icon></v-btn>
      <v-btn v-if="lockMapOn" icon @click.prevent="toggle" class="pa-0" dense :disabled="timeSeriesLoading"><v-icon :color="hazardMarkerColor">fa-stop</v-icon></v-btn>
      <v-btn v-if="lockMapOn" icon @click.prevent="next" class="pa-0" dense :disabled="timeSeriesLoading || noNext"><v-icon :color="hazardMarkerColor">fa-forward</v-icon></v-btn>
      <v-btn v-if="footprint_dates.length > 1 && !lockMapOn" @click.prevent="toggle" icon dense><v-icon :color="hazardMarkerColor">fa-history</v-icon></v-btn>
    </l-control>
    <l-feature-group v-if="lockMapOn && timeSeriesLayers">
      <l-tile-layer
        v-if="tsl_1"
        :ref="tsl_1.id"
        :title="tsl_1"
        :id="tsl_1.id"
        :name="tsl_1.title"
        :url="tsl_1.tile_url"
        :bounds="tsl_1.bounds"
        :tms="tsl_1.tms"
        :maxNativeZoom="tsl_1.maxNativeZoom"
        :maxZoom="tsl_1.maxZoom"
        @load="bringToFront($event,tsl_1)"
      />
      <l-tile-layer
        v-if="tsl_2"
        :ref="tsl_2.id"
        :id="tsl_2.id"
        :name="tsl_2.title"
        :url="tsl_2.tile_url"
        :bounds="tsl_2.bounds"
        :tms="tsl_2.tms"
        :maxNativeZoom="tsl_2.maxNativeZoom"
        :maxZoom="tsl_2.maxZoom"
        @load="bringToFront($event,tsl_2)"
      />
      <l-tile-layer
        v-if="tsl_3"
        :ref="tsl_3.id"
        :id="tsl_3.id"
        :name="tsl_3.title"
        :url="tsl_3.tile_url"
        :bounds="tsl_3.bounds"
        :tms="tsl_3.tms"
        :maxNativeZoom="tsl_3.maxNativeZoom"
        :maxZoom="tsl_3.maxZoom"
        @load="bringToFront($event,tsl_3)"
      />
    </l-feature-group>
    <v-dialog v-model="timeSeriesLoading" scrollable max-width="500px" width="unset">
      <v-card class="card-outter pt-16 pb-0" style="overflow-y: auto;">
        <v-divider></v-divider>
        <v-card-title class="pa-7">
          <v-progress-circular
            class="mr-5"
            color="primary"
            size="40"
            indeterminate
          />
          Loading Time Series
        </v-card-title>
        <v-card-actions class="card-actions pt-4">
          <v-btn @click.prevent="toggle" text class="grey--text">
            <v-icon right color="grey">
              mdi-close
            </v-icon>
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { mapState } from 'vuex'
import { LControl, LFeatureGroup, LTileLayer } from 'vue2-leaflet'

export default {
  components: {
    LControl,
    LFeatureGroup,
    LTileLayer,
  },
  computed: {
    ...mapState('demo', {
      dates: state => state.dates,
      layers: state => state.demo_layers,
      lockMapOn: state => state.lockMapOn,
      visibleLayer: state => state.visibleLayer
    }),
    noNext() {
      return this.current == 0 || typeof this.timeSeriesLayers[this.current - 1] == 'undefined'
    },
    noPrev() {
      return this.current == 2 || typeof this.timeSeriesLayers[this.current + 1] == 'undefined'
    },
    timeSeriesLayers() {
      // note that these are ordered from newest to oldest
      return this.layers.filter((layer) => {
        return (this.timeSeriesLayerIds.includes(layer.id))
      })
    },
    timeSeriesLayerIds() {
      return this.footprint_dates.map(date => date.id)
    },
    timeSeriesLoading() {
      let ts_active = 0
      if (this.tsl_1) ts_active++
      if (this.tsl_2) ts_active++
      if (this.tsl_3) ts_active++
      return this.lockMapOn && this.timeSeriesLoaded < ts_active
    }
  },
  data() {    
    return {
      current: 0,
      hazardMarkerColor: 'deep-orange',
      timeSeriesLoaded: 0,
      tsl_1: {},
      tsl_2: {},
      tsl_3: {},
      saved: {},
    }
  },
  methods: {
    bringToFront(e, layer) {
      if (layer.id != this.visibleLayer.id) {
        e.target.bringToBack()
      }
      this.timeSeriesLoaded++
    },
    next() {
      // moving forward in time
      this.current--
      this.$refs[this.timeSeriesLayers[this.current].id].mapObject.bringToFront()
    },
    prev() {
      // moving backwark in time
      this.current++
      this.$refs[this.timeSeriesLayers[this.current].id].mapObject.bringToFront()
    },
    toggle() {
      if(!this.lockMapOn) {
        this.saved = this.visibleLayer
        this.tsl_1 = this.timeSeriesLayers[0]
        this.tsl_2 = this.timeSeriesLayers[1]
        this.tsl_3 = this.timeSeriesLayers[2]
        this.$store.dispatch('demo/toggleMapLock', true)
        this.$emit('toggle-lock-map')
        this.$refs[this.timeSeriesLayers[this.current].id].mapObject.bringToFront()
      } else if (this.lockMapOn) {
        let lml = this.saved
        this.$store.dispatch('demo/setVisibleLayer', lml)
        this.timeSeriesLoaded = 0
        this.$store.dispatch('demo/toggleMapLock', false)
        this.$emit('toggle-lock-map')
        this.current = null
      }
    },
  },
  props: ['footprint_dates']
}
</script>

<style scoped>
</style>>