<template>
    <v-card class="ma-4 pa-2">
        <v-card-title>
            Knowledge Quiz
        </v-card-title>
        <v-card-text v-if="!quizComplete">
            Question {{ currentQuestion + 1 }} of {{ questions.length }}
            <v-progress-linear :value="Math.floor(((currentQuestion + 1) / questions.length) * 100)"></v-progress-linear>
        </v-card-text>
        <section v-if="!quizComplete">
            <v-card-text class="pb-0">
                {{ getCurrentQuestion.question }}
                <v-radio-group v-model="quizChoices">
                    <v-radio
                        class="option black--text"
                        v-for="(choice, index) in getCurrentQuestion.choices"
                        :key="choice"
                        :label="`${choice}`"
                        :value="index"
                        @change="setAnswer(index)"
                    ></v-radio>
                </v-radio-group>
                
            </v-card-text>
            <v-card-actions class="pt-0 pr-4">
                <v-spacer></v-spacer>
                <v-btn
                    @click="nextQuestion"
                    :disabled="getCurrentQuestion.selected == null"
                >{{ getCurrentQuestion.selected == null ? 'Select an answer' : currentQuestion == questions.length - 1 ? 'Finish' : 'Confirm answer' }}</v-btn>
            </v-card-actions>
      </section>
      <section v-else>
        <v-card-text>Quiz Completed!</v-card-text>
        <v-card-text>You answered {{ score }} out of {{ questions.length }} questions correctly</v-card-text>
        <v-expansion-panels class="px-4">
            <v-expansion-panel
                v-for="(question, index) in questions"
                :key="question.question"
            >
                <v-expansion-panel-header>Question {{ index + 1 }}
                    
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-card-text class="pt-0">
                        <b>{{ question.question }}</b><br>
                        You Answered: <b>{{ question.choices[question.selected] }}</b><br>
                        Correct Answer: <b>{{ question.choices[question.answer] }}</b><br>
                        {{ question.correct }}
                    </v-card-text>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>


        <v-card-text>
            <v-btn @click="resetQuiz">Restart quiz</v-btn>
        </v-card-text>
      </section>

 
    </v-card>
</template>
    
<script>
export default { 
    data() {
        return {
            questions: [
                {
                    question: 'Rough ice, called rubble ice, starts to form at the same time as pressure ridges.',
                    answer: 1,
                    choices: [ 'True', 'False', ],
                    selected: null,
                    correct: "Pressure ridges form after rubble ice does. Ridges typically form when the ice cover is landfast to the surrounding shores. The expansion of" +
                        " lake ice has nowhere to go but up. An exception occurs in large lakes with smaller sheltered bays. The bays may have an open water edge to the main" + 
                        " lake but will still form pressure ridges while the main basin continues to move ice. "
                },
                {
                    question: 'The presence of rubble ice means you are considering travel on a lake that has mobile ice.',
                    answer: 0,
                    choices: [ 'True', 'False', ],
                    selected: null,
                    correct: "Rubble ice forms when ice sheets are mobile and repeatedly collide together, or when broken ice drifts downwind and accumulates against an ice sheet" + 
                        " or the shore. For many lakes, this means a continuous ice cover repeatedly forms and breaks apart over a period of weeks due to wind shear stress."
                },
                {
                    question: 'Open water leads are wide cracks of open water that are large enough to be navigable with a boat. They pose an immersion hazard, and a risk possibly' + 
                        ' requiring professional rescue should the ice drift away from shore. As leads form due to wind, and wind varies, it is impossible to know if a lake can form open water leads.',
                    answer: 1,
                    choices: [ 'True', 'False', ],
                    selected: null,
                    correct: "The ice deformations on a lake tell a story about how the ice formed and what happened to it. The presence of rubble fields found on one side of the" + 
                        " lake tell a story of downwind ice movement, fracturing, and rubble accumulating often into piles, rows, or even fields. It stands to reason that the upwind" + 
                        " side of the lake had to form an open water lead, even if you never saw it." 
                },
                {
                    question: 'Open water leads pose a travel hazard in many lakes during the New Ice period. When planning lake ice travel on a newer ice cover it is possible to minimize your' +
                        ' risk of immersion or stranding due to open water leads.',
                    answer: 0,
                    choices: [ 'True', 'False', ],
                    selected: null,
                    correct: "Avoid accessing the ice on the upwind side of the lake on windy days during the New Ice period. Travel to the downwind side or wait until the wind subsides." + 
                        " Confirm the winds are not forecasted to shift during the day." 
                },
                {
                    question: 'Checking ice thickness with an ice chisel, sometimes called a spud bar, along your route is essential.',
                    answer: 0,
                    choices: [ 'True', 'False', ],
                    selected: null,
                    correct: "Early lake ice travel demands a good understanding of what you walk on. If you fish the New Ice period and don't have an ice chisel, it is time to get one. Attach" + 
                        " a braided line to the end that is longer than the water is deep, and let it drag behind you. This will enable you to retrieve your chisel when it slips out of your hand, " + 
                        "and in the worst case, you and your buddy will have a rope to grab should you fall in." 
                },
                {
                    question: 'Once the ice thickness is checked and is known you can proceed with your travel so long as it is sufficiently thick.',
                    answer: 1,
                    choices: [ 'True', 'False', ],
                    selected: null,
                    correct: "Most lakes form ice in stages. Typically, the ice will be oldest (thickest) nearshore and youngest (thinnest) in the offshore. Don't leave your ice chisel at the shore." + 
                        " After chiseling then measure your auger hole thickness so you know the number and how it changes over distance. " 
                },
            ],
            quizComplete: false,
            currentQuestion: 0,
            quizChoices: null,
        }
    },
    computed: {
        score() {
            let total = 0
            this.questions.map(q => {
                if (q.selected == q.answer) {
                    total++
                }
            })
            return total
        },
        getCurrentQuestion() {
            return this.questions[this.currentQuestion]
        },
        wrongQuestions() {
            return this.questions.filter((q) => q.answer != q.selected)
        }
    },
    methods: {
        setAnswer(choice) {
            this.questions[this.currentQuestion].selected = choice
        },
        nextQuestion() {
            this.quizChoices = null
            if (this.currentQuestion < this.questions.length - 1) {
                this.currentQuestion++
                return
            }
            this.quizComplete = true
            this.$emit('next-section')
        },
        resetQuiz() {
            this.quizComplete = false
            this.currentQuestion = 0
            this.$emit('clear-done', 'Quiz')
        }
    },
}
</script>

<style>
.option {
    border-style: solid;
    border-width: 1px;
	padding: 1rem;
	border-radius: 0.5rem;
	cursor: pointer;
}
</style>