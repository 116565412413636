<template>
  <v-row class="mb-2">
    <!-- <v-chip
      class="ma-1 px-2"
      color="light-blue"
      label
      small
      v-for="attribute in attributes"
      :key="attribute"
    >
      {{ attribute }}
    </v-chip> -->
    <v-flex 
      v-for="(attribute,index) in attributes"
      :key="index" 
      shrink
    >
      <v-tooltip 
        
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-img
            v-bind="attrs"
            v-on="on"
            class="ma-1"
            contain
            max-height="55px"
            :src="'/img/hazard_icons/admin_' + getIcon(attribute)"
            width="46px"
          />
        </template>
        <span>{{ attribute }}</span>
      </v-tooltip>
    </v-flex>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('map', {
      hazardtypes: state => state.hazardtypes,
    }),
  },
  data() {
    return {
    }
  },
  methods: {
    getIcon(name) {
      return this.hazardtypes.find(type => type.name == name).icon
    }
  },
  props: [
    'attributes'
  ],
}

</script>