<template>
    <span>
        <l-control 
            class="custom-control" 
            position="topright"      
        >
        <v-tooltip left :disabled=is_mobile v-model="help">
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              icon
              @click="toggleAutoSpotMode"
              v-bind="attrs"
              v-on="on"
            >  
            <span class="fa-stack">
                <v-icon :color="showAutoSpot ? 'deep-orange':'icetimeblue'" large >mdi-auto-mode</v-icon>
                <v-icon :color="showAutoSpot ? 'deep-orange':'icetimeblue'" class="ml-n6" small>fa-fish</v-icon>
            </span>
            </v-btn>
          </template>
          <span>Auto Spot</span>
        </v-tooltip>
        </l-control>
        <v-dialog
            max-width="500px"
            hide-overlay
            transition="dialog-bottom-transition"
            mode="out-in"
            no-click-animation
            persistent
            value="autospot"
            v-if="showAutoSpot && gps_location"
        >
        <!-- :fullscreen="mobile_fullscreen" -->
        <v-card class="pb-4" style="overflow-y: auto; " v-if="showAutoSpotSelect">
            <v-card-actions class="pt-4">
                <v-spacer></v-spacer>
                <v-btn @click="clickCancel" text class="grey--text">
                    Close
                    <v-icon right color="grey">mdi-close</v-icon>
                </v-btn>
            </v-card-actions>         
            <v-divider></v-divider>
                <!-- <v-card-text v-if="closestLoaded"> loaded </v-card-text> -->
                    <v-card 
                    v-for="item in closest_spot"
                    class="pa-2 ma-2 black--text text-body-2" 
                    rounded
                    flat
                    :key="item.id"
                    >
                    <p class="text-h5">The closest fishing spot is:</p>
                    <v-row class="pt-2" no-gutters>
                        <v-col cols="12">
                            <v-card-text class="py-0">
                                <div class="text-caption">Name</div>
                                <p class="text-h6">{{ item.name }}</p>
                            </v-card-text>
                        </v-col>
                    </v-row>
                    <v-row class="px-4" no-gutters>
                        <v-col cols="6">
                            <div class="text-caption">Latitude</div>
                            <p class="text-h6">{{ parseFloat(item.lat).toFixed(6) }}</p>
                        </v-col>    
                        <v-col cols="6">
                            <div class="text-caption">Longitude</div>
                            <p class="text-h6">{{ parseFloat(item.lng).toFixed(6) }}</p>
                        </v-col>   
                    </v-row>
                    <!-- <p class="text-h6">Latitude: {{ item.lat }}, Longitude: {{ item.lng }}</p> -->
                    <v-row class="pt-2" no-gutters>   
                        <v-col cols="12">
                            <v-card-text class="py-0">
                                <div class="text-caption">Distance</div>
                                <p class="text-h6">{{ distance }}</p>
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn @click="continueToSpot" color="success">
                            Use This Spot
                        </v-btn>
                        <v-btn @click="gpsSpot" color="primary">
                            Create New Spot
                        </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
                </v-card>
            <v-card style="overflow-y: auto; " v-if="showAutoSpotForm">
                <v-card-actions class="pt-4">
                    <v-spacer></v-spacer>
                    <v-btn @click="clickCancel" text class="grey--text">
                        Close
                        <v-icon right color="grey">mdi-close</v-icon>
                    </v-btn>
                </v-card-actions>         
                <v-divider></v-divider>
                <v-card 
                    class="pa-2 ma-2 black--text text-body-2" 
                    rounded
                    flat
                >
                    <p class="text-h5">Create New Fishing Spot</p>
                    <v-row class="px-4" no-gutters>
                        <v-col cols="12">
                            <div class="subtitle-2">Current Location</div>
                        </v-col>  
                    </v-row>
                    <v-row class="px-4" no-gutters>
                        <v-col cols="6">
                            <div class="text-caption">Latitude</div>
                            <p class="text-h6">{{ gpsValue.lat.toFixed(6) }}</p>
                        </v-col>    
                        <v-col cols="6">
                            <div class="text-caption">Longitude</div>
                            <p class="text-h6">{{ gpsValue.lng.toFixed(6) }}</p>
                        </v-col>   
                    </v-row>
                    <v-row class="pt-2" no-gutters>
                        <v-col cols="12">
                            <v-card-text class="py-0">
                                <v-text-field
                                    class="text-body-2 form-label"
                                    dense
                                    hide-details
                                    label="Fishing Spot Name"
                                    type="text"
                                    v-model="spotName"
                                    outlined
                                />
                            </v-card-text>
                        </v-col>
                    </v-row>
                    <v-row class="pt-2" no-gutters>
                        <v-col cols="12">
                            <v-card-text class="py-2">
                                <v-textarea hide-details v-model="spotComment" class="text-body-2" label="Comment" outlined ></v-textarea>
                            </v-card-text>
                        </v-col>
                    </v-row>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="ma-1 white--text pa-4" 
                            color="grey"
                            @click="clickGoBack"
                        >
                            Cancel
                        </v-btn>
                        <v-btn 
                            class="ma-1 white--text pa-4" 
                            color="success"
                            @click="saveFishingSpot"
                        >
                            Create New Spot
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-card>
        </v-dialog>
    </span>
  </template>
  
  <script>
  import { LControl } from 'vue2-leaflet'
  import { mapState } from 'vuex'
  
  export default {
    data() {
      return {
        help: false,
        gpsValue: null,
        showAutoSpotSelect: true,
        showAutoSpotForm: false,
        showAutoSpot: false,
        selectedSpecies: '',
        competition: null,
        wasReleased: '',
        err_message: '',
        error: false,
        loading: false,
        extraOptions: false,
        gpsLoaded: false,
        closestLoaded: false,
        distance: '-',

        spotName: '',
        spotComment: '',
        length: '',
        weight: '',
        depth: '',
      }
    },
    components: {
      LControl,
    //   LMarker,
    //   LPopup,
    },
    computed: {
        ...mapState('spot_mapper', {
            species: state => state.species,
            joined_comps: state => state.joined_comps,
            closest_spot: state => state.closest_spot,
            selected_fishing_spot: state => state.selected_fishing_spot,
        }),
        ...mapState('user', {
            user: state => state.user
        }),
        ...mapState('map', {
            showHelp: state => state.showHelp,
        }),          
        is_mobile() {
            return this.$vuetify.breakpoint.xs ? this.showHelp ? false : true : false
        },   
        mobile_fullscreen() {
            return !this.$vuetify.breakpoint.xs ? false : true
        },  
    },
        created() {
    },
    watch: {
      gps_location(){
            // **Might need to check to see if users gps updates afterwards, so we compare the local to the passed gps values?
            this.gpsValue = this.gps_location
            // console.log("lat: " + this.gpsValue.lat)
            // console.log("lng: " + this.gpsValue.lng)
            if(!this.gpsLoaded){
                this.autoSpotLoaded()
            }
        this.$emit('close_snackbar')
      },
      showHelp() {
        this.help = this.showHelp
      },      
    },
    methods: {
        clickCancel() {
            this.spotName = ''
            this.spotComment = ''
            this.showAutoSpotSelect = true
            this.showAutoSpotForm = false
            this.showAutoSpot = false
            
        },
        clickGoBack() {
            this.spotName = ''
            this.spotComment = ''
            this.showAutoSpotSelect = true
            this.showAutoSpotForm = false
        },
        toggleAutoSpotMode() {
            this.showAutoSpot = !this.showAutoSpot
            // this.clickCancel()

            if(this.showAutoSpot) {
                this.$emit('auto-spot-toggled')
                // console.log('toggle true')
                // this.gpsValue = this.$refs.spot.mapObject.locate()
                
            } else {
                this.$emit('auto-spot-untoggled')
                this.clickCancel()
                // console.log('toggle false')
            }
            // console.log("Auto Spot GPS: " + this.gpsValue)
            },
        autoSpotLoaded() {
            this.gpsLoaded = true
            // let current_location = {
            //     lat: this.gpsValue.lat,
            //     lng: this.gpsValue.lng,
            // }
            let submitvalue = this.gpsValue.lat + "$" + this.gpsValue.lng
            // console.log("the GPS Has Loaded")
            // console.log(current_location)
            // this.test()
            this.$store.dispatch('spot_mapper/getClosestSpot', submitvalue)
            .then(() => {
                if(this.closest_spot !== null){
                    this.mathDistance(this.closest_spot[0])
                    // console.log(this.closest_spot)
                }

            })
            .finally(() => {
                if(this.closest_spot !== null){
                    this.closestLoaded = true
                    // console.log(this.closest_spot)
                }
            })
        },
        mathDistance(closest_spot) {
            // console.log("lat: " + this.gpsValue.lat)
            let lat1 = this.gpsValue.lat
            let lon1 = this.gpsValue.lng
            let lat2 = closest_spot.lat
            let lon2 = closest_spot.lng
            const R = 6371e3; // metres
            const φ1 = lat1 * Math.PI/180; // φ, λ in radians
            const φ2 = lat2 * Math.PI/180;
            const Δφ = (lat2-lat1) * Math.PI/180;
            const Δλ = (lon2-lon1) * Math.PI/180;

            const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
                    Math.cos(φ1) * Math.cos(φ2) *
                    Math.sin(Δλ/2) * Math.sin(Δλ/2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

            this.distance = R * c; // in metres

            if (this.user.metric == "1") {
                this.distance = parseFloat(this.distance.toFixed(2))
                if (this.distance >= 1000) {
                    this.distance = (this.distance / 1000).toFixed(2) + " km"
                }
                else {
                    this.distance = this.distance + " m"
                }
            }
            else {
                this.distance = parseFloat((this.distance / 1609).toFixed(2)) + " mi"
            }

            // console.log(this.distance)
            // console.log(this.closest_spot)
            // console.log(this.closest_spot.lat)
        },
        extra_options(){
            this.extraOptions = !this.extraOptions
        },
        gpsSpot() {
            this.showAutoSpotSelect = false
            this.showAutoSpotForm = true
        },
        continueToSpot() {
            // console.log(this.closest_spot[0].id)
            this.clickCancel()
            this.$store.dispatch('access_points/setSelectedAccessPoint', null)
            // this.$store.dispatch('spot_mapper/setClosestSpot', null)
            this.$store.dispatch('access_points/setShowMarkerDialog', false)
            this.$store.dispatch('spot_mapper/setSelectedGeopost', null)
            this.$store.dispatch('spot_mapper/setSelectedFishingSpot', this.closest_spot[0].id)
            .then(() => {
                this.$store.dispatch('spot_mapper/getGroupCatches', this.closest_spot[0].id)
                this.$emit('auto-spot-found')
                // console.log(this.$store.dispatch('spot_mapper/getGroupCatches', spotId))
            })
        },
        saveFishingSpot() {
            let spotItem = {
                name: this.spotName,
                comment: this.spotComment,
                gps: this.gpsValue,
                user_id: this.user.id,
            }
            // console.log(spotItem)
            this.$store.dispatch('spot_mapper/recordFishingSpot', spotItem)
            .then(() => {})
            .catch(() => {})
            .finally(() => {
                this.$emit('spot-added')
                // console.log(this.selected_fishing_spot)
                this.toggleAutoSpotMode()
                this.showAutoSpot = false
                this.$store.dispatch('access_points/setSelectedAccessPoint', null)
                this.$store.dispatch('access_points/setShowMarkerDialog', false)
                this.$store.dispatch('spot_mapper/setSelectedGeopost', null)
                this.$store.dispatch('spot_mapper/getFishingSpots')
                .then(() => {
                    this.$store.dispatch('spot_mapper/setSelectedFishingSpot', this.selected_fishing_spot)
                    // this.$store.dispatch('spot_mapper/getGroupCatches', this.selected_fishing_spot)
                    // console.log(this.$store.dispatch('spot_mapper/getGroupCatches', spotId))
                })
            })
        },
    },
    mounted() {
    //   this.clickCancel()
      this.$store.dispatch('spot_mapper/getSpecies')
        .then(() => {
            // console.log(this.selected_fishing_spot)
        }),
        this.$store.dispatch('spot_mapper/getJoinedComps')
        .then(() => {
            // console.log("Joined Comps: " + JSON.stringify(this.joined_comps))
            if(this.joined_comps.length > 0){
              // console.log(this.joined_comps[0])
              this.competition = this.joined_comps[0].id
            }
        })
        let urlParams = new URLSearchParams(window.location.search);
        let myParam = urlParams.get('mode');
        if(myParam === "autospot"){
            // console.log('mode = autospot')
            this.toggleAutoSpotMode()
        }
    },
    props: [
      'gps_location'
    ]
  }
  </script>
  
  <style scoped>
  >>>.v-label {
    font-size: 14px;
  }
  
  >>>.myDivIcon {
    line-height: 20px;
    text-align: center;
  }
  </style>