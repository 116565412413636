<template>
    <v-container >
        <TopNav ></TopNav>
        <HowTo ></HowTo>
    </v-container>
</template>

<script>
// import
// import { mapGetters,  } from 'vuex'
import HowTo from '../components/HowTo';
import TopNav from '../components/TopNav';


export default{
components: {
    HowTo,
    TopNav,
},
computed: {
    // ...mapGetters('user', {
    //   isLoggedIn: 'isLoggedIn',
    // }),

},
data() {
    return{

    }

},
methods: {


},
mounted() {
    // this.$store.dispatch('setMode', 'app')
    this.$store.dispatch("setTab", "howto");
    // this.$store.dispatch('checkToken')
    // console.log('view')
    // TODO: add check for subscriber status
}
}

</script>