<template>
    <v-container >
        <TopNav ></TopNav>
        <PersonalLeaderboard ></PersonalLeaderboard>
    </v-container>
</template>

<script>
// import
// import { mapState } from 'vuex';
import PersonalLeaderboard from '../components/PersonalLeaderboard';
import TopNav from '../components/TopNav';


export default{
    components: {
        PersonalLeaderboard,
        TopNav,
    },
computed: {

},
data() {
    return{

}
},
methods: {

},
mounted() {
    // this.$store.dispatch('leaderboard/setPage', 'join_list')
    this.$store.dispatch('setMode', 'app')
    this.$store.dispatch('setTab', 'leaderboard')
    this.$store.dispatch('checkToken')
    // TODO: add check for subscriber status
}
}

</script>