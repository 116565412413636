<template>
  <v-dialog  v-model="show" max-width="800" persistent>
    <v-card class="pa-12">
      <v-card-title class="mx-0 mt-0 mb-4 pa-0">
        {{ data.id ? 'Update Advisory':'Create New Advisory' }}
      </v-card-title>
      <v-row>
        <v-col cols="12" xs="12" sm="4" md="4">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="data.date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="data.date"
                v-bind="attrs"
                v-on="on"
                dense
                :disabled="data.id != null"
                hide-details
                label="Date"
                outlined
                readonly
              />
            </template>
            <v-date-picker
              v-model="data.date"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(data.date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" xs="12" sm="4" md="4">
          <v-autocomplete
            v-model="data.region_id"
            dense
            hide-details="true"
            item-text="name"
            item-value="radar_id"
            :items="regions"
            label="Region"
            outlined
            read-only
            @change="region_changed"
            :disabled="data.hazards != null"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="4" md="4">
          <!-- <v-combobox
            v-model="data.footprint_id"
            :disabled="!data.region_id"
            dense
            hide-details="true"
            item-text="name"
            item-value="id"
            :items="footprints"
            label="Footprint"
            outlined
          /> -->
          <v-autocomplete
            v-model="data.footprint_id"
            dense
            hide-details="true"
            item-text="name"
            item-value="id"
            :items="footprints"
            label="Footprint"
            outlined
            read-only
            :disabled="data.hazards != null"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12">
          <v-autocomplete
            v-model="data.hazards"
            chips
            deletable-chips
            hide-details="true"
            item-text="name"
            item-value="name"
            :items="hazardtypes"
            label="Hazards"
            multiple
            outlined
            :disabled="data.hazards != null"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="data.title"
            label="Title"
            hide-details="auto"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            v-model="data.ras_description"
            auto-grow
            counter="255"
            hide-details="auto"
            label="RAS Description (for unsubcribed users)"
            outlined
            rows="3"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            v-model="data.description"
            auto-grow
            counter="65535"
            hide-details="auto"
            label="Description (for subscribed users)"
            outlined
            rows="5"
          />
        </v-col>
      </v-row>
      <v-card-actions class="mt-4 pb-0">
        <v-spacer />
        <v-btn class="px-5 mx-2 white--text" color="blue darken-1" @click="cancel_create">
          Cancel
        </v-btn>
        <v-btn class="px-5 mx-2 white--text" color="blue darken-1" :loading="loading" @click="save">
          Save
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('map', {
      footprints: state => state.footprints,
      hazardtypes: state => state.hazardtypes,
    }),
    ...mapState(['regions']),
    // computed_footprints() {
    //   if (this.data.region_id) {
    //     this.region_changed()
    //   }
    //   return this.footprints
    // }
  },
  created() {
    this.$store.dispatch('ras/getManualAdvisories')
    this.$store.dispatch('getRegion')
    this.$store.dispatch('map/getHazardTypes')
    this.$store.dispatch('setTab', 'advisories')
  },
  data() {
    return {
      last_val: 0,
      loading: false,
      menu: false,
    }
  },
  methods: {
    cancel_create() {
      this.loading = false
      this.$store.dispatch('ras/setAdvisoryCreate', false)
      this.$emit('create-done')
    },
    region_changed() {
      this.$store.dispatch('map/getFootprints', {id: this.data.region_id})
    },
    reset_advisories() {
      this.$store.dispatch('ras/getManualAdvisories')
    },
    save() {
      this.loading = true
      let post_data = {
        footprint_id: this.data.footprint_id,
        hazards: this.data.hazards.toString(),
        title: this.data.title,
        ras_description: this.data.ras_description,
        description: this.data.description,
        date: this.data.date
      }

      if (this.data && this.data.id) {
        post_data.id = this.data.id
        this.$store.dispatch('ras/updateManualAdvisory', post_data)
        .then(() => {
          this.reset_advisories()
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false
            this.infoMessage = ""
            this.cancel_create()
          }, 1500)
        })
      } else {
        this.$store.dispatch('ras/createManualAdvisory', post_data)
        .then(() => {
          this.reset_advisories()
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false
            this.infoMessage = ""
            this.cancel_create()
          }, 1500)
        })
      }
    },
    update_search_input(val) {
      if (this.last_val !== val) {
        this.region_changed()
        this.last_val = val
      }
    }
  },
  props: ['show', 'data'],
  watch: {
    // item (val) {
    //   return val
    // }
  }
}
</script>
