<template>
  <v-container>
    <p class="text-h5 pl-5 pt-8">Install Ice Time&trade;</p>
    <v-layout class="px-4" cols="12" row>
      <v-flex class="pa-4" lg12 xl10>
        <v-card class="pa-3">
          <v-card-title class="px-6">Desktop Installation</v-card-title>
          <v-card-subtitle class="px-6">Only supported for Chrome and Edge browsers at this time. Consider bookmarking Ice Time in your browser, if this not an option for you.</v-card-subtitle>
          <v-container>
            <v-tabs class="pb-3" v-model="browser_tab"> 
              <v-tab key="chrome">Google Chrome (recommended)</v-tab>
              <v-tab key="firefox">Microsoft Edge</v-tab>
            </v-tabs>
            <v-tabs-items v-model="browser_tab">
              <v-tab-item key="chrome">
                <v-container class="pa-3">
                  Install Ice Time&trade; so the program icon sits on your desktop for easy use. Launching the program this way also maximizes the useful area of your screen to the app.
                </v-container>

                <v-row class="pa-2 mb-3">
                  <v-flex md12 lg6>
                    <v-card class="ma-3" height="100%" outlined flat>
                      <v-img max-height="100%" src="img/docs/pwa_chrome_install_1.png" />
                      <v-divider />
                      <v-card-text class="pb-0">Installation icon can be located in the top right corner of your browser.</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex md12 lg6>
                    <v-card class="ma-3" height="100%" outlined flat>
                      <v-img max-height="100%" src="img/docs/pwa_chrome_install_2.png" />
                      <v-divider />
                      <v-card-text>Alternatively, an installation menu item can be found within the top right menu: <v-chip small><v-icon small>mdi-dots-vertical</v-icon></v-chip> > <v-chip small>Install Ice Time&hellip;</v-chip></v-card-text>
                    </v-card>
                  </v-flex>
                </v-row>
              </v-tab-item>
              <v-tab-item key="firefox">
               <v-container class="pa-3">
                  Install Ice Time&trade; so the program is accessible on Windows device for your convenience. Launching the program this way also maximizes the useful area of your screen to the app.
                </v-container>
                <v-row class="pa-2 mb-3">
                  <v-flex sm12 md6>
                    <v-card class="ma-3" height="100%" outlined flat>
                      <v-img max-height="100%" src="img/docs/pwa_edge_install_1.png" />
                      <v-divider />
                      <v-card-text class="pb-0">The app installation option can be accessed from the top right menu: <v-chip small><v-icon small>mdi-dots-horizontal</v-icon></v-chip> > <v-chip small><v-icon class="pr-1" small>mdi-grid-large</v-icon>Apps</v-chip> > <v-chip small><v-img src="img/icons/favicon-16x16.png" class="mr-2" />Install this site as an app</v-chip></v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex sm12 md6>
                    <v-card class="ma-3" height="100%" outlined flat>
                      <v-img max-height="100%" src="img/docs/pwa_edge_install_2.png" />
                      <v-divider />
                      <v-card-text class="pb-0">Before installation is completed, you will be given multiple access options. Select as many as you desire, then click 'Allow' to apply these options.</v-card-text>
                    </v-card>
                  </v-flex>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card>
      </v-flex>
      <v-flex class="pa-4" lg12 xl10>
        <v-card class="pa-3 mb-12">
          <v-card-title class="px-6">Mobile Bookmarking</v-card-title>
          <v-container>
            <v-tabs class="pb-3" v-model="mobile_tab"> 
              <v-tab style="text-transform: none!important;" key="iOS">iOS</v-tab>
              <v-tab key="andriod">Android</v-tab>
            </v-tabs>
            <v-tabs-items v-model="mobile_tab">
              <v-tab-item key="iOS">
                <v-container class="pa-3">
                  Bookmark Ice Time&trade; so that it can be easily accessed as an icon on your mobile device. Launching the program this way also maximizes the useful area of your screen to the app. Super helpful especially on mobile devices.
                </v-container>

                <v-row class="d-md-flex d-lg-none">
                  <v-stepper class="mt-4" v-model="mobile_step" outlined flat>
                    <v-stepper-header class="mx-6 mt-5" >
                      <v-stepper-step step="1" editable>Locate</v-stepper-step>
                      <v-divider />
                      <v-stepper-step step="2" editable>Add</v-stepper-step>
                      <v-divider />
                      <v-stepper-step step="3" editable>Confirm</v-stepper-step>
                    </v-stepper-header>
                    
                    <v-stepper-content step="1">
                      <v-card class="pb-4" height="100%" outlined flat>
                        <v-img max-height="100%" src="img/docs/iphone_bookmark_1.png" />
                        <v-divider />
                        <v-card-text class="pb-0">Locate the browser menu icon (<v-chip small><v-icon small>mdi-open-in-app</v-icon></v-chip>) centered at the bottom of the screen.</v-card-text>
                      </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                      <v-card class="pb-4" height="100%" outlined flat>
                        <v-img max-height="100%" src="img/docs/iphone_bookmark_2.png" />
                        <v-divider />
                        <v-card-text>Select the <v-chip small>Add to Home Screen <v-icon small>mdi-plus-box-outline</v-icon></v-chip> option.</v-card-text>
                      </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                      <v-card class="pb-4" height="100%" outlined flat>
                        <v-img max-height="100%" src="img/docs/iphone_bookmark_3.png" />
                        <v-divider />
                        <v-card-text>Confirm the name you wish to bookmark Ice Time&trade; under, then select <v-chip>Add</v-chip> near the top right to complete the process.</v-card-text>
                      </v-card>
                    </v-stepper-content>
                  </v-stepper>
                </v-row>

                <v-row cols="12" class="d-none d-md-flex pa-2 mb-3">
                  <v-flex md12 lg4>
                    <v-card class="ma-3" height="100%" outlined flat>
                      <v-img max-height="100%" src="img/docs/iphone_bookmark_1.png" />
                      <v-divider />
                      <v-card-text class="pb-0">Locate the browser menu icon (<v-chip small><v-icon small>mdi-open-in-app</v-icon></v-chip>) centered at the bottom of the screen.</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex md12 lg4>
                    <v-card class="ma-3" height="100%" outlined flat>
                      <v-img max-height="100%" src="img/docs/iphone_bookmark_2.png" />
                      <v-divider />
                      <v-card-text>Select the <v-chip small>Add to Home Screen <v-icon small>mdi-plus-box-outline</v-icon></v-chip> option.</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex md12 lg4>
                    <v-card class="ma-3" height="100%" outlined flat>
                      <v-img max-height="100%" src="img/docs/iphone_bookmark_3.png" />
                      <v-divider />
                      <v-card-text>Confirm the name you wish to bookmark Ice Time&trade; under, then select <v-chip small>Add</v-chip> near the top right to complete the process.</v-card-text>
                    </v-card>
                  </v-flex>
                </v-row>
              </v-tab-item>
              <v-tab-item key="android">
               <v-container class="pa-3">
                  Install Ice Time&trade; so that it can be easily accessed as an icon on your mobile device. Launching the program this way also maximizes the useful area of your screen to the app. Super helpful especially on mobile devices. The sample below uses Google Chrome on an Android phone.
                </v-container>

                <v-row class="d-md-flex d-lg-none">
                  <v-stepper class="mt-4" v-model="mobile_step" outlined flat>
                    <v-stepper-header class="mx-6 mt-5" >
                      <v-stepper-step step="1" edit-icon="mdi-alpha-a" complete editable>Option A</v-stepper-step>
                      <v-divider />
                      <v-stepper-step step="2" edit-icon="mdi-alpha-b" complete editable>Option B</v-stepper-step>
                      <v-divider />
                      <v-stepper-step step="3" edit-icon="mdi-tray-arrow-down" complete editable>Confirm</v-stepper-step>
                    </v-stepper-header>
                    
                    <v-stepper-content step="1">
                      <v-card class="pb-4" height="100%" outlined flat>
                        <v-img max-height="100%" src="img/docs/android_install_1.jpg" />
                        <v-divider />
                        <v-card-text class="pb-0"><v-span class="font-italic">Option A</v-span> — Click the "Add Ice Time to Home screen" link.</v-card-text>
                      </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                      <v-card class="pb-4" height="100%" outlined flat>
                        <v-img max-height="100%" src="img/docs/android_install_2.jpg" />
                        <v-divider />
                        <v-card-text><v-span class="font-italic">Option B</v-span> — Ciick the install menu item found within the top right menu: <v-chip small><v-icon small>mdi-dots-vertical</v-icon></v-chip> > <v-chip small><v-icon class="pr-1" small>mdi-cellphone-arrow-down</v-icon> Install app</v-chip></v-card-text>
                      </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                      <v-card class="pb-4" height="100%" outlined flat>
                        <v-img max-height="100%" src="img/docs/android_install_3.jpg" />
                        <v-divider />
                        <v-card-text>Confirm that you want to install Ice Time. After clicking install, you should see the Ice Time icon on your device's home screen.</v-card-text>
                      </v-card>
                    </v-stepper-content>
                  </v-stepper>
                </v-row>

                <v-row cols="12" class="d-none d-md-flex pa-2 mb-3">
                  <v-flex md12 lg4>
                    <v-card class="ma-3" height="100%" outlined flat>
                      <v-img max-height="100%" src="img/docs/android_install_1.jpg" />
                      <v-divider />
                      <v-card-text class="pb-0"><v-span class="font-italic">Option A</v-span> — Click the "Add Ice Time to Home screen" link.</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex md12 lg4>
                    <v-card class="ma-3" height="100%" outlined flat>
                      <v-img max-height="100%" src="img/docs/android_install_2.jpg" />
                      <v-divider />
                      <v-card-text><v-span class="font-italic">Option B</v-span> — Ciick the install menu item found within the top right menu: <v-chip small><v-icon small>mdi-dots-vertical</v-icon></v-chip> > <v-chip small><v-icon class="pr-1" small>mdi-cellphone-arrow-down</v-icon> Install app</v-chip></v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex md12 lg4>
                    <v-card class="ma-3" height="100%" outlined flat>
                      <v-img max-height="100%" src="img/docs/android_install_3.jpg" />
                      <v-divider />
                      <v-card-text>Confirm that you want to install Ice Time. After clicking install, you should see the Ice Time icon on your device's home screen.</v-card-text>
                    </v-card>
                  </v-flex>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {
      browser_tab: '',
      mobile_tab: ''
    }
  }
}
</script>

<style scoped>
>>>.v-slide-group--is-overflowing > div:nth-child(1) {
  display: none!important;
  visibility: hidden!important;
}
</style>
