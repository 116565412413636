<template>
  <v-scroll-y-reverse-transition mode="out-in" :hide-on-leave="true">
    <v-overlay :value="true">
      <v-dialog
        v-if="access_point_info"
        class="ma-0 pa-0"
        mode="out-in"
        no-click-animation
        overlay-opacity="0.2"
        persistent
        :value="!this.geofence_record.includes(access_point_id)"
      > 
        <v-container class="pa-0 ma-0">
          <v-card class="pa-4 ma-6" elevation="10" outlined style="border-radius: 10px;">
            <!-- <v-card-title class="my-4 mx-1">
              How was the <span class="deep-orange--text ml-2">{{ access_point_info.name ? (access_point_info.name + " Access Point"):("Access Point @" + access_point_info.lat + ", " +  access_point_info.lng) }}</span>?
            </v-card-title> -->
            <v-card-title class="my-4 mx-1">
              You passed near this access point{{ access_point_info.name ? (' : ' + access_point_info.name):(' @' + access_point_info.lat + ', ' + access_point_ino.lng) }}. Recommend a mode of travel?
            </v-card-title>
            <v-row class="align-center mx-2 mb-6 justify-center">
              <v-card-text 
                v-if="this.selected_condition && this.selected_condition.id == 1" 
                class="text-body-1"
              >
                I was <span class="deep-orange--text font-weight-medium">unable to gain access</span>
              </v-card-text>
              <v-card-text v-else class="text-body-1">
                I was able to gain access by: 
                <span class="deep-orange--text font-weight-medium ml-1">{{ this.selected_condition.name ? this.selected_condition.name:'(select below)' }}</span>
              </v-card-text>
              <v-btn
                v-for="(condition,index) in access_point_conditions"
                class="mx-1" @click="select_condition(condition)"
                :color="selected_condition.id == condition.id ? 'deep-orange':'white'"
                :dark="selected_condition.id == condition.id"
                elevation="0"
                fab
                :key="index"
                small
                :text="false"
              >
                <v-icon v-if="condition.id != '3'" :color="selected_condition.id == condition.id ? 'white':'grey darken-2'">
                  {{ icons[index] }}
                </v-icon>
                <!-- <v-img v-else contain max-width="28" src="/img/quad_default.svg" :style="selected_condition.id == condition.id ? 'filter: invert(100%) sepia(3%) saturate(6863%) hue-rotate(27deg) brightness(109%) contrast(109%);':'filter: invert(31%) sepia(96%) saturate(0%) hue-rotate(174deg) brightness(101%) contrast(98%);'" /> -->
                <v-img v-else contain max-width="25" :src="quad_icon" :style="selected_condition.id == condition.id ? 'filter: invert(100%) sepia(3%) saturate(6863%) hue-rotate(27deg) brightness(109%) contrast(109%);':'filter: invert(31%) sepia(96%) saturate(0%) hue-rotate(174deg) brightness(101%) contrast(98%);'" />
              </v-btn>
            </v-row>
            <v-alert class="my-2 mx-2 py-3 px-4 text-caption" color="amber lighten-2" :value="err_msg != ''">
              <v-layout>
                <v-flex align-self-center class="mx-2" shrink>
                  <v-icon>fa-exclamation-triangle</v-icon>
                </v-flex>
                <v-flex class="ml-4">
                  {{ err_msg }}
                </v-flex>
              </v-layout>
            </v-alert>
            <v-card-actions class="ma-5 justify-center">
              <!-- <v-spacer /> -->
              <v-btn 
                class="pa-5 text-capitalize text-body-1 font-weight-bold grey-darken-2-text"
                :loading="loading"
                rounded
                @click="prompt_clicked"
              >
                {{ selected_condition.id ? 'Continue':'I did not use this Access Point' }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-dialog>
    </v-overlay>
  </v-scroll-y-reverse-transition>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  data() {
    return {
      err_msg: '',
      // geofence_ids: [], // list of geoposts that triggered prompt, recorded so that they are not re-prompted
      icons: [
        'fa-ban',
        'fa-walking',
        'mdi-atv',
        'mdi-snowmobile',
        'fa-truck-pickup',
        'fa-truck-monster'
      ],
      loading: false,
      quad_icon: require('/public/img/quad.svg'),
      radio_group: '',
      selected_condition: {
        id: null,
        name: '',
      },
    }
  },
  components: {
  },
  computed: {
    ...mapGetters('user', {
      isLoggedIn: 'isLoggedIn',
      isStaffUser: 'isStaffUser'
    }),
    ...mapState('access_points', {
      access_point_conditions: state => state.access_point_conditions,
      geofence_access_points: state => state.geofence_access_points,
      geofence_record: state => state.geofence_record,
    }),
    ...mapState('spot_mapper', {
      access_points: state => state.access_points,
    }),
    ...mapState('user', {
      id: state => state.user.id,
    }),
    access_point_id() {
      return this.geofence_access_points.length > 0 ? this.geofence_access_points[0].id:0
    },
    access_point_info() {
      return this.access_point_id ? this.access_points.find(ap => ap.accesspoint_id == this.access_point_id):0
    },
    show_dialog() {
      // return this.access_point_id != 0 ? !this.geofence_ids.includes(this.access_point_id):true 
      return this.access_point_id != 0 ? !this.geofence_record.includes(this.access_point_id):false
    }
  },
  created() {
    if (this.access_point_conditions === undefined || this.access_point_conditions.length == 0) {
      this.$store.dispatch('access_points/getAccessPointConditions')
    }
  },
  methods: {
    new_vote() {
      this.voting = false
      this.$emit('apc-updated')
    },
    prompt_clicked() {
      if (this.selected_condition.id) {
        // add vote if a condition was selected
        this.loading = true
        let data = {
          geopost_id: this.access_point_info.geopost_id,
          vote: this.selected_condition.id,
        }

        this.$store.dispatch('access_points/createVote', data)
        .then(() => {
          this.$emit('apc-updated')
          this.$store.dispatch('access_points/addGeofenceRecord', this.access_point_id)
          this.$emit('apc-untoggled')
        })
        .catch(() => {
          this.err_mesg = 'An error ocurred, please try again.'
          // console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
      } else {
        this.$store.dispatch('access_points/addGeofenceRecord', this.access_point_id)
        this.$emit('apc-untoggled')
      }
    },
    select_condition(condition) {
      if (this.selected_condition == condition) {
        this.selected_condition = ''
      } else {
        this.selected_condition = condition
      }
    },
    toggleVote() {
      this.$store.dispatch('access_points/setAccessPointEditing', false)
    }
  },
  mounted() {
    // if(this.access_point_info.accesspoint_id) console.log(this.geofence_ids.includes(this.access_point_info.accesspoint_id))
  },
  watch: {
    // show_dialog(nd, od) {
    //   console.log(`Old value '${od}' being replaced with new value '${nd}'`)
    //   console.log(this.geofence_record.includes(this.access_point_id))
    // }
  }
}
</script>

<style scoped>
>>>.sticky {
  position: sticky;
  top: 0;
  z-index: 2;
}

>>>.v-dialog{
  /* background-color: #fff; */
  border-radius: 10px;
  bottom: 0; 
  box-shadow: none;
  right: 0;
  margin: 0;
  max-height: 77%;
  max-width: 500px;
  overflow-y: auto!important; 
  padding: 0;
  position: absolute;
  width: unset;
}
</style>