<template><div></div></template>
<script>
import L from 'leaflet'
import 'leaflet.vectorgrid'
import { mapState } from 'vuex'

export default {
  data() {
    return {

    }
  },
  computed:{
    ...mapState('map', {
      map: state => state.map,
      visibleLayer: state => state.visibleLayer,
      legend: state => state.legend,
      iceClassOptions: state => state.iceClassOptions,
    }),
    is_mobile() {
      return this.$vuetify.breakpoint.xs ? true : false
    }         
  },
  props: {
    url: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    visible: {
      type: Boolean,
      custom: true,
      default: true,
    },
  },
  watch: {
    url() {
      this.updateLayer()
    },
    options() {
      this.updateLayer()
    },
    visible() {
      this.updateLayer()
    },
  },
  mounted () {
    this.iceClassOptions['rendererFactory'] = L.canvas.tile

    this.mapObject = L.vectorGrid.protobuf(this.url, this.iceClassOptions)
    L.DomEvent.on(this.mapObject, this.$listeners)
    if (this.$parent._isMounted)  {
      this.deferredMountedTo(this.$parent.mapObject);
    }  
    //change layer order here
  },
  beforeDestroy() {
    this.removeLayer()
  },
  methods: {
    deferredMountedTo(parent) {
      // this.mapObject.addTo(parent)
      parent.addLayer(this.mapObject, !this.visible)

      this.attributionControl = parent.attributionControl;
      for (var i = 0; i < this.$children.length; i++) {
        if (typeof this.$children[i].deferredMountedTo === "function") {
          this.$children[i].deferredMountedTo(this.mapObject);
        }
      }      
    },

    setAttribution(val, old) {
      this.attributionControl.removeAttribution(old);
      this.attributionControl.addAttribution(val);
    },

    setToken(val) {
      this.options.token = val;
    },
    removeLayer() {
      this.$parent.mapObject.removeLayer(this.mapObject);
    },
    updateLayer() {    
      this.removeLayer()
      this.iceClassOptions['rendererFactory'] = L.canvas.tile

      this.mapObject = L.vectorGrid.protobuf(this.url, this.iceClassOptions)
      if (this.options && this.options.interactive) {
        L.DomEvent.on(this.mapObject, this.$listeners)
      }
      this.deferredMountedTo(this.$parent.mapObject);
    }
  }
}
</script>