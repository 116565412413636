<template>
  <v-tabs
    center-active
    centered
    :class="$vuetify.breakpoint.lgAndUp ? 'mt-12':''"
    :height="$vuetify.breakpoint.lgAndUp ? '100%':'unset'"
    :show-arrows="!$vuetify.breakpoint.lgAndUp"
    :vertical="$vuetify.breakpoint.lgAndUp"
  >
    <v-tab
      v-for="(tab,index) in tabs"
      :class="$vuetify.breakpoint.lgAndUp ? 'justify-start text-subtitle-1 text-capitalize ml-0 pl-10 pr-12 py-0':'text-body-2 text-capitalize py-3'"
      :key="tab"
    >
      {{ tab }}
      <v-icon v-if="[0,1,2,3].includes(index)" class="mx-2" icon small>
        mdi-progress-clock
      </v-icon>
    </v-tab>
    <v-tab-item :class="$vuetify.breakpoint.lgAndUp ? '':'mt-2'"><LakeIceTravel /></v-tab-item>
    <v-tab-item :class="$vuetify.breakpoint.lgAndUp ? '':'mt-2'"><IceTimeAcademy /></v-tab-item>
    <!-- <v-tab-item><RAS /></v-tab-item> -->
    <!-- <v-tab-item><APConditions /></v-tab-item> -->
    <v-tab-item :class="$vuetify.breakpoint.lgAndUp ? '':'mt-2'"><Geoposts /></v-tab-item>
    <v-tab-item :class="$vuetify.breakpoint.lgAndUp ? '':'mt-2'"><RadarSatelliteMonitoring /></v-tab-item>
  </v-tabs>
</template>

<script>
import IceTimeAcademy from './IceTimeAcademy.vue'
// import RAS from './RAS.vue'
// import APConditions from './APConditions.vue'
import Geoposts from './Geoposts.vue'
import RadarSatelliteMonitoring from './RadarSatelliteMonitoring.vue'
import LakeIceTravel from './LakeIceTravel.vue'

export default { 
  components: {
    IceTimeAcademy,
    // RAS,
    // APConditions,
    Geoposts,
    RadarSatelliteMonitoring,
    LakeIceTravel,
  },
  data() {
    return {
      tabs: [
        'Lake Ice Travel',
        'Ice Time Academy',
        // 'Regional Advisory Service',
        // 'Access Point Conditions',
        'GeoPosts™',
        'Radar Satellite Monitoring',
      ]
    }
  },
  methods: {
  }
}
</script>