<template>
<v-container class="ma-0 pa-0">
    <v-card class="blue lighten-5 mb-16">
        <v-card-title>Upcoming Competitions</v-card-title>
        <!-- <v-btn
                
                @click="testbtn"
                >
                    Test
            </v-btn> -->
        <v-card-text>
            <v-btn
                v-if="!filters_on"
                @click="filterOptions"
                >
                    Filter
            </v-btn>
            <v-btn
                v-else
                @click="filterOptions"
                class="success">
                Filter<v-icon>mdi-check</v-icon>
            </v-btn>            
        </v-card-text>
            <v-dialog v-model="show_filters" width="1000">
                <v-card 
                    class="pa-4" 
                    v-if="show_filters"
                    >

                    <v-card-text>
                        <v-radio-group
                            v-model="filter_joined"
                            mandatory
                            @change="filterComps()"
                            >
                            <!-- @change="filterjoinedList" -->
                            <v-radio
                                label="Joined Competitions"
                                value="joined"
                            ></v-radio>
                            <v-radio
                                label="Joinable Competitions"
                                value="not-joined"
                            ></v-radio>
                            <v-radio
                                label="All Competitions"
                                value="all"
                            ></v-radio>
                        </v-radio-group>
                        <!-- Live filter may be redundent with date range? -->
                        <!-- <v-divider></v-divider>
                        <v-radio-group
                            v-model="filter_live"
                            mandatory
                            row
                            >
                            <v-radio
                                label="Live Competitions"
                                value="live"
                            ></v-radio>
                            <v-radio
                                label="Upcoming Competitions"
                                value="upcoming"
                            ></v-radio>
                            <v-radio
                                label="All Competitions"
                                value="all"
                            ></v-radio>
                        </v-radio-group> -->
                        <v-divider></v-divider>
                        <v-row>
                        <v-col>
                            <v-menu
                                v-model="start_menu"
                                :close-on-content-click="false"
                                transition="slide-x-transition"
                                min-width="auto"
                                @input="filterComps()"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="filter_start_date"
                                    label="Start"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @change="filterComps()"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="filter_start_date"
                                no-title
                                scrollable
                                @input="start_menu = false && filterComps()"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <v-menu
                                v-model="end_menu"
                                :close-on-content-click="false"
                                transition="slide-x-transition"
                                min-width="auto"
                                @input="filterComps()"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="filter_end_date"
                                    label="End"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="filter_end_date"
                                no-title
                                scrollable
                                @input="end_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        </v-row>
                        <div class="text-end">
                            <v-row >
                                <v-col>
                                    <v-btn class="mx-2" color="error" @click="clearFilters()">clear</v-btn>
                                    <v-btn color="success" @click="filterOptions()">ok</v-btn>
                                    <!-- <v-btn color="success" @click="filterComps()">Confirm</v-btn> -->
                                </v-col>                            
                            </v-row>
                        </div>
                    </v-card-text>

            </v-card>           
        </v-dialog>
        <div v-if="!loading">

        
        <v-container v-if="filtered_comps.length == 0">
            <v-card >
                <v-card-title>No competitions found!</v-card-title>
            </v-card>            
        </v-container>

        <v-container v-else v-for="(item, index) in filtered_comps" :key="item.id">
            <!-- Temp Add Example -->
            <v-card v-if="(index % 4 === 1)"
                class="px-4 py-2 mb-10"
                outlined
                elevation="2"
            
            >
                <v-img contain src="/img/nge_logo.png" max-height="250"/>                
            </v-card>

            <v-card
                :class="set_padding_margins"
                :key="item.id"
                outlined            
                elevation="2"
                @click="toComp(item.id)"
            >
                <div>
                    <v-card-text>                        
                        <v-card-actions class="pa-0">
                            <p :class="font_size_title">{{convertSpecial(item.name)}}</p><v-spacer></v-spacer>
                            <v-menu v-if="isLive(item.start_date, item.end_date)" no-gutters>
                            <template v-slot:activator="{ on: menu, attrs }">
                                <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">                                    
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="{ ...tooltip, ...menu }" 
                                        class="mx-4"                              
                                    >
                                    <span><v-icon v-if="icon_color" color="red">mdi-access-point</v-icon></span>
                                    <span><v-icon v-if="!icon_color" color="black">mdi-access-point</v-icon></span>
                                    LIVE
                                    </v-btn>
                                </template>
                                <span>Live Competition</span>
                                </v-tooltip>
                            </template>
                        </v-menu>     
                        </v-card-actions>    
                        <div>{{formatDates(item.start_date)}} - {{formatDates(item.end_date)}}</div>
                    </v-card-text>            
                
                    <v-row no-gutters>
                        <v-col cols="14">
                            <v-card-text class="py-0">
                                <div>Hosted By</div>
                                <p :class="font_size_value">{{item.host}}</p>                        
                            </v-card-text>                    
                        </v-col>
                        <v-col cols="14" sm="7">
                            <v-card-text class="py-0">
                                <div>Location</div>
                                <p :class="font_size_value">{{item.location}}</p>                        
                            </v-card-text>   
                        </v-col>
                    </v-row>

                    <v-row no-gutters>
                        <v-col cols="14">
                            <v-card-text class="py-0">
                                <div>Buy In</div>
                                <p :class="font_size_value">${{item.buy_in}}</p>                        
                            </v-card-text>                    
                        </v-col>
                        <v-col sm="7">
                            <v-card-text class="py-0">
                                <div>Total Payout</div>
                                <p :class="font_size_value">${{ getPrizeAmount(item.value, item.prize_type, item.buy_in, item.members) }}</p>                        
                            </v-card-text>   
                        </v-col>
                    </v-row>

                    <v-row no-gutters align="center">
                        <v-col cols="6" sm="5">
                            <v-card-text class="py-0">
                                <div>Participants</div>
                                <p :class="font_size_value">{{ item.members }}/{{ (item.team_limit * item.team_size) }}</p>                        
                            </v-card-text>                    
                        </v-col>
                        <v-col cols="6" sm="5">
                            <v-card-text class="py-0">
                                <div v-if="item.team_size>1">
                                    <div >Teams</div>
                                    <p :class="font_size_value">{{ item.teams }}/{{ item.team_limit }}</p>
                                </div>                            
                                <div v-else>
                                    <div >Individual Competition</div>
                                    <p :class="font_size_value">-</p>
                                </div>                                                   
                            </v-card-text>   
                        </v-col>
                        <v-col cols="12" sm="2" class="text-right">
                            <v-btn
                                class="blue ma-4"
                                v-if="joined_list.some(comp => comp.id === item.id)"
                                @click="toComp(item.id)"
                            >
                                <span class="white--text"><v-icon left>mdi-check</v-icon>Joined</span>
                            </v-btn>

                            <v-btn 
                                v-else
                                class="green ma-4"
                                @click="toComp(item.id)"
                            >                     
                                <span class="white--text"><v-icon left>mdi-account-plus</v-icon>Joinable</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>

                <!-- <v-row class="mx-auto">
                    <v-col>
                        <h2>
                            
                            <v-menu v-if="isLive(item.start_date, item.end_date)">
                            <template v-slot:activator="{ on: menu, attrs }">
                                <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn
                                    icon
                                    large
                                    v-bind="attrs"
                                    v-on="{ ...tooltip, ...menu }"
                                    >
                                    <span><v-icon v-if="icon_color" color="red">mdi-access-point</v-icon></span>
                                    <span><v-icon v-if="!icon_color" color="black">mdi-access-point</v-icon></span>
                                    </v-btn>
                                </template>
                                <span>Live Competition</span>
                                </v-tooltip>
                            </template>
                        </v-menu>
                        </h2>
                        
                    </v-col>
                    <v-col md="4" class="mx-auto pt-5">
                        <h5 >
                            {{item.start_date.substring(0,16)}} - {{item.end_date.substring(0,16)}}
                        </h5>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="4">
                        Hosted By: {{item.host}}
                    </v-col>
                    <v-col md="4">
                        Location: {{item.location}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="3">
                        <v-row>
                            <v-col>
                                Buy-In:
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                ${{item.buy_in}}
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col md="3">
                        <v-row>
                            <v-col>
                                ~Total Payout:
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                ${{item.prize_pool}}
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row v-if="button_load">
                    <v-col md="4">
                        Participants: {{ item.members }}/{{ (item.team_limit * item.team_size) }}
                    </v-col>
                    <v-col md="4" sm="6" cols="6" v-if="item.team_size>1">
                        Teams: {{ item.teams }}/{{ item.team_limit }}
                    </v-col>
                    <v-col md="4" sm="6" cols="6" v-else>
                        Individual Competition
                    </v-col>
                    <v-col md="4" align-self="end" v-if="joined_list === null || !joined_list.some(comp => comp.id === item.id)">
                        <v-btn
                        class="green"
                        @click="toComp(item.id)"
                        >
                            <span class="white--text">Register Here</span>
                        </v-btn>
                        <v-menu v-if="isLive(item.start_date, item.end_date)">
                            <template v-slot:activator="{ on: menu, attrs }">
                                <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn
                                    text
                                    v-bind="attrs"
                                    v-on="{ ...tooltip, ...menu }"
                                    >
                                    <span><v-icon color="red">mdi-access-point</v-icon></span>
                                    </v-btn>
                                </template>
                                <span>Live Competition</span>
                                </v-tooltip>
                            </template>
                        </v-menu>
                    </v-col>
                    <v-col md="4" align-self="end" v-if="joined_list.some(comp => comp.id === item.id)">
                        <v-btn
                        class="blue"
                        @click="toComp(item.id)"
                        >
                            <span class="white--text">View Competition</span>
                        </v-btn>
                        <v-menu v-if="isLive(item.start_date, item.end_date)">
                            <template v-slot:activator="{ on: menu, attrs }">
                                <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn
                                    text
                                    v-bind="attrs"
                                    v-on="{ ...tooltip, ...menu }"
                                    >
                                    <span><v-icon color="red">mdi-access-point</v-icon></span>
                                    </v-btn>
                                </template>
                                <span>Live Competition</span>
                                </v-tooltip>
                            </template>
                        </v-menu>
                    </v-col>
                </v-row> -->
            </v-card>
        </v-container>
        </div>

    </v-card>

    <!-- <v-btn @click="testStuff">

    </v-btn> -->
</v-container>
</template>

<script>
//import
import { mapState } from 'vuex';

export default{
    data(){
        return {
            button_load: false,
            filtered_comps:[],
            filter_joined:'all',
            filter_live:'all',
            filter_start_date:null,
            filter_end_date:null,
            loaded:false,
            temp_start:[
                'Oct 3rd 2024',
                'Oct 4th 2024',
                'Oct 5th 2024',
                'Oct 6th 2024',
                'Oct 7th 2024',
                'Oct 8th 2024',
            ],
            temp_end:[
                'Oct 3rd 2024',
                'Oct 4th 2024',
                'Oct 5th 2024',
                'Oct 6th 2024',
                'Oct 7th 2024',
                'Oct 8th 2024',
            ],
            show_filters:false,
            filters_on:false,
            start_menu:false,
            end_menu:false,
            current_date:null,

            icon_color:true,
            loading: true,
        }
    },
    watch: {
        filter_start_date(){
            this.filterComps()
        },
        filter_end_date(){
            this.filterComps()
        }
    },
    components:{

    },
    computed: {
        ...mapState('leaderboard', {
            competitions: state => state.competitions,
            joined_list: state => state.joined_list,
        }),
        // ...mapState('spot_mapper', {
        //     joined_comps: state => state.joined_comps
        // }),
        font_size_title(){
            return !this.$vuetify.breakpoint.xs ? "text-h4 mb-0" : "text-h6 mb-0"
        },       
        font_size_value(){
            return !this.$vuetify.breakpoint.xs ? "text-h5" : "text-h6"
        },    
        set_padding_margins(){
            return !this.$vuetify.breakpoint.xs ? "px-4 py-2 mb-3" : ""
        }          
    },
    methods: {
        // delete when testing is done
        testbtn(){
            let data = {
                id:'1',
                measurement: '20.87',
                status: '2'
            }
            this.$store.dispatch('leaderboard/testCV', data)
        },
        formatDates(date){
            let temp = date + ' UTC'
            let value = new Date(temp)
            
            return (value.toLocaleString())
        },
        swap_color(){
            this.icon_color = !this.icon_color
            setTimeout(()=> {
                    this.swap_color()
                    // console.log("color")
                }, 800)
        },
        toComp(id){
            // console.log(id)
            // window.location.href = 
            this.$router.push(("/app/leaderboard/compsummary/" + id))
            // this.$store.selected_competition = id
            // this.$store.dispatch('leaderboard/getCompetition')
            // this.$store.dispatch('leaderboard/getTeams')
            // this.$store.page = 'selected_comp'
            // this.$emit('change-page')
        },
        clearFilters(){
            this.filtered_comps = this.competitions
            this.filter_joined = 'all'
            this.filter_live = 'all'
            this.filter_start_date = null
            this.filter_end_date = null
            this.filters_on = false
        },
        getPrizeAmount(total, type, buy_in, members){
            let result = total
            if(type == 2){
                let total_buy_ins = buy_in * members
                result = Math.round(((total/100) * total_buy_ins) * 100) / 100
            }
            if(!result){result = 0}
            return result
        },
        convertSpecial(text){  
            text = String(text).replace("&lt;" , "<")
            text = String(text).replace("&gt;" , ">")
            text = String(text).replace("&quot;" , "\"")
            text = String(text).replace("&#039;" , "'")
            text = String(text).replace("&amp;" , "&")
            // return htmlStr
            // console.log(text)
            return text
        },
        filterComps(){
            //Filter joined
            let joined_filtered = []
            this.filtered_comps = []
            this.filters_on = true
            if(this.filter_joined==="joined"){
                this.competitions.forEach(comp => {
                    if(this.joined_list.some(joined => joined.id === comp.id)){
                        joined_filtered.push(comp)
                        // console.log("comp:" + comp.id)
                    }

                })
                // (this.joined_comps.some(comp => comp.id === item.id)
            }else if(this.filter_joined==="not-joined"){
                this.competitions.forEach(comp => {
                    if(this.joined_list === null || !this.joined_list.some(joined => joined.id === comp.id)){
                        joined_filtered.push(comp)
                        // console.log("comp:" + comp.id)
                    }

                })
            }else if(this.filter_joined==="all"){
                this.filters_on = false
                joined_filtered = this.competitions
            }
            // console.log(joined_filtered)
            //filter live
            // let live_filtered = []
            // let current_date = new Date()
            // if(this.filter_live==="live"){
            //     this.joined_filtered.forEach(comp => {
            //         if(this.joined_list.some(joined => joined.id === comp.id)){
            //             this.live_filtered.push(comp)
            //             // console.log("comp:" + comp.id)
            //         }

            //     })
            //     // (this.joined_comps.some(comp => comp.id === item.id)
            // }else if(this.filter_joined==="upcoming"){
            //     this.joined_filtered.forEach(comp => {
            //         if(this.joined_list === null || !this.joined_list.some(joined => joined.id === comp.id)){
            //             this.live_filtered.push(comp)
            //             // console.log("comp:" + comp.id)
            //         }

            //     })
            // }else if(this.filter_joined==="all"){
            //     // this.filtered_comps = this.competitions
            // }

            //filter start dates

            let start_date_filtered = joined_filtered
            // console.log(start_date_filtered)
            if(this.filter_start_date!== null){
                start_date_filtered = joined_filtered.filter(a => {
                    var date = new Date(a.start_date)
                    // console.log(date)
                    let temp_start_date = new Date(this.filter_start_date)
                    // console.log(temp_start_date)
                    return (date >= temp_start_date)
                })
                this.filters_on = true
            }

            // console.log(start_date_filtered)

            //filter end dates

            let end_date_filtered = start_date_filtered
            if(this.filter_end_date !== null){
                end_date_filtered = joined_filtered.filter(a => {
                    var date = new Date(a.end_date)
                    let temp_end_date = new Date(this.filter_end_date)
                    return (date <= temp_end_date)
                })
                this.filters_on = true
            }
             
            // console.log(end_date_filtered)
            this.filtered_comps = end_date_filtered

        },
        isLive(start_date, end_date){
            let startDate = new Date(start_date);
            let endDate = new Date(end_date);

            return (this.current_date >= startDate && this.current_date <= endDate);
        },
        filterjoinedList(){
            this.filtered_comps = []
            if(this.filter_joined==="joined"){
                this.competitions.forEach(comp => {
                    if(this.joined_list.some(joined => joined.id === comp.id)){
                        this.filtered_comps.push(comp)
                        // console.log("comp:" + comp.id)
                    }

                })
                // (this.joined_comps.some(comp => comp.id === item.id)
            }else if(this.filter_joined==="not-joined"){
                this.competitions.forEach(comp => {
                    if(this.joined_list === null || !this.joined_list.some(joined => joined.id === comp.id)){
                        this.filtered_comps.push(comp)
                        // console.log("comp:" + comp.id)
                    }

                })
            }else if(this.filter_joined==="all"){
                this.filtered_comps = this.competitions
            }
        },
        filterOptions(){
            this.show_filters = !this.show_filters
        },
    },
    mounted() {
        window.scrollTo(0,0);
        this.current_date = new Date()
        //Change this API to include team count per member?
        this.$store.dispatch('leaderboard/getCompetitions')
        .then(() =>{
            this.filtered_comps = this.competitions
            // console.log(this.filtered_comps)
            this.loading = false
        })
        // this.$store.dispatch('spot_mapper/getJoinedComps')
        this.$store.dispatch('leaderboard/getJoinedList')
            .then(() => {
                this.button_load = true
                // console.log(this.filtered_comps)
                // console.log(this.joined_list)
            })
            .finally(() =>{
                this.loaded = true
                this.swap_color()
                //*This is to view the url Data Params, and will be used for the Bumb Board testing*
                let urlParams = new URLSearchParams(window.location.search);
                let myParam = urlParams.get('mode');
                // console.log(myParam)
                if(myParam === "joined"){
                    this.filter_joined = "joined"
                    this.filterComps()
                }                
            })
        // console.log(this.competitions)
        // console.log(this.joined_comps.some(comp => comp.id === "23"))
    }
}
</script>

<style scoped>
>>>.col{
    height: 50%
}
</style>
