<template>
  <v-card
    class="ma-5 pa-4"
  >
    <ComingSoonChip />
    <v-card-title>
      Incident Reporting
    </v-card-title>
    <v-card-text class="text-body-1">
      Stay informed with near real-time reporting of ice incidents published by professionally trained first responders<span class="text-caption">*</span>. Receive text notifications and map locations of known incidents near you.
    </v-card-text>
    <v-card-text class="text-caption">
      * where applicable.
    </v-card-text>
  </v-card>
</template>
  
<script>
import ComingSoonChip from "./ComingSoonChip.vue"

export default { 
  components: {
    ComingSoonChip,
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
  