<template>
  <v-app>
    <!-- <v-navigation-drawer 
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      v-model="navDrawer"
      width="300px"
    >
      <Menu />
    </v-navigation-drawer> -->
    <v-app-bar 
      app 
      color="#2F4782" 
      dark 
      :clipped-left="$vuetify.breakpoint.lgAndUp"
    >
      <!-- <v-app-bar-nav-icon @click.stop="navDrawer = !navDrawer" /> -->
      <!-- <v-btn v-if="$route.path != '/'" class="mr-0" dense icon text :to="mode == 'ras_history' ? '/ras':'/'">
        <v-icon>fa-caret-left</v-icon>
      </v-btn> -->
      <v-btn class="mx-0" icon tile to="/" height="35" width="35">
        <v-img 
          alt="Ice Time icon" 
          max-height="35" 
          max-width="35" 
          src="/img/icons/apple-touch-icon-60x60.png" 
        />
      </v-btn>
      <v-toolbar-title>{{ mode_title }}</v-toolbar-title>
      <v-spacer />
      <!-- <span v-if="$vuetify.breakpoint.smAndUp" class="text-h6 indigo--text text--lighten-5 pr-10">Verification: {{ verification_number.toUpperCase() }}</span>
      <span v-else class="text-h6 indigo--text text--lighten-5 pr-4 pl-4"> {{ verification_number.toUpperCase() }}</span> -->
      <Status />
      <template v-if="mode && mode != 'regions' && mode !='discovery' && mode != 'ras' && mode != 'ras_history' && mode != 'academy'&& isLoggedIn" v-slot:extension>
        <ModeTabs />
      </template>
    </v-app-bar>

    <v-dialog
      v-if="dialog.show"
      v-model="dialog.show"
      persistent
      max-width="400px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title class="text-h5">Ice Time Notification</v-card-title>
        <v-card-text v-if="dialog.message">{{ dialog.message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1" 
            text
            @click="clickDialog" 
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-main>
      <v-banner 
        v-if="showTrialBanner"
        app 
        color="deep-orange accent-2" 
      >
        <v-icon class="mr-2 mb-1" small>fa-exclamation-triangle</v-icon>
        <span class="font-weight-bold">
          Keep using Ice Time for free by verifying your email address{{ isExpiredTrialUser ? "":` within ${14 - user_dayspast} day(s)`}}.
        </span>
        <template v-slot:actions style="padding:0;">
          <v-btn 
            color="deep-orange darken-2" 
            elevation="0"
            :loading="loading"
            @click="resend_verification"
          >
            {{ message }} 
          </v-btn>
        </template>
      </v-banner>
      <v-banner
        v-if="!isLoggedIn && ($route.path.startsWith('/app') || $route.path.startsWith('/regions'))"
        app
        color="deep-orange"
        :single-line="$vuetify.breakpoint.mdAndUp"
      >
        <span class="font-weight-bold">
          Register to use more tools and create content.
        </span>
        <template v-slot:actions style="padding:0;">
          <v-btn 
            color="deep-orange darken-2" 
            elevation="0"
            to="/signup"
          >
            Register
          </v-btn>
        </template>
      </v-banner>
      <v-scroll-x-transition mode="out-in" :hide-on-leave="true">
        <router-view></router-view>
      </v-scroll-x-transition>
    </v-main>
    <v-snackbar :timeout="-1" :value=" snackbar && snackbar.showing">
      <!-- <v-btn text :href=snackbar.link>{{ snackbar.text }}</v-btn> -->
      <v-layout row>
        <v-flex v-if="!snackbar.link || snackbar.link == ''" class="my-4 mx-5">
          {{ snackbar.text }}
        </v-flex>
        <v-flex v-else class="my-4 mx-5">
          <router-link 
            :to="snackbar.link" 
            style="border-bottom: 0.5px dashed grey; color: #ffffff; padding-bottom: 1px; text-decoration: none; text-transform: none;"
          >
            {{ snackbar.text }}
          </router-link>
        </v-flex>
      </v-layout>
      <template v-slot:action="{attrs}">
        <v-btn 
          v-bind="attrs" 
          class="mx-3" 
          :icon="$vuetify.breakpoint.mdAndDown"
          @click="closeSnackbar"
        >
          <v-icon class="" color="pink">mdi-close</v-icon>
          <span v-if="$vuetify.breakpoint.lgAndUp" class="mx-1">Close</span>
        </v-btn>
      </template>
      <!-- <v-btn @click="snackbar = false">
        <v-icon color="pink">mdi-close</v-icon>
      </v-btn> -->
    </v-snackbar>
    <v-fab-transition>
      <v-btn
        @click="toTop"
        v-scroll="onScroll"
        v-show="topFab"
        class="mb-16"
        color="grey lighten-1"
        transition="slide-y-transition"
        bottom right
        dark
        fab
        fixed 
        style="opacity: 0.75;"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-fab-transition>
    <FABMenu v-if="$route.path != '/'" />
    <!-- <v-btn
      color="grey lighten-1"
      transition="slide-y-transition"
      bottom right
      dark
      fab
      fixed 
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn> -->
  </v-app>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import goTo from 'vuetify/lib/services/goto'
// import Menu from './components/Menu.vue'
import ModeTabs from './components/ModeTabs.vue'
import Status from './components/Status.vue'
import FABMenu from "./components/FABMenu.vue"
import messaging from "./firebase"

export default {
  data() {
    return {
      fab: false,
      hasSubscription: false,
      loading: false,
      message: "Resend Verification Email",
      navDrawer: false,
      registration: null,
      scroll: 0,
      verification_number: "1A2B3C",
    }
  },
  components: {
    FABMenu,
    Status,
    // Menu,
    ModeTabs,
  },
  computed: {
    topFab() {
      return this.scroll > 500
    },
    mode_title() {
      switch(this.mode) {
        case 'admin':
          return 'Admin Dashboard'
        case 'app': {
          switch (this.tab) {
            case 'radar':
              return 'Lake Ice Travel'
            case 'map':
              return 'Spot Mapper'
            case 'leaderboard':
              return 'Leaderboard'   
            case 'howto':
              return 'Leaderboard - How To'   
            case 'about':
              return 'Leaderboard - About'   
            case 'started':
              return 'Leaderboard - Getting Started'   
            case 'faq':
              return 'Leaderboard - FAQ'   
            default:
            return ''
          }
        }
        case 'discovery':
          return 'Feature Discovery'
        case 'ras':
          return 'Regional Advisory Service'
        case 'ras_history':
          return 'RAS History'
        case 'regions':
          return 'Available Imagery'
        case 'user':
          return 'User Dashboard'
        case 'verify':
          return 'Account Verification'
        case 'academy':
          return 'Ice Time Academy'  
        default:
          return 'Ice Time Beta'
      }
    },
    showTrialBanner() {
      return this.isLoggedIn && this.isTrialUser && this.$route.path != '/signup' && this.$route.path != '/user/profile' && !this.$route.path.includes('/user/verify')
    },
    ...mapGetters('user', {
      isLoggedIn: 'isLoggedIn',
      isStaffUser: 'isStaffUser',
      isAdmin: 'isAdmin',
      isExpiredTrialUser: 'isExpiredTrialUser',
      isTrialUser: 'isTrialUser'
    }),
    ...mapState('user',{
            user_id: state => state.user.id,
        }),
    ...mapState(['dialog']),
    ...mapState(['mode']),
    ...mapState(['snackbar']),
    ...mapState(['tab']),
    ...mapState('user', {
    //   user_status: state => state.user.status,
      user_dayspast: state => state.user.days_past,
    })
  },
  created() {
    // this.swStatus = this.swStatusList.default
    // document.addEventListener('swUpdateFound', this.detectedUpdateFound, { once: true })
    // document.addEventListener('swUpdated', this.detectedUpdated, { once: true })
    // document.addEventListener('swOffline', this.detectedOffline, { once: true })
    // document.addEventListener('swError', this.detectedError, { once: true })
    // navigator.serviceWorker.addEventListener('controllerchange', () => {
    //   if (this.refreshing) return
    //   this.refreshing = true
    //   window.location.reload()
    // })

    // this.$store.dispatch('user/getSubscriptions')
    // .then((resp) => {
    //   // console.log(resp)
    //   if(resp.data.length > 0) {
    //     this.hasSubscription = true
    //   }
    // })
    
    // this.$http.interceptors.response.use(undefined, function (err) {
    //   return new Promise(function () {
    //     if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
    //       this.$store.dispatch('logout')
    //     }
    //     throw err;
    //   })
    // })
  },
  methods: {
    clickDialog() {
      this.dialog.show = false
      this.$router.push('/login')
      this.$router.go()
    },
    closeSnackbar() {
      this.$store.dispatch('setSnackbar', {})
    },
    onScroll(e) {
      this.scroll = e.target.scrollingElement.scrollTop;
    },
    // openBillingPortal() {
    //   this.$store.dispatch('user/openBillingPortal')
    //   .catch(err => {
    //     if(err.response.status == '403') {
    //       this.$store.dispatch('logout')
    //       this.$store.dispatch('setDialog', { 
    //         show: true, 
    //         message: err.response.data, 
    //         status: err.response.status })
    //     }
    //   })
    // },
    resend_verification() {
      this.loading = true
      this.$store.dispatch('user/resendVerification')
      .then(() => {
        this.message = "Sent!"
      })
      .catch(err => {
        this.message = err.response.data
      })
      .finally(() => {
        this.loading = false
      })
    },
    toggleNavDrawer() {
      this.$store.dispatch('toggleNavDrawer')
    },
    toTop() {
      goTo(0)
    },
    calculateProofValue(){

      // var now = new Date()
      var UTCNow = new Date()
      var selectDaysSinceEpoch = Math.floor(UTCNow/8.64e7)
      // var fullDaysSinceEpoch = Math.floor(now/8.64e7)
      // var weekDay = now.getUTCDay() +1
      var selectedWeekDay = UTCNow.getUTCDay() +1
      var bigNumber = (selectDaysSinceEpoch * (selectedWeekDay * 10) * this.user_id)
      var base36Number = bigNumber.toString(36)
      // this.fullBase36Number = base36Number
      let tempNumber = base36Number.substring((selectedWeekDay-1), (selectedWeekDay+3))
      let tempRemainder = base36Number.substring(0,(4-(tempNumber.length)))
      let finalDigits = tempNumber + tempRemainder
      this.verification_number = finalDigits

      // console.log(fullDaysSinceEpoch)
      // console.log(weekDay)
      // console.log(selectDaysSinceEpoch)
      // console.log(selectedWeekDay)
      // console.log(bigNumber)
      // console.log(base36Number)
      // console.log("temp Number: " + tempNumber)
      // console.log("temp Remainder: " + tempRemainder)
      // console.log(finalDigits)

    },
  },
  mounted() {
    if (this.isLoggedIn && messaging) {
      try {
        messaging.getToken({ vapidKey: "BDsPTtTxM892AoC7uaXEzqpptgFN83FP1NZdK4Y1aXFH-DW__1CtAvEjnbSkKKsvxijwxztS32cI0RmlNOvNHqw",})
        .then((currentToken) => {
          if (currentToken) {
            // console.log("2a - client token", currentToken);
            let data = {
              registration_token: currentToken
            }
            this.$store.dispatch('subscribe', data)
          } else {
            // console.log("2b - No registration token available");
          }
        });
        // .catch((err) => {
          // console.log("Error caught!", err);
        // });

        messaging.onMessage((payload) => {
          // console.log(payload);
          var payload_link = payload.notification.click_action ? payload.notification.click_action:"";
          this.$store.dispatch('setSnackbar', {
            showing: true,
            text: payload.notification.body,
            link: payload_link
          });
        });
      } catch(err) {
        this.$store.dispatch('setSnackbar', {
          showing: true,
          text: "Heads up! This device is not compatible with push notifications.",
          link: ""
        });
      }
    }
    this.$store.dispatch('checkToken')
    this.calculateProofValue()
    // console.log(this.user)
  }
}
</script>

<style>
/* @media screen and (min-aspect-ratio: 13/9) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100%;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
  .bw-dashboard {
    height: inherit;
  }
}
@media screen and (min-width: 1292px) {
  html {
    transform: none;
    transform-origin: none;
    width: 100%;
    overflow-x: none;
    position: relative;
  }
  .bw-dashboard {
    height: 100vh;
  }
} */

html, body, #app {
  width: 100%;
  height: 100%;
}
.v-application--wrap {
  min-height: 100%!important;
}

.v-btn--outlined {
    background-color: rgba(255,255,255,0.9);
}

.v-speed-dial--direction-top .v-speed-dial__list {
  align-items: baseline;
  width: 300px!important;
}

.v-expansion-panel-content__wrap {
  padding: 0!important;
}

.v-card__text, .v-card__title {
  word-break: normal!important; /* maybe !important  */
}

>>>.v-snack__wrapper {
  max-width: none;
}
</style>