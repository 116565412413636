<template>
  <v-carousel
    cycle
    height="100%"
    hide-delimiter-background
    min="200"
    :show-arrows="false"
    vertical-delimiters
  >
    <v-flex align-self-center>
      <v-carousel-item
        v-for="(img, i) in img_names"
        :key="i"
        eager
      >
        <v-img :src="img" height="100%" eager />
        <!-- <v-img :src="img" /> -->
        <!-- <v-sheet
          :color="colors[i]"
          height="100%"
          min-height="200"
        >
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <div class="text-h2">
              {{ slide }} Slide
            </div>
          </v-row>
        </v-sheet> -->
      </v-carousel-item>
    </v-flex>
  </v-carousel>
</template>

<script>
export default {
  created() {
    this.randomFive().forEach(item => {
      this.img_names.push('img/ff/fastfact_' + item + '.png')
    })
  },
  data() {
    return {
      img_names: [],
      colors: [
        'indigo',
        'warning',
        'pink darken-2',
        'red lighten-1',
        'deep-purple accent-4',
      ],
      slides: [
        'First',
        'Second',
        'Third',
        'Fourth',
        'Fifth',
      ],
    }
  },
  methods: {
    randomFive() {
      let five = new Set()
      let placeholder = 0
      while(five.size < 5) {
        placeholder = Math.floor(Math.random() * 25)
        if (placeholder > 0) five.add(placeholder)
      }
      return Array.from(five)
    }
  },
  setup() {
  },
}
</script>
