import { render, staticRenderFns } from "./CompRoster.vue?vue&type=template&id=fd2c6958&scoped=true"
import script from "./CompRoster.vue?vue&type=script&lang=js"
export * from "./CompRoster.vue?vue&type=script&lang=js"
import style0 from "./CompRoster.vue?vue&type=style&index=0&id=fd2c6958&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd2c6958",
  null
  
)

export default component.exports