<template>
  <v-card 
    class="mt-5 mb-3 mx-3 pa-6" 
    color="grey lighten-3" 
    ref="apc_comments"
    rounded
  >
    <v-card-subtitle 
      class="mb-2 pa-2 text-subtitle-2 black--text"
      ref="apc_textarea"
    >
      Add Comment:
    </v-card-subtitle>
    <v-card 
      class="ma-2" 
      color="grey lighten-2" 
      flat
    >
      <v-textarea
        auto-grow
        autocomplete
        autofocus
        background-color="grey lighten-2"
        class="text-body-2"
        clear-icon="fa-close"
        counter="250"
        dense
        flat
        no-resize
        rows="3"
        solo
        v-model="comment"
      />
    </v-card>
    <v-card-subtitle 
        class="mb-0 pa-2 text-subtitle-2 black--text"
        >
        Comment Image:
        </v-card-subtitle>
        <image-uploader
          ref="fileUpload"
          :debug="0"
          :maxWidth="1024"
          :quality=".9"
          :autoRotate=false
          outputFormat="verbose"
          :preview=false
          :className="['fileinput', { 'fileinput--loaded' : hasImage }]"
          @input="setImage"
        >
        <label for="fileInput" slot="upload-label">
          <figure>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
            >
              <path
                class="path1"
                d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
              ></path>
            </svg>
          </figure>
          <span class="upload-caption">{{
            hasImage ? "Replace" : "Click to upload"
          }}</span>
        </label>
        </image-uploader>
        <div v-if="currentImage">
            <v-img :src="currentImage.dataUrl"/>
        </div>
    <v-card-actions>
      <!-- <v-spacer /> -->
      <v-btn 
        class="py-3"
        color="grey" 
        dark
        elevation="0" 
        :loading="loading"
        x-small 
        width="100%"
        @click="save_comment"
      >
        {{ err_message != '' ? err_message:'save' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import ImageUploader from 'vue-image-upload-resize';

export default {
  components: { 
    ImageUploader
  },
  computed: {
    ...mapState('access_points', {
      selected_condition: state => state.selected_condition,
    }),
    ...mapState('spot_mapper', {
      selected_geopost: state => state.selected_geopost,
    })
  },
  created() {
    // console.log('APCAddComment Created!')
    this.$nextTick(() => {
      this.$nextTick(() => {
        this.panToTextarea()
      })
    })
  },
  data() {
    return {
      comment: '',
      err_message: '',
      err: false,
      loading: false,
      comment_id: null,
      currentImage: undefined,
      previewImage: undefined,
      showMessage: false,
      message: "",
      messageType: "",
      require_photo: false,
      image: null,
      hasImage: false,
      originalImage: null,
      blobImage: null,
    };
  },
  methods: {
    panToTextarea() {
      // console.log("focusing on textarea ...")
      // this.$vuetify.goTo('#apc_textarea', { container: '#apc_comments' })
      this.$vuetify.goTo(this.$refs.apc_textarea, { container: this.$refs.apc_comments })
    },
    save_comment() {
      this.err = false
      this.loading = true
      let data = {
        geopost_id: this.selected_geopost,
        text: this.comment,
      }

      this.$store.dispatch('spot_mapper/createComment', data)
      .then((response) => {
        
        this.comment_id = response["data"]
      })
      .catch(() => {
        this.err_message = 'Retry'
        this.err = true
        // console.log(err)
      })
      .finally(() => {
        this.loading = false
        if(!this.err){
          this.messageType = "success"
          if(this.currentImage != undefined && !this.catch_error){
            this.uploadFile(this.comment_id)
          }else{
            this.$emit('new-comment')
            
          }
        }
      })
    },
    setImage(output) {
      this.hasImage = true
      this.currentImage = output
      // console.log(this.currentImage)
      // console.log(this.currentImage.dataUrl)
      // console.log('Exif', output.exif)
      // console.log('Original Image:', this.originalImage)
      this.originalImage = this.$refs.fileUpload.$el.children[1].files[0]
    },
    uploadFile(commentId) {
      if(this.currentImage != undefined && !this.showMessage) {
        let formData = new FormData()
        // var file = new File()
        fetch(this.currentImage.dataUrl)
        .then(response => response.blob())
        .then(blob => {
            var name = this.currentImage.info.name
            var type = this.currentImage.info.type
            var file = new File([blob], name, {type: type})

            formData.append('file', file)
            formData.append('comment_id', commentId)
            // console.log(formData)
            this.$store.dispatch('spot_mapper/uploadCommentImage', formData)
              .then((response) => {
                this.currentImage = undefined      
                this.message = response["data"]   
                if(this.message.includes("Error")) {
                    this.messageType = "error"
                }
                else {
                    this.messageType = "success"
                }
                this.showMessage = true
              })
              .catch((err) => {
                  // console.log(err)
                  this.err_message = err
              }).finally(() =>{
                this.$emit('new-comment')
              })
        })
      }     
    },
  },
}
</script>

<style scoped>
</style>