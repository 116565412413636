import axios from 'axios'
import messaging from '../../firebase'
import Vue from 'vue'
import Vuex from 'vuex'

// If API is needed
Vue.use(Vuex)
axios.defaults.baseUrl = Vue.$apiEndpointUrl

const state = {
  geolocation: null,
  products: [],
  token: localStorage.getItem('token') || '',
  user: JSON.parse(localStorage.getItem('user')) || {},
  user_types: [],
  profile_card: {},
  profile_card_species: {},
  favorite_species: [],
};

const getters = {
  isAdmin: state => {
    if (!state.token) return false
    return state.user.status == 9
  },
  isLoggedIn: state => !!state.token,
  isVerifiedUser: state => state.user.status > 0,
  isProUser: state => {
    return state.user.status >= 2
  },
  isStaffUser: state => { 
    return state.user.status >= 8
  },
  isTrialUser: state => {
    return state.user.status == 0
  },
  isExpiredTrialUser: state => {
    return state.user.days_past >= 14
  }
};

const mutations = {
  auth_success(state, payload){
    state.token = payload.token
    state.user = payload.user
  },
  // auth_user(state, payload) {
  //   state.user = payload
  // },
  getProducts(state, payload) {
    state.products = payload
  },
  getUserTypes(state, payload) {
    state.user_types = payload
  },
  logout(state){
    state.token = ''
    state.user = {}
  },
  setGeolocation(state, value) {
    state.geolocation = value
  },
  setProfileCard(state, card) {
    state.profile_card = card
  },
  setProfileCardSpecies(state, species) {
    state.profile_card_species = species
  },
  setFavorite_species(state, species) {
    state.favorite_species = species
  },
  user_update(state, payload){
    state.user = payload.user
  },
};

const actions = {
  getProducts({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('products/')
      .then(resp => {
        commit('getProducts', resp.data.data)
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getSubscriptions() {
    return new Promise((resolve, reject) => {
      axios.get('subscriptioninfo')
      .then(resp => {
        // console.log(resp)
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getUserTypes({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('usertypes')
      .then((resp) => {
        let ut = resp.data.filter(function(e) {
          return (
            e.title !== 'Professional' &&
            e.title !== 'Administrator' && 
            e.title !== 'Unverified'
          )
        })
        ut.forEach(userType => {
          userType.selected = false
        })
        commit('getUserTypes', ut)
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  openBillingPortal() {
    return new Promise((resolve, reject) => {
      axios.get('billingportal')
      .then(resp => {
        //console.log(resp)
        //window.location.href = resp.data.url
        window.open(resp.data.url, "_self")
        // const user = resp.data.user
        // commit('user_update', {user})
        resolve(resp)
      }).catch(err => {
        // commit('auth_error', err)
        reject(err)
      })
    })
  },
  login({commit}, user){
    return new Promise((resolve, reject) => {
      //commit('auth_request')
      axios.post('login', user)
      .then(resp => {
        if (typeof(resp.data.token) == 'undefined' || typeof(resp.data.user) == 'undefined') {
          commit('auth_error')
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          reject({response: {data: 'Unknown server error'}})
        } else {
          const token = resp.data.token
          const user = resp.data.user

          localStorage.setItem('token', token)
          localStorage.setItem('user', JSON.stringify(user).replace("\\", ""))
          axios.defaults.headers.common['Authorization'] = token
          commit('auth_success', {
            token,
            user
          })
          resolve(resp)
        }
      })
      .catch(err => {
        // commit('auth_error')
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        reject(err)
      })
    })
  },
  logout({commit}){
    return new Promise((resolve) => {
      commit('logout')
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      delete axios.defaults.headers.common['Authorization']
      resolve()
    })
  },
  resendVerification() {
    return new Promise((resolve, reject) => {
      axios.post('resend_verification')
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  setGeolocation({commit}, location) {
    return new Promise((resolve) => {
      commit('setGeolocation', location)
      resolve()
    })
  },
  signup({commit}, user){
    return new Promise((resolve, reject) => {
      axios.post('signup', user)
      .then(resp => {
        if (typeof(resp.data.token) == 'undefined' || typeof(resp.data.user) == 'undefined') {
          // commit('auth_error')
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          reject({response: {data: 'Unknown server error'}})
        } else {
          const token = resp.data.token
          const user = resp.data.user

          localStorage.setItem('token', token)
          localStorage.setItem('user', JSON.stringify(user).replace("\\", ""))
          axios.defaults.headers.common['Authorization'] = token
          commit('auth_success', {
            token,
            user
          })
          resolve(resp)
        }
      })
      .catch(err => {
        // commit('auth_error', err)
        // localStorage.removeItem('token')
        // localStorage.removeItem('user')
        reject(err)
      })
    })
  },
  subscribe({dispatch}){
    // console.log("[$store] Subscribe process starting ...");

    return new Promise((resolve, reject) => {
      try {
        // console.log("[$store] [FCM] Retrieving token ...");
        messaging.getToken({
          vapidKey:
            "BDsPTtTxM892AoC7uaXEzqpptgFN83FP1NZdK4Y1aXFH-DW__1CtAvEjnbSkKKsvxijwxztS32cI0RmlNOvNHqw",
        })
        .then((currentToken) => {
          if (currentToken) {
            // console.log("[$store] [FCM] Token retrieved: ", currentToken);

            let data = {
              registration_token: currentToken
            }

            // console.log("[$store] [API] Attempting to subscribe token ...");
            axios.post('subscribe', data)
            .then(resp => {
              messaging.onMessage((payload) => {
                var payload_link = payload.notification.click_action ? payload.notification.click_action:"";
                dispatch('setSnackbar', {
                  showing: true,
                  text: payload.notification.body,
                  link: payload_link
                });
              });
              // console.log("[$store] [API] Tokens subscribed!");
              resolve(resp)
            })
            .catch(err => {
              // console.log("[$store] [API] Token registration failed: " . err);
              // commit('auth_error')
              reject(err)
            })
          }
        });
      } catch(err) {
        dispatch('setSnackbar', {
          showing: true,
          text: "Heads up! This device is not compatible with push notifications.",
          link: ""
        });
      }
    })
  },
  updatePassword(_, data) {
    return new Promise((resolve,reject) => {
      axios.post('update', data)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  updatePrimaryRegion({commit}, region) {
    return new Promise((resolve, reject) => {
      axios.put('primary_region', region)
      .then(resp => {
        const user = resp.data
        localStorage.setItem('user', JSON.stringify(user).replace("\\", ""))
        commit('user_update', {user})
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateProfile({commit}, user){
    return new Promise((resolve, reject) => {
      axios.put('profile', user)
      .then(resp => {
        const user = resp.data.user
        localStorage.setItem('user', JSON.stringify(user).replace("\\", ""))
        commit('user_update', {user})
        resolve(resp)
      }).catch(err => {
        // commit('auth_error', err)
        reject(err)
      })
    })
  },
  updatePrefrences({commit}, prefrences){
    return new Promise((resolve, reject) => {
      axios.put('user_prefrences', prefrences)
      .then(resp => {
        const user = resp.data.user
        localStorage.setItem('user', JSON.stringify(user).replace("\\", ""))
        commit('user_update', {user})
        resolve(resp)
      }).catch(err => {
        // commit('auth_error', err)
        reject(err)
      })
    })
  },
  uploadProfileImage({commit}, data) {
    return new Promise((resolve, reject) => {
      axios.post('uploadprofileimage', data)
      .then(resp => {
        const user = resp.data["user"]
        localStorage.setItem('user', JSON.stringify(user).replace("\\", ""))
        commit('user_update', {user})
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  verifyAccount({commit}, token) {
    return new Promise((resolve, reject) => {
      axios.put('activate/' + token)
      .then(resp => {
        // const user = resp.data
        // localStorage.setItem('user', JSON.stringify(user).replace("\\", ""))
        // commit('auth_success', user)
        // resolve(resp)
        if (typeof(resp.data.token) == 'undefined' || typeof(resp.data.user) == 'undefined') {
          commit('auth_error')
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          resolve(resp)
          // reject({response: {data: 'Unknown server error'}})
        } else {
          const token = resp.data.token
          const user = resp.data.user

          localStorage.setItem('token', token)
          localStorage.setItem('user', JSON.stringify(user).replace("\\", ""))
          axios.defaults.headers.common['Authorization'] = token
          commit('auth_success', {
            token,
            user
          })
          resolve(resp)
        }
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getProfileCard({ commit }, request) {
    return new Promise((resolve, reject) => {
      axios.get('profilecard/' + request)
      .then(resp => {
        commit('setProfileCard', resp.data)
        // console.log(resp)
        resolve(resp)
    })
      .catch(err => {
        // console.log(err)
        reject(err)
      })
    })
  },
  getFavoriteSpecies({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('favorite_species')
      .then(resp => {
        commit('setFavorite_species', resp.data)
        // console.log(resp)
        resolve(resp)
    })
      .catch(err => {
        // console.log(err)
        reject(err)
      })
    })
  },
  getProfileCardSpecies({ commit }, {user, species}) {
    return new Promise((resolve, reject) => {
      axios.get('profilecardspecies/' + user + '/' + species)
      .then(resp => {
        commit('setProfileCardSpecies', resp.data)
        // console.log(resp)
        resolve(resp)
    })
      .catch(err => {
        // console.log(err)
        reject(err)
      })
    })
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}