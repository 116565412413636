<template>
  <v-card
    class="ma-5 pa-4"
  >
    <v-card-title>
      Spot Mapper - map your spots and log your catches
    </v-card-title>
    <v-card-text class="text-body-1">
      Finally, a dedicated tool that is easy to use and separates your spot mapping from your sonar. At last, your sonar screen can focus on what it does best…sonar. Map your sites. Export your map coordinates, notes, and fish catch data.
    </v-card-text>
    <v-card-text class="text-body-1">
      Yes, you can keep your spots and catch info private. 
    </v-card-text>
    <v-card-text>
      ( Map of spots and graphic of the real fish records )
    </v-card-text>
  </v-card>
</template>
  
  <script>
  export default { 
    data() {
      return {
      }
    },
    methods: {
    }
  }
  </script>
  