<template>
  <v-btn
    @click.prevent="$emit('regular-click')"
    :color="color"
    :disabled="disabled"
    :to="route"
    :width="$vuetify.breakpoint.xs ? '100%' : 'auto'"
    class="mx-1 my-2 py-6 px-5 white--text "
    rounded
  >
    <v-icon v-if="icon" class="mr-3">{{ icon }}</v-icon>
    {{ text }}
  </v-btn>
</template>

<script>

export default {
  data() {
    return {
    }
  },
  props: [ 
    'color',
    'disabled',
    'icon',
    'text',
    'route',
  ]
}
</script>