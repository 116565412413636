<template>
  <v-tabs
    slider-color="blue"
    slider-size="3"
    :align-with-title="!$vuetify.breakpoint.xs"
    :grow="$vuetify.breakpoint.xs"
  >
    <!-- <v-tab key="mapper" to="/app/mapper" >
      <v-icon v-if="$vuetify.breakpoint.xs">fa-map-marker-alt</v-icon>
      <span v-else>Spot Mapper</span>
    </v-tab> -->
    <v-tab key="radar" to="/app/radar">
      <v-icon v-if="$vuetify.breakpoint.xs">fa-walking</v-icon>
      <span v-else>Lake Ice Travel</span>
    </v-tab>
    <v-tab key="map" to="/app/map">
      <v-icon v-if="$vuetify.breakpoint.xs">fa-compass</v-icon>
      <span v-else>Spot Mapper</span>
    </v-tab>
    <v-tab key="leaderboard" to="/app/leaderboard/all">
      <v-icon v-if="$vuetify.breakpoint.xs">fa-medal</v-icon>
      <span v-else>Leaderboard</span>
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  data() {
    return {
    }
  },
}
</script>