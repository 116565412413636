<template>
  <v-fade-transition mode="out-in" :hide-on-leave="true">
    <v-card 
      :class="$vuetify.breakpoint.smAndDown ? 'mt-4 pa-2':'mt-8 pa-2'"
      v-show="show"
      width="100%"
      :loading="entry"
    >
    <span v-show="!entry">
      <v-card-title>
        {{ advisory.footprint_name ? advisory.footprint_name:'Undefined' }}
      </v-card-title>
      <v-card-subtitle class="mx-2">
        <v-row>
          <v-chip 
            class="ma-1 px-2 white--text" 
            color="grey" 
            label
          >
            <div><v-icon>mdi-map</v-icon> {{ advisory.region_name }}</div>
          </v-chip>
          <v-chip 
            class="ma-1 px-2" 
            label
          >
            <div v-if="$vuetify.breakpoint.smAndDown"><v-icon>mdi-update</v-icon> {{ advisory.last_update }} UTC</div>
            <div v-else>Updated: {{ advisory.last_update }} UTC</div>
          </v-chip>
        </v-row>
        <MainAttributeChips v-if="advisory.advisories" :attributes="uniqueHazards(advisory.advisories.split(','))" />
      </v-card-subtitle>
      <v-row>
        <v-flex :class="$vuetify.breakpoint.mdAndDown ? 'ma-4 xs12':'ma-4 grow'">
          <v-card-text v-if="advisory.title != ''" class="text-subtitle-1">
            {{ advisory.title }}
          </v-card-text>
          <v-card-text v-if="advisory.description != ''" class="py-0 text-body-2">
            {{ advisory.description }}
          </v-card-text>
        </v-flex>
        <v-flex
          align-self-end
          class="pa-6"
          :shrink="$vuetify.breakpoint.lgAndUp"
          style="text-align: right;" 
          text
        >
          <!-- <v-card-text v-if="advisory.layer_id" class="ma-0 py-0 pr-5 teal--text text-uppercase text-caption font-italic font-weight-medium">
            <v-icon class="mr-1 pb-1" color="teal" small>mdi-shimmer</v-icon>
            Premium Features
          </v-card-text> -->
          <v-btn
            v-if="isLoggedIn"
            class="my-1 text-capitalize white--text"
            color="icetimeblue" 
            large
            rounded
            :to="'/app/radar/' + advisory.region_id + '/' + advisory.layer_id" 
          >
            <v-icon class="mr-3">mdi-map-search</v-icon> View Map
          </v-btn><br />
          <!-- <v-btn
            v-if="advisory.layer_id"
            class="my-1 text-capitalize white--text"
            color="teal" 
            large
            rounded
            :to="'/app/radar/' + advisory.region_id + '/' + advisory.footprint_unique_id"
          >
            <v-icon class="mr-3">fa-map</v-icon> View Latest Radar Imagery
          </v-btn><br v-if="advisory.layer_id" /> -->
          <v-btn 
            class="my-1 text-capitalize white--text"
            color="icetimeblue"
            large
            rounded
            :to="'/ras/history/' + advisory.footprint_id"
          >
            <v-icon class="mr-3">mdi-history</v-icon> View Advisory History
          </v-btn>
        </v-flex>
      </v-row>
    </span>
    </v-card>
  </v-fade-transition>
</template>

<script>
import MainAttributeChips from './MainAttributeChips'
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    MainAttributeChips,
  },
  computed: {
    ...mapGetters('user', {
      isLoggedIn: 'isLoggedIn',
    }),    
    ...mapState('ras', {
      entry: state => state.entry
    })
  },
  data() {
    return {
      attributes: [
        'attribute 1',
        'attribute 2',
        'attribute 3',
      ],
      show: true,
    }
  },
  methods: {
    uniqueHazards(hazardList) {
      return hazardList.filter(function(item, pos, self) { return self.indexOf(item) == pos; })
    }
  },
  props: [
    'advisory',
  ],
  created() {
    // console.log(this.advisory)
  },    
  setup() {
  },
}
</script>