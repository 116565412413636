<template>
  <v-card class="ma-5 pa-4">
    <v-card-title>
      Get Started
    </v-card-title>
    <v-card-text class="text-body-1">
      Ice Time&trade; is the worlds first lake ice advisory service developed to help you thrive on the ice. Our climate is warming, and our ice continues to change. Your ice travel can’t be planned with a calendar anymore. It’s time to leverage the technology and tools of Ice Time&trade; to reduce risk, have more fun, and be apart of the modern ice angling tribe. Thrive on the ice!
    </v-card-text>
    <v-card-text>
      ( photo placeholder )
    </v-card-text>
    <v-card-subtitle class="text-subtitle-1">
      Select the category that interest you the most:
      <v-radio-group class="mx-5 text-caption" v-model="interest" @change="save_interest">
        <v-radio label="Ice Time Free" :value="1" />
        <v-radio label="Angler's Leaderboard™ Competitions" :value="2" />
        <v-radio label="Lake Ice Hazard Mapping" :value="3" />
      </v-radio-group>
    </v-card-subtitle>
  </v-card>
</template>
  
  <script>
  export default { 
    data() {
      return {
        interest: '',
      }
    },
    methods: {
      save_interest() {
        // console.log('this user is interested in ' + this.interest)
        this.$emit('set-interest', this.interest)
      }
    }
  }
  </script>
  