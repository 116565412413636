<template><div></div></template>
<script>
import L from 'leaflet'
import 'leaflet.vectorgrid'
import { mapState } from 'vuex'

export default {
  computed:{
      ...mapState('user', {
        user: state => state.user,
      }),
      ...mapState('map', {
        map: state => state.map,
      }),
      is_mobile() {
        return this.$vuetify.breakpoint.xs ? true : false
      }         
    },
  props: {
    url: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  watch: {
    url() {
      this.updateLayer()
    },
    options() {
      this.updateLayer()
    },
  },
  mounted () {
    this.mapObject = L.vectorGrid.protobuf(this.url, this.options)
    if (this.options && this.options.interactive) {
      L.DomEvent.on(this.mapObject, this.$listeners)
    }
    if (this.$parent._isMounted)  {
      this.deferredMountedTo(this.$parent.mapObject);
    }
    //change layer order here
  },
  beforeDestroy() {
    this.removeLayer()
  },
  methods: {
    calculateMeasurement(item) {
      var value = item.layer.properties.value
      var return_string = value.toFixed(2).toString() + " cm."

      if (this.user.metric != "1") {
        return_string = (value / 2.54).toFixed(2).toString() + "in."
      }
      
      return return_string
    },
    deferredMountedTo(parent) {
      var ref = this

      this.mapObject.on('click', function (e) { 
          L.popup()
          .setContent("<p><b>Ice Thickness:</b> " + ref.calculateMeasurement(e))
          .setLatLng(e.latlng)
          .openOn(parent)
        })
        .addTo(parent).bringToFront()

      this.attributionControl = parent.attributionControl;
      for (var i = 0; i < this.$children.length; i++) {
        if (typeof this.$children[i].deferredMountedTo === "function") {
          this.$children[i].deferredMountedTo(this.mapObject);
        }
      }      
    },

    setAttribution(val, old) {
      this.attributionControl.removeAttribution(old);
      this.attributionControl.addAttribution(val);
    },

    setToken(val) {
      this.options.token = val;
    },
    removeLayer() {
      this.$parent.mapObject.removeLayer(this.mapObject);
    },
    updateLayer() {
      this.removeLayer()
      this.mapObject = L.vectorGrid.protobuf(this.url, this.options)
      if (this.options && this.options.interactive) {
        L.DomEvent.on(this.mapObject, this.$listeners)
      }
      this.deferredMountedTo(this.$parent.mapObject);
    }
  }
}
</script>