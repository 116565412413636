<template>
  <v-container class="px-6 mt-8 pb-15 mb-15">
    <v-row>
      <v-col md="8" sm="12" xs="12">
        <v-row>
          <p class="text-h5 mx-3">
            <v-icon class="mr-2 mb-1">fa-map-marked-alt</v-icon>
            {{ region_name }}: {{ footprint_name }}
          </p>
        </v-row>
        <v-row>
          <HistoricalAdvisories :advisories="advisory_history" />
        </v-row>
      </v-col>
      <v-col class="my-1 mt-0" md="4" sm="12" xs="12">
        <AdCarousel />
        <!-- <v-carousel
          cycle
          height="100%"
          hide-delimiter-background
          min="200"
          :show-arrows="false"
          vertical-delimiters
        >
          <v-carousel-item
            v-for="(img, i) in img_names"
            :key="i"
            :src="'img/ff/' + img"
          />
        </v-carousel> -->
      </v-col>
    </v-row>
    <Footer />
  </v-container>
</template>

<script>
import AdCarousel from '../components/AdCarousel.vue'
import HistoricalAdvisories from '../components/HistoricalAdvisories.vue'
import Footer from './../../../components/Footer.vue'

import { mapState } from 'vuex'

export default {
  components: {
    AdCarousel,
    HistoricalAdvisories,
    Footer,
  },
  computed: {
    ...mapState('ras', {
      advisory_history: state => state.advisory_history,
      entry: state => state.entry,
      selected_region: state => state.selected_region,
    }),
    ...mapState('user', {
      primary_region: state => state.user.primary_region,
    }),
  },
  created() {
    // this.$store.dispatch('ras/getAdvisories', this.id)
    // if (this.primary_region) {
    //   this.$store.dispatch('ras/setEntry', false)
    // }
    this.randomFive().forEach(item => {
      this.img_names.push('fastfact_' + item + '.png')
    })
  },
  data() {
    return {
      img_names: [],
      footprint_name: '',
      geolocation: null,
      region_name: '',
    }
  },
  methods: {
    randomFive() {
      let five = new Set()
      let placeholder = 0
      while(five.size < 5) {
        placeholder = Math.floor(Math.random() * 25)
        if (placeholder > 0) five.add(placeholder)
      }
      return Array.from(five)
    }
  },
  mounted() {
    this.$store.dispatch('setMode', 'ras_history')
    this.$store.dispatch('ras/getRegionFootprint', this.id)
    .then((resp) => {
      this.footprint_name = resp.data.footprint_name
      this.region_name = resp.data.region_name
      // console.log(data.footprint_name)
    })
    this.$store.dispatch('ras/getAdvisoryHistory', this.id)
  },
  props: [
    'id',

  ]
}
</script>