<template>
  <v-container fluid pa-0 fill-height>
    <l-map 
      v-if="isLoggedIn" 
      v-bind:style="{cursor: cursor}"
      ref="map" 
      style="height:100%; z-index: 0!important;" 
      @locationerror="locationError"
      @locationfound="locationFound" 
      @update:center="updateCenter" 
      @update:zoom="updateZoom" 
      :center="center" 
      :options="mapOptions" 
      :maxBounds="mapBounds" 
      :minZoom="mapMinZoom"
      :maxZoom="mapMaxZoom" 
      :zoom="zoom" 
      @click="changeMarkerLocation"  
    >   
      <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <!-- <l-tile-layer v-for="layer in layers" :key="layer.id" :name="layer.title" :url="layer.tile_url" :bounds="layer.bounds" :tms="layer.tms" /> -->  
      <!-- <l-tile-layer 
        v-if="visibleLayer && visibleLayer.bounds"
        :name="visibleLayer.title"
        :url="visibleLayer.tile_url"
        :bounds="visibleLayer.bounds"
        :tms="visibleLayer.tms"
        :maxNativeZoom="visibleLayer.maxNativeZoom"
        :maxZoom="visibleLayer.maxZoom"
        :visible="isMapVisible && isZoomWithinDropInRange && !lockMapOn"
      /> -->

      <OverlayLayer 
        v-if="visibleLayer && visibleLayer.bounds && (isMapVisible && isZoomWithinDropInRange && !lockMapOn)"
        :url="visibleLayer.tile_url" 
      />

      <l-feature-group v-if="region_geojson" ref="region" @ready="rebound">
        <l-geo-json
          v-if="region_geojson"
          :geojson="region_geojson"
          :options="regionOptions"
        />
      </l-feature-group>

      <l-feature-group ref="regions_online" @ready="rebound" v-if="isLoggedIn">
        <l-feature-group ref="subscribed" @ready="rebound">
          <l-geo-json
              v-for="region in subscribed"
              :key="region.index"
              :geojson="region" 
              :options="overlayOptionsSubbed"
          />
        </l-feature-group>

        <l-feature-group ref="unsubscribed">
          <l-geo-json
            v-for="region in unsubscribed"
            :key="region.index"
            :geojson="region" 
            :options="overlayOptions"
          />
        </l-feature-group>
      </l-feature-group>

      <l-geo-json v-if="visibleLayer && visibleLayer.overlay_geojson" :visible="isIceRoadVisible && isZoomWithinDropInRange" :geojson="visibleLayer.overlay_geojson" :options="overlayOptions"></l-geo-json>

      <IceThicknessLayer 
        v-if="showThickness && thickness_layer"
        :url="thickness_layer.tile_url"   
        :options="thicknessOptions"  
      />

      <!-- Markers -->      
      <HazardMarkers 
        v-if="visibleLayer" 
        :hazards="hazards" 
        :id="current_region.radar_id ? current_region.radar_id:current_region" @pins-changed="pins_changed" />
      <APCMarkers v-if="geopost_filter_themes.includes('access_points')" :access_points="access_points" />

      <!-- Tools (right) -->      
      <!-- <FootprintSelect @footprint-changed="footprintChanged" /> -->
      <RadarImageryTool 
        v-show="!hide_buttons"
        @layer-changed="layer_changed"
        @radar-imagery-requested="radar_requested"
        @footprint-changed="footprintChanged"
        @reset-footprints="reset_footprints"
      />    
      <RegionSelect v-show="!hide_buttons" @region-changed="regionChanged" />
      <HazardMarkerTool :center="center" @pins-changed="pins_changed" />
      <APCTool 
        :marker_location="click_location"
        :mapClicked="mapClicked"
        @apc-toggled="apc_toggled"
        @apc-untoggled="apc_untoggled"
        @apc-updated="apc_updated" 
      />            
      <ImageryLegend />
      <IceThicknessTool 
        v-show="!hide_buttons && visibleLayer.id" 
        @get-thickness="getThickness"  
        @hide-thickness="hideThickness"
      />      
      <!-- Other -->
      <APCMarkerDialog 
        v-if="show_marker_dialog"
        @apc-edit-toggled="apc_edit_toggled"
        @apc-untoggled="apc_untoggled"
        @apc-updated="apc_updated" 
        @ready="testLoad()"
        />      
      <APCVotePrompt @apc-untoggled="apc_untoggled" @apc-updated="apc_updated" />      
      <GPSTool @gps-mode-changed="gpsModeChanged" :gps="gps" />
      <GeopostFilter @filter-themes-changed="filterThemesChanged" @filter-time-changed="filterTimeChanged" />
      <MapControls @update-zoom-control="updateZoomControl" :zoomControl="zoomControl" />   
      <ReportInfo v-show="visibleLayer" />
      <HelpTool />
      
      <!-- <TimeSeries @toggle-lock-map="lockMap" @pins-changed="pins_changed" :footprint_dates="footprint_dates" /> -->
      <TimeSeriesv2 
        @toggle-lock-map="lockMap" 
        @pins-changed="pins_changed" 
        :footprint_dates="footprint_dates" />        
    </l-map>

    <!-- <v-dialog v-model="dateMenuDialog" width="unset">
      <v-card class="card-outter pt-16">
        <v-divider></v-divider>
        <v-card-title>Select Imagery Date</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item v-for="item in footprint_dates" :key="item.date" @click="showDate(item.id)" :disabled="visibleLayer.id == item.id">
              <v-list-item-title>{{ item.date }}<span v-if="visibleLayer.id == item.id"> [current]</span></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="card-actions pt-4">
          <v-btn @click.prevent="dateMenuDialog = false" text class="grey--text">
            Close
            <v-icon right color="grey">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="zoomControlDialog" scrollable max-width="500px" width="unset">
      <v-card class="card-outter pt-16 pb-0" style="overflow-y: auto;">
        <v-divider></v-divider>
        <v-card-title class="pa-7">
          You are attempting to access a resolution that is not supported by Ice Time. Do you wish to continue?
        </v-card-title>
        <v-card-text>
          You can change the Zoom Control in the <v-icon color="hazardMarkerColor">mdi-layers-triple-outline</v-icon> Layer Controls options.
        </v-card-text>
        <v-card-actions class="card-actions pt-4">
          <v-btn @click.prevent="deactivateZoomControl" text class="grey--text">
            Continue
          </v-btn>
          <v-btn @click.prevent="activateZoomControl" text class="grey--text">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { icon, latLng, latLngBounds } from 'leaflet';
import { LFeatureGroup, LMap, LTileLayer, LGeoJson } from 'vue2-leaflet'
// import LProtobuf from 'vue2-leaflet-vectorgrid'

import RegionSelect from '../components/RegionSelect.vue'
// import FootprintSelect from '../components/FootprintSelect.vue'
import GPSTool from '../components/GPSTool.vue'
import GeopostFilter from '../../spot_mapper/components/GeopostFilter.vue'
import APCMarkers from '../../access_points/components/Markers.vue'
import HazardMarkers from '../components/HazardMarkers.vue'
import APCTool from '../../access_points/components/AddMarker.vue'
import HazardMarkerTool from '../components/HazardMarkerTool.vue'
import MapControls from '../components/MapControls.vue'
import HelpTool from '../components/HelpTool.vue';
import ReportInfo from '../components/ReportInfo.vue'
import TimeSeriesv2 from '../components/TimeSeriesv2.vue'

import APCMarkerDialog from '../../access_points/components/MarkerDialog.vue'
import APCVotePrompt from '../../access_points/components/VotePrompt.vue'

import RadarImageryTool from '../components/RadarImageryTool.vue'
import IceThicknessTool from '../components/IceThicknessTool.vue'
import IceThicknessLayer from '../components/IceThicknessLayer.vue'
import OverlayLayer from '../components/OverlayLayer.vue'

import ImageryLegend from '../components/ImageryLegend.vue'

export default {
  props: ['id', 'layer'],
  data() {
    return {
      regions_geojson: [],
      center: latLng(50.7, -96.6), // hardcoded center
      click_location: null,
      dateMenuDialog: false,
      // delete_loading: false,
      filter_all: [ 
        // "fishing_spots", 
        "access_points"],
      hazards_filtered: [],
      hazards_pois: [],
      footprint_dates: [],
      // footprints_hint: '',
      gps: null,
      hazardMarkerColor: 'icetimeblue',
      // lockMapLayer: '',
      map: null,
      mapBounds: null,
      mapClicked: false,
      mapMinZoom: null,
      mapMaxZoom: null,
      mapOptions: {    
        preferCanvas: true,
        wheelPxPerZoomLevel: 120,
        zoomSnap: 0.1,
      },
      overlayOptionsSubbed: {
        style: {
          color: '#4CAF50',
          fillColor: '#00C853',
          fillOpacity: 0.3
        },
        onEachFeature: function onEachFeature(feature, layer) {       
          var text = "<p><b>Footprint Name:</b> " + feature.properties.name
          text += "<br><b>Newest Image Date:</b> " + feature.properties.date
          text += "<br><b>Total Images:</b> " + feature.properties.total_images
          text += "</p>"
          // text += "<a href='/app/radar/" + feature.properties.region + "/" + feature.properties.footprint_id + "'>View Image</a>"
          layer.bindPopup(text)
        }        
      },
      overlayOptions: {
        style: {
          color: '#9E9E9E',
          fillColor: '#9E9E9E',
          fillOpacity: 0.3,
          opacity: 1
        },
        onEachFeature: function onEachFeature(feature, layer) {
          // layer.bindPopup("You are not subscribed to the <i>" + feature.properties.region_name + "</i> region. <a href='/purchase'>Click here to subscribe</a>.")
          layer.bindPopup("You are not subscribed to the <i>" + feature.properties.region_name + "</i> region.<br><a href=''>Click here to subscribe (Coming Soon)</a>.")        
        }
      },
      region_geojson: '',
      regionOptions: {
        style: {
          interactive: true,
          color: '#2f4782',
          fillOpacity: 0,
          opacity: 1
        },
      },      
      // report: [],
      showLoading: true,
      // timeSeriesLayerIds: [],   
      timeSeriesLoaded: 0,
      zoomControlDialog: false,
      initial_prop_load: false,
      itemsWithMeasurement: ['1', '2'],
      view_poly: null,
      viewable_layers: [],
      thicknessOptions: {   
        // maxZoom: 10,
        interactive: true,  
        pane: 'thickness',
        vectorTileLayerStyles: {
          // A plain set of L.Path options.
          icethickness: {
              weight: 0,
              fillColor: '#ffff00',
              fillOpacity: 0,
              fill: true,
              // opacity: 0,
          },
        },        
      },  
    }
  },
  computed: {
    ...mapGetters('user', {
      isAdmin: 'isAdmin',
      isLoggedIn: 'isLoggedIn',
      isStaffUser: 'isStaffUser'
    }),
    ...mapState('access_points', {
      access_points: state => state.access_points,
      geofence_access_points: state => state.geofence_access_points,
      geofence_record: state => state.geofence_record,
      show_marker_dialog: state => state.show_marker_dialog,
      selectedCursor: state => state.selectedCursor,
      pointAddMode: state => state.pointAddMode,
      geopost_filter_themes: state => state.geopost_filter_themes,         
    }),    
    ...mapState('user', {
      user: state => state.user
    }),
    ...mapState('map', {
      current_footprint: state => state.current_footprint,
      current_region: state => state.current_region,
      dates: state => state.dates,
      footprints: state => state.footprints,
      gpsMode: state => state.gpsMode,
      hazards: state => state.hazards,
      isMapVisible: state => state.isMapVisible,
      layers: state => state.layers,
      legend: state => state.legend,
      lockMapOn: state => state.lockMapOn,
      range_labels: state => state.range_labels,
      range_value: state => state.range_value,
      user_reports: state => state.user_reports,
      visibleLayer: state => state.visibleLayer,
      zoom: state => state.zoom,
      zoomControl: state => state.zoomControl,
      clicked: state => state.radar_imagery_clicked,
      hide_buttons: state => state.hide_buttons,
      showThickness: state => state.thickness,
      thickness_layer: state => state.thicknessLayer,
      iceClassOptions: state => state.classColours,
    }),     
    isZoomWithinDropInRange() {
      let returnVal = this.zoom > this.range_labels[this.range_value[0]] && this.zoom < this.range_labels[this.range_value[1]]
      return returnVal
    },
    subscribed() {      
      if (this.isStaffUser) {
        return this.regions_geojson
      } else {
        return this.regions_geojson.filter(region => {
          return this.subscriptions_ids.includes(region.features[0].properties.region)
        })
      }
    },
    unsubscribed() {
      if (this.isStaffUser) {
        return []
      } else {
        return this.regions_geojson.filter(region => {
          return !this.subscriptions_ids.includes(region.features[0].properties.region)
        })
      }
    }    
  },
  components: {
      // FootprintSelect,
      GPSTool,
      GeopostFilter,
      APCTool,
      APCMarkerDialog,
      APCMarkers,
      APCVotePrompt,
      HazardMarkers,
      HazardMarkerTool,
      LFeatureGroup,
      LMap,
      LTileLayer,
      LGeoJson,
      MapControls,
      RegionSelect,
      ReportInfo,
      HelpTool,
      TimeSeriesv2,
      RadarImageryTool,
      ImageryLegend,
      IceThicknessTool,
      IceThicknessLayer,
      OverlayLayer,
      // LProtobuf
  },
  mounted () {    
    if (this.isLoggedIn) {
      if(this.lockMapOn) {
        this.$store.dispatch('map/toggleMapLock')
      }    
      this.$store.dispatch('map/setVisibleLayer', {})
      this.$store.dispatch('map/setLayers', [])
      this.$store.dispatch('map/setHideButtons', false)
      this.$store.dispatch('setTab', 'radar')
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.map = this.$refs.map.mapObject // work as expected
          this.$store.dispatch('map/setMap', this.map)
          this.$refs.map.mapObject.createPane('thickness')
          this.$refs.map.mapObject.createPane('iceclass')
          this.$refs.map.mapObject.getPane('thickness').style.zIndex = 650
          this.$store.dispatch('setMode', 'app')          
        })
      }) 
    }   
  },
  created () {    
    if (this.isLoggedIn) {

      this.showLoading = true
      this.initial_prop_load = false

      // Geopost setup
      this.$store.pointAddMode = 'none'
      this.$store.selectedCursor = 'default'
      this.cursor = this.selectedCursor
      this.$store.clickedLocation = this.center
      this.$store.dispatch('access_points/setGeopostFilterThemes', this.filter_all)
      this.update_geoposts()

      var id = this.id ? this.id:this.user.primary_region    
      this.$store.dispatch('map/setCurrentRegion', id)

      this.$store.dispatch('map/getFootprints', {id})
      .then(() => {
        this.$store.dispatch('map/getLayers', {id})
        .then(() => {
          if (this.id && this.layer) {
            var vl = this.layers.find(layer => layer.id === this.layer)    
            var image_type = vl.type
            this.$store.dispatch('map/getLegend', {image_type})         
            this.$store.dispatch('map/setCurrentFootprint', vl)
            this.footprintChanged()
            this.pins_changed()
            
          }
          else {
            this.regionChanged()
          }        
        })
      })

      // Get report information for regions
      this.$store.dispatch('map/getUserReports')
      .catch((err) => {
        if(err.response.status == '403') {
          this.$store.dispatch('logout')
          this.$store.dispatch('setDialog', { 
            show: true, 
            message: err.response.data, 
            status: err.response.status })
        }
      })  
    }
  },
  methods: {
    testLoad() {
      // console.log('Test load')
    },
    activateZoomControl() {
      this.$store.dispatch('map/setZoom', 14.9)
      this.mapMaxZoom = 14.9
      this.$store.dispatch('map/setZoomControl', true)
      this.zoomControlDialog = false
    },
    apc_edit_toggled(gps) {
      this.$store.dispatch('access_points/setAccessPointNewLocation', gps)
      this.$refs.map.mapObject.flyTo(gps, 18, {
        animate: false,
      })
      // map.flyTo([lat, lng], zoom);
      // this.zoom = 18
      // this.$store.dispatch('map/setZoom', 14.9)
      // this.mapMaxZoom = 14.9
      // this.$store.dispatch('map/setZoomControl', true)
    },    
    apc_toggled() {
      // this.$store.pointAddMode = 'apc'
      this.$store.dispatch ('access_points/setPointAddMode', 'apc')
      this.zoom_placeholder = this.zoom
      // console.log(`[toggled] ${this.zoom_placeholder}`)
      let snackbar = {
        showing: true,
        text: 'Click anywhere on the map to place an Access Point marker.',
        // link: ''
      }
      this.$store.dispatch('setSnackbar', snackbar)
    },    
    apc_untoggled() {
      // this.$store.pointAddMode = 'none'
      this.$store.dispatch ('access_points/setPointAddMode', 'none')
      // this.zoom = 10
      // console.log(`[untoggled] ${this.zoom_placeholder}`)
      this.$store.dispatch('map/setZoom', this.zoom_placeholder)
      this.$store.dispatch('setSnackbar', {})
      // this.$store.dispatch('map/setZoom', 15)
      // this.$store.dispatch('map/setZoomControl', false)
      // this.zoomControlDialog = false
    },
    // apc_selected(lat, lng) {
    //   this.$refs.map.mapObject.panTo(latLng(lat,lng))
    // },
    apc_updated() {
      this.$store.dispatch('access_points/getAccessPoints')
      // console.log('apc updated!')
    },    
    bringToFront(e, layer) {
      // console.log("Comparing '" + e.target.key + "' with '" + this.visibleLayer.id + "' ...")
      // console.log(layer.id)
      if (layer.id != this.visibleLayer.id) {
        e.target.bringToBack()
        // e.target.visible = true
        // e.target.bringToFront()
      } //else {
      //   e.target.bringToBack()
      // }

      // Increase timeSeriesLoaded value (part of the "loading" logic)
      this.timeSeriesLoaded += 1
    },
    changeMarkerLocation(event) {
      this.$store.dispatch('setSnackbar', {})
      // console.log(this.$store.pointAddMode)
      if(this.pointAddMode !== 'none') {
        this.mapClicked = true
        this.click_location = latLng(event.latlng.lat, event.latlng.lng)
        this.$refs.map.mapObject.flyTo(this.click_location, 12, {animate: true, duration: 1})
        this.$store.clickLocation = this.click_location
      }
      // console.log(this.mapClicked)
    },    
    deactivateZoomControl() {
      this.$store.dispatch('map/setZoom', 15)
      this.$store.dispatch('map/setZoomControl', false)
      this.zoomControlDialog = false
    },
    // deleteHazard(pin) {
    //   this.delete_loading = true
    //   this.$store.dispatch('deleteHazard', pin.id)
    //   .then(() => {
    //     var target_url = '/app/radar/' + this.visibleLayer.map_id + "/" + this.visibleLayer.id
    //     this.$router.push(target_url)
    //     this.$router.go()
    //   })
    //   .catch(() => {
    //     this.delete_loading = false
    //   })
    // },
    filterTimeChanged() {
      // console.log('updating geoposts')
      this.update_geoposts()
    },
    filterThemesChanged() {
      this.update_geoposts()
    },    
    footprintChanged() { 
      // console.log(this.dates)
      // Must set footprint_dates everytime footprint changes

      // Not setting current footprint to correct value
      // var the_list = this.dates.filter((el) => 
      //   el.relative_orbit == this.current_footprint.relative_orbit && el.latitude == this.current_footprint.latitude
      // )     
      // console.log(the_list)
      this.regions_geojson = []
      this.$store.dispatch('map/setThicknessLayer', null)
      if(this.visibleLayer && this.visibleLayer.bounds) {           
        this.showFootprint(this.current_footprint)

      } else if (this.current_footprint.center_lat && this.current_footprint.center_lng){    
        this.$refs.map.mapObject.panTo(latLng(this.current_footprint.center_lat,this.current_footprint.center_lng))
        // this.center = latLng(this.current_footprint.center_lat, this.current_footprint.center_lng)
      }      
    },
    // getMaxZoom(json) {
    //   if(json == '' || json == null) return
    //   var info = JSON.parse(json)
    //   var max = 1
    //   if(!info.TileSets || !info.TileSets.TileSet) return 0
    //   info.TileSets.TileSet.forEach(function(zoom) {
    //     if(parseInt(zoom['@attributes'].href) > max) max = parseInt(zoom['@attributes'].href)
    //     // if(parseInt(zoom['@attributes'].href) < min) min = parseInt(zoom['@attributes'].href)
    //   })
    //   return max
    // },
    getLayers(id) {
      this.$store.dispatch('map/getLayers', {id})
      .then(()=> {
        // var fp = null
        if(this.layers.length > 0) {

          if(this.id && this.layer && this.initial_prop_load) {
            // Determine the visible layer and current footprint
            var vl = null
            // var cf = null
            if (this.layer) { // check for layer id prop)
                vl = this.layers.find(layer => {
                  return layer.id == this.layer
                })
              // }
            } else if (this.dates.length > 1) {
              this.dates.sort()
              vl = this.layers.find(layer => {
                return layer.id == this.dates[0].id
              })
            } else {
              vl = this.layers[0]
            }
            this.$refs.map.fitBounds(vl.bounds)            
            this.$store.dispatch('map/setVisibleLayer', vl)         
          }

          var newest_layers = this.layers.reduce((a, fp) => {
          if(!(fp.footprint_id in a)) {
            a[fp.footprint_id] = fp;
          } else {
            a[fp.footprint_id] = Date.parse(fp.date) > Date.parse(a[fp.footprint_id].date) ? fp : a[fp.footprint_id]
          }
          return a;
          }, {})         
          
          for (let layer in newest_layers) {
            if (newest_layers[layer].geojson) {
              // console.log('Pushing: ' + layer.id)
              var count = this.layers.filter((obj) => obj.footprint_id === layer).length
              var image_geojson = JSON.parse(newest_layers[layer].geojson)   
              image_geojson.features[0]["properties"]["region"] = newest_layers[layer].map_id
              image_geojson.features[0]["properties"]["footprint_id"] = newest_layers[layer].footprint_id
              image_geojson.features[0]["properties"]["date"] = newest_layers[layer].date     
              image_geojson.features[0]["properties"]["name"] = newest_layers[layer].name    
              image_geojson.features[0]["properties"]["region_name"] = newest_layers[layer].region_name          
              image_geojson.features[0]["properties"]["total_images"] = count         
              this.regions_geojson.push(image_geojson)
            }            
          }  
          // console.log(this.regions_geojson)
        }
      })      
    },    

    gpsModeChanged() {
      if(this.gpsMode == 1) {
        this.mapBounds = null
        this.$refs.map.mapObject.locate({setView: false, watch: true, enableHighAccuracy: true})
      } else if(this.gpsMode == 2) {
        this.mapBounds = latLngBounds([this.gps, this.gps])
        this.$refs.map.mapObject.locate({setView: true, watch: true, enableHighAccuracy: true})
      } else if(this.gpsMode == 0) {
        this.mapBounds = null
        this.$refs.map.mapObject.locate({setView: false, watch: false, enableHighAccuracy: false})
      }
      // console.log("mode is " + this.gpsMode)
    },
    layer_changed() {
      // this.$store.dispatch('map/setRadarImageryClicked', false)
    },    
    locationError() {
      this.gps = this.center
    },
    locationFound(location) {
      this.gps = location.latlng
    },
    lockMap() {
      if(this.lockMapOn) {
        // Lock screen
        this.$store.dispatch('map/setZoom', this.zoom)
        this.mapMaxZoom = this.zoom
        this.mapMinZoom = this.zoom
        this.$refs.map.mapObject.dragging.disable()
        this.$refs.map.mapObject.doubleClickZoom.disable();
      } else if (!this.lockMapOn) {
        this.mapMinZoom = 0
        this.mapMaxZoom = 18
        this.$refs.map.mapObject.dragging.enable()
        this.$refs.map.mapObject.doubleClickZoom.enable();
        this.viewable_layers = []
      }
    },
    pins_changed() {
      let id = this.current_region.radar_id ? this.current_region.radar_id:this.current_region
      this.$store.dispatch('map/getHazards', {id})
      .then(() => {
        if (this.hazards) {
          // filter out Point of Interest Pins
          let poi_ids = ['19', '20', '21', '22', '23']
          this.hazards_pois = this.hazards.filter(function(el) {
            return poi_ids.includes(el.hazardtype_id)
          })
          this.hazards_pois.sort(function(a, b) {
            return parseInt(a.admin_order) - parseInt(b.admin_order)
          })

          // filter out non-POI pins
          this.hazards_filtered = this.hazards.filter(function(el) {
            return !(poi_ids.includes(el.hazardtype_id))
          })

          // build user pin groups
          let pAdmin = []
          let pPersonal = []
          let pPOI = []
          let pPro = []
          let pUser = []

          // Create pin for each hazard
          if (this.hazards_filtered) {
            this.hazards_filtered.forEach((hazard) => {

              // Build iconUrl string
              var hazard_user_string = '';
              if (hazard.usertype_id == 1) {
                hazard_user_string = "user"  
              } else if (hazard.usertype_id == 2) {
                hazard_user_string = "pro"
              } else if (hazard.usertype_id == 9) {
                hazard_user_string = "admin"
              }
              var hazard_iconUrl = "/img/hazard_icons/" + hazard_user_string + "_" + hazard.hazardtype_icon

              // Build pop-up text
              var popup_text = hazard.hazardtype_name
              if(hazard.value) {
                if(this.itemsWithMeasurement.includes(hazard.hazardtype_id)) {
                  let x = parseFloat(hazard.value)
                  let y = this.user.metric == "1" ? (parseFloat(x.toFixed(2)) + " m"):(parseFloat((x * 39.3701).toFixed(2)) + " in")
                  popup_text += ": " + y
                } else {
                  popup_text += ": " + hazard.value
                }
              }
              // Determine opacity of of icon
              let hazard_opacity = 1.0
              // Comment out following if block for testing
              if(hazard.is_permanent == 0) {
                var current = new Date()
                var day = 24 * 60 * 60 * 1000;
                var hazard_date =  new Date(hazard.expiration + 'T00:00')
                var diff = Math.round((hazard_date.getTime() - current.getTime())/day)
                hazard_opacity = hazard.usertype_id == 9 || diff > 0 ? parseFloat((((diff + 7) / 10.0)).toFixed(2)):0
              }
              var placeholder = {
                alias: hazard.alias,
                date: hazard.date,
                lat: hazard.lat,
                lng: hazard.lng,
                opacity: hazard_opacity,
                icon: new icon ({
                  iconUrl: hazard_iconUrl,
                  iconSize: [64,64],
                  iconAnchor: [32,64]
                }),
                popup: popup_text,
                id: hazard.id,
                is_permanent: hazard.is_permanent,
                is_public: hazard.is_public,
                layer_id: hazard.layer_id,
                user_type: hazard.usertype_id,
                user_id: hazard.user_id,
                hazard_name: hazard.hazardtype_name,
              }
              if (hazard_opacity > 0) {
                if (hazard.user_id == this.user.id) {
                  // this.personalPins.push(placeholder)
                  pPersonal.push(placeholder)
                } else if (hazard.usertype_id == 1) {
                  // this.userPins.push(placeholder)
                  pUser.push(placeholder)
                } else if (hazard.usertype_id == 2) {
                  // this.proPins.push(placeholder)
                  pPro.push(placeholder)
                } else if (hazard.usertype_id == 9) {
                  // this.adminPins.push(placeholder)
                  pAdmin.push(placeholder)
                }
              }
            })
          }
          
          // Create pin for each POI hazard
          if (this.hazards_pois) {
            var icon_index = ''
            this.hazards_pois.forEach((hazard) => {
              switch(hazard.hazardtype_id) {
                case '19':
                  icon_index = 0
                  break
                case '20':
                  icon_index = 1
                  break
                case '21':
                  icon_index = 2
                  break
                case '22':
                  icon_index = 3
                  break
                case '23':
                  icon_index = 4
                  break
              }

              var placeholder = {
                alias: hazard.alias,
                date: hazard.date,
                layer_id: hazard.layer_id,
                lat: hazard.lat,
                lng: hazard.lng,
                icon_index: icon_index,
                id: hazard.id,
                user_type: hazard.usertype_id,
                user_id: hazard.user_id
              }
              
              pPOI.push(placeholder)
            })
            // console.log(this.poiPins)
          }

          // console.log(pAdmin)
          // console.log(pPersonal)
          // console.log(pPOI)
          // console.log(pPro)
          // console.log(pUser)

          this.$store.dispatch('map/setPinsAdmin', pAdmin)
          this.$store.dispatch('map/setPinsPersonal', pPersonal)
          this.$store.dispatch('map/setPinsPOI', pPOI)
          this.$store.dispatch('map/setPinsPro', pPro)
          this.$store.dispatch('map/setPinsUser', pUser)
        }
      })
    },
    radar_requested() {
      // let latlngbounds = this.$refs.map.mapObject.getBounds()
      // console.log(latlngbounds.getSouthWest())
      // console.log(latlngbounds.getSouthEast())
      // console.log(latlngbounds.getNorthEast())
      // console.log(latlngbounds.getNorthWest())
      // this.getLayers(bounds_str)      

      if (!this.region_geojson) {        
        var radar_id = this.current_region && this.current_region.radar_id ? this.current_region.radar_id:this.current_region
        var str = ''
        this.$store.dispatch('getRegionGeoJSON', {radar_id})
        .then(resp => {
          str = resp.data.geojson
          str = JSON.parse(str)
        })
        .then(() => {
          this.region_geojson = str        
        })   
      }
      this.reset_footprints()
      this.$store.dispatch('map/setRadarImageryClicked', true)
      this.getLayers(this.current_region.radar_id ? this.current_region.radar_id:this.current_region)  
    },    
    rebound() {
      if (this.region_geojson) {
        this.$refs.map.fitBounds(this.$refs.region.mapObject.getBounds())
      }
      
    },    
    regionChanged() {     
      var radar_id = this.current_region && this.current_region.radar_id ? this.current_region.radar_id:this.current_region
      var str = ''    
      this.regions_geojson = []
      this.$store.dispatch('map/getHazards', {id: 0})        
      this.$store.dispatch('getRegionGeoJSON', {radar_id})
      .then(resp => {
        str = resp.data.geojson
        str = JSON.parse(str)
      })
      .then(() => {
        this.region_geojson = str
      })
      .then(() => {
        this.rebound()
      })
      .then(() => {        
        this.pins_changed()
        // If id and layer are specified (ie. admin dashboard)        
        
        this.$store.dispatch('map/getFootprints', {id: radar_id })
        .then(() => {
          
          this.$store.dispatch('map/getLayers', {id: radar_id})
          .then(() => {            
            this.$store.dispatch('map/setCurrentFootprint', {})
            if (this.id && this.layer && this.initial_prop_load) {
              this.initial_prop_load = true
              var fp = null
              if(this.layers.length > 0) {
                //// Determine the visible layer and current footprint
                var vl = null
                var cf = null                
                if (this.layer) { // check for layer id prop)
                  if(this.layer.includes('_')) { // check for footprint_id prop
                    let split = this.layer.split('_')
                    let ro = parseInt(split[0].substring(2))
                    let lat = split[1]
                    fp = this.footprints.find(footprint => {
                      return (footprint.relative_orbit == ro && footprint.latitude == lat.latitude)
                    })
                  } else {
                    vl = this.layers.find(layer => {
                      return layer.id == this.layer
                    })
                  }
                } else if (this.dates.length > 1) {
                  this.dates.sort()
                  vl = this.layers.find(layer => {
                    return layer.id == this.dates[0].id
                  })
                } else {
                  vl = this.layers[0]
                }   
                
                cf = fp ? fp:this.footprints[0]
                this.$store.dispatch('map/setCurrentFootprint', cf)
                this.$store.dispatch('map/setVisibleLayer', vl)                  
                if (this.visibleLayer) {
                  // console.log('Visible Layer exists!')
                  this.center = this.visibleLayer.center
                  this.bounds = this.visibleLayer.bounds

                  // console.log('Visible layer RO: ' + this.visibleLayer.relative_orbit)
                  // console.log('Visible layer Lat: ' + this.visibleLayer.latitude)
                  
                  cf = this.footprints.find(footprint => {
                    // console.log('Comparing to RO: ' + footprint.relative_orbit + ', Lat: ' + footprint.latitude)
                    return (footprint.relative_orbit == this.visibleLayer.relative_orbit && footprint.latitude == this.visibleLayer.latitude)
                  })

                  this.$store.dispatch('map/setCurrentFootprint', cf) // problem statmeent
                }

                // Set the dates so that it only shows the current footprint. 
                // Note: not longer relevant, but times series logic relies on footprint_dates variable
                if (this.current_footprint) {
                  // this.showFootprint(cf, this.visibleLayer.id)
                  this.showFootprint(cf, vl.id)            
                } else {
                  this.footprint_dates = this.dates
                }
              }

              else {                
                if(this.layer && this.layer.includes('_')) { // check for footprint_id prop
                  let split = this.layer.split('_')
                  let ro = parseInt(split[0].substring(2))
                  let lat = split[1]
                  fp = this.footprints.find(footprint => {
                    return (footprint.relative_orbit == ro && footprint.latitude == lat.latitude)
                  })
                }
                // this.current_footprint = fp ? fp:this.footprints[0]
                // let cf = fp ? fp:null
                let cf = fp ? fp:this.footprints[0]
                this.$store.dispatch('map/setCurrentFootprint', cf)
                if (cf) this.showFootprint(cf, vl.id)
              }
            }

          })
        })


        // .then(() => {
        //   this.getLayers(radar_id)
        // })
        .catch()
        // .finally(() => {
        //   this.$refs.map.fitBounds(this.visibleLayer.bounds)
        // })
      })
      .finally(() => {
        this.$store.dispatch('map/setVisibleLayer', {})
        this.$store.dispatch('map/setRadarImageryClicked', false)
        this.$store.dispatch('map/setLayers', [])
        this.$store.dispatch('map/setThickness', false)
        this.$store.dispatch('map/setThicknessLayer', null)
        this.footprint_dates = []
      })
    },    
    reset_footprints() {
      this.regions_geojson = []      
      this.$store.dispatch('map/setLayers', [])
      this.$store.dispatch('map/setVisibleLayer', {})
      this.$store.dispatch('map/setThickness', false)
      this.$store.dispatch('map/setThicknessLayer', null)
      this.$store.dispatch('map/setDates', [])
      this.footprint_dates = []
      this.rebound()
    },
    showDate(dateItem) {
      // console.log(dateItem)
      this.dateMenuDialog = false
      // console.log(this.$refs[dateItem])
      // console.log(this.$refs.map)
      if(this.lockMapOn) this.$refs[dateItem].mapObject.bringToFront()
      let vl = null
      // this.visibleLayer = this.layers.find(layer => {
      vl = this.layers.find(layer => {
        return layer.id == dateItem
      })
      // console.log(vl)
      this.$store.dispatch('map/setVisibleLayer', vl)
    },
    showFootprint(footprintItem) {
      var footprint_list = this.dates.filter(function(el) {
        return el.relative_orbit == footprintItem.relative_orbit && el.latitude == footprintItem.latitude
      })      

      if (this.viewable_layers.length == 0) {
        this.$refs.map.fitBounds(this.visibleLayer.bounds)
      }
      

      if(footprint_list.length > 0) {
        this.footprint_dates = footprint_list

      //   if(layer_id) {
      //     this.showDate(layer_id)
      //   } else {
      //     this.showDate(this.footprint_dates[0].id)
      //   }        
      //   this.$refs.map.fitBounds(this.visibleLayer.bounds)
      } 
      
      // else {      
      //   if(footprintItem.center_lat == null) {         
      //     this.$refs.map.fitBounds(this.visibleLayer.bounds)
      //   } else {
      //     console.log('here!')
      //     this.$refs.map.mapObject.panTo(latLng(footprintItem.center_lat, footprintItem.center_lng))
      //   }        
      // }
      this.$store.dispatch('map/setThickness', false)
    },
    getThickness() {
      if(this.thickness_layer != null) {
        this.$store.dispatch('map/setThickness', true)
      }
      else {
        this.$store.dispatch('map/getThicknessLayer', {id: this.visibleLayer.id})
      }      
    },
    hideThickness(){
      this.$store.dispatch('map/setThickness', false)
    },
    updateCenter(center) {
      this.center = center
    },
    update_geoposts() {
      // if (this.geopost_filter_themes.includes("fishing_spots")) {
      //   this.$store.dispatch('spot_mapper/getFishingSpots')
      // }      
      if (this.geopost_filter_themes.includes("access_points")) {        
        // throwing error: [vuex] module namespace not found in mapState(): /
        this.$store.dispatch('access_points/getAccessPoints')
      }
    },    
    updateZoom(zoom) {
      if (this.visibleLayer && zoom > 14.9 ) {
        if (this.zoomControl == null) {
          this.zoomControlDialog = true
        }
      } else {
        this.$store.dispatch('map/setZoom', zoom)
      }
    },
    updateZoomControl() {
      if (!this.zoomControl) {
        this.mapMaxZoom = 20
      } else {
        this.mapMaxZoom = 14.9
      }
    },
  },
}
</script>

<style scoped>
>>>.example-custom-control {
  background: #fff;
  padding: 0 0.5em;
  border: 1px solid #aaa;
  border-radius: 0.1em;
}
>>>.leaflet-bottom {
  bottom: 0;
  left: 50%;
  transform: translate( -50%, 0%);
}

>>>.custom-control {
  background: #fff;
  padding: 5px;
  border: 1px solid #aaa;
  border-radius: 5px;
}

>>>.custom-control-2 {
  background-color: #fff;
  padding: 10px;
  border: 0;
  border-radius: 10px;
  opacity: 0.8;
  font-weight: bolder;
  font-size: large;
  bottom: 0;
  left: 50%;
  transform: translate( -50%, 0%);
}

>>>.custom-control-2:hover {
  transition-duration: 200ms;
  opacity: 1;
}

>>>.v-dialog {
  position: absolute;
  bottom: 0;
  right: 0;
}

>>>.card-outter {
  position: relative;
  padding-bottom: 1.5em;
}

>>>.card-actions {
  position: absolute;
  top: 0;
  right:0;
}

>>>div.v-messages:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
  color: red;
}
</style>