<template>
    <v-container>
        <h1 class="text-center">How To</h1>
        <p class="mb-10">
            <v-img
                comtain
                src="/img/SpotMapper_Howto-1.png"
            ></v-img>
        </p>
        <p class="mb-10">
            <v-img
                comtain
                src="/img/SpotMapper_Howto-2.png"
            ></v-img>
        </p>
        <p class="mb-10">
            <v-img
                comtain
                src="/img/SpotMapper_Howto-3.png"
            ></v-img>
        </p>
        <p class="mb-10">
            <v-img
                comtain
                src="/img/SpotMapper_Howto-4.png"
            ></v-img>
        </p>
        <p class="mb-10">
            <v-img
                comtain
                src="/img/SpotMapper_Howto-5.png"
            ></v-img>
        </p>


    </v-container>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>