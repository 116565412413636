<template>
  <v-container>
    <!-- <AdminHeader title="Reports Administration" /> -->
    <ReportsTable />
  </v-container>
</template>

<script>
// import AdminHeader from '../components/AdminHeader.vue'
import ReportsTable from '../components/ReportsTable.vue'

export default {
  components: {
    // AdminHeader,
    ReportsTable
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
  },
  mounted() {
    // this.$store.dispatch('setMode', 'admin')
    this.$store.dispatch('setTab', 'reports')
  }
}
</script>
