import firebase from "firebase/app";
import "firebase/firebase-messaging";
// import "firebase/analytics";

let messaging = null;

if (firebase.messaging.isSupported()) {
  var firebaseConfig = {
    apiKey: "AIzaSyBUVQMsRYJMd4vxxBkPZTxGCMkup7l6yow",
    authDomain: "nge-test-c9d02.firebaseapp.com",
    projectId: "nge-test-c9d02",
    storageBucket: "nge-test-c9d02.appspot.com",
    messagingSenderId: "849790133585",
    appId: "1:849790133585:web:288036b46802b42bb1df70",
    measurementId: "G-R7FF3JRX7N",
  };

  firebase.initializeApp(firebaseConfig);
  // firebase.analytics();

  messaging = firebase.messaging();
}

export default messaging;