<template>
  <l-feature-group>
    <l-feature-group :visible="isPersonalHazardVisible && isZoomWithinDropInRange">
      <l-marker v-for="pin in filteredPersonalPins" :key="pin.index" :lat-lng="[pin.lat, pin.lng]" :icon="pin.icon" :opacity="pin.opacity">
        <l-popup class="py-2">
          <span class="text-body-1">{{ pin.popup }}</span><br />          
          <span class="text-caption">Created by 
            <span v-if="pin.user_id == $store.state.user.user.id" class="text-subtitle-2 font-italic">You</span>
            <span v-else-if="pin.user_type == 9" class="text-subtitle-2">Admin</span>
            <span v-if="pin.is_public == 0" class="text-subtitle-2"> (Private)</span>
          </span>          
          <br /><span class="text-caption">Placed on {{ pin.date }} ({{ daysAgo(pin.date) }})</span>
          <br /><span class="text-caption">Lat {{ pin.lat }}</span>
          <br /><span class="text-caption">Long {{ pin.lng }}</span>
          <v-row
            v-if="isAdmin == true || pin.user_id == $store.state.user.user.id"
            class="mt-1"
          >
            <v-spacer />
            <v-btn
              class="text-caption ma-2"
              color="red"
              x-small
              dark
              @click.prevent="deleteHazard(pin)"
              :loading = "delete_loading"
            >
              Delete
            </v-btn>
          </v-row>
        </l-popup>
      </l-marker>
    </l-feature-group>

    <l-feature-group :visible="isUserHazardVisible && isZoomWithinDropInRange">
      <l-marker v-for="pin in filteredUserPins" :key="pin.index" :lat-lng="[pin.lat, pin.lng]" :icon="pin.icon" :opacity="pin.opacity">
        <l-popup class="py-2">
          <span class="text-body-1">{{ pin.popup }}</span><br />
          <span class="text-caption">Created by 
            <span v-if="pin.user_id == $store.state.user.user.id" class="text-subtitle-2 font-italic">You</span>
            <span v-else class="text-subtitle-2">{{ pin.alias }}</span>
            <span v-if="pin.is_public == 0" class="text-subtitle-2"> (Private)</span>
          </span>
          <br /><span class="text-caption">Placed on {{ pin.date }} ({{ daysAgo(pin.date) }})</span>          
          <br /><span class="text-caption">Lat {{ pin.lat }}</span>
          <br /><span class="text-caption">Long {{ pin.lng }}</span>

          <v-row
            v-if="isAdmin == true || pin.user_id == $store.state.user.user.id"
            class="mt-1"
          >
            <v-spacer />
            <v-btn
              class="text-caption ma-2"
              color="red"
              x-small
              dark
              @click.prevent="deleteHazard(pin)"
              :loading = "delete_loading"
            >
              Delete
            </v-btn>
          </v-row>
        </l-popup>
      </l-marker>
    </l-feature-group>

    <l-feature-group :visible="isProHazardVisible && isZoomWithinDropInRange">
      <l-marker v-for="pin in filteredProPins" :key="pin.index" :lat-lng="[pin.lat, pin.lng]" :icon="pin.icon" :opacity="pin.opacity">
        <l-popup class="py-2">
          <span class="text-body-1">{{ pin.popup }}</span><br />
          <span class="text-caption">Created by
            <span v-if="pin.user_id == $store.state.user.user.id" class="text-subtitle-2 font-italic">You</span>
            <span v-else class="text-subtitle-2">{{ pin.alias }}</span>
            <span v-if="pin.is_public == 0" class="text-subtitle-2"> (Private)</span>
          </span>
          <br /><span class="text-caption">Placed on {{ pin.date }} ({{ daysAgo(pin.date) }})</span>
          <br /><span class="text-caption">Lat {{ pin.lat }}</span>
          <br /><span class="text-caption">Long {{ pin.lng }}</span>

          <v-row
            v-if="isAdmin == true || pin.user_id == $store.state.user.user.id"
            class="mt-1"
          >
            <v-spacer />
            <v-btn
              class="text-caption ma-2"
              color="red"
              x-small
              dark
              @click.prevent="deleteHazard(pin)"
              :loading = "delete_loading"
            >
              Delete
            </v-btn>
          </v-row>
        </l-popup>
      </l-marker>
    </l-feature-group>

    <l-feature-group :visible="isAdminHazardVisible">
      <l-marker v-for="pin in filteredAdminPins" :key="pin.index" :lat-lng="[pin.lat, pin.lng]" :icon="pin.icon" :opacity="pin.opacity">
        <l-popup class="py-2">
          <span class="text-body-1">{{ pin.popup }}</span><br />
          <span class="text-caption">Created by 
            <span v-if="pin.user_id == $store.state.user.user.id" class="text-subtitle-2 font-italic">You</span>
            <span v-else class="text-subtitle-2">Admin</span>
            <span v-if="pin.is_public == 0" class="text-subtitle-2"> (Private)</span>
          </span>
          <br /><span class="text-caption">Placed on {{ pin.date }} ({{ daysAgo(pin.date) }})</span>
          <br /><span class="text-caption">Lat: {{ pin.lat }}</span>
          <br /><span class="text-caption">Long: {{ pin.lng }}</span>

          <v-row
            v-if="isAdmin == true || pin.user_id == $store.state.user.user.id"
            class="mt-1"
          >
            <v-spacer />
            <v-btn
              class="text-caption ma-2"
              color="red"
              x-small
              dark
              @click.prevent="deleteHazard(pin)"
              :loading = "delete_loading"
            >
              Delete
            </v-btn>
          </v-row>
        </l-popup>
      </l-marker>

      <l-feature-group :visible="isAdminHazardVisible">
        <l-marker v-for="pin in filteredPOIPins" :key="pin.index" :lat-lng="[pin.lat, pin.lng]" :icon="pois_icons[pin.icon_index]">
          <l-popup class="py-2">
            <span class="text-caption">Created by 
              <span v-if="pin.user_id == $store.state.user.user.id" class="text-subtitle-2 font-italic">You</span>
              <span v-else class="text-subtitle-2">Admin</span>
              <span v-if="pin.is_public == 0" class="text-subtitle-2"> (Private)</span>
            </span>
            <br /><span class="text-caption">Placed on {{ pin.date }} ({{ daysAgo(pin.date) }})</span>
            <br /><span class="text-caption">Lat {{ pin.lat }}</span>
            <br /><span class="text-caption">Long {{ pin.lng }}</span>
            
            <v-row
              v-if="isAdmin == true"
              class="mt-1"
            >
              <v-spacer />
              <v-btn
                class="text-caption ma-2"
                color="red"
                x-small
                dark
                @click.prevent="deleteHazard(pin)"
                :loading = "delete_loading"
              >
                Delete
              </v-btn>
            </v-row>
          </l-popup>
        </l-marker>
      </l-feature-group>
    </l-feature-group>
  </l-feature-group>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { LFeatureGroup, LMarker, LPopup } from 'vue2-leaflet'
import { icon } from 'leaflet'

export default {
  components: {
    LFeatureGroup,
    LMarker,
    LPopup,
  },
  computed: {
    ...mapGetters('map', {
      filteredAdminPins: 'filteredAdminPins',
      filteredPersonalPins: 'filteredPersonalPins',
      filteredPOIPins: 'filteredPOIPins',
      filteredProPins: 'filteredProPins',
      filteredUserPins: 'filteredUserPins',
    }),
    ...mapGetters('user', {
      isAdmin: 'isAdmin',
    }),
    ...mapState('map', {
      // hazards: state => state.hazards,
      isAdminHazardVisible: state => state.isAdminHazardVisible,
      isPersonalHazardVisible: state => state.isPersonalHazardVisible,
      isProHazardVisible: state => state.isProHazardVisibile,
      isUserHazardVisible: state => state.isUserHazardVisible,
      visibleLayer: state => state.visibleLayer,
      
    }),
    ...mapState('user', {
      user: state => state.user
    })
  },
  created() {
    // this.processHazards()
  },
  data() {
    return {
      adminPins: [],
      delete_loading: false,
      hazards_filtered: [],
      hazards_pois: [],
      itemsWithMeasurement: ['1', '2'],
      personalPins: [],
      poiPins: [],
      pois_icons: [
        new icon ({
          iconUrl: "/img/hazard_icons/admin_point_of_interest_1.png",
          iconSize: [64,64],
          iconAnchor: [32,64]
        }),
        new icon ({
          iconUrl: "/img/hazard_icons/admin_point_of_interest_2.png",
          iconSize: [64,64],
          iconAnchor: [32,64]
        }),
        new icon ({
          iconUrl: "/img/hazard_icons/admin_point_of_interest_3.png",
          iconSize: [64,64],
          iconAnchor: [32,64]
        }),
        new icon ({
          iconUrl: "/img/hazard_icons/admin_point_of_interest_4.png",
          iconSize: [64,64],
          iconAnchor: [32,64]
        }),
        new icon ({
          iconUrl: "/img/hazard_icons/admin_point_of_interest_5.png",
          iconSize: [64,64],
          iconAnchor: [32,64]
        })
      ],
      proPins: [],
      userPins: []
    }
  },
  methods: {
    daysAgo(date) {
      var creationDate =  new Date(date + 'T00:00')
      var daysAgo = Math.floor((new Date() - creationDate) / (1000 * 60 * 60 * 24))

      return daysAgo == 1 ? daysAgo + ' day ago' : daysAgo + ' days ago'
    },

    deleteHazard(pin) {
      this.delete_loading = true
      this.$store.dispatch('map/deleteHazard', pin.id)
      .then(() => {
        this.$emit("pins-changed")

        this.$store.dispatch('ras/getAdvisory', this.visibleLayer.id)
        .then(resp => {      
            if(resp.data) {              
              let post_data = {
                footprint_id: this.visibleLayer.footprint_id,
                layer_id: this.visibleLayer.id,            
                title: resp.data.title,
                ras_description: resp.data.ras_description,
                description: resp.data.description,
                date: resp.data.date,
                id: resp.data.id,
              }
              var newHazardList = resp.data.hazards.split(',')
              var idx = newHazardList.findIndex(item => item == pin.hazard_name)
              if (newHazardList.length == 1 && idx == 0) {
                post_data.hazards = ''
              } 
              else {
                newHazardList.splice(idx,1)
                post_data.hazards = newHazardList.join(',')
              }
              this.$store.dispatch('ras/updateManualAdvisory', post_data)
            }
          })        

        // this.processHazards()
        // var target_url = '/app/radar/' + this.visibleLayer.map_id + "/" + this.visibleLayer.id
        // this.$router.push(target_url)
        // this.$router.go()
        this.delete_loading = false
      })
      .catch(() => {
        this.delete_loading = false
      })
    },
    // processHazards() {
    //   let id = this.id
    //   console.log('id prop is ' + this.id)
    //   this.$store.dispatch('map/getHazards', {id})
    //   .then(() => {
    //     if (this.hazards) {
    //       // filter out Point of Interest Pins
    //       let poi_ids = ['19', '20', '21', '22', '23']
    //       this.hazards_pois = this.hazards.filter(function(el) {
    //         return poi_ids.includes(el.hazardtype_id)
    //       })
    //       this.hazards_pois.sort(function(a, b) {
    //         return parseInt(a.admin_order) - parseInt(b.admin_order)
    //       })

    //       // filter out non-POI pins
    //       this.hazards_filtered = this.hazards.filter(function(el) {
    //         return !(poi_ids.includes(el.hazardtype_id))
    //       })
        
    //       // build user pin groups
    //       let pAdmin = []
    //       let pPersonal = []
    //       let pPOI = []
    //       let pPro = []
    //       let pUser = []

    //       // Create pin for each hazard
    //       if (this.hazards_filtered) {
    //         this.hazards_filtered.forEach((hazard) => {
    //           // Build iconUrl string
    //           var hazard_user_string = '';
    //           if (hazard.usertype_id == 1) {
    //             hazard_user_string = "user"  
    //           } else if (hazard.usertype_id == 2) {
    //             hazard_user_string = "pro"
    //           } else if (hazard.usertype_id == 9) {
    //             hazard_user_string = "admin"
    //           }
    //           var hazard_iconUrl = "/img/hazard_icons/" + hazard_user_string + "_" + hazard.hazardtype_icon

    //           // Build pop-up text
    //           var popup_text = hazard.hazardtype_name
    //           if(hazard.value) {
    //             if(this.itemsWithMeasurement.includes(hazard.hazardtype_id)) {
    //               let x = parseFloat(hazard.value)
    //               let y = this.user.is_metric ? (parseFloat(x.toFixed(2)) + " m"):(parseFloat((x * 39.3701).toFixed(2)) + " in")
    //               popup_text += ": " + y
    //             } else {
    //               popup_text += ": " + hazard.value
    //             }
    //           }

    //           // Determine opacity of of icon
    //           let hazard_opacity = 1.0
    //           if(hazard.is_permanent == 0) {
    //             var current = new Date()
    //             var day = 24 * 60 * 60 * 1000;
    //             var hazard_date =  new Date(hazard.expiration)
    //             var diff = Math.round((hazard_date.getTime() - current.getTime())/day)
    //             hazard_opacity = hazard.usertype_id == 9 || diff > 0 ? Math.round((((diff + 7) / 10.0)).toFixed(2)):0
    //           }

    //           var placeholder = {
    //             lat: hazard.lat,
    //             lng: hazard.lng,
    //             opacity: hazard_opacity,
    //             icon: new icon ({
    //               iconUrl: hazard_iconUrl,
    //               iconSize: [64,64],
    //               iconAnchor: [32,64],
    //               popupAnchor: [0,-75]
    //             }),
    //             date: hazard.date,
    //             popup: popup_text,
    //             id: hazard.id,
    //             is_permanent: hazard.is_permanent,
    //             layer_id: hazard.layer_id,
    //             user_type: hazard.usertype_id,
    //             user_id: hazard.user_id
    //           }
              
    //           if (hazard.user_id == this.user.id) {
    //             // this.personalPins.push(placeholder)
    //             pPersonal.push(placeholder)
    //           } else if (hazard.usertype_id == 1) {
    //             // this.userPins.push(placeholder)
    //             pUser.push(placeholder)
    //           } else if (hazard.usertype_id == 2) {
    //             // this.proPins.push(placeholder)
    //             pPro.push(placeholder)
    //           } else if (hazard.usertype_id == 9) {
    //             // this.adminPins.push(placeholder)
    //             pAdmin.push(placeholder)
    //           }
    //         })
    //       }
          
    //       // Create pin for each POI hazard
    //       if (this.hazards_pois) {
    //         var icon_index = ''
    //         this.hazards_pois.forEach((hazard) => {
    //           switch(hazard.hazardtype_id) {
    //             case '19':
    //               icon_index = 0
    //               break
    //             case '20':
    //               icon_index = 1
    //               break
    //             case '21':
    //               icon_index = 2
    //               break
    //             case '22':
    //               icon_index = 3
    //               break
    //             case '23':
    //               icon_index = 4
    //               break
    //           }

    //           var placeholder = {
    //             layer_id: hazard.layer_id,
    //             lat: hazard.lat,
    //             lng: hazard.lng,
    //             icon_index: icon_index,
    //             id: hazard.id,
    //             date: hazard.date,
    //             user_type: hazard.usertype_id,
    //             user_id: hazard.user_id
    //           }
              
    //           pPOI.push(placeholder)
    //         })
    //         // console.log(this.poiPins)
    //       }

    //       this.$store.dispatch('map/setPinsAdmin', pAdmin)
    //       this.$store.dispatch('map/setPinsPersonal', pPersonal)
    //       this.$store.dispatch('map/setPinsPOI', pPOI)
    //       this.$store.dispatch('map/setPinsPro', pPro)
    //       this.$store.dispatch('map/setPinsUser', pUser)
    //     }
    //   })
    // }
  },
  props: [
    'hazards',
    'id',
    // 'isAdminHazardVisible',
    // 'isPersonalHazardVisible',
    // 'isProHazardVisible',
    // 'isUserHazardVisible',
    'isZoomWithinDropInRange',
  ]
}
</script>

<style scoped>
</style>