<template>
  <v-container>
    <p class="text-h5 pl-5 pt-8"><v-icon class="mr-3" color="teal">mdi-shimmer</v-icon>Stories</p>
    <p class="text-subtitle px-5 pb-3">Media Resources for Social Media <i>Stories</i></p>
    <v-text-field 
      color="teal"
      class="px-4 pb-4 text-h6"
      v-model="search_string"
      prepend-inner-icon="mdi-file-search"
      placeholder="Search Story Bundles"
    />
    <v-layout class="px-4" cols="12" row>
      <v-flex v-for="fact in filtered_stories" :key="fact.index" class="pa-4" xs12 sm6 xl4>
        <v-card class="pa-3 mb-12 card-outter" height="100%">
          <v-card-title class="text-h6">
            {{ fact.text }}
          </v-card-title>
          <v-img 
            :src="fact.filesample"
            class="mx-3 mb-4 mt-0"
          />
          <v-card-actions class="card-actions">
            <v-spacer />
            <v-btn :href="fact.filename" target="_blank" width="100%">Download Image Bundle</v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {
      stories: [
        {
          filename: '/img/power/stories/stories_general.zip',
          filesample: '/img/power/stories/stories_general_0.png',
          text: "Story Images for Unspecified Region"
        },
        {
          filename: '/img/power/stories/stories_manitoba.zip',
          filesample: '/img/power/stories/stories_manitoba_3.png',
          text: "Story Images for Manitoba"
        },
        {
          filename: '/img/power/stories/stories_minnesota.zip',
          filesample: '/img/power/stories/stories_minnesota_3.png',
          text: "Story Images for Minnesota"
        },
        {
          filename: '/img/power/stories/stories_ontario_nw.zip',
          filesample: '/img/power/stories/stories_ontario_nw_3.png',
          text: "Story Images for North Western Ontario"
        },
        {
          filename: '/img/power/stories/stories_ontario_s.zip',
          filesample: '/img/power/stories/stories_ontario_s_3.png',
          text: "Story Images for Southern Ontario"
        },
      ],
      search_string: ''
    }
  },
  computed: {
    filtered_stories() {
      let filtered = this.stories.filter((item) => {
        let lower_text = item.text.toLowerCase()
        let lower_str = this.search_string.toLowerCase()
        return lower_text.includes(lower_str) || item.filename.includes(lower_str)
      })
      
      return filtered.sort(function(a,b) {
        return new Date(b.date) - new Date(a.date);
      })
    }
  }
}
</script>

<style scoped>
>>>.v-input__slot {
  padding: 15px;
}

>>>.v-input__prepend-inner {
  padding-right: 15px!important;
}

>>>.card-outter {
  position: relative;
}

>>>.card-actions {
  bottom: 0;
  position: absolute;
  right:0;
  width: 100%; 
  padding: 1.5em;
}
</style>
