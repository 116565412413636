<template>
    <v-container class="mb-15 pb-15">
      <v-layout class="mb-6 mx-0" cols="12" md12 lg8 offset-lg2 xl6 offset-xl3 row>
            <v-flex xs12>
                <p class="text-h5 ma-8">
                CV Test Page
                </p>
            </v-flex>
            <!-- <v-flex xs12 sm5 md6 lg4></v-flex> -->
            <v-flex xs12>
                <v-select
                    v-model="selected_user"
                    :items="all_users"
                    label="Select User"
                    item-text="alias"
                    item-value="id"
                    outlined
                    @change="getUsersFishingSpot()"
                ></v-select>
            </v-flex>
            <v-flex xs12 v-if="selected_user">
                <v-select
                    v-model="selected_fishing_spot"
                    :items="user_fishing_spots"
                    label="Select Fishing Spot"
                    item-text="name"
                    item-value="id"
                    outlined
                ></v-select>
            </v-flex>
            <!-- <v-flex xs12>
                <div v-if="selected_fishing_spot">
                    <v-card>
                        <v-card-title>Add New Catch:</v-card-title>
                        <v-card-subtitle>*You must upload a different photo every time</v-card-subtitle>
                        <v-container >
                            <v-row class="pt-2">
                            <v-col class="px-2" cols="12" sm="6">
                                <v-autocomplete
                                    v-model="selectedSpecies"
                                    :items="species"
                                    item-text="common_name"
                                    item-value="id"
                                    dense
                                    label="Species"
                                    hide-details
                                    class="pb-2"
                                    >
                                </v-autocomplete>
                            </v-col>
                            <v-col class="px-2" cols="12" sm="6">
                                <v-text-field
                                dense
                                hide-details
                                label="Length"
                                v-model="length"
                                type="number"
                                suffix="cm.">
                                </v-text-field>
                            </v-col>       
                            <v-col class="px-0" cols="12" sm="6">
                                <v-card-text class="py-0">
                                    <div class="text-body-1">Catch Photo</div>
                                    <p class="text-h6"></p>                            
                                </v-card-text>  
                                <div class="px-4">
                                <image-uploader
                                    ref="fileUpload"
                                    :debug="0"
                                    :maxWidth="maxValues"
                                    :maxSize="maxSize"
                                    :maxHeight="maxValues"
                                    :scaleRatio="scaleRatio"
                                    :autoRotate=false
                                    outputFormat="verbose"
                                    :preview=false
                                    :className="['fileinput', { 'fileinput--loaded' : hasImage }]"
                                    @input="setImage"
                                >
                                <label for="fileInput" slot="upload-label">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="32"
                                        height="32"
                                        viewBox="0 0 32 32"
                                    >
                                        <path
                                        class="path1"
                                        d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                                        ></path>
                                    </svg>
                                    <span class="px-2 upload-caption">{{
                                    hasImage ? "Change Photo" : "Select Photo"
                                    }}</span>
                                </label>
                                </image-uploader>
                                <div v-if="currentImage && $vuetify.breakpoint.xs" style="max-width: 280px">
                                    <v-img :src="currentImage.dataUrl"/>
                                </div>
                                <div v-else-if="currentImage" style="max-width: 280px">
                                    <v-img :src="currentImage.dataUrl"/>
                                </div>          
                                <div v-if="image_metadata" >
                                    <v-btn 
                                        elevation="0"
                                        small
                                        dark
                                        color="yellow"
                                        :loading="loading"
                                        width="40%"
                                        @click="showMetadata"
                                    >
                                        See Meta Data
                                    </v-btn>
                                </div>
                                             
                                </div>
                            </v-col>                 
                            </v-row>
                        </v-container>
                        <v-card-actions>
                            <v-btn 
                                elevation="0"
                                small
                                dark
                                color="indigo"
                                :loading="loading"
                                width="100%"
                                @click="save_fish"
                            >
                                save catch
                            </v-btn>
                            <div v-if="showMessage">
                                    <v-alert :type="messageType">
                                        {{message}}
                                    </v-alert>
                                </div>        
                        </v-card-actions>
                    </v-card>
                </div>
            </v-flex> -->
            <v-flex xs12>
                <v-container v-if="selected_fishing_spot">
                    <v-data-table
                        dense
                        :headers="headers"
                        :items="catches"
                        :items-per-page="-1"
                        class="elevation-1"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"        
                        @click:row="showImage"   
                    >
                        <template v-slot:item.image_name="{ item }">
                            <!-- <v-img :src="(image_start + item)" max-height="50" max-width="50"/> -->
                            <!-- <v-avatar class="ma-1" tile> -->
                                <v-img         
                                    v-if="item.image_name"        
                                    :src="($apiEndpointUrl + image_start + item.image_name)" 
                                    max-height="100"
                                    max-width="100"
                                    alt="Catch"/>
                                <v-icon v-else large>mdi-account</v-icon>
                            <!-- </v-avatar> -->
                            <!-- <v-img v-else src="$apiEndpointUrl + 'profile_image/532profile.jpeg'" max-height="50" max-width="50"/> -->
                            <!-- <v-icon v-else large >mdi-account-box-outline</v-icon> -->
                        </template>
                        <template v-slot:item.ai_species="{ item }">
                            <div v-if="item.ai_species == null"></div>
                            <div v-else>{{ GetSpeciesName(item.ai_species) }}</div>
                        </template>
                        <template v-slot:item.ai_status="{ item }">
                            <v-icon v-if="item.ai_status==2" large color="green">mdi-checkbox-marked</v-icon>
                            <v-icon v-else-if="item.ai_status == 1" large color="red">mdi-close-box</v-icon>
                            <v-icon v-else large color="black">mdi-checkbox-blank-outline</v-icon>
                        </template>
                        <template v-slot:item.board_status="{ item }">
                            <v-icon v-if="item.board_status==2" large color="green">mdi-checkbox-marked</v-icon>
                            <v-icon v-else-if="item.board_status == 1" large color="red">mdi-close-box</v-icon>
                            <v-icon v-else large color="black">mdi-checkbox-blank-outline</v-icon>
                        </template>
                        <template v-slot:item.qr_status="{ item }">
                            <v-icon v-if="item.qr_status==2" large color="green">mdi-checkbox-marked</v-icon>
                            <v-icon v-else-if="item.qr_status == 1" large color="red">mdi-close-box</v-icon>
                            <v-icon v-else large color="black">mdi-checkbox-blank-outline</v-icon>
                        </template>
                        <template v-slot:item.fish_status="{ item }">
                            <v-icon v-if="item.fish_status==2" large color="green">mdi-checkbox-marked</v-icon>
                            <v-icon v-else-if="item.fish_status == 1" large color="red">mdi-close-box</v-icon>
                            <v-icon v-else large color="black">mdi-checkbox-blank-outline</v-icon>
                        </template>
                        <template v-slot:item.measurement_status="{ item }">
                            <v-icon v-if="item.measurement_status==2" large color="green">mdi-checkbox-marked</v-icon>
                            <v-icon v-else-if="item.measurement_status == 1" large color="red">mdi-close-box</v-icon>
                            <v-icon v-else large color="black">mdi-checkbox-blank-outline</v-icon>
                        </template>
                    </v-data-table>
                </v-container>
            </v-flex>
        </v-layout>
        <v-dialog
            v-model="image_overlay"
            max-width="500"
            overflow-auto
        >
            <v-card max-width="500">
                    
                    <v-card-actions class="pa-2">
                        <v-spacer></v-spacer>
                        <v-icon
                            large
                            @click="image_overlay = false"
                        >mdi-close-circle
                        </v-icon>                
                    </v-card-actions>
                    <v-card-text>
                        Board:
                    </v-card-text>
                    <v-img
                        :src="($apiEndpointUrl + image_mask_start + 'board_' + image_mask_name)" 
                        contain
                        class="px-3"
                        >
                        <!-- max-height="500" -->
                        <!-- <template v-slot:placeholder>
                            <div class="d-flex align-center justify-center fill-height">
                                <v-progress-circular
                                color="grey-lighten-4"
                                indeterminate
                                ></v-progress-circular>
                            </div>
                        </template> -->
                    </v-img>
                    <v-card-text>
                        QR:
                    </v-card-text>
                    <v-img
                        :src="($apiEndpointUrl + image_mask_start + 'qr_' + image_mask_name)" 
                        contain
                        class="px-3"
                        >
                    </v-img>
                    <v-card-text>
                        Mask:
                    </v-card-text>
                    <v-img
                        :src="($apiEndpointUrl + image_mask_start + 'mask_' + image_mask_name)" 
                        contain
                        class="px-3"
                        >
                    </v-img>
                    <v-card-text>
                        Measurement:
                    </v-card-text>
                    <v-img
                        :src="($apiEndpointUrl + image_mask_start + 'measurement_' + image_mask_name)" 
                        contain
                        class="px-3"
                        >
                    </v-img>
                <!-- <v-img src="$apiEndpointUrl + 'catch_image/6478f3c0bfcdd.png'"/> -->
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="image_metadata_overlay"
            max-width="500"
            overflow-auto
        >
            <v-card max-width="500">
                <v-card-actions class="pa-2">
                        <v-spacer></v-spacer>
                        <v-icon
                            large
                            @click="image_metadata_overlay = false"
                        >mdi-close-circle
                        </v-icon>         
                    </v-card-actions>
                    <v-card-text>
                        {{ image_metadata }}
                    </v-card-text>
            </v-card>
        </v-dialog>
      <Footer />
      </v-container>
    </template>
    
    <script>
    import { mapState } from 'vuex'
    import Footer from '../../../components/Footer.vue'
    // import ImageUploader from 'vue-image-upload-resize';
    
    export default {
      data() {
        return {
            selected_fishing_spot: null,
            
            image_start: "catch_image/",
            image_mask_start: "catch_mask_image/",
            // image_start: this.$apiEndpointUrl + "catch_image/",
            // image_mask_start: this.$apiEndpointUrl + "catch_mask_image/",
            // test: this.$apiEndpointUrl + "catch_mask_image/",
            loading: false,
            selectedSpecies: '',
            length: '',
            err_message: '',
            error: false,
            catch_error: false,
            currentImage: undefined,
            previewImage: undefined,
            showMessage: false,
            message: "",
            messageType: "",

            image_metadata_overlay:false,
            image_overlay:false,
            image_name:null,
            image_mask_name: null,

            image: null,
            hasImage: false,
            originalImage: null,
            image_metadata: null,
            blobImage: null,
            catch_id: null,
            interval: null,
            qualityAmount: 1,
            scaleRatio: 1,
            maxValues: 10000, //This is to set the max size (height, width) of the image in Pixels(?), we must have this as the defaults will shrink images and lose quality
            maxSize: 10, //This is in MP, and roughly converts to just below 10 MB at 12 bit depth, but the program does not seem to be converitng as the math should line up...

            sortBy: 'id',
            sortDesc: true,
            headers: [
                { text: 'id', align: 'start', value: 'id' },
                { text: 'Common Name', value: 'common_name' },
                { text: 'Length', value: 'length' },
                { text: 'Calculated Length', value: 'ai_length' },
                { text: 'Calculated Species', value: 'ai_species' },
                { text: 'Upload Status', value: 'ai_status' },
                { text: 'Board Status', value: 'board_status' },
                { text: 'QR Status', value: 'qr_status' },
                { text: 'Fish Status', value: 'fish_status' },
                { text: 'Measurement Status', value: 'measurement_status' },
                { text: 'Picture', value: 'image_name' },
            ],
            selected_user: null,
        }
        },
            watch: {
            selected_fishing_spot(){
                this.$store.dispatch('spot_mapper/setSelectedFishingSpot', this.selected_fishing_spot)
                this.getAllCatches()
            }
        },
      components: {
          Footer,
        //   ImageUploader
      },
      computed: {
          ...mapState('user',{
              user: state => state.user,
              profile_card: state => state.profile_card,
              profile_card_species: state => state.profile_card_species,
          }),
          ...mapState('admin', {
                users: state => state.users,
                all_users: state => state.all_users,
          }),
          ...mapState('spot_mapper',{
              species: state => state.species,
              fishing_spots: state => state.fishing_spots,
              all_fishing_spots: state => state.all_fishing_spots,
              user_fishing_spots: state => state.user_fishing_spots,
              catches: state => state.catches,
          }),
          font_size_value(){
              return !this.$vuetify.breakpoint.xs ? "text-h5" : "text-h6"
          },
          info_height(){
              return !this.$vuetify.breakpoint.xs ? "300px" : "310px"
          },
          bio_height(){
              return !this.$vuetify.breakpoint.xs ? "200px" : "auto"
          }
      },
      methods: {
        update_regional(){
            let data = {
                region: '16',
                season_id: '1'
            }
            this.$store.dispatch('spot_mapper/updateRegional', data)
        },
        showImage(row){
            // console.log(row)
            this.image_mask_name = row.image_name
            this.image_overlay = true

        },
        getName(){
            // console.log(user)
            // let temp_name = null
            // if(user.first_name){
            //     temp_name = user.first_name + " " + user.last_name
            // }else{
            //     temp_name = user.alias
            // }
            // return temp_name
        },
        getUsersFishingSpot(){
            // console.log(this.selected_user)
            this.$store.dispatch('spot_mapper/getUserFishing', this.selected_user).then(() =>{
                // console.log(this.user_fishing_spots)
            })
        },
        showMetadata(){
            // console.log(image)
            // this.image_mask_name = image
            this.image_metadata_overlay = true
            // var metadata_array = JSON.parse(JSON.stringify(this.image_metadata))
            // console.log(metadata_array)

        },
        GetSpeciesName(id){
            let index = this.species.findIndex(obj => obj.id == id)
            return this.species[index].common_name
        },
        getAllCatches(){
            this.$store.dispatch('spot_mapper/getCatches', this.selected_fishing_spot)
            .then(() => {
                clearInterval(this.interval)
                // console.log(this.catches)
                this.interval = null
                this.interval = setTimeout(()=> {
                    this.getAllCatches()
                }, 2000)
            })
        },
        setImage(output) {
            this.hasImage = true
            this.currentImage = output
            this.originalImage = this.$refs.fileUpload.$el.children[1].files[0]
            this.image_metadata = output.exif
            // console.log(this.currentImage)
            // console.log(this.currentImage.dataUrl)
            // console.log('Exif', output.exif)
            // console.log('Original Image:', this.originalImage)
            // this.previewImage = output.dataUrl
            this.require_photo = false
        },
        getDBTime(date){
            let localdate = new Date(date)
            return (localdate.toISOString().slice(0, -1))
        },
        uploadFile(catchId) {
            // console.log("image upload")
          if(this.currentImage != undefined && !this.showMessage) {
            let formData = new FormData()
            fetch(this.currentImage.dataUrl)
            .then(response => response.blob())
            .then(blob => {
                var name = this.currentImage.info.name
                var type = this.currentImage.info.type
                var file = new File([blob], name, {type: type})

                formData.append('file', file)
                formData.append('catch_id', catchId)
                // formData.append('qualityAmount', this.qualityAmount)
                // formData.append('scaleRatio', this.scaleRatio)
                this.$store.dispatch('leaderboard/uploadImage', formData)
                  .then((response) => {
                    this.currentImage = undefined
                    this.previewImage = undefined
                    this.message = response["data"]   
                    if(this.message.includes("Error")) {
                        this.messageType = "error"
                    }
                    else {
                        this.messageType = "success"
                    }
                    // this.showMessage = true
                    this.getAllCatches()
                  })
                  .catch((err) => {
                      // console.log(err)
                      this.err_message = err
                      this.showMessage = true
                  })
            })
          }
        },
        save_fish() {
            this.error = false
            if(this.species === ''){
                this.error = true
            } else {
                // this.loading = true
                let date = new Date()
                let fixed_date = this.getDBTime(date)
                let data = {
                    species: this.selectedSpecies,
                    date: fixed_date,
                    length: this.length,
                    fishing_spot: this.selected_fishing_spot,
                }
                // console.log(data)
                this.$store.dispatch('spot_mapper/recordCatch', data)
                .then((response) => {
                    this.catch_error = false
                    // console.log(response["data"])
                    this.catch_id = response["data"]

                })
                .catch((err) => {
                    this.err_message = 'Retry: ' + err
                    this.catch_error = true
                    // console.log(this.err_message)
                    this.catch_id = undefined
                })
                .finally(() => {
                    this.loading = false
                    if(!this.catch_error){
                        this.messageType = "success"
                        // console.log(this.catch_id)
                    }
                    this.update_regional()
                    // console.log(this.currentImage)
                    // console.log(this.catch_error)
                    if(this.currentImage != undefined && !this.catch_error){
                        this.uploadFile(this.catch_id)
                        this.length = null
                    }else{
                        this.getAllCatches()
                        this.length = null
                    }
                })
            }
        },
      },
      mounted() {
        this.$store.dispatch('setTab', 'cvtest')
        this.$store.dispatch('spot_mapper/getSpecies')
        // this.$store.dispatch('spot_mapper/getUserFishing')
        // this.$store.dispatch('spot_mapper/getAllFishingSpots')
        // .then(() => {
        // console.log(this.fishing_spots)
        // })
        // this.$store.dispatch('admin/getUsers')
        // .then(() => {
        //     console.log(this.users)
        //     this.showLoading = false
        // })
        this.$store.dispatch('admin/getAllUsers')
        .then(() => {
            // console.log(this.all_users)
            this.showLoading = false
        })
      },
      beforeDestroy() {
        clearInterval(this.interval)
        this.interval = null
      }
    }
    </script>
  
  <style scoped>
  >>>#center{
      margin: auto;
  }
  </style>