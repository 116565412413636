<template>
    <v-container class="ma-0 pa-0">
        <v-card class="blue lighten-5 mt-5 mb-16 pb-3">
            <v-row align="center" class="ml-0 pl-0">
                <v-col cols="9">
                    <v-card-title class="pb-0 mb-0 pl-2 pt-2">
                        <p class="mb-0 pb-0 font-weight-bold text-h5">ALB Daily Top 20</p>
                    </v-card-title>
                    <p class="my-0 py-0 pl-2 text-subtitle-1">Regional Leaderboard</p>
                </v-col>
            </v-row>   
            <v-col cols="16" sm="3" class="mb-0 pb-0">
                <v-select
                    :items="week_dates"
                    v-model="selected_date"
                    label="Date"
                    solo
                    read-only
                    dense
                    @change="getNewDateData(selected_date)"
                    prepend-icon="mdi-calendar-range"
                    hide-details
                    ></v-select>
            </v-col>
            <v-container v-if="fixed_leaderboard.length == 0">
                <v-card >
                    <v-card-title>No recorded catches!</v-card-title>
                </v-card>            
            </v-container>

            <v-container v-else>

                <v-data-table
                    v-if="mobile_fullscreen"
                    :mobile-breakpoint="0"
                    :headers="mobile_headers"
                    :items="itemsWithIndex"
                    :items-per-page="20"
                    class="mt-2 elevation-1"   
                    :hide-default-footer="true"
                    :single-expand="true"
                    disable-sort
                    item-key="alias"
                    @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
                >
                    <template v-slot:item.alias="{ item }">
                        <div v-if="item.alias.length > 12">{{ item.alias.substring(0,12) }}...</div>
                        <div v-else>{{ item.alias }}</div>
                    </template>  
                    <template v-slot:item.score="{ item }">
                        <div>{{ Math.round(item.score) }}</div>
                    </template>  
                    <template v-slot:item.image="{ item }">
                        <v-avatar class="my-2">
                            <v-img        
                                v-if="item.profile_image"        
                                :src="($apiEndpointUrl + image_start + item.profile_image)" 
                                alt="Avatar"
                            />
                            <v-icon v-else large>mdi-account</v-icon>
                        </v-avatar>
                    </template>
                    <template v-slot:item.place="{ item }">
                        <div v-if="item.place === 1"><v-img contain src="/img/Gold-removebg.png" height="40" width="40"></v-img> </div>
                        <div v-else-if="item.place === 2"><v-img contain src="/img/Silver-removebg.png" height="40" width="40"></v-img> </div>
                        <div v-else-if="item.place === 3"><v-img contain src="/img/Bronze-removebg.png" height="40" width="40"></v-img> </div>
                        <div style="padding-left: 10px;" v-else>{{ item.place }}th</div>
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                        <td class="expand-panel" :colspan="headers.length">
                            <v-row class="py-2">
                                <v-col cols="6">
                                    <v-card-text class="py-0">
                                        <div class="text-caption font-weight-bold">Diversity</div>
                                        <v-chip class="text-body-1" :color="getColor(item.diversity)" dark>{{ item.diversity }}</v-chip>
                                    </v-card-text>   
                                </v-col>  
                                <v-col cols="6">
                                    <div class="text-caption font-weight-bold">View Profile</div>
                                    <v-btn @click="showCard(item)" icon small>
                                        <v-icon>mdi-card-account-details-outline</v-icon>
                                    </v-btn>
                                </v-col>  
                            </v-row>
                            <v-row class="pb-2" no-gutters>
                                <v-col cols="12">
                                    <v-card-text class="py-0">
                                        <div class="text-caption font-weight-bold">Total Score</div>
                                        <p v-if="item.score" class="text-body-1">{{ item.score }}</p>  
                                        <p v-else class="text-body-1">-</p>                          
                                    </v-card-text>   
                                </v-col>   
                            </v-row>
                            
                        </td>
                    </template>
                </v-data-table>

                <v-data-table
                    v-else
                    dense
                    :headers="headers"
                    :items="fixed_leaderboard"
                    :items-per-page="20"
                    class="elevation-1 mt-2"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"        
                    @click:row="showCard"
                    :hide-default-footer="true"
                >
                    <template v-slot:item.image="{ item }">
                        <!-- <v-img :src="(image_start + item)" max-height="50" max-width="50"/> -->
                        <v-avatar class="ma-2">
                            <v-img         
                                v-if="item.profile_image"        
                                :src="($apiEndpointUrl + image_start + item.profile_image)" 
                                alt="Avatar"/>
                            <v-icon v-else large>mdi-account</v-icon>
                        </v-avatar>
                        <!-- <v-img v-else src="$apiEndpointUrl + 'profile_image/532profile.jpeg'" max-height="50" max-width="50"/> -->
                        <!-- <v-icon v-else large >mdi-account-box-outline</v-icon> -->

                    </template>
                    <template v-slot:item.place="{ item }">
                        <div v-if="item.place === 1"><v-img contain src="/img/Gold-removebg.png" height="90" width="90"></v-img> </div>
                        <div v-else-if="item.place === 2"><v-img contain src="/img/Silver-removebg.png" height="90" width="90"></v-img> </div>
                        <div v-else-if="item.place === 3"><v-img contain src="/img/Bronze-removebg.png" height="90" width="90"></v-img> </div>
                        <div v-else style="padding-left: 35px;" >{{ item.place }}th</div>

                    </template>
                    <template v-slot:item.diversity="{ item }">
                        <v-chip
                            :color="getColor(item.diversity)"
                            dark
                        >
                            {{ item.diversity }}
                        </v-chip>
                    </template>
                </v-data-table>
            </v-container>
          
        </v-card>

    <v-dialog v-model="image_overlay" max-width="1000">
    <v-card class="pa-4">
        <v-row >
            <v-spacer></v-spacer>
            <v-card-actions class="pb-0">
                <v-icon
                    large
                    @click="image_overlay = false"
                >mdi-close-circle
                </v-icon>                
            </v-card-actions>
        </v-row>

        <v-row>            
            <v-col cols="12" sm="4">
                <v-card-text class="text-center">         
                    <v-avatar class="ma-2 justify-center" size="128">
                        <v-img         
                            v-if="current_avatar"        
                            :src="($apiEndpointUrl + image_start + current_avatar)" 
                            alt="Avatar"/>
                        <v-icon v-else large>mdi-account</v-icon>
                    </v-avatar> 
                    <v-card-title class="justify-center"><h2>{{ card_name }}</h2></v-card-title>

                    <v-row no-gutters>
                        <v-col>
                            Joined: {{ card_date }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            Regions: {{ card_region }}
                        </v-col>
                    </v-row>  
                </v-card-text> 
            </v-col>   
            <v-col cols="12" sm="8">
                <v-card class="pb-4" :height="info_height" outlined>
                    <v-tabs
                        fixed-tabs
                        bg-color="primary"
                        class="mb-5"
                    >
                        <v-tab v-if="!$vuetify.breakpoint.xs">Competitions</v-tab>
                        <v-tab v-else><v-icon>mdi-trophy-outline</v-icon></v-tab>
                        <v-tab v-if="!$vuetify.breakpoint.xs">Catches</v-tab>
                        <v-tab v-else><v-icon>mdi-fish</v-icon></v-tab>
                    
                        <v-tab-item class="mt-4" align="center">
                            <v-row align="center">
                                <v-col cols="12" sm="6" class="d-flex">
                                    <v-card-text class="py-0">
                                        <div>Total Completed Competitions</div>
                                        <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card.total }}</p>
                                        <p v-else :class="font_size_value">-</p>                                            
                                    </v-card-text>                    
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <v-row>
                                        <v-col cols="4" sm="12">
                                            {{ profile_card.firsts }}
                                            <v-icon color="yellow" large>mdi-trophy</v-icon>
                                            <nobr v-if="!$vuetify.breakpoint.xs"> 1st Place Finishes</nobr>
                                        </v-col>
                                        <v-col cols="4" sm="12">
                                            {{ profile_card.seconds }}
                                            <v-icon color="grey" large>mdi-trophy</v-icon>
                                            <nobr v-if="!$vuetify.breakpoint.xs"> 2nd Place Finishes</nobr>
                                        </v-col>
                                        <v-col cols="4" sm="12">
                                            {{ profile_card.thirds }}
                                            <v-icon color="brown" large>mdi-trophy</v-icon>
                                            <nobr v-if="!$vuetify.breakpoint.xs"> 3rd Place Finishes</nobr>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item>
                            <v-row class="mt-4 mx-4">
                                <v-autocomplete
                                    item-text="common_name"
                                    item-value="id"
                                    hide-details="auto"
                                    outlined
                                    dense
                                    label="Species"
                                    v-model="selected_species"
                                    :items="species"
                                    return-object
                                    :onchange="getCardSpeciesInfo()"
                                ></v-autocomplete>                                    
                            </v-row>

                            <v-row>
                                <v-col cols="6" sm="6">
                                    <v-card-text class="py-0">
                                        <div>Total Caught</div>
                                        <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card_species.total }}</p>
                                        <p v-else :class="font_size_value">0</p>                                            
                                    </v-card-text>                    
                                </v-col>
                                <v-col cols="6" sm="6">
                                    <v-card-text class="py-0">
                                        <div>Longest Caught</div>
                                        <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card_species.longest }} cm</p>
                                        <p v-else :class="font_size_value">-</p>                        
                                    </v-card-text>   
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6" sm="6">
                                    <v-card-text class="py-0">
                                        <div>Heaviest Caught</div>
                                        <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card_species.heaviest }} lbs.</p>
                                        <p v-else :class="font_size_value">-</p>                                            
                                    </v-card-text>                    
                                </v-col>
                                <v-col cols="6" sm="6">
                                    <v-card-text class="py-0">
                                        <div>Average Caught per Fishing Day</div>
                                        <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ Math.round((profile_card_species.total/profile_card_species.days)*100) / 100 }}</p>
                                        <p v-else :class="font_size_value">-</p>                        
                                    </v-card-text>   
                                </v-col>
                            </v-row>                                
                        </v-tab-item>
                    </v-tabs>                          
                </v-card>
            </v-col>    
            <v-col cols="12" sm="4">
                <v-card class="pb-4" :height="bio_height" outlined>
                    <v-card-title class="pb-0">
                        <h5>Bio</h5>  
                        <v-card-text class="px-0">{{ card_bio }}</v-card-text>                          
                    </v-card-title>
                </v-card>
            </v-col>          
            <v-col cols="12" sm="8">
                <v-card class="pb-4" height="200px" outlined>
                    <v-card-title class="pb-0">
                        <h5>Badges</h5>   
                    </v-card-title>
                    <!-- <v-row class="justify-center py-8">
                        <v-slide-group class="pa-1" show-arrows>
                            <v-slide-item><v-img contain src="/img/test_badge.png" max-height="100px" max-width="100px"/></v-slide-item>
                            <v-slide-item><v-img contain src="/img/badge2.png" max-height="100px" max-width="100px"/></v-slide-item>
                            <v-slide-item><v-img contain src="/img/badge3.png" max-height="100px" max-width="100px"/></v-slide-item>
                            <v-slide-item><v-img contain src="/img/badge4.png" max-height="100px" max-width="100px"/></v-slide-item>
                            <v-slide-item><v-img contain src="/img/badge5.png" max-height="100px" max-width="100px"/></v-slide-item>                      
                        </v-slide-group>
                    </v-row> -->
                </v-card>
            </v-col>                       
        </v-row>
        
    </v-card>
    </v-dialog>

</v-container>
</template>
      
<script>
import { mapState } from 'vuex'
    
export default {
    data: () => ({
        metric: false,
        region:"0",
        test_dates: ["Live", "2023", "2022", "2021", "2020", "2019"],
        region_list: [{"id": "0", "name": "All Regions"}],
        fixed_leaderboard:[],
        region_id: '16',
        image_start: 'profile_image/',

        sortBy: 'score',
        sortDesc: true,
        mobile_headers: [
            // { text: '', value: 'index'},
            { text: 'Place', align: 'start', value: 'place', width: "10%" },
            { text: 'User', value: 'image', align: 'start', width: "10%" },
            { text: '', value: 'alias', align: 'left' },
            { text: 'Score', value: 'score', align: 'right' },
        ],
        headers: [
            { text: 'Place', align: 'start', value: 'place' },
            { text: '', value: 'image' },
            { text: 'User', value: 'alias' },
            { text: 'Unique Species', value: 'caught_species' },
            { text: 'Catch Score', value: 'catch_score' },
            { text: 'Diversity (%)', value: 'diversity' },
            { text: 'Comp Bonus', value: 'comp_points' },
            { text: 'Score', value: 'score' },
        ],
        image_overlay:false,
        zIndex: 1,
        join_date: null,

        card_mode: 1,
        mode_options: [
            { text: 'Competition', value: 1},
            { text: 'Species', value: 2},
        ],
        card_name: null,
        card_date: null,
        card_region: null,
        card_bio: null,
        selected_species: null,
        current_species: null,
        current_avatar: null,
        loading: false,
        button_disabled: false,
        tab: null,
        week_dates: [],
        selected_date:"",
    }),
    computed: {
        ...mapState('leaderboard', {
            daily_leaderboard: state => state.daily_leaderboard,
            regions: state => state.regions,
        }),
        ...mapState('user',{
            user: state => state.user,
            profile_card: state => state.profile_card,
            profile_card_species: state => state.profile_card_species,
        }),
        ...mapState('spot_mapper',{
            species: state => state.species,
        }),
        font_size_value(){
            return !this.$vuetify.breakpoint.xs ? "text-h5" : "text-h6"
        },       
        info_height(){
            return !this.$vuetify.breakpoint.xs ? "300px" : "310px"
        },            
        bio_height(){
            return !this.$vuetify.breakpoint.xs ? "200px" : "auto"
        },
        mobile_fullscreen() {
            return !this.$vuetify.breakpoint.mobile ? false : true
            // return true
        }, 
        itemsWithIndex() {  
            return this.fixed_leaderboard.map(
                (items, index) => ({
                ...items,
                index: index + 1
            }))
        }
    },
    methods: {
        showCard(row){
            this.fixed_leaderboard.map((item, index) => {
                item.selected = item === row

                this.$set(this.fixed_leaderboard, index, item)
            })

            this.selected_species = null
            this.current_species = null
            this.card_mode = 1
            this.$store.dispatch('user/getProfileCard', row.user_id)
            .then(() => {
                this.card_name = this.profile_card.profile.name
                this.card_date = new Date(this.profile_card.profile.tos_accepted).toLocaleDateString('en-us', { month:"long", day:"numeric", year:"numeric"})
                this.card_region = this.profile_card.profile.region
                this.card_bio = this.profile_card.profile.bio

                this.$store.dispatch('spot_mapper/getSpecies')
                .then(() => {
                    // console.log(this.species)
                    this.image_overlay = true
                })
            })
            this.current_avatar = row.profile_image
        },
        getDBTime(date){
            let localdate = new Date(date)
            // console.log(localdate.toISOString().slice(0, -1))
            // return (localdate.toISOString().slice(0, -1))
            return localdate.toISOString().split('T')[0]
        },
        calcualteDiversity(){

        },
        getNewDateData(day){
            let region = this.region_id
            let date = day
            this.fixed_leaderboard = []
            this.$store.dispatch('leaderboard/getDailyLeaderboard', { region, date}).then(() => {
            var current_place = 1
            this.daily_leaderboard.forEach(item => {
                // let simplediversity = (item.unique_species / 30)
                var row = {
                        place: current_place,
                        profile_image: item.profile_image,
                        user_id: item.user_id,
                        alias: item.alias,
                        caught_species: item.caught_species,
                        catch_score: item.catch_score,
                        diversity: (Math.round(((item.diversity*100) + Number.EPSILON) * 100) / 100), 
                        comp_points: (Math.round(((Number(item.comp_points)) + Number.EPSILON) * 100) / 100),
                        score: (Math.round(((Number(item.score)) + Number.EPSILON) * 100) / 100),
                    }
                    // diversity: (Math.round(((item.diversity*100) + Number.EPSILON) * 100) / 100), 
                    // score: (Math.round(((Number(item.score)) + Number.EPSILON) * 100) / 100),
                    // comp_points: (Math.round(((Number(item.comp_points)) + Number.EPSILON) * 100) / 100),
                this.fixed_leaderboard.push(row)
                current_place ++
            })
            // console.log(this.fixed_leaderboard)
            })
        },
        calculateDates(){

            // this.getDBTime(date)
            let today = new Date();
            let minus1 = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
            let minus2 = new Date(minus1.getTime() - 1 * 24 * 60 * 60 * 1000);
            let minus3 = new Date(minus2.getTime() - 1 * 24 * 60 * 60 * 1000);
            let minus4 = new Date(minus3.getTime() - 1 * 24 * 60 * 60 * 1000);
            let minus5 = new Date(minus4.getTime() - 1 * 24 * 60 * 60 * 1000);
            let minus6 = new Date(minus5.getTime() - 1 * 24 * 60 * 60 * 1000);
            this.week_dates = [
                {text:this.getFormatedDate(today), value:this.getDBTime(today)},
                {text:this.getFormatedDate(minus1), value:this.getDBTime(minus1)},
                {text:this.getFormatedDate(minus2), value:this.getDBTime(minus2)},
                {text:this.getFormatedDate(minus3), value:this.getDBTime(minus3)},
                {text:this.getFormatedDate(minus4), value:this.getDBTime(minus4)},
                {text:this.getFormatedDate(minus5), value:this.getDBTime(minus5)},
                {text:this.getFormatedDate(minus6), value:this.getDBTime(minus6)},

            ]
            this.selected_date = this.getDBTime(today)
            // console.log(this.week_dates)
        },
        getFormatedDate(value){
            const offset = value.getTimezoneOffset()
            value = new Date(value.getTime() - (offset*60*1000))
            // console.log(value.toISOString().split('T')[0])
            return value.toISOString().split('T')[0]
        },
        getColor (value) {
            if (value > 50) return 'green'
            else if (value > 20) return 'orange'
            else return 'red'
        },
        getCardSpeciesInfo(){
            if(this.selected_species!==null && !this.loading){
                if(this.current_species!==this.selected_species.id){
                    this.loading = true
                    let user = this.user.id
                    let species = this.selected_species.id
                    this.$store.dispatch('user/getProfileCardSpecies', { user, species})
                    .then(() => {
                        // console.log(this.profile_card_species)
                        this.current_species = this.selected_species.id
                        this.loading = false
                    })
                }
            }
        },
    },
    created () {

    },
    mounted(){

        this.calculateDates()
        this.getNewDateData(this.selected_date)
        this.$store.dispatch('leaderboard/getRegions').then(() => { 
            Object.values(this.regions).forEach((item) => {
                this.region_list.push(item)
            })
        })

        this.join_date = new Date(this.user.tos_accepted).toLocaleDateString('en-us', { month:"long", day:"numeric", year:"numeric"})


        //*This is to view the url Data Params, and will be used for the Bumb Board testing*
        // let urlParams = new URLSearchParams(window.location.search);
        // let myParam = urlParams.get('page');
        // // console.log(myParam)
        // if(myParam === "mycomps"){
        //         this.$store.page = 'join_list'
        //         this.$emit('change-page')
        // }
        // if(myParam === "regional"){
        //         // console.log("You got here from the QR code!")
        // }
        // if(myParam === "test"){
        //     this.$store.page = 'admin_leaderboard'
        //     this.$emit('change-page')
        // }
    }
}
</script>
<style scoped>
>>>.expand-panel {
    background-color: white !important;
}
/* Should zebra stripe leaderboard */
>>>tr:nth-child(even){
    background-color: #90CAF9;
}
>>>.container-class {
    position: relative;
}
>>>.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(1){
    padding-left: 10px;
}
>>>.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(2){
    padding-left: 0px;
}
>>>.v-data-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(2){
    padding-left: 0px;
}
>>>#add-left {
    position: absolute;
    top:25%;
    left: -10px;
    border: none;
    
}
>>>#add-left-div {
    position: absolute;
    height: 50%;
    width: 9%;
    top:25%;
    left: .2%;
    border: none;
    
}
>>>#add-right-div {
    position: absolute;
    height: 50%;
    width: 9%;
    top:25%;
    right: .2%;
    border: none;
    
}
>>>#add-top {
    /* position: absolute; */
    /* top:25%; */
    left: 10%;
}
>>>#add-bot {
    scroll-margin-bottom: 500px;
    position: fixed;
    bottom:0%;
    left: 13%;
}
>>>#add-bot-sm {
    scroll-margin-bottom: 500px;
    position: fixed;
    bottom:0%;
    left: 8.5%;
}
>>>#center{
    margin: auto;
}
>>>#underline{
    color:black;
    border-bottom: solid .8px;
    border-color: white;
}
>>>#input{
    color:black;
    font-size: 18px;
    line-height: 100%;
    text-align: center;
    /* font-weight: bold; */
    padding: 0;
}

</style>