<template>
    <span>
      <l-control 
        v-show="visibleLayer.id"
        class="custom-control"
        position="topright"
      >

        <v-tooltip v-if="visibleLayer.id" left :disabled=is_mobile v-model="help">
        <template v-slot:activator="{ on, attrs }">
            <v-btn     
                v-show="!showLegend"               
                icon
                @click="toggleLegend"
                v-bind="attrs"
                v-on="on"
                @pointerdown="hide_tooltip = false"
                @pointerup="hide_tooltip = true"  
            >
            <v-icon :color="'icetimeblue'">mdi-map-legend</v-icon>                       
            </v-btn>

        </template>
        <span>Show Imagery Legend</span>

        </v-tooltip>
            <div v-if="showLegend">
              <v-card-actions class="pa-0 ma-0 pb-1">
                <v-spacer></v-spacer>
                <v-btn depressed x-small color="white" class="pa-0 ma-0" @click="toggleLegend">
                    <v-icon right color="grey">mdi-close</v-icon>
                </v-btn>
              </v-card-actions>         
              <v-card-text class="ma-0 pa-0" >
                  <div v-if="legend.length == 0" class="text-caption"><v-btn depressed x-small color="white"><div class="text-caption">Legend not available</div></v-btn></div>
                  <v-row 
                    v-else
                    no-gutters 
                    class="pa-0"                     
                    v-for="(item, i) in legend"
                    :key="i">
                    <v-btn v-if="item.alpha != '0'" depressed x-small color="white"><v-icon class="legend-icon" :color=rgbstring(item) left>mdi-square</v-icon><div class="text-caption">{{item.name}}</div></v-btn>
                  </v-row>
              </v-card-text>  
            </div>    
        </l-control>
  
    </span>
</template>
  
  <script>
  import { LControl } from 'vue2-leaflet'
  import { mapState } from 'vuex'
  
  export default {
    data() {
      return {
        help: false,
        showForm: false,
        showLegend: false,
        classes: [],
      }
    },
    components: {
      LControl,
    },
    computed: {
      ...mapState('map', {
        visibleLayer: state => state.visibleLayer,
        legend: state => state.legend,
        showHelp: state => state.showHelp,
      }),
      ...mapState('user', {
        user: state => state.user
      }),
      is_mobile() {
        return this.$vuetify.breakpoint.xs ? this.showHelp ? false : true : false
      }         
    },
    created() {
      
    },
    watch: {
      showHelp() {
        this.help = this.showHelp
      },      
    },
    methods: {
      clickCancel() {
        if (this.$refs.apcmarker) {
          this.$refs.apcmarker.mapObject.closePopup() 
        }
      },
      toggleLegend() {
        this.showLegend = !this.showLegend
        this.clickCancel()
      },
      rgbstring(item) {
        return 'rgba(' + item.red + ','+ item.green +',' + item.blue + ',' + item.alpha +')'
      }
    },
    update() {
    },
    mounted() {
      this.clickCancel()
    },
    props: [
      'marker_location'
    ]
  }
  </script>
  
  <style scoped>
    .legend-icon {
      border: outset black;
    }

    .legend-control {
        background: #fff;
        padding: 5px;
        border: 1px solid #aaa;
        border-radius: 5px;
    }  
    .disable-events {
        pointer-events: none
    }
  </style>