<template>
  <v-card
    class="ma-5 pa-4"
  >
    <ComingFall2023Chip />
    <v-card-title>
      Radar Satellite Monitoring
    </v-card-title>
    <v-card-text class="text-body-1">
      Ice Time&trade; leverages the power of side-looking Synthetic Aperture Radar (SAR) satellite images to image open water, ice deformations, and the states of lake ice.
    </v-card-text>
    <v-card-text>
      ( Figure - RCM radar satellite in operation. Figure caption: Ice Time&trade; uses 3 or more satellites to monitor open water areas and ice cover. )
    </v-card-text>
    <v-card-text>
      ( Figure caption: Ice Time&trade; uses 3 or more satellites to monitor open water areas and ice cover. )
    </v-card-text>
    <v-card-actions v-if="!show" class="mt-4 justify-center" @click="show_content">
      <v-btn class="grey--text" rounded small text>
        <v-icon class="mx-2" x-small>fa-angle-double-down</v-icon>
        Scroll for detail
        <v-icon class="mx-2" x-small>fa-angle-double-down</v-icon>
      </v-btn>
    </v-card-actions>
    <span v-else>
      <v-card-text class="text-body-1">
        Radar is excellent at separating land, open water and lake ice types. Radar satellites are considered all weather because they can see at night, through cloud, and in the cold of winter through the snow to the ice! No other type of satellite can do that.
      </v-card-text>
      <v-card-text class="text-body-1">
        The satellite revisit period varies by location but averages about 3 - 4 days. 
      </v-card-text>
      <v-card-text>
        Figure - Open Water, New Ice, Winter, and Melt Onset images here. 
      </v-card-text>
      <v-card-text class="text-body-1">
        You can see the changes over time and make decisions for yourself and your guests!
      </v-card-text>
      <v-card-text>
        ( Figure - set of time series images here. )
      </v-card-text>
      <v-card-text class="text-body-1">
        The images and ice reports published by ice specialists are published to your computer, tablet, and phone and in near real-time, typically less than 12 hours after the satellite overpass.  
      </v-card-text>
      <v-card-text class="text-body-1">
        How do we do it?  Ice TimeTM is the product of our pioneering research on the use radar signals returned from water and lake ice from on ice studies since 2017.
      </v-card-text>
      <v-card-text>
        ( Figure - EOADP on ice images here. )
      </v-card-text>
      <v-card-text class="text-body-1">
        We use powerful computing methods to classify ice types and hazards. Our lake ice classifications were trained and tested on our radar image database of more than 25,000 lakes in Canada and the northern USA with more than 3,000 hours of computing time.
      </v-card-text>
      <v-card-text>
        ( Figure - vector validation maps and inside our AI machine. )
      </v-card-text>
    </span>
  </v-card>
</template>
  
<script>
import ComingFall2023Chip from './ComingFall2023Chip.vue'

export default { 
  components: {
    ComingFall2023Chip
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    show_content() {
      this.show = true
    }
  }
}
</script>
  