<template>
  <v-row>
    <v-col class="d-none d-sm-flex d-lg-none pa-3" sm="3" md="4">
      <v-img
        alt="App logo" 
        contain
        src="img/icetime_logo_transparent.png"
      />
    </v-col>
    <v-col class="d-none d-lg-flex pa-5" lg="4">
      <v-img
        alt="App logo" 
        contain
        src="img/icetime_logo_transparent.png"
      />
    </v-col>
    <v-col align-self="end">
      <v-card :class="$vuetify.breakpoint.smAndDown ? 'mb-4 pa-6 text-body-2':'my-7 pa-6 font-weight-bold'" style="background-color: #C5CAE9;">
        Welcome to the Regional Advisory Service, Ice Time's Free resource that helps you identify what the state of your lake ice is recently. To see the ice hazards on a map, you can subscribe here for the season, or for a short time to help you with your next trip.
      </v-card>
      <v-card
        :class="$vuetify.breakpoint.smAndDown ? 'pa-2':'px-4 py-8'"
        style="background-color: #F5F5F5;"
        width="100%"
      > 
        <v-layout
          align-content-center
          class="px-2"
        >
          <!-- <v-flex align-self-center shrink>
            <v-btn
              class="ml-0 mr-2 my-2 mt-4"
              icon
              :color="geoColor"
              :loading="geoLoading"
              @click.prevent="useGeolocation"
            >
              <v-icon>fa-crosshairs</v-icon>
          </v-btn>
          </v-flex> -->

          <v-flex align-self-center>
            <v-autocomplete
              label="Selected Region"
              class="py-3"
              hide-details
              item-text="name"
              item-value="radar_id"
              placeholder="Search Regions"
              v-model="selected"
              :dense="$vuetify.breakpoint.smAndDown"
              :items="active_regions"
              :loading="entry"
              @change="select_changed"
              prepend-icon="mdi-map"
            />
          </v-flex>
        </v-layout>
        <v-alert
          border="left"
          class="mx-3 mb-0 px-6 mt-4 text-caption" 
          close-icon="mdi-close"
          color="deep-orange"
          colored-border
          dismissible
          transition="slide-y-transition"
          :dense="$vuetify.breakpoint.lgAndDown"
          :value="!entry && selected != '' && matches.length == 0"
        >
          No advisories are available for this region.
        </v-alert>
        <v-alert
          border="left"
          class="mx-3 mb-0 pl-6 mt-4 text-caption" 
          colored-border
          transition="slide-y-transition"
          :color="primary_region_retry ? 'orange':'gray'"
          :dense="$vuetify.breakpoint.lgAndDown"
          :value="selected != '' && primary_region_prompt"
        >
          <v-layout class="pa-3" row>
            <v-flex align-self-center>
              {{ !primary_region_retry ?"Set selected region to primary region?":"An error ocurred. Retry?" }}
            </v-flex>
            <v-flex v-if="primary_region_loading" align-self-center shrink>
              <v-progress-circular 
                indeterminate 
                size="20" 
                small 
                width="2"
              />
            </v-flex>
            <v-flex v-else-if="primary_region_retry" align-self-center class="amber--text" shrink>
              <v-btn icon small @click="update_primary_region"><v-icon color="orange">mdi-autorenew</v-icon></v-btn>
              <!-- <v-btn icon small><v-icon small>mdi-thumb-down</v-icon></v-btn> -->
            </v-flex>
            <v-flex v-else align-self-center shrink>
              <v-btn icon small @click="update_primary_region"><v-icon>mdi-thumb-up</v-icon></v-btn>
              <!-- <v-btn icon small><v-icon small>mdi-thumb-down</v-icon></v-btn> -->
            </v-flex>
          </v-layout>
        </v-alert>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex"
// import states from '../../../../public/advisories_test_data.json'

export default {
  computed: {
    ...mapGetters('user', {
      isLoggedIn: 'isLoggedIn',
    }),
    ...mapState(['regions']),
    ...mapState('ras', {
      advisories: state => state.advisories,
      entry: state => state.entry,
      selected_region: state => state.selected_region
    }),
    ...mapState('user', {
      // geolocation: state => state.geolocation,
      primary_region: state => state.user.primary_region
    }),
    // geoColor() {
    //   return this.geolocation ? '':'deep-orange'
    // },
    matches() {
      // return states.filter(item => item.region_id == this.selected)
      return this.advisories.filter(item => item.region_id == this.selected)
    },
    primary_region_prompt() {
      return this.selected != this.primary_region && this.isLoggedIn
    },
  },
  created() {
    if(this.primary_region) {
      this.selected = this.primary_region
    } else {
      this.selected = '1'
      // this.useGeolocation()
    }
  },
  data() {
    return {
      // geoLoading: false,
      primary_region_loading: false,
      primary_region_retry: false,
      selected: '',
      active_regions: [],
    }
  },
  methods: {
    // location_error() {
    //   // console.log(error)
    //   this.$store.dispatch('user/setGeolocation', 0)
    //   this.$store.dispatch('ras/setEntry', false)
    //   this.geoLoading = false
    // },
    // location_retrieved(location) {
    //   this.$store.dispatch('user/setGeolocation', [location.coords.latitude, location.coords.longitude])
    //   this.$store.dispatch('ras/setEntry', false)
    //   this.$store.dispatch('ras/getAdvisories', this.geolocation)
    //   this.geoLoading = false
    // },
    select_changed() {
      this.primary_region_loading = false
      this.primary_region_retry = false
      this.$store.dispatch('ras/setSelectedRegion', this.selected)
      this.$emit('selected-region-changed')
    },
    update_primary_region() {
      this.primary_region_loading = true
      this.$store.dispatch('user/updatePrimaryRegion', { region: this.selected })
      .then(() => {
        this.primary_region_retry = false
      })
      .catch(() => {
        this.primary_region_retry = true
      })
      .finally(() => {
        this.primary_region_loading = false
      })
    },
    // useGeolocation() {
    //   this.geoLoading = true
    //   navigator.geolocation.getCurrentPosition(this.location_retrieved, this.location_error)
    // },
  },
  mounted() {
    if (this.regions.length == 0) { 
      this.$store.dispatch('getRegion')
      .then(() => {
        this.active_regions = this.regions.filter(region => region.active == 1)        
      })        
    }
    else {
      this.active_regions = this.regions.filter(region => region.active == 1)
    }  
    this.selected = this.primary_region ? this.primary_region:'1'
    this.$store.dispatch('ras/setSelectedRegion', this.selected)
  },
}
</script>

<style scoped>
>>>.v-input__prepend-outer {
  margin-right: 15px;
}
</style>