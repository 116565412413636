import Discovery from './Discovery.vue'

export const DiscoveryRoutes = [
  {
    path: '/discovery',
    component: Discovery,
    meta: {
      // requiresAuth: true,
      // requiresAdmin: true
    },
  },
]