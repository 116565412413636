import { render, staticRenderFns } from "./SpotMarker.vue?vue&type=template&id=13354c7f&scoped=true"
import script from "./SpotMarker.vue?vue&type=script&lang=js"
export * from "./SpotMarker.vue?vue&type=script&lang=js"
import style0 from "./SpotMarker.vue?vue&type=style&index=0&id=13354c7f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13354c7f",
  null
  
)

export default component.exports