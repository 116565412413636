<template>
  <v-flex>
    <v-layout height="100%" row>
      <v-flex xs8 offset-xs2 sm6 offset-sm3 md4 offset-md4 lg2 offset-lg5 pa-5>
        <v-img alt="App logo" src="img/icetime_logo_transparent.png" />
      </v-flex>
    </v-layout>
    <v-layout class="my-8 px-4" row>
      <v-flex align-self-center text-center xs12>
        Logged in as <span class="font-weight-bold mx-1">{{ name }}</span>
      </v-flex>
      <v-flex align-self-center text-right>
        <!-- <ButtonXLarge color="teal" icon="fa-map-marked-alt" text="Available Imagery" route="/regions" /><br /> -->
        <ButtonXLarge color="icetimeblue" icon="fa-exclamation-triangle" text="Regional Advisories" route="/ras" /><br />
        <ButtonXLarge color="amber" icon="fa-graduation-cap" text="Ice Time Academy" route="/academy" /><br />
        <ButtonXLarge color="teal" icon="fa-walking" text="Lake Ice Travel" route="/app/radar" /><br /> 
        <ButtonXLarge color="teal" icon="fa-compass" text="Spot Mapper" route="/app/map" /><br />
        <ButtonXLarge color="teal" icon="fa-medal" text="Leaderboard" route="/app/leaderboard/all"/>
        <!-- <ButtonXLarge color="teal" :disabled="true" icon="fa-award" text="Friendly Competitions" /> -->
      </v-flex>
      <v-divider v-if="!$vuetify.breakpoint.xs" class="mx-6" vertical />
      <v-flex align-self-center text-left>
        <ButtonRegular color="icetimeblue" icon="fa-info-circle" text="Start Tour" route="/discovery" /><br />
        <!-- <ButtonRegular color="icetimeblue" :disabled="true" icon="fa-exclamation-triangle" text="Purchased Images" /><br /> -->
        <ButtonRegular color="teal" icon="fa-map-marked-alt" text="Available Imagery" route="/regions" /><br />
        <ButtonRegular color="amber" icon="fa-tools" text="Admin Dashboard" route="/admin/reports" /><br />
        <ButtonRegular color="icetimeblue" icon="fa-user-cog" text="User Dashboard" route="/user/profile" /><br />
        <ButtonRegular color="icetimeblue" icon="fa-sign-in-alt" text="Logout" @regular-click="logout" />   
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import ButtonRegular from './ButtonRegular.vue'
import ButtonXLarge from './ButtonXLarge.vue'
import { mapState } from 'vuex'

export default {
  components: {
    ButtonRegular,
    ButtonXLarge,
  },
  computed: {
    ...mapState('user', {
      name: state => state.user.alias ? state.user.alias:state.user.email
    }),
  },
  data() {
    return {

    }
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout')
      // .then(() => {
      //   this.$router.push('/')
      //   this.$router.go()
      // })
    },
  }
}
</script>
