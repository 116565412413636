<template>
    <v-container>
        <h1 class="pa-4">Edit Teams</h1>
        <v-card>
            <v-container class="blue lighten-4">
            <v-row justify="end">
            <v-btn 
                @click="backToComp"
                >
                Back To Competition
            </v-btn>
            </v-row>
            <v-card
                class="mt-5 mb-5 pa-1 blue lighten-4"
                :elevation="0"
                >
                <v-row>
                <v-col class="ma-2 flex-grow-1" cols="12">
                    <v-card outlined class="teamitem pa-4 "
                        v-for="(temp, i) in comp_teams"
                        :key="i">
                    <v-row class="justify-center d-flex align-center " width="100%">
                        <v-col class="justify-center" cols="2">
                            <v-card-title class="black--text text-center"
                            align-self="center"
                            >
                                Team Name
                            </v-card-title>
                        </v-col>
                        <v-col class="justify-center" cols="2">
                            <v-row>
                                <v-btn class="ma-2 justify-center"
                                color="red lighten-2">
                                    Remove Team
                                </v-btn>
                                
                            </v-row>
                            <v-row>
                                <v-btn class="ma-2 justify-center"
                                color="blue lighten-1">
                                    Rename Team
                                </v-btn>
                                
                            </v-row>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-row>
                                <v-card-subtitle>
                                    Member Name
                                </v-card-subtitle>
                                
                            </v-row>
                            <v-row>
                                <v-card-subtitle>
                                    Member Name
                                </v-card-subtitle>
                                
                            </v-row>
                            <v-row>
                                <v-card-subtitle>
                                    Member Name
                                </v-card-subtitle>
                                
                            </v-row>
                        </v-col>
                        <v-col class="justify-center" cols="1">
                            <v-row>
                                <v-btn class="ma-2 justify-center" color="deep-orange lighten-2">
                                    Kick
                                </v-btn>
                                
                            </v-row>
                            <v-row>
                                <v-btn class="ma-2 justify-center" color="deep-orange lighten-2">
                                    Kick
                                </v-btn>
                                
                            </v-row>
                            <v-row>
                                <v-btn class="ma-2 justify-center" color="deep-orange lighten-2">
                                    Kick
                                </v-btn>
                                
                            </v-row>
                        </v-col>
                        <v-col class="justify-center" cols="1">
                            <v-row>
                                <v-btn class="ma-2 justify-center" color="red lighten-1">
                                    ban
                                </v-btn>

                            </v-row>
                            <v-row>
                                <v-btn class="ma-2 justify-center" color="red lighten-1">
                                    ban
                                </v-btn>
                                
                            </v-row>
                            <v-row>
                                <v-btn class="ma-2 justify-center" color="red lighten-1">
                                    ban
                                </v-btn>
                                
                            </v-row>
                        </v-col>
                        <v-col class="justify-center" cols="2">
                            <v-row>
                                <v-btn class="ma-2 justify-center" color="green lighten-2">
                                    accept
                                </v-btn>
                                
                            </v-row>
                            <v-row>
                                <v-btn class="ma-2 justify-center" color="green lighten-2">
                                    accept
                                </v-btn>
                                
                            </v-row>
                            <v-row>
                                <v-btn class="ma-2 justify-center" color="green lighten-2">
                                    accept
                                </v-btn>
                                
                            </v-row>
                        </v-col>
                    </v-row>
                    </v-card>
                </v-col>
                </v-row>
            </v-card>
            </v-container>
        </v-card>
    </v-container>
    </template>
    
    <script>
    import { mapState } from 'vuex';
    
    export default {
        components:{
        },
        computed: {
            ...mapState('leaderboard', {
                comp_teams: state => state.comp_teams,
                team_list: state => state.team_list,
            })
        },
        data() {
            return {
                fixed_teams:[],
            }
        },
        methods: {
            backToComp(){
                this.$store.page = 'selected_comp'
                this.$emit('change-page')
            }
        },
        mounted() {
            this.$store.dispatch('leaderboard/getCompTeams')
            this.$store.dispatch('leaderboard/getTeamList')
            //** TODO make the fixing of team list ocure after the responce of team list?
            // console.log(this.team_list)

            var temp_id = null
            var team = {}
            var current_member = {}
            this.team_list.forEach(item => {
                if(item.id !== temp_id){
                    team = {
                        id: item.id,
                        name: item.name,
                        members:[],
                    }
                    current_member = {
                        member_id: item.member,
                        alias: item.alias,
                    }
                    team.members.push(current_member)
                    this.fixed_teams.push(team)
                    temp_id = item.id
                    // console.log(temp_id)
                } else if(current_member.member_id !== item.member) {
                    current_member = {
                        member_id: item.member,
                        alias: item.alias,
                    }
                    team.members.push(current_member)
                }
            })
            // when we know better sort by we can sort at beginning?
            // console.log(this.fixed_teams)
        }
    }
    </script>
    
    <style scoped>
    >>>.col{
        padding: 12px;
        padding-bottom: 0px;
        padding-top: 0px;

    }
    >>>.card-title{
        padding: 0px;
        padding-bottom: 0px;
        padding-top: 0px;
        margin: 0px;
    }
    >>>.teamitem{
        border-color: black;
        border-width: 2px;
    }
    </style>