<template>
  <v-card
    class="ma-5 pa-4"
  >
    <ComingSoonChip />
    <v-card-title>
      Local Services
    </v-card-title>
    <v-card-text class="text-body-1">
      Use a map to make your plan and improve your outdoor experience.  Locate your lodge or the precise spot of your ice hut rental on a map. It’s easy, free, and just a click away!
    </v-card-text>
  </v-card>
</template>
  
<script>
import ComingSoonChip from './ComingSoonChip.vue';

export default {
  data() {
      return {};
  },
  methods: {},
  components: { ComingSoonChip }
}
</script>
  