<template>
<v-container class="ma-0 pa-0">
    <!-- <v-card id="add-top" width="80%" class="d-none d-sm-block">
        <v-img contain src="/img/The_Fishin_Hole_Banner.png" max-height="130px"/>
    </v-card>
    <v-card id="add-top" width="80%" class="d-sm-none">
        <v-img contain src="/img/The_Fishin_Hole_Banner.png" max-height="100px"/>
    </v-card>

    <div id="add-left-div" class="d-none d-md-block">
        <v-img contain src="/img/The_Fishin_Hole_Catalog.png" height="100%"/>
    </div>

    <div id="add-right-div" class="d-none d-md-block">
        <v-img contain src="/img/The_Fishin_Hole_Catalog.png" height="100%"/>
    </div> -->
    
    <!-- <v-btn
    @click="testmeasure()">
        Test
    </v-btn> -->
    <v-card class="blue lighten-5 mt-5 mb-16 pb-3">
            <v-row align="center" class="ml-0 pl-0">
                <v-col cols="9">
                    <v-card-title class="pb-0 mb-0 pl-2 pt-2">
                        <p class="mb-0 pb-0 font-weight-bold text-h5">Angler of the Year</p>
                    </v-card-title>
                    <p class="my-0 py-0 pl-2 text-subtitle-1">Regional Leaderboard</p>
                </v-col>
                <v-col cols="3" class="text-right pr-8">
                    <v-btn v-if="!$vuetify.breakpoint.xs"
                        @click="updateCatches()"
                        :disabled="button_disabled">
                        Reload<v-icon right>mdi-refresh</v-icon>
                    </v-btn>
                    <v-btn v-else
                        @click="updateCatches()"
                        :disabled="button_disabled"
                        fab
                        small><v-icon>mdi-refresh</v-icon>
                    </v-btn>                    
                </v-col>
            </v-row>    
        <v-container>        
            <v-row>
                <v-col cols="16" sm="3" class="mb-0 pb-0">
                    <v-select 
                        :items="test_dates"
                        v-model="date"
                        solo
                        read-only
                        dense
                        @change="filter_table"
                        prepend-icon="mdi-calendar-range"
                        hide-details
                    >
                    </v-select>
                </v-col>
                <v-col cols="16" sm="3" class="mb-2">
                    <v-select 
                        :items="region_list"
                        item-text="name"
                        item-value="id"
                        v-model="region"
                        solo
                        read-only
                        dense
                        @change="filter_table"
                        prepend-icon="mdi-map"
                        hide-details
                    >   
                    </v-select >
                </v-col>
                <!-- <v-col cols="16" sm="3" class="pt-0" offset-sm="3">
                    <v-btn
                    class="blue">
                        Join Now!
                    </v-btn>
                </v-col> -->
                <!-- <p>{{ Object.values(regions) }}</p> -->                
            </v-row>

                <!-- <v-col>
                    <v-btn 
                        @click="test"
                    >
                        {{ metric? "Metric": "Imperial" }}
                    </v-btn>
                </v-col> -->

            <v-data-table
                v-if="mobile_fullscreen"
                :mobile-breakpoint="0"
                :headers="mobile_headers"
                :items="itemsWithIndex"
                class="mt-2 elevation-1"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"    
                :hide-default-footer="true"
                :single-expand="true"
                @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            >
                <template v-slot:item.alias="{ item }">
                    <div v-if="item.alias.length > 12">{{ item.alias.substring(0,12) }}...</div>
                    <div v-else>{{ item.alias }}</div>
                </template>  
                <template v-slot:item.score="{ item }">
                    <div>{{ Math.round(item.score) }}</div>
                </template>  
                <template v-slot:item.image="{ item }">
                    <!-- <v-img :src="(image_start + item)" max-height="50" max-width="50"/> -->
                    <v-avatar class="my-2">
                        <v-img        
                            v-if="item.profile_image"        
                            :src="(image_start + item.profile_image)" 
                            alt="Avatar"
                        />
                        <v-icon v-else large>mdi-account</v-icon>
                    </v-avatar>
                    <!-- <v-img v-else src="$apiEndpointUrl + 'profile_image/532profile.jpeg'" max-height="50" max-width="50"/> -->
                    <!-- <v-icon v-else large >mdi-account-box-outline</v-icon> -->

                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td class="expand-panel" :colspan="headers.length">
                        <v-row class="py-2">
                            <v-col cols="6">
                                <v-card-text class="py-0">
                                    <div class="text-caption font-weight-bold">Unique Species</div>
                                    <p class="capitalize text-body-1">{{ item.caught_species }}</p>                            
                                </v-card-text>   
                            </v-col>  
                            <v-col cols="6">
                                <div class="text-caption font-weight-bold">View Profile</div>
                                <v-btn @click="showCard(item)" icon small>
                                    <v-icon>mdi-card-account-details-outline</v-icon>
                                </v-btn>
                            </v-col>  
                        </v-row>
                        <v-row class="pb-2" no-gutters>
                            <v-col cols="6">
                                <v-card-text class="py-0">
                                    <div class="text-caption font-weight-bold">Catch Score</div>
                                    <p v-if="item.catch_score" class="text-body-1">{{ item.catch_score }}</p>  
                                    <p v-else class="text-body-1">-</p>                          
                                </v-card-text>   
                            </v-col>  
                            <v-col cols="6">
                                <v-card-text class="py-0">
                                    <div class="text-caption font-weight-bold">Diversity</div>
                                    <v-chip class="text-body-1" :color="getColor(item.diversity)" dark>{{ item.diversity }}</v-chip>
                                </v-card-text>   
                            </v-col>  
                        </v-row>
                        <v-row class="pb-2" no-gutters>
                            <v-col cols="6">
                                <v-card-text class="py-0">
                                    <div class="text-caption font-weight-bold">Comp. Points</div>
                                    <p v-if="item.comp_points" class="text-body-1">{{ item.comp_points }}</p>  
                                    <p v-else class="text-body-1">-</p>                          
                                </v-card-text>   
                            </v-col>  
                            <v-col cols="6">
                                <v-card-text class="py-0">
                                    <div class="text-caption font-weight-bold">Total Score</div>
                                    <p v-if="item.score" class="text-body-1">{{ item.score }}</p>  
                                    <p v-else class="text-body-1">-</p>                          
                                </v-card-text>   
                            </v-col>  
                        </v-row>
                        
                    </td>
                </template>
            </v-data-table>

            <v-data-table
                v-else
                dense
                :headers="headers"
                :items="fixed_leaderboard"
                :items-per-page="10"
                class="elevation-1 mt-5"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"        
                @click:row="showCard"
                
            >
                <template v-slot:item.image="{ item }">
                    <!-- <v-img :src="(image_start + item)" max-height="50" max-width="50"/> -->
                    <v-avatar class="ma-2">
                        <v-img         
                            v-if="item.profile_image"        
                            :src="(image_start + item.profile_image)" 
                            alt="Avatar"/>
                        <v-icon v-else large>mdi-account</v-icon>
                    </v-avatar>
                    <!-- <v-img v-else src="$apiEndpointUrl + 'profile_image/532profile.jpeg'" max-height="50" max-width="50"/> -->
                    <!-- <v-icon v-else large >mdi-account-box-outline</v-icon> -->

                </template>
                <template v-slot:item.diversity="{ item }">
                    <v-chip
                        :color="getColor(item.diversity)"
                        dark
                    >
                        {{ item.diversity }}
                    </v-chip>
                </template>
            </v-data-table>
            <!-- <v-row
            class="pa-0" image_start: 
            justify="center">
                <v-col md="1" cols="1"
                class="text-center rounded-sm ma-0 text-md-h6 text-caption font-weight-bold">
                    #
                </v-col>
                <v-col md="2" cols="2" align-self="center"
                class="text-center rounded-sm ma-0 text-md-h6 text-caption font-weight-bold">
                    Alias
                </v-col>
                <v-col md="2" cols="3" align-self="center"
                class="text-center rounded-sm ma-0 text-md-h6 text-caption font-weight-bold">
                    Top Length
                </v-col>
                <v-col md="2" cols="3" align-self="center"
                class="text-center rounded-sm ma-0 text-md-h6 text-caption font-weight-bold">
                    Top Weight
                </v-col>
                <v-col md="2" cols="3" align-self="center"
                class="text-center rounded-sm ma-0 text-md-h6 text-caption font-weight-bold">
                    Total Catches
                </v-col>
                <v-col md="2" cols="3" align-self="center"
                class="text-center rounded-sm ma-0 text-md-h6 text-caption font-weight-bold">
                    Points
                </v-col>
            </v-row>
            <v-row 
            class="pa-0" 
            justify="center"
            v-for="(person, k) in this.fixed_leaderboard"
            :key="k" >
                <v-col md="1" cols="1"
                class="text-center rounded-sm ma-1">
                    {{ k + 1 }}
                </v-col>
                <v-col md="2"
                class="white text-center rounded ma-1">
                    {{ person.alias }}
                </v-col>
                <v-col md="2"
                class="white text-center rounded ma-1">
                    {{ metric? (person.length * 2.54) + " cm." : person.length + " in." }}
                </v-col>
                <v-col md="2"
                class="white text-center rounded ma-1">
                    {{ metric? (person.weight * 0.453592) + " kg." : person.weight + " lb." }}
                </v-col>
                <v-col md="2"
                class="white text-center rounded ma-1">
                    {{  person.catches }}
                </v-col>
                <v-col md="2"
                class="white text-center rounded ma-1">
                    {{ person.points}}
                </v-col>
            </v-row> -->
        </v-container>
    </v-card>
    <!-- <v-card id="add-bot" width="74%" class="d-none d-sm-block mt-4">
        <v-img contain src="/img/The_Fishin_Hole_Banner.png" max-height="130px"/>
    </v-card> -->
    <!-- Show only on small screens -->
    <!-- <v-card id="add-bot-sm" width="72%" class="d-sm-none mt-4">
        <v-img contain src="/img/The_Fishin_Hole_Banner.png" max-height="150px"/>
    </v-card> -->

    <v-dialog v-model="image_overlay" max-width="1000">

        <v-card class="pa-4">
            <v-row >
                <v-spacer></v-spacer>
                <v-card-actions class="pb-0">
                    <v-icon
                        large
                        @click="image_overlay = false"
                    >mdi-close-circle
                    </v-icon>                
                </v-card-actions>
            </v-row>

            <v-row>            
                <v-col cols="12" sm="4">
                    <v-card-text class="text-center">         
                        <v-avatar class="ma-2 justify-center" size="128">
                            <v-img         
                                v-if="current_avatar"        
                                :src="(image_start + current_avatar)" 
                                alt="Avatar"/>
                            <v-icon v-else large>mdi-account</v-icon>
                        </v-avatar> 
                        <v-card-title class="justify-center"><h2>{{ card_name }}</h2></v-card-title>

                        <v-row no-gutters>
                            <v-col>
                                Joined: {{ card_date }}
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col>
                                Regions: {{ card_region }}
                            </v-col>
                        </v-row>  
                    </v-card-text> 
                </v-col>   
                <v-col cols="12" sm="8">
                    <v-card class="pb-4" :height="info_height" outlined>
                        <v-tabs
                            fixed-tabs
                            bg-color="primary"
                            class="mb-5"
                        >
                            <v-tab v-if="!$vuetify.breakpoint.xs">Competitions</v-tab>
                            <v-tab v-else><v-icon>mdi-trophy-outline</v-icon></v-tab>
                            <v-tab v-if="!$vuetify.breakpoint.xs">Catches</v-tab>
                            <v-tab v-else><v-icon>mdi-fish</v-icon></v-tab>
                        
                            <v-tab-item class="mt-4" align="center">
                                <v-row align="center">
                                    <v-col cols="12" sm="6" class="d-flex">
                                        <v-card-text class="py-0">
                                            <div>Total Completed Competitions</div>
                                            <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card.total }}</p>
                                            <p v-else :class="font_size_value">-</p>                                            
                                        </v-card-text>                    
                                    </v-col>

                                    <v-col cols="12" sm="6">
                                        <v-row>
                                            <v-col cols="4" sm="12">
                                                {{ profile_card.firsts }}
                                                <v-icon color="yellow" large>mdi-trophy</v-icon>
                                                <nobr v-if="!$vuetify.breakpoint.xs"> 1st Place Finishes</nobr>
                                            </v-col>
                                            <v-col cols="4" sm="12">
                                                {{ profile_card.seconds }}
                                                <v-icon color="grey" large>mdi-trophy</v-icon>
                                                <nobr v-if="!$vuetify.breakpoint.xs"> 2nd Place Finishes</nobr>
                                            </v-col>
                                            <v-col cols="4" sm="12">
                                                {{ profile_card.thirds }}
                                                <v-icon color="brown" large>mdi-trophy</v-icon>
                                                <nobr v-if="!$vuetify.breakpoint.xs"> 3rd Place Finishes</nobr>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <v-row class="mt-4 mx-4">
                                    <v-autocomplete
                                        item-text="common_name"
                                        item-value="id"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        label="Species"
                                        v-model="selected_species"
                                        :items="species"
                                        return-object
                                        :onchange="getCardSpeciesInfo()"
                                    ></v-autocomplete>                                    
                                </v-row>

                                <v-row>
                                    <v-col cols="6" sm="6">
                                        <v-card-text class="py-0">
                                            <div>Total Caught</div>
                                            <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card_species.total }}</p>
                                            <p v-else :class="font_size_value">0</p>                                            
                                        </v-card-text>                    
                                    </v-col>
                                    <v-col cols="6" sm="6">
                                        <v-card-text class="py-0">
                                            <div>Longest Caught</div>
                                            <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card_species.longest }} cm</p>
                                            <p v-else :class="font_size_value">-</p>                        
                                        </v-card-text>   
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="6" sm="6">
                                        <v-card-text class="py-0">
                                            <div>Heaviest Caught</div>
                                            <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card_species.heaviest }} lbs.</p>
                                            <p v-else :class="font_size_value">-</p>                                            
                                        </v-card-text>                    
                                    </v-col>
                                    <v-col cols="6" sm="6">
                                        <v-card-text class="py-0">
                                            <div>Average Caught per Fishing Day</div>
                                            <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ Math.round((profile_card_species.total/profile_card_species.days)*100) / 100 }}</p>
                                            <p v-else :class="font_size_value">-</p>                        
                                        </v-card-text>   
                                    </v-col>
                                </v-row>                                
                            </v-tab-item>
                        </v-tabs>                          
                    </v-card>
                </v-col>    
                <v-col cols="12" sm="4">
                    <v-card class="pb-4" :height="bio_height" outlined>
                        <v-card-title class="pb-0">
                            <h5>Bio</h5>  
                            <v-card-text class="px-0">{{ card_bio }}</v-card-text>                          
                        </v-card-title>
                    </v-card>
                </v-col>          
                <v-col cols="12" sm="8">
                    <v-card class="pb-4" height="200px" outlined>
                        <v-card-title class="pb-0">
                            <h5>Badges</h5>   
                        </v-card-title>
                        <!-- <v-row class="justify-center py-8">
                            <v-slide-group class="pa-1" show-arrows>
                                <v-slide-item><v-img contain src="/img/test_badge.png" max-height="100px" max-width="100px"/></v-slide-item>
                                <v-slide-item><v-img contain src="/img/badge2.png" max-height="100px" max-width="100px"/></v-slide-item>
                                <v-slide-item><v-img contain src="/img/badge3.png" max-height="100px" max-width="100px"/></v-slide-item>
                                <v-slide-item><v-img contain src="/img/badge4.png" max-height="100px" max-width="100px"/></v-slide-item>
                                <v-slide-item><v-img contain src="/img/badge5.png" max-height="100px" max-width="100px"/></v-slide-item>                      
                            </v-slide-group>
                        </v-row> -->
                    </v-card>
                </v-col>                       
            </v-row>
            
        </v-card>

        <!-- <v-card class="pb-4 blue lighten-1 mx-auto">
            <v-row class="pa-5 pb-0" no-gutters>
                <v-col class="" cols="4">
                    <v-card class="blue lighten-3 mb-4">

                        <v-avatar class="ma-2" size="256">
                            <v-img         
                                v-if="current_avatar"        
                                :src="(image_start + current_avatar)" 
                                alt="Avatar"/>
                            <v-icon v-else large>mdi-account</v-icon>
                        </v-avatar>                       

                    </v-card>
                    <v-card class="blue lighten-3 pa-1" height="100" width="230">
                        <v-row class="pa-0 ma-0 mb-2" no-gutters v-if="card_name">
                            <v-col id="underline" class="text-center"> {{ card_name }}</v-col>
                        </v-row>
                        <v-row class="pa-0 ma-0 mb-2" no-gutters v-if="card_date">
                            <v-col id="underline" class="text-center">Joined {{ card_date }}</v-col>
                        </v-row>
                        <v-row  class="pa-0 ma-0" no-gutters v-if="card_region">
                            <v-col id="underline" class="text-center ">Regions: {{ card_region }}</v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col class="ml-auto" cols="8">
                    <v-card class="blue lighten-3 pa-1 mb-4" height="180">
                        <v-row id="underline" class="pa-0 ma-0 mb-2" no-gutters justify="space-around">
                            <v-col class="text-center" cols="4">
                                <v-select
                                class="pa-0 ma-0 text-center"
                                width="60"
                                solo
                                dense
                                outlined
                                hide-details="auto"
                                v-model="card_mode"
                                :items="mode_options"
                                single-line
                                ></v-select>
                            </v-col>
                            <v-col v-if="card_mode===2" class="text-center" cols="4">
                            <v-autocomplete
                                class="pa-0 ma-0 text-center"
                                width="60"
                                solo
                                dense
                                outlined
                                item-text="common_name"
                                item-value="id"
                                hide-details="auto"
                                v-model="selected_species"
                                :items="species"
                                single-line
                                return-object
                                :onchange="getCardSpeciesInfo()"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <div v-if="card_mode===1">
                            <v-row class="pa-0 ma-0 mb-2" no-gutters>
                            <v-col id="underline" class="text-center">{{ profile_card.total }} Total Compleated Competitions</v-col>
                            </v-row>
                            <v-row class="pa-0 ma-0 mb-2" no-gutters>
                                <v-col id="underline" class="text-center">{{ profile_card.firsts }} First Place Finishes</v-col>
                            </v-row>
                            <v-row class="pa-0 ma-0 mb-2" no-gutters>
                                <v-col id="underline" class="text-center">{{ profile_card.seconds }} Second Place Finishes</v-col>
                            </v-row>
                            <v-row  class="pa-0 ma-0" no-gutters>
                                <v-col id="underline" class="text-center">{{ profile_card.thirds }} Third Place Finishes</v-col>
                            </v-row>
                        </div>
                        
                        <div v-if="card_mode===2 && selected_species!==null">
                            <v-row class="pa-0 ma-0 mb-2" no-gutters>
                                <v-col id="underline" v-if="profile_card_species.total>0" class="text-center">{{ profile_card_species.total }} Caught Total</v-col>
                                <v-col id="underline" v-else class="text-center">0 Caught Total</v-col>
                            </v-row>
                            <v-row class="pa-0 ma-0 mb-2" no-gutters>
                                <v-col id="underline" v-if="profile_card_species.total>0" class="text-center">Longest: {{ profile_card_species.longest }} in.</v-col>
                                <v-col id="underline" v-else class="text-center">Longest: 0 in.</v-col>
                            </v-row>
                            <v-row class="pa-0 ma-0 mb-2" no-gutters>
                                <v-col id="underline" v-if="profile_card_species.total>0" class="text-center">Heaviest: {{ profile_card_species.heaviest }} lbs.</v-col>
                                <v-col id="underline" v-else class="text-center">Heaviest: 0 lbs.</v-col>
                            </v-row>
                            <v-row  class="pa-0 ma-0" no-gutters>
                                <v-col id="underline" v-if="profile_card_species.total>0" class="text-center">{{ Math.round((profile_card_species.total/profile_card_species.days)*100) / 100 }} Caught per Fishing Day</v-col>
                                <v-col id="underline" v-else class="text-center">No {{ profile_card_species.common_name }} Caught</v-col>
                            </v-row>
                        </div>
                    </v-card>
                    <v-card class="blue lighten-3 pa-1" height="150">
                        <v-card-text v-if="card_bio" id="input">
                            {{ card_bio }}
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="pa-5 pb-0" no-gutters>
                <v-col>
                    <v-card class="blue lighten-3 mb-4" height="100">
                        <v-slide-group
                        
                        show-arrows
                        >
                        <v-slide-item
                        >
                        <v-img contain src="/img/test_badge.png" max-height="100px" max-width="100px"/>
                        </v-slide-item>
                        <v-slide-item
                        >
                        <v-img contain src="/img/badge2.png" max-height="100px" max-width="100px"/>
                        </v-slide-item>
                        <v-slide-item
                        >
                        <v-img contain src="/img/badge3.png" max-height="100px" max-width="100px"/>
                        </v-slide-item>
                        <v-slide-item
                        >
                        <v-img contain src="/img/badge4.png" max-height="100px" max-width="100px"/>
                        </v-slide-item>
                        <v-slide-item
                        >
                        <v-img contain src="/img/badge5.png" max-height="100px" max-width="100px"/>
                        </v-slide-item>
                        </v-slide-group>
                    </v-card>
                </v-col>
            </v-row>
            <div class="text-center">
                <v-btn
                    id="center"
                    class="white--text mt-2"
                    color="red darken-2"
                    @click="image_overlay = false"
                >
                    Close
                </v-btn>
                </div>
        </v-card> -->

        </v-dialog>
</v-container>
</template>

<script>
    import { mapState } from 'vuex';

    export default{
    data(){
        return {
            metric: false,
            date:"Live",
            region:"0",
            test_dates: ["Live", "2023", "2022", "2021", "2020", "2019"],
            region_list: [{"id": "0", "name": "All Regions"}],
            fixed_leaderboard:[],
            region_id: '0',
            season_id: '1',
            image_start: this.$apiEndpointUrl + 'profile_image/',

            sortBy: 'score',
            sortDesc: true,
            mobile_headers: [
                // { text: '', value: 'index'},
                { text: 'User', value: 'image', align: 'start', width: "10%" },
                { text: '', value: 'alias', align: 'left' },
                { text: 'Score', value: 'score', align: 'right' },
            ],
            headers: [
                { text: '', align: 'start', value: 'image' },
                { text: 'User', value: 'alias' },
                { text: 'Unique Species', value: 'caught_species' },
                { text: 'Catch Score', value: 'catch_score' },
                { text: 'Diversity (%)', value: 'diversity' },
                { text: 'Comp Bonus', value: 'comp_points' },
                { text: 'Score', value: 'score' },
            ],
            image_overlay:false,
            zIndex: 1,
            join_date: null,

            card_mode: 1,
            mode_options: [
                { text: 'Competition', value: 1},
                { text: 'Species', value: 2},
            ],
            card_name: null,
            card_date: null,
            card_region: null,
            card_bio: null,
            selected_species: null,
            current_species: null,
            current_avatar: null,
            loading: false,
            button_disabled: false,
            tab: null
        }
    },
    components:{

    },
    computed: {
        ...mapState('leaderboard', {
            live_leaderboard: state => state.live_leaderboard,
            regions: state => state.regions,
        }),
        ...mapState('user',{
            user: state => state.user,
            profile_card: state => state.profile_card,
            profile_card_species: state => state.profile_card_species,
        }),
        ...mapState('spot_mapper',{
            species: state => state.species,
        }),
        font_size_value(){
            return !this.$vuetify.breakpoint.xs ? "text-h5" : "text-h6"
        },       
        info_height(){
            return !this.$vuetify.breakpoint.xs ? "300px" : "310px"
        },            
        bio_height(){
            return !this.$vuetify.breakpoint.xs ? "200px" : "auto"
        },
        mobile_fullscreen() {
            return !this.$vuetify.breakpoint.mobile ? false : true
            // return true
        }, 
        itemsWithIndex() 
        {  return this.fixed_leaderboard.map(
            (items, index) => ({
            ...items,
            index: index + 1
            }))
        }
    },
    methods: {
        filter_table(){            
            var season = this.test_dates.indexOf(this.date.toString()).toString()
            var region = this.region.toString()
            this.fixed_leaderboard = JSON.parse(JSON.stringify(this.live_leaderboard))   
            if (season == 0) {
                if (region != 0) {
                    this.fixed_leaderboard = this.fixed_leaderboard.filter((item) => item.region_id == region)
                }
            }    
            else {
                if (region != 0) {
                    this.fixed_leaderboard = this.fixed_leaderboard.filter((item) => item.season_id == season && item.region_id == region)
                }
                else {
                    this.fixed_leaderboard = this.fixed_leaderboard.filter((item) => item.season_id == season)  
                }
            } 
            this.fixed_leaderboard = this.fixed_leaderboard.map((item) => {
                item.diversity = (Math.round(((item.diversity*100) + Number.EPSILON) * 100) / 100)
                item.comp_points = (Math.round(((Number(item.comp_points)) + Number.EPSILON) * 100) / 100)
                item.score = (Math.round(((Number(item.score)) + Number.EPSILON) * 100) / 100)
                return item
            })              
        },
        
        getCardSpeciesInfo(){
            if(this.selected_species!==null && !this.loading){
                if(this.current_species!==this.selected_species.id){
                    this.loading = true
                    let user = this.user.id
                    let species = this.selected_species.id
                    this.$store.dispatch('user/getProfileCardSpecies', { user, species})
                    .then(() => {
                        // console.log(this.profile_card_species)
                        this.current_species = this.selected_species.id
                        this.loading = false
                    })
                }
            }
        },
        test(){
            if(this.image_overlay){
                // console.log(this.image_overlay)
            
                // this.image_overlay = false
            }
            
            // this.metric = !this.metric
        },
        getColor (value) {
            if (value > 50) return 'green'
            else if (value > 20) return 'orange'
            else return 'red'
        },
        showCard(row){

            this.fixed_leaderboard.map((item, index) => {
                item.selected = item === row

                this.$set(this.fixed_leaderboard, index, item)
            })

            this.selected_species = null
            this.current_species = null
            this.card_mode = 1
            this.$store.dispatch('user/getProfileCard', row.user_id)
            .then(() => {
                this.card_name = this.profile_card.profile.name
                this.card_date = new Date(this.profile_card.profile.tos_accepted).toLocaleDateString('en-us', { month:"long", day:"numeric", year:"numeric"})
                this.card_region = this.profile_card.profile.region
                this.card_bio = this.profile_card.profile.bio

                this.$store.dispatch('spot_mapper/getSpecies')
                .then(() => {
                    // console.log(this.species)
                    this.image_overlay = true
                })
            })
            this.current_avatar = row.profile_image
        },
        updateCatches(){
            this.button_disabled = true
            let region = this.region_id
            let season = this.season_id
            this.fixed_leaderboard = []
            
            this.$store.dispatch('leaderboard/getLiveLeaderboard', { region, season}).then(() => {

                this.live_leaderboard.forEach(item => {
                    var row = {
                            id: item.id,
                            user_id: item.user_id,
                            alias: item.alias,
                            profile_image: item.profile_image,
                            caught_species: item.caught_species,
                            catch_score: item.catch_score,
                            diversity: (Math.round(((item.diversity*100) + Number.EPSILON) * 100) / 100), 
                            comp_points: (Math.round(((Number(item.comp_points)) + Number.EPSILON) * 100) / 100),
                            score: (Math.round((((Number(item.catch_score)) * (1 + Number(item.diversity))) + (Number(item.comp_points)) + Number.EPSILON) * 100) / 100),
                            // score: (Math.round(((Number(item.score)) + Number.EPSILON) * 100) / 100),
                        }
                    this.fixed_leaderboard.push(row)
                })

                // console.log(this.fixed_leaderboard)
            })
            .finally(() => {
                setTimeout(()=> {
                    this.button_disabled = false
                }, 3000)
            })
        },
        testmeasure() {
            let data = {
                        id: '532',
                    }
            this.$store.dispatch('spot_mapper/measure', data)
        }
        // changePage(page){
        //     this.$store.page = page
        //     // console.log(this.$store.page)
        //     this.$emit('change-page')
        //     // vm.$forceUpdate()
        // },
        // expand(){
        //     if(!this.expanded){
        //         this.panel = [...Array(this.panels).keys()].map((k,i) => i)
        //     }else{
        //         this.panel = []
        //     }
        //     this.expanded = !this.expanded
        // }
    },
    mounted() {
        let region = this.region_id
        let season = this.season_id
        // this.fixed_leaderboard = []
        this.$store.dispatch('leaderboard/getLiveLeaderboard', { region, season}).then(() => {
            this.live_leaderboard.forEach(item => {
                var row = {
                        id: item.id,
                        user_id: item.user_id,
                        alias: item.alias,
                        profile_image: item.profile_image,
                        caught_species: item.caught_species,
                        catch_score: item.catch_score,
                        diversity: (Math.round(((item.diversity*100) + Number.EPSILON) * 100) / 100), 
                        comp_points: (Math.round(((Number(item.comp_points)) + Number.EPSILON) * 100) / 100),
                        score: (Math.round((((Number(item.catch_score)) * (1 + Number(item.diversity))) + (Number(item.comp_points)) + Number.EPSILON) * 100) / 100),
                    }
                    // score: (Math.round((((Number(item.catch_score)) * Number((1 + item.diversity))) + (Number(item.comp_points)) + Number.EPSILON) * 100) / 100),
                this.fixed_leaderboard.push(row)
            })
            // console.log(this.fixed_leaderboard)
        })
        this.$store.dispatch('leaderboard/getRegions').then(() => { 
            Object.values(this.regions).forEach((item) => {
                this.region_list.push(item)
            })
        })

        // let selected_user = this.user.id
        // this.$store.dispatch('user/getProfileCard', selected_user)
        // .then(() => {
        //     // console.log(this.profile_card)
        //     this.card_name = this.profile_card.profile.name
        //     this.card_date = new Date(this.profile_card.profile.tos_accepted).toLocaleDateString('en-us', { month:"long", day:"numeric", year:"numeric"})
        //     this.card_region = this.profile_card.profile.region
        //     this.card_bio = this.profile_card.profile.bio

        //     this.$store.dispatch('spot_mapper/getSpecies')
        //     .then(() => {
        //         // console.log(this.species)
        //     })
        // })

        this.join_date = new Date(this.user.tos_accepted).toLocaleDateString('en-us', { month:"long", day:"numeric", year:"numeric"})
        //*This is to view the url Data Params, and will be used for the Bumb Board testing*
        let urlParams = new URLSearchParams(window.location.search);
        let myParam = urlParams.get('page');
        // console.log(myParam)
        if(myParam === "mycomps"){
                this.$store.page = 'join_list'
                this.$emit('change-page')
        }
        if(myParam === "regional"){
                // console.log("You got here from the QR code!")
        }
        if(myParam === "test"){
            this.$store.page = 'admin_leaderboard'
            this.$emit('change-page')
        }
        //**TODO Need to get list seasons to populate dropdowns.
    }
}

</script>
<style scoped>
/* Should zebra stripe leaderboard */
>>>tr:nth-child(even){
    background-color: #90CAF9;
}
>>>.container-class {
  position: relative;
}
>>>#add-left {
    position: absolute;
    top:25%;
    left: -10px;
    border: none;
    
}
>>>#add-left-div {
    position: absolute;
    height: 50%;
    width: 9%;
    top:25%;
    left: .2%;
    border: none;
    
}
>>>#add-right-div {
    position: absolute;
    height: 50%;
    width: 9%;
    top:25%;
    right: .2%;
    border: none;
    
}
>>>#add-top {
    /* position: absolute; */
    /* top:25%; */
    left: 10%;
}
>>>#add-bot {
    scroll-margin-bottom: 500px;
    position: fixed;
    bottom:0%;
    left: 13%;
}
>>>#add-bot-sm {
    scroll-margin-bottom: 500px;
    position: fixed;
    bottom:0%;
    left: 8.5%;
}
>>>#center{
    margin: auto;
}
>>>#underline{
    color:black;
    border-bottom: solid .8px;
    border-color: white;
}
>>>#input{
    color:black;
    font-size: 18px;
    line-height: 100%;
    text-align: center;
    /* font-weight: bold; */
    padding: 0;
}
.expand-panel {
    background-color: white !important;
}
</style>