<template>
  <v-card
  class="ma-5 pa-4"
  >
    <v-card-title>
      Access Point Conditions
    </v-card-title>
    <v-card-text class="text-body-1">
      The condition of shoreline access points can make or break your day on the ice. Stay on top of the changes. Open the Access Point Conditions map, click on your access point, and see what recent travellers are saying before you leave home.
    </v-card-text>
    <v-card-text>
      ( Graphic of ice time dialogue showing user votes for truck, snowmobile, quad or walk on with date. )
    </v-card-text>
  </v-card>
</template>
  
<script>
export default { 
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
  