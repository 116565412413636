<template>
    <v-container class="ma-0 pa-0 mb-10">
        <v-card class="blue lighten-5 pb-3 mb-16">
            <v-card-title>Competition Roster</v-card-title>
            <v-card
                :class="set_padding_margins"
            >
                <v-tabs
                    fixed-tabs
                    bg-color="primary"
                    class="mb-5"
                    v-model="active_tab"
                >
                    <v-tab v-if="!$vuetify.breakpoint.xs" @click="changeSummaryPage($route.params.id)">Summary</v-tab>
                    <v-tab v-else @click="changeSummaryPage($route.params.id)"><v-icon>mdi-information-box</v-icon></v-tab>
                    <v-tab v-if="!$vuetify.breakpoint.xs" @click="changeRosterPage($route.params.id)">Roster</v-tab>
                    <v-tab v-else @click="changeRosterPage($route.params.id)"><v-icon>mdi-list-box</v-icon></v-tab>
                    <v-tab v-if="!$vuetify.breakpoint.xs" @click="changeLeaderboardPage($route.params.id)">Leaderboard</v-tab>
                    <v-tab v-else @click="changeLeaderboardPage($route.params.id)"><v-icon>mdi-trophy</v-icon></v-tab>
                </v-tabs>  
                <v-row v-if="fixed_teams.length == 0">
                    <v-container>
                        <v-row :no-gutters="set_gutters">
                            <v-col cols="12">
                                <v-card-text class="py-0">
                                    <p>No teams have been added to this competition!</p>
                                </v-card-text>                                
                            </v-col>
                        </v-row>                                
                    </v-container>                
                </v-row>
                
                <v-row v-else
                    class="teamitem mb-2"
                    v-for="(item, i) in fixed_teams"
                    :key="i"
                >
                    <v-container>
                        <v-row :no-gutters="set_gutters">
                            <v-col cols="12" sm="4" class="pb-0">
                                <v-card-text class="py-0">
                                    <div>Team Name</div>
                                    <p :class="font_size_value">{{ convertSpecial(item.name) }}</p>                        
                                </v-card-text>                    
                            </v-col>
                            <!-- <v-divider v-if="!$vuetify.breakpoint.xs" class="my-4" vertical></v-divider> -->
                            <v-col cols="12" sm="8" class="pb-0">
                                <v-card-text class="py-0">
                                    <div>Members</div>
                                        <v-row class="subtitle-2 font-weight-bold" no-gutters>
                                            <v-col v-for="(member, j) in item.members" :key="j" cols="6">
                                                <v-avatar class="ma-2" @click="showCard(member.member_id, member.profile_image)">
                                                    <v-img
                                                        v-if="member.profile_image"        
                                                        :src="(image_profile_start + member.profile_image)" 
                                                        alt="Avatar"/>
                                                    <v-icon v-else large>mdi-account</v-icon>
                                                </v-avatar>
                                                {{ member.alias }}
                                            </v-col>
                                        </v-row>
                                </v-card-text>    
                            </v-col>
                        <!-- <v-col class="justify-center" cols="1">
                            <v-row>
                                <v-btn class="ma-2 justify-center" color="deep-orange lighten-2">
                                    Kick
                                </v-btn>
                                
                            </v-row>
                            <v-row>
                                <v-btn class="ma-2 justify-center" color="deep-orange lighten-2">
                                    Kick
                                </v-btn>
                                
                            </v-row>
                            <v-row>
                                <v-btn class="ma-2 justify-center" color="deep-orange lighten-2">
                                    Kick
                                </v-btn>
                                
                            </v-row>
                        </v-col>
                        <v-col class="justify-center" cols="1">
                            <v-row>
                                <v-btn class="ma-2 justify-center" color="red lighten-1">
                                    ban
                                </v-btn>

                            </v-row>
                            <v-row>
                                <v-btn class="ma-2 justify-center" color="red lighten-1">
                                    ban
                                </v-btn>
                                
                            </v-row>
                            <v-row>
                                <v-btn class="ma-2 justify-center" color="red lighten-1">
                                    ban
                                </v-btn>
                                
                            </v-row>
                        </v-col> -->
                        <!-- <v-col class="justify-center" cols="2">
                            <v-row> -->
                                <!-- **TODO Have check to where this only shows if member is pending, and the person looking is team owner -->
                                <!-- <v-btn class="ma-0 justify-center text-caption" color="green lighten-2">
                                    accept
                                </v-btn>
                                
                            </v-row> -->
                            <!-- <v-row>
                                <v-btn class="ma-2 justify-center" color="green lighten-2">
                                    accpet
                                </v-btn>
                                
                            </v-row>
                            <v-row>
                                <v-btn class="ma-2 justify-center" color="green lighten-2">
                                    accpet
                                </v-btn>
                                
                            </v-row> -->
                        <!-- </v-col> -->                            
                        </v-row >                           

                        <!-- <v-row :no-gutters="set_gutters" v-if="(competition.owner == user.id || user.status == 9)">
                            <v-col class="mx-2 my-0 pt-0">
                                <v-card-actions class="py-0">
                                    <v-spacer></v-spacer>
                                    <v-btn class="ma-2 justify-center"
                                    color="red lighten-2">
                                        Remove Team
                                    </v-btn>
                                    <v-btn class="ma-2 justify-center"
                                    color="blue lighten-1">
                                        Rename Team
                                    </v-btn>    
                                </v-card-actions>  
                            </v-col>                        
                        </v-row>                                       -->
                    </v-container>            
                    <v-divider v-if="i != fixed_teams.length-1" class="mx-6"></v-divider>   
                </v-row>
            </v-card>
        </v-card>
        <v-dialog v-model="profile_overlay" max-width="1000">

            <v-card class="pa-4">
                <v-row >
                    <v-spacer></v-spacer>
                    <v-card-actions class="pb-0">
                        <v-icon
                            large
                            @click="profile_overlay = false"
                        >mdi-close-circle
                        </v-icon>                
                    </v-card-actions>
                </v-row>

                <v-row>            
                    <v-col cols="12" sm="4">
                        <v-card-text class="text-center">         
                            <v-avatar class="ma-2 justify-center" size="128">
                                <v-img         
                                    v-if="current_avatar"        
                                    :src="(image_profile_start + current_avatar)" 
                                    alt="Avatar"/>
                                <v-icon v-else large>mdi-account</v-icon>
                            </v-avatar> 
                            <v-card-title class="justify-center"><h2>{{ card_name }}</h2></v-card-title>

                            <v-row no-gutters>
                                <v-col>
                                    Joined: {{ card_date }}
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                    Regions: {{ card_region }}
                                </v-col>
                            </v-row>  
                        </v-card-text> 
                    </v-col>   
                    <v-col cols="12" sm="8">
                        <v-card class="pb-4" :height="info_height" outlined>
                            <v-tabs
                                fixed-tabs
                                bg-color="primary"
                                class="mb-5"
                            >
                                <v-tab v-if="!$vuetify.breakpoint.xs">Competitions</v-tab>
                                <v-tab v-else><v-icon>mdi-trophy-outline</v-icon></v-tab>
                                <v-tab v-if="!$vuetify.breakpoint.xs">Catches</v-tab>
                                <v-tab v-else><v-icon>mdi-fish</v-icon></v-tab>
                            
                                <v-tab-item class="mt-4" align="center">
                                    <v-row align="center">
                                        <v-col cols="12" sm="6" class="d-flex">
                                            <v-card-text class="py-0">
                                                <div>Total Completed Competitions</div>
                                                <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card.total }}</p>
                                                <p v-else :class="font_size_value">-</p>                                            
                                            </v-card-text>                    
                                        </v-col>

                                        <v-col cols="12" sm="6">
                                            <v-row>
                                                <v-col cols="4" sm="12">
                                                    {{ profile_card.firsts }}
                                                    <v-icon color="yellow" large>mdi-trophy</v-icon>
                                                    <nobr v-if="!$vuetify.breakpoint.xs"> 1st Place Finishes</nobr>
                                                </v-col>
                                                <v-col cols="4" sm="12">
                                                    {{ profile_card.seconds }}
                                                    <v-icon color="grey" large>mdi-trophy</v-icon>
                                                    <nobr v-if="!$vuetify.breakpoint.xs"> 2nd Place Finishes</nobr>
                                                </v-col>
                                                <v-col cols="4" sm="12">
                                                    {{ profile_card.thirds }}
                                                    <v-icon color="brown" large>mdi-trophy</v-icon>
                                                    <nobr v-if="!$vuetify.breakpoint.xs"> 3rd Place Finishes</nobr>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-row class="mt-4 mx-4">
                                        <v-autocomplete
                                            item-text="common_name"
                                            item-value="id"
                                            hide-details="auto"
                                            outlined
                                            dense
                                            label="Species"
                                            v-model="selected_species"
                                            :items="species"
                                            return-object
                                            :onchange="getCardSpeciesInfo()"
                                        ></v-autocomplete>                                    
                                    </v-row>

                                    <v-row>
                                        <v-col cols="6" sm="6">
                                            <v-card-text class="py-0">
                                                <div>Total Caught</div>
                                                <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card_species.total }}</p>
                                                <p v-else :class="font_size_value">0</p>                                            
                                            </v-card-text>                    
                                        </v-col>
                                        <v-col cols="6" sm="6">
                                            <v-card-text class="py-0">
                                                <div>Longest Caught</div>
                                                <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card_species.longest }} cm</p>
                                                <p v-else :class="font_size_value">-</p>                        
                                            </v-card-text>   
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="6" sm="6">
                                            <v-card-text class="py-0">
                                                <div>Heaviest Caught</div>
                                                <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card_species.heaviest }} lbs.</p>
                                                <p v-else :class="font_size_value">-</p>                                            
                                            </v-card-text>                    
                                        </v-col>
                                        <v-col cols="6" sm="6">
                                            <v-card-text class="py-0">
                                                <div>Average Caught per Fishing Day</div>
                                                <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ Math.round((profile_card_species.total/profile_card_species.days)*100) / 100 }}</p>
                                                <p v-else :class="font_size_value">-</p>                        
                                            </v-card-text>   
                                        </v-col>
                                    </v-row>                                
                                </v-tab-item>
                            </v-tabs>                          
                        </v-card>
                    </v-col>    
                    <v-col cols="12" sm="4">
                        <v-card class="pb-4" :height="bio_height" outlined>
                            <v-card-title class="pb-0">
                                <h5>Bio</h5>  
                                <v-card-text class="px-0">{{ card_bio }}</v-card-text>                          
                            </v-card-title>
                        </v-card>
                    </v-col>          
                    <v-col cols="12" sm="8">
                        <v-card class="pb-4" height="200px" outlined>
                            <v-card-title class="pb-0">
                                <h5>Badges</h5>   
                            </v-card-title>
                            <v-row class="justify-center py-8">
                                <v-slide-group class="pa-1" show-arrows>
                                    <v-slide-item><v-img contain src="/img/test_badge.png" max-height="100px" max-width="100px"/></v-slide-item>
                                    <v-slide-item><v-img contain src="/img/badge2.png" max-height="100px" max-width="100px"/></v-slide-item>
                                    <v-slide-item><v-img contain src="/img/badge3.png" max-height="100px" max-width="100px"/></v-slide-item>
                                    <v-slide-item><v-img contain src="/img/badge4.png" max-height="100px" max-width="100px"/></v-slide-item>
                                    <v-slide-item><v-img contain src="/img/badge5.png" max-height="100px" max-width="100px"/></v-slide-item>                      
                                </v-slide-group>
                            </v-row>
                        </v-card>
                    </v-col>                       
                </v-row>
                
            </v-card>
        </v-dialog>
    </v-container>
</template>
    
<script>
import { mapState } from 'vuex';

export default {
    components:{
    },
    computed: {
        ...mapState('leaderboard', {
            competition: state => state.competition,
            comp_teams: state => state.comp_teams,
            team_list: state => state.team_list,
        }),
        ...mapState('user',{
            user: state => state.user,
            profile_card: state => state.profile_card,
            profile_card_species: state => state.profile_card_species,
        }),
        ...mapState('spot_mapper',{
            species: state => state.species,
        }),  
        set_gutters(){
            return !this.$vuetify.breakpoint.xs ? false : true
        },     
        set_padding_margins(){
            return !this.$vuetify.breakpoint.xs ? "px-4 py-2 ma-3" : "ma-3"
        }, 
        font_size_value(){
            return !this.$vuetify.breakpoint.xs ? "text-h5" : "text-h6"
        },
        info_height(){
            return !this.$vuetify.breakpoint.xs ? "300px" : "310px"
        },
        bio_height(){
            return !this.$vuetify.breakpoint.xs ? "200px" : "auto"
        }         
    },
    data() {
        return {
            fixed_teams:[],
            active_tab: 1,

            image_profile_start: this.$apiEndpointUrl + 'profile_image/',

            card_mode: 1,
            mode_options: [
                { text: 'Competition', value: 1},
                { text: 'Species', value: 2},
            ],
            card_name: null,
            card_date: null,
            card_region: null,
            card_bio: null,
            selected_species: null,
            current_species: null,
            current_avatar: null,
            loading: false,
            profile_overlay: false,
        }
    },
    methods: {
        changeSummaryPage(id){
            // window.location.href = "/app/leaderboard/compsummary/" + id
            this.$router.push(("/app/leaderboard/compsummary/" + id))
            // this.$store.page = page
            // console.log(this.$store.page)
            // this.$emit('change-page')
            // vm.$forceUpdate()
        },
        changeRosterPage(id){
            // window.location.href = "/app/leaderboard/comproster/" + id
            this.$router.push(("/app/leaderboard/comproster/" + id))
            // this.$store.page = page
            // console.log(this.$store.page)
            // this.$emit('change-page')
            // vm.$forceUpdate()
        },
        convertSpecial(text){  
            text = String(text).replace("&lt;" , "<")
            text = String(text).replace("&gt;" , ">")
            text = String(text).replace("&quot;" , "\"")
            text = String(text).replace("&#039;" , "'")
            text = String(text).replace("&amp;" , "&")
            // console.log(text)
            return text
        },
        changeLeaderboardPage(id){
            // window.location.href = "/app/leaderboard/compleaderboard/" + id
            this.$router.push(("/app/leaderboard/compleaderboard/" + id))
            // this.$store.page = page
            // console.log(this.$store.page)
            // this.$emit('change-page')
            // vm.$forceUpdate()
        },
        showCard(id, avatar){

            // this.fixed_leaderboard.map((item, index) => {
            //     item.selected = item === row

            //     this.$set(this.fixed_leaderboard, index, item)
            // })

            this.selected_species = null
            this.current_species = null
            this.card_mode = 1
            this.$store.dispatch('user/getProfileCard', id)
            .then(() => {
                this.card_name = this.profile_card.profile.name
                this.card_date = new Date(this.profile_card.profile.tos_accepted).toLocaleDateString('en-us', { month:"long", day:"numeric", year:"numeric"})
                this.card_region = this.profile_card.profile.region
                this.card_bio = this.profile_card.profile.bio

                this.$store.dispatch('spot_mapper/getSpecies')
                .then(() => {
                    // console.log(this.species)
                    this.profile_overlay = true
                })
            })
            this.current_avatar = avatar
        },
        getCardSpeciesInfo(){
            if(this.selected_species!==null && !this.loading){
                if(this.current_species!==this.selected_species.id){
                    this.loading = true
                    let user = this.user.id
                    let species = this.selected_species.id
                    this.$store.dispatch('user/getProfileCardSpecies', { user, species})
                    .then(() => {
                        // console.log(this.profile_card_species)
                        this.current_species = this.selected_species.id
                        this.loading = false
                    })
                }
            }
        },
        backToComp(){
            this.$store.page = 'selected_comp'
            this.$emit('change-page')
        }
    },
    mounted() {
        window.scrollTo(0,0);
        let id = this.$route.params.id
        this.$store.selected_competition = id
        this.$store.dispatch('leaderboard/getCompTeams')
        this.$store.dispatch('leaderboard/getTeamList')
        .then(() => {
        //** TODO make the fixing of team list ocure after the responce of team list?
        // console.log(this.team_list)

        var temp_id = null
        var team = {}
        var current_member = {}
        this.team_list.forEach(item => {
            if(item.id !== temp_id){
                team = {
                    id: item.id,
                    name: item.name,
                    members:[],
                }
                current_member = {
                    member_id: item.member,
                    alias: item.alias,
                    profile_image: item.profile_image,
                }
                team.members.push(current_member)
                this.fixed_teams.push(team)
                temp_id = item.id
                // console.log(temp_id)
            } else if(current_member.member_id !== item.member) {
                current_member = {
                    member_id: item.member,
                    alias: item.alias,
                    profile_image: item.profile_image,
                }
                team.members.push(current_member)
            }
        })
        // when we know better sort by we can sort at beginning?
        // console.log(this.fixed_teams)
    })
    }
}
</script>

<style scoped>
/* >>>.col{
    padding: 12px;
    padding-bottom: 0px;
    padding-top: 0px;

} */
>>>.card-title{
    padding: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
    margin: 0px;
}
>>>.teamitem{
    border-color: black;
    border-width: 2px;
}
</style>