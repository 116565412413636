<template>
    <v-container class="ma-0 pa-0">
        <v-card class="blue lighten-5 mb-16">
            <v-card-title>Finished Competitions</v-card-title>        

            <v-container v-if="ended_comps.length == 0">
                <v-card >
                    <v-card-title>No competitions found!</v-card-title>
                </v-card>            
            </v-container>

            <v-container v-else v-for="item in ended_comps" :key="item.id">
                <v-card
                    :class="set_padding_margins"
                    :key="item.id"
                    outlined            
                    elevation="2"
                    @click="toComp(item.id)"
                >
                    <div>
                        <v-card-text>                        
                            <p :class="font_size_title">{{item.name}}</p>
                            <div>{{formatDates(item.start_date)}} - {{formatDates(item.end_date)}}</div>
                        </v-card-text>            
                    
                        <v-row no-gutters>
                            <v-col cols="14">
                                <v-card-text class="py-0">
                                    <div>Hosted By</div>
                                    <p :class="font_size_value">{{item.host}}</p>                        
                                </v-card-text>                    
                            </v-col>
                            <v-col cols="14" sm="7">
                                <v-card-text class="py-0">
                                    <div>Location</div>
                                    <p :class="font_size_value">{{item.location}}</p>                        
                                </v-card-text>   
                            </v-col>
                        </v-row>

                        <!-- <v-row no-gutters align="center">
                            <v-col class="text-right">
                                <v-btn 
                                    class="green ma-4"
                                    @click="toComp(item.id)"
                                    >
                                <span class="white--text">Register Here</span>
                            </v-btn>
                            </v-col>
                        </v-row> -->
                    </div>
                </v-card>
            </v-container>
        </v-card>
    </v-container>
</template>

<script>
//import
import { mapState } from 'vuex';

export default{
    data(){
        return {
            
        }
    },
    components:{

    },
    computed: {
        ...mapState('leaderboard', {
            ended_comps: state => state.ended_comps,
        }),
        font_size_title(){
            return !this.$vuetify.breakpoint.xs ? "text-h4 mb-0" : "text-h6 mb-0"
        },       
        font_size_value(){
            return !this.$vuetify.breakpoint.xs ? "text-h5" : "text-h6"
        },    
        set_padding_margins(){
            return !this.$vuetify.breakpoint.xs ? "px-4 py-2 mb-3" : ""
        },  
    },
    methods: {
        toComp(id){
            this.$router.push(("/app/leaderboard/finishedcomp/" + id))
            // console.log(id)
            // this.$store.selected_competition = id
            // this.$store.dispatch('leaderboard/getCompetition')
            // this.$store.dispatch('leaderboard/getTeams')
            // Is there a difference between the two?
            // this.$store.page = 'finished_comp'
            // this.$store.page = 'selected_comp'
            // this.$emit('change-page')
        },
        formatDates(date){
            let temp = date + ' UTC'
            let value = new Date(temp)
            
            return (value.toLocaleString())
        },
    },
    mounted() {
        //Change this API to include team count per member?
        this.$store.dispatch('leaderboard/getEndedComps')
        // console.log(this.competitions)
    }
}
</script>

<style scoped>
>>>.col{
    height: 50%
}
</style>