<template>
    <v-container>
        <h1>Getting Started</h1>
        <h4>Where do I start?</h4>
        <p> The Home screen sets up your navigation:</p>
        <p> For our beta testing group summer and fall 2024: <a href="https://beta.icetime.app/">https://beta.icetime.app/</a></p>
        <p> For the member community after Nov 1, 2024: <a href="https://beta.icetime.app/">https://my.icetime.app/</a></p>
        <p> We suggest you view the “Start Tour” option from the home screen which introduces you quicky to the apps available. If you need more information to decide to join, also see the documents linked on the Regional Leaderboard page, listed below. This tour aims.</p>
        <p> There are two main entry points to Anglers Leaderboard (ALB) from Home:</p>
        <p> ⦁ Regional Leaderboard. This displays the ranked results of the member community for your region.  You will also find introductory documents (Getting Started, About, and FAQ) and navigation to most areas of the app., including competitions, and the catch scores for the leaderboard championships. Members and the public can access the Regional Leaderboard.</p>
        <p> ⦁ SpotMapper. This is where you enter your fish catches. Members have access to state-of-the-art automatic tools for easily capturing your photos, length measurements, and species identifications. Users that have not subscribed can manually enter their information for free. All users can see their catches and stats on the Personal Leaderboard, which is private.</p>
        <p> At any time, you can also use the pop-up navigation (blue circle) found in the lower right corner of your screen.</p>

        <h2>Beta Tester Notice:</h2>
        <p>For question, troubleshooting, or issue reports please contact: <a href= "mailto: cole@nextgenenvironment.ca ?cc=info@nextgenenvironment.ca" >cole@nextgenenvironment.ca and info@nextgenenvironment.ca</a></p>


    </v-container>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>