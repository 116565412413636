<template>
  <v-container class="mb-15 pb-15">
    <v-layout class="mb-6 mx-0" cols="12" md12 lg8 offset-lg2 xl6 offset-xl3 row>
        <v-flex xs12>
            <p class="text-h5 ma-8">
            <v-icon class="mr-2">fa-address-card</v-icon>
            User Card
            </p>
        </v-flex>
        <v-flex xs12 sm5 md6 lg4></v-flex>
        <v-card class="pa-4" width="100%">
            <v-row>            
                <v-col cols="12" sm="4">
                    <v-card-text class="text-center">         
                        <v-avatar class="ma-2 justify-center" size="128">
                            <v-img         
                                v-if="user.profile_image"        
                                :src="(image_start + user.profile_image)" 
                                alt="Avatar"/>
                            <v-icon v-else large>mdi-account</v-icon>
                        </v-avatar> 
                        <v-card-title class="justify-center"><h2>{{ card_name }}</h2></v-card-title>

                        <v-row no-gutters>
                            <v-col>
                                Joined: {{ card_date }}
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col>
                                Regions: {{ card_region }}
                            </v-col>
                        </v-row>  
                    </v-card-text> 
                </v-col>   
                <v-col cols="12" sm="8">
                    <v-card class="pb-4" :height="info_height" outlined>
                        <v-tabs
                            fixed-tabs
                            bg-color="primary"
                            class="mb-5"
                        >
                            <v-tab v-if="!$vuetify.breakpoint.xs">Competitions</v-tab>
                            <v-tab v-else><v-icon>mdi-trophy-outline</v-icon></v-tab>
                            <v-tab v-if="!$vuetify.breakpoint.xs">Catches</v-tab>
                            <v-tab v-else><v-icon>mdi-fish</v-icon></v-tab>
                        
                            <v-tab-item class="mt-4" align="center">
                                <v-row align="center">
                                    <v-col cols="12" sm="6" class="d-flex">
                                        <v-card-text class="py-0">
                                            <div>Total Completed Competitions</div>
                                            <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card.total }}</p>
                                            <p v-else :class="font_size_value">-</p>                                            
                                        </v-card-text>                    
                                    </v-col>

                                    <v-col cols="12" sm="6">
                                        <v-row>
                                            <v-col cols="4" sm="12">
                                                {{ profile_card.firsts }}
                                                <v-icon color="yellow" large>mdi-trophy</v-icon>
                                                <nobr v-if="!$vuetify.breakpoint.xs"> 1st Place Finishes</nobr>
                                            </v-col>
                                            <v-col cols="4" sm="12">
                                                {{ profile_card.seconds }}
                                                <v-icon color="grey" large>mdi-trophy</v-icon>
                                                <nobr v-if="!$vuetify.breakpoint.xs"> 2nd Place Finishes</nobr>
                                            </v-col>
                                            <v-col cols="4" sm="12">
                                                {{ profile_card.thirds }}
                                                <v-icon color="brown" large>mdi-trophy</v-icon>
                                                <nobr v-if="!$vuetify.breakpoint.xs"> 3rd Place Finishes</nobr>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <v-row class="mt-4 mx-4">
                                    <v-autocomplete
                                        item-text="common_name"
                                        item-value="id"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        label="Species"
                                        v-model="selected_species"
                                        :items="species"
                                        return-object
                                        :onchange="getCardSpeciesInfo()"
                                    ></v-autocomplete>                                    
                                </v-row>

                                <v-row>
                                    <v-col cols="6" sm="6">
                                        <v-card-text class="py-0">
                                            <div>Total Caught</div>
                                            <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card_species.total }}</p>
                                            <p v-else :class="font_size_value">0</p>                                            
                                        </v-card-text>                    
                                    </v-col>
                                    <v-col cols="6" sm="6">
                                        <v-card-text class="py-0">
                                            <div>Longest Caught</div>
                                            <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card_species.longest }} cm</p>
                                            <p v-else :class="font_size_value">-</p>                        
                                        </v-card-text>   
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="6" sm="6">
                                        <v-card-text class="py-0">
                                            <div>Heaviest Caught</div>
                                            <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card_species.heaviest }} lbs.</p>
                                            <p v-else :class="font_size_value">-</p>                                            
                                        </v-card-text>                    
                                    </v-col>
                                    <v-col cols="6" sm="6">
                                        <v-card-text class="py-0">
                                            <div>Average Caught per Fishing Day</div>
                                            <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ Math.round((profile_card_species.total/profile_card_species.days)*100) / 100 }}</p>
                                            <p v-else :class="font_size_value">-</p>                        
                                        </v-card-text>   
                                    </v-col>
                                </v-row>                                
                            </v-tab-item>
                        </v-tabs>                          
                    </v-card>
                </v-col>    
                <v-col cols="12" sm="4">
                    <v-card class="pb-4" :height="bio_height" outlined>
                        <v-card-title class="pb-0">
                            <h5>Bio</h5>  
                            <v-card-text class="px-0">{{ card_bio }}</v-card-text>                          
                        </v-card-title>
                    </v-card>
                </v-col>          
                <v-col cols="12" sm="8">
                    <v-card class="pb-4" height="200px" outlined>
                        <v-card-title class="pb-0">
                            <h5>Badges</h5>   
                        </v-card-title>
                        <v-row class="justify-center py-8">
                            <v-slide-group class="pa-1" show-arrows>
                                <v-slide-item><v-img contain src="/img/test_badge.png" max-height="100px" max-width="100px"/></v-slide-item>
                                <v-slide-item><v-img contain src="/img/badge2.png" max-height="100px" max-width="100px"/></v-slide-item>
                                <v-slide-item><v-img contain src="/img/badge3.png" max-height="100px" max-width="100px"/></v-slide-item>
                                <v-slide-item><v-img contain src="/img/badge4.png" max-height="100px" max-width="100px"/></v-slide-item>
                                <v-slide-item><v-img contain src="/img/badge5.png" max-height="100px" max-width="100px"/></v-slide-item>                      
                            </v-slide-group>
                        </v-row>
                    </v-card>
                </v-col>                       
            </v-row>            
        </v-card>
        <v-flex xs12 sm5 md6 lg4></v-flex>
        <!-- <v-card class="mt-5 pa-4" width="100%">
            <v-row>
            <v-col cols="6" sm="6">
                <v-select
                    :items="week_dates"
                    v-model="selected_date"
                    label="Day Select"
                    dense
                    outlined
                    width="10"
                    @change="calculateProofValue(selected_date, user_id)"
                ></v-select>

                <v-text-field
                    v-model="user_id"
                    label="ID Select"
                    @change="calculateProofValue(selected_date, user_id)"
                >

                </v-text-field>
            </v-col>
            <v-divider
                vertical
            ></v-divider>
            <v-col v-if="fullBase32Number" cols="6" sm="4"> 
                <div>Full Base 36 Number: {{ fullBase32Number.toUpperCase() }} </div>
                <div>Final Code Value: {{ codeValue.toUpperCase() }} </div>
            </v-col>
            </v-row>

           
        </v-card> -->
    </v-layout>
    <Footer />
    </v-container>
  </template>
  
  <script>
  import { mapState } from 'vuex'
  import Footer from '../../../components/Footer.vue'
  
  export default {
    data() {
      return {
        image_start: this.$apiEndpointUrl + 'profile_image/',
        join_date: null,
        card_mode: 1,
        mode_options: [
            { text: 'Competition', value: 1},
            { text: 'Species', value: 2},
        ],
        card_name: null,
        card_date: null,
        card_region: null,
        card_bio: null,
        selected_species: null,
        current_species: null,
        loading: false,
        week_dates: [],
        selected_date: "",
        user_id: 532,
        fullBase32Number: null,
        codeValue: null,
        
      }
    },
    components: {
        Footer
    },
    computed: {
    //   ...mapState(['mailchimp_subscriptions']),
        ...mapState('user',{
            user: state => state.user,
            profile_card: state => state.profile_card,
            profile_card_species: state => state.profile_card_species,
        }),
        ...mapState('spot_mapper',{
            species: state => state.species,
        }),
        font_size_value(){
            return !this.$vuetify.breakpoint.xs ? "text-h5" : "text-h6"
        },       
        info_height(){
            return !this.$vuetify.breakpoint.xs ? "300px" : "310px"
        },            
        bio_height(){
            return !this.$vuetify.breakpoint.xs ? "200px" : "auto"
        }        
    },
    methods: {
        getCardSpeciesInfo(){
            if(this.selected_species!==null && !this.loading){
                if(this.current_species!==this.selected_species.id){
                    this.loading = true
                    let user = this.user.id
                    let species = this.selected_species.id
                    this.$store.dispatch('user/getProfileCardSpecies', { user, species})
                    .then(() => {
                        // console.log(this.profile_card_species)
                        this.current_species = this.selected_species.id
                        this.loading = false
                    })
                }
            }
        },
        calculateDates(){

            // this.getDBTime(date)
            let today = new Date();
            let minus1 = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
            let minus2 = new Date(minus1.getTime() - 1 * 24 * 60 * 60 * 1000);
            let minus3 = new Date(minus2.getTime() - 1 * 24 * 60 * 60 * 1000);
            let minus4 = new Date(minus3.getTime() - 1 * 24 * 60 * 60 * 1000);
            let minus5 = new Date(minus4.getTime() - 1 * 24 * 60 * 60 * 1000);
            let minus6 = new Date(minus5.getTime() - 1 * 24 * 60 * 60 * 1000);
            this.week_dates = [
                {text:this.getFormatedDate(today), value:this.getDBTime(today)},
                {text:this.getFormatedDate(minus1), value:this.getDBTime(minus1)},
                {text:this.getFormatedDate(minus2), value:this.getDBTime(minus2)},
                {text:this.getFormatedDate(minus3), value:this.getDBTime(minus3)},
                {text:this.getFormatedDate(minus4), value:this.getDBTime(minus4)},
                {text:this.getFormatedDate(minus5), value:this.getDBTime(minus5)},
                {text:this.getFormatedDate(minus6), value:this.getDBTime(minus6)},

            ]
            this.selected_date = this.getDBTime(today)
            // console.log(this.week_dates)
        },
        getFormatedDate(value){
            const offset = value.getTimezoneOffset()
            value = new Date(value.getTime() - (offset*60*1000))
            // console.log(value.toISOString().split('T')[0])
            return value.toISOString().split('T')[0]
        },
        getDBTime(date){
            let localdate = new Date(date)
            // console.log(localdate.toISOString().slice(0, -1))
            // return (localdate.toISOString().slice(0, -1))
            return localdate.toISOString().split('T')[0]
        },
        calculateProofValue(date_value, user_id){

            // var now = new Date()
            var selectedDateCalc = new Date(date_value)
            var selectDaysSinceEpoch = Math.floor(selectedDateCalc/8.64e7)
            // var fullDaysSinceEpoch = Math.floor(now/8.64e7)
            // var weekDay = now.getUTCDay() +1
            var selectedWeekDay = selectedDateCalc.getUTCDay() +1
            var bigNumber = (selectDaysSinceEpoch * (selectedWeekDay * 10) * user_id)
            var base36Number = bigNumber.toString(36)
            this.fullBase32Number = base36Number
            let tempNumber = base36Number.substring((selectedWeekDay-1), (selectedWeekDay+3))
            let tempRemainder = base36Number.substring(0,(4-(tempNumber.length)))
            let finalDigits = tempNumber + tempRemainder
            this.codeValue = finalDigits

            // console.log(fullDaysSinceEpoch)
            // console.log(weekDay)
            // console.log(selectDaysSinceEpoch)
            // console.log(selectedWeekDay)
            // console.log(bigNumber)
            // console.log(base36Number)
            // console.log("temp Number: " + tempNumber)
            // console.log("temp Remainder: " + tempRemainder)
            // console.log(finalDigits)
            
        },
    //   update_card(){
  
    //     // let data = {
          
    //     // }
    //     // console.log(data)
    //   }
    },
    mounted() {
        this.$store.dispatch('setTab', 'card')

        this.calculateDates()
        this.calculateProofValue(this.selected_date, this.user_id)

        // console.log(this.user)
        let selected_user = this.user.id
        this.$store.dispatch('user/getProfileCard', selected_user)
        .then(() => {
            // console.log(this.profile_card)
            this.card_name = this.profile_card.profile.name
            this.card_date = new Date(this.profile_card.profile.tos_accepted).toLocaleDateString('en-us', { month:"long", day:"numeric", year:"numeric"})
            this.card_region = this.profile_card.profile.region
            this.card_bio = this.profile_card.profile.bio

            this.$store.dispatch('spot_mapper/getSpecies')
            .then(() => {
                // console.log(this.species)
            })
        })
        // .finally(() => {
            
        // })
    
        this.join_date = new Date(this.user.tos_accepted).toLocaleDateString('en-us', { month:"long", day:"numeric", year:"numeric"})
    }
  }
  </script>

<style scoped>
>>>#center{
    margin: auto;
}
>>>#underline{
    border-bottom: solid .8px;
    border-color: grey;
}
>>>#input{
    font-size: 17px;
    line-height: 100%;
    text-align: center;
    /* font-weight: bold; */
    padding: 0;
    color: #212121;
    
}
</style>