<template>
  <v-container>
    <!-- <AdminHeader title="User Administration" /> -->
    <UserTable />
  </v-container>
</template>

<script>
  // import AdminHeader from '../components/AdminHeader.vue'
  import UserTable from '../components/UserTable.vue'

  export default {
    components: {
      // AdminHeader,
      UserTable
    },
    data() {
      return {}
    },
    mounted() {
      // this.$store.dispatch('setMode', 'admin')
      this.$store.dispatch('setTab', 'users')
    }
  }
</script>
