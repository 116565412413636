<template>
  <v-container cols="12">
    <v-alert v-if="showLoading" type="info">Loading...</v-alert>
    <v-flex xs12 pa-2>
      <v-row cols="12" class="pb-0 pt-6">
        <v-col cols="12" sm="12" md="6" class="pr-1 pl-5">
          <p class="text-h4">
            <v-chip color="deep-orange" class="py-4 mr-1 mb-1 text-button" outlined>
              <v-icon class="pr-1">
                mdi-shimmer
              </v-icon>
              Demo
            </v-chip>
            Lake Ice Reports
          </p>
        </v-col>
        <v-spacer />
        <v-col cols="12" sm="12" md="6" lg="4" class="pr-5">
          <v-select 
            :items="regions"
            item-text="name"
            item-value="radar_id"
            :disabled="showLoading"
            :hint="selected_hint"
            hide-details="auto"
            v-model="selected_region"
            item-color="deep-orange"
            color="deep-orange"
            label="Filter by Region"
            dense
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-layout row dense v-if="filtered_reports.length > 0" mx-2>
            <v-flex v-for="item in filtered_reports" :key="item.id" xs12>
              <v-hover v-slot:default="{ hover }">
                <v-card :elevation="hover ? 6:2" class="card-outter pa-6 mb-6">
                  <v-row>
                    <v-col cols="12" xs="12" sm="6" md="4" class="ma-0 pa-0">
                      <v-img 
                        :src="$apiEndpointUrl + 'layerthumb/' + item.layer_id + '/thumb.png'"
                        height="100%"
                        max-width="100%"
                        max-height="350"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="8" class="ma-0 pa-0">
                      <v-container pb-0>
                        <v-chip small class="ma-1 white--text" color="deep-orange">
                          {{ item.region_name }}
                        </v-chip>
                        <v-chip small class="ma-1" color="grey lighten-1">
                          {{ item.footprint_name }}
                        </v-chip>  
                        <v-chip small class="ma-1">
                          {{ item.date }}
                        </v-chip>
                      </v-container>
                      <v-card-title class="headline">
                        {{ item.title }}
                      </v-card-title>
                      <v-container px-4 mb-8>
                        <v-card-text style="white-space: pre-line">
                          {{ item.description }}
                        </v-card-text>
                      </v-container>
                      <v-card-actions class="card-actions">
                        <v-btn @click.prevent="goMap(item.region_id, item.layer_id)" text>
                          View Map
                          <v-icon right>
                            mdi-exit-to-app
                          </v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-col>
                  </v-row>
                </v-card>
              </v-hover>
            </v-flex>
          </v-layout>
          <v-layout row v-if="showNoReports">
            <v-flex xs12 sm12 pa-2>
              <v-card>
                <v-card-title>
                  No demo reports found!
                </v-card-title>
              </v-card>
            </v-flex>
          </v-layout>
        </v-col>
      </v-row>
    </v-flex>
  </v-container>
</template>

<script>
import {mapState} from 'vuex'
export default {
  data() {
    return {
      regions: [],
      selected_hint: '',
      selected_region: 0,
      showLoading: true,
    }
  },
  computed: {
    ...mapState(['active_regions']),
    ...mapState('demo', {
      demo_user_reports: state => state.demo_user_reports,
    }),
    ...mapState(['user']),
    filtered_reports: function() {
      if(this.demo_user_reports) {
        return this.demo_user_reports.filter((report) => {
          return this.selected_region == 0 ? report:(report.map_id == this.selected_region)
        })
      } else {
        return []
      }
    },
    showNoReports() {
      return this.filtered_reports.length == 0
    }
  },
  created() {
    this.showLoading = true
    var stripe_id = this.user.stripe_id
    this.$store.dispatch("getActiveRegions")
    .then(() => {
      this.regions = this.active_regions
      this.regions.push({ name: "All Regions", radar_id: 0 })
    })
    this.$store.dispatch('demo/getDemoUserReports', { stripe_id })
      .then(() => {
        this.showLoading = false
        // if(this.demo_user_reports.length === 0) {
        //   this.showNoReports = true
        // }
      })
      .catch(err => {
        if(err.response.status == '403') {
          this.$store.dispatch('logout')
          this.$store.dispatch('setDialog', { 
            show: true, 
            message: err.response.data, 
            status: err.response.status
          })
        }
      })
  },
  methods: {
    goHome() {
      this.$router.push('/')
    },
    goMap(region_id, layer_id) {
      // this.$store.dispatch('setPlaceholderId', layer_id)
      this.$router.push('/demo/region/' + region_id + "/" + layer_id)
    },
  }
}
</script>

<style scoped>
>>>.img {
  height:100vh;
}
>>>.card-outter {
  position: relative;
  padding-bottom: 50px;
}
>>>.card-actions {
  position: absolute;
  bottom: 0;
  right:0;
}
</style>