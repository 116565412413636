<template>
  <v-container fluid pa-0 fill-height>
    <l-map 
      ref="map" 
      style="height:100%; z-index: 0!important;" 
      @update:center="updateCenter" 
      @locationfound="locationFound" 
      @update:zoom="updateZoom" 
      :center="center" 
      :options="mapOptions" 
      :maxBounds="mapBounds" 
      :maxZoom="mapMaxZoom" 
      :zoom="zoom" 
    >
      <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <!-- <l-tile-layer v-for="layer in layers" :key="layer.id" :name="layer.title" :url="layer.tile_url" :bounds="layer.bounds" :tms="layer.tms" /> -->
      <l-tile-layer 
        v-if="visibleLayer && visibleLayer.bounds"
        :name="visibleLayer.title"
        :url="visibleLayer.tile_url"
        :bounds="visibleLayer.bounds"
        :tms="visibleLayer.tms"
        :maxNativeZoom="visibleLayer.maxNativeZoom"
        :maxZoom="visibleLayer.maxZoom"
        :visible="isMapVisible && isZoomWithinDropInRange && !lockMapOn"
      />

      <l-geo-json v-if="visibleLayer && visibleLayer.overlay_geojson" :visible="isIceRoadVisible && isZoomWithinDropInRange" :geojson="visibleLayer.overlay_geojson" :options="overlayOptions"></l-geo-json>

      <FootprintSelect @footprint-changed="footprintChanged" />
      <HazardMarkerTool v-if="visibleLayer" :center="center" />
      <GPSTool @gps-mode-changed="gpsModeChanged" :gps="gps" />
      <MapControls @update-zoom-control="updateZoomControl" :zoomControl="zoomControl" />
      <ReportInfo v-if="visibleLayer" />
      <HazardMarkers v-if="visibleLayer" :id="id" />
      <TimeSeries @toggle-lock-map="lockMap" :footprint_dates="footprint_dates" />
    </l-map>

    <!-- <v-dialog v-model="dateMenuDialog" width="unset">
      <v-card class="card-outter pt-16">
        <v-divider></v-divider>
        <v-card-title>Select Imagery Date</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item v-for="item in footprint_dates" :key="item.date" @click="showDate(item.id)" :disabled="visibleLayer.id == item.id">
              <v-list-item-title>{{ item.date }}<span v-if="visibleLayer.id == item.id"> [current]</span></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="card-actions pt-4">
          <v-btn @click.prevent="dateMenuDialog = false" text class="grey--text">
            Close
            <v-icon right color="grey">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="zoomControlDialog" scrollable max-width="500px" width="unset">
      <v-card class="card-outter pt-16 pb-0" style="overflow-y: auto;">
        <v-divider></v-divider>
        <v-card-title class="pa-7">
          You are attempting to access a resolution that is not supported by Ice Time. Do you wish to continue?
        </v-card-title>
        <v-card-text>
          You can change the Zoom Control in the <v-icon color="hazardMarkerColor">mdi-layers-triple-outline</v-icon> Layer Controls options.
        </v-card-text>
        <v-card-actions class="card-actions pt-4">
          <v-btn @click.prevent="deactivateZoomControl" text class="grey--text">
            Continue
          </v-btn>
          <v-btn @click.prevent="activateZoomControl" text class="grey--text">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { latLng } from 'leaflet';
import { LMap, LTileLayer, LGeoJson } from 'vue2-leaflet'

import FootprintSelect from '../components/FootprintSelect.vue'
import GPSTool from '../components/GPSTool.vue'
import HazardMarkers from '../components/HazardMarkers.vue'
import HazardMarkerTool from '../components/HazardMarkerTool.vue'
import MapControls from '../components/MapControls.vue'
import ReportInfo from '../components/ReportInfo.vue'
import TimeSeries from '../components/TimeSeries.vue'

export default {
  props: ['id', 'layer'],
  data() {
    return {
      center: latLng(50.7, -96.6), // hardcoded center
      dateMenuDialog: false,
      delete_loading: false,
      demo_hazards_filtered: [],
      demo_hazards_pois: [],
      footprint_dates: [],
      footprints_hint: '',
      gps: null,
      hazardMarkerColor: 'deep-orange',
      lockMapLayer: '',
      map: null,
      mapBounds: null,
      mapMaxZoom: null,
      mapOptions: {    
        wheelPxPerZoomLevel: 120,
        zoomSnap: 0.1,
      },
      overlayOptions: {
        style: function(feature) {
          if(feature.properties.Color) {
            return {
              color: feature.properties.Color,
              //opacity: feature.properties.Opacity,
              //stroke: feature.properties.Stroke,
              //weight: feature.properties.weight
            }
          }
        },
        onEachFeature: function onEachFeature(feature, layer) {
          layer.bindPopup("Date: " + feature.properties.Date)
        }
      },
      // report: [],
      showLoading: true,
      timeSeriesLayerIds: [],
      timeSeriesLoaded: 0,
      zoomControlDialog: false,
    }
  },
  computed: {
    ...mapGetters('user', {
      isAdmin: 'isAdmin',
      isLoggedIn: 'isLoggedIn'
    }),
    ...mapState('user', {
      user: state => state.user
    }),
    ...mapState('demo', {
      current_footprint: state => state.current_footprint,
      dates: state => state.dates,
      demo_hazards: state => state.demo_hazards,
      layers: state => state.demo_layers,
      demo_user_reports: state => state.demo_user_reports,
      footprints: state => state.footprints,
      gpsMode: state => state.gpsMode,
      isMapVisible: state => state.isMapVisible,
      lockMapOn: state => state.lockMapOn,
      range_labels: state => state.range_labels,
      range_value: state => state.range_value,
      visibleLayer: state => state.visibleLayer,
      zoom: state => state.zoom,
      zoomControl: state => state.zoomControl,
    }),
    isZoomWithinDropInRange() {
      let returnVal = this.zoom > this.range_labels[this.range_value[0]] && this.zoom < this.range_labels[this.range_value[1]]
      return returnVal
    },
  },
  components: {
      FootprintSelect,
      GPSTool,
      HazardMarkers,
      HazardMarkerTool,
      LMap,
      LTileLayer,
      LGeoJson,
      MapControls,
      ReportInfo,
      TimeSeries,
  },
  mounted () {
    this.$nextTick(() => {
      this.$nextTick(() => {
        this.map = this.$refs.map.mapObject // work as expected
      })
    })
  },
  created () {
    this.showLoading = true

    var id = this.id
    this.$store.dispatch('demo/getFootprints', {id})
    .then(() => {
      this.$store.dispatch('demo/getDemoLayers', {id})
      .then(()=> {
        var fp = null
        if(this.layers.length > 0) {
          //// Determine the visible layer and current footprint
          var vl = null
          var cf = null
          if (this.layer) { // check for layer id prop)
            if(this.layer.includes('_')) { // check for footprint_id prop
              let split = this.layer.split('_')
              let ro = parseInt(split[0].substring(2))
              let lat = split[1]
              fp = this.footprints.find(footprint => {
                return (footprint.relative_orbit == ro && footprint.latitude == lat.latitude)
              })
            } else {
              vl = this.layers.find(layer => {
                return layer.id == this.layer
              })
            }
          } else if (this.dates.length > 1) {
            this.dates.sort()
            vl = this.layers.find(layer => {
              return layer.id == this.dates[0].id
            })
          } else {
            vl = this.layers[0]
          }

          // vl = vl ? vl:{}
          // this.current_footprint = fp ? fp:this.footprints[0]
          // let cf = fp ? fp:null
          cf = fp ? fp:this.footprints[0]
          this.$store.dispatch('demo/setCurrentFootprint', cf)
          this.$store.dispatch('demo/setVisibleLayer', vl)

          if (this.visibleLayer) {
            // console.log('Visible Layer exists!')
            this.center = this.visibleLayer.center
            this.bounds = this.visibleLayer.bounds

            // console.log('Visible layer RO: ' + this.visibleLayer.relative_orbit)
            // console.log('Visible layer Lat: ' + this.visibleLayer.latitude)
            
            cf = this.footprints.find(footprint => {
              // console.log('Comparing to RO: ' + footprint.relative_orbit + ', Lat: ' + footprint.latitude)
              return (footprint.relative_orbit == this.visibleLayer.relative_orbit && footprint.latitude == this.visibleLayer.latitude)
            })

            this.$store.dispatch('demo/setCurrentFootprint', cf) // problem statmeent
          }

          // Set the dates so that it only shows the current footprint. 
          // Note: not longer relevant, but times series logic relies on footprint_dates variable
          if (this.current_footprint) {
            // this.showFootprint(cf, this.visibleLayer.id)
            this.showFootprint(cf)
          } else {
            this.footprint_dates = this.dates
          }
        } else {
          if(this.layer && this.layer.includes('_')) { // check for footprint_id prop
            let split = this.layer.split('_')
            let ro = parseInt(split[0].substring(2))
            let lat = split[1]
            fp = this.footprints.find(footprint => {
              return (footprint.relative_orbit == ro && footprint.latitude == lat.latitude)
            })
          }
          // this.current_footprint = fp ? fp:this.footprints[0]
          // let cf = fp ? fp:null
          let cf = fp ? fp:this.footprints[0]
          this.$store.dispatch('demo/setCurrentFootprint', cf)
          this.showFootprint(cf)
        }
      })
    })

    // Get report information for regions
    this.$store.dispatch('demo/getDemoUserReports')
    .catch((err) => {
      if(err.response.status == '403') {
        this.$store.dispatch('logout')
        this.$store.dispatch('setDialog', { 
          show: true, 
          message: err.response.data, 
          status: err.response.status })
      }
    })
  },
  methods: {
    activateZoomControl() {
      this.$store.dispatch('demo/setZoom', 14.9)
      this.mapMaxZoom = 14.9
      this.$store.dispatch('demo/setZoomControl', true)
      this.zoomControlDialog = false
    },
    bringToFront(e, layer) {
      // console.log("Comparing '" + e.target.key + "' with '" + this.visibleLayer.id + "' ...")
      // console.log(layer.id)
      if (layer.id != this.visibleLayer.id) {
        e.target.bringToBack()
        // e.target.visible = true
        // e.target.bringToFront()
      } //else {
      //   e.target.bringToBack()
      // }

      // Increase timeSeriesLoaded value (part of the "loading" logic)
      this.timeSeriesLoaded += 1
    },
    deactivateZoomControl() {
      this.$store.dispatch('demo/setZoom', 15)
      this.$store.dispatch('demo/setZoomControl', false)
      this.zoomControlDialog = false
    },
    deleteHazard(pin) {
      this.delete_loading = true
      this.$store.dispatch('deleteHazard', pin.id)
      .then(() => {
        var target_url = '/demo/region/' + this.visibleLayer.map_id + "/" + this.visibleLayer.id
        this.$router.push(target_url)
        this.$router.go()
      })
      .catch(() => {
        this.delete_loading = false
      })
    },
    footprintChanged() {
      if(this.visibleLayer && this.visibleLayer.bounds) {
        this.$refs.map.fitBounds(this.visibleLayer.bounds)
      } else {
        this.center = [this.current_footprint.center_lat, this.current_footprint.center_lng]
      }
    },
    // getMaxZoom(json) {
    //   if(json == '' || json == null) return
    //   var info = JSON.parse(json)
    //   var max = 1
    //   if(!info.TileSets || !info.TileSets.TileSet) return 0
    //   info.TileSets.TileSet.forEach(function(zoom) {
    //     if(parseInt(zoom['@attributes'].href) > max) max = parseInt(zoom['@attributes'].href)
    //     // if(parseInt(zoom['@attributes'].href) < min) min = parseInt(zoom['@attributes'].href)
    //   })
    //   return max
    // },
    gpsModeChanged() {
      if(this.gpsMode == 1) {
        this.mapBounds = null
        this.map.locate({setView: false, watch: true, enableHighAccuracy: true})
      } else if(this.gpsMode == 2) {
        this.map.locate({setView: true, watch: true, enableHighAccuracy: true})
      } else if(this.gpsMode == 0) {
        this.mapBounds = null
        this.map.locate({setView: false, watch: false, enableHighAccuracy: false})
      }
    },
    locationFound(location) {
      this.gps = location.latlng
    },
    lockMap() {
      if(this.lockMapOn) {
        // Lock screen
        this.$store.dispatch('demo/setZoom', this.zoom)
        this.mapMaxZoom = this.zoom
        this.$refs.map.minZoom = this.zoom
        this.$refs.map.mapObject.dragging.disable()
      } else if (!this.lockMapOn) {
        this.$refs.map.minZoom = 0
        this.mapMaxZoom = 18
        this.$refs.map.mapObject.dragging.enable()
      }
    },
    showDate(dateItem) {
      // console.log(dateItem)
      this.dateMenuDialog = false
      // console.log(this.$refs[dateItem])
      // console.log(this.$refs.map)
      if(this.lockMapOn) this.$refs[dateItem].mapObject.bringToFront()
      let vl = null
      // this.visibleLayer = this.layers.find(layer => {
      vl = this.layers.find(layer => {
        return layer.id == dateItem
      })
      this.$store.dispatch('demo/setVisibleLayer', vl)
    },
    showFootprint(footprintItem, layer_id) {
      var footprint_list = this.dates.filter(function(el) {
        return el.relative_orbit == footprintItem.relative_orbit && el.latitude == footprintItem.latitude
      }).slice(0,3)
      
      if(footprint_list.length > 0) {
        this.footprint_dates = footprint_list

        if(layer_id) {
          this.showDate(layer_id)
        } else {
          this.showDate(this.footprint_dates[0].id)
        }
        this.$refs.map.fitBounds(this.visibleLayer.bounds)
      } else {
        this.center = [footprintItem.center_lat, footprintItem.center_lng]
      }
    },
    updateCenter(center) {
      this.center = center
    },
    updateZoom(zoom) {
      if (this.visibleLayer && zoom > 14.9 ) {
        if (this.zoomControl == null) {
          this.zoomControlDialog = true
        }
      } else {
        this.$store.dispatch('demo/setZoom', zoom)
      }
    },
    updateZoomControl() {
      if (!this.zoomControl) {
        this.mapMaxZoom = 20
      } else {
        this.mapMaxZoom = 14.9
      }
    },
  },
}
</script>

<style scoped>
>>>.leaflet-bottom {
  bottom: 0;
  left: 50%;
  transform: translate( -50%, 0%);
}

>>>.custom-control {
  background: #fff;
  padding: 5px;
  border: 1px solid #aaa;
  border-radius: 5px;
}

>>>.custom-control-2 {
  background-color: #fff;
  padding: 10px;
  border: 0;
  border-radius: 10px;
  opacity: 0.8;
  font-weight: bolder;
  font-size: large;
}

>>>.custom-control-2:hover {
  transition-duration: 200ms;
  opacity: 1;
}

>>>.v-dialog {
  position: absolute;
  bottom: 0;
  right: 0;
}

>>>.card-outter {
  position: relative;
  padding-bottom: 1.5em;
}

>>>.card-actions {
  position: absolute;
  top: 0;
  right:0;
}

>>>div.v-messages:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
  color: red;
}
</style>