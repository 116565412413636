<template>
    <v-container>
        <h1>Frequently Asked Questions</h1>
        <h4>ALB and Me</h4>
        <h4>The ALB Real-Time On-line Angling Platform</h4>
        <h4>ALB Competitions</h4>
        <h4>ALB Tournaments</h4>
        <h3>ALB and Me</h3>
        <h2>Am I a good fit for ALB?</h2>
        <p> ⦁ If you angle twice or more a month on average, you are considered a strongly interested or avid angler. Odds are you are a good fit. It’s time to join other like-minded members of the ALB community and have fun!</p>
        <p> ⦁ You don’t have to fish each month. To be eligible for all cash awards and prizes you would want to fish both challenge periods, i.e. warm and cold, each 12 times or more. Your effort could be spread out during each challenge period, or 12 days in a row.  The more you go fishing the better your odds!</p>
        <h2>What do I need to do to play ALB?</h2>
        <p> ⦁ You can fish the way you always have, where an internet connection exists, in your region. Fish one lake or fish many. Fish for one species or fish for many species.</p>
        <p> ⦁ You can host or join competitions</p>
        <p> ⦁ Join ALB Tournaments hosted by recognized outdoor associations</p>
        <p> ⦁ The Regional Leaderboard is the list of ranked angler catches. It changes in real-time as fish are caught. Visit the Regional Leaderboard often to see who’s leading the championships! </p>
        <h3>ALB - The Real-Time Angling Platform</h3>
        <h2>What is ALB?</h2>
        <p>ALB is a real-time on-line angling platform that uses your cell phone to:</p>
        <p> ⦁ Automatically map your fishing spots</p>
        <p> ⦁ Automatically track all your catches while you fish on your own where and when you want to</p>
        <p> ⦁ Host or join ALB Competitions and ALB Tournaments</p>
        <p> ⦁ Rank your catches against all other ALB members in your region by the moment, the day, and for the year</p>
        <p> ⦁ Rank your catches against all other ALB members in your region by the moment, the day, and for the year</p>
        <p> ⦁ The top anglers are determined two ways: </p>
        <p> ⦁ 1) those with the highest personal annual scores </p>
        <p> ⦁ 2) those with the highest scores determined from your best 24 days of the year (12 warm, 12 cold).</p>
        <p> ⦁ Your scores are determined from the fish length statistics for all fish species, and a fish catch diversity index. An advantage is provided to those that host and successfully complete competitions. One competition can be scheduled at a time. </p>
        <p> ⦁ Your angling, competition, and tournament scores are listed in the Regional Leaderboard, visible to the public, along with your accomplishments on the ALB</p>
        <p> ⦁ Level the playing field by enabling non-professional anglers to engage in competitions at their level of experience and at times and in places in which they are familiar</p>
        <p> ⦁ Promote angling, the angling community, and the conservation of your fisheries </p>
        <h2>How is ALB different?</h2>
        <p> ⦁ The ALB real-time angling platform has greatly simplified the process of recording reliable catch statistics and locations. This benefits all levels of anglers. ALB opens opportunities for less experienced anglers to challenge themselves and other anglers at levels that are comfortable to them and inexpensive, and still have the chance to win substantial awards and prizes like the Pro’s do. </p>
        <p> ⦁ Catch information is collected using a patent pending approach that combines your cell phone camera and our fish measurement board into an automatic length measurement and species detection system. </p>
        <h2>How does the PhotoMeasure! board work?</h2>
        <p> ⦁ The PhotoMeasure! board contains scale targets called QR codes. This enables your camera to know the physical size of a pixel in your photo in real measurement units (e.g. inches or centimetres). Our Artificial Intelligence software does the rest. </p>
        <p> ⦁ When following the photo guidelines as long as at least one QR code remains unobstructed a length measurement can be made. More than one unobstructed QR code is recommended.</p>
        <p> ⦁ You can hold the fish down on the PhotoMeasure! board in the mid-body area during the photo</p>
        <p> ⦁ The fish can otherwise be on an angle, or anywhere on the board. A vertical bump is not needed but is available as an optional accessory to attach to the end of your PhotoMeasure! board.</p>
        <h2>How does ALB detect fish species?</h2>
        <p> ⦁ The company that creates ALB, NextGen Environmental Research Inc. specializes in Artificial Intelligence, machine learning, and software application development. The species identifications and the length measurement techniques are based on large photo samples that train specialized artificial intelligence models.</p>
        <p> ⦁ If you submit a photo of a species that we do not yet have a photo database for then enter the species and length info. We will automatically add you photo and use it to make to ID that species in the future. </p>
        <h2>How do I know the fish species detections and PhotoMeasure! lengths are good?</h2>
        <p> ⦁ We publish the statistics for length measurements and species identification on the ALB website. </p>
        <p> ⦁ If you have a species that is not yet identified automatically you can still enter your species and length manually. Your photo will be automatically put into our training database so future photos of this species can be done automatically.</p>
        <h2>If the PhotoMeasure! board does not need or use a ruler, why is there one on it?</h2>
        <p> ⦁ The measurement ruler is for the users that choose to use it as a standard bump board. In that case they may have opted to purchase the vertical bump. We want ALB members to be armed with what they need. Sometimes that’s a vertical bump and an old-fashioned measurement ruler.</p>
        <h2>Photographs can be subject to distortion. Is this an issue with PhotoMeasure!?</h2>
        <p> ⦁ The effect of camera position and angle was studied extensively during the development of the PhotoMeasure! board. The method we developed effectively corrects for distortion. </p>
        <p> ⦁ If you follow the basic guideline of being vertical over the board at the center of the fish with the camera on the horizontal axis, in landscape orientation, then distortion is negligeable or is easily corrected. </p>
        <p> ⦁ If you submit a poorly taken photo by accident where distortion may be a factor, we notify you of that and reject the photo. </p>
        <h3>About Competitions</h3>
        <h2>Competitive angling often is done in teams with pairs of anglers. Do I have to choose a partner?</h2>
        <p> ⦁ No. ALB is designed as a competitive sport tool for individuals. If you choose to you can fish with another ALB member and share a PhotoMeasure! board, but your catches and statistics are your own.</p>
        <h2>Why individual scoring?</h2>
        <p> ⦁ Most anglers are competitive, but the fact is most anglers don’t compete. Here’s why: 1) It can be intimidating to take on those with more experience, in addition to cost of tournament entry, so most do not. 2) ALB wants you to become a better, more well-rounded angler. There are score advantages offered to those that are multi-species anglers. 3) ALB levels the playing field. You don’t have to have an expensive boat and 10 years of tournament experience to win ALB. When we say every catch counts, we mean it! You can be a riverbank or shoreline fisherman, fish out of a kayak, or your own boat. ALB is a platform that gives every angler a chance to win, a chance to improve and grow, and to be a member of an on-line and real-time community that looks at fishing in a whole new way.</p>
        <h2>How is an ALB competition different from an ALB tournament?</h2>
        <p> ⦁ The number of people that can participate. Competitions can be thought of as “friendly” competitions for people you know, or as one with fewer competitors than most tournaments hold, with people that you may not know. The maximum number of people that can join an angling competition varies by state or province due to regulation. You are required to know the regulations if you host ALB competitions.</p>
        <p> ⦁ The ALB maximum limit to host a competition using your personal name is 20 persons. </p>
        <p> ⦁ If you want to hold an ALB Tournament that exceeds that number of participants, you may need to apply for a permit from your state or province and provide that completed document to us. </p>
        <p> ⦁ All ALB tournaments must be held by a recognized outdoor association. </p>
        <p> ⦁ ALB competitions and tournaments are held within regions given that angling regulations are unique to each province or state.</p>
        <h2>What is the difference between Open and Closed competitions?</h2>
        <p> ⦁ Any ALB member can join an open competition in their region. Remember, the maximum number of anglers in a competition is capped in some areas due to government regulation. A closed competition is not open to all ALB members. An example of a closed competition could be, for example, an office outing held at a lodge where all participants are employees already known to the competition host. </p>
        <h2>Does the Competition host have access to real-time catch information during the event?</h2>
        <p> ⦁ Yes. All catches by all participants including the catch site and fish photo are visible in real-time. </p>
        <h2>As a Competition host do I have a responsibility to the participants?</h2>
        <p> ⦁ Yes. You will be provided information for every catch including the photography. The host can communicate with a participant while on the water in the case where an aspect of the photo needs to be clarified, or possibly removed from the competition. </p>
        <p> ⦁ You will also determine when the results are declared final and made public. Often, hosts delay results an hour or more to allow competitors to get off the water and prepare.</p>
        <h3>About Tournaments</h3>
        <h2>If I want to host a tournament, do I have to be an ALB subscriber? </h2>
        <p> ⦁ No. The applicant must represent a recognized outdoor association with a history of activity and provide official correspondence to us before access is granted. Contact us here.</p>
        <h2>Does the Tournament host organization pay to use ALB?</h2>
        <p> ⦁ No, when ALB is used by all competitors.  We generate a small revenue from each competitor.</p>
        <h2>Does the Tournament host have access to real-time catch information during the event?</h2>
        <p> ⦁ Yes. All catches by participants including the catch site and fish photo are visible in real-time. Other catch information is available as a purchase option.</p>
        <h2>Are publication black-outs, where the Tournament ranks are not updated, available?</h2>
        <p> ⦁ Yes. The Tournament host can set the start time and duration of the black out. </p>
        <h2>Do ALB Tournaments have to be held in one location?</h2>
        <p> ⦁ No. The format of your tournament is yours to decide. It must fall within one ALB region, however.</p>

    </v-container>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>