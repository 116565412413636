<template>
<v-container class="ma-0 pa-0 mb-10">
<v-card class="blue lighten-5 pb-3 mb-16">
    <v-card-title>Edit Competition</v-card-title>
    <v-row justify="center" class="mt-3 mb-1 pa-0 red--text" v-if="error">{{ this.error_msg }}</v-row>
    <v-row justify="center" class="mt-3 mb-1 pa-0 blue--text" v-if="edited">
        <span class="text-h4">Your Competition Has Been Updated!</span>
    </v-row> 
    <v-row justify="center" class="mt-3 mb-1 pa-0" v-if="edited">
        <v-btn v-if="!$vuetify.breakpoint.xs" @click="goBack"><v-icon right class="pr-2">mdi-arrow-left-bold-box</v-icon> Return to Summary</v-btn>
        <v-btn v-else icon large @click="goBack"><v-icon>mdi-arrow-left-bold-box</v-icon></v-btn>
    </v-row> 
    <v-card
        v-if="!edited"
        :class="set_padding_margins"
    >
    <div class="text-right mt-6 mr-4">
        <v-btn v-if="!$vuetify.breakpoint.xs" @click="goBack"><v-icon right>mdi-arrow-left-bold-box</v-icon></v-btn>
        <v-btn v-else icon large @click="goBack"><v-icon>mdi-arrow-left-bold-box</v-icon></v-btn>
    </div>
        <v-row class="px-4" :no-gutters="set_gutters">
            <v-col cols="12" sm="6">
                <v-text-field
                    v-model="name"
                    label="Competition Name"
                    type="text"
                    :rules="[field_rules.required]"
                >
                </v-text-field>                  
            </v-col>                
            <v-col cols="12" sm="6">
                <v-text-field
                    v-model="host"
                    label="Hosted By"
                    type="text">
                </v-text-field>
            </v-col>
        </v-row>                
        <v-row class="px-4" :no-gutters="set_gutters">
            <v-col cols="6" sm="6">
                <v-menu
                    v-model="start_menu"
                    :close-on-content-click="false"
                    transition="slide-x-transition"
                    min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="start_date"
                    label="Start Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="[field_rules.required]"
                ></v-text-field>
                </template>
                <v-date-picker
                    v-model="start_date"
                    no-title
                    scrollable
                    @input="start_menu = false"
                ></v-date-picker>
                </v-menu>
            </v-col>    
            <v-col cols="6" sm="6">
                <v-menu
                    ref="menu"
                    v-model="start_time_menu"
                    :close-on-content-click="false"
                    :return-value.sync="start_time"
                    transition="slide-x-transition"
                    max-width="300px"
                    min-width="300px"
                >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="start_time"
                    label="Start Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="[field_rules.required]"
                ></v-text-field>
                </template>
                <v-time-picker
                    v-if="start_time_menu"
                    v-model="start_time"
                    full-width
                    @click:minute="$refs.menu.save(start_time)"
                ></v-time-picker>
            </v-menu>
            </v-col>
        </v-row>    
        <v-row class="px-4" :no-gutters="set_gutters">
            <v-col cols="6" sm="6">
                <v-menu
                    v-model="end_menu"
                    :close-on-content-click="false"
                    transition="slide-x-transition"
                    min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="end_date"
                    label="End Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="[field_rules.required]"
                ></v-text-field>
                </template>
                <v-date-picker
                    v-model="end_date"
                    no-title
                    scrollable
                    @input="end_menu = false"
                ></v-date-picker>
                </v-menu>
            </v-col> 
            <v-col cols="6" sm="6">
                <v-menu
                    ref="menu1"
                    v-model="end_time_menu"
                    :close-on-content-click="false"
                    :return-value.sync="end_time"
                    transition="slide-x-transition"
                    max-width="300px"
                    min-width="300px"
                >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="end_time"
                    label="End Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="[field_rules.required]"
                ></v-text-field>
                </template>
                <v-time-picker
                    v-if="end_time_menu"
                    v-model="end_time"
                    full-width
                    @click:minute="$refs.menu1.save(end_time)"
                ></v-time-picker>
                </v-menu>
            </v-col>  
        </v-row>    
        <v-row class="px-4" :no-gutters="set_gutters">
            <v-col cols="12" sm="6">
                <v-autocomplete
                    v-model="region"
                    label="Region"
                    :items="region_list"
                    item-text='name'
                    item-value='name'
                >
                </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                    v-model="location"
                    label="Body of Water/Area"
                    type="text">
                </v-text-field>                    
            </v-col>
        </v-row>         
        <!-- <v-row class="px-4" :no-gutters="set_gutters">
            <v-col cols="12" sm="6">
                <v-text-field
                    v-model="buy_in"
                    label="Buy-in"
                    type="number"
                    step="0.01"
                    prefix="$"
                    >
                </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                    v-model="prize_pool"
                    label="Total"
                    type="number"
                    step="0.01"
                    prefix="$"
                    >
                </v-text-field>                    
            </v-col>
        </v-row>     -->
        <v-row class="px-4" :no-gutters="set_gutters">
            <v-col cols="12" sm="6">
                <v-select
                    label="Maximum Team Members"
                    v-model="team_size"
                    :items="max_team_size"
                >
                </v-select>
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                    v-model="team_limit"
                    label="Maximum Competing Teams"
                    type="number"
                    min="0"
                    step="1"
                >
                </v-text-field>
            </v-col>
        </v-row>     
        <v-row class="px-4" :no-gutters="set_gutters">
            <v-col cols="12" sm="6">
                <v-select
                    v-model="type"
                    :items="type_list"
                    label="Competition Type"
                >
                </v-select>
            </v-col>
            <v-col cols="12" sm="6">
                <v-select
                    v-model="visibility"
                    :items="visibility_list"
                    label="Visibility"
                >
                </v-select>
            </v-col>
        </v-row>           
        <v-row class="px-4" :no-gutters="set_gutters">
            <v-col cols="12" sm="6">
                <v-select
                    label="Scoring Criteria"
                    :onchange="check_if_amount_needed()"
                    v-model="sort_type"
                    :items="sort_list"
                >
                </v-select>
            </v-col>
            <v-col v-if="amount_needed" cols="12" sm="6">
                <v-select               
                    label="Maximum Amount"         
                    v-model="number_of_fish"
                    :items="max_fish_amount"
                >
                </v-select>
            </v-col>
        </v-row>          
        <v-row class="px-4" :no-gutters="set_gutters">
            <v-col cols="12" sm="6">
                <v-checkbox 
                    class="py-0 my-0" 
                    v-model="require_photo" 
                    label="Catches Must Have Photos" 
                    color="primary"
                ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="6">
            </v-col>
        </v-row>    

        <v-divider class="mx-2"></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="!expanded" icon @click="expand"><v-icon>mdi-arrow-expand</v-icon></v-btn>
            <v-btn v-else icon @click="expand"><v-icon>mdi-arrow-collapse</v-icon></v-btn>
        </v-card-actions>

        <v-expansion-panels 
            v-model="panel" 
            focusable 
            flat 
            multiple
        >                

            <v-expansion-panel>
                <v-expansion-panel-header><h3>Monetary</h3></v-expansion-panel-header>
                <v-expansion-panel-content>
                    
                    <v-container class="pa-8">
                        <v-row class="px-4" :no-gutters="set_gutters">
                            <v-col cols="12" sm="6" align="center">
                                <v-text-field
                                    v-model="buy_in"
                                    label="Buy-in"
                                    type="number"
                                    step="0.01"
                                    prefix="$"
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" align="center">
                                <v-btn
                                    color="white"
                                    @click="percentage_mode = !percentage_mode"
                                >
                                Change to {{ percentage_mode ? "$" : "%" }} Mode
                                </v-btn>
                            </v-col>
                            <!-- <v-col cols="12" sm="3" align="center">
                                <v-btn
                                    color="white"
                                    @click="validateFormValues()"
                                >
                                    Test Form
                                </v-btn>
                            </v-col> -->
                        </v-row>
                        <v-row class="px-4" :no-gutters="set_gutters">
                            <v-col align="center">
                                <v-btn
                                    color="white"
                                    @click="add_awards"
                                >
                                <v-icon left >mdi-seal</v-icon>
                                {{ (prizes_list === null || prizes_list.length === 0) ? "Add Awards" : "Add Another Award" }}
                                </v-btn>  
                            </v-col>
                        </v-row>
                    </v-container>

                    <v-container
                        v-for="(item, k) in this.prizes_list"
                        :key="k"
                        class="pa-0"
                    >
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn icon large @click="remove_awards(k)"><v-icon>mdi-delete</v-icon></v-btn>
                        </v-card-actions>

                        <v-row class="px-4" :no-gutters="set_gutters">
                            <v-col cols="12" sm="3">
                                <v-text-field
                                    v-model="item.place"
                                    label="Place"
                                    type="number"
                                    step="1"
                                >
                            </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="item.value"
                                label="Value"
                                type="number"
                                :step='(percentage_mode ? "1" : "0.01")'
                                :prefix='(percentage_mode ? "%" : "$")'
                                :error="value_error"
                                :error-messages="( value_error ? 'Value cannot be over 100%' : '')"
                                >
                            </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="ml-auto">
                                <v-textarea
                                v-model="item.text"
                                label="Description"
                                rows="2"
                                >

                                </v-textarea>
                            </v-col>
                        </v-row>
                        <!-- <v-row class="px-4 mt-0" :no-gutters="set_gutters">
                            <v-col cols="12" class="ml-auto">
                                <v-textarea
                                v-model="item.text"
                                label="Description"
                                >

                                </v-textarea>
                            </v-col>
                        </v-row> -->
                        <v-divider v-if="k != prizes_list.length-1" class="mx-2"></v-divider> 
                    </v-container>                    
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header><h3>Criteria</h3></v-expansion-panel-header>
                <v-expansion-panel-content>

                    <!-- <v-row
                    justify="start"
                    >
                        <v-col sm="3">
                            <v-card-title 
                                class="pt-0 black--text"
                                ref="comp_textarea"
                            >Species Allowed:
                            </v-card-title>
                        </v-col>
                        <v-col sm="4"
                        >
                            <v-autocomplete
                                v-model="species_allowed"
                                :items="species"
                                item-text='common_name'
                                item-value="id"
                                label="Species"
                                multiple
                                chips
                                ></v-autocomplete>
                        </v-col>
                    </v-row> -->
                    
                    <v-container class="pa-8">
                        <v-row>
                            <v-col align="center">
                                <v-btn
                                    color="white"
                                    @click="add_species"
                                >
                                <v-icon left >mdi-fish</v-icon>
                                {{ (species_list === null || species_list.length === 0) ? "Add Species" : "Add Another Species" }}
                                </v-btn>  
                            </v-col>
                        </v-row>
                    </v-container>

                    <v-container
                        v-for="(item, k) in this.species_list"
                        :key="k"
                        class="pa-0"
                    >
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn icon large @click="remove_species(k)"><v-icon>mdi-delete</v-icon></v-btn>
                        </v-card-actions>

                        <v-row class="px-4">
                            <v-col cols="12" sm="6">
                                <v-autocomplete
                                    v-model="item.species_id"
                                    :items="species"
                                    item-text='common_name'
                                    item-value="id"
                                    label="Species"
                                    ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-4">
                            <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="item.lower_slot"
                                label="Minimum Length"
                                type="number"
                                step="0.01"
                                suffix="cm"
                                >
                            </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-model="item.upper_slot"
                                    label="Maximum Length"
                                    type="number"
                                    step="0.01"
                                    suffix="cm"
                                    >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-4">
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-model="item.lower_weight"
                                    label="Minimum Weight"
                                    type="number"
                                    step="0.01"
                                    suffix="lb."
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-model="item.upper_weight"
                                    label="Maximum Weight"
                                    type="number"
                                    step="0.01"
                                    suffix="lb."
                                    >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <!-- <v-row class="px-4 pb-4 mt-0">
                            <v-col cols="12" sm="6">
                            <v-radio-group
                                v-model="item.score"
                                label="Score By"
                                class="mt-0"
                                row
                            >
                                <v-radio label="Length" value="length"></v-radio>
                                <v-radio label="Weight" value="weight"></v-radio>
                            </v-radio-group>
                            </v-col>
                        </v-row> -->
                        <v-divider v-if="k != species_list.length-1" class="mx-2"></v-divider> 
                    </v-container>                    
                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- <v-expansion-panel 
                :key="1"
                class="blue lighten-4 mt-2" 
            >
            <v-expansion-panel-header>
                <template v-slot>
                <v-row no-gutters>
                    <v-col cols="4">
                    Prizes:
                    </v-col>
                </v-row>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-textarea class="pa-4"
                v-model="prize_list"
                placeholder="The prizes give out for winning or placing within the competition. Example:
        1st place: 50$
        2nd Place: 25$
        3rd Place: 5$"
                ></v-textarea>
            </v-expansion-panel-content>
            </v-expansion-panel> -->

            <v-expansion-panel>
                <v-expansion-panel-header><h3>Rules</h3></v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-container class="px-0 pt-4 pb-0">
                        <v-textarea class="px-4"
                            v-model="rules"
                            outlined
                            hide-details
                            placeholder="The host may enter rules specific to this competition here"
                        ></v-textarea>     
                    </v-container>
                </v-expansion-panel-content>
            </v-expansion-panel>                

            <v-expansion-panel>
                <v-expansion-panel-header><h3>Blackout Period</h3></v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-container class="pa-8">
                        <v-row>
                            <v-col align="center">
                                <v-btn
                                    color="white"
                                    @click="add_blackout"
                                >
                                    <v-icon left >mdi-eye-off</v-icon>
                                    {{ blackout_list.length >= 1? "Add Another Blackout" : "Add Blackout"}}
                                </v-btn>  
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-container
                        v-for="(item, k) in this.blackout_list"
                        :key="k"
                        class="pa-0"
                    >
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn icon large @click="remove_blackout(k)"><v-icon>mdi-delete</v-icon></v-btn>
                    </v-card-actions>

                    <v-row class="px-4" align="center">
                        <v-col cols="6" sm="6">
                            <v-menu
                                v-model="item.blackout_start_menu"
                                :close-on-content-click="false"
                                transition="slide-x-transition"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="item.blackout_start_date"
                                    label="Start Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="item.blackout_start_date"
                                    no-title
                                    scrollable
                                    @input="item.blackout_start_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>                            
                        <v-col cols="6" sm="6">
                            <input 
                                name="shift_start_time" 
                                type="time"
                                class="form-input w-full relative z-10"
                                placeholder="Start time"
                                v-model="item.blackout_start_time"
                            />

                        <!-- <v-menu
                            ref="menu"
                            v-model="item.blackout_start_time_menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="item.blackout_start_time"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="item.blackout_start_time"
                                label="Start Time"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-time-picker
                            v-if="item.blackout_start_time_menu"
                            v-model="item.blackout_start_time"
                            full-width
                            @click:minute="$refs.menu.save(item.blackout_start_time)"
                            ></v-time-picker>
                        </v-menu> -->
                        <!-- @click:minute="$refs.menu3.save(item.blackout_start_time)" -->

                        </v-col>
                    </v-row>
                    <v-row class="px-4" align="center">
                        <v-col cols="6" sm="6">
                            <v-menu
                                v-model="item.blackout_end_menu"
                                :close-on-content-click="false"
                                transition="slide-x-transition"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="item.blackout_end_date"
                                    label="End Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="item.blackout_end_date"
                                    no-title
                                    scrollable                                    
                                    @input="item.blackout_end_menu = false"
                                ></v-date-picker>
                            </v-menu>   
                        </v-col>
                        <v-col cols="6" sm="6" >
                            <input 
                                name="blackout_end_time" 
                                type="time"
                                class="form-input w-full relative z-10"
                                placeholder="End time"
                                v-model="item.blackout_end_time"
                            />
                        </v-col>
                    </v-row>
                    <v-divider v-if="k != blackout_list.length-1" class="mx-2"></v-divider> 
                    </v-container>
                    </v-expansion-panel-content>      
                    
                <!-- <v-row
                    justify="start"
                >
                <v-col md="2">
                    <v-card-title 
                        class="pt-0 black--text"
                        ref="comp_textarea"
                    >Blackout Period:
                    </v-card-title>
                </v-col>
                <v-col md="2"
                >
                    <v-text-field
                        v-model="blackout_time"
                        type="number"
                        step="1"
                        suffix="Hours"
                    >
                    </v-text-field>
                </v-col>
                <v-col md="2">
                    <v-card-title 
                        class="pt-0 black--text"
                        ref="comp_textarea"
                    >Publishing Delay:
                    </v-card-title>
                </v-col>
                <v-col md="2"
                >
                    <v-text-field
                        v-model="delay_time"
                        type="number"
                        step="1"
                        suffix="Hours"
                    >
                    </v-text-field>
                </v-col>
                </v-row> -->                            

            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header><h3>Side Pot</h3></v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-container class="pa-8">
                        <v-row>
                            <v-col align="center">
                                <v-btn
                                    color="white"
                                    @click="add_sidepot"
                                >
                                    <v-icon left >mdi-cash-multiple</v-icon>
                                    {{ has_sidepot === true? "Remove Side Pot" : "Add Side Pot"}}
                                </v-btn>  
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-container v-if="has_sidepot">
                        <v-row class="px-2">
                            <v-col cols="12" sm="6">
                                <v-select
                                    v-model="sidepot_scoring"
                                    :items="[
                                        {value: 1, text: 'Total Length'}, 
                                        {value: 2, text: 'Total Weight'}, 
                                        {value: 3, text: 'Total Fish Caught'},
                                        {value: 4, text: ((this.number_of_fish > 1) ? this.number_of_fish + ' Longest Fish' : 'Longest Fish')}, 
                                        {value: 5, text: ((this.number_of_fish > 1) ? this.number_of_fish + ' Heaviest Fish' : 'Heaviest Fish')}
                                        ]"
                                    label="Side Pot Scoring"
                                ></v-select>                           
                            </v-col>                            
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-model="sidepot_amount"
                                    label="Total"
                                    type="number"
                                    step="0.10"
                                    prefix="$"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-expansion-panel-content>
            </v-expansion-panel> 

        </v-expansion-panels> 
        
        <v-card-actions class="ma-0 justify-center">           
            <v-btn 
                elevation="5"
                dark
                color="indigo"
                :loading="loading"
                @click="edit_comp"
            >
                Save Changes
            </v-btn>    
        </v-card-actions>      
        <v-row justify="center" class="mt-3 mb-1 pa-0 red--text" v-if="error">{{ this.error_msg }}</v-row>
        <v-row justify="center" class="mt-3 mb-1 pa-0 blue--text" v-if="edited"><span class="text-h4">Your Competition Has Been Updated!</span></v-row>                             
        </v-card>
    </v-card>
</v-container>  
</template>

<script>
import { mapState } from 'vuex';

export default {
    components:{
    },
    computed: {
        ...mapState('leaderboard', {
            species: state => state.species,
            regions: state => state.regions,
            competition: state => state.competition,
            comp_species: state => state.comp_species,
            comp_blackout: state => state.comp_blackout,
            comp_sidepot: state => state.comp_sidepot,
            comp_prizes: state => state.comp_prizes,
            
        }),
        font_size_title(){
            return !this.$vuetify.breakpoint.xs ? "text-h4 mb-0" : "text-h6 mb-0"
        },       
        font_size_value(){
            return !this.$vuetify.breakpoint.xs ? "text-h5" : "text-h6"
        },    
        set_gutters(){
            return !this.$vuetify.breakpoint.xs ? false : true
        },     
        set_padding_margins(){
            return !this.$vuetify.breakpoint.xs ? "pa-4 ma-3 py-2" : "ma-3"
        },          
    },
    data() {
        return {
            name: '',
            host: null,
            start_date: null,
            end_date: null,
            location: '',
            buy_in: null,
            prize_pool:null,
            // prize_list:null,
            team_size:1,            
            visibility:'Public',
            type:'Open',
            species_allowed:[],            
            rules:null,
            start_menu:false,
            end_menu:false,
            visibility_list:["Public", "Private"],
            type_list:["Open", "Request", "Invite Only"],
            max_team_size:[1, 2, 3, 4, 5],
            max_fish_amount:[1, 2, 3, 4, 5],
            team_limit: '10',
            loading: false,
            error: false,
            panel:[],
            expanded: false,
            panels: 3,
            region_list: [],
            region: '',
            // mb_dialog: false,
            // mb_form: false,
            // agree_dialog: false,
            // user_agreement: false,
            error_msg: "",
            start_time: null,
            start_time_menu: false,
            end_time: null,
            end_time_menu: false,
            // blackout_start_time: null,
            // blackout_start_time_menu: false,       
            // blackout_end_time: null,
            // blackout_end_time_menu: false,            
            species_list: [],
            edited: false,

            blackout_time: "",
            delay_time: "",
            blackout_list: [],
            require_photo:false,

            sort_type:"Longest '#' Fish",
            sort_list:["Total Weight", "Total Length", "Total Fish Caught", "Longest '#' Fish", "Heaviest '#' Fish"],
            number_of_fish: 1,     
            sidepot_scoring: null,
            sidepot_amount: null,
            has_sidepot: false,
            sidepot_deleted: false,
            amount_needed: true,

            field_rules: {
                required: value => !!value || 'Required',
            },
            percentage_mode: false, // false / 1 = $, true / 2 = %
            value_error: false,
            prizes_list: [],
        }
    },
    methods: {
        // changePage(page){
        //     this.$store.page = page
        //     // console.log(this.$store.page)
        //     // this.$emit('change-page')
        //     // vm.$forceUpdate()
        // },
        goBack(){
            // console.log(this.competition)
            this.$router.push(('/app/leaderboard/compsummary/' + this.$route.params.id))
            // this.$store.page = 'edit_comp'
            // this.$emit('change-page')
        },
        validateFormValues(){
            //Check if percentage is less that 100
            if(this.percentage_mode){
                let total_percent = 0
                this.prizes_list.forEach(item => {
                    total_percent += Number(item.value)
                })
                if(total_percent > 100){
                    this.value_error = true
                }else{
                    this.value_error = false
                }
                // console.log(total_percent)
            }
        },
        edit_comp(){
            // Validate all form data
            this.validateFormValues()

            // this.error = false
            // this.error_msg = ""
            // this.loading = true
            let vis_number = 0
            switch (this.visibility){
                case "Public":
                    vis_number = 1
                    break;
                case "Private":
                    vis_number = 0
                    break;
                default:
                    this.error = true
            }
            let type_number = 0
            switch (this.type){
                case "Open":
                    type_number = 1
                    break;
                case "Request":
                    type_number = 2
                    break;
                case "Invite Only":
                    type_number = 3
                    break;
                default:
                    this.error = true
            }

            let score_type = 1
            let amount = null
            switch (this.sort_type){
                case "Total Length":
                    score_type = 1
                    break;
                case "Total Weight":
                    score_type = 2
                    break;
                case "Total Fish Caught":
                    score_type = 3
                    break;
                case "Longest '#' Fish":
                    score_type = 4
                    amount = this.number_of_fish
                    break;
                case "Heaviest '#' Fish":
                    score_type = 5
                    amount = this.number_of_fish
                    break;
                default:
                    this.error = true
            }
            let sidepot = null
                if(this.has_sidepot){
                    sidepot = this.sidepot_scoring
                }
                // trying to solve/test null insert issue
            let host_val = null
            if(this.host !== null){
                host_val = this.host
            }
            let photo_required = 0;
            if(this.require_photo === true){
                photo_required = 1;
            }
            let buy_in_value = this.buy_in
            if(this.buy_in_other){
                buy_in_value = this.buy_in_other_value
            }
            // let sidepot = null
            // let new_sidepot_amount = null
            // if(!this.sidepot_deleted && this.comp_sidepot !== null){
            //     // console.log("inside sidepot not changed")
            //     sidepot = this.comp_sidepot[0].score
            //     new_sidepot_amount = this.comp_sidepot[0].payout
            // }
            // if(this.has_sidepot){
            //     sidepot = this.sidepot_scoring
            //     new_sidepot_amount = this.sidepot_amount
            // }
            let fixed_start = this.start_date + " " + this.start_time
            let db_start = this.getDBTime(fixed_start)
            let fixed_end = this.end_date + " " + this.end_time
            let db_end = this.getDBTime(fixed_end)

            let fixedBlackout = []
            this.blackout_list.forEach(element => {
                let bl_st = this.getDBTime((element.blackout_start_date + " " + element.blackout_start_time))
                let bl_en = this.getDBTime((element.blackout_end_date + " " + element.blackout_end_time))

                fixedBlackout.push({
                    blackout_start_date: bl_st.substring(0,10),
                    blackout_start_time: bl_st.substring(11,19),
                    blackout_end_date: bl_en.substring(0,10),
                    blackout_end_time: bl_en.substring(11,19),
                })
            });
            let prizes_type = (this.percentage_mode ? 2 : 1)
            let data = {
                comp_id: this.competition.id,
                name: this.name,
                host: host_val,
                start_date: db_start,
                end_date: db_end,
                location: this.region + ": " + this.location,
                buy_in: buy_in_value,
                prize_pool: this.prize_pool,
                team_size: this.team_size,
                visibility: vis_number,
                type: type_number,
                rules: this.rules,
                team_limit: this.team_limit,
                species: this.species_list, //
                blackout_date: fixedBlackout, //
                require_photo: photo_required,
                sidepot: sidepot,
                sidepot_amount: this.sidepot_amount,
                has_sidepot: this.has_sidepot,
                score_type: score_type,
                amount: amount,
                prizes: this.prizes_list,
                prizes_type: prizes_type
            }
            // console.log(data)

            this.$store.dispatch('leaderboard/editCompetition', data)
                .then(() => {
                })
                .catch((err) => {
                    this.error = true
                    this.err_message = 'Retry: ' + err
                })
                .finally(() => {
                    this.loading = false
                    this.edited = true
                    this.$store.dispatch('leaderboard/getCompetition')
                    // console.log(data)
            })
        },
        add_species(){
            // 
            if (this.species_list === null) {
                this.species_list = [{
                    species_id: '',
                    lower_slot:null,
                    upper_slot:null,
                    lower_weight:null,
                    upper_weight:null,
                    score: "length"
                }]
            }
            else {
                this.species_list.unshift({
                    species_id: '',
                    lower_slot:null,
                    upper_slot:null,
                    lower_weight:null,
                    upper_weight:null,
                    score: "length"
                })
            }
        },
        add_awards(){
            // 
            this.prizes_list.push({
                place: (this.prizes_list.length + 1),
                value: 1.00,
                text: '',
            })
            // console.log(this.species_list)
        },
        check_if_amount_needed(){
            let answer = false
            if(this.sort_type === "Longest '#' Fish" || this.sort_type === "Heaviest '#' Fish"){
                answer = true
            }
            this.amount_needed = answer
        },
        add_sidepot(){
            if(this.comp_sidepot){
                this.has_sidepot = true
            }else{
                this.has_sidepot = true
            }
        },
        delete_sidepot(){
            this.has_sidepot = false
        },
        add_blackout(){            
            this.blackout_list.unshift({
                blackout_start_date: '',
                blackout_start_time: '',
                blackout_end_date: '',
                blackout_end_time: '',
        })
        // console.log(this.blackout_list)
        },
        remove_species(item_number){
            this.species_list.splice(item_number,1)
        },
        remove_awards(item_number){
            this.prizes_list.splice(item_number,1)
        },
        remove_blackout(item_number){
            this.blackout_list.splice(item_number,1)
            // console.log(this.species_list)
        },
        expand(){
            if(!this.expanded){
                this.panel = [...Array(this.panels).keys()].map((k,i) => i)
            }else{
                this.panel = []
            }
            this.expanded = !this.expanded
        },
        check_type(){
            
        },
        backToComp(){
            this.$store.page = 'selected_comp'
            this.$emit('change-page')
        },
        getDBTime(date){
            let localdate = new Date(date)
            return (localdate.toISOString().slice(0, -1))
        }
    },
    mounted() {
        let id = this.$route.params.id
        this.$store.selected_competition = id

        this.$store.dispatch('leaderboard/getSpecies')
        this.$store.dispatch('leaderboard/getRegions').then(() => {
            this.region_list = Object.values(this.regions)
        })
        this.$store.dispatch('leaderboard/getCompetition').then(() => {
            // console.log(this.competition)
            // console.log(this.comp_blackout)

            switch (this.competition.visibility){
                case 1:
                    this.visibility = "Public"
                    break;
                case 0:
                    this.visibility = "Private"
                    break;
                default:
                    this.visibility = "Public"
            }

            switch (this.competition.type){
                case 1:
                    this.type = "Open"
                    break;
                case 2:
                    this.type = "Request"
                    break;
                case 3:
                    this.type = "Invite Only"
                    break;
                default:
                    this.type = "Open"
            }
            // console.log(this.competition)
            this.name = this.competition.name
            this.host = this.competition.host
            // this.start_date = this.competition.start_date
            // this.end_date = this.competition.end_date
            // this.start_time = this.competition.start_time
            // this.end_time = this.competition.end_time
            let start_array = this.competition.start_date.split(" ")
            let end_array = this.competition.end_date.split(" ")
            this.start_date = start_array[0]
            this.end_date = end_array[0]
            this.start_time = start_array[1]
            this.end_time = end_array[1]
            this.buy_in = this.competition.buy_in
            this.prize_pool = this.competition.prize_pool
            this.rules = this.competition.rules
            this.team_size = parseInt(this.competition.team_size)
            this.sort_type = this.sort_list[parseInt(this.competition.score_type)-1]
            this.number_of_fish = parseInt(this.competition.amount)
            this.team_limit = this.competition.team_limit
            this.species_list = this.comp_species           
            //** This will need to be updated when location is sperated in database
            let temp = this.competition.location
            this.region = this.competition.location.substring(0, this.competition.location.indexOf(":"));
            this.location = this.competition.location.substring((this.competition.location.indexOf(":")+2), temp.length)
            // console.log(this.comp_blackout)
            if(this.comp_blackout){
                this.comp_blackout.forEach(blackout => {
                    this.blackout_list.push({
                        blackout_start_date: blackout.start_date.substring(0,10),
                        blackout_start_time: blackout.start_date.substring(11,19),
                        blackout_end_date: blackout.end_date.substring(0,10),
                        blackout_end_time: blackout.end_date.substring(11,19),
                    })
                })
            }
            if(this.comp_prizes){
                this.comp_prizes.forEach(prize => {
                    // console.log(prize.type)
                    if(prize.type == 2){
                        this.percentage_mode = true
                        // console.log()
                    }
                    this.prizes_list.push({
                        place: prize.place,
                        value: prize.value,
                        text: prize.text,
                    })
                })
            }
            if(parseInt(this.competition.require_photo) === 1){
                this.require_photo = true
                
            }
            // let localdate = new Date(this.competition.start_date)
            // console.log(localdate.toString())
            // console.log(localdate.toISOString().slice(0, -1))
            
            if(this.comp_sidepot){
                this.has_sidepot = true
                this.sidepot_amount = this.comp_sidepot[0].payout                
                this.sidepot_scoring = parseInt(this.comp_sidepot[0].score)
            }
            
        })
        // console.log(this.sort_type)
    }
}
</script>

<style scoped>
>>>.col{
    padding: 12px;
    padding-bottom: 0px;
    padding-top: 0px;
}
>>>.card-title{
    padding: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
    margin: 0px;
}
</style>