<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
// import { Bar } from 'vue-chartjs'
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: { Bar },
  computed: {
    chartData() {
      let apc_data = [ 0, 0, 0, 0, 0, 0 ]
      let apc_labels = [ "&fa-pin;", "fa fa-pin", 'fa-pin', '&f05e;', 'f05e', '\uf119' ]
      let apc_backgrounds = [ '#E0E0E0', '#E0E0E0', '#E0E0E0', '#E0E0E0', '#E0E0E0', '#E0E0E0' ]

      this.access_point.data.forEach((x, index) => {
        apc_data[index] = x.votes < 1 ? 0:(x.votes / this.access_point.votes_sum) * 100
        apc_labels[index] = x.name
      })

      let max = Math.max(...apc_data)
      let indexes = []
      for (let index = 0; index < apc_data.length; index++) {
        if (apc_data[index] === max) {
          indexes.push(index);
        }
      }
      indexes = indexes.length == 6 ? []:indexes

      indexes.forEach(y => {
        apc_backgrounds[y] = '#FF5722'
      })

      return {
        labels: apc_labels,
        datasets: [
          { 
            backgroundColor: apc_backgrounds,
            data: apc_data,
            minBarLength: 10,
          },
        ]
      }
    }
  },
  props: {
    access_point: {
      type: Object,
      default: () => {}
    },
    sum: {
      type: Number,
      default: 0
    },
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 100
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // chartData: {
      //   labels: ["\uf24e", "\uf1b9", "\uf242"],
      //   datasets: [ { data: [40, 20, 12] } ]
      // },
      chartOptions: {
        maxBarThickness: 25,
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: 'Accessibility Condition based on User Votes'
          }
        },
        scales: {
          x: {
            grid: { display: false },
            minBarLength: 5,
          },
          y: {
            ticks: {
              callback: (value) => {
                return value + '%'
              },
              maxTicksLimit: 3,
            },
            type: 'linear',
            max: 100,
            min: 0
          }
        },
        //// None of the Tooltip options listed below are working, likely in wrong place
        // tooltip: {
        //   enabled: true,
        //   callbacks: {
        //     title: (item) => {
        //       return item + ' hello'
        //     },
        //     label: (item) => {
        //       // let vote_text = Number(item) > 1 ? 'votes':'vote'
        //       return Number(item.yLabel) + ' user votes'
        //     },
        //     afterlabel: (item) => {
        //       return item + ' afterlabel'
        //     }
        //   }
        // },
      }
    }
  }
}
</script>