<template>
    <v-container>
    <v-row class="pb-4" :justify="menu_align">
            <div class="pr-2 text-center">
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn                    
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    text
                    x-large
                    >
                    <div v-if="$vuetify.breakpoint.mobile"><v-icon>mdi-trophy-outline</v-icon></div>
                    <div v-else><v-icon left>mdi-trophy-outline</v-icon>Leaderboards</div>
                    </v-btn>
                </template>
                <v-list subheader>
                    <div v-if="$vuetify.breakpoint.mobile">
                        <v-subheader>Leaderboards</v-subheader>
                        <v-divider class="mx-1"></v-divider>
                    </div>
                    <v-list-item
                        v-for="(item, index) in leaderboard_pages"
                        :key="index"
                        @click="changePage(item.value)"
                    >{{ item.text }}
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
            <div class="px-2 text-center">
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    text
                    x-large
                    >
                    <div v-if="$vuetify.breakpoint.mobile"><v-icon>mdi-clipboard-list-outline</v-icon></div>
                    <div v-else><v-icon left>mdi-clipboard-list-outline</v-icon>Competitions</div>
                    </v-btn>
                </template>
                <v-list subheader>
                    <div v-if="$vuetify.breakpoint.mobile">
                        <v-subheader>Competitions</v-subheader>
                        <v-divider class="mx-1"></v-divider>
                    </div>
                    <v-list-item                    
                        v-for="(item, index) in comp_pages"
                        :key="index"
                        @click="changePage(item.value)"
                    >{{ item.text }}
                    </v-list-item>
                </v-list>
            </v-menu>
            </div>   
            <div class="pl-2 text-center">
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    text
                    x-large
                    >
                    <div v-if="$vuetify.breakpoint.mobile"><v-icon>mdi-help</v-icon></div>
                    <div v-else><v-icon left>mdi-information-slab-box-outline</v-icon>Need Help?</div>
                    </v-btn>
                </template>
                <v-list subheader>
                    <div v-if="$vuetify.breakpoint.mobile">
                        <v-subheader>Need Help?</v-subheader>
                        <v-divider class="mx-1"></v-divider>
                    </div>
                    <v-list-item                    
                        v-for="(item, index) in info_pages"
                        :key="index"
                        @click="changeInfoPage(item.value)"
                    >{{ item.text }}
                    </v-list-item>
                </v-list>
            </v-menu>  
            </div> 
    </v-row>
    <!-- <v-dialog
        v-model="overlay"
        hide-overlay
        :fullscreen="mobile_fullscreen"
        transition="dialog-bottom-transition"
        mode="out-in"
        no-click-animation
        persistent
      >
        <v-card class="pa-4">
            <v-row >
                <v-spacer></v-spacer>
                <v-card-actions class="pb-0">
                    <v-icon
                        large
                        @click="overlay = false"
                    >mdi-close-circle
                    </v-icon>                
                </v-card-actions>
            </v-row>
            <v-row>
                <About v-if="mode=='about'"/>
                <Faq v-if="mode=='faq'"/>
                <GettingStarted v-if="mode=='gettingstarted'"/>
            </v-row>
        </v-card>
      </v-dialog> -->
    </v-container>
</template>
<script>
//import 
import { mapState} from 'vuex';
// eslint-disable-next-line no-unused-vars
import { LControl } from 'vue2-leaflet'
// import About from './About.vue'
// import Faq from './Faq.vue'
// import GettingStarted from './GettingStarted.vue'

export default {
    components: {
        // About,
        // Faq,
        // GettingStarted
    },
    data(){
        return {
            selected_class: "green darken-4",
            other_class:"green darken-1",
            real_time_leaderboard_class:"green darken-1",
            personal_leaderboard_class:"green darken-1",
            create_comp_class:"green darken-1",
            ended_comps_class:"green darken-1",
            join_list_class:"green darken-4",
            comp_pages:[
                {value: 'complist', text: "Upcoming"},
                {value: 'finishedlist', text: "Finished"},
                {value: 'createcomp', text: "Create New"},
            ],
            // comp_btn: ,
            leaderboard_pages: [
                {value: 'all', text: "Regional"},
                // {value: 'toptenleaderboard', text: "Top 10"},
                // {value: 'dailyleaderboard', text: "Daily"},
                {value: 'personalleaderboard', text: "Personal"},
            ],
            info_pages: [
                {value: 'howto/alb', text: "How To"},
                {value: 'about/alb', text: "About"},
                {value: 'gettingstarted/alb', text: "Getting Started"},
                {value: 'faq/alb', text: "Frequently Asked Questions"},
            ],
            leaderboard_btn: null,
            overlay:false,
            mode:'about',
        }
    },
    computed: {
        ...mapState('leaderboard', {
            page: state => state.page,
        }),
        mobile_fullscreen() {
            return !this.$vuetify.breakpoint.xs ? false : true
        },
        menu_align() {
            return this.$vuetify.breakpoint.mobile ? 'space-around' : 'start'
        },
    },
    watch: {
        selectedPage: function () {
            this.real_time_leaderboard_class="green darken-1"
            this.personal_leaderboard_class="green darken-1"
            this.create_comp_class="green darken-1"
            this.ended_comps_class="green darken-1"
            this.join_list_class="green darken-1"
            switch(this.selectedPage){
                case 'join_list':
                    this.join_list_class = this.selected_class
                    break;
                case 'create_comp':
                    this.create_comp_class = this.selected_class
                    break;
                case 'real_time_leaderboard':
                    this.real_time_leaderboard_class = this.selected_class
                    break
                case 'personal_leaderboard':
                    this.personal_leaderboard_class = this.selected_class
                    break
                case 'ended_comps':
                    this.ended_comps_class = this.selected_class
                    break
            }
        }
    },
    methods:{
        compPageChange(){

        },
        // changePage(page){
        //     this.$store.page = page
        //     // console.log(this.$store.page)
        //     this.$emit('change-page')
        //     // vm.$forceUpdate()
        // },
        changePage(page){
            // console.log(page)
            this.$router.push(('/app/leaderboard/' + page))
            // window.location.href = '/app/leaderboard/' + page
            // this.$store.page = page
            // console.log(this.$store.page)
            // this.$emit('change-page')
            // vm.$forceUpdate()
        },
        changeInfoPage(page){
            // console.log(page)
            // this.mode = page
            // this.overlay = true
            this.$router.push(('/' + page))
            // window.location.href = '/app/leaderboard/' + page
            // this.$store.page = page
            // console.log(this.$store.page)
            // this.$emit('change-page')
            // vm.$forceUpdate()
        },
        clearSelectedClass(){
            this.real_time_leaderboard_class="green darken-1"
            this.create_comp_class="green darken-1"
            this.ended_comps_class="green darken-1"
            this.join_list_class="green darken-1"
            this.personal_leaderboard="green darken-1"
        }
    },
    props: [
    'selectedPage'
  ]
}
</script>
