<template>
  <v-container class="mb-15 pb-12">
    <v-layout row>
      <v-flex xs12>
        <v-card v-if="(!isLoggedIn || isTrialUser) && !isVerified" class="pa-4 ma-5">
          <v-card-title class="justify-center">
            <v-progress-circular v-if="verifying" class="mr-3" indeterminate />
            <v-icon
              v-else
              class="mr-4"
              :color="isVerified ? 'green':'red'"
            >
              {{ isVerified ? 'fa-user-check':'fa-user-times' }}
            </v-icon>
            {{ verifying ? 'Verifying Account':message }}
          </v-card-title>
          <v-card-actions v-if="isLoggedIn" class="justify-center">
            <v-btn 
              class="px-3"
              elevation="0"
              :loading="loading"
              @click="resend_verification"
            >
              {{ message_resend }}
            </v-btn> 
          </v-card-actions>
        </v-card>
        <v-card v-else class="pa-4 ma-5">
          <v-card-title class="justify-center">
            <v-icon class="mr-4" color="green">fa-user-check</v-icon>
            Account has been verified.
          </v-card-title>
          <v-card-actions class="justify-center">
            <v-btn 
              v-if="isLoggedIn"
              class="px-3"
              elevation="0"
              to="/"
            >
              Continue to Ice Time
            </v-btn>
            <v-btn 
              v-if="!isLoggedIn"
              class="px-3"
              elevation="0"
              to="/login"
            >
              Log In
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <Footer />
  </v-container>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapState } from 'vuex'
import Footer from '../../../components/Footer.vue'

export default {
  data() {
    return {
      isVerified: false,
      loading: false,
      message: "Resend Verification Email",
      message_resend: "Resend Verification Email",
      // verification_failed: false,
      verifying: false,
    }
  },
  components: {
    Footer,
  },
  computed: {
    ...mapGetters('user', {
      isExpiredTrialUser: 'isExpiredTrialUser',
      isLoggedIn: 'isLoggedIn',
      isTrialUser: 'isTrialUser',
    }),
    ...mapState('user', {
      user: state => state.user,
    }),
  },
  methods: {
    resend_verification() {
      this.loading = true
      this.$store.dispatch('user/resendVerification')
      .then(() => {
        this.message_resend = "Sent!"
      })
      .catch(err => {
        this.message = err.data
      })
      .finally(() => {
        this.loading = false
      })
    },
  },
  mounted() {
    this.$store.dispatch('setMode', 'verify')
    if ((this.isTrialUser || !this.isLoggedIn) && this.token && this.token.length == 128) {
      this.verifying = true
      this.$store.dispatch('user/verifyAccount', this.token)
      .then(() => {
        // console.log(resp)
        this.message = 'User Verification Successful'
        this.isVerified = true
      })
      .catch(err => {
        // this.verification_failed = true
        this.message = err.response.data
      })
      .finally(() => {
        this.verifying = false
      })
    } else if (this.isLoggedIn && !this.isTrialUser) {
      this.message = "Account is already verified."
    } else {
      this.message = "Could not verify account."
    }
  },
  props: ['token']
}
</script>
