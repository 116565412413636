<template>
   <v-dialog 
        class="ma-4 pb-12"
        hide-overlay
        no-click-animation
        persistent
        style=""
        v-model="visible" 
    > 
        <v-card
            class="pa-6 pb-12 mb-12"
            height="100%"
            flat
        >
            <v-container>
                <v-card-title>
                    <v-icon class="pr-4" color="icetimeblue">fa-ruler-combined</v-icon>
                    Personal Leaderboard
                </v-card-title>

                <v-btn @click="goToViewCatches">Catches</v-btn>
                <v-btn>Statistics</v-btn>
            </v-container>
        
            <v-container class="fill-height" style="background: #FAFAFA; border: 1px solid #FAFAFA; border-radius: 7px; overflow-y: scroll!important; height: 81%;">
            <!-- <v-card class="my-12" height="333" flat style="overflow-y: scroll;"> -->
                <v-card v-for="x in catches"
                    :key="x.id"
                    class="my-3 mx-0 py-2 pl-6 pr-2"
                    width="100%"
                >
                    <v-layout>
                        <v-flex>
                            <v-row>
                                <v-flex class="pa-4" shrink align-self-center >
                                    <v-icon color="light-blue" size="50">fa-fish</v-icon>                        
                                </v-flex>
                                <v-flex>
                                    <v-card-title>{{ x.common_name }}</v-card-title>
                                    <v-card-subtitle>
                                        {{ x.scientific_name }}
                                    </v-card-subtitle> 
                                    <v-card-text>Caught on {{ x.date }}</v-card-text>
                                </v-flex>
                            </v-row>

                            <v-row class="mb-3">
                                <v-col class="px-8">
                                    <v-icon pr="2">fa-ruler</v-icon> {{ x.length }}
                                </v-col>
                                <v-col class="px-8">
                                    <v-icon>mdi-arrow-expand-vertical</v-icon>  
                                </v-col> 
                                <v-col class="px-8">
                                    <v-icon color="pink">fa-hand-holding-heart</v-icon>
                                </v-col>
                            </v-row>
                        </v-flex>        
                    </v-layout>
                </v-card>
            <!-- </v-card> -->
            </v-container>
        </v-card>
   </v-dialog>
        
</template>

<script>
// import { LControl } from 'vue2-leaflet';
import { mapState } from 'vuex';

export default {
    data() {
        return {
            // visible: true, 
        }
    },
    components: {
        // LControl
    },
    computed: {
        ...mapState('spot_mapper', {
            catches: state => state.catches
        }) 
    },    
    methods: {
        goToViewCatches() {

        },

        toggleViewCatches() {

        }
    },
    props: [ 'visible' ]

}
</script>

<style scoped>
>>>.v-dialog{
    height: 75%;
    left: 0;
    bottom: 0; 
    max-width: 500px;
    overflow-y: hidden!important; 
    position: absolute;
    width: unset;
}

</style>