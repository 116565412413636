<template>
  <v-container>
    <p class="text-h5 pl-5 pt-8"><v-icon class="mr-3" color="teal">mdi-shimmer</v-icon>Your Lake Right Now</p>
    <v-alert class="mx-3" color="teal lighten-1" type="info">Please remember to always include the disclaimer <b>"Published with permission using an Ice Time Power license"</b> in your posts.</v-alert>
     <v-text-field 
      color="teal"
      class="px-4 pb-4 text-h6"
      v-model="search_string"
      prepend-inner-icon="mdi-file-search"
      placeholder="Search Your Lake Right Now"
    />
    <v-layout class="px-4" cols="12" row>
      <v-flex v-for="item in filtered_ylrn" :key="item.index" class="pa-4" xs12>
        <v-card class="pa-3 card-outter">
          <v-row>
            <v-col cols="12" xs="12" lg="6" class="ma-0 pa-0">
              <v-img 
                :src="item.filename"
                height="100%"
              />
            </v-col>
            <v-col cols="12" xs="12" lg="6" class="ma-0 pa-0">
              <v-chip class="mx-7 mt-7 white--text" color="teal" small>{{ item.date }}</v-chip>
              <v-card-title class="text-h6 mb-3 mx-3">
                {{ item.title }}
              </v-card-title>
              <v-card-subtitle class="text-body-1 pb-12 mx-3 mb-12" v-html="item.text">
              </v-card-subtitle>
              <v-card-actions class="card-actions">
                <v-spacer />
                <v-btn :href="item.filename" target="_blank">Download Full Image</v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {
      search_string: '',
      ylrn: [
        {
          date: '2021-12-14',
          filename: '/img/power/ylrn/ylrn_20211214_south_basin_lwpg_east_shore.png',
          title: "South Basin LWPG East Shore Rough and Smooth",
          text: "<p>Ice moves south. Main patterns of rough ice begin to establish for winter 2022.</p><p>The current Ice Time radar image shows, since the last image on December 2, most of the ice sheets in the southern basin moved south. This movement compressed and fractured the ice sheets to the south creating many new areas of rough ice. New patterns of rough ice are now visible on the west, south, and east coasts of the basin. The east side beaches have the greatest rough ice accumulations.</p><p>Published with permission using an Ice Time Power License.</p>"
        },
        {
          date: '2021-12-14',
          filename: '/img/power/ylrn/ylrn_20211214_south_basin_lwpg_refrozen_lead.png',
          title: "South Basin LWPG Refrozen Lead",
          text: "<p>Ice continues to move in the south basin. A new open water lead formed.</p><p>Ice movements continued to occur in the southern basin after December 2. The movement of ice floes after that image was taken created areas of open water that were much wider than visible before, some as wide as 7.5 km. After that, but before last nights image (December 14), a new open water lead formed along the west and southern coasts that was 42 km long and 300 m wide.</p><p>Published with permission using an Ice Time Power License.</p>"
        },
        {
          date: "2021-12-02",
          filename: '/img/power/ylrn/ylrn_20211202_south_of_hecla.png',
          title: "South of Hecla",
          text: "<p>High winds in central Canada and the northern USA yesterday have broken up the initial ice cover of many moderate and large lakes in the region. In the southern basin of Lake Winnipeg our radar imagery taken just hours ago shows the ice covers have fragmented and shifted downwind leaving massive open water leads. Large ice movements like this will create ice covers of different age and thickness. Stay on top of the changes during this extended period of unseasonably warm weather. Join the tribe of modern anglers that use Ice Time to remain situationally aware.</p><p>For the big picture: icetime.app</p><p>Published with permission using an Ice Time Power license.</p>"
        },
        {
          date: "2021-12-02",
          filename: '/img/power/ylrn/ylrn_20211202_south_of_hecla_no_symbols.png',
          title: "South of Hecla (No Symbols)",
          text: "<p>Please see the ice report in Ice Time.</p><p>Published with permission using an Ice Time Power license.</p>"
        },
        {
          date: '2021-12-02',
          filename: '/img/power/ylrn/ylrn_20211202_lotw_big_traverse.png',
          title: "LOTW Big Traverse",
          text: "<p>High winds in central Canada and the northern USA yesterday have broken up the initial ice cover of many moderate and large lakes in the region. In Lake of the Woods and the southern basin of Lake Winnipeg our radar imagery taken just hours ago shows the ice covers have fragmented and shifted downwind leaving massive open water leads. Large ice movements like this will create ice covers of different age and thickness. Stay on top of the changes during this extended period of unseasonably warm weather.  Join the tribe of modern anglers that use Ice Time to remain situationally aware.</p><p>For the big picture: icetime.app</p><p>Published with permission using an Ice Time Power license.</p>"
        },
      ]
    }
  },
  computed: {
    filtered_ylrn() {
      return this.ylrn.filter((item) => {
        let lower_text = item.text.toLowerCase()
        let lower_title = item.title.toLowerCase()
        let lower_str = this.search_string.toLowerCase()
        return lower_text.includes(lower_str) || lower_title.includes(lower_str) || item.date.includes(lower_str)
      })
    }
  }
}
</script>

<style scoped>
>>>.v-input__slot {
  padding: 15px;
}

>>>.v-input__prepend-inner {
  padding-right: 15px!important;
}

>>>.card-outter {
  position: relative;
}

>>>.card-actions {
  bottom: 0;
  position: absolute;
  right: 0;
  padding: 1.5em;
}
</style>
