<template>
  <l-control
    class="custom-control gps-control ml-16" 
    position="topright"
  >
    <v-tooltip left :disabled=is_mobile v-model="help">
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on" v-bind="attrs" class="d-inline-block">
          <!-- Test Period -->
          <!-- <v-btn 
            v-if="!clicked || no_layers"
            :disabled="!isProUser"
            icon
            @click="get_layers"
          > -->
          <v-btn 
            v-if="!clicked || no_layers || !show_select"
            icon
            @click="get_layers"
          >          
            <v-icon v-if="clicked && no_layers" color="grey">mdi-layers-remove</v-icon>
            <v-icon v-else color="blue">mdi-layers-search</v-icon>
          </v-btn>
          <v-select
            v-else-if="layers.length > 0"
            @change="layerChanged"
            :disabled="lockMapOn"
            :items="layers"
            :loading="!layers"
            v-model="selectedLayer"
            color="#2f4782"
            class="ml-2 pa-0 text-subtitle-2"
            dense
            flat
            hide-details="auto"
            item-value="id"
            item-color="#2f4782"
            persistent-hint
            prepend-icon="mdi-image-area-close"
            return-object
            solo
            style="max-width: 285px!important;"
          >
          <template v-slot:label>
            <div class="text-subtitle-2 black--text">{{ layers_hint }}</div>
          </template>
          <template v-slot:selection="data">
            <div class="text-subtitle-2">{{ data.item.date }} {{ data.item.resolution ? ` (${data.item.resolution})`:'' }} - {{data.item.name}}</div>
          </template>
          <template v-slot:item="data">
            <div class="text-subtitle-2">{{ data.item.date }} {{ data.item.resolution ? ` (${data.item.resolution})`:'' }} - {{data.item.name}}</div>
          </template>
          <template v-slot:prepend>
            <v-btn icon small>          
              <v-icon>mdi-image-area-close</v-icon>
            </v-btn>
          </template>           
          <template v-slot:append-outer>
            <v-btn icon small @click="close_select">          
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </template>              
          </v-select>
        </div>
      </template>
      <!-- Test Period -->
      <!-- <span v-if="!isProUser">Please subscribe to the {{  }} Region to View Radar Imagery</span> -->
      <!-- <span v-else-if="clicked && no_layers">No Radar Imagery Found</span> -->
      <span v-if="!clicked">Get Radar Imagery</span>
      <span v-else-if="clicked && no_layers">No Radar Imagery Found</span>
      <span v-else>Select Imagery Date</span>
    </v-tooltip>
  </l-control>
</template>

<script>
import { LControl } from 'vue2-leaflet'
import { mapGetters, mapState } from 'vuex'

export default {
  computed:{
    ...mapGetters('user', {
      'isProUser': 'isProUser',
    }),
    ...mapState('map', {
      current_region: state => state.current_region,
      layers: state => state.layers,
      lockMapOn: state => state.lockMapOn,
      clicked: state => state.radar_imagery_clicked,
      visibleLayer: state => state.visibleLayer,
      showHelp: state => state.showHelp,
      // selectedLayer: state => state.selectedLayer,
    }),
    layers_hint() {
      let text = ''
      if (this.layers.length > 0) {
        text = `${this.layers.length} image(s) found`
      } else {
        text = "No images available" 
      }
      return text
    },
    no_layers() {
      return !this.layers || this.layers.length == 0
    },
    selected() {
      return this.visibleLayer && this.visibleLayer.id ? this.visibleLayer:null
    },
    is_mobile() {
      return this.$vuetify.breakpoint.xs ? this.showHelp ? false : true : false
    }   
  },
  watch: {
    layers_hint() {
      this.selectedLayer = this.visibleLayer
      this.$emit('footprint-changed')
    },
    showHelp() {
      this.help = this.showHelp
    },
  },
  components: {
    LControl,
},
  data() {
    return {
      // clicked: false,
      // no_layers: false,
      help: false,
      selectedLayer: null,
      show_select: false,
    }
  },
  methods: {
    close_select() {
      this.show_select = false
      this.$store.dispatch('map/setRadarImageryClicked', false)
      this.$emit('reset-footprints')
    },
    get_layers() {
      // this.clicked = true

      this.$emit('radar-imagery-requested')    
        
      this.show_select = true
      // this.$store.dispatch('map/getLayers', {id: this.current_region})
      // .then(() => {
      //   if(this.layers.length == 0) {
      //     this.no_layers = true
      //   } else {
      //     // this.selected = this.layers[0]
      //     this.$store.dispatch('map/setVisibleLayer', this.layers[0])
      //   }
      // })
      // .catch(() => {
      //   this.no_layers = true
      // })
      // .finally(() => {
      //   // this.clicked = true
      //   this.$store.dispatch('map/setRadarImageryClicked', true)
      // })
    },
    layerChanged() {      
      let value = this.selectedLayer
      this.$store.dispatch('map/setCurrentFootprint', value)
      // this.$store.dispatch('map/setVisibleLayer', value)
      let image_type = value.type 
      this.$store.dispatch('map/getLegend', {image_type})   
      this.$emit('footprint-changed')
    },
  },
}
</script>

<style scoped>
>>>.custom-control-2:hover {
  transition-duration: 200ms;
  opacity: 1;
}

>>>.mdi-foot-print {
  color: #2f4782;
  padding-right: 8px;
}
</style>
