<template>
  <v-container class="mb-15 pb-15">
    <v-form ref="preferencesForm" lazy-validation>
      <v-layout cols="12" md12 lg8 offset-lg2 xl6 offset-xl3 row>
        <v-flex xs12>
          <p class="text-h5 ma-8">
            <v-icon class="mr-2">fa-cogs</v-icon>
            Ice Time Preferences
          </p>
        </v-flex>
        <v-flex xs12 sm6 lg4>
          <v-card class="mx-5 my-4 pa-4">
            <v-card-title>
              Maps
            </v-card-title>
            <v-card-text>Default drop-in / drop-out range</v-card-text>
            <!-- <v-card-title>Unit of Measure:</v-card-title> -->
            <!-- <v-card-text>
              <v-select
                :items="units"
                v-model="metric"
                item-text="name"
                item-value="value"
              >

              </v-select>
            </v-card-text> -->
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 lg4>
          <v-card class="mx-5 my-4 pa-4">
            <v-card-title>
              Email
            </v-card-title>
            <v-card-text>Newsletters</v-card-text>
            <v-card-text>Promotions</v-card-text>
          </v-card>
        </v-flex>
        <v-spacer />
        <v-flex md12 lg4>
          <v-card class="mx-5 my-4 pa-4">
            <v-card-title>
              Favorite Species
            </v-card-title>
            <v-card-text>
              <v-autocomplete 
                v-model="selectedSpecies"
                :items="species"
                item-text="common_name"
                item-value="id"
                dense
                label="Species"
                hide-details
                class="pb-2"
                multiple
                chips
                >
                <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  close
                  @click:close="remove(data.item.id)"
                >
                  {{ data.item.common_name }}
                </v-chip>
              </template>
              </v-autocomplete>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-alert v-if="infoMessage !== ''" type="info">
          <span v-html="infoMessage" />
        </v-alert>
        <v-flex xs12 >
          <v-layout row>
            <v-spacer />
            <v-btn 
              class="mt-7 mb-12" 
              color="primary"
              :loading="loading" 
              :width="$vuetify.breakpoint.mdAndDown ? '80%':'300'"
              @click="update_preferences"
            >
              Save
            </v-btn>
            <v-spacer />
          </v-layout>
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'

export default {
  data() {
    return {
      infoMessage: '',
      disabled: true,
      loading: false,
      selectedSpecies: [],
      metric: 1,
      units: [
        {name : "Metric", value : 1},
        {name : "Imperial", value : 2}
      ],
      filtered_species: [],
    }
  },
  computed: {
    ...mapState(['mailchimp_subscriptions']),
    ...mapState(['user']),
    ...mapState('spot_mapper', {
        species: state => state.species,
      }),
    ...mapState('user', {
        favorite_species: state => state.favorite_species,
      }),
  },
  methods: {
    update_preferences(){

      let data = {
        fav_species : this.selectedSpecies,
        metric: this.metric
      }
      // console.log(data)
      this.$store.dispatch('user/updatePrefrences', data)
    },
    remove (item) {
      // console.log(this.selectedSpecies)
      const index = this.selectedSpecies.indexOf(item)
      // console.log(index)
      if (index >= 0) this.selectedSpecies.splice(index, 1)
    },
    re_order_species(){
      // console.log(this.species)
      let temp_array = [...this.species]
      this.favorite_species.forEach(fav => {
        let temp_index = temp_array.map(function(a) { return a.id }).indexOf(fav.species_id)
        // let temp_index = this.species.id.indexOf(fav.species_id)
        // console.log(temp_index)
        if(temp_index === -1){
          // console.log("Species Not Found: " + fav.species_id)
        }else{
          // console.log("Species Found: " + fav.species_id + " index: " + temp_index)

          // let item = temp_array.splice(temp_index, 1)
          // console.log(item)
          // temp_array.splice(0, 0, item);
          // console.log(temp_array)
          
          temp_array.splice(0, 0, temp_array.splice(temp_index, 1)[0]);
          // console.log(temp_array)
          this.filtered_species = temp_array
        }
      })
    },
  },
  mounted() {
    this.$store.dispatch('setTab', 'preferences')
    this.$store.dispatch('spot_mapper/getSpecies').then(() => {
      this.$store.dispatch('user/getFavoriteSpecies').then(() => {
        this.metric = Number(this.user.user.metric)
        if(this.user.favorite_species.length > 0){
          // console.log("> 0")
          this.user.favorite_species.forEach(species => {
            this.selectedSpecies.push(species.species_id)
            // console.log(species)
            // this.re_order_species()
          });
          // this.filtered_species = [...this.species]
        }else{
          // this.filtered_species = [...this.species]
        }
      })
    })
    // .finaly(() => {
    //   this.re_order_species()
    // })
    
    // console.log(this.selectedSpecies)
    // console.log(this.user.favorite_species)
    // console.log(this.user.user)
  }
}
</script>
