<template>
  <v-tabs
    :align-with-title="!$vuetify.breakpoint.xs"
    :grow="$vuetify.breakpoint.xs"
    slider-color="amber"
    slider-size="3"
  >
    <v-tab key="reports" to="reports">
      <v-icon v-if="$vuetify.breakpoint.xs">fa-tools</v-icon>
      <span v-else>Report Management</span>
    </v-tab>
    <v-tab key="importer" to="importer">
      <v-icon v-if="$vuetify.breakpoint.xs">fa-upload</v-icon>
      <span v-else>Radar Importer</span>
    </v-tab>
    <v-tab key="users" to="users">
      <v-icon v-if="$vuetify.breakpoint.xs">fa-address-book</v-icon>
      <span v-else>User Management</span>
    </v-tab>
    <v-tab key="create_advisory" to="advisories">
      <v-icon v-if="$vuetify.breakpoint.xs">fa-file</v-icon>
      <span v-else>Advisory Management</span>
    </v-tab>
    <v-tab key="uploader" to="uploader">
      <v-icon v-if="$vuetify.breakpoint.xs">fa-download</v-icon>
      <span v-else>Species Trainer</span>
    </v-tab>
    <v-tab key="stats" to="stats">
      <v-icon v-if="$vuetify.breakpoint.xs">fa-chart-column</v-icon>
      <span v-else>Training Stats</span>
    </v-tab>
    <v-tab key="processingviewer" to="processingviewer">
      <v-icon v-if="$vuetify.breakpoint.xs">fa-images</v-icon>
      <span v-else>Computer Vision Viewer</span>
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  data() {
    return {
    }
  },
}
</script>