import Academy from './Academy.vue'

export const AcademyRoutes = [
  {
    path: '/academy',
    component: Academy,
    meta: {
      // requiresAuth: true,
      // requiresAdmin: true
    },
  },
]