<template>
  <l-control
    class="custom-control gps-control ml-16" 
    position="topright"
  >
    <v-tooltip v-if="visibleLayer.id" left :disabled=is_mobile v-model="help">
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on" v-bind="attrs" class="d-inline-block">
          <!-- Test Period -->
          <!-- <v-btn 
            v-if="!clicked || no_layers"
            :disabled="!isProUser"
            icon
            @click="get_layers"
          > -->
          <v-btn 
            icon
            @click="toggleThickness"
          >          
            <v-icon v-if="showThickness && thickness_layer == null" color="grey">mdi-layers-remove</v-icon>
            <v-icon v-else :color="showThickness ? 'deep-orange':'icetimeblue'">mdi-tape-measure</v-icon>
          </v-btn>

        </div>
      </template>
      <!-- Test Period -->
      <!-- <span v-if="!isProUser">Please subscribe to the {{  }} Region to View Radar Imagery</span> -->
      <!-- <span v-else-if="clicked && no_layers">No Radar Imagery Found</span> -->
      <span v-if="!showThickness">Get Ice Thickness</span>        
      <span v-else-if="showThickness && thickness_layer == null">Ice Thickness Not Available</span>
      <span v-else>Ice Thickness Available</span>
    </v-tooltip>
  </l-control>
</template>
  
<script>
import { LControl } from 'vue2-leaflet'
import { mapGetters, mapState } from 'vuex'

export default {
  computed:{
    ...mapGetters('user', {
      'isProUser': 'isProUser',
    }),
    ...mapState('map', {
      current_region: state => state.current_region,
      layers: state => state.layers,
      lockMapOn: state => state.lockMapOn,
      visibleLayer: state => state.visibleLayer,
      showThickness: state => state.thickness,
      thickness_layer: state => state.thicknessLayer,
      map: state => state.map,
      showHelp: state => state.showHelp,
    }),
    is_mobile() {
      return this.$vuetify.breakpoint.xs ? this.showHelp ? false : true : false
    },     
  },
  watch: {
    showHelp() {
      this.help = this.showHelp
    },
  },  
  components: {
    LControl,
  },
  data() {
    return {
      help: false,
    }
  }, 
  methods: {
    toggleThickness() {
      if(this.showThickness && this.thickness_layer != null) {
        this.$emit('hide-thickness')
      }
      else if(!this.showThickness) {
        this.$emit('get-thickness')
      }        
    },      
  },

}
</script>

<style scoped>
>>>.custom-control-2:hover {
  transition-duration: 200ms;
  opacity: 1;
}

>>>.mdi-foot-print {
  color: #2f4782;
  padding-right: 8px;
}
</style>
  