<template>
  <l-control 
    class="custom-control gps-control ml-16" 
    position="topright"
  >
    <v-tooltip left :disabled=is_mobile v-model="help">
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on" v-bind="attrs" class="d-inline-block">      
          <v-btn 
            v-if="!clicked"
            icon
            @click="clicked = !clicked"
          >          
            <v-icon v-if="clicked && no_layers" color="grey">mdi-layers-remove</v-icon>
            <v-icon v-else color="blue">mdi-map</v-icon>
          </v-btn>

          <v-select
            v-else
            @change="regionChanged"
            :disabled="lockMapOn"
            :items="active_regions"
            :hint="regions_hint"
            :loading="(!selected && regions.length > 0) || regions.length == 0"
            :value="selected"
            class="ml-2 pa-0 text-subtitle-2"
            color="#2f4782"
            dense
            flat
            hide-details="auto"
            item-color="#2f4782"
            item-text="name"
            item-value="radar_id"
            persistent-hint
            return-object
            solo
          > 
          <template v-slot:prepend>
            <v-btn icon small>          
              <v-icon>mdi-map</v-icon>
            </v-btn>
          </template>           
          <template v-slot:append-outer>
            <v-btn icon small @click="clicked = false">          
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </template>    
          </v-select>
        </div>
      </template>        
      <span>Select Region</span>     
    </v-tooltip>    
  </l-control>
</template>

<script>
import { LControl } from 'vue2-leaflet'
import { mapGetters, mapState } from 'vuex'

export default {
  computed:{
    ...mapGetters('user', ['isLoggedIn']),
    ...mapState(['regions']),
    ...mapState('map', {
      current_region: state => state.current_region,
      lockMapOn: state => state.lockMapOn,
      showHelp: state => state.showHelp,
    }),
    ...mapState('user', {
      user_primary_region: state => state.user.primary_region,
    }),
    selected() {
      return this.current_region ? this.current_region:null
    },
    is_mobile() {
      return this.$vuetify.breakpoint.xs ? this.showHelp ? false : true : false
    }       
  },
  watch: {
    showHelp() {
      this.help = this.showHelp
    },
  },
  components: {
    LControl,
  },
  data() {
    return {
      help: false,
      regions_hint: '',
      active_regions: [],
      clicked: false,
    }
  },
  methods: {
    regionChanged(value) {           
      this.clicked = false
      this.$store.dispatch('map/setCurrentRegion', value)
      this.$emit('region-changed')      
    },
    adjust_region() {
      let id = null   
      let search_value = '1'    
      
      if(this.isLoggedIn)
        search_value = this.current_region

      id = this.regions.find(({radar_id}) => radar_id === search_value)
        
      this.$store.dispatch('map/setCurrentRegion', id)
      this.$emit('region-changed')
      // console.log(this.current_region)
    }
  },
  created() {
    if (this.regions.length == 0) { 
      this.$store.dispatch('getRegion')
      .then(() => {
        this.active_regions = this.regions.filter(region => region.active == 1)        
      })      
    }
    else {
      this.active_regions = this.regions.filter(region => region.active == 1)
    }    

    // this.$emit('region-changed')
  }
  // mounted() {
  //   if (this.regions.length == 0) { 
  //     this.$store.dispatch('getRegion')
  //     .then(() => {
  //       this.adjust_region()             
  //     })
  //   } else {
  //     this.adjust_region()
  //   }
  // }
}
</script>

<style scoped>
>>>.custom-control-2:hover {
  transition-duration: 200ms;
  opacity: 1;
}

>>>.fa-map-marked-alt {
  color: #2f4782;
  margin-right: 18px;
}
</style>
