<template>
  <v-card
    class="ma-5 pa-4"
  >
    <v-card-title>
      Ice Time Regions
    </v-card-title>
    <v-card-text class="text-body-1">
      Ice Time&trade; operates by geographic regions because the climate of your area differs from others. Regions also enable us to provide information that is specific to the areas you are interested in. Regions are often the same as provinces or states, except in cases where they cover large areas.  
    </v-card-text>
    <v-card-text>
      ( Figure – Geographic regions. )
    </v-card-text>
    <v-card-text class="text-body-1">
      Ice Time Free&trade; operates in one region of your choice for free. You can add additional regions for a small fee. Subscriptions decrease in cost for each additional region added.
    </v-card-text>
    <v-card-text class="text-body-1">
      As Ice Time&trade; grows, new regions will be added. Click here to see the areas imaged this winter. 
    </v-card-text>
  </v-card>
</template>
  
  <script>
  export default { 
    data() {
      return {
      }
    },
    methods: {
    }
  }
  </script>
  