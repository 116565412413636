<template>
  <span>
    <v-form
      lazy-validation
      ref="signupForm"
    >
      <v-text-field 
        autofocus
        id="alias" 
        label="Alias"
        name="alias"
        required
        type="text"
        validate-on-blur
        v-model="alias"
        v-on:blur="aliasBlur"
        :error-messages="alias_message"
        :loading="checking_alias"
        :rules="[notNullAlias, aliasFree]"
      />
      <v-text-field
        id="email" 
        label="Email" 
        name="email"
        required
        type="email"
        validate-on-blur
        v-model="email" 
        v-on:blur="emailBlur"
        :error-messages="email_message"
        :loading="checking_email"
        :rules="[...email_rules, emailFree]" 
      />
      <!-- <v-text-field 
        id="confirmEmail" 
        label="Confirm Email" 
        name="confirmEmail" 
        type="email" 
        v-model="confirmEmail" 
        validate-on-blur
        :rules="[compareEmails]"
      /> -->
      <v-text-field 
        id="password" 
        label="Password" 
        name="password" 
        required
        :type="show_pass ? 'text' : 'password'"
        v-model="password" 
        validate-on-blur
        hint="Password length must be at least 10 characters"
        :rules="[notNullPassword, tenOrGreater]"
        :append-icon="show_pass ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="show_pass = !show_pass"
      />
      <!-- <v-text-field 
        id="confirmPassword" 
        label="Confirm Password" 
        name="confirmPassword" 
        v-model="confirmPassword"
        validate-on-blur
        type="password" 
        :rules="[comparePasswords]"
      /> -->
      <!-- <v-select
        item-text="name"
        label="Primary Region"
        :items="regions"
      /> -->
      <v-autocomplete
        item-text="name"
        item-value="radar_id"
        label="Primary Region"
        v-model="primary_region"
        :items="regions"
        :rules="[notNullPrimaryRegion]"
      />
    </v-form>
    <v-btn
      class="mr-4 my-4"
      color="primary"
      @click.prevent="signup"
    >
      Sign Up
    </v-btn>
    <!-- <v-btn class="white--text" color="grey" to="/">
        Go Back Home
    </v-btn> -->
  </span>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['footprint_list']),
    ...mapState(['regions']),
    aliasFree() {
      return () => this.alias_message === "" || this.alias_message
    },
    emailFree() {
      return () => this.email_message === "" || this.email_message
    },
    // compareEmails() {
    //   return () => (this.email === this.confirmEmail) || 'Emails do not match.'
    // },
    // comparePasswords() {
    //   return () => (this.password === this.confirmPassword) || 'Passwords do not match.'
    // },
    notNullAlias() {
      return () => this.alias != "" || "Please specify a unique alias."
    },
    // notNullName() {
    //   return () => this.name != "" || "Name is required."
    // },
    notNullPassword() {
      return () => this.password != "" || "Password is required."
    },
    tenOrGreater() {
        return () => this.password.length > 9 || "Password needs to be 10 or more characters."
    },    
    includeSpecialChar() {
        return () => !this.password.match(/^[a-z0-9]+$/i) || "Password needs at least 1 special character: ~`!@#$%^&*()-_+={}[]|\\;:\"<>,./?"
    },
    notNullPrimaryRegion() {
      return () => this.primary_region != "" || "A primary region is required."
    },
    // regions() {
    //   let x = []
    //   let y = ''
    //   let isFirst = true
    //   // return [ 
    //   //   ...this.regions_manitoba, 
    //   //   { divider: true }, 
    //   //   ...this.regions_ontario,
    //   //   { divider: true },
    //   //   ...this.regions_minnesota
    //   // ]

    //   this.footprint_list.forEach( fp => {
    //     if (y !== fp.radar_id) {
    //       if (isFirst) {
    //         isFirst = false
    //         x.push(
    //           { header: fp.region_name }
    //         )
    //       } else {
    //         x.push(
    //           { divider: true },
    //           { header: fp.region_name }
    //         )
    //       }

    //       y = fp.radar_id
    //       x.push(
    //         { text: fp.name, value: fp.id }
    //       )
    //     } else {
    //       y = fp.radar_id
    //       x.push(
    //         { text: fp.name, value: fp.id }
    //       )
    //     }
    //   })

    //   return x
    // }
  },
  created() {
    this.$store.dispatch('getFootprintList')
    this.$store.dispatch('getRegion')
  },
  data() {
    return {
      address: 'na',
      alias: '',
      alias_message: '',
      checking_alias: false,
      checking_email: false,
      city: 'na',
      confirmEmail: '',
      confirmPassword: '',
      countries: [
        'CA','US'
      ],
      country: 'CA',
      email: '',
      email_message: '',
      email_rules: [
        v => !!v || "Email is required.",
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(v) || 'E-mail must be valid.',
      ],
      infoMessage: '',
      name: 'na',
      password: '',
      phone: 'na',
      postal_zip: 'ba',
      primary_region: '',
      show_pass: false,    
      // regions_manitoba: [
      //   {
      //     header: 'Manitoba'
      //   },
      //   {
      //     text: 'Lake Winnipeg (South)'
      //   },
      //   {
      //     text: 'Lake Manitoba',
      //   }
      // ],
      // regions_ontario: [
      //   {
      //     header: 'Ontario'
      //   },
      //   {
      //     text: 'Algonquin',
      //   },
      //   { 
      //     text: 'Kawartha',
      //   },
      //   {
      //     text: 'Lake of the Woods',
      //   },
      //   {
      //     text: 'Lake Simcoe',
      //   },
      //   {
      //     text: 'Leech Lake',
      //   },
      //   {
      //     text: 'Rainy Lake'
      //   }
      // ],
      // regions_minnesota: [
      //   {
      //     header: 'Minnesota'
      //   },
      //   {
      //     text: 'Kabetogama Lake',
      //   },
      //   {
      //     text: 'Lake of the Woods',
      //   },
      //   { 
      //     text: 'Lake Superior',
      //   },
      //   {
      //     text: 'Leech Lake',
      //   },
      //   {
      //     text: 'Mille Lacs',
      //   },
      //   {
      //     text: 'Red Lake',
      //   },
      //   {
      //     text: 'Rainy Lake',
      //   }
      // ]
    }
  },
  methods: {
    aliasBlur() {
      if (this.alias) {
        this.checking_alias = true
        this.$store.dispatch('checkAlias', this.alias)
        .then((resp) => {
          this.alias_message = resp.data == '0' ? "":"This alias is not available."
        })
        .catch(err => {
          this.alias_message = err.response.data
        })
        .finally(() => {
          this.checking_alias = false
        })
      }
    },
    emailBlur() {
      this.checking_email = true
      this.$store.dispatch('checkEmail', this.email)
      .then((resp) => {
        this.email_message = parseInt(resp.data) > 0 ? "This email is already registered with Ice Time.":""
      })
      .catch(err => {
        this.email_message = err.response.data
      })
      .finally(() => {
        this.checking_email = false
      })
    },
    signup() {
      this.infoMessage = ''
      // this.loadingSignUp = true
      // this.infoMessage = 'Signing up...'
      // this.$emit('signup-clicked')
      this.$store.dispatch('setSignUpMessage', 'Signing up ...')

      if (this.$refs.signupForm.validate()) {
        this.e6++
        this.$emit('signup-clicked')

        let data = {
          alias: this.alias,
          email: this.email,
          password: this.password,
          // name: this.name,
          // address: this.address,
          // city: this.city,
          // postal_zip: this.postal_zip,
          primary_region: this.primary_region,
          // country: this.country,
          // phone: this.phone,
        }

        var msg = ''
        this.$store.dispatch('user/signup', data)
        .then(()=> {
          // this.$router.push('/')
          this.loadingSignUp = false
          msg = "<b>Account created for " + this.email + "!</b> Please check your email for the activation link. If you do not receive the link within 24 hours, please contact <a style='font-weight: bold;' href='mailto:hello@icetime.app'>hello@icetime.app</a> for further assistance."
          this.$store.dispatch('setSignUpMessage', msg)
          this.$emit('registration-successful')
          // setTimeout(() => {
          //   this.$router.push('/login')
          //   this.$emit('registration-successful')
          //   // this.e6++
          // }, 10000)
        })
        .catch(err => {
          if (err.response.status == '500') {
            msg = 'An unknown error occurred.'
          } else {
            this.loadingSignUp = false
            msg = err.response.data
          }
          this.$store.dispatch('setSignUpMessage', msg)
          this.$emit('registration-unsuccessful')
        })
        
      } else {
        this.loadingSignUp = false
      }

      this.$emit('signup-post-return')
    },
  },
}
</script>
