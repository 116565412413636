<template>
    <v-card class="ma-4 pa-2 mb-16">
        <v-card-title>
            The Backstory
        </v-card-title>
        <v-card-text>
            {{ Math.floor(((currentPage + 1) / totalPages) * 100) }}%
            <v-progress-linear :value="Math.floor(((currentPage + 1) / totalPages) * 100)">
            </v-progress-linear>
        </v-card-text>
        <v-card flat>
            <v-slide-x-transition leave-absolute hide-on-leave>
                <v-card-text v-if="currentPage == 0">
                    Satellite radar is still a relatively new technology. In truth it is complicated. Fortunately, NextGen Environmental Research Inc. (NGE), the makers if Ice Time&#8482;, 
                    have studied radar satellite images since Canada put up its first radar satellite in 1996. NGE has worked closely with the late Dr. David G. Barber, a world-renowned 
                    radar and climate scientist with the Center for Earth Observation Science at the University of Manitoba. NGE has also worked closely with the Canadian Space Agency 
                    since 2017 conducting the groundbreaking on-ice research that has proven how satellite radar can be used to monitor lake ice formation, deformation, and degradation. 
                    Governments around the world have committed to this “all weather” imaging technology, some with signed agreements to continue to deploy radar satellites past 2040. 
                    Satellite radar imaging is here to stay. 
                </v-card-text>
            </v-slide-x-transition>
            <v-slide-x-transition leave-absolute hide-on-leave>
                <v-card-text v-if="currentPage == 1">
                    So, why is it that I am seeing this satellite radar technology early? Dr. Paul M. Cooley, the president of NGE, is a snowmobiler and avid ice angler. He also is a 
                    published radar satellite data scientist. He spent much of his life on lake ice and understands the pains of ice travel. Paul recognized that there was nothing 
                    available to educate and help people make better choices about ice travel. Should we go? If we do choose to go, what is the smartest and least risky route? The 
                    problem is that travel plans often are made at home with little or no knowledge about the ice. When you finally get to the shore the risk is elevated. You've already 
                    invested time and money into your day. Each year millions of anglers make decisions from shore that risk their lives and gear literally step by step. What is happening 
                    near shore is often different than that of the offshore, the area you cannot see from land. This is an age-old problem but now we have a new solution, Ice Time&#8482;!
                    The unfortunate truth about the risks of ice travel is revealed by the statistics. The Canadian Red Cross Reports that every 10 years about 500 Canadians are lost to 
                    cold water immersion. Most of these are recreational. 
                </v-card-text>
            </v-slide-x-transition>
            <v-slide-x-transition leave-absolute hide-on-leave>
                <v-card-text v-if="currentPage == 2">
                    In 2017, Paul started NGE with the goal to make lake ice travel safer. An unsolicited proposal was sent to the Canadian Space Agency. This was the start of Ice Time&#8482;, 
                    the world's first service to make lake ice travel safer. Now, you have an app that can show you the lake ice dynamics before you plan to travel, and you can do this from 
                    the comfort of your home! We believe that the use of Ice Time&#8482; can prevent most cold-water immersions through education and monitoring.
                </v-card-text>
            </v-slide-x-transition>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="currentPage != 0 && currentPage != totalPages" @click="prevPage">Prev</v-btn>
                <v-btn v-if="currentPage != totalPages -1 && currentPage != totalPages " @click="nextPage">Next</v-btn>
                <v-btn v-if="currentPage == totalPages -1" @click="nextSection">Next Section</v-btn>
            </v-card-actions>
        </v-card>
    </v-card>
</template>
    
<script>
import goTo from 'vuetify/lib/services/goto'

export default { 
    data() {
        return {
            currentPage: 0,
            totalPages: 3,
        }
    },
    props: [],
    computed: {
    },
    methods: {
        nextPage() {
            this.currentPage++
            goTo(0)
        },
        prevPage() {
            this.currentPage--
            goTo(0)
        },
        nextSection() {
            this.$emit('next-section')
            goTo(0)
        },
    },
}
</script>

<style>
</style>