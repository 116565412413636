// import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

// If API is needed
Vue.use(Vuex)
// axios.defaults.baseUrl = Vue.$apiEndpointUrl

const state = {
};

const getters = {

};

const mutations = {
};

const actions = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}