<template>
  <v-container>
    <v-layout row>
    <v-flex xs12 sm4 offset-sm4>
      <v-card class="pa-4 ma-5" v-if="link_not_sent">
        <v-card-title>
          Forgot your password?
        </v-card-title>
        <v-card-subtitle>
          Please enter your email address and we'll send you a link to reset your password
        </v-card-subtitle>            
        <v-card-text>
          <v-container>
            <form @submit.prevent="login">
              <v-layout row>
                <v-flex xs12>
                <v-text-field 
                    ref="email" 
                    name="email" 
                    label="Email" 
                    id="email" 
                    v-model="email" 
                    type="email"
                    :error-messages="email_message"
                    :loading="checking_email"                        
                    :rules="[...email_rules, foundEmail]"
                    required></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs12>
                  <v-btn block type="submit">Confirm</v-btn>
                </v-flex>
              </v-layout>
              <v-alert v-if="infoMessage !== ''" type="info" class="mt-8">{{ infoMessage }}</v-alert>
            </form>
          </v-container>
        </v-card-text>
      </v-card>
      <v-card class="pa-4 ma-5 align-center justify-center" v-else>
        <v-card-title>
          Link Sent!
        </v-card-title>
        <v-card-subtitle>
          Please check your email ({{ email }}) for the password reset link
        </v-card-subtitle>   
        <v-card-text>
          <v-container>
              <v-layout row>
                <v-flex xs12>
                  <v-btn block href="/">Return To Main</v-btn>
                </v-flex>
              </v-layout>
              <v-alert v-if="infoMessage !== ''" type="info" class="mt-8">{{ infoMessage }}</v-alert>
          </v-container>
        </v-card-text>
      </v-card>                
    </v-flex>
    <Footer />
  </v-layout>
  </v-container>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import Footer from '../components/Footer.vue'

export default {
  data() {
    return {
      email: '',
      infoMessage: '',
      password: '',
      show_pass: false,
      checking_email: false,
      link_not_sent: true,
      email_message: '',
      email_rules: [
        v => !!v || "Email is required.",
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(v) || 'E-mail must be valid.',
      ],      
    }
  },
  computed: {
    ...mapState('map', {
      maps: state => state.maps
    })
  },
  created: function () {
    this.$nextTick(() => this.$refs.email.focus())
  },
  methods: {
    login() {
        this.checking_email = true
        this.$store.dispatch('checkEmail', this.email)
        .then((resp) => {
            if (parseInt(resp.data) > 0) {
                this.email_message = ""
                let data = {
                    email: this.email
                }
                this.$store.dispatch('forgotPassword', data)
                .then(() => {
                })                
                this.link_not_sent = false
            }
            else {
                this.email_message = "We couldn't find this email"
            }            
        })
        .catch(err => {
            this.email_message = err.response.data
        })
        .finally(() => {
            this.checking_email = false
        })
    },
    foundEmail() {
      return () => this.email_message === "" || this.email_message
    },    
  },
  components: {
    Footer
  }
}
</script>