<template>
  <v-flex>
    <v-layout height="100%" row>
      <v-flex xs8 offset-xs2 sm6 offset-sm3 md4 offset-md4 lg2 offset-lg5 pa-5>
        <v-img alt="App logo" src="img/icetime_logo_transparent.png" />
      </v-flex>
    </v-layout>
    <v-layout class="my-8 px-4" row>
      <v-flex align-self-center text-right>
        <ButtonXLarge color="icetimeblue" icon="fa-exclamation-triangle" text="Regional Advisories" route="/ras" /><br />
        <ButtonXLarge color="teal" icon="fa-walking" text="Lake Ice Travel" route="/app/radar" /><br /> 
        <ButtonXLarge color="teal" icon="fa-compass" text="Spot Mapper" route="/app/map" /><br />
        <ButtonXLarge color="teal" icon="fa-medal" text="Leaderboard" route="/app/leaderboard/all"/>
      </v-flex>
      <v-divider v-if="!$vuetify.breakpoint.xs" class="mx-6" vertical />
      <v-flex align-self-center text-left>
        <ButtonRegular color="icetimeblue" icon="fa-info-circle" text="Start Tour" route="/discovery" /><br />
        <ButtonRegular color="teal" icon="fa-map-marked-alt" text="Available Imagery" route="/regions" /><br />
        <ButtonRegular color="icetimeblue" icon="fa-user-plus" text="Register" route="/signup" /><br />
        <ButtonRegular color="icetimeblue" icon="fa-sign-in-alt" text="Login" route="/login" />
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import { mapState } from 'vuex'
import ButtonRegular from './ButtonRegular.vue'
import ButtonXLarge from './ButtonXLarge.vue'

export default {
  components: {
    ButtonRegular,
    ButtonXLarge,
  },
  computed: {
    ...mapState('user', {
      primary_region: state => state.user.primary_region
    })
  },
  data() {
    return {
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout')
      // .then(() => {
      //   this.$router.push('/')
      //   this.$router.go()
      // })
    },
  }
}
</script>
