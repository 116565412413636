import { render, staticRenderFns } from "./Regions.vue?vue&type=template&id=192f99b8&scoped=true"
import script from "./Regions.vue?vue&type=script&lang=js"
export * from "./Regions.vue?vue&type=script&lang=js"
import style0 from "./Regions.vue?vue&type=style&index=0&id=192f99b8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "192f99b8",
  null
  
)

export default component.exports