<template>
  <v-container>
    <p class="text-h5 pl-5 pt-8 pb-4"><v-icon class="mr-3">fa-user-plus</v-icon>Sign Up for Ice Time</p>
    <v-stepper
      pagination=false
      v-model="e6"
      vertical
    >
      <v-stepper-step
        :complete="e6 > 1"
        color="icetimeblue"
        step="1"
      >
        Build Profile
      </v-stepper-step>

      <v-stepper-content step="1">
        <SignUpForm 
          @registration-unsuccessful="registrationUnsuccessful"
          @registration-successful="registrationSuccessful" 
          @signup-clicked="signupClicked"
        /> 
      </v-stepper-content>

      <v-stepper-step
        :complete="e6 > 2"
        color="icetimeblue"
        step="2"
      >
        {{ register_text }}
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-progress-linear
          :color="progressColor"
          :indeterminate="loadingSignUp"
          class="my-3"
          value="100"
        />
        <span v-html="signup_message" />
        <br />
        <v-btn
          class="my-3"
          color="primary"
          :disabled="loadingSignUp || progressColor == 'red'"
          @click="e6++"
        >
          Continue
        </v-btn>
      </v-stepper-content>

      <v-stepper-step
        :complete="e6 > 3"
        color="icetimeblue"
        step="3"
      >
        Upgrade to Pro
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-card
          color="grey lighten-1"
          class="mb-12 text-center"
        >
          <Plans />
          <v-btn 
            class="mx-2 mt-4 white--text"
            color="teal"
            href="/purchase"
          >
            Upgrade to Pro License
          </v-btn>
          <v-btn
            class="mx-2 mt-4"
            text
            href="/"
          >
            Continue with Free License
          </v-btn>
          <v-card-text class="py-5 font-italic">
            * Free Accounts will expire in 2 weeks if they are not verified.
          </v-card-text>
        </v-card>
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Plans from '../components/Plans.vue'
import SignUpForm from '../components/SignUpForm.vue';

export default {
  data() {
    return {
      e6: 1,
      loadingSignUp: false,
      progressColor: 'black',
    }
  },
  components: {
    Plans,
    SignUpForm
},
  computed: {
    ...mapState(['signup_message']),
    register_text() {
      // let text = 'Register'
      // if(this.e6 > 2) {
      //   text = 'Registered'
      // } else if(this.e6 > 1) {
      //   text = 'Registering'
      // }
      // return text
      return 'Register'
    }
  },
  // created() {
  //   this.$nextTick(() => this.$refs['alias'].focus())
  // },
  methods: {
    signupClicked() {
      this.e6++
      this.loadingSignUp = true
    },
    registrationUnsuccessful() {
      this.progressColor = 'red'
      this.loadingSignUp = false
      setTimeout(() => {
        this.e6--
      }, 5000)
    },
    registrationSuccessful() {
      this.progressColor = 'green'
      this.loadingSignUp = false
      // this.e6 = 3
      // setTimeout(() => {
      //   // this.e6++
      // }, 10000)
    }
  }
}
</script>
