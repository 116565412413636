import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'
import Home from './views/Home.vue'
import Contact from './views/Contact.vue'
import Signup from './views/Signup.vue'
import Login from './views/Login.vue'
import ForgotPassword from './views/ForgotPassword.vue'
import ResetPassword from './views/ResetPassword.vue'
import Purchase from './features/user/views/Purchase.vue'

// Import modular routes
import { AdminRoutes } from './features/admin/router'
import { DemoRoutes } from './features/demo/router'
import { CustomerServiceRoutes } from './features/customerservice/router'
import { LegalRoutes } from './features/legal/router'
import { MapRoutes } from './features/map/router'
import { PowerRoutes } from './features/power/router'
import { RASRoutes } from './features/ras/router'
import { UserRoutes } from './features/user/router'
import { DiscoveryRoutes } from './features/discovery/router'
import { AcademyRoutes } from './features/academy/router'
// import { ALBRoutes } from './features/leaderboard/router'

Vue.use(VueRouter)

const routes = [
  ... AdminRoutes,
  ... CustomerServiceRoutes,
  ... DemoRoutes,
  ... LegalRoutes,
  ... MapRoutes,
  ... PowerRoutes,
  ... RASRoutes,
  ... UserRoutes,
  ... DiscoveryRoutes,
  ... AcademyRoutes,
  // ... ALBRoutes,
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/index.html',
    name: 'home_index',
    component: Home
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
    beforeEnter: (to, from, next) => {
      if (store.getters["user/isLoggedIn"]) {
        next('/')
      } else {
        next()
      }
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.getters["user/isLoggedIn"]) {
        next({
          name: "home"
        });
      } else {
        next()
      }
    }
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    component: ForgotPassword
  },  
  {
    path: '/resetpassword/:userkey?',
    name: 'Reset Password',
    component: ResetPassword,
    props: true,
  },
  {
    path: '/purchase',
    name: 'purchase',
    component: Purchase,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  if(to.meta.requiresAuth) {
    if(to.meta.requiresAdmin) {
      if(store.getters['user/isLoggedIn']) {
        next()
        return
      }
      next('/login')
    }

    if(to.meta.requiresStaff) {
      if(store.getters['user/isStaffUser']) {
        next()
        return
      }
      next('/login')
    }

    if(to.meta.requiresProLicense) {
      if(store.getters['user/isStaffUser'] || store.getters['user/isProUser']) {
        next()
        return
      }
      next('/login')
    }

    if(store.getters['user/isLoggedIn']) {
      next()
      return
    }
    next('/login') 
  } else if(to.meta.requiresValidTrialUser) {
    if(store.getters['user/isExpiredTrialUser']) {
      next('/')
      return
    } else {
      next()
    }
  } else {
    next() 
  }

  // store.dispatch('setMode', '')
  // store.dispatch('setTab', '')
  store.dispatch('setShowTooltip', false)
})

export default router
