<template v-if="isLoggedIn && isAdmin">
  <v-scroll-y-reverse-transition mode="out-in" :hide-on-leave="true">
    <router-view />
  </v-scroll-y-reverse-transition>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  ...mapGetters('user', {
      isLoggedIn: 'isLoggedIn',
      isAdmin: 'isAdmin',
    }),  
  mounted() {
    this.$store.dispatch('setMode', 'admin')
    this.$store.dispatch('checkToken')
  },
}
</script>
