<template>
  <v-container class="pa-8">
    <v-form ref="preferencesForm" lazy-validation>
      <v-layout cols="12" md12 lg8 offset-lg2 xl6 offset-xl3 row>
        <v-flex xs12>
          <p class="text-h5 ma-8">
            <v-icon class="mr-2">fa-user</v-icon>
            Profile Data
          </p>
        </v-flex>
        <v-flex xs12>
          <v-alert
            v-if="isTrialUser"
            class="mx-5 pa-8"
            color="deep-orange accent-2" 
          >
            <v-row align="center">
              <v-col class="grow">
                <v-icon class="mr-2 mb-1" small>fa-exclamation-triangle</v-icon>
                <span class="font-weight-bold">
                  Your trial period {{ isExpiredTrialUser ? "has ended":`ends in ${14 - user.days_past} days`}}.
                </span> Please verify your account to continue using Ice Time.
              </v-col>
              <v-col class="shrink">
                <v-btn 
                    color="deep-orange darken-2" 
                    elevation="0"
                    :loading="loading"
                    @click="resend_verification"
                  >
                    {{ message }} 
                  </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-flex>
        <v-flex xs12 md6>
          <v-card v-if="x" class="pa-4 mx-5 my-4">
            <v-card-title>
              User Information
            </v-card-title>
            <v-card-text>
              <v-layout class="mt-2 pa-1" row>
                <v-flex class="ma-0 px-2" xs12>
                  <v-text-field v-model="x.email" filled label="Email" hint="Please contact support@icetime.app about changing your email." readonly />
                </v-flex>
                <v-flex class="ma-0 px-2" md12 lg6>
                  <v-text-field v-model="x.alias" filled label="Alias" hint="Please contact support@icetime.app about changing your alias." readonly />
                </v-flex>
                <v-flex class="ma-0 px-2" md12 lg6>
                  <v-text-field 
                    v-model="status_title" 
                    filled label="Account Type" 
                    readonly
                    @click:append="$router.push('/purchase')"
                  >
                    <template v-if="(user.status < 1)" v-slot:append>
                      <v-btn 
                        class="white--text text-caption font-weight-regular" 
                        color="teal"
                        elevation="0"
                        rounded 
                        small 
                        style="margin-top: -0.2em;"
                      >
                        Upgrade
                        <v-icon class="ml-1" small>mdi-shimmer</v-icon>
                      </v-btn>
                    </template>
                    <template v-else v-slot:append>
                      <v-btn 
                        class="white--text text-caption font-weight-regular" 
                        color="grey darken-1"
                        elevation="0"
                        rounded 
                        small 
                        style="margin-top: -0.2em;"
                      >
                        View Plan
                        <v-icon class="ml-2" x-small>fa-pen</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex class="ma-0 px-2" xs12>
                  <v-autocomplete
                    v-model="x.primary_region"
                    :items="regions"
                    item-text="name"
                    item-value="radar_id"
                    label="Primary Region"
                  />
                </v-flex>
                <v-flex class="ma-0 px-2" xs12>
                  <image-uploader
                    ref="fileUpload"
                    :debug="0"
                    :maxWidth="1024"
                    :quality=".9"
                    :autoRotate=false
                    outputFormat="verbose"
                    :preview=false
                    :className="['fileinput', { 'fileinput--loaded' : hasImage }]"
                    @input="setImage"
                  >
                  <label for="fileInput" slot="upload-label">
                    <figure>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <path
                          class="path1"
                          d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                        ></path>
                      </svg>
                    </figure>
                    <span class="upload-caption">{{
                      hasImage ? "Replace" : "Click to add image"
                    }}</span>
                  </label>
                  </image-uploader>
                </v-flex>
                <v-flex class="ma-0 px-2" xs12 v-if="!loading">
                  <!-- <div v-if="currentImageName && !imageUpload">
                    <v-img :src="(image_start + currentImageName)" max-height="500" max-width="400"/>
                  </div>
                  <div v-else-if="imageUpload">
                    <v-img :src="currentImage.dataUrl" max-height="500" max-width="400"/>
                  </div> -->
                  <div>
                    <div v-if="currentImageName && !replaced">
                      <v-img :src="(image_start + currentImageName)" max-height="500" max-width="400"/>
                    </div>
                    <div v-else-if="originalImage">
                      <v-img :src="(originalImage.dataUrl)" max-height="500" max-width="400"/>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
            <!-- <v-card-title class="mb-3">
              Lifestyle
            </v-card-title>
            <v-card-text class="py-0 my-0 text-body-1">
              I am on the lake: <v-select dense style="display: inline-flex!important;" />.
            </v-card-text>
            <v-card-text class="py-0 my-0 text-body-1">
              I travel <v-select dense style="display: inline-flex!important;" />.
            </v-card-text>
            <v-card-text class="py-0 mt-0 mb-5 text-body-1">
              I fish <v-select dense style="display: inline-flex!important;" /> time(s) a year.
            </v-card-text>
            <v-card-title>
              Interests
            </v-card-title>
            <v-card-text>
              <v-row class="pa-4">
                <v-flex md12 lg6>
                  <v-checkbox v-model="user.is_ice_traveller" label="Lake Ice Condition Reports" />
                  <v-checkbox v-model="user.is_ice_traveller" label="Ice Roads / Transportation" />
                  <v-checkbox v-model="user.is_fisher" label="Ice Fishing" />
                  <v-checkbox v-model="user.is_competitor" label="Fishing Competitions" />
                </v-flex>
                <v-flex md12 lg6>
                  <v-checkbox v-model="user.is_concerned_about_environment" label="Environmental Changes" />
                  <v-checkbox v-model="user.is_social" label="Connecting with Other Lake Ice Users" />
                  <v-checkbox v-model="user.is_recording_stats" label="Recording Fishing Spots" />
                  <v-checkbox v-model="user.is_recording_stats" label="Tracking Catches" />
                </v-flex>
              </v-row>
            </v-card-text> -->
          </v-card>
        </v-flex>
        <v-flex xs12 md6>
          <v-card v-if="x" class="pt-4 px-4 pb-2 mx-5 my-4">
            <v-card-title>
              Personal Information
            </v-card-title>
            <v-card-text>
              <v-layout class="mt-2 pa-1" row>
                <v-flex class="ma-0 px-2" xs12>
                  <v-text-field v-model="x.name" label="Name" />
                </v-flex>
                <v-flex class="ma-0 px-2" xs12>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-click="false"
                    :close-on-content-click="false"
                    :return-value.sync="user.birthday"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="x.birthday"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        label="Date of Birth"
                      >
                        <template v-slot:append>
                          <v-icon style="margin-top: -0.25em; margin-right: 0.3em;">
                            fa-calendar-alt
                          </v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="x.birthday" no-title scrollable>
                      <v-spacer />
                      <v-btn color="primary" text @click="(x.birthday = null)">
                        Remove
                      </v-btn>
                      <v-btn color="primary" text @click="(menu = false)">
                        Cancel
                      </v-btn>
                      <v-btn color="primary" text @click="$refs.menu.save(user.birthday)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <!-- <v-text-field v-model="user.address" label="Address"/> -->
                <!-- <v-text-field v-model="user.city" label="Location"/> -->
                <v-flex class="ma-0 px-2" xs12>
                  <v-combobox 
                    v-model="x.country"
                    :items="countries" 
                    item-value="abbreviation" 
                    item-text="name" 
                    label="Country"
                    :return-object="false"
                  />
                </v-flex>
                <v-slide-y-transition>
                  <v-flex v-if="isNorthAmerican" class="ma-0 px-2" md12 lg6>
                    <v-text-field 
                      v-model="x.postal_zip" 
                      label="Postal Code / ZIP" 
                    />
                  </v-flex>
                </v-slide-y-transition>
                <!-- <v-text-field v-if="user.postal_zip" v-model="x.postal_zip_name" filled readonly label="Postal Code / ZIP" /> -->
                <v-flex :class="isNorthAmerican ? 'ma-0 px-2 md12 lg6':'ma-0 px-2 xs12'">
                  <v-text-field v-model="x.phone" label="Phone Number" />
                </v-flex>
                <!-- <v-flex class="ma-0 px-2" xs12>
                  <v-select
                    v-model="x.metric"
                    :items="unit_list"
                    item-text="text"
                    item-value="value"
                    label="Unit of Measure"
                    single-line
                  ></v-select>
                </v-flex> -->
                <v-flex class="ma-0 px-2" xs12>
                  <v-textarea
                      v-model="bio"
                      label="Bio" 
                    />
                </v-flex>
                <v-flex class="ma-0 px-2" xs12>
                  <div v-if="imageUpload">
                    <v-card-title>
                      Replacing With:
                    </v-card-title>
                    <v-img :src="currentImage.dataUrl" max-height="500" max-width="400"/>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <v-alert v-if="infoMessage" class="d-flex ma-6" type="info">
            <span v-html="infoMessage" />
          </v-alert>
        </v-flex>
        <v-flex xs12 class="justify-center align-center" align-self-center>
          <v-layout row>
            <v-spacer />
            <v-btn 
              class="white--text d-flex mt-6 mb-12" 
              :color="save_success ? 'blue':'amber'" 
              :loading="loading" 
              :width="$vuetify.breakpoint.mdAndDown ? '80%':'300'" 
              @click="updateProfile"
            >
              {{ save_button_str }}
            </v-btn>
            <v-spacer />
          </v-layout>
          <!-- <v-layout row>
            <v-spacer />
            <v-btn 
              class="white--text d-flex mt-6 mb-12" 
              :color="save_success ? 'blue':'amber'" 
              :loading="loading" 
              :width="$vuetify.breakpoint.mdAndDown ? '80%':'300'" 
              @click="uploadFile"
            >
              Upload
            </v-btn>
            <v-spacer />
          </v-layout> -->
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ImageUploader from 'vue-image-upload-resize'

export default {
  data() {
    return {
      countries: [
        { abbreviation: 'Canada', name: 'Canada' },
        { abbreviation: 'USA', name: 'United States of America'}
      ],
      disabled: true,
      infoMessage: '',
      loading: false,
      menu: false,
      message: "Resend Verification Email",
      save_button_str: 'Save Changes',
      save_success: true,
      x: null, // placeholder for user object
      unit_list: [
        {value: '1', text: 'Metric'},
        {value: '2', text: 'Imperial'}
      ],
      currentImage: null,
      imageUpload:false,
      currentImageName: null,
      hasImage:false,
      showMessage:false,
      image_start: this.$apiEndpointUrl + "profile_image/",
      image_name: null,
      err_message:null,
      replaced: false,
      originalImage: null,
      bio: null
    }
  },
  components: {
    ImageUploader
  },
  computed: {
    ...mapGetters('user', {
      isTrialUser: 'isTrialUser',
      isExpiredTrialUser: 'isExpiredTrialUser'
    }),
    ...mapState('user', {
      user: state => state.user,
    }),
    ...mapState(['regions']),
    isNorthAmerican() {
      return ['Canada', 'USA'].includes(this.x.country)
    },
    status_title() {
      let title = ''
      switch(this.user.status) {
        case '0':
          title = 'Unverified User'
          break
        case '1':
          title = 'Free User'
          break
        case '2':
          title = 'Pro User'
          break
        case '8':
          title = 'Staff User'
          break
        case '9':
          title = 'Admin User'
          break
        default:
          title = 'Uknown User ' + this.user.status
          break
      }
      return title
    }
  },
  methods: {
    resend_verification() {
      this.loading = true
      this.$store.dispatch('user/resendVerification')
      .then(() => {
        this.message = "Sent!"
      })
      .catch(err => {
        this.message = err.data
      })
      .finally(() => {
        this.loading = false
      })
    },
    setImage(output) {
      this.imageUpload = true
      this.currentImage = output
      this.hasImage = true
      // this.originalImage = this.$refs.fileUpload.$el.children[1].files[0]
      // console.log('Info', output.info)
      // console.log(output)
      // console.log(this.imageUpload)
      // console.log('Exif', output.exif)
      // console.log('Original Image:', this.originalImage)
      // this.previewImage = output.dataUrl
    },
    convertSpecial(text){  
        text = String(text).replace("&lt;" , "<")
        text = String(text).replace("&gt;" , ">")
        text = String(text).replace("&quot;" , "\"")
        text = String(text).replace("&#039;" , "'")
        text = String(text).replace("&amp;" , "&")
        // return htmlStr
        // console.log(text)
        return text
    },
    convertBio(){
      this.bio = this.convertSpecial(this.x.bio)
    },
    updateProfile() {
      this.loading = true

      let data = {
        id: this.x.id,
        primary_region: this.x.primary_region,
        name: this.x.name,
        birthday: this.x.birthday ? this.x.birthday:"",
        country: this.x.country,
        postal_zip: this.x.postal_zip,
        phone: this.x.phone,
        bio: this.x.bio
      }
        // metric: this.x.metric,
      // console.log("update data: " , data)
      this.$store.dispatch('user/updateProfile', data)
      .then(() => {
        if(this.imageUpload){
          // console.log("update image? " + this.imageUpload)
          this.uploadFile()
          // .then(() => {
            // this.imageUpload = false
            // console.log("Updated image!")
            // this.x = this.user
            // this.save_button_str = 'Updated!'
            // this.save_success = true
            // this.hasImage = true
            // this.currentImageName = this.user.profile_image
          // })
        }else{
          this.x = this.user
          this.save_button_str = 'Updated!'
          this.save_success = true
        }

      })
      .catch((error) => {
        // console.log(error)
        this.err_message = error
        this.save_button_str = 'Update Failed - Please Try Again'
        this.save_success = false
      })
      .finally(() => {
        setTimeout(() => this.loading = false, 500);
        
      })
    },
    uploadFile() {
      // console.log("test")
          if(this.currentImage != undefined && !this.showMessage) {
            let formData = new FormData()
            fetch(this.currentImage.dataUrl)
            .then(response => response.blob())
            .then(blob => {
                this.originalImage = this.currentImage
                var name = this.currentImage.info.name
                // var type = this.currentImage.info.type
                var file = new File([blob], name, {type: "image/jpeg"})
                formData.append('file', file)
                this.$store.dispatch('user/uploadProfileImage', formData)
                  .then(() => {
                    // this.currentImageName = undefined
                    // this.message = response["data"]
                    if(this.message.includes("Error")) {
                      // console.log("error")
                        // this.messageType = "error"
                    }
                    else {
                      this.imageUpload = false
                      this.x = this.user
                      this.save_button_str = 'Updated!'
                      this.save_success = true
                      this.hasImage = true
                      this.currentImageName = this.x.profile_image
                      // console.log(this.x)
                      this.replaced = true
                        // this.messageType = "success"
                    }
                    // this.showMessage = true
                  })
                  .catch((err) => {
                      // console.log("error: ", err)
                      this.err_message = err
                  })
            })
          }     
        },
  },
  mounted() {
    this.$store.dispatch('setTab', 'profile')
    this.$store.dispatch('getRegion')
    // .then(() => {
      // console.log(this.user)
    // })
    this.x = this.user
    // console.log(this.x)
    if(this.user.profile_image){
      this.hasImage = true
      this.currentImageName = this.user.profile_image
      this.image_name = this.user.profile_image
    }
    this.convertBio()
  }
}
</script>

<style>
#fileInput.fileinput {
  display: none;
}
.fileinput {
  display: none;
}
</style>