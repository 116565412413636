<template>
  <v-container>
    <v-alert v-if="showLoading" type="info">Loading...</v-alert>
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>User Table (Read Only)</v-toolbar-title>
          <v-divider class="mx-4" inset vertical/>
          <v-text-field
            append-icon="mdi-magnify"
            class="px-2 text-h6"
            color="primary"
            label="Search"
            v-model="search"
            single-line
            hide-details
          />
          <v-divider class="mx-4" inset vertical/>
          <v-btn 
            :loading="showLoadingUserLocations" 
            @click.prevent="showUserLocationsDialog = !showLoadingUserLocations"
            class="mr-2"
            color="#2f4782"
            icon
          >
            <v-icon>mdi-counter</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container class="pt-0 pb-6 py-6">
          <v-row cols="12" class="py-3 pl-6">
            <v-flex class="pt-5" xs12 sm5 lg4 xl3>
              <span class="mr-4"><v-icon class="mr-4">mdi-magnify-plus-outline</v-icon>Regional Filters:</span>
            </v-flex>
            <v-flex xs12 sm7 lg8 xl19>
              <v-row class="pa-3" cols="12">
                <v-flex xs12 sm6 md4 lg3><v-checkbox v-model="show1" label="Manitoba" class="mr-4" hide-details /></v-flex>
                <v-flex xs12 sm6 md4 lg3><v-checkbox v-model="show19" label="North Western Ontario" class="mr-4" hide-details /></v-flex>
                <v-flex xs12 sm6 md4 lg3><v-checkbox v-model="show3001" label="Southern Ontario" class="mr-4" hide-details /></v-flex>
                <v-flex xs12 sm6 md4 lg3><v-checkbox v-model="show1026" label="Minnesota" class="mr-4" hide-details /></v-flex>
              </v-row>
            </v-flex>
          </v-row>
          <v-row cols="12" class="py-3 pl-6">
            <v-flex class="pt-5" xs12 sm4 lg3 xl2>
              <span class="mr-4"><v-icon class="mr-4">mdi-magnify-plus-outline</v-icon>License Filter:</span>
            </v-flex>
            <v-flex class="pl-6" xs12 sm8 lg9 xl10>
              <v-radio-group v-model="show_status" hide-details row>
                <v-radio label="All" value="All" />
                <v-radio label="Unlicensed" value="Unlicensed" />
                <v-radio label="Personal" value="Personal" />
                <v-radio label="Power" value="Power" />
              </v-radio-group>
            </v-flex>
          </v-row>
        </v-container>
        <v-divider />
      </template>

      <template v-slot:item.location="{ item }">
        <span class="d-none d-lg-flex">
          {{ item.location ? item.location:'???' }}
        </span>
        <span class="d-mg-flex d-lg-none ma-2">
          {{ item.location_abbr ? item.location_abbr:'???' }}
        </span>
      </template>

      <template v-slot:item.country="{ item }">
        <span class="d-none d-lg-flex">
          {{ item.country == 'CA' ? 'Canada':'USA' }}
        </span>
        <span class="d-mg-flex d-lg-none ma-2">
          {{ item.country }}
        </span>
      </template>

      <template v-slot:item.subscriptions="{ item }">
        <v-chip 
          v-for="sub in item.subscriptions.split(',')" 
          :key="sub.index"
          class="mx-1"
          small
          dense
        >
          {{ sub == '1' ? 'Manitoba':'' }}
          {{ sub == '19' ? 'North Western Ontario':'' }}
          {{ sub == '3001' ? 'Southern Ontario':'' }}
          {{ sub == '1026' ? 'Minnesota':'' }}
          {{ sub == '' ? 'n/a':'' }}
        </v-chip>
      </template>
    </v-data-table>

    <v-dialog v-model="showUserLocationsDialog" max-width="800">
      <v-data-table
        :headers="headersUserLocations"
        :items="user_locations"
        item-key="location"
        class="elevation-1"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: () => ({
      data_table_items: [],
      search: '',
      show_status: 'All',
      show1: '',
      show19: '',
      show1026: '',
      show3001: '',
      showUserLocationsDialog: false,
      showLoading: true,
      showLoadingUserLocations: true,
    }),
    computed: {
      ...mapState(['active_regions']),
      ...mapState('admin', {
        user_locations: state => state.user_locations,
        users: state => state.users,
      }),
      headers() {
        return [
          { text: 'Signup Date', value: 'signup_date' },
          { text: 'First Name', value: 'first_name' },
          { text: 'Last Name', value: 'last_name' },
          { text: 'Email', value: 'email' },
          { 
            text: 'License', 
            value: 'license',
            filter: value => {
              if(this.show_status == 'All') {
                return true
              } else {
                return value == this.show_status
              }
            }
          },
          { text: 'Location', value: 'location'},
          { text: 'Country', value: 'country'},
          { 
            text: 'Subscriptions', 
            value: 'subscriptions',
            filter: value => {
              if(this.selectedSubs.length == 0) {
                return true
              } else {
                var regions = value.split(',')
                var returnBool = true
                this.selectedSubs.forEach(element => {
                  if(!regions.includes(element)) {
                    returnBool = false
                  }
                });
              }
              return returnBool
            }
          }
        ]
      },
      headersUserLocations() {
        return [
          { text: 'Locations', value: 'location' },
          { text: 'Count', value: 'count' },
        ]
      },
      selectedSubs() {
        var filterSub = []
        if(this.show1) filterSub.push('1')
        if(this.show19) filterSub.push('19')
        if(this.show1026) filterSub.push('1026')
        if(this.show3001) filterSub.push('3001')
        return filterSub
      }
    },
    created () {
      this.$store.dispatch("getActiveRegions")
      this.$store.dispatch('admin/getUsers')
      .then(() => {
          this.showLoading = false
      }) 

      this.$store.dispatch('admin/getUserLocations')
      .then(() => {
        this.showLoadingUserLocations = false
      })
      .catch(() => {
        this.showLoadingUserLocations = false
      })
    },
}
</script>>