<template>
  <v-container class="pa-8">
    <span class="text-subtitle-1 mb-4">
      You have selected the <span class="font-weight-bold mx-1">{{ region }}</span> Region. These are the currently available FTP uploads:
    </span>
    <v-list two-line subheader>
      <v-list-item v-for="item in uploads_tif" :key="item.id" class="rounded-lg elevation-3 my-3">
        <v-list-item-avatar tile>
          <v-icon>fa-file</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ item.filename }}</v-list-item-title>
          <v-list-item-subtitle v-text="humanFileSize(item.size)" class="text-body-2" />
          <!-- <v-list-item-subtitle>Status: {{ item.status }}</v-list-item-subtitle> -->
        </v-list-item-content>
        <v-list-item-action style="flex-direction: row!important; align-items: center;">
          <!-- <v-list-item-action-text v-text="humanFileSize(item.size)"></v-list-item-action-text> -->
          <v-btn color="blue" icon @click="$emit('import',item)"><v-icon>fa-file-import</v-icon></v-btn>
          <!-- <v-dialog max-width="400">
            <template v-slot:activator="{on, attrs}">
              <v-btn icon v-bind="attrs" v-on="on"><v-icon>fa-eye</v-icon></v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">Preview</v-card-title>
              <v-card-text>
                {{ $apiEndpointUrl }}uploadthumb/{{ item.filename.replace('.tif', '.png') }} (missing end point)
              </v-card-text>
              <v-card-text>
                <v-img :src="$apiEndpointUrl + 'uploadthumb/' + item.filename.replace('.tif','.png') " />
              </v-card-text>
            </v-card>
          </v-dialog> -->
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
// import {mapState} from 'vuex'
// import axios from 'axios'

export default {
  data() {
    return {
      uploads: [],
      uploads_tif: [],
    //   editDialog: false,
    //   editedIndex: -1,
    //   editedItem: {},
    //   defaultItem: {
    //     title: '',
    //     description: '',
    //     id: '',
    //     date: new Date().toISOString().substr(0, 10)
    //   },
    //   layers: false,
    //   uploadFiles: [],
    //   uploadFileProgressValue: 0,
    //   uploadStartTime: null,
    //   uploadFileProgressText: '',
    //   uploadFileLoading: false,
    //   layerDateMenu: false
    }
  },
  computed: {
    //...mapState(['layers']),
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    // editDialogTitle() {
    //   return this.editedIndex === -1 ? 'New Layer' : 'Edit Layer'
    // },
    // layersActive: function() {
    //   if(!this.layers) return false
    //   return this.layers.filter(function (item) {
    //     return item.status === 1
    //   }) 
    // },
    // layersPending: function() {
    //   if(!this.layers) return false
    //   return this.layers.filter(function (item) {
    //     return item.status > 1
    //   }) 
    // }
  },
  created() {
    this.getUploads()
  },
  methods: {
    // handleFileAdd(files) {
    //   if(files.length > 0) {
    //     if(files[0].name !== '') {
    //       var segments = files[0].name.split('_')
    //       if(segments.length > 1) {
    //         segments.forEach((element)=> {
    //           var datetest = Date.parse(element)
    //           if(!isNaN(datetest)) {
    //             if(typeof(this.editedItem.date) == 'undefined') {
    //               datetest = new Date(datetest)
    //               datetest = datetest.getFullYear()
    //               if(datetest > 1900 && datetest < 2100) {
    //                 this.editedItem.date = element
    //               }
    //             }
    //           } else {
    //             if(typeof(this.editedItem.title) == 'undefined') {
    //               this.editedItem.title = element
    //             } else {
    //               if(typeof(this.editedItem.description) == 'undefined') {
    //                 this.editedItem.description = element
    //               }
    //             }
    //           }
    //         })
    //       }
    //     }
    //   }
    // },
    getUploads() {
    //   var id = this.id
      this.$store.dispatch('admin/getUploads')
      .then((uploads)=> {
        //console.log(layers)
        this.uploads = uploads.data
        if(this.uploads) {
        //   this.uploads.forEach((item)=> {
        //     if(item.status == 8) {
        //       setTimeout(()=> this.checkStatus(item),1000)
        //     }
        //   })

          this.uploads_tif = this.uploads.filter(function(el) {
            return el.filename.endsWith('.tif') || el.filename.endsWith('thickness.geojson') || el.filename.endsWith('iceclass.geojson')
          })
        }
      })
    },
    // getTileMapInfo(json) {
    //   if(json == '' || json == null) return
    //   var info = JSON.parse(json)
    //   var min = 20
    //   var max = 1
    //   if(!info.TileSets || !info.TileSets.TileSet) return '[no tile info]'
    //   info.TileSets.TileSet.forEach(function(zoom) {
    //     if(parseInt(zoom['@attributes'].href) > max) max = parseInt(zoom['@attributes'].href)
    //     if(parseInt(zoom['@attributes'].href) < min) min = parseInt(zoom['@attributes'].href)
    //   })
    //   return min + '-' + max + ' zoom'
    // },
    // checkStatus(item) {
    //   axios.get('/status/' + item.id)
    //   .then((response)=> {
    //     item.progress = response.data.progress
    //     item.status = response.data.status
    //     if(response.data.tilemap_json !== item.tilemap_json) item.tilemap_json = response.data.tilemap_json
    //     if(item.status == 8 && item.type == 1) {
    //       setTimeout(()=> this.checkStatus(item),5000)
    //     }
    //     if(item.status == 1) {
    //       item.complete = true
    //       // setTimeout(()=> item.status = 1,10000)
    //     }
    //   })
    //   //console.log('Status flag sent')
    // },
    // uploadFileStart() {
    //   let formData = new FormData()
    //   this.uploadFiles.forEach((file,index) => {
    //     formData.append('files[' + index + ']', file)
    //   })
    //   formData.append('item_details', JSON.stringify(this.editedItem))
    //   this.uploadStartTime = new Date().getTime()
    //   axios.post(
    //     '/layer/' + this.id,
    //     formData,
    //     {
    //       headers: {
    //         'Content-Type': 'multipart/form-data'
    //       },
    //       onUploadProgress: function( progressEvent ) {
    //         this.uploadFileProgressValue = parseInt( Math.round((progressEvent.loaded / progressEvent.total)*100))
    //         var timeSoFar = new Date().getTime() - this.uploadStartTime
    //         this.uploadFileProgressText = this.uploadFileProgressValue + "% [" + this.humanFileSize((progressEvent.loaded / (timeSoFar / 1000)).toFixed(3)) + "/s]"
    //       }.bind(this)
    //     }
    //   ).then(()=> {
    //     //console.log('upload file complete')
    //     this.uploadFileProgressValue = 0
    //     this.uploadFiles = []
    //     this.closeDialog()
    //     var id = this.id
    //     this.$store.dispatch('getLayers', {id})
    //     .then((layers)=> {
    //       //console.log(layers)
    //       this.layers = layers.data
    //       this.layers.forEach((item)=> {
    //         if(item.status == 10) {
    //           axios.get('/process/' + this.id)
    //           .then(()=> {
    //             this.updateLayers()
    //           })
    //         }
    //       })          
    //     })
    //   }).catch((e)=> {
    //     alert('upload error: '+e)
    //     //console.log('upload error: '+e)
    //     this.uploadFileProgressValue = 0
    //   })
    // },
    // closeDialog() {
    //   this.editDialog = false
    //   setTimeout(() => {
    //     this.editedItem = Object.assign({}, this.defaultItem)
    //     this.editedIndex = -1
    //   }, 300)
    // },
    // saveLayer() {
    //   if(this.editedIndex > -1) {
    //     this.editSaving = true
    //     this.$store.dispatch('updateLayer', this.editedItem)
    //     .then(()=>{
    //       this.editSaving = false
    //       this.closeDialog()
    //       var id = this.id
    //       this.$store.dispatch('getLayers', {id})
    //       .then((layers)=> {
    //         this.layers = layers.data
    //       })
    //     })
    //   }
    // },
    // editLayer(layer) {
    //   this.editedIndex = this.layers.indexOf(layer)
    //   this.editedItem = Object.assign({}, layer)
    //   this.editDialog = true
    // },
    // deleteLayer(item) {
    //   if(item.id > -1  && confirm('Remove layer titled "' + item.title + '"? This will permanently destroy all input files and generated outputs related to this layer.') == true) {
        
    //     this.layers[this.editedIndex].deleting = true

    //     //this.layers.splice(this.editedIndex,1)
    //     axios.delete('/layer/' + item.id).then(()=> {
    //       //console.log('delete complete')
    //       this.updateLayers()
    //     })
    //     this.closeDialog()
    //   }
    // },
    humanFileSize(bytes, si = true) {
      var thresh = si ? 1000 : 1024;
      if(Math.abs(bytes) < thresh) {
          return bytes + ' B';
      }
      var units = si
          ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
          : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
      var u = -1;
      do {
          bytes /= thresh;
          ++u;
      } while(Math.abs(bytes) >= thresh && u < units.length - 1);
      return bytes.toFixed(1)+' '+units[u];
    }    
  },
  props: ['region']
}
</script>
