<template>
  <v-container class="px-6 mt-4 pb-15 mb-15">
    <v-row>
      <v-col 
        md="8" sm="12" xs="12"
        :class="$vuetify.breakpoint.smAndDown ? 'px-4 py-4 font-weight-bold':'px-8 py-6 font-weight-bold'"
        style="background-image: url('/img/ed_monroe_chequamegon_bay_pressure_ridge.png'); background-position: center; background-size: cover  ; border-radius: 10px;"
      >
        <AdvisoryRegionSelect />
        <MainAdvisory v-if="ras_advisories[0] && ras_advisories[0].footprint_name" :advisory="ras_advisories[0]" />
      </v-col>
      <v-col class="my-1 py-0 px-3" md="4" sm="12" xs="12">
        <AdCarousel />
      </v-col>
    </v-row>
    <v-row class="px-2 py-8">
      <Advisories v-if="!entry" :advisories="ras_advisories.slice(1)" />
    </v-row>
    <Footer />
  </v-container>
</template>

<script>
import AdCarousel from '../components/AdCarousel.vue'
import Advisories from '../components/Advisories.vue'
import AdvisoryRegionSelect from '../components/AdvisoryRegionSelect.vue'
import Footer from './../../../components/Footer.vue'
import MainAdvisory from '../components/MainAdvisory.vue'

// import states from '../../../../public/advisories_test_data.json'
import { mapState } from 'vuex'

export default {
  components: {
    AdCarousel,
    Advisories,
    AdvisoryRegionSelect,
    Footer,
    MainAdvisory,
  },
  computed: {
    ...mapState('map', {
      hazardtypes: state => state.hazardtypes
    }),
    ...mapState('ras', {
      advisories: state => state.advisories,
      entry: state => state.entry,
      selected_region: state => state.selected_region,
    }),
    ...mapState('user', {
      primary_region: state => state.user.primary_region,
    }),
    ras_advisories() {
      // if (this.geolocation) {
      //   return this.advisories
      // } else {
        let placeholder = this.advisories.filter(item => item.region_id != this.selected_region)
        let matches = this.advisories.filter(item => item.region_id == this.selected_region)
        for (const match of matches) {
          placeholder.unshift(match)
        }
        return placeholder
      // }
    },
  }, 
  created() {
    this.$store.dispatch('map/getHazardTypes')
    .then(() => {
        this.$store.dispatch('ras/getAdvisories')
    })
    
    // .catch((err) => {
    //   // console.log(err)
    //   this.$store.dispatch('handleError', err)
    // })

    // if (this.primary_region) {
    //   this.$store.dispatch('ras/setEntry', false)
    // }
  },
  data() {
    return {
      // geolocation: null,
    }
  },
  methods: {
    // location_error() {
    //   // console.log(error)
    //   // this.$store.dispatch('ras/setEntry', false)
    // },
    // location_retrieved(location) {
    //   this.geolocation = [location.coords.latitude, location.coords.longitude]
    //   // this.$store.dispatch('ras/setEntry', false)
    //   this.$store.dispatch('ras/getAdvisories', this.geolocation)
    // },
    // useGeolocation() {
    //   navigator.geolocation.getCurrentPosition(this.location_retrieved, this.location_error)
    // }
  },
  mounted() {
    this.$store.dispatch('setMode', 'ras')
    this.$store.dispatch('setTab', 'ras')
    // this.useGeolocation()
    this.$store.dispatch('checkToken')
  }
}
</script>

<style scoped>
</style>