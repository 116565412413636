<template>
  <v-card
    class="ma-5 pa-4"
  >
    <v-card-title>
      Regional Advisory Service
    </v-card-title>
    <v-card-text class="text-body-1">
      This free service provides simple overviews of what's happening with the ice cover in the Ice Time&trade; regions monitored using satellites. If you are not an Ice Time Radar subscriber yet, you can purchase the latest ice hazard map here from within Ice Time Free&trade;. 
    </v-card-text>
  </v-card>
</template>
  
<script>
export default {
  data() {
    return {
    };
  },
  methods: {
  },
  components: { 
  }
}
</script>
