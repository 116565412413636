<template>
  <v-row class="my-0 px-0" width="100%">
    <v-flex 
      class="pt-0 px-3 py-5"
      v-for="advisory in advisories"
      xs12 sm12 md6 lg4 xl3
      :key="advisory.id"
    >
      <v-fade-transition mode="out-in" :hide-on-leave="true">
        <v-card 
          class="card-outter px-2 pt-2 pb-12 mb-12 fill-height"
          v-show="show"
          width="100%"
          :loading="entry"
        >
          <v-card-title class="text-subtitle-1 font-weight-medium">
            {{ advisory.footprint_name }}
          </v-card-title>
          <v-card-subtitle class="mx-2">
            <v-row>
              <v-chip 
                class="ma-1 px-2 white--text" 
                color="grey" 
                label 
                small
              >
                <div><v-icon>mdi-map</v-icon> {{ advisory.region_name }}</div>
              </v-chip>
              <v-chip 
                class="ma-1 px-2" 
                label 
                small
              >
                <div v-if="$vuetify.breakpoint.smAndDown"><v-icon>mdi-update</v-icon> {{ advisory.last_update }} UTC</div>
                <div v-else>Updated: {{ advisory.last_update }} UTC</div>
              </v-chip>
            </v-row>
            <AltAttributeChips v-if="advisory.advisories" :attributes="uniqueHazards(advisory.advisories.split(','))" /> 
          </v-card-subtitle>
          <v-card-text class="text-body-1">
            {{ advisory.title }}
          </v-card-text>
          <v-card-text class="py-0 text-body-2">
            {{ advisory.description }}
          </v-card-text>
          <v-card-actions class="card-actions ma-4">
            <!-- <v-icon
              v-if="advisory.layer_id"
              class="mx-1"
              color="teal" 
              large
            >
              mdi-shimmer
            </v-icon>
            <v-tooltip v-if="advisory.layer_id" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ma-1"
                  color="teal"
                  elevation="0"
                  fab
                  v-bind="attrs"
                  v-on="on"
                  :to="'/app/radar/' + advisory.region_id + '/' + advisory.id"
                >
                  <v-icon color="white">fa-map</v-icon>
                </v-btn>
              </template>
              <span>View Latest Map</span>
            </v-tooltip> -->
            <!-- <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-1"
                color="teal"
                disabled
                elevation="0"
                fab
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="white">fa-map-marker-alt</v-icon>
              </v-btn>
            </template>
            <span>View Geoposts</span>
          </v-tooltip> -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="isLoggedIn"
                v-bind="attrs"
                v-on="on"  
                class="ma-1"
                color="icetimeblue"
                elevation="0"
                fab
                :to="'/app/radar/' + advisory.region_id + '/' + advisory.layer_id"
              >
                <v-icon color="white">mdi-map-search</v-icon>
              </v-btn>
            </template>
            <span>View Map</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"  
                class="ma-1"
                color="icetimeblue"
                elevation="0"
                fab
                :to="'/ras/history/' + advisory.footprint_id"
              >
                <v-icon color="white">mdi-history</v-icon>
              </v-btn>
            </template>
            <span>View Advisory History</span>
          </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-fade-transition>
    </v-flex>
  </v-row>
</template>

<script>
import AltAttributeChips from './AltAttributeChips.vue';
import { mapState, mapGetters } from 'vuex'

export default {
  components: { 
    AltAttributeChips 
  },
  computed: {
    ...mapGetters('user', {
      isLoggedIn: 'isLoggedIn',
    }),      
    ...mapState('ras', {
      entry: state => state.entry,
    })
  },
  methods: {
    uniqueHazards(hazardList) {
      return hazardList.filter(function(item, pos, self) { return self.indexOf(item) == pos; })
    }
  },
  data() {
    return {
      show: true,
    }
  },
  props: [
      "advisories",
  ],
  created() {
    // console.log(this.advisories)
  },  
  setup() {
  },
  
}
</script>

<style scoped>
>>>.card-outter {
  position: relative;
  padding-bottom: 50px;
}
>>>.card-actions {
  position: absolute;
  bottom: 0;
  right:0;
}
</style>