<template>
    <v-card class="ma-4 pa-2 mb-16">
        <v-card-title>
            Surface and Volume
        </v-card-title>
        <v-card-text>
            {{ Math.floor(((currentPage + 1) / totalPages) * 100) }}%
            <v-progress-linear :value="Math.floor(((currentPage + 1) / totalPages) * 100)">
            </v-progress-linear>
        </v-card-text>
        <v-card flat>
            <v-slide-x-transition leave-absolute hide-on-leave>
                <v-card-text v-if="currentPage == 0">
                    What the heck! Right now, you are probably thinking I just learned that objects much smaller than the incoming wavelength do not interact with the radar signal, 
                    yet I was just shown that a radar can see small bubbles in lake ice! Yes, it is true. Earlier, we got you started by thinking about object size, say like sand 
                    grains, stones, pebbles, and rocks because these are easily thought of as objects of a certain size. A radar burst of energy also “see's” the discontinuity 
                    between the solid ice and the air bubble. This boundary is tiny, but radar detects it. When many bubbles are present the energy is scattered in many directions, 
                    some of which go towards the satellite. This is important for lake ice travel because we now have a way to map solid black ice and the much weaker white ice that 
                    is full of air pockets. 
                </v-card-text>
            </v-slide-x-transition>
            <v-slide-x-transition leave-absolute hide-on-leave>
                <v-card-text v-if="currentPage == 1">
                    Satellite radar images can be large with dimensions that are 40,000 columns by 20,000 rows, or 800 million pixels! As the satellite passes along its polar orbit 
                    it emits and receives backscattered energy from an area on the Earth that is 300 km by 200 km in less than one minute!  The bursts of energy emitted from a radar 
                    instrument travel at the speed of light. This is in part why radar satellites form images that can have many small pixels.  
                </v-card-text>
            </v-slide-x-transition>
            <v-slide-x-transition leave-absolute hide-on-leave>
                <v-card-text v-if="currentPage == 2">
                    Speaking of resolution, this is another way that radar images differ from the more familiar visible light satellites. The pixels formed from a satellite using 
                    visible light are “area average”. This represents, for example, the dominant light reflected from a green tree canopy. In the case of satellite radar, resolution 
                    is different. An example to help illustrate. If a satellite radar imaged your kitchen table with a single pixel, as we learned earlier, that smooth surface would 
                    create forward scattering away from the satellite. In the overall image of many pixels this one would have a small digital number given little energy returned to 
                    the satellite and would appear dark. However, if we put a small metal cheese grater on the edge of the table, which is an angled surface that points towards the 
                    satellite, the energy returned to the satellite would be high. The point to remember is that objects that scatter energy back to the satellite can be much smaller 
                    than the size of a pixel and they can be detected easily. It is for this reason that satellite radar image specialists do not use the term resolution to describe 
                    the size that a pixel represents on the ground but instead use pixel spacing. Now you know the image pixel spacing has little to do with the resolution capability 
                    of a radar. 
                </v-card-text>
            </v-slide-x-transition>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="currentPage != 0 && currentPage != totalPages" @click="prevPage">Prev</v-btn>
                <v-btn v-if="currentPage != totalPages -1 && currentPage != totalPages " @click="nextPage">Next</v-btn>
                <v-btn v-if="currentPage == totalPages -1" @click="nextSection">Next Section</v-btn>
            </v-card-actions>
        </v-card>
    </v-card>
</template>
    
<script>
import goTo from 'vuetify/lib/services/goto'

export default { 
    data() {
        return {
            currentPage: 0,
            totalPages: 3,
        }
    },
    props: [],
    computed: {
    },
    methods: {
        nextPage() {
            this.currentPage++
            goTo(0)
        },
        prevPage() {
            this.currentPage--
            goTo(0)
        },
        nextSection() {
            this.$emit('next-section')
            goTo(0)
        },
    },
}
</script>

<style>
</style>