<template>
  <v-card
    class="ma-5 pa-4"
  >
    <ComingFall2023Chip />
    <v-card-title>
      Angler's Leaderboard&trade;
    </v-card-title>
    <v-card-text class="text-body-1">
      The fish tales stop here! Set up a competition for a day with your friends on one or more lakes. For the competitive or aspiring Pros, keep track of your seasonal statistics, track your point score, and compete with other leading anglers within or among geographic regions. Subscribers can follow today's comp results. Find out where you rank today, this week, this month, or this season on the Ice Time&trade; community Anglers Leaderboard&trade;!
    </v-card-text>
    <v-card-text class="text-body-1">
      Subscribe to learn more about awards, prizes, and special offers.
    </v-card-text>
    <v-card-actions class="mt-4 justify-center">
      <v-btn class="grey--text" rounded small text>
        <v-icon class="mx-2" x-small>fa-angle-double-down</v-icon>
        Scroll for detail
        <v-icon class="mx-2" x-small>fa-angle-double-down</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
  
<script>
import ComingFall2023Chip from './ComingFall2023Chip.vue';

export default {
  data() {
    return {
    };
  },
  methods: {
  },
  components: { 
    ComingFall2023Chip
  }
}
  </script>
  