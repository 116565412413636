// import CustomerService from './CustomerService'
import FAQ from './views/FAQ'
import Fundamentals from './views/Fundamentals'
import Install from './views/Install'
// import Support from './views/Support'

export const CustomerServiceRoutes = [
//   {
//     path: '/info',
//     component: CustomerService,
//     meta: {
//         requiresAdmin: true
//     },
//     children: [
//     ]
//   },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/fundamentals',
    name: 'Ice Time Fundamentals',
    component: Fundamentals
  },
  {
    path: '/install',
    name: 'Ice Time Installation',
    component: Install
  },
//   {
//     path: 'support',
//     name: 'Suppport',
//     component: Support
//   },
]