<template>
    <v-container>
    <v-alert v-if="showLoading" type="info">Loading...</v-alert>
    <v-container v-else>
        <h1>Catch Trainer Uploader</h1>
        <v-divider class="mb-2"></v-divider>
        <!-- <v-btn class="mb-2"
        @click="testSend()">
        Test Send Dummy Data
        </v-btn>  -->
        <v-radio-group
      v-model="filter_mode"
      @change="FilterList"
      row
    >
      <v-radio
        label="All"
        value="All"
      ></v-radio>
      <v-radio
        label="Unprocessed"
        value="Unprocessed"
      ></v-radio>
      <v-radio
        label="Sent"
        value="Sent"
      ></v-radio>
      <v-radio
        label="Training"
        value="Training"
      ></v-radio>
      <v-radio
        label="Trained"
        value="Trained"
      ></v-radio>
      <v-radio
        label="Failed/Exclude"
        value="Failed"
      ></v-radio>
    </v-radio-group>

        <v-data-table
            dense
            :headers="headers"
            :items="current_list"
            :items-per-page="10"
            class="elevation-1"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @click:row="showFull"
            >
        <template v-slot:item.image_name="{ item }">
                <v-img         
                    v-if="item.image_name"        
                    :src="($apiEndpointUrl + image_start + item.image_name)" 
                    max-height="100"
                    max-width="100"
                    alt="Catch"/>
                <v-icon v-else large>mdi-image</v-icon>
        </template>

        <template v-slot:item.common_name="{ item }">
            <div v-if="item.corrected_species_id">{{ GetSpeciesName(item.corrected_species_id) }}</div>
            <div v-else>{{ item.common_name }}</div>
        </template>

        <template v-slot:item.status="{ item }">
            <div v-if="item.status === null">Unprocessed</div>
            <div v-if="item.status === '0'">Failed to Send to Train</div>
            <div v-if="item.status === '1'">Failed Training</div>
            <div v-if="item.status === '2'">Exclude Image</div>
            <div v-if="item.status === '5'">Send List</div>
            <div v-if="item.status === '6'">Sent to API</div>
            <div v-if="item.status === '7'">ML Recieved</div>
            <div v-if="item.status === '8'">Training Successful</div>
        </template>
        
        <!-- <template v-slot:item.tags="{ item }">
            <div v-if="item.length && item.ai_length">{{ Math.round((item.length - item.ai_length) * 1000) / 1000 }} cm.<v-icon v-if="(item.length-item.ai_length) > 0.635 || (item.ai_length-item.length) > 0.635" color="orange">mdi-flag-variant</v-icon></div>
            <div v-else></div>
        </template> -->

        <!-- <template v-slot:item.common_name="{ item }">
            <div class="red--text" v-if="item.status==0">{{ item.common_name }}</div>
            <div v-else>{{ item.common_name }}</div>
        </template> -->
    </v-data-table>
    <v-divider class="mt-2"></v-divider>
    <h1>Training List</h1>
    <v-data-table
            dense
            :headers="headers"
            :items="training_list"
            :items-per-page="10"
            class="elevation-1 mt-2"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @click:row="showFull2"
            >
        <template v-slot:item.image_name="{ item }">
                <v-img         
                    v-if="item.image_name"        
                    :src="($apiEndpointUrl + image_start + item.image_name)" 
                    max-height="100"
                    max-width="100"
                    alt="Catch"/>
                <v-icon v-else large>mdi-image</v-icon>
        </template>
        <!-- Once we have decided on all the status codes change below **TODO** -->
        <template v-slot:item.status="{ item }">
            <div v-if="item.status === null">Unprocessed</div>
            <div v-if="item.status === '0'">Failed to Send to Train</div>
            <div v-if="item.status === '1'">Failed Training</div>
            <div v-if="item.status === '2'">Exclude Image</div>
            <div v-if="item.status === '5'">Send List</div>
            <div v-if="item.status === '6'">Sent to API</div>
            <div v-if="item.status === '7'">ML Recieved</div>
            <div v-if="item.status === '8'">Training Successful</div>
        </template>
        
        <!-- <template v-slot:item.tags="{ item }">
            <div v-if="item.length && item.ai_length">{{ Math.round((item.length - item.ai_length) * 1000) / 1000 }} cm.<v-icon v-if="(item.length-item.ai_length) > 0.635 || (item.ai_length-item.length) > 0.635" color="orange">mdi-flag-variant</v-icon></div>
            <div v-else></div>
        </template> -->

        <!-- <template v-slot:item.common_name="{ item }">
            <div class="red--text" v-if="item.status==0">{{ item.common_name }}</div>
            <div v-else>{{ item.common_name }}</div>
        </template> -->
    </v-data-table>
    <v-col class="text-right" >
        <v-btn class="mt-2" color="success"
            @click="ClickSend()">
            Send Training Data
            </v-btn> 

    </v-col>
    <!-- Falied List -->
    <v-divider class="mt-2"></v-divider>
    <h1>Exclude List</h1>
    <v-data-table
            dense
            :headers="headers"
            :items="failed_list"
            :items-per-page="10"
            class="elevation-1 mt-2"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @click:row="showFull2"
            >
        <template v-slot:item.image_name="{ item }">
                <v-img         
                    v-if="item.image_name"        
                    :src="($apiEndpointUrl + image_start + item.image_name)" 
                    max-height="100"
                    max-width="100"
                    alt="Catch"/>
                <v-icon v-else large>mdi-image</v-icon>
        </template>
        <!-- Once we have decided on all the status codes change below **TODO** -->
        <template v-slot:item.status="{ item }">
            <div v-if="item.status === null">Unprocessed</div>
            <div v-if="item.status === '0'">Failed to Send to Train</div>
            <div v-if="item.status === '1'">Failed Training</div>
            <div v-if="item.status === '2'">Exclude Image</div>
            <div v-if="item.status === '5'">Send List</div>
            <div v-if="item.status === '6'">Sent to API</div>
            <div v-if="item.status === '7'">ML Recieved</div>
            <div v-if="item.status === '8'">Training Successful</div>
        </template>
        
        <!-- <template v-slot:item.tags="{ item }">
            <div v-if="item.length && item.ai_length">{{ Math.round((item.length - item.ai_length) * 1000) / 1000 }} cm.<v-icon v-if="(item.length-item.ai_length) > 0.635 || (item.ai_length-item.length) > 0.635" color="orange">mdi-flag-variant</v-icon></div>
            <div v-else></div>
        </template> -->

        <!-- <template v-slot:item.common_name="{ item }">
            <div class="red--text" v-if="item.status==0">{{ item.common_name }}</div>
            <div v-else>{{ item.common_name }}</div>
        </template> -->
    </v-data-table>
    <v-col class="text-right" >
        <v-btn class="mt-2" color="success"
            @click="ClickSendFailed()">
            Send Exclude Data
            </v-btn> 

    </v-col>
    <v-dialog
        v-model="overlay"
        max-width="400"
    >
        <v-card max-width="400" class="pa-1">
                
            <v-card-actions class="pa-2">
                <v-spacer></v-spacer>
                <v-icon
                    large
                    @click="overlay=false"
                >mdi-close-circle
                </v-icon>                
            </v-card-actions>
        
            <v-img 
                v-if="selected_catch.image_name" 
                :src="($apiEndpointUrl + image_start + selected_catch.image_name)" 
                max-height="500"
                contain
            >
                <template v-slot:placeholder>
                    <div class="d-flex align-center justify-center fill-height">
                        <v-progress-circular
                        color="grey-lighten-4"
                        indeterminate
                        ></v-progress-circular>
                    </div>
                </template>
            </v-img>
            <v-card-text class="mt-5" v-if="selected_catch.status == null">
                <v-autocomplete 
                    v-model="current_species"
                    :items="species"
                    item-text="common_name"
                    item-value="common_name"
                    dense
                    label="Species"
                    hide-details
                    class="pb-2"
                    >
                </v-autocomplete>
                <v-select
                    v-model="current_tags"
                    :items="tag_list"
                    label="Tags"
                    multiple
                    chips
                ></v-select>
            </v-card-text>
            <v-card-actions v-if="selected_catch.status == null">

                <v-col class="text-center">
                    <v-btn
                        @click="addToTrainList()"
                        color="success"
                    >
                        Add to Training List
                    </v-btn>
                    <v-btn class="ma-2" color="error" 
                        @click="addToExcludeList()">
                        Add to Exclude List
                    </v-btn>
                </v-col>

            </v-card-actions>
            <v-card-actions v-else-if="selected_catch.status == 5">
                <v-btn
                    @click="removefromTrainList()"
                    color="warning"
                >
                    Remove From Training List
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog
        v-model="overlay2"
        max-width="400"
    >
        <v-card max-width="400" class="pa-1">
                
            <v-card-actions class="pa-2">
                <v-spacer></v-spacer>
                <v-icon
                    large
                    @click="overlay2=false"
                >mdi-close-circle
                </v-icon>                
            </v-card-actions>
        
            <v-img 
                v-if="selected_catch.image_name" 
                :src="($apiEndpointUrl + image_start + selected_catch.image_name)" 
                max-height="500"
                contain
            >
                <template v-slot:placeholder>
                    <div class="d-flex align-center justify-center fill-height">
                        <v-progress-circular
                        color="grey-lighten-4"
                        indeterminate
                        ></v-progress-circular>
                    </div>
                </template>
            </v-img>
            <v-card-text class="mt-5" v-if="selected_catch.status == null">
                <v-autocomplete 
                    v-model="current_species_id"
                    :items="species"
                    item-text="common_name"
                    item-value="common_name"
                    dense
                    label="Species"
                    hide-details
                    class="pb-2"
                    >
                </v-autocomplete>
                <v-select
                    v-model="current_tags"
                    :items="tag_list"
                    label="Tags"
                    multiple
                    chips
                ></v-select>
            </v-card-text>
            <v-card-actions v-if="selected_catch.status == null">
                <v-col class="text-center">
                    <v-btn
                        @click="addToTrainList()"
                        color="success"
                    >
                        Add to Training List
                    </v-btn>
                    <v-btn class="ma-2" color="error" 
                        @click="addToExcludeList()">
                        Add to Exclude List
                    </v-btn>
                </v-col>

            </v-card-actions>
            <v-card-actions v-else-if="selected_catch.status == 5">
                <v-btn
                    @click="removefromTrainListInList()"
                    color="warning"
                >
                    Remove From Training List
                </v-btn>
            </v-card-actions>
            <v-card-actions v-else-if="selected_catch.status == 2">
                <v-btn
                    @click="removefromfaliedListInList()"
                    color="warning"
                >
                    Remove From Exclude List
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog
        v-model="confirm_overlay"
        max-width="500"
    >
        <v-card max-width="500" class="pa-1">
            <div v-if="!send_loading">
            <v-card-actions class="pa-2">
                <v-spacer></v-spacer>
                <v-icon
                    large
                    @click="confirm_overlay=false"
                >mdi-close-circle
                </v-icon>                
            </v-card-actions>
            <v-col class="mx-auto">
                <h3>Are you sure you want to submit this data?</h3>
            </v-col>
            <v-card-actions class="pa-2">
                <v-col class="text-center">
                    <v-btn class="ma-2" color="success" @click="SendTrainingData">
                        Confirm
                    </v-btn>
                    <v-btn class="ma-2" color="error" @click="confirm_overlay = false">
                        Cancel
                    </v-btn>
                </v-col>

            </v-card-actions>
        </div>
        <div v-else>
            <v-card-actions class="pa-2">
                <v-col class="text-center">
                    <v-progress-circular
                        indeterminate
                        color="green"
                    ></v-progress-circular>
                </v-col>
            </v-card-actions>
        </div>
            </v-card>
        </v-dialog>

    </v-container>
</v-container>
</template>
  
<script>
import { mapState } from 'vuex'
  
export default {
    computed: {
        ...mapState('admin', {
            training_catches: state => state.training_catches,
        }),
        ...mapState('spot_mapper', {
            species: state => state.species,
        })
    },
    data: () => ({
        showLoading: true,
        items: [],
        sortBy: 'id',
        sortDesc: true,
        headers: [
            { text: 'id', align: 'start', value: 'id' },
            { text: 'Species', value: 'common_name' },
            { text: 'Status', value: 'status' },
            { text: 'Tags', value: 'tags' },
            { text: 'Submit Date', value: 'submit_date' },
            { text: 'Train Date', value: 'train_date' },
            { text: 'Picture', value: 'image_name' },
        ],
        // image_start: this.$apiEndpointUrl + "catch_image/",
        image_start: "catch_image/",
        selected_catch: {
            "id": "0",
            "common_name": "temp",
            "species_id": "1",
            "status": null,
            "train_date": null,
            "submit_date": null,
            "tags": null,
            "image_name": null
        },
        tag_list: ['Hands', 'Bumpboard'],
        current_species: null,
        current_species_id: null,
        current_tags: null,
        current_row: null,
        overlay:false,
        overlay2:false,
        confirm_overlay:false,
        training_list:[],
        failed_list:[],
        current_list: [],
        filter_mode: "Unprocessed",
        sendMode: 1,
        send_loading: false
    }),
    methods:{
        testSend(){
            // console.log("test")
            let data = {
                "trained": [
                    {"id": 429},
                    {"id": 428},
                    {"id": 434}
                ],
                "skipped": [
                    {"id": 430},
                    {"id": 433}
                ]
            }
            this.$store.dispatch('admin/sendtest', data)
            // console.log(data)
        },
        FilterList(filter_by){
            // console.log(filter_by)
            // this.current_row = this.items.findIndex(obj => obj.id == row.id)
            switch(filter_by){
                case "All":
                    this.current_list = this.items
                    break
                case "Unprocessed":
                    this.current_list = this.items.filter(item => item.status == null)
                    break
                case "Sent":
                    this.current_list = this.items.filter(item => item.status == 6)
                    break
                case "Training":
                    this.current_list = this.items.filter(item => item.status == 7)
                    break
                case "Trained":
                    this.current_list = this.items.filter(item => item.status == 8)
                    break
                case "Failed":
                    this.current_list = this.items.filter(item => item.status == 0 || item.status == 1 || item.status == 2)
                    break
            }
            // console.log(this.current_list)
        },
        ClickSend(){
            this.sendMode = 1
            this.confirm_overlay = true
        },
        ClickSendFailed(){
            this.sendMode = 0
            this.confirm_overlay = true
        },
        GetSpeciesName(id){
            let index = this.species.findIndex(obj => obj.id == id)
            return this.species[index].common_name
        },
        SendTrainingData(){
            this.send_loading = true
            if(this.sendMode == 1){
                this.$store.dispatch('admin/sendTrainingData', this.training_list)
                .finally(() => {
                    setTimeout(()=> {
                        location.reload();
                    }, 500)
                })
                // console.log(this.training_list)
            }else if(this.sendMode == 0){
                this.$store.dispatch('admin/sendExcludeData', this.failed_list)
                .finally(() => {
                    setTimeout(()=> {
                        location.reload();
                    }, 500)
                })
                // console.log(this.failed_list)
            }
        },
        showFull(row){
            this.selected_catch = row
            // this.image_name = row.image_name
            // console.log(row)
            // console.log(this.items.indexOf(row))
            this.current_row = this.items.indexOf(row)
            this.current_species = row.common_name
            this.current_tags = row.tags
            // console.log(this.current_species)
            this.overlay = true
        },
        showFull2(row){
            this.selected_catch = row
            // this.image_name = row.image_name
            // console.log(this.selected_catch)
            // console.log(this.items.indexOf(row))
            this.current_row = this.items.findIndex(obj => obj.id == row.id)
            // console.log(this.current_row)
            // this.current_row = this.items.indexOf(row)
            this.current_species = row.common_name
            this.current_tags = row.tags
            // console.log(this.current_species)
            this.overlay2 = true
        },
        // resetDefaults(){
        //     this.current_species = null
        //     this.current_tags = null
        //     this.current_row = null
        //     this.overlay = false
        //     this.selected_catch = null
        // },
        addToExcludeList(){
            let temp_tags = null
            let temp_species_id = null
                if(this.current_tags !== null){
                    temp_tags = this.current_tags.toString()
                }

            let index = this.species.findIndex(obj => obj.common_name == this.current_species)
            // console.log(index)
            temp_species_id = this.species[index].id
            
            let tempData = {
                "id": this.selected_catch.id,
                "common_name": this.current_species,
                "species_id": temp_species_id,
                "status": "2",
                "train_date": null,
                "submit_date": null,
                "tags": temp_tags,
                "image_name": this.selected_catch.image_name
            }
            // console.log(tempData)
            this.failed_list.push(tempData)
            Object.assign(this.items[this.current_row], tempData)
            // console.log(this.items[this.current_row])
            // console.log(this.failed_list)
            this.overlay = false
        },
        addToTrainList(){
            let temp_tags = null
            let temp_species_id = null
                if(this.current_tags !== null){
                    temp_tags = this.current_tags.toString()
                }

            let index = this.species.findIndex(obj => obj.common_name == this.current_species)
            // console.log(index)
            temp_species_id = this.species[index].id
            
            let tempData = {
                "id": this.selected_catch.id,
                "common_name": this.current_species,
                "species_id": temp_species_id,
                "status": "5",
                "train_date": null,
                "submit_date": null,
                "tags": temp_tags,
                "image_name": this.selected_catch.image_name
            }
            // console.log(tempData)
            this.training_list.push(tempData)
            Object.assign(this.items[this.current_row], tempData)
            // console.log(this.items[this.current_row])
            // console.log(this.training_list)
            this.overlay = false
        },
        removefromTrainList(){
            // console.log(this.selected_catch)
            // let index = this.training_list.indexOf(this.selected_catch)
            let index = this.training_list.findIndex(obj => obj.id == this.selected_catch.id)
            let itemsIndex = this.items.findIndex(obj => obj.id == this.selected_catch.id)
            Object.assign(this.items[itemsIndex], this.training_catches[this.current_row])
            this.training_list.splice(index, 1)
            // console.log(itemsIndex)
            // console.log(this.training_list)
            // console.log(index)
            this.overlay = false
        },
        removefromTrainListInList(){
            let index = this.training_list.findIndex(obj => obj.id == this.selected_catch.id)
            let itemsIndex = this.items.findIndex(obj => obj.id == this.selected_catch.id)
            Object.assign(this.items[itemsIndex], this.training_catches[itemsIndex])
            this.training_list.splice(index, 1)
            // console.log(this.items[itemsIndex])
            this.overlay2 = false
        },
        removefromfaliedListInList(){
            // console.log(this.selected_catch)
            // let index = this.failed_list.indexOf(this.selected_catch)
            let index = this.failed_list.findIndex(obj => obj.id == this.selected_catch.id)
            let itemsIndex = this.items.findIndex(obj => obj.id == this.selected_catch.id)
            Object.assign(this.items[itemsIndex], this.training_catches[itemsIndex])
            this.failed_list.splice(index, 1)

            // console.log(this.items[itemsIndex])

            this.overlay2 = false
        },
    },
    created () {

    },
    mounted(){
        this.$store.dispatch('spot_mapper/getSpecies')
        this.$store.dispatch('admin/getTrainingCatches')
        .then(() => {
            // console.log(this.training_catches)
        })
        .finally(() => {
            this.showLoading = false
            // Create Deep Copy
            this.items = JSON.parse(JSON.stringify(this.training_catches));
            this.current_list = JSON.parse(JSON.stringify(this.training_catches));
            this.FilterList("Unprocessed")
            // console.log(this.items)
        })
        
    }
}
</script>
<style scoped>
/* Should zebra stripe leaderboard */
>>>tr:nth-child(even){
    background-color: #90CAF9;
}
</style>