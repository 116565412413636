<template>
  <span>
    <l-control 
      class="custom-control" 
      position="topright"      
    >
      <v-tooltip left :disabled=is_mobile v-model="help">
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            icon
            @click="toggleSpotMarkerMode"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon :color="showSpotMarker ? 'deep-orange':'icetimeblue'">fa-fish</v-icon>
          </v-btn>
        </template>
        <span>Place Fishing Spot</span>
      </v-tooltip>
    </l-control>
    <l-marker 
      draggable
      :icon="icon_active"
      :lat-lng="[currentLatLong.lat,currentLatLong.lng]" 
      ref="spot" 
      v-if="showSpotMarker && marker_location"
      v-on:moveend="setCurrentLatLong"
    >
      <l-popup 
        class="py-0 my-0"
        :closeButton="false"
        persistent
      >
        <v-layout class="px-4 py-5" row v-if="showPrompt"> 
          <v-flex class="text-h6">
            Create a Fishing Spot @ {{ currentLatLong.lat.toFixed(4) }}, {{ currentLatLong.lng.toFixed(4) }}?
          </v-flex>
          <v-flex class="mt-2 d-flex justify-center">
            <v-text-field
              class="ml-3 my-4 text-body-2 form-label"
              dense
              hide-details
              label="Fishing Spot Name"
              type="text"
              v-model="spotName"
            />
          </v-flex>
          <v-flex class="mb-2 d-flex justify-center">
            <v-textarea v-model="spotComment" class="ma-2 px-0 py-4" label="Comment" solo ></v-textarea>
          </v-flex>
          <v-flex class="d-flex justify-end">
            <v-btn 
              class="ma-1 white--text pa-4" 
              color="grey"
              small
              @click="clickCancel"
            >
              Cancel
            </v-btn>
            <v-btn 
              class="ma-1 white--text pa-4" 
              color="black"
              small
              @click="saveFishingSpot"
            >
              Save
            </v-btn>
          </v-flex>
        </v-layout>
      </l-popup>
    </l-marker>
  </span>
</template>

<script>
import { divIcon } from 'leaflet'
import { LControl,LMarker,LPopup } from 'vue2-leaflet'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      currentLatLong: '',
      spotName: '',
      spotComment: '',
      icon_active: new divIcon({
        className: 'myDivIcon',
        html:'<i class="fa fa-map-marker-alt fa-3x" style="color: #FF5722; text-shadow: -3px -3px 0 #fff, 3px -3px 0 #fff, -3px 3px 0 #fff, 3px 3px 0 #fff, 0 7.3px 0px #fff, 0 -3.5px 0 #fff, 3.5px 0 0 #fff, -3.5px 0 0 #fff;" />',
        iconSize: [20, 20],
        popupAnchor: [4,-25]
      }),
      showFishingSpotForm: false,
      showPrompt: true,
      showSpotMarker: false,
      help: false,
    }
  },
  components: {
    LControl,
    LMarker,
    LPopup,
  },
  computed: {
    ...mapState('spot_mapper', {
      species: state => state.species
    }),
    ...mapState('user', {
      user: state => state.user,
    }),
    ...mapState('map', {
      showHelp: state => state.showHelp,
    }),    
    is_mobile() {
      return this.$vuetify.breakpoint.xs ? this.showHelp ? false : true : false
    }    
  },
  created() {
  },
  watch: {
    marker_location(){
      // console.log("marker Locaiton")
      this.currentLatLong = this.marker_location
      this.$emit('close_snackbar')
    },
    showHelp() {
      this.help = this.showHelp
    },    
  },
  methods: {
    setCurrentLatLong() {
      this.currentLatLong = this.$refs.spot.mapObject.getLatLng()
      // this.marker_location = this.currentLatLong
      // console.log('there is data ' + this.currentLatLong)
    },
    clickCancel() {
      if (this.$refs.spot) {
        this.$refs.spot.mapObject.closePopup() 
      }
      this.showPrompt = true
      this.showFishingSpotForm = false
      this.spotName = ''
      this.spotComment = ''
    },
    saveFishingSpot() {
      let spotItem = {
        name: this.spotName,
        comment: this.spotComment,
        gps: this.currentLatLong,
        user_id: this.user.id,
      }
      this.$store.dispatch('spot_mapper/recordFishingSpot', spotItem)
      .then(() => {this.$emit('spot-updated')})      
      .catch(() => {})
      .finally(() => {
        //this.resetForm()
        this.toggleSpotMarkerMode()
      })
    },
    toggleSpotMarkerMode() {
      this.showSpotMarker = !this.showSpotMarker
      this.clickCancel()

      if(this.showSpotMarker) {
        this.$emit('spot-toggled')
      } else {
        this.$emit('spot-untoggled')
      }
      // console.log("show spot marker state: " + this.showSpotMarker)
    },
  },
  mounted() {
    this.clickCancel()

    //*This is to view the url Data Params, and will be used for the Bumb Board testing*
    let urlParams = new URLSearchParams(window.location.search);
    let myParam = urlParams.get('page');
    // console.log(myParam)
    if(myParam === "autospot"){
      // console.log('You got here from autospot')
    }
  },
  props: [
    'marker_location'
  ]
}
</script>

<style scoped>
>>>.v-label {
  font-size: 14px;
}

>>>.myDivIcon {
  line-height: 20px;
  text-align: center;
}
</style>