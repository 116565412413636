<template>
  <v-card
    class="ma-5 pa-4"
  >
    <ComingSoonChip />
    <v-card-title>
      On Demand
    </v-card-title>
    <v-card-text class="text-body-1">
      Ice Time Free enables you to order radar satellite images or join an angling competition when you choose to, and without a subscription.
    </v-card-text>
  </v-card>
</template>
  
<script>
import ComingSoonChip from "./ComingSoonChip.vue"

export default { 
  components: {
    ComingSoonChip,
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
  