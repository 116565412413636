<template>
<v-container>
    <h1>Redirecting <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular></h1>
    <!-- <h2>Maybe have a normal QR code on bumpboard packaging to link to this page and have an explination/video explaning the product and how to use it</h2> -->
        
</v-container>
</template>
<script>
  
export default {
    components: {

    },
    data() {
        return {
        }
    },
    computed: {
        // ...mapState('admin', {
        //     training_catches: state => state.training_catches
        // })
      },
    created () {

    },
    methods: {

    },
    async mounted(){        
        let urlParams = new URLSearchParams(window.location.search);
        let myParam = urlParams.get('qr');
        // console.log(myParam)
        if(myParam === "joined"){
            window.location.replace('/app/leaderboard/complist?mode=joined');
        }
        if(myParam === "regional"){
            window.location.replace('/app/leaderboard/all');
        }
        if(myParam === "autospot"){
            window.location.replace('/app/map?mode=autospot');
        }
        if(myParam === "about"){
            //app/leaderboard/bumpboard?qr=about
            window.location.replace('/about/alb');
        }
        
        // const devices = await navigator.mediaDevices.enumerateDevices();
        // this.videoDevices = devices.filter((d) => d.kind === "videoinput");
        // await this.StartRecording(
        //     this.videoDevices.length === 1 ? "user" : "environment"
        // );
    }
}
</script>

<style scoped>


</style>