<template>
  <AdminTabs v-if="mode == 'admin'" />
  <AppTabs v-else-if="mode == 'app'" />
  <UserTabs v-else-if="mode == 'user'" />
</template>

<script>
import { mapState } from 'vuex'
import AdminTabs from '../features/admin/components/AdminTabs.vue'
import AppTabs from '../features/map/components/AppTabs.vue'
import UserTabs from '../features/user/components/UserTabs.vue'

export default {
  components: {
    AdminTabs,
    AppTabs,
    UserTabs,
  },
  computed: {
    ...mapState(['mode'])
  },
  created() {
  },
  mounted() {
  },
  data() {
    return {
    }
  }
}
</script>

<style scoped>
>>>.theme--dark.v-tabs > .v-tabs-bar {
  background-color: transparent;
}
</style>