<template>
<v-container>
<v-scroll-y-reverse-transition mode="out-in" :hide-on-leave="true">
    <v-dialog      
        max-width="1200px"
        hide-overlay
        :fullscreen="mobile_fullscreen"
        transition="dialog-bottom-transition"
        mode="out-in"
        no-click-animation
        persistent
        value="full_breakdown"
    >
        <v-card class="pa-4" style="overflow-y: auto; ">
            <v-card-actions class="pt-4">
                <v-spacer></v-spacer>
                <v-btn @click="closeDialog" text class="grey--text">
                Close
                <v-icon right color="grey">mdi-close</v-icon>
                </v-btn>
            </v-card-actions>
            <v-divider></v-divider>
            <v-card-title>View All Catches</v-card-title>
            <v-card-actions>
                <v-btn
                v-if="!mobile_fullscreen"
                @click="testBTN()">
                    Swap length/weight
                </v-btn>
            </v-card-actions>
            <div class="px-6">
                <div class="mb-2 text-caption font-weight-bold">Sort By</div>
                <v-select 
                    :items="select_items"
                    v-model="sortBy"
                    item-text="label"
                    item-value="value"
                    solo
                    read-only
                    dense
                    return-object
                    prepend-icon="mdi-sort"
                    @change="changeHeader"
                    >
                </v-select>
            </div>
            <v-divider></v-divider>

            <v-data-table
                v-if="mobile_fullscreen"
                :mobile-breakpoint="0"
                :headers="mobile_headers"
                :items="catches"
                :items-per-page="-1"
                :sort-by="sortBy.value"
                :sort-desc="sortDesc"
                :single-expand="true"
                :hide-default-footer="true"
                show-expand
                @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            >
                <template v-slot:item.date="{ item }">
                    <div v-if="item.date">{{ formatDate(item.date) }}</div>
                    <div v-else>-</div>
                </template>  
                <template v-slot:item.competition_id="{ item }">
                    <div v-if='item.competition != null'>{{ item.competition_id }}</div>
                    <div v-else>-</div>
                </template>  
                <template v-slot:item.length="{ item }">
                    <div v-if='item.length != ""'>{{ Math.round((item.length) * 1000) / 1000 }} cm.</div>
                    <div v-else>-</div>
                </template>  
                <template v-slot:item.weight="{ item }">
                    <div v-if='item.weight != ""'>{{ Math.round((item.weight) * 1000) / 1000 }} lbs.</div>
                    <div v-else>-</div>
                </template>  
                <template v-slot:item.common_name="{ item }">
                    <div class="capitalize">{{ item.common_name }}</div>
                </template>  
                <template v-slot:expanded-item="{ headers, item }">
                    <td class="expand-panel" :colspan="headers.length">
                        <v-row class="pt-2">
                            <v-col cols="12">
                                <v-card-text class="py-0">
                                    <div class="text-caption font-weight-bold">Species Name</div>
                                    <p class="capitalize text-body-1">{{ item.common_name }}</p>                            
                                </v-card-text>   
                            </v-col>  
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="6">
                                <v-card-text class="py-0">
                                    <div class="text-caption font-weight-bold">Length</div>
                                    <p v-if="item.length" class="text-body-1">{{ Math.round((item.length) * 1000) / 1000 }} cm.</p>  
                                    <p v-else class="text-body-1">-</p>                          
                                </v-card-text>   
                            </v-col>  
                            <v-col cols="6">
                                <v-card-text class="py-0">
                                    <div class="text-caption font-weight-bold">Predicted Length</div>
                                    <p v-if="item.ai_length" class="text-body-1">{{ Math.round((item.ai_length) * 1000) / 1000 }} cm.</p>  
                                    <p v-else class="text-body-1">-</p>                          
                                </v-card-text>   
                            </v-col>  
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="6">
                                <v-card-text class="py-0">
                                    <div class="text-caption font-weight-bold">Weight</div>
                                    <p v-if="item.weight" class="text-body-1">{{ Math.round((item.weight) * 1000) / 1000 }} lbs.</p>  
                                    <p v-else class="text-body-1">-</p>                          
                                </v-card-text>   
                            </v-col>  
                            <v-col cols="6">
                                <v-card-text class="py-0">
                                    <div class="text-caption font-weight-bold">Depth</div>
                                    <p v-if="item.depth" class="text-body-1">{{ Math.round((item.depth) * 1000) / 1000 }} m.</p>  
                                    <p v-else class="text-body-1">-</p>                          
                                </v-card-text>   
                            </v-col>  
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-card-text class="py-0">
                                    <div class="text-caption font-weight-bold">Predicted Species</div>
                                    <p v-if="item.ai_common" class="text-body-1">{{ ai_common }}</p>  
                                    <p v-else class="text-body-1">-</p>                          
                                </v-card-text>   
                            </v-col>  
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="6">
                                <v-card-text class="py-0">
                                    <div class="text-caption font-weight-bold">Upload Status</div>
                                    <v-icon v-if="item.ai_status==2 && item.measurement_status==2 && item.qr_status==2 && item.board_status==2 && item.fish_status==2" large color="green">mdi-checkbox-marked</v-icon>
                                    <v-icon v-else-if="item.ai_status==1 || item.measurement_status==1 || item.qr_status==1 || item.board_status==1 || item.fish_status==1" large color="red">mdi-close-box</v-icon>
                                    <v-icon v-else large color="black">mdi-checkbox-blank-outline</v-icon>                
                                </v-card-text>   
                            </v-col>  
                            <v-col cols="6">
                                <v-card-text class="py-0">
                                    <div class="text-caption font-weight-bold">Contested</div>
                                    <p v-if="item.status==0"><v-icon color="orange">mdi-flag-variant</v-icon></p>
                                    <p v-else>-</p>                  
                                </v-card-text>   
                            </v-col>  
                        </v-row>
                        <v-row class="pb-2" no-gutters>
                            <v-col cols="12">
                                <v-card-text class="py-0">
                                    <div class="text-caption font-weight-bold">Photo</div>
                                    <v-img         
                                        v-if="item.image_name"        
                                        class="grey darken-1"
                                        :src="(image_start + item.image_name)" 
                                        aspect-ratio="2"
                                        contain
                                        alt="Catch"
                                        @click="showImage(item)"/>
                                    <v-icon v-else large>mdi-fish</v-icon>           
                                </v-card-text>   
                            </v-col>  
                        </v-row>
                    </td>
                </template>
            </v-data-table>



            <v-data-table
                v-else
                dense
                :headers="headers"
                :items="catches"
                :items-per-page="-1"
                :sort-by="sortBy.value"
                :sort-desc="sortDesc"
                @click:row="showImage"
            >
                <template v-slot:item.image_name="{ item }">
                        <v-img         
                            v-if="item.image_name"        
                            class="grey darken-1"
                            :src="(image_start + item.image_name)" 
                            aspect-ratio="1"
                            contain
                            alt="Catch"/>
                        <v-icon v-else large>mdi-fish</v-icon>
                </template>
                <template v-slot:item.ai_status="{ item }">
                    <!-- <v-icon v-if="item.ai_status==2" large color="green">mdi-checkbox-marked</v-icon>
                    <v-icon v-else-if="item.ai_status == 1" large color="red">mdi-close-box</v-icon> -->
                    <v-icon v-if="item.ai_status==2 && item.measurement_status==2 && item.qr_status==2 && item.board_status==2 && item.fish_status==2" large color="green">mdi-checkbox-marked</v-icon>
                    <v-icon v-else-if="item.ai_status==1 || item.measurement_status==1 || item.qr_status==1 || item.board_status==1 || item.fish_status==1" large color="red">mdi-close-box</v-icon>
                    <v-icon v-else large color="black">mdi-checkbox-blank-outline</v-icon>
                </template>
                <template v-slot:item.status="{ item }">
                    <!-- <div v-if="item.length && item.ai_length"><v-icon v-if="1-(item.length/item.ai_length) > 0.02 || 1-(item.ai_length/item.length) > 0.02" color="orange">mdi-flag-variant</v-icon></div> -->
                    <div v-if="item.status==0"><v-icon color="orange">mdi-flag-variant</v-icon></div>
                    <div v-else>-</div>
                </template>
                <template v-slot:item.length="{ item }">
                    <div v-if="item.length">{{ Math.round((item.length) * 1000) / 1000 }} cm.</div>
                    <div v-else>-</div>
                    
                </template>
                <template v-slot:item.weight="{ item }">
                    <div v-if="item.weight">{{ Math.round((item.weight) * 1000) / 1000 }}</div>
                    <div v-else>-</div>
                    
                </template>
                <template v-slot:item.ai_length="{ item }">
                    <div v-if="item.ai_length">{{ Math.round((item.ai_length) * 1000) / 1000 }} cm.</div>
                    <div v-else>-</div>
                    
                </template>
                <template v-slot:item.ai_common="{ item }">
                    <div v-if="item.ai_common">{{ item.ai_common }}</div>
                    <div v-else>-</div>
                    
                </template>
                <template v-slot:item.common_name="{ item }">
                    <div class="capitalize" v-if="item.common_name">{{ item.common_name }}</div>
                    <div v-else>-</div>
                    
                </template>                
            </v-data-table>
        </v-card>
    </v-dialog>
</v-scroll-y-reverse-transition>
<v-dialog
        v-model="image_overlay"
        max-width="800"
    >
        <v-card max-width="800" class="pa-1">
            <v-card-actions class="pa-2">
                <v-spacer></v-spacer>
                <v-icon
                    large
                    @click="resetDefaults()"
                >mdi-close-circle
                </v-icon>                
            </v-card-actions>
            <v-img 
                v-if="image_name" 
                :src="(image_start + image_name)" 
                max-height="500"
                contain
            >
                <template v-slot:placeholder>
                    <div class="d-flex align-center justify-center fill-height">
                        <v-progress-circular
                        color="grey-lighten-4"
                        indeterminate
                        ></v-progress-circular>
                    </div>
                </template>
            </v-img>
        </v-card>
    </v-dialog>
</v-container>
</template>
  
<script>
  import { mapState } from 'vuex';
  
  export default {
        components: {
        },
        computed: {
            ...mapState('spot_mapper', {
                catches: state => state.catches,
                selected_fishing_spot: state => state.selected_fishing_spot,
            }),
            mobile_fullscreen() {
                return !this.$vuetify.breakpoint.mobile ? false : true
                // return true
            }, 
        },   
        data() {
            return {
                select_items: [
                    { label: 'Date Caught', value: 'date'},
                    { label: 'ID', value: 'id'},
                    { label: 'Competition', value: 'competition_id'},
                    { label: 'Species', value: 'common_name'},
                    { label: 'Length', value: 'length'},
                    { label: 'Weight', value: 'weight'},
                ],
                full_breakdown:true,
                mobile_headers: [
                    { text: 'Date Caught', value: 'date' },
                    { text: '', value: 'data-table-expand' },
                ],
                headers: [
                    { text: 'Fish Name', value: 'common_name' },
                    { text: 'Date Caught', value: 'date' },
                    { text: 'Length', value: 'length' },
                    { text: 'Calculated Length', value: 'ai_length' },
                    { text: 'Predicted Species', value: 'ai_common' },
                    { text: 'Upload Status', value: 'ai_status', align: 'center' },
                    { text: 'Contested', value: 'status' },
                    { text: 'Picture', value: 'image_name' },
                ],
                image_start: this.$apiEndpointUrl + "catch_image/",
                sortBy: 'date',
                sortDesc: true,
                length_mode: true,
                image_name: null,
                image_overlay:false,

            }
        },
        methods: {
            changeHeader(event) {
                this.mobile_headers = [
                    { text: event.label, value: event.value },
                    { text: '', value: 'data-table-expand' },
                ]
            },
            formatDate(date_string){
                return new Date(date_string).toLocaleString('en-ca')
            },
            closeDialog() {
                this.$emit('fullBreakdown')
            },
            testBTN(){
                this.length_mode = !this.length_mode
                if(this.length_mode == true){
                    this.headers = [
                        { text: 'Fish Name', value: 'common_name' },
                        { text: 'Date Caught', value: 'date' },
                        { text: 'Length', value: 'length' },
                        { text: 'Calculated Length', value: 'ai_length' },
                        { text: 'Predicted Species', value: 'ai_common' },
                        { text: 'Upload Status', value: 'ai_status', align: 'center' },
                        { text: 'Contested', value: 'status' },
                        { text: 'Photo', value: 'image_name' },
                    ]
                }else{
                    this.headers = [
                        { text: 'Fish Name', value: 'common_name' },
                        { text: 'Date Caught', value: 'date' },
                        { text: 'Weight', value: 'weight' },
                        { text: 'Calculated Length', value: 'ai_length' },
                        { text: 'Predicted Species', value: 'ai_common' },
                        { text: 'Upload Status', value: 'ai_status', align: 'center' },
                        { text: 'Contested', value: 'status' },
                        { text: 'Photo', value: 'image_name' },
                    ]
                }
            },
            showImage(row){
                this.image_name = row.image_name
                // this.selected_catch = row.id

                this.image_overlay = true
            },
            resetDefaults(){
                this.image_overlay = false
            },
        },
        created() {
            this.$store.dispatch('spot_mapper/getCatches', this.selected_fishing_spot)
            .then(() => {
                // console.log(this.catches)
            })
        },
    }
</script>
<style scoped>
/* Should zebra stripe leaderboard */
>>>tr:nth-child(even){
    background-color: #90CAF9;
}
.expand-panel {
    background-color: white !important;
}
.capitalize {
    text-transform: capitalize;
}
.small-radio >>> label {
    font-size: 14px;
}
</style>