import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router.js'
import store from './store.js'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import { Icon }  from 'leaflet'
import 'leaflet/dist/leaflet.css'
import VueGeolocation from 'vue-browser-geolocation';

// Test commit
// import firebase from "firebase/app";
import firebaseMessaging from "firebase/firebase-messaging";
// import firebaseMessaging from 'firebase'

Vue.use(VueGeolocation);

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

Vue.prototype.$http = axios
//Vue.prototype.$apiEndpointUrl = 'https://nge-api.greenaerotech.com/v1/'
// Vue.prototype.$apiEndpointUrl = 'http://api-2.icetime.app.test/v1/'
Vue.prototype.$apiEndpointUrl = 'https://api-2.icetime.app/v1/'
axios.defaults.baseURL = Vue.prototype.$apiEndpointUrl

const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

Vue.config.productionTip = false

// For firebase cloud messaging (FCM)
// Vue.prototype.$messaging = firebase.messaging();
Vue.prototype.$messaging = firebaseMessaging;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  mounted() {
    if(this.$store.getters.isLoggedIn){
      this.$store.dispatch('user/subscribe')
      .catch(err => {
        if(err.response.status == '403') {
          this.$store.dispatch('logout')
          this.$store.dispatch('setDialog', { 
            show: true, 
            message: err.response.data, 
            status: err.response.status })
        }
      })
    }
  }
}).$mount('#app')