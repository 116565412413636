import EULAPersonal from './views/EulaPersonal'
import EULAPower from './views/EulaPower'
import PrivacyPolicy from './views/PrivacyPolicy'
import TermsOfService from './views/TermsOfService'
import TermsOfUse from './views/TermsOfUse'

export const LegalRoutes = [
  {
    path: '/legal/eulapersonal',
    name: 'End User License Agreement for Personal Accounts',
    component: EULAPersonal
  },
  {
    path: '/legal/eulapower',
    name: 'End User License Agreement for Power Accounts',
    component: EULAPower
  },
  {
    path: '/legal/privacy',
    name: 'Privacy Policy',
    component: PrivacyPolicy
  },
  {
    path: '/legal/termsofservice',
    name: 'Terms of Service',
    component: TermsOfService
  },
  {
    path: '/legal/termsofuse',
    name: 'Terms of Use',
    component: TermsOfUse
  }
]