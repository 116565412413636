<template>
  <span>
    <l-control 
      class="custom-control" 
      position="topright"
      v-if="isLoggedIn"
    >
      <span class="text-body-2 mx-2 mt-2" v-if="hazardMarkerMode == 1">
        {{ center.lat }}, {{ center.lng }}
      </span>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon @click.stop="clickHazardIcon"
            :color="hazardCreateColor"
            :disabled="lockMapOn || (!isAdmin && visibleLayer.id != footprint_dates[0].id)"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-alert</v-icon>
          </v-btn>
          <!-- <span class="text-body-2 mx-2 mt-2" v-if="hazardMarkerMode == 1">
            {{ center.lat }}, {{ center.lng }}
          </span>-->
        </template>
        <span v-if="!hazardMarkerMode">Toggle Hazard Creation</span>
        <span v-if="hazardMarkerMode">Exit Hazard Creation</span>
      </v-tooltip>
    </l-control>
    <l-marker v-if="hazardMarkerMode" ref="demoHazardMarker" :lat-lng="[center.lat,center.lng]" :icon="centerMarker">
      <l-popup class="py-2">
        <span class="text-body-1">Place new hazard icon here?</span>
        <v-row class="mt-1">
          <v-spacer />
          <v-btn text color="blue darken-1" @click.prevent="clickHazardButton">Confirm</v-btn>
        </v-row>
      </l-popup>
    </l-marker>
    <v-dialog v-model="hazardMenuDialog" scrollable max-width="400px" width="unset">
      <v-card>
        <v-card-title>Add Icon</v-card-title>
        <v-card-subtitle>
          GPS {{ center }}
        </v-card-subtitle>
        <v-container>
          <v-form ref="form" lazy-validation>
            <v-select
              v-model="selectedHazardItemGroup"
              v-if="isAdmin"
              @change="hazardItemGroupSelected"
              :items="hazardItemGroups"
              label="Select Hazard Type"
              outlined
              read-only
              required
            />
            <v-select
              v-if="hazardItems.length > 0 && selectedHazardItemGroup"
              v-model="selectedHazard"
              :items="hazardItems"
              item-text="name"
              item-value="id"
              label="Select Icon"
              @change="selectChanged"
              outlined
              read-only
              required
              :rules="[checkSelectedHazard]"
            >
              <template v-if="selectedHazardItemGroup == 1" v-slot:selection="{ item }">
                <img :src="'/img/hazard_icons/user_' + item.icon" aspect-ratio="1" width="40" class="mr-3">{{ item.name }}
              </template>
              <template v-else-if="selectedHazardItemGroup == 2" v-slot:selection="{ item }">
                <img :src="'/img/hazard_icons/pro_' + item.icon" aspect-ratio="1" width="40" class="mr-3">{{ item.name }}
              </template>
              <template v-else-if="selectedHazardItemGroup == 9" v-slot:selection="{ item }">
                <img :src="'/img/hazard_icons/admin_' + item.icon" aspect-ratio="1" width="40" class="mr-3">{{ item.name }}
              </template>

              <template v-if="selectedHazardItemGroup == 1" v-slot:item="{ item }">
                <img :src="'/img/hazard_icons/user_' + item.icon" aspect-ratio="1" width="40" class="mr-3">{{ item.name }}
              </template>
              <template v-else-if="selectedHazardItemGroup == 2" v-slot:item="{ item }">
                <img :src="'/img/hazard_icons/pro_' + item.icon" aspect-ratio="1" width="40" class="mr-3">{{ item.name }}
              </template>
              <template v-else-if="selectedHazardItemGroup == 9" v-slot:item="{ item }">
                <img :src="'/img/hazard_icons/admin_' + item.icon" aspect-ratio="1" width="40" class="mr-3">{{ item.name }}
              </template>
            </v-select>
            <!-- <v-select
              v-if="this.itemsWithExpiry.includes(this.selectedHazard)"
              v-model="selectedExpiry"
              label="Expiry"
              :items="expiryItems"
              outlined
              read-only
            /> -->
            <v-select
              v-if="this.selectedHazard == 16"
              v-model="selectedCandling"
              :items="candlingItems"
              label="Seep Level"
              outlined
              read-only
            />
            <v-text-field
              v-if="this.itemsWithMeasurement.includes(this.selectedHazard)"
              v-model="iconValue" 
              :rules="[checkNumericValue]"
              type="number" 
              label="Measure"
              outlined
            />
            <v-radio-group
              v-if="this.itemsWithMeasurement.includes(this.selectedHazard)"
              v-model="iconUnitMeasure" 
              mandatory
              row
            >
              <v-spacer />
              <v-radio label="Metres" value="m" />
              <v-radio label="Inches" value="in" />
              <v-spacer />
            </v-radio-group>
            <v-textarea
              v-if="this.itemsWithDescription.includes(this.selectedHazard)"
              v-model="iconNote" 
              :rules="[checkDescription]"
              label="Description"
              rows="4"
              outlined
              autogrow
            />
          </v-form>
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-switch
            v-if="this.itemsWithPrivacy.includes(this.selectedHazard)"
            v-model="iconIsPublic"
            :label="`${iconIsPublic ? 'Public':'Private'}`"
            color="success"
            inset
          />
          <v-divider 
            v-if="this.itemsWithPrivacy.includes(this.selectedHazard)"
            class="mx-4"
            inset
            vertical
          />
          <v-btn
            color="blue darken-1" 
            text
            @click="cancelHazard"
          >
            Cancel
          </v-btn>
          <v-btn 
            color="blue darken-1" 
            text 
            @click.prevent="createHazard"
          >
            Create
          </v-btn>
        </v-card-actions>
        
        <v-alert v-if="infoMessage !== ''" type="info" class="mt-8">
          {{ infoMessage }}
        </v-alert>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import { LControl, LMarker, LPopup, } from 'vue2-leaflet'
  import { icon } from 'leaflet';

  export default {
    components: {
      LControl,
      LMarker,
      LPopup,
    },
    computed: {
      ...mapGetters('user', { 
        isAdmin: 'isAdmin',
        isLoggedIn: 'isLoggedIn',
      }),
      ...mapState('demo', {
        footprints: state => state.footprints,
        lockMapOn: state => state.lockMapOn,
        visibleLayer: state => state.visibleLayer
      }),
      ...mapState('user', {
        user: state => state.user
      }),
      ...mapState(['hazardtypes']),
      checkDescription() {
        return () => this.iconNote.length > 0 || "Note description cannot be empty.";
      },
      checkNumericValue() {
        return () => isNaN(Number.parseFloat(this.iconValue)) == 0 || "Measurement must be numeric.";
      },
      checkSelectedHazard() {
        return () => this.selectedHazard && this.selectedHazard > 0 || "Select a hazard to create.";
      },
      checkUnitMeasure() {
        return () => this.iconUnitMeasure || "Please select your unit of measure.";
      },
    },
    created() {
      this.$store.dispatch('getHazardTypes')
      .then(() => {
        // Set the hazard list depends on current user status
        this.userItems = this.hazardtypes.filter(function(el) {
          return el.user_order != null
        })
        this.userItems.sort(function(a, b) {
          return parseInt(a.user_order) - parseInt(b.user_order)
        })
        this.proItems =  this.hazardtypes.filter(function(el) {
          return el.pro_order != null
        })
        this.proItems.sort(function(a, b) {
          return parseInt(a.pro_order) - parseInt(b.pro_order)
        })
        this.adminItems = this.hazardtypes.filter(function(el) {
          return el.admin_order != null
        })
        this.adminItems.sort(function(a, b) {
          return parseInt(a.admin_order) - parseInt(b.admin_order)
        })
      })
    },
    data() {
      return {
        adminItems: [],
        candlingItems: [
          { text: '1/4', value: 0.25 },
          { text: '1/2', value: 0.50 },
          { text: '3/4', value: 0.75 }
        ],
        centerMarker: new icon ({
          iconUrl: "/img/crosshairs.png",
          iconSize: [64,64],
          iconAnchor: [32,64]
        }),
        // expiryItems: [
        //   { text: 'After 1 Day', value: 1 },
        //   { text: 'After 2 Days', value: 2 },
        //   { text: 'After 3 Days', value: 3 },
        //   { text: 'After 4 Days', value: 4 },
        //   { text: 'After 5 Days', value: 5 },
        //   { text: 'After 6 Days', value: 6 },
        //   { text: 'After 7 Days (default)', value: 7 },
        // ],
        hazardCreateColor: 'deep-orange',
        hazardCreateDialog: false,
        hazardItemGroups: [ 
          { text: 'User', value: 1 },
          { text: 'Pro', value: 2 },
          { text: 'Admin', value: 9 }
        ],
        hazardItems: [],
        hazardMarkerColor: 'deep-orange',
        hazardMarkerMode: false,
        hazardMenuDialog: false,
        hazardMenuMarker: '',
        iconIsPublic: true,
        iconNote: '',
        iconUnitMeasure: '',
        iconValue: '',
        infoMessage: '',
        itemsWithDescription: ['14'],
        itemsWithExpiry: ['1', '4', '5', '7', '13', '14', '15'],
        itemsWithMeasurement: ['1', '2'],
        itemsWithPrivacy: ['2', '11', '14'],
        proItems: [],
        selectedCandling: '',
        selectedExpiry: 1, // default 1 for demo
        selectedHazard: '',
        selectedHazardItemGroup: null,
        userItems: [],
      }
    },
    methods: {
      cancelHazard() {
        this.hazardMenuDialog = false
        this.selectedHazardItemGroup = null
        this.selectChanged()
      },
      clickHazardButton() {
        this.$refs.demoHazardMarker.mapObject.closePopup()

        if (this.isAdmin) {
          this.selectedHazardItemGroup = null
          this.hazardMenuDialog = true
        } else if (this.isLoggedIn) {
        // } else {
          this.selectedHazardItemGroup = 1
          this.hazardItems = this.userItems
          this.hazardMenuDialog = true
        }
      },
      clickHazardIcon() {
        if (this.hazardMarkerMode == 0) {
          this.hazardMarkerMode = 1
        this.hazardMarkerColor = 'green'
        } else {
          this.hazardMarkerMode = 0
          this.hazardMarkerColor = 'silver'
        }
      },
      createHazard() {
        this.hazardCreateDialog = false
        if (this.$refs.form.validate()) {
          this.infoMessage = "Creating hazard ..."

          // Adjust the iconValue being passed
          if (this.itemsWithMeasurement.includes(this.selectedHazard)) {
            this.iconValue = this.iconUnitMeasure == "m" ? this.iconValue:(this.iconValue / 39.3701)
          } else if (this.selectedHazard == 16) {
            this.iconValue = this.selectedCandling;
          } else if (this.selectedHazard == 14) {
            this.iconValue = this.iconNote;
          } else {
            this.iconValue = '';
          }

          // Build data payload
          let data = {
            radar_id: this.id,
            user_id: this.user.id,
            usertype_id: this.selectedHazardItemGroup,
            hazardtype_id: this.selectedHazard,
            is_public: this.iconIsPublic == true ? 1:0,
            value: this.iconValue,
            expiration_days: this.selectedExpiry,
            lat: this.center.lat,
            lng: this.center.lng,
            layer_id: this.visibleLayer.id
          }

          // HTTP to API
          this.$store.dispatch('demo/createDemoHazard', data)
          .then(() => {
            this.clickHazardIcon() // turns off hazard mode
            this.infoMessage = "Hazard has been recorded, thank you!"
            this.populatePins()
            setTimeout(() => {
              this.infoMessage = "";
              this.hazardMenuDialog = false;
              this.selectChanged();
            }, 1500);
          })
          .catch((err) => {
            if (err.response.status == '403') {
              this.$store.dispatch('logout')
              this.$store.dispatch('setDialog', { 
                show: true, 
                message: err.response.data, 
                status: err.response.status
              })
            } else {
              this.infoMessage = err.response.data
              setTimeout(() => {
                this.infoMessage = "";
                this.hazardMenuDialog = false;
                this.selectChanged();
              }, 3000);
            }
          })
        }
      },
      hazardItemGroupSelected() {
        this.selectedHazard = ''
        if (this.selectedHazardItemGroup == 1) {
          this.hazardItems = this.userItems
        } else if (this.selectedHazardItemGroup == 2) {
          this.hazardItems = this.proItems
        } else if (this.selectedHazardItemGroup == 9) {
          this.hazardItems = this.adminItems
        }
      },
      selectChanged() {
        this.selectedExpiry = 1;
        this.selectedCandling = '';
        this.iconNote = '';
        this.iconValue = '';
        this.iconUnitMeasure = '';
        this.iconValue = '';
      },
      toggle() {
        this.$store.dispatch('demo/toggleHazardMarkerTool')
        // this.$emit('hazard-marker-mode-changed')
      },
    },
    props: ['center']
  }
</script>

<style scoped>
>>>.v-dialog {
  position: absolute;
  bottom: 0;
  right: 0;
}

>>>.card-outter {
  position: relative;
  padding-bottom: 1.5em;
}

>>>.card-actions {
  position: absolute;
  top: 0;
  right:0;
}
</style>