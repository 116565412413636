<template>
  <v-card 
    class="mt-5 mb-3 mx-3 pa-6" 
    :color="dialog ? 'grey lighten-3':'white'" 
    rounded
  >
    <v-card-subtitle 
      class="mb-6 pa-2 text-subtitle-2 black--text"
    >
      Please select the condition that best describes this access point's accesibility:
    </v-card-subtitle>
    <DropdownSelector 
      class="mx-4"
      color="deep-orange"
    />
    <v-alert class="mb-2 mx-2 py-3 px-4 text-caption" color="grey lighten-2">
      <v-layout>
        <v-flex align-self-center class="mx-2" shrink>
          <v-icon>fa-info-circle</v-icon>
        </v-flex>
        <v-flex class="ml-4">
          Users can vote <strong>once per 24 hours, per access point</strong>. Votes made within that hour will update your last vote.
        </v-flex>
      </v-layout>
    </v-alert>
    <v-card-actions>
      <!-- <v-spacer /> -->
      <v-btn 
        class="py-3"
        color="deep-orange lighten-1" 
        dark 
        elevation="0" 
        :loading="loading"
        x-small 
        width="100%"
        @click="saveVote"
      >
        {{ err_message != '' ? err_message:'save' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import DropdownSelector from './DropdownSelector.vue';

export default {
  components: { 
    DropdownSelector,
  },
  computed: {
    ...mapState('access_points', {
      selected_condition: state => state.selected_condition,    
      selected_geopost: state => state.selected_geopost,  
    }),
  },
  data() {
    return {
      class_component: "text-subtitle-2 bck--text",
      err_message: '',
      loading: false,
      // selected_condition: "",
    };
  },
  methods: {
    saveVote() {
      this.loading = true
      let data = {
        geopost_id: this.selected_geopost,
        vote: this.selected_condition,
      }
      this.$store.dispatch('access_points/createVote', data)
      .then(() => {
        this.$emit('new-vote')
      })
      .catch(() => {
        this.err_message = 'Retry'
        // console.log(err)
      })
      .finally(() => {
        this.loading = false
      })
    }
  },
  props: ["dialog"],
}
</script>