<template>
  <span>
    <l-control 
      v-show="isLoggedIn && !hide_buttons"
      class="custom-control" 
      position="topright"      
    >
      <v-tooltip left :disabled=is_mobile v-model="help">
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            icon
            @click="toggleAPCTool"
            v-bind="attrs"
            v-on="on"
            @pointerdown="hide_tooltip = false"
            @pointerup="hide_tooltip = true"            
          >
            <v-icon :color="showAPCTool ? 'deep-orange':'icetimeblue'">fa-map-marker-alt</v-icon>
          </v-btn>
        </template>
        <span>Place Access Point</span>
      </v-tooltip>
    </l-control>
    <l-marker 
      draggable
      :icon="icon_active"
      :lat-lng="[currentLatLong.lat,currentLatLong.lng]" 
      ref="apcmarker" 
      v-if="showAPCTool && marker_location"
      v-on:moveend="setCurrentLatLong"
    >
      <l-popup 
        class="py-0 my-0"
        :closeButton="false"
        persistent
      >
        <v-layout class="px-4 py-5" row v-if="showPrompt"> 
          <v-flex class="text-h6">
            Create an <span class="deep-orange--text">Access Point</span> @ {{ currentLatLong.lat.toFixed(4) }}, {{ currentLatLong.lng.toFixed(4) }}?
          </v-flex>
          <v-flex class="mt-2 d-flex justify-center">
            <v-text-field
              class="ml-3 my-4 text-body-2 form-label"
              dense
              hide-details
              label="Location Name (optional)"
              type="text"
              v-model="apc_name"
            />
          </v-flex>
          <v-flex class="mb-2 d-flex justify-center">
            <v-select
              class="mx-2 my-4 text-body-2 form-label"
              dense
              hide-details
              item-value="id"
              item-text="name" 
              :items="access_point_conditions"
              label="Access Point Condition (optional)"
              type="text"
              v-model="apc_condition"
            >
              <template slot="selection" slot-scope="data">
                <v-layout class="mx-3 my-1" row>
                  <v-flex align-self-center justify-center xs3>
                    <v-icon small>{{ getIconName(data.item.id) }}</v-icon>
                  </v-flex>
                  <v-flex align-self-center>
                    {{ data.item.name }}
                  </v-flex>
                </v-layout>
              </template>
              <template slot="item" slot-scope="data">
                <v-layout class="mx-3 my-1" row>
                  <v-flex align-self-center justify-center shrink xs2>
                    <v-icon small>{{ getIconName(data.item.id) }}</v-icon>
                  </v-flex>
                  <v-flex align-self-center>
                    {{ data.item.name }}
                  </v-flex>
                </v-layout>
              </template>
            </v-select>
          </v-flex>
          <v-flex class="d-flex justify-end">
            <v-btn 
              class="ma-1 white--text pa-4" 
              color="grey"
              small
              @click="clickCancel"
            >
              Cancel
            </v-btn>
            <v-btn 
              class="ma-1 white--text pa-4" 
              color="black"
              small
              @click="saveAccessPoint"
            >
              Save
            </v-btn>
          </v-flex>
        </v-layout>
      </l-popup>
    </l-marker>
  </span>
</template>

<script>
import { divIcon } from 'leaflet'
import { LControl,LMarker,LPopup } from 'vue2-leaflet'
import { mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {
      apc_condition: '',
      apc_name: '',
      help: false,
      icon_active: new divIcon({
        className: 'myDivIcon',
        html:'<i class="fa fa-map-marker-alt fa-3x" style="color: #FF5722; text-shadow: -3px -3px 0 #fff, 3px -3px 0 #fff, -3px 3px 0 #fff, 3px 3px 0 #fff, 0 7.3px 0px #fff, 0 -3.5px 0 #fff, 3.5px 0 0 #fff, -3.5px 0 0 #fff;" />',
        // html:'<i class="fa fa-map-marker-alt fa-3x" style="color: #FF5722; -webkit-text-stroke: 5px #fff; paint-order: stroke;" />',
        // html:'<span class="fa-stack fa-2x"><i class="fa fa-map-marker-alt fa-stack-2x" style="color: #FF5722; -webkit-text-stroke: 5px transparent;" /><i class="fa fa-map-marker-alt fa-stack-1x" style="color: transparent; -webkit-text-stroke: 5px #fff;" /></span>',
        iconSize: [20, 20],
        popupAnchor: [4,-25]
      }),
      showForm: false,
      showPrompt: true,
      showAPCTool: false,
      currentLatLong: null,
    }
  },
  components: {
    LControl,
    LMarker,
    LPopup,
  },
  computed: {
    ...mapState('access_points', {
      access_point_conditions: state => state.access_point_conditions,
    }),
    ...mapGetters('user', { 
      user: state => state.user,
      isAdmin: 'isAdmin',
      isLoggedIn: 'isLoggedIn',
      isProUser: 'isProUser',
    }),
    ...mapState('user', {
      user: state => state.user,
      hide_buttons: state => state.hide_buttons,
    }),
    ...mapState('map', {
      hide_buttons: state => state.hide_buttons,
      showHelp: state => state.showHelp,
    }),    
    is_mobile() {
      return this.$vuetify.breakpoint.xs ? this.showHelp ? false : true : false
    }         
  },
  created() {
    this.$store.dispatch('access_points/getAccessPointConditions')
  },
  watch: {
    marker_location(){
      // console.log("marker Locaiton")
      this.currentLatLong = this.marker_location
    },
    showHelp() {
      this.help = this.showHelp
    },    
  },
  methods: {
    setCurrentLatLong() {
      this.currentLatLong = this.$refs.apcmarker.mapObject.getLatLng()
      // this.marker_location = this.currentLatLong
      // console.log('there is data ' + this.currentLatLong)
    },
    clickCancel() {
      if (this.$refs.apcmarker) {
        this.$refs.apcmarker.mapObject.closePopup() 
      }
      this.showPrompt = true
      this.showForm = false
      this.apc_name = ''
      this.apc_condition = ''
    },
    getIconName(id) {
      let iconname = ''
      switch(id) {
        case '1':
          iconname = 'fa-ban'
          break
        case '2':
          iconname = 'fa-walking'
          break
        case '3':
          iconname = 'mdi-atv'
          break
        case '4':
          iconname = 'mdi-snowmobile'
          break
        case '5':
          iconname = 'fa-car-side'
          break
        case '6':
          iconname = 'fa-truck-monster'
          break
      }
      return iconname
    },
    saveAccessPoint() {
      let apcItem = {
        lat: this.currentLatLong.lat,
        lng: this.currentLatLong.lng,
        name: this.apc_name,
        initial_vote: this.apc_condition,
      }

      this.$store.dispatch('access_points/recordAccessPoint', apcItem)
      .then(() => {
        this.$emit('apc-updated')
      })      
      .catch(() => {})
      .finally(() => {
        this.toggleAPCTool()
      })
    },
    toggleAPCTool() {
      this.showAPCTool = !this.showAPCTool
      this.clickCancel()

      if(this.showAPCTool) {
        this.$emit('apc-toggled')
      } else {
        this.$emit('apc-untoggled')
      }
    },
  },
  mounted() {
    this.clickCancel()
  },
  props: [
    'marker_location'
  ]
}
</script>

<style scoped>
>>>.v-label {
  font-size: 14px;
}

>>>.myDivIcon {
  line-height: 20px;
  text-align: center;
}
</style>