<template>
    <v-container>
        <v-layout row>
            <v-flex xs12 sm4 offset-sm4>
                <v-card v-if="complete" class="pa-4 ma-5">
                    <v-card-title>Password Reset!</v-card-title>
                    <v-card-subtitle>
                        Your password has been reset. Please login to Ice Time with your new password.
                    </v-card-subtitle>      
                    <v-layout row>
                        <v-flex xs12>
                            <v-card-actions class="ma-2">
                                <v-btn block href="/login">
                                    Continue to Login
                                </v-btn>
                            </v-card-actions>
                        </v-flex>
                    </v-layout>                     
                </v-card>    

                <v-card v-else-if="verified" class="pa-4 ma-5">
                    <v-card-title>Reset Your Password?</v-card-title>
                    <v-card-subtitle>
                        Please enter a new password for {{ email }}.
                    </v-card-subtitle>   
                    <v-container>
                        <v-form
                            lazy-validation
                            ref="password_form"
                        >
                            <v-text-field 
                                v-model="password_new"
                                :append-icon="show_new ? 'mdi-eye' : 'mdi-eye-off'"
                                label="New Password" 
                                required
                                hint="Password length must be at least 10 characters"
                                :rules="[notNullPassword,tenOrGreater]"
                                :type="show_new ? 'text' : 'password'"
                                validate-on-blur
                                @click:append="show_new = !show_new"
                            />
                            <v-text-field 
                                v-model="password_confirm"
                                :append-icon="show_repeat ? 'mdi-eye' : 'mdi-eye-off'"
                                id="confirmPassword" 
                                label="Confirm New Password" 
                                :rules="[comparePasswords]"
                                validate-on-blur
                                :type="show_repeat ? 'text' : 'password'"
                                @click:append="show_repeat = !show_repeat"
                            />         
                        </v-form>
                        <v-layout row>
                            <v-flex xs12>
                                <v-card-actions class="ma-2">
                                    <v-btn block @click.prevent="reset_password">
                                        Reset Password
                                    </v-btn>
                                </v-card-actions>
                            </v-flex>
                        </v-layout>
                        <v-alert v-if="message" class="ma-4" type="info">{{ this.message }}</v-alert>  
                    </v-container>                                
                </v-card>  
                <v-card v-else class="pa-4 ma-5">
                    <v-card-title>Invalid Link</v-card-title>
                    <v-card-subtitle>
                        The link provided is either invalid or expired.
                    </v-card-subtitle>    
                    <v-container>
                        <v-layout row>
                            <v-flex xs12>
                                <v-card-actions class="ma-2">
                                    <v-btn block href="/">
                                        Return To Main
                                    </v-btn>
                                </v-card-actions>
                            </v-flex>
                        </v-layout>  
                    </v-container>                     
                </v-card>                  
            </v-flex>
        <Footer />
      </v-layout>
    </v-container>
  </template>

<script>
// @ is an alias to /src
import Footer from '../components/Footer.vue'

export default {
    data() {
        return {
            message: '',
            show_new: false,
            show_repeat: false,
            password_confirm: '',
            password_new: '',
            email: '',
            verified: false,
            complete: false,
        }
    },
    computed: {
        comparePasswords() {
            return () => (this.password_new === this.password_confirm) || 'Passwords do not match.'
        },
        notNullPassword() {
            return () => this.password_new != "" || "This field is required."
        },
        tenOrGreater() {
            return () => this.password_new.length > 9 || "New password needs to be 10 or more characters."
        },
        includeSpecialChar() {
            return () => !this.password.match(/^[a-z0-9]+$/i) || "Password needs at least 1 special character: ~`!@#$%^&*()-_+={}[]|\\;:\"<>,./?"
        },        
    },    
    components: {
        Footer
    },
    methods: {

        reset_password() {
            if (this.$refs.password_form.validate()) {
                let data = {
                    email: this.email,
                    new: this.password_new,
                    userkey: this.userkey
                }
                this.$store.dispatch('resetPassword', data)
                .then(() => {
                    this.complete = true
                })
                .catch(() => {
                    this.complete = false
                })        
            }
        }
    },
    mounted() {        
        if (this.userkey) {
            let data = {
                userkey: this.userkey
            }
            this.$store.dispatch('verifyKey', data)
            .then(resp => {
                this.email = resp.data.email
                this.verified = true
            })
            .catch(() => {
                this.verified = false
            })     
        }

    },
    props: ['userkey']
}
</script>  