<template>
  <span>
    <l-control 
      class="custom-control"
      position="topright" 
    >
      <v-select
        @change="footprintChanged"
        :disabled="lockMapOn"
        :hint="footprints_hint"
        :items="footprints"
        :loading="(!selected && footprints.length > 0) || footprints.length == 0"
        :value="selected"
        class="pa-0 text-subtitle-1"
        color="deep-orange"
        dense
        flat
        hide-details="auto"
        item-color="deep-orange"
        item-text="name"
        item-value="id"
        prepend-inner-icon="mdi-foot-print"
        persistent-hint
        return-object
        solo
      />
    </l-control>
  </span>
</template>

<script>
import { LControl, } from 'vue2-leaflet'
import { mapState } from 'vuex'

export default {
  components: {
    LControl,
  },
  computed: {
    ...mapState('demo', {
      current_footprint: state => state.current_footprint,
      footprints: state => state.footprints,
      lockMapOn: state => state.lockMapOn,
      visibleLayer: state => state.visibleLayer,
    }),
    footprints_hint() {
      return this.visibleLayer && this.visibleLayer.bounds ? '':"Region data is not avialable at this time."
    },
    selected() {
      return this.current_footprint ? this.current_footprint.id:null
    }
  },
  data() {
    return {
    }
  },
  methods: {
    footprintChanged(value) {
      this.$store.dispatch('demo/setCurrentFootprint', value)
      this.$emit('footprint-changed')
    },
  },
}
</script>

<style scoped>
>>>.custom-control-2:hover {
  transition-duration: 200ms;
  opacity: 1;
}

>>>.mdi-foot-print {
  color: #FF5722;
  padding-right: 8px;
}
</style>