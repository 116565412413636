<template>
  <v-container :key="remount">
    <v-alert v-if="showLoading" type="info">Loading...</v-alert>
    <v-alert v-if="showNoReports" type="info">No reports found!</v-alert>
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="data_table_items"
      item-key="id"
      :search="search"
      show-expand
      single-expand
      sort-by="date"
      sort-desc
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Reports Table</v-toolbar-title>
          <v-divider class="mx-4" inset vertical/>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            class="px-2"
            hide-details
            label="Search"
            single-line
          />
          <v-divider class="mx-4" inset vertical />
          <v-switch 
            v-model="data_table_switch"
            color="deep-orange"
            class="pt-5 px-2"
            label="Demo Reports"
          />
          
          <v-dialog v-model="dialog" max-width="800">
            <v-card class="pa-5">
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container class="pb-0">
                  <v-row>
                    <v-col cols="12" xs="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.region_name"
                        label="Region"
                        hide-details="true"
                        disabled
                        dense
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-row>
                        <v-col cols="12" xs="12" sm="6">
                          <v-text-field
                            v-model="editedItem.satelite"
                            label="Satelite"
                            hide-details="true"
                            disabled
                            outlined
                            dense
                          /> 
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                          <v-text-field
                            v-model="editedItem.image_id"
                            label="Image ID"
                            hide-details="true"
                            disabled
                            outlined
                            dense
                          /> 
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col>
                      <v-row>
                        <v-col cols="12" xs="12" sm="6">
                          <v-text-field
                            v-model="editedItem.relative_orbit"
                            label="Relative Orbit"
                            hide-details="true"
                            disabled
                            outlined
                            dense
                          /> 
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                          <v-text-field
                            v-model="editedItem.latitude"
                            label="Latitude"
                            hide-details="true"
                            disabled
                            outlined
                            dense
                          /> 
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" xs="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.date"
                        label="Date"
                        hide-details="true"
                        disabled
                        outlined
                        dense
                      />
                    </v-col>
                     <v-col cols="12" xs="12" sm="6" md="6">
                      <v-select
                        v-model="selectedStatus"
                        :items="reportStatuses"
                        :disabled="editedItem.status == 3"
                        label="Report Status"
                        hide-details="true"
                        outlined
                        dense
                        read-only
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.title"
                        label="Title"
                        hide-details="auto"
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        v-model="editedItem.ras_description"
                        auto-grow
                        counter="255"
                        hide-details="auto"
                        label="RAS Description (for unsubscribed users)"
                        outlined
                        rows="3"
                      />
                      </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        v-model="editedItem.description"
                        auto-grow
                        counter="65535"
                        hide-details="auto"
                        label="Description (for subscribed users)"
                        outlined
                        rows="5"
                      />
                      </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-if="editedItem.overlay_geojson"
                        v-model="editedItem.overlay_geojson"
                        label="Pressure Ridge Vector Layer (optional)"
                        hint="Click the 'clear' icon to remove or change."
                        persistent-hint
                        outlined
                        readonly
                        clearable
                      />
                      <v-select
                        v-else
                        v-model="selected_zip"
                        :items="uploads_zip"
                        item-text="filename"
                        hide-details="auto"
                        truncate-length="32"
                        label="Pressure Ridge Vector Layer (optional)"
                        return-object
                        outlined
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-alert v-if="infoMessage" type="info">
                {{ infoMessage }}
              </v-alert>
              <v-card-actions class="pt-0">
                <v-spacer />
                <v-switch v-model="editedItem.is_demo" :input-value="parseInt(editedItem.is_demo)" label="Demo" class="pr-2" inset />
                <v-divider class="mx-4" inset vertical />
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click.prevent="update" :loading="saveLoad">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row class="xs12 pa-6">
            <v-col cols="12" sm="6" md="4">
              <v-img 
                :src="$apiEndpointUrl + 'layerthumb/' + item.layer_id + '/thumb.png'" 
                height="100%"
                max-width="100%"
                max-height="350"
              />
            </v-col>
            <v-col cols="12" sm="6" md="8">
              <v-chip v-if="data_table_switch" small class="mr-2 my-2 white--text" color="deep-orange">{{ item.region_name }}</v-chip> 
              <v-chip v-else small class="mr-2 my-2" color="primary">{{ item.region_name }}</v-chip>
              <v-chip small class="mr-2 my-2" color="grey lighten-1">{{ item.footprint_name }}</v-chip> 
              <v-chip small class="mr-2 my-2">{{ item.date }}</v-chip>
              <p class="text-h5 py-2">{{ item.title ? item.title:"[ This report has no title ]" }}</p>
              <p class="text-body-1" style="white-space: pre-line">{{ item.description ? item.description:"[ This report has no description ]" }}</p>
            </v-col>
          </v-row>
        </td>
      </template>

      <template v-slot:item.status="{ item }">
        <span class="d-none d-lg-flex">
          <v-chip 
            v-if="item.status == 1"
            class="ma-2 caption"
            :color="data_table_switch ? 'deep-orange accent-1':'green'"
            text-color="white"
            small
          >
            Published
          </v-chip>
          <v-chip 
            v-else-if="item.status == 2"
            class="ma-2 caption"
            color="yellow"
            text-color="black"
            small
          >
            Archived
          </v-chip>
          <v-chip 
            v-else-if="item.status == 3"
            class="ma-2 caption"
            color="red"
            text-color="white"
            small
          >
            Deleted
          </v-chip>
          <v-chip
            v-else
            class="ma-2 caption"
            small
          >
            Unpublished
          </v-chip>
        </span>
        <span class="d-md-flex d-lg-none ma-2">
          <v-icon 
            v-if="item.status == 1"
            :color="data_table_switch ? 'deep-orange accent-1':'green'"
          >
            mdi-circle-slice-8
          </v-icon>
          <v-icon 
            v-else-if="item.status == 2"
            color="yellow"
          >
            mdi-minus-circle-outline
          </v-icon>
          <v-icon 
            v-else-if="item.status == 3"
            color="red"
          >
            mdi-close-circle
          </v-icon>
          <v-icon
            v-else
            color="gray"
          >
            mdi-circle-outline
          </v-icon>
        </span>
      </template>

      <template v-slot:item.vector_layer="{ item }">
        <v-icon 
          v-if="item.type == 2" 
          class="ma-2"
          color="black"
        >
          mdi-check-circle
        </v-icon>
        <v-icon
          v-else 
          class="ma-2"
          color="grey lighten-1"
        >
          mdi-minus-circle
        </v-icon>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          class="mr-2"
          @click="clickDeleteIcon(item)"
        >
          mdi-delete
        </v-icon>
        <v-icon
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          class="mr-2"
          @click="goMap(item)"
        >
          mdi-exit-to-app
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogConfirm" max-width="500px">
      <v-card>
        <v-card-title>Please confirm!</v-card-title>
        <v-card-text>
          <span class="font-weight-bold">You are about to <span class="red--text">delete</span> the image data for {{ this.deleteItem.region_name }} on {{ this.deleteItem.date }}. This cannot be undone!</span>
        </v-card-text>
        <v-card-text>
          Report and layer data will still exist, but images will no longer be available.
        </v-card-text>
        <v-alert v-if="deleteInfo" type="info" class="mx-2">
          {{ deleteInfo }}
        </v-alert>
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey" @click.prevent="dialogConfirm = false" text>Cancel</v-btn>
          <v-btn color="primary" @click.prevent="clickDelete" :loading="deleteLoad" text>Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import {mapState, } from 'vuex';

  export default {
    data: () => ({
      data_table_switch: false,
      defaultItem: {
        date: '',
        ras_description: '',
        description: '',
        id: '',
        image_id: '',
        is_demo: 0,
        layer_id: '',
        region_name: '',
        satelite: '',
        status: 0,
        title: '',
        vector_layer: '',
      },
      deleteInfo: '',
      deleteItem: '',
      deleteLoad: false,
      dialog: false,
      dialogConfirm: false,
      editedIndex: -1,
      editedItem: {
        date: '',
        ras_description: '',
        description: '',
        id: '',
        image_id: '',
        is_demo: 0,
        layer_id: '',
        region_name: '',
        satelite: '',
        status: 0,
        title: '',
        vector_layer: '',
      },
      headers: [
        { text: 'Status', value: 'status' },
        { text: 'Date', value: 'date', align: 'start' },
        { text: "Satelite", value: "satelite" },
        { text: 'RO', value: 'relative_orbit' },
        { text: 'Latitude', value: 'latitude' },
        { text: 'Region', value: 'region_name' },
        { text: 'Vector', value: 'vector_layer'},
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Preview', value: 'data-table-expand' },
      ],
      infoMessage: '',      
      reportStatuses: [
        { text: 'Unpublished', value: 0 },
        { text: 'Published', value: 1 },
        { text: 'Archived', value: 2 },
        { text: 'Deleted', value: 3, disabled: true },
      ],
      remount: 0,
      saveLoad: false,
      search: '',
      selected_zip: '',
      selectedStatus: '',
      showLoading: true,
      // showNoReports: false,
      uploads_zip: [],
    }),
    computed: {
      ...mapState('admin', {
        reports: state => state.reports
      }),
      // ...mapState(['demo_reports']),
      data_table_items() {
        if (this.data_table_switch) {
          return this.reports.filter(function(el) {
            return el.is_demo == 1
          })
        } else {
          return this.reports.filter(function(el) {
            return el.is_demo == 0
          })
        }
      },
      formTitle () {
        return this.editedIndex === -1 ? 'New Report' : 'Edit Report'
      },
      isNewReport() {
        return this.editedIndex === -1 
      },
      showNoReports() {
        return this.reports.length == 0
      }
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
    },
    created () {
      this.showLoading = true
      this.$store.dispatch('admin/getReports')
      .then(() => {
        this.showLoading = false
        // if (this.reports.length == 0) {
        //   this.showNoReports = true
        // }
      })
      .catch(() => {
        this.showLoading = false
      })

      this.$store.dispatch('admin/getUploads')
      .then((resp) => {
        this.uploads_zip = resp.data.filter(function(el) {
          return el.filename.includes('.zip')
        })
      })
    },
    methods: {
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      // clickDataTableSwitch() {
      //   if (this.data_table_switch) {
      //     this.data_table_items =  this.reports.filter(function(el) {
      //       return el.is_demo == 1
      //     })
      //   } else {
      //     this.data_table_items = this.reports.filter(function(el) {
      //       return el.is_demo == 0
      //     })
      //   }
      // },
      clickDelete() {
        if(this.deleteItem) {
          this.deleteLoad = true
          this.$store.dispatch('admin/deleteReport', this.deleteItem.id)
          .then((resp) => {
            if(resp.data != '') {
              // Catches "Invalid request!" responses
              this.deleteLoad = false
              this.deleteInfo = resp.data
              setTimeout(() => {
                this.deleteInfo = ""
                this.dialogConfirm = false
              }, 2000)
            }
          })
          .catch((err) => {
            this.deleteLoad = false
            if(err.response.status == '403') {
              this.$store.dispatch('logout')
              this.$store.dispatch('setDialog', { 
                show: true, 
                message: err.response.data, 
                status: err.response.status })
            } else {
              this.deleteInfo = err.response.data
            }
          })
          .finally(() => {
            if(this.deleteInfo.length == 0) {
              this.deleteLoad = false
              this.deleteInfo = "Delete successful!"
              setTimeout(() => {
                this.deleteInfo = ""
                this.dialogConfirm = false
                this.$store.dispatch('admin/getReports')
                .then(() => {
                  if (this.reports.length == 0) {
                    this.showNoReports = true
                  }
                })
              }, 2000)
            }
          })
        }
      },
       clickDeleteIcon(item) {
        this.deleteItem = item
        this.dialogConfirm = true
        // console.log(item.id)
      },
      editItem (item) {
        this.editedIndex = this.reports.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.editedItem.is_demo = parseInt(this.editedItem.is_demo)
        this.selectedStatus = this.reportStatuses[this.editedItem.status]
        this.dialog = true
      },
      goMap(item) {
        if(this.data_table_switch) {
          this.$router.push('/demo/region/' + item.map_id + "/" + item.layer_id)
        } else {
          this.$router.push('/app/radar/' + item.map_id + "/" + item.layer_id)
        }
      },
      update() {
        if (this.editedIndex > -1) {
          this.saveLoad = true

          let data = {
            id: this.editedItem.id,
            layer_id: this.editedItem.layer_id,
            status: this.selectedStatus.value != null ? this.selectedStatus.value:this.selectedStatus,
            title: this.editedItem.title,
            ras_description: this.editedItem.ras_description,
            description: this.editedItem.description,
            is_demo: this.editedItem.is_demo
          }

          let importDetails = {
            file: this.selected_zip,
            layer_id: this.editedItem.layer_id
          }

          this.$store.dispatch('admin/updateReport', data)
          .then(() => {
            if (!this.editedItem.overlay_geojson && this.selected_zip != '') {
              this.$store.dispatch('admin/postImport', {importDetails})
              .catch((err) => {
                this.infoMessage = err.response.data
              })
            }
          })
          .then(() => {
            this.saveLoad = false
            this.infoMessage = "Report updated!"
          })
          .catch((err) => {
            this.saveLoad = false
            if(err.response.status == '403') {
              this.$store.dispatch('logout')
              this.$store.dispatch('setDialog', { 
                show: true, 
                message: err.response.data, 
                status: err.response.status
              })
            } else if (err.response.status == '504') {
              this.infoMessage = "API connection timed out! If you just published an email, a confirmation email will be sent as soon as the automated email script has completed."
            } else {
              this.infoMessage = err.response.data
            }
          })
          .finally(() => {
            setTimeout(() => {
              this.infoMessage = ""
              this.close()
              this.$store.dispatch('admin/getReports')
              .then(() => {
                this.showLoading = false
                if (this.reports.length == 0) {
                  this.showNoReports = true
                }
              })
            }, 1500)
          })
        }
      },
    },
  }
</script>
