<template>
  <v-container>
    <p class="text-h5 pl-5 pt-8">Frequently Asked Questions</p>
    <v-layout class="px-8 pb-12" cols="12" row>
      <v-text-field 
        class="px-4 pb-4 text-h6"
        v-model="search_string"
        prepend-inner-icon="mdi-file-search"
        placeholder="Search FAQ"
      />
      <v-expansion-panels
        v-model="panel"
        multiple
      >
        <v-expansion-panel 
          v-for="item in filtered_qa" 
          :key="item.index"
        >
          <v-expansion-panel-header class="text-subtitle-2">
            <p class="pa-0 ma-0" v-html="item.q" />
          </v-expansion-panel-header>
          <v-expansion-panel-content class="px-6 pb-6">
            <p class="pa-0 ma-0" v-html="item.a" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="text-subtitle-2">
            How do I interpret Ice Time's status?
          </v-expansion-panel-header>
          <v-expansion-panel-content class="px-12 pb-6">
            Ice Time's status can be found at the top right corner of your device screen. Its unique states are listed below.
            <v-layout class="pt-6" cols="12" xs12 row>
              <v-flex class="pa-3" xs12 sm4 md3>
                <v-chip class="py-4 px-1 mr-6" color="#2f4782" small>
                  <v-btn class="mx-0" color="#2f4782" small><v-icon :color="swStatusList.default.color">{{ swStatusList.default.icon }}</v-icon></v-btn>
                  <span class="white--text text-subtitle-2 pr-4 ml-0 pl-0 d-none d-sm-flex">{{ swStatusList.default.text }}</span>
                </v-chip>
              </v-flex>
              <v-flex class="pa-3" xs12 sm8 md9>
                Ice Time is in a functional state.
              </v-flex>
              <v-flex class="pa-3" xs12 sm4 md3>
                <v-chip class="py-4 px-1 mr-6" color="#2f4782" small>
                  <v-btn class="mx-0 white--text" color="#2f4782" loading small />
                  <span class="white--text text-subtitle-2 pr-4 ml-0 pl-0 d-none d-sm-flex">{{ swStatusList.updatedFound.text }}</span>
                </v-chip>
              </v-flex>
              <v-flex class="pa-3" xs12 sm8 md9>
                An Ice Time update is being downloaded in the background.
              </v-flex>
              <v-flex class="pa-3" xs12 sm4 md3>
                <v-chip class="py-4 px-1 mr-6" color="#2f4782" small>
                  <v-btn class="mx-0" color="#2f4782" small><v-icon :color="swStatusList.updated.color">{{ swStatusList.updated.icon }}</v-icon></v-btn>
                  <span class="white--text text-subtitle-2 pr-4 ml-0 pl-0 d-none d-sm-flex">{{ swStatusList.updated.text }}</span>
                </v-chip>
              </v-flex>
              <v-flex class="pa-3" xs12 sm8 m9>
                Ice Time requires a refresh for general session maintenance or to apply application updates. Please click on the icon (<v-icon>mdi-refresh</v-icon>) so that the refresh occurs at your convenience.
              </v-flex>
              <v-flex class="pa-3" xs12 sm4 md3>
                <v-chip class="py-4 px-1 mr-6" color="#2f4782" small>
                  <v-btn class="mx-0" color="#2f4782" small><v-icon :color="swStatusList.error.color">{{ swStatusList.error.icon }}</v-icon></v-btn>
                  <span class="white--text text-subtitle-2 pr-4 ml-0 pl-0 d-none d-sm-flex">{{ swStatusList.error.text }}</span>
                </v-chip>
              </v-flex>
              <v-flex class="pa-3" xs12 sm8 md9>
                If this error status persists, please contact us at <a v-if="hasSubcriptions" href="mailto:support@icetime.app">support@icetime.app</a><a v-else href="mailto:hello@icetime.app">hello@icetime.app</a>.
              </v-flex>
            </v-layout>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-layout>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {
      search_string: '',
      swStatusList: {
        default: {
          color: 'light-blue accent-1',
          icon: 'mdi-check-circle-outline',
          loading: false,
          text: 'frosty', 
        },
        updatedFound: {
          color: 'yellow',
          icon: 'mdi-refresh',
          loading: true,
          text: 'loading changes', 
        },
        updated: {
          color: 'yellow',
          icon: 'mdi-refresh',
          loading: false,
          text: 'refresh', 
        },
        error: {
          color: 'red lighten-2',
          icon: 'mdi-close-circle-outline',
          loading: false,
          text: 'error', 
        },
      },
      qa: [
        {
          q: "Where do I get Ice Time?",
          a: "There is only one place: <a href='/'>my.icetime.app</a>."
        },
        {
          q: "What should I expect after I subscribe?",
          a: "We will first confirm your subscription by email. Make sure you read the <a href='/fundamentals'>Ice Time Fundamentals</a> on the home screen of the app. Next, use the <a href='/demo/reports'>demo data</a> from previous years to become familiar with the app. When active radar imaging begins in your region, you will receive an email letting you know there is new imagery available. If you are an Android user, you will also get a push notification when the new image and lake ice reports are published."
        },
        {
          q: "Do I need more than one license to use Ice Time on more than one device?",
          a: "No. One license can operate Ice Time on your mobile phone, tablet, or your desktop PC. You just can’t run them at the same time."
        },
        {
          q: "Are there different versions for Android and iOS?",
          a: "No. You are good to go."
        },
        {
          q: "Do I need to update Ice Time for new features, or update again next year when the ice is forming?",
          a: "No. We tend to feature updates and those are pushed to you. If you set your subscription to be reoccurring, there is nothing to do next fall other than open the app up."
        },
        {
          q: "Do you recommend an approach to using Ice Time on your devices?",
          a: "Generally, yes. Take advantage of your desktop monitor and watch your ice cover evolve from the comfort of home using a large monitor. A larger display makes seeing things like ice movements easy. Stay informed just before you travel. Make use of your cellular devices when on the move."
        },
        {
          q: "When I view the Ice Time demo data, I see two types of maps that are colored differently. Why?",
          a: "Our mapping recognizes that the hazards change during the ice period. We use one method of mapping for early and late season ice and another during the cold of winter when the ice is not expected to be mobile. This simplifies viewing the maps and the hazards they show."
        },
        {
          q: "Do the GPS functions work when used on your desktop?",
          a: "No."
        },
        {
          q: "What does near real-time imaging mean?",
          a: "Typically, we distribute imagery within 1-2 days of receiving it. On occasion it may be a bit longer due to national holidays or unavoidable circumstance."
        },
        {
          q: "When other users map the ice thickness at a spot, is that updated on my Ice Time automatically?",
          a: "Yes. When users map features that are not declared as private, they immediately upload to all other users. As ice conditions often change (e.g. ice thickness) some of the mapping symbols will be automatically removed. Some can persist for up to 7 days. The longer one of these symbols stays on the map the more transparent it becomes. This makes it easy to see which symbol is current. Other symbols, like pressure ridges, are persistent hazard features. These symbols will remain for the entire ice period. The appearance of the permanent symbols is opaque and never changes."
        },
        {
          q: "Can I mark information on the maps and keep it private?",
          a: "Yes. You can map symbols like waypoints and your fishing spots, just as examples. We also add the date and time."
        },
        {
          q: "Are the small lakes that fall in each of the image areas included in the radar images?",
          a: "Yes. Radar is very sensitive to water. We map anything you can get a boat into, so long as it’s a lake."
        },
        {
          q: "What is the resolution of the radar images?",
          a: "Radar resolution is quite different than optical data resolution.  If you inquire about the size of a pixel, it is about 10 meters. Radar satellites can “see” at a sub-pixel level."
        },
        {
          q: "Are these raw radar images?",
          a: "No. We put each radar image through extensive processing that results in a high standard that is scientific publication quality. We then map ice features using algorithms based on our on-ice research that started in 2017."
        },
        {
          q: "Can you provide a schedule of dates when the satellite data will be made available?",
          a: "Unfortunately, no. We cannot provide assurances for schedules we are not in control of. The satellite data vendors do experience technical issues, too, albeit rarely. The frequency of image updates depends on the geographic region, the time of year, and the size of the Ice Time community in that region. The interval ranges 3 – 12 days. If your last image was more than 12 days, you will have heard from us about that. Sometimes, a satellite is subject to a collision avoidance maneuver. This stops imaging temporarily. This happens but is infrequent."
        },
        {
          q: "How big do the lake ice features, like rubble ice, have to be before the radar can detect them?",
          a: "We’ve done detailed studies with drones at the same time a radar satellite passed overhead to confirm this. We can image pressure ridges and rubble fields very precisely even when they are only 10 cm above ice level. We have identified pressure ridges before they uplift through the snowpack."
        },
        {
          q: "What is an open water lead?",
          a: "An open water crack in the lake that is sufficiently large to be considered navigable by boat."
        },
        {
          q: "Ice Time is not currently offered where I ride and fish. How do I get this app going in my region?",
          a: "We let the users decide which regions we will open up next. You can cast a vote here: <a href='https://www.icetime.app/near-you' target='_blank'>https://www.icetime.app/near-you</a>."
        },
        {
          q: "Does Ice Time apply to river ice?",
          a: "No. Ice Time is a lake ice application."
        },
      ]
    }
  },
  computed: {
    filtered_qa: function() {
      return this.qa.filter((item) => {
        let lower_q = item.q.toLowerCase()
        let lower_a = item.q.toLowerCase()
        let lower_str = this.search_string.toLowerCase()
        return lower_q.includes(lower_str) || lower_a.includes(lower_str)
      })
    }
  },
  // created: function () {
  //   this.$http.interceptors.response.use(undefined, function (err) {
  //     return new Promise(function () {
  //       if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
  //         this.$store.dispatch('logout')
  //       }
  //       throw err;
  //     })
  //   })
  // },
}
</script>

<style scoped>
>>>.v-input__prepend-inner, >>>.v-text-field__slot {
  padding: 10px;
}
</style>