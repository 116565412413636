<template>
    <v-container>
      <AdminStats />
    </v-container>
</template>
  
<script>
    import AdminStats from '../components/AdminTrainedStats.vue'
  
    export default {
      components: {
        // AdminHeader,
        AdminStats, 
      },
      data() {
        return {}
      },
      mounted() {
        // this.$store.dispatch('setMode', 'admin')
        this.$store.dispatch('setTab', 'users')
      }
    }
</script>