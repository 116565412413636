<template>
  <!-- <v-container
    class="pt-0 ma-0"
    style="background-color: #000; max-width: none!important;"
  > -->
  <!-- <v-container
    class="pt-0 ma-0"
  > -->
    <!-- <template v-slot:extension> -->
      <v-tabs
        :align-with-title="!$vuetify.breakpoint.xs"
        :grow="$vuetify.breakpoint.xs"
        slider-color="blue"
        slider-size="3"
        center-active
        show-arrows
      >
        <v-tab key="profile" to="profile">
          <v-icon v-if="$vuetify.breakpoint.xs">fa-user</v-icon>
          <span v-else>Profile</span>
        </v-tab>
        <v-tab key="card" to="card">
          <v-icon v-if="$vuetify.breakpoint.xs">fa-address-card</v-icon>
          <span v-else>User Card</span>
        </v-tab>
        <v-tab key="preferences" to="preferences">
          <v-icon v-if="$vuetify.breakpoint.xs">fa-cogs</v-icon>
          <span v-else>Preferences</span>
        </v-tab>
        <v-tab key="billing" to="billing">
          <v-icon v-if="$vuetify.breakpoint.xs">fa-file-invoice-dollar</v-icon>
          <span v-else>Billing</span>
        </v-tab>
        <v-tab key="password" to="password">
          <v-icon v-if="$vuetify.breakpoint.xs">fa-lock</v-icon>
          <span v-else>Change Password</span>
        </v-tab>
      </v-tabs>
    <!-- </template> -->
  <!-- </v-container> -->
</template>

<script>
// import { mapState } from 'vuex'

export default {
  // computed: {
  //   ...mapState(['tab'])
  // },
  data() {
    return {
    }
  },
}
</script>