<template>
  <v-btn
    :color="color"
    :disabled="disabled"
    :to="route"
    :width="$vuetify.breakpoint.xs ? '100%' : 'auto'"
    class="mx-1 my-2 py-8 px-5 white--text"
    rounded
  >
    {{ text }}
    <v-icon v-if="icon" class="ml-4" x-large>{{ icon }}</v-icon>
  </v-btn>
</template>

<script>

export default {
  data() {
    return {
    }
  },
  props: [ 
    'color',
    'disabled',
    'icon',
    'text',
    'route',
  ]
}
</script>
