<template>
  <v-row class="my-0 px-0" width="100%">
    <v-flex 
      class="pt-0 px-3 py-5"
      v-for="advisory in advisories"
      xs12 sm12 md6
      :key="advisory.id"
    >
      <v-fade-transition mode="out-in" :hide-on-leave="true">
        <v-card 
          class="card-outter pa-4 fill-height"
          v-show="show"
          width="100%"
          :loading="entry"
        >
          <v-card-title class="text-subtitle-1 font-weight-medium">
            {{ advisory.footprint_name }}
          </v-card-title>
          <v-card-subtitle class="mx-2">
            <v-row>
              <v-chip 
                class="ma-1 px-2 white--text" 
                color="grey" 
                label 
                small
              >
                <div><v-icon>mdi-map</v-icon> {{ advisory.region_name }}</div>
              </v-chip>
              <v-chip 
                class="ma-1 px-2" 
                label 
                small
              >
                <div v-if="$vuetify.breakpoint.smAndDown"><v-icon>mdi-update</v-icon> {{ advisory.last_update }} UTC</div>
                <div v-else>Updated: {{ advisory.last_update }} UTC</div>
              </v-chip>
            </v-row>
            <AltAttributeChips v-if="advisory.advisories" :attributes="uniqueHazards(advisory.advisories.split(','))" /> 
          </v-card-subtitle>
          <v-card-text class="text-body-1">
            {{ advisory.title }}
          </v-card-text>
          <v-card-text class="text-body-2">
            {{ advisory.description }}
          </v-card-text>
        </v-card>
      </v-fade-transition>
    </v-flex>
  </v-row>
</template>

<script>
import AltAttributeChips from './AltAttributeChips.vue';
import { mapState } from 'vuex'

export default {
  components: { 
    AltAttributeChips 
  },
  computed: {
    ...mapState('ras', {
      entry: state => state.entry,
    })
  },
  methods: {
    uniqueHazards(hazardList) {
      return hazardList.filter(function(item, pos, self) { return self.indexOf(item) == pos; })
    }
  },
  data() {
    return {
      show: true,
    }
  },
  props: [
      "advisories",
  ],
  created() {
    this.$store.dispatch('map/getHazardTypes')
  },
  
}
</script>

<style scoped>
>>>.card-outter {
  position: relative;
  padding-bottom: 50px;
}
>>>.card-actions {
  position: absolute;
  bottom: 0;
  right:0;
}
</style>