<template>
    <l-feature-group>
      <v-marker-cluster>
        <l-marker 
          v-for="item in fishing_spots" 
          :key="item.id" 
          :lat-lng="[item.lat, item.lng]"   
          @click="setSelectedFishingSpot(item.id)"   
        >
          <l-icon
            :iconSize="[50,50]"
          >
            <v-icon 
              id="icontext"
              large
              :color="item.id == selected_fishing_spot ? '#FF5722':'black'"
              :style="item.id == selected_fishing_spot ? 'text-shadow: -3px -3px 0 #fff, 3px -3px 0 #fff, -3px 3px 0 #fff, 3px 3px 0 #fff, 0 3.5px 0px #fff, 0 -3.5px 0 #fff, 3.5px 0 0 #fff, -3.5px 0 0 #fff;':''"
            >fa-fish</v-icon>
            <div style="font-size:9px; color:black; margin-left: -20%; text-align: center; font-weight: bold;">{{ item.name }}</div>
          </l-icon>
        </l-marker>
      </v-marker-cluster> 
    </l-feature-group>
</template>
  
<script>
  import { LFeatureGroup, LMarker } from 'vue2-leaflet';
  import { LIcon } from 'vue2-leaflet';
  import { icon } from 'leaflet';
  import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
  import { mapState } from 'vuex'
  
  export default {
      components: {
          LFeatureGroup,
          LMarker,
          LIcon,
          'v-marker-cluster': Vue2LeafletMarkerCluster
      },
      computed: {
        ...mapState('spot_mapper', {
          selected_fishing_spot: state => state.selected_fishing_spot,
    })
      },
      created() {
        
      },
      data() {
          return {
            fishMarker: new icon ({
            iconUrl: "/img/fish-solid.svg",
            iconSize: [40,40],
            iconAnchor: [32,64]
          })
        }
      },
      methods: {        
          // deleteFishingSpot(id) {
          //     let spotId = {
          //         id: id
          //     }
          //     this.$store.dispatch('spot_mapper/deleteFishingSpot', spotId).then(() => {
          //         this.$emit('deleteFishingSpot')            
          //     })
          //     .catch(() => {})
          //     .finally(() => {this.$refs.map.closePopup()})
          // },
        setSelectedFishingSpot(spotId) {
          this.$store.dispatch('spot_mapper/setSelectedFishingSpot', spotId)
          this.$store.dispatch('access_points/setSelectedAccessPoint', null)
          this.$store.dispatch('access_points/setShowMarkerDialog', false)
          this.$store.dispatch('spot_mapper/setSelectedGeopost', null)
          .then(() => {
            this.$store.dispatch('spot_mapper/getGroupCatches', spotId)
            // console.log(this.$store.dispatch('spot_mapper/getGroupCatches', spotId))
          })
        }
      },
      mounted() {
        this.$store.dispatch('spot_mapper/setSelectedFishingSpot', null)
      },
      props: ['fishing_spots']
  }
</script>
  
<style>
  @import "~leaflet.markercluster/dist/MarkerCluster.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style>