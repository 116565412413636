<template>
  <span>
    <l-control 
      class="custom-control gps-control pa-0"
      position="topleft"
      style="padding: 0.15em 0.15em 0.15em 0.15em !important;"
    >
      <span v-if="gpsMode > 0 && gps && !$vuetify.breakpoint.xs" class="text-body-2 ml-2">
        {{ gps.lat }}, {{ gps.lng }}
      </span>
      <v-tooltip right :disabled="false">
        <template v-slot:activator="{ on }">
          <div v-on="on" class="d-inline-block">
            <v-btn
              class="pa-0 ma-0"
              :disabled="isDisabled"
              :loading="gpsMode > 0 && !gps"
              icon
              small
              @click.prevent="toggle"
            >
              <v-icon :color="gpsColor">mdi-crosshairs-gps</v-icon>
            </v-btn>
          </div>
        </template>
        <span v-if="gpsMode == 0">
          Find Your Location{{ isDisabled ? ' (Unavailable)':'' }}
        </span>
        <span v-else-if="gpsMode == 1">Pan to Your Location</span>
        <span v-else-if="gpsMode == 2">Close Location Tool</span>
      </v-tooltip>
    </l-control>
    <l-marker v-if="gps && gpsMode > 0" :lat-lng="gps" :zIndexOffset="100">
      <v-icon icon-url="fa-pin"/>
    </l-marker>
  </span>
</template>

<script>
import { mapState } from 'vuex'
import { LControl, LMarker } from 'vue2-leaflet'

export default {
  components: {
    LControl,
    LMarker,
  },
  computed: {
    ...mapState('map', {
      gpsMode: state => state.gpsMode,
      lockMapOn: state => state.lockMapOn,
    }),
    isDisabled() {
      return this.lockMapOn || this.disabled
    }
  },
  data() {
    return {
      gpsColor: "icetimeblue",
      offset: 0,
    }
  },
  methods: {
    toggle() {
      if(this.gpsMode == 0) {
        this.$store.dispatch('map/setGPSMode', 1)
        this.gpsColor = 'blue'
      } else if(this.gpsMode == 1) {
        this.$store.dispatch('map/setGPSMode', 2)
        this.gpsColor = 'green'
      } else if(this.gpsMode == 2) {
        this.$store.dispatch('map/setGPSMode', 0)
        this.gpsColor = 'icetimeblue'
      }

      this.$emit('gps-mode-changed')
    },
  },
  props: ['disabled','gps']
}
</script>

<style scoped>
>>>.custom-control-2:hover {
  transition-duration: 200ms;
  opacity: 1;
}

>>>.v-dialog {
  position: absolute;
  bottom: 0;
  right: 0;
}

>>>.card-outter {
  position: relative;
  padding-bottom: 1.5em;
}

>>>.card-actions {
  position: absolute;
  top: 0;
  right:0;
}
</style>