<template>
    <v-container >
        <TopNav ></TopNav>
        <EndedCompList ></EndedCompList>
    </v-container>
</template>

<script>
// import
import { mapGetters,  } from 'vuex'
import EndedCompList from '../components/EndedCompList';
import TopNav from '../components/TopNav';


export default{
components: {
    EndedCompList,
    TopNav,
},
computed: {
    ...mapGetters('user', {
      isLoggedIn: 'isLoggedIn',
    }),    

},
data() {
    return{

    }

},
methods: {


},
mounted() {
    this.$store.dispatch('setMode', 'app')
    this.$store.dispatch('setTab', 'leaderboard')
    this.$store.dispatch('checkToken')
    // console.log('view')
    // TODO: add check for subscriber status
}
}

</script>