<template>
  <v-container>
    <v-alert v-if="showLoading" type="info">Loading...</v-alert>
    <CreateUpdateAdvisory 
      :data="editedItem" 
      :show="toggle_advisory_create" 
      @create-done="create_done"
    />
    <v-data-table
      :headers="headers"
      :items="manual_advisories"
      :search="search"
      item-key="id"
      sort-by="date"
      sort-desc
      single-expand
      class="elevation-1"
      show-expand
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Manual Advisories</v-toolbar-title>
          <v-divider class="mx-4" inset vertical/>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            class="px-2"
            color="amber"
            hide-details
            label="Search"
            single-line
          />
          <v-divider class="mx-4" inset vertical />
          <v-btn color="amber" rounded @click="toggle_create">
            <v-icon small>fa-plus</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp" class="ml-2">Create Advisory</span>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row class="xs12 pa-6">
            <v-col cols="12">
              <v-chip label small class="ml-1 mr-2 my-2" color="primary">{{ item.region_name }}</v-chip>
              <v-chip label small class="mr-2 my-2" color="grey lighten-1">{{ item.footprint_name }}</v-chip> 
              <v-chip label small class="mr-2 my-2">{{ item.date }}</v-chip>
              <AltAttributeChips :attributes="item.hazards.split(',')" class="ml-0 my-0" />
              <p class="text-h5 py-2">{{ item.title ? item.title:"[ This report has no title ]" }}</p>
              <p class="text-body-1" style="white-space: pre-line">{{ item.description ? item.description:"[ This report has no description ]" }}</p>
            </v-col>
          </v-row>
        </td>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          class="mr-2"
          @click="clickDeleteIcon(item)"
        >
          mdi-delete
        </v-icon>
        <v-icon
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogConfirm" max-width="500px">
      <v-card>
        <v-card-title>Please confirm!</v-card-title>
        <v-card-text>
          <span class="font-weight-bold">You are about to <span class="red--text">delete</span> a advisory for {{ this.deleteItem.region_name }} on {{ this.deleteItem.date }}.</span> This cannot be undone!
        </v-card-text>
        <v-alert v-if="deleteInfo" type="info" class="mx-2">
          {{ deleteInfo }}
        </v-alert>
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey" @click.prevent="dialogConfirm = false" text>Cancel</v-btn>
          <v-btn color="primary" @click.prevent="clickDelete" :loading="deleteLoad" text>Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import CreateUpdateAdvisory from './CreateUpdateAdvisory.vue'
import AltAttributeChips from './AltAttributeChips.vue';

export default {
  data: () => ({
    blankItem: {
      id: null,
      date: '',
      title: '',
      ras_description: '',
      description: '',
      region_id: '',
      footprint_id: '',
    },
    deleteInfo: '',
    deleteItem: '',
    deleteLoad: false,
    dialog: false,
    dialogConfirm: false,
    editedIndex: -1,
    editedItem: {
      id: null,
      date: '',
      title: '',
      ras_description: '',
      description: '',
      region_id: '',
      footprint_id: '',
    },
    headers: [
      { text: "Date", value: "date" },
      { text: 'Region', value: 'region_name' },
      { text: 'Footprint', value: 'footprint_name', align: 'start' },
      { text: 'Actions', value: 'actions', sortable: false },
      { text: 'Preview', value: 'data-table-expand' },
      { text: 'Title', value: 'title', align: ' d-none' },
      { text: 'Description', value: 'description', align: ' d-none' },
      { text: 'Hazards', value: 'hazards', align: ' d-none' }
    ],
    infoMessage: '',      
    saveLoad: false,
    search: '',
    show_create: false,
    showLoading: true,
  }),
  components: {
    CreateUpdateAdvisory,
    AltAttributeChips
},
  computed: {
    ...mapState('ras', {
      manual_advisories: state => state.manual_advisories,
      toggle_advisory_create: state => state.toggle_advisory_create
    }),
  },
  created () {
    this.showLoading = true
    this.$store.dispatch('ras/getManualAdvisories')
    .then(() => {
      this.showLoading = false
    })
    .catch(() => {
      this.showLoading = false
    })
  },
  methods: {
    clickDelete() {
      if(this.deleteItem) {
        this.deleteLoad = true
        this.$store.dispatch('ras/deleteManualAdvisory', this.deleteItem.id)
        .then((resp) => {
          if(resp.data != '') {
            // Catches "Invalid request!" responses
            this.deleteLoad = false
            this.deleteInfo = resp.data
            setTimeout(() => {
              this.deleteInfo = ""
              this.dialogConfirm = false
            }, 2000)
          }
        })
        .catch((err) => {
          this.deleteLoad = false
          if(err.response.status == '403') {
            this.$store.dispatch('logout')
            this.$store.dispatch('setDialog', { 
              show: true, 
              message: err.response.data, 
              status: err.response.status })
          } else {
            this.deleteInfo = err.response.data
          }
        })
        .finally(() => {
          if(this.deleteInfo.length == 0) {
            this.deleteLoad = false
            this.deleteInfo = "Delete successful!"
            setTimeout(() => {
              this.deleteInfo = ""
              this.dialogConfirm = false
              this.$store.dispatch('ras/getManualAdvisories')
            }, 2000)
          }
        })
      }
    },
    clickDeleteIcon(item) {
      this.deleteItem = item
      this.dialogConfirm = true
      // console.log(item.id)
    },
    create_done() {
      this.editedItem = this.blankItem
    },
    editItem(item) {
      // this.editedIndex = this.advisories.indexOf(item)
      this.editedItem = Object.assign({}, item) // not just a ref of item
      this.editedItem.hazards = this.editedItem.hazards.split(',')
      this.$store.dispatch('map/getFootprints', {id: item.region_id})
      // this.editedItem.is_demo = parseInt(this.editedItem.is_demo)
      // this.show = true
      this.toggle_create()
    },
    toggle_create() {
      this.$store.dispatch('ras/setAdvisoryCreate', true)
    },
    update() {
      if (this.editedIndex > -1) {
        this.saveLoad = true

        let data = {
          id: this.editedItem.id,
          layer_id: this.editedItem.layer_id,
          status: this.selectedStatus.value != null ? this.selectedStatus.value:this.selectedStatus,
          title: this.editedItem.title,
          ras_description: this.editedItem.ras_description,
          description: this.editedItem.description,
          is_demo: this.editedItem.is_demo
        }

        let importDetails = {
          file: this.selected_zip,
          layer_id: this.editedItem.layer_id
        }

        this.$store.dispatch('admin/updateReport', data)
        .then(() => {
          if (!this.editedItem.overlay_geojson && this.selected_zip != '') {
            this.$store.dispatch('admin/postImport', {importDetails})
            .catch((err) => {
              this.infoMessage = err.response.data
            })
          }
        })
        .then(() => {
          this.saveLoad = false
          this.infoMessage = "Report updated!"
        })
        .catch((err) => {
          this.saveLoad = false
          if(err.response.status == '403') {
            this.$store.dispatch('logout')
            this.$store.dispatch('setDialog', { 
              show: true, 
              message: err.response.data, 
              status: err.response.status
            })
          } else if (err.response.status == '504') {
            this.infoMessage = "API connection timed out! If you just published an email, a confirmation email will be sent as soon as the automated email script has completed."
          } else {
            this.infoMessage = err.response.data
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.infoMessage = ""
            this.close()
            this.$store.dispatch('admin/getReports')
            .then(() => {
              this.showLoading = false
            })
          }, 1500)
        })
      }
    },
  },
}
</script>