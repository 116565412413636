<template>
  <v-container class="mx-0 px-0 py-0 fill-height" style="max-width: none;">
    <span v-if="$vuetify.breakpoint.lgAndUp && current_feature > 0" :class="isTrialUser ? 'backforth mt-12 mb-8 mr-12 pt-8':'backforth my-8 mr-12'" style="z-index: 1000000;">
      <v-btn 
        v-if="current_feature > 1"
        class="mr-4" 
        rounded
        @click="current_feature--"
      >
        Back
      </v-btn>
      <v-btn 
        v-if="current_feature < (tabs.length - 1)"
        rounded
        @click="current_feature++"
      >
        Next
      </v-btn>
    </span>
    <!-- <v-scroll-y-reverse-transition mode="out-in" :hide-on-leave="true"> -->
      <!-- <router-view /> -->
      <v-tabs
        v-model="current_feature"
        active-class="custom-active"
        background-color="grey lighten-1"
        centered
        center-active
        class="fill-height"
        color="white"
        :height="$vuetify.breakpoint.lgAndUp ? '100%':'auto'"
        :show-arrows="$vuetify.breakpoint.mdAndDown"
        :hide-slider="!$vuetify.breakpoint.lgAndUp"
        slider-color="grey"
        slider-size="10"
        :vertical="$vuetify.breakpoint.lgAndUp"
      >
        <!-- <span :class="$vuetify.breakpoint.lgAndUp ? 'ma-8 text-h5 black--text':'ma-4 text-h6 black--text'">Feature Discovery</span> -->
        <v-img 
          v-if="$vuetify.breakpoint.lgAndUp"
          alt="App logo" 
          class="ma-8 shrink align-self-center" 
          contain
          src="img/icetime_logo_transparent.png" 
          width="200"
        />
        <v-tab
          v-for="(tab, index) in tabs"
          :class="$vuetify.breakpoint.lgAndUp ? 'justify-start text-capitalize black--text pl-8 pr-12':'text-capitalize black--text text-subtitle-2 py-3'"
          :key="tab"
        >
          {{ tab }}
          <v-icon v-if="[2,3].includes(index)" class="mx-2" icon>
            mdi-progress-clock
          </v-icon>
        </v-tab>
        <v-tab-item :value="0">
          <GetStarted @set-interest="(n) => set_interest(n)" />
        </v-tab-item>
        <v-tab-item :class="$vuetify.breakpoint.lgAndUp ? 'mt-12':''" :value="1">
          <IceTimeFree />
        </v-tab-item>
        <v-tab-item :class="$vuetify.breakpoint.lgAndUp ? 'pt-12':''" :value="2">
          <ALB />
        </v-tab-item>
        <v-tab-item :class="$vuetify.breakpoint.lgAndUp ? 'mt-12':''" :value="3">
          <LakeIceTravelContent />
        </v-tab-item>
        <v-tab-item :class="$vuetify.breakpoint.lgAndUp ? 'pt-12':''" :value="4">
          <IceTimeRegions />
        </v-tab-item>
        <v-tab-item :class="$vuetify.breakpoint.lgAndUp ? 'pt-12':''" :value="5">
          <RegistrationAndSubscription />
        </v-tab-item>
      </v-tabs>
    <!-- </v-scroll-y-reverse-transition> -->
  </v-container>
</template>

<script>
import GetStarted from './components/GetStarted.vue';
import IceTimeFree from './components/IceTimeFree.vue'
import ALB from './components/ALB.vue'
import LakeIceTravelContent from './components/LakeIceTravelContent.vue'
import IceTimeRegions from './components/IceTimeRegions.vue'
import RegistrationAndSubscription from './components/RegistrationAndSubscription.vue'
import { mapGetters } from 'vuex';
// import { mapState } from 'vuex';

export default {
  components: {
    ALB,
    GetStarted,
    IceTimeFree,
    LakeIceTravelContent,
    IceTimeRegions,
    RegistrationAndSubscription,
  },
  computed: {
    // ...mapState('discovery', {
    //   interest: state => state.interest
    // })
    ...mapGetters('user', {
      isTrialUser: 'isTrialUser'
    })
  },
  created() {
  },
  data() {
    return {
      current_feature: 0,
      tabs: [
        'Get Started',
        'Ice Time Free',
        'Angler\'s Leaderboard™ Competitions',
        'Lake Ice Travel',
        'Ice Time Regions',
        'User Registration & Subscription'
      ]
    }
  },
  methods: {
    next() {
      this.current_feature += 1
    },
    set_interest(n) {
      // console.log('switch to ' + n)
      this.current_feature = n
    }
  },
  mounted() {
    this.$store.dispatch('setMode', 'discovery')
    this.$store.dispatch('checkToken')
  },
}
</script>

<style scoped>
>>>.backforth {
  position: absolute;
  top: 0;
  right: 0;
}

>>>.custom-active {
  background-color: #9E9E9E!important;
}
</style>