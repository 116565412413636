<template>
  <v-container style="height: 88%;">
    <HomeUnregistered class="mb-12 pb-12" v-if="!user.status" />
    <HomeFree class="mb-12 pb-12" v-if="user.status < 2" />
    <HomePro class="mb-12 pb-12" v-else-if="user.status >= 2" />
    <Footer />
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Footer from '../components/Footer.vue'
import HomeFree from '../components/HomeFree.vue'
import HomePro from '../components/HomePro.vue'
import HomeUnregistered from '../components/HomeUnregistered.vue'

export default {
  computed: {
    ...mapState('user', {
      user: state => state.user,
      token: state => state.token
    }),
  },
  components: {
    Footer,
    HomeFree,
    HomePro,
    HomeUnregistered,
  },
  mounted() {
    this.$store.dispatch('setMode', '')
  }
}
</script>
