<template>
<v-container>
    <v-alert v-if="showLoading" type="info">Loading...</v-alert>
<v-container v-else>
    <h1>Training Statistics</h1>
    <v-divider class="mb-2"></v-divider>
    <!-- <v-btn class="mb-2"
    @click="testSend()">
    Test Send Dummy Data
    </v-btn>  -->

    <v-data-table
        dense
        :headers="headers"
        :items="average_species_stats"
        :items-per-page="-1"
        class="elevation-1"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @click:row="showFull"
    >
        <template v-slot:item.accuracy="{ item }">
            <div>{{ Math.round((getAccuracy(item.id, item.count) *100 ) * 100) /100 }}%</div>
        </template>
        <template v-slot:item.trained="{ item }">
            <div>{{ getTrained(item.id, item.count) }}</div>
        </template>

        <template v-slot:item.max_length="{ item }">
            <div >{{ getUpper(item.id) }}</div>
            
        </template>
        <template v-slot:item.min_length="{ item }">
            <div >{{ getLower(item.id) }}</div>
            
        </template>
        <template v-slot:item.max_ai_length="{ item }">
            <div >{{ getUpperAI(item.id) }}</div>
            
        </template>
        <template v-slot:item.min_ai_length="{ item }">
            <div >{{ getLowerAI(item.id) }}</div>
            
        </template>
    
    </v-data-table>

    <v-dialog
        v-model="overlay"
        max-width="1000"
    >
        <v-card max-width="1000" class="pa-1">
                
            <v-card-actions class="pa-2">
                <v-spacer></v-spacer>
                <v-icon
                    large
                    @click="overlay=false"
                >mdi-close-circle
                </v-icon>                
            </v-card-actions>
            <v-data-table
                dense
                :headers="breakdown_headers"
                :items="full_species_stats"
                :items-per-page="10"
                class="elevation-1"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"        
                >
                <template v-slot:item.image_name="{ item }">
                        <v-img         
                            v-if="item.image_name"        
                            :src="($apiEndpointUrl + 'catch_image/' + item.image_name)" 
                            max-height="100"
                            max-width="100"
                            alt="Catch"/>
                        <v-icon v-else large>mdi-fish</v-icon>
                </template>
                <!-- <template v-slot:item.ai_status="{ item }">
                    <v-icon v-if="item.ai_status==2 && item.measurement_status==2 && item.qr_status==2 && item.board_status==2 && item.fish_status==2" large color="green">mdi-checkbox-marked</v-icon>
                    <v-icon v-else-if="item.ai_status==1 || item.measurement_status==1 || item.qr_status==1 || item.board_status==1 || item.fish_status==1" large color="red">mdi-close-box</v-icon>
                    <v-icon v-else large color="black">mdi-checkbox-blank-outline</v-icon>
                </template> -->
                <template v-slot:item.length="{ item }">
                    <div v-if="item.length && item.ai_length">{{ Math.round((item.length) * 1000) / 1000 }} cm<v-icon v-if="(item.length-item.ai_length) > 0.635 || (item.ai_length-item.length) > 0.635" color="orange">mdi-flag-variant</v-icon></div>
                    <div v-else-if="item.length">{{ Math.round((item.length) * 1000) / 1000 }} cm</div>
                    <div v-else></div>
                    
                </template>
                <template v-slot:item.ai_length="{ item }">
                    <div v-if="item.ai_length">{{ Math.round((item.ai_length) * 1000) / 1000 }} cm</div>
                    <div v-else></div>
                    
                </template>
                <!-- <template v-slot:item.date="{ item }">
                    
                </template> -->
            </v-data-table>
        </v-card>
    </v-dialog>

</v-container>
</v-container>
</template>
  
<script>
import { mapState } from 'vuex'
  
export default {
    computed: {
        ...mapState('admin', {
            // training_catches: state => state.training_catches,
            full_species_stats: state => state.full_species_stats,
            average_species_stats: state => state.average_species_stats,
            accuracy_species_stats: state => state.accuracy_species_stats,
            trained_species_stats: state => state.trained_species_stats,
            lower_species_stats: state => state.lower_species_stats,
            upper_species_stats: state => state.upper_species_stats,
        }),
        ...mapState('spot_mapper', {
            species: state => state.species,
        })
    },
    data: () => ({
        showLoading: true,
        items: [],
        sortBy: 'count',
        sortDesc: true,
        headers: [
            { text: 'id', align: 'start', value: 'id' },
            { text: 'Species', value: 'common_name' },
            { text: '95% length', value: 'max_length' },
            { text: '5% length', value: 'min_length' },
            { text: '95% AI Length', value: 'max_ai_length' },
            { text: '5% AI Length', value: 'min_ai_length' },
            { text: 'Count', value: 'count' },
            { text: 'Agreement', value: 'accuracy' },
            { text: 'Trained Count', value: 'trained' },
        ],
        breakdown_headers: [
            { text: 'id', align: 'start', value: 'id' },
            { text: 'Species Id', value: 'species' },
            { text: 'Species', value: 'common_name' },
            { text: 'Length', value: 'length' },
            { text: 'AI Length', value: 'ai_length' },
            { text: 'Date', value: 'date' },
            { text: 'Image', value: 'image_name' },
        ],
        image_start: "https://api-2.icetime.app/v1/catch_image/",
        test_data: [
            {id: '49', common_name: 'northern pike', max_length: '50.30', min_length: '26.90', max_ai_length: '69.63',min_ai_length: '27.28', count: '7', accuracy: '1', trained: '0'},
            {id: '982', common_name: 'walleye', max_length: '48.00', min_length: '7.90', max_ai_length: '49.19',min_ai_length: '12.28', count: '39', accuracy: '3', trained: '0'},
            {id: '641', common_name: 'black crappie', max_length: '26.50', min_length: '26.0', max_ai_length: '26.96',min_ai_length: '26.55', count: '5', accuracy: '3', trained: '0'},
            {id: '575', common_name: 'yellow perch', max_length: '20.40', min_length: '7.95', max_ai_length: '20.91',min_ai_length: '20.18', count: '8', accuracy: '5', trained: '0'},
            {id: '1096', common_name: 'smallmouth bass', max_length: '40.80', min_length: '35.30', max_ai_length: '40.36',min_ai_length: '35.76', count: '4', accuracy: '3', trained: '0'},
            {id: '753', common_name: 'freshwater drum', max_length: '40.80', min_length: '40.80', max_ai_length: '41.21',min_ai_length: '40.36', count: '2', accuracy: '2', trained: '0'},
            {id: '618', common_name: 'largemouth bass', max_length: '23.00', min_length: '20.30', max_ai_length: '20.87',min_ai_length: '20.43', count: '4', accuracy: '3', trained: '0'},
        ],
        test_species:[
            {id:'429', species:'982', common_name:'walleye', length:'48.0', ai_length:'51.249794989051', image_name:'660d6c89916d8.jpg', date:'2024-04-03 14:49:39'}
        ],
        overlay: false,
        selected_species: null,

    }),
    methods:{
        testSend(){
            // console.log("test")
            let data = {
                "trained": [
                    {"id": 429},
                    {"id": 428},
                    {"id": 434}
                ],
                "skipped": [
                    {"id": 430},
                    {"id": 433}
                ]
            }
            this.$store.dispatch('admin/sendtest', data)
            // console.log(data)
        },
        getAccuracy(id, count){
            let result = null

            let index = this.accuracy_species_stats.findIndex(obj => obj.id == id)
            let amount = this.accuracy_species_stats[index].accuracy
            result = amount/count
            // console.log("the accuracy is: " + result)
            
            return result;
            // addToTrainList(){
            //     let temp_tags = null
            //     let temp_species_id = null
            //         if(this.current_tags !== null){
            //             temp_tags = this.current_tags.toString()
            //         }

            //     let index = this.species.findIndex(obj => obj.common_name == this.current_species)
            //     // console.log(index)
            //     temp_species_id = this.species[index].id
                
            //     let tempData = {
            //         "id": this.selected_catch.id,
            //         "common_name": this.current_species,
            //         "species_id": temp_species_id,
            //         "status": "5",
            //         "train_date": null,
            //         "submit_date": null,
            //         "tags": temp_tags,
            //         "image_name": this.selected_catch.image_name
            //     }
            //     // console.log(tempData)
            //     this.training_list.push(tempData)
            //     Object.assign(this.items[this.current_row], tempData)
            //     // console.log(this.items[this.current_row])
            //     // console.log(this.training_list)
            //     this.overlay = false
            // },
        },
        getTrained(id){
            let result = 0

            if(this.trained_species_stats !== null){
                let index = this.trained_species_stats.findIndex(obj => obj.id == id)
                let amount = this.accuracy_species_stats[index].trained
                result = amount
                // console.log(result)
            }
            
            return result;
        },
        getLower(id){
            let result = "Not Available"

            let amount = this.lower_species_stats[id].lower_range
            if(amount !== null){
                result = amount + " cm"
            }
            
            return result;
        },
        getLowerAI(id){
            let result = "Not Available"

            let amount = this.lower_species_stats[id].lower_ai_range
            if(amount !== null){
                result = amount + " cm"
            }
            
            return result;
        },
        getUpper(id){
            let result = "Not Available"

            let amount = this.upper_species_stats[id].upper_range
            if(amount !== null){
                result = amount + " cm"
            }
            
            return result;
        },
        getUpperAI(id){
            let result = "Not Available"

            let amount = this.upper_species_stats[id].upper_ai_range
            if(amount !== null){
                result = amount + " cm"
            }
            
            return result;
        },
        showFull(row){
            let species = row.id
            // console.log(species)
            // console.log(row)
            this.$store.dispatch('admin/getFullStatistics', { species }).then(() => {
                // console.log(this.full_species_stats)
                this.overlay = true
            })
            this.overlay = true

        },
    },
    created () {

    },
    mounted(){
        this.items = this.data
        this.showLoading = false
        
        this.$store.dispatch('admin/getGeneralStatistics').then(() => {

                // this.overlay = true
            }).finally(() => {
                // console.log(this.average_species_stats)
                // console.log(this.accuracy_species_stats)
                // console.log(this.trained_species_stats)
                // console.log(this.lower_species_stats)
                // console.log(this.upper_species_stats)
            })
        // fetch('https://api-2.icetime.app/v1/average_species_statistics').then(
        //     console.log('api test get')
        // )
        // fetch('https://api-2.icetime.app/v1/read_test').then(
            // console.log('api test get')
        // )
    }
}
</script>
<style scoped>
/* Should zebra stripe leaderboard */
>>>tr:nth-child(even){
    background-color: #90CAF9;
}
</style>