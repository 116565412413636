import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
axios.defaults.baseUrl = Vue.$apiEndpointUrl

const state = {
    species: [],
    competitions: [],
    ended_comps: [],
    page: 'join_list',
    selected_competition: '',
    competition: [],
    teams: [],
    team_members: [],
    comp_teams: [],
    team_list: [],
    live_leaderboard: [],
    daily_leaderboard: [],
    top_ten_leaderboard: [],
    sport_fish: [],
    personal: [],
    regions: [],
    selected_region: '16',
    comp_species:[],
    comp_prizes:[],
    comp_blackout:[],
    catch_image: undefined,
    image_name: 'test',
    joined_list: [],
    comp_sidepot: [],
    full_species_stats: [],
};

const getters = {

};

const mutations = {
    setSpecies(state, species) {
        state.species = species
      },
    setCompetitions(state, comps) {
        state.competitions = comps
      },
    setPage(state, page) {
        state.page = page
      },
    setSelectedCompetition(state, compId) {
        state.selected_competition = compId
      },
    setCompetition(state, comp) {
        state.competition = comp
      },
      setPrizes(state, prizes) {
        state.comp_prizes = prizes
      },
    setEndedComps(state, comps) {
        state.ended_comps = comps
      },
    setTeams(state, teams) {
        state.teams = teams
      },
    setTeamMembers(state, team_members){
        state.team_members = team_members
      },
    setCompTeams(state, comp_teams) {
        state.comp_teams = comp_teams
      },
    setTeamList(state, team_list) {
        state.team_list = team_list
      },
    setLiveLeaderboard(state, live_leaderboard) {
        state.live_leaderboard = live_leaderboard
      },
    setDailyLeaderboard(state, daily_leaderboard) {
        state.daily_leaderboard = daily_leaderboard
      },
    setTopTenLeaderboard(state, top_ten_leaderboard) {
        state.top_ten_leaderboard = top_ten_leaderboard
      },
    setRegions(state, regions) {
          state.regions = regions
      },
    setSelectedRegion(state, region) {
          state.selected_region = region
      },
    setCompSpecies(state, comp_species) {
          state.comp_species = comp_species
      },
    setCompBlackout(state, comp_blackout) {
          state.comp_blackout = comp_blackout
      },
    setCompSidepot(state, comp_sidepot) {
          state.comp_sidepot = comp_sidepot
      },
    setCatchImage(state, catch_image) {
          state.catch_image = catch_image
      },
    setImageName(state, image_name) {
          state.image_name = image_name
      },
    setSportFish(state, sport_fish) {
          state.sport_fish = sport_fish
      },
    setPersonal(state, personal) {
          state.personal = personal
      },
    setJoinedList(state, list) {
          state.joined_list = list
      },
    setFullSpeciesStats(state, stats) {
        state.full_species_stats = stats
      },
};

const actions = {
  getSpecies({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('species')
      .then(resp => {
        // console.log(resp)
        commit('setSpecies', Object.values(resp.data))
        resolve(resp)
    })
      .catch(err => {
        reject(err)
      })
    })
  },
  getCompetitions({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('competitions')
      .then(resp => {
        // console.log(resp)
        if (resp.data) {
          commit('setCompetitions', Object.values(resp.data))
        }
        resolve(resp)
    })
      .catch(err => {
        // console.log(err)
        reject(err)
      })
    })
  },
  getEndedComps({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('ended_competitions')
      .then(resp => {
        // console.log(resp)
        if (resp.data) {
          commit('setEndedComps', Object.values(resp.data))
        }
        resolve(resp)
    })
      .catch(err => {
        // console.log(err)
        reject(err)
      })
    })
  },
  setSelectedCompetition({ commit }, id) {
    return new Promise((resolve) => {
        // console.log()
        commit('setSelectedCompetition', id)
        resolve(id)
    })
  },
  setSelectedRegion({ commit }, region){
    return new Promise((resolve) => {
        // console.log("test region: " + id)
        commit('setSelectedRegion', region)
        resolve(region)
    })
  },
  // **NOT WORKING
  setImageName({ commit }, name) {
    return new Promise((resolve) => {
      commit('setImageName', name)
      // console.log("this.image_name: " + this.image_name)
      // console.log("name :" + name)
      resolve(name)
    })
  },
  setCompSpecies({ commit }, species) {
    return new Promise((resolve) => {
        commit('setCompSpecies', species)
        resolve(species)
    })
  },
  setCompBlackout({ commit }, blackout) {
    return new Promise((resolve) => {
        commit('setCompBlackout', blackout)
        resolve(blackout)
    })
  },
  setCompSidepot({ commit }, sidepot) {
    return new Promise((resolve) => {
        commit('setCompSidepot', sidepot)
        resolve(sidepot)
    })
  },
  getCompetition({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('competition/' + this.selected_competition)
      .then(resp => {
        if (resp.data) {
          // console.log(this.selected_competition)
          // commit('setCompetition', resp.data[0])
          commit('setCompSpecies', resp.data["comp_species"])
          commit('setCompBlackout', resp.data["comp_blackout"])
          commit('setCompSidepot', resp.data["comp_sidepot"])
          commit('setCompetition', resp.data["competition"])
          commit('setPrizes', resp.data["comp_prizes"])
          // console.log(resp.data["comp_sidepot"])
        } else {
          // console.log(resp.data[0])
          commit('setCompetition', [])
          commit('setCompSpecies', [])
          commit('setCompBlackout', [])
          commit('setCompSidepot', [])
          commit('setPrizes', [])
        }
        resolve(resp)
      })
      .catch(err => {{
        reject(err)
      }})
    })
  },
  getPersonalLeaderboard({commit}) {
    // console.log(this.region)
    return new Promise((resolve, reject) => {
      axios.get('personal_leaderboard/' + '16')
      .then(resp => {
        if (resp.data) {
          // console.log(resp.data)
          // commit('setCompetition', resp.data[0])
          commit('setSportFish', resp.data["sport"])
          commit('setPersonal', resp.data["leaderboard"])
          // console.log(resp.data["comp_species"])
        } else {
          // console.log(resp.data[0])
          commit('setSportFish', [])
          commit('setPersonal', [])
        }
        resolve(resp)
      })
      .catch(err => {{
        reject(err)
      }})
    })
  },
  getFullSpeciesStatistics({commit}, { species }) {
    return new Promise((resolve, reject) => {
      axios.get('personal_full_species_statistics/' + species )
      .then(resp => {
        if (resp.data) {
          // console.log(resp.data)
          commit('setFullSpeciesStats', resp.data)
        } else {
          // console.log(resp.data[0])
          commit('setFullSpeciesStats', [])
          
        }
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getTeams({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('teams/' + this.selected_competition)
      .then(resp => {
        if (resp.data) {
          // console.log(resp.data)
          commit('setTeams', resp.data)
        } else {
          // console.log(resp.data[0])
          commit('setTeams', [])
        }
        resolve(resp)
      })
      .catch(err => {{
        reject(err)
      }})
    })
  },
  getTeamMembers({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('team_members/' + this.selected_competition)
      .then(resp => {
        if (resp.data) {
          // console.log(resp.data)
          commit('setTeamMembers', resp.data)
        } else {
          // console.log(resp.data[0])
          commit('setTeamMembers', [])
        }
        resolve(resp)
      })
      .catch(err => {{
        reject(err)
      }})
    })
  },
  getCompSpecies({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('comp_species/' + this.selected_competition)
      .then(resp => {
        if (resp.data) {
          // console.log(resp.data)
          commit('setCompSpecies', resp.data)
        } else {
          // console.log(resp.data[0])
          commit('setCompSpecies', [])
        }
        resolve(resp)
      })
      .catch(err => {{
        reject(err)
      }})
    })
  },
  getCompTeams({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('compteams/' + this.selected_competition)
      .then(resp => {
        if (resp.data) {
          // console.log(resp.data)
          commit('setCompTeams', resp.data)
        } else {
          // console.log(resp.data[0])
          commit('setCompTeams', [])
        }
        resolve(resp)
      })
      .catch(err => {{
        reject(err)
      }})
    })
  },
  getTeamList({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('teammemberlist/' + this.selected_competition)
      .then(resp => {
        if (resp.data) {
          // console.log(resp.data)
          commit('setTeamList', resp.data)
        } else {
          // console.log(resp.data[0])
          commit('setTeamList', [])
        }
        resolve(resp)
      })
      .catch(err => {{
        reject(err)
      }})
    })
  },
  getJoinedList({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('joined_competition_list')
      .then(resp => {
        if (resp.data) {
          // console.log(resp.data)
          commit('setJoinedList', resp.data)
        } else {
          // console.log(resp.data[0])
          commit('setJoinedList', [])
        }
        resolve(resp)
      })
      .catch(err => {{
        reject(err)
      }})
    })
  },
  getLiveLeaderboard({commit}, { region, season }) {
    return new Promise((resolve, reject) => {
      axios.get('liveleaderboard/' + region + '/' + season)
      .then(resp => {
        if (resp.data) {
          // console.log(resp.data)
          commit('setLiveLeaderboard', resp.data)
        } else {
          // console.log(resp.data[0])
          // commit('setCompTeams', [])
        }
        resolve(resp)
      })
      .catch(err => {{
        reject(err)
      }})
    })
  },
  getDailyLeaderboard({commit}, { region, date }) {
    return new Promise((resolve, reject) => {
      axios.get('dailyleaderboard/' + region + '/' + date)
      .then(resp => {
        if (resp.data) {
          // console.log(resp.data)
          commit('setDailyLeaderboard', resp.data)
        } else {
          // console.log(resp.data[0])
          commit('setDailyLeaderboard', [])
        }
        resolve(resp)
      })
      .catch(err => {{
        reject(err)
      }})
    })
  },
  getTopTenLeaderboard({commit}, { region }) {
    return new Promise((resolve, reject) => {
      axios.get('toptenleaderboard/' + region )
      .then(resp => {
        if (resp.data) {
          // console.log(resp.data)
          commit('setTopTenLeaderboard', resp.data)
        } else {
          // console.log(resp.data[0])
          commit('setTopTenLeaderboard', [])
        }
        resolve(resp)
      })
      .catch(err => {{
        reject(err)
      }})
    })
  },
  getRegions({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('active_regions')
      .then(resp => {
        if (resp.data) {
          // console.log(resp.data)
          commit('setRegions', resp.data)
        } else {
          // console.log(resp.data[0])
          commit('setRegions', [])
        }
        resolve(resp)
      })
      .catch(err => {{
        reject(err)
      }})
    })
  },
  recordCompetition(_, compItem) {
    return new Promise((resolve, reject) => {
      axios.post('competition', compItem)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  rejectCatch(_, rejectItem) {
    return new Promise((resolve, reject) => {
      axios.post('rejectcatch', rejectItem)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  acceptCatch(_, acceptItem) {
    return new Promise((resolve, reject) => {
      axios.post('acceptcatch', acceptItem)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  editCompetition(_, compItem) {
    return new Promise((resolve, reject) => {
      axios.put('editcompetition', compItem)
      .then(resp => {
        this.getCompetition()
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  // This is for joining a competition on an existing team
  joinTeam(_, compitem) {
    return new Promise((resolve, reject) => {
      axios.post('jointeam', compitem)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  joinComp(_, compitem) {
    return new Promise((resolve, reject) => {
      axios.post('createteam', compitem)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  uploadImage(_, data) {
    return new Promise((resolve, reject) => {
      axios.post('uploadimage', data)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  reuploadImage(_, data) {
    return new Promise((resolve, reject) => {
      axios.post('reuploadimage', data)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getImage({commit}, image_name) {
    return new Promise((resolve, reject) => {
      // console.log(image_name)
      axios.get('catch_image/' + image_name)
      .then(resp => {
        if (resp.data) {
          commit('setCatchImage', resp.data)
        } else {
          commit('setCatchImage', undefined)
        }
        resolve(resp)
      })
      .catch(err => {{
        reject(err)
      }})
    })
  }, 
  compWithdraw(_, data) {
    return new Promise((resolve, reject) => {
      axios.post('comp_withdraw', data)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  testCV(_, data) {
    return new Promise((resolve, reject) => {
      axios.post('receivecv', data)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  compForfeit(_, data) {
    return new Promise((resolve, reject) => {
      axios.post('comp_forfeit', data)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  endComp(_, data) {
    return new Promise((resolve, reject) => {
      axios.put('end_competition', data)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  addCompBonus(_, data) {
    return new Promise((resolve, reject) => {
      axios.put('add_comp_bonus', data)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }