import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
//import VueZxios from 'Vue-axios'
import messaging from './firebase'
// import { TableFooter } from '@material-ui/core'

//// Import stores by feature
import admin from './features/admin/store'
import user from './features/user/store'
import customerservice from './features/customerservice/store'
import demo from './features/demo/store'
import map from './features/map/store'
import spot_mapper from './features/spot_mapper/store'
import ras from './features/ras/store'
import geopost from './features/geopost/store'
import discovery from './features/discovery/store'
import access_points from './features/access_points/store'
import leaderboard from './features/leaderboard/store'

Vue.use(Vuex)
axios.defaults.baseUrl = Vue.$apiEndpointUrl
axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'icetime.app';

export default new Vuex.Store({
  state: {
    active_regions: [],
    dialog: {},
    footprint_id: '',
    footprint_list: [],
    footprints: [],
    // hazards: [],
    hazardtypes: [],
    // layers: [],
    mailchimp_subscriptions: [],
    mode: '',
    navDrawer: false,
    // maps: [],
    // map_user_reports: [],
    placeholder_id: '',
    products: [],
    regions: [],
    // reports: [],
    showTooltip: false,
    signup_message: '',
    snackbar: {
      showing: false,
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dapibus.',
      link: ''
    },
    tab: '',
    // token: localStorage.getItem('token') || '',
    // user: JSON.parse(localStorage.getItem('user')) || {},
    // user_locations: [],
    // user_reports: [], 
    // users: [],
    // view_map: '',
  },
  mutations: {
    // auth_success(state, payload){
    //   state.token = payload.token
    //   state.user = payload.user
    // },
    auth_error() {
      //console.log(payload)
    },
    getActiveRegions(state, payload) {
      state.active_regions = payload
    },
    // getDemoHazards(state, payload) {
    //   state.demo_hazards = payload
    // },
    // getDemoLayers(state, payload) {
    //   state.demo_layers = payload
    // },
    // getDemoUserReports(state, payload) {
    //   state.demo_user_reports = payload
    // },
    // getFootprints(state, payload) {
    //   state.footprints = payload
    // },
    // getHazards(state, payload) {
    //   state.hazards = payload
    // },
    getFootprintList(state, payload) {
      state.footprint_list = payload
    },
    getHazardTypes(state, payload) {
      state.hazardtypes = payload
    },
    // getProducts(state, payload) {
    //   state.products = payload
    // },
    getLayers(state, payload) {
      state.layers = payload
    },
    getMailChimpSubscriptions(state, payload) {
      state.mailchimp_subscriptions = payload
    },
    // getMap(state, payload) {
    //   state.view_map = payload
    // },
    // getMaps(state, payload) {
    //   state.maps = payload
    // },
    // getMapUserReports(state,payload) {
    //   state.map_user_reports = payload
    // },
    getRegions(state, payload) {
      state.regions = payload
    },
    // getReports(state, payload) {
    //   state.reports = payload
    // },
    // getUserLocations(state, payload) {
    //   state.user_locations = payload
    // },
    // getUserReports(state, payload) {
    //   state.user_reports = payload
    // },
    // getUsers(state, payload) {
    //   state.users = payload
    // },
    // getUserTypes(state, payload) {
    //   state.usertypes = payload
    // },
    // logout(state){
    //   state.token = ''
    //   state.user = {}
    // },
    setDialog(state, data) {
      state.dialog = data;
    },
    setFootprintId(state, data) {
      state.footprint_id = data
    },
    setMode(state, mode) {
      state.mode = mode
    },
    setPlaceholderId(state, data) {
      state.placeholder_id = data
    },
    setShowTooltip(state, payload) {
      state.showTooltip = payload
    },
    setSignUpMessage(state, payload) {
      state.signup_message = payload
    },
    setTab(state, tab) {
      state.tab = tab
    },
    setSnackbar(state, snackbar) {
      state.snackbar = snackbar
    },
    // user_update(state, payload){
    //   state.user = payload.user
    // },
    toggleNavDrawer(state) {
      state.navDrawer = !state.navDrawer
    }
  },
  actions: {
    checkAlias(_, alias) {
      return new Promise((resolve, reject) => {
        axios.get('check_alias/' + alias)
          .then(resp => {
            resolve(resp)
          }).catch(err => {
            reject(err)
          })
      })
    },
    checkEmail(_, email) {
      return new Promise((resolve, reject) => {
        axios.get('check_email/' + email)
          .then(resp => {
            resolve(resp)
          }).catch(err => {
            reject(err)
          })
      })
    },
    checkToken({dispatch}) {
      axios.get('')
      .catch(err => {
        dispatch('handleError', err)
      })
    },
    handleError({dispatch}, err) {
      if (err.response && err.response.status == '403' && err.response.data == "A newer session has been detected. This session has been closed, please re-login.") {
        dispatch('user/logout')
        dispatch('setDialog', {
          show: true,
          message: err.response.data,
          status: err.response.status
        })
      }
    },
    setShowTooltip({ commit }, show) {
      commit('setShowTooltip', show)
    },
    setSnackbar({ commit }, snackbar) {
      commit('setSnackbar', snackbar);
    },
    setDialog({ commit }, data) {
      commit('setDialog', data);
    },
    setPlaceholderId({ commit }, data) {
      commit('setPlaceholderId', data)
    },
    setMode({ commit }, mode) {
      commit('setMode', mode)
    },
    setTab({ commit }, tab) {
      commit('setTab', tab)
    },
    // postImport(_, payload) {
    //   //console.log(payload)
    //   return new Promise((resolve) => {
    //     axios.post('import', payload)
    //     .then(resp => {
    //       resolve(resp)
    //     })
    //   })
    // },
    // stopImport(_, layer) {
    //   return new Promise((resolve, reject) => {
    //     axios.post('stop_import', layer)
    //     .then(resp => {
    //       resolve(resp)
    //     })
    //     .catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    setSignUpMessage({ commit }, message) {
      return new Promise(() =>
        commit('setSignUpMessage', message)
      )
    },
    getActiveRegions({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('active_regions')
          .then(resp => {
            commit('getActiveRegions', Object.values(resp.data))
            resolve(resp)
          }).catch(err => {
            reject(err)
          })
      })
    },
    // getProducts({commit}) {
    //   return new Promise((resolve, reject) => {
    //     axios.get('products/')
    //     .then(resp => {
    //       commit('getProducts', resp.data.data)
    //       resolve(resp)
    //     }).catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    // getSubscriptions() {
    //   return new Promise((resolve, reject) => {
    //     axios.get('subscriptioninfo')
    //     .then(resp => {
    //       // console.log(resp)
    //       resolve(resp)
    //     }).catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    // getLayers({commit}, payload) {
    //   return new Promise((resolve, reject) => {
    //     axios.get('layers/' + payload.id)
    //     .then(resp => {
    //       commit('getLayers', resp.data)
    //       resolve(resp)
    //     }).catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    getRegion({ commit }) {
      return new Promise((resolve, reject) => {
        // var getstr = payload ? ('regions/' + payload.radar_id) : 'regions'
        axios.get('regions')
        .then(resp => {
          commit('getRegions', Object.values(resp.data))
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getRegionGeoJSON(_, payload) {
      return new Promise((resolve, reject) => {
        // var getstr = payload ? () : 'regions'
        axios.get('regions/' + payload.radar_id)
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // getUploads() {
    //   return new Promise((resolve, reject) => {
    //     axios.get('uploads')
    //     .then(resp => {
    //       resolve(resp)
    //     }).catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    // getRegions() {
    //   return new Promise((resolve, reject) => {
    //     axios.get('regions')
    //     .then(resp => {
    //       resolve(resp)
    //     }).catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    // getStatus() {
    //   return new Promise((resolve, reject) => {
    //     axios.get('status')
    //     .then(resp => {
    //       resolve(resp)
    //     }).catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    // getMaps({commit}) {
    //   return new Promise((resolve, reject) => {
    //     //axios.get('maps')
    //     axios.get('subscriptions')
    //     .then(resp => {
    //       commit('getMaps', Object.values(resp.data))
    //       resolve(resp)
    //     }).catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    // getMap({commit}, id) {
    //   return new Promise((resolve, reject) => {
    //     axios.get('map/' + id)
    //     .then(resp => {
    //       commit('getMap', resp.data)
    //       resolve(resp)
    //     }).catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    // getReports({commit}) {  
    //   return new Promise((resolve, reject) => {
    //     axios.get('reports')
    //     .then(resp => {
    //       commit('getReports', Object.values(resp.data))
    //       resolve(resp)
    //     }).catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    // updateReport({dispatch}, report) {
    //   return new Promise((resolve, reject) => {
    //     axios.put('reports', report)
    //     .then(resp => {
    //       dispatch('getReports')
    //       resolve(resp)
    //     }).catch(err => {
    //       // commit('auth_error', err)
    //       reject(err)
    //     })
    //   })
    // },
    // getUserReports({commit}) {
    //   return new Promise((resolve, reject) => {
    //     axios.get('user_reports')
    //     .then(resp => {
    //       commit('getUserReports', resp.data)
    //       resolve(resp)
    //     }).catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    // getMapUserReports({commit}, id) {
    //   return new Promise((resolve, reject) => {
    //     axios.get('user_reports/' + id)
    //     .then(resp => {
    //       commit('getMapUserReports', resp.data)
    //       resolve(resp)
    //     })
    //     .catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    // updateMap({commit}, map) {
    //   return new Promise((resolve, reject) => {
    //     axios.put('map/' + map.id, map)
    //     .then(resp => {
    //       commit('getMaps', resp.data)
    //       resolve(resp)
    //     }).catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    updateLayer({ commit }, layer) {
      return new Promise((resolve, reject) => {
        axios.put('layer', layer)
          .then(resp => {
            commit('getLayers', resp.data)
            resolve(resp)
          }).catch(err => {
            reject(err)
          })
      })
    },
    // deleteReport(_, item) {
    //   return new Promise((resolve, reject) => {
    //     axios.delete('report/' + item)
    //     .then((resp) => {
    //       resolve(resp)
    //     })
    //     .catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    // postMap({commit}, map) {
    //   return new Promise((resolve, reject) => {
    //     axios.post('map', map)
    //     .then(resp => {
    //       commit('getMaps', resp.data)
    //       resolve(resp)
    //     }).catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    // updateProfile({commit}, user){
    //   return new Promise((resolve, reject) => {
    //     axios.put('profile', user)
    //     .then(resp => {
    //       const user = resp.data.user
    //       commit('user_update', {user})
    //       resolve(resp)
    //     }).catch(err => {
    //       // commit('auth_error', err)
    //       reject(err)
    //     })
    //   })
    // },
    // openBillingPortal() {
    //   return new Promise((resolve, reject) => {
    //     axios.get('billingportal')
    //     .then(resp => {
    //       //console.log(resp)
    //       //window.location.href = resp.data.url
    //       window.open(resp.data.url, "_self")
    //       // const user = resp.data.user
    //       // commit('user_update', {user})
    //       resolve(resp)
    //     }).catch(err => {
    //       // commit('auth_error', err)
    //       reject(err)
    //     })
    //   })
    // },
    // signup({commit}, user){
    //   return new Promise((resolve, reject) => {
    //     axios.post('signup', user)
    //     .then(resp => {
    //       if(resp.data.status == 0) {
    //         // account created but activation required
    //       }
    //       // const token = resp.data.token
    //       // const user = resp.data.user
    //       // localStorage.setItem('token', token)
    //       // localStorage.setItem('user', JSON.stringify(user))
    //       // axios.defaults.headers.common['Authorization'] = token
    //       // commit('auth_success', {token,user})
    //       resolve(resp)
    //     })
    //     .catch(err => {
    //       commit('auth_error', err)
    //       // localStorage.removeItem('token')
    //       // localStorage.removeItem('user')
    //       reject(err)
    //     })
    //   })
    // },
    // login({commit}, user){
    //   return new Promise((resolve, reject) => {
    //     //commit('auth_request')
    //     axios.post('login', user)
    //     .then(resp => {
    //       if (typeof(resp.data.token) == 'undefined' || typeof(resp.data.user) == 'undefined') {
    //         commit('auth_error')
    //         localStorage.removeItem('token')
    //         localStorage.removeItem('user')
    //         reject({response: {data: 'Unknown server error'}})
    //       } else {
    //         const token = resp.data.token
    //         const user = resp.data.user

    //         localStorage.setItem('token', token)
    //         localStorage.setItem('user', JSON.stringify(user).replace("\\", ""))
    //         axios.defaults.headers.common['Authorization'] = token
    //         commit('user/auth_success', {
    //           token,
    //           user
    //         })
    //         resolve(resp)
    //       }
    //     })
    //     .catch(err => {
    //       commit('auth_error')
    //       localStorage.removeItem('token')
    //       localStorage.removeItem('user')
    //       reject(err)
    //     })
    //   })
    // },
    // logout({commit}){
    //   return new Promise((resolve) => {
    //     commit('logout')
    //     localStorage.removeItem('token')
    //     localStorage.removeItem('user')
    //     delete axios.defaults.headers.common['Authorization']
    //     resolve()
    //   })
    // },
    subscribe({commit, dispatch}){
      // console.log("[$store] Subscribe process starting ...");

      return new Promise((resolve, reject) => {
        try {
          if(messaging) {
            // console.log("[$store] [FCM] Retrieving token ...");
            messaging.getToken({
              vapidKey:
                "BDsPTtTxM892AoC7uaXEzqpptgFN83FP1NZdK4Y1aXFH-DW__1CtAvEjnbSkKKsvxijwxztS32cI0RmlNOvNHqw",
            })
            .then((currentToken) => {
              if (currentToken) {
                // console.log("[$store] [FCM] Token retrieved: ", currentToken);

                let data = {
                  registration_token: currentToken
                }

                // console.log("[$store] [API] Attempting to subscribe token ...");
                axios.post('subscribe', data)
                .then(resp => {
                  messaging.onMessage((payload) => {
                    var payload_link = payload.notification.click_action ? payload.notification.click_action:"";
                    dispatch('setSnackbar', {
                      showing: true,
                      text: payload.notification.body,
                      link: payload_link
                    });
                  });
                  // console.log("[$store] [API] Tokens subscribed!");
                  resolve(resp)
                })
                .catch(err => {
                  // console.log("[$store] [API] Token registration failed: " . err);
                  commit('auth_error')
                  reject(err)
                })
              }
            })
          }
        } catch(err) {
          dispatch('setSnackbar', {
            showing: true,
            text: "Heads up! This device is not compatible with push notifications.",
            link: ""
          });
        }
      })
    },
    sendPush({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('push', data)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            // console.log(err);
            commit('auth_error');
            reject(err);
          })
      });
    },
    // createHazard({commit}, data) {
    //   return new Promise((resolve, reject) => {
    //     axios.post('hazards', data)
    //     .then(resp => {
    //       resolve(resp)
    //     })
    //     .catch(err => {
    //       commit('auth_error', err)
    //       reject(err)
    //     })
    //   })
    // },
    // deleteHazard({commit}, data) {
    //   return new Promise((resolve, reject) => {
    //     axios.delete('hazards/' + data)
    //     .then(resp => {
    //       commit('getHazards', resp.data)
    //       resolve(resp)
    //     }).catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    // getHazards({commit}, data) {
    //   return new Promise((resolve, reject) => {
    //     axios.get('hazards/' + data.id)
    //     .then(resp => {
    //       commit('getHazards', resp.data)
    //       resolve(resp)
    //     }).catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    getHazardTypes({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('hazardtypes')
          .then(resp => {
            commit('getHazardTypes', Object.values(resp.data))
            resolve(resp)
          }).catch(err => {
            reject(err)
          })
      })
    },
    // getFootprints({commit}, data) {
    //   return new Promise((resolve, reject) => {
    //     axios.get('footprints/' + data.id)
    //     .then(resp => {
    //       commit('getFootprints', Object.values(resp.data))
    //       resolve(resp)
    //     }).catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    // getUsers({commit}) {
    //   return new Promise((resolve, reject) => {
    //     axios.get('users')
    //     .then(resp => {
    //       commit('getUsers', Object.values(resp.data))
    //       resolve(resp)
    //     })
    //     .catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    // getUserLocations({commit}) {
    //   return new Promise((resolve, reject) => {
    //     axios.get('user_locations')
    //     .then(resp => {
    //       commit('getUserLocations', Object.values(resp.data))
    //       resolve(resp)
    //     })
    //     .catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    // getUserTypes({commit}) {
    //   return new Promise((resolve, reject) => {
    //     axios.get('usertypes')
    //     .then(resp => {
    //       commit('getUserTypes', Object.values(resp.data))
    //       resolve(resp)
    //     }).catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    getCurrency() {
      return new Promise((resolve, reject) => {
        axios.get('currency')
          .then(resp => {
            resolve(resp)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getFootprintList({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('footprint_list')
          .then(resp => {
            commit('getFootprintList', Object.values(resp.data))
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // getSpace() {
    //   return new Promise((resolve, reject) => {
    //     axios.get('space')
    //     .then(resp => {
    //       resolve(resp)
    //     })
    //     .catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    // getDemoLayers({commit}, payload) {
    //   return new Promise((resolve, reject) => {
    //     axios.get('demo_layers/' + payload.id)
    //     .then(resp => {
    //       commit('getDemoLayers', resp.data)
    //       resolve(resp)
    //     }).catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    // getDemoHazards({commit}, data) {
    //   return new Promise((resolve, reject) => {
    //     axios.get('demo_hazards/' + data.id)
    //     .then(resp => {
    //       commit('getDemoHazards', resp.data)
    //       resolve(resp)
    //     }).catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    // createDemoHazard({commit}, data) {
    //   return new Promise((resolve, reject) => {
    //     axios.post('demo_hazards', data)
    //     .then(resp => {
    //       resolve(resp)
    //     })
    //     .catch(err => {
    //       commit('auth_error', err)
    //       reject(err)
    //     })
    //   })
    // },
    // getDemoUserReports({commit}) {
    //   return new Promise((resolve, reject) => {
    //     axios.get('demo_user_reports')
    //     .then(resp => {
    //       commit('getDemoUserReports', resp.data)
    //       resolve(resp)
    //     }).catch(err => {
    //       reject(err)
    //     })
    //   })
    // },
    testMailChimp() {
      return new Promise((resolve, reject) => {
        axios.get('mailchimp_ping')
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getMailChimpSubscriptions({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('mailchimp_subscriptions')
          .then(resp => {
            commit('getMailChimpSubscriptions', resp.data)
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    toggleNavDrawer({ commit }) {
      commit('toggleNavDrawer')
    },
    verifyKey(_, userkey) {      
      return new Promise((resolve, reject) => {
        axios.post('validate_key/', userkey)
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    forgotPassword(_, data) {
      return new Promise((resolve, reject) => {
        axios.post('forgot_password/', data)        
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    resetPassword(_, data) {
      return new Promise((resolve, reject) => {
        axios.put('reset_password/', data)
        .then(resp => {   
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    }
  },
  getters: {
    getTab: state => {
      return state.tab
    },
  },

  modules: {
    admin,
    customerservice,
    demo,
    map,
    spot_mapper,
    ras,
    user,
    geopost,
    discovery,
    access_points,
    leaderboard,
    // test
  }
})