<template>
<v-container>
    <v-avatar class="ma-2" @click="showCard">
        <v-img         
            v-if="user.profile_image"        
            :src="($apiEndpointUrl + image_start + user.profile_image)" 
            alt="Avatar"/>
        <v-icon v-else large>mdi-account</v-icon>
    </v-avatar>

    <v-dialog v-model="image_overlay" max-width="1000">
    <v-card class="pa-4">
        <v-row >
            <v-spacer></v-spacer>
            <v-card-actions class="pb-0">
                <v-icon
                    large
                    @click="image_overlay = false"
                >mdi-close-circle
                </v-icon>                
            </v-card-actions>
        </v-row>

        <v-row>            
            <v-col cols="12" sm="4">
                <v-card-text class="text-center">         
                    <v-avatar class="ma-2 justify-center" size="128">
                        <v-img         
                            v-if="current_avatar"        
                            :src="($apiEndpointUrl + image_start + current_avatar)"
                            alt="Avatar"/>
                        <v-icon v-else large>mdi-account</v-icon>
                    </v-avatar> 
                    <v-card-title class="justify-center"><h2>{{ card_name }}</h2></v-card-title>

                    <v-row no-gutters>
                        <v-col>
                            Joined: {{ card_date }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            Regions: {{ card_region }}
                        </v-col>
                    </v-row>  
                </v-card-text> 
            </v-col>   
            <v-col cols="12" sm="8">
                <v-card class="pb-4" :height="info_height" outlined>
                    <v-tabs
                        fixed-tabs
                        bg-color="primary"
                        class="mb-5"
                    >
                        <v-tab v-if="!$vuetify.breakpoint.xs">Competitions</v-tab>
                        <v-tab v-else><v-icon>mdi-trophy-outline</v-icon></v-tab>
                        <v-tab v-if="!$vuetify.breakpoint.xs">Catches</v-tab>
                        <v-tab v-else><v-icon>mdi-fish</v-icon></v-tab>
                    
                        <v-tab-item class="mt-4" align="center">
                            <v-row align="center">
                                <v-col cols="12" sm="6" class="d-flex">
                                    <v-card-text class="py-0">
                                        <div>Total Completed Competitions</div>
                                        <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card.total }}</p>
                                        <p v-else :class="font_size_value">-</p>                                            
                                    </v-card-text>                    
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <v-row>
                                        <v-col cols="4" sm="12">
                                            {{ profile_card.firsts }}
                                            <v-icon color="yellow" large>mdi-trophy</v-icon>
                                            <nobr v-if="!$vuetify.breakpoint.xs"> 1st Place Finishes</nobr>
                                        </v-col>
                                        <v-col cols="4" sm="12">
                                            {{ profile_card.seconds }}
                                            <v-icon color="grey" large>mdi-trophy</v-icon>
                                            <nobr v-if="!$vuetify.breakpoint.xs"> 2nd Place Finishes</nobr>
                                        </v-col>
                                        <v-col cols="4" sm="12">
                                            {{ profile_card.thirds }}
                                            <v-icon color="brown" large>mdi-trophy</v-icon>
                                            <nobr v-if="!$vuetify.breakpoint.xs"> 3rd Place Finishes</nobr>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item>
                            <v-row class="mt-4 mx-4">
                                <v-autocomplete
                                    item-text="common_name"
                                    item-value="id"
                                    hide-details="auto"
                                    outlined
                                    dense
                                    label="Species"
                                    v-model="selected_species"
                                    :items="species"
                                    return-object
                                    :onchange="getCardSpeciesInfo()"
                                ></v-autocomplete>                                    
                            </v-row>

                            <v-row>
                                <v-col cols="6" sm="6">
                                    <v-card-text class="py-0">
                                        <div>Total Caught</div>
                                        <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card_species.total }}</p>
                                        <p v-else :class="font_size_value">0</p>                                            
                                    </v-card-text>                    
                                </v-col>
                                <v-col cols="6" sm="6">
                                    <v-card-text class="py-0">
                                        <div>Longest Caught</div>
                                        <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card_species.longest }} cm</p>
                                        <p v-else :class="font_size_value">-</p>                        
                                    </v-card-text>   
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6" sm="6">
                                    <v-card-text class="py-0">
                                        <div>Heaviest Caught</div>
                                        <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card_species.heaviest }} lbs.</p>
                                        <p v-else :class="font_size_value">-</p>                                            
                                    </v-card-text>                    
                                </v-col>
                                <v-col cols="6" sm="6">
                                    <v-card-text class="py-0">
                                        <div>Average Caught per Fishing Day</div>
                                        <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ Math.round((profile_card_species.total/profile_card_species.days)*100) / 100 }}</p>
                                        <p v-else :class="font_size_value">-</p>                        
                                    </v-card-text>   
                                </v-col>
                            </v-row>                                
                        </v-tab-item>
                    </v-tabs>                          
                </v-card>
            </v-col>    
            <v-col cols="12" sm="4">
                <v-card class="pb-4" :height="bio_height" outlined>
                    <v-card-title class="pb-0">
                        <h5>Bio</h5>  
                        <v-card-text class="px-0">{{ card_bio }}</v-card-text>                          
                    </v-card-title>
                </v-card>
            </v-col>          
            <v-col cols="12" sm="8">
                <v-card class="pb-4" height="200px" outlined>
                    <v-card-title class="pb-0">
                        <h5>Badges</h5>   
                    </v-card-title>
                    <!-- <v-row class="justify-center py-8">
                        <v-slide-group class="pa-1" show-arrows>
                            <v-slide-item><v-img contain src="/img/test_badge.png" max-height="100px" max-width="100px"/></v-slide-item>
                            <v-slide-item><v-img contain src="/img/badge2.png" max-height="100px" max-width="100px"/></v-slide-item>
                            <v-slide-item><v-img contain src="/img/badge3.png" max-height="100px" max-width="100px"/></v-slide-item>
                            <v-slide-item><v-img contain src="/img/badge4.png" max-height="100px" max-width="100px"/></v-slide-item>
                            <v-slide-item><v-img contain src="/img/badge5.png" max-height="100px" max-width="100px"/></v-slide-item>                      
                        </v-slide-group>
                    </v-row> -->
                </v-card>
            </v-col>                       
        </v-row>
        
    </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('user',{
            user: state => state.user,
            profile_card: state => state.profile_card,
            profile_card_species: state => state.profile_card_species,
        }),
        ...mapState('spot_mapper',{
            species: state => state.species,
        }),
        font_size_title(){
            return !this.$vuetify.breakpoint.xs ? "text-h4 mb-0" : "text-h6 mb-0"
        },
        font_size_value(){
            return !this.$vuetify.breakpoint.xs ? "text-h5" : "text-h6"
        },
        set_gutters(){
            return !this.$vuetify.breakpoint.xs ? false : true
        },
        set_padding_margins(){
            return !this.$vuetify.breakpoint.xs ? "px-4 pt-6 ma-3 pb-0" : "ma-3"
        },
        info_height(){
            return !this.$vuetify.breakpoint.xs ? "300px" : "310px"
        },
        bio_height(){
            return !this.$vuetify.breakpoint.xs ? "200px" : "auto"
        }
    },
    data() {
        return {
            image_overlay: false,
            image_start: 'profile_image/',
            zIndex: 1,
            join_date: null,

            card_mode: 1,
            mode_options: [
                { text: 'Competition', value: 1},
                { text: 'Species', value: 2},
            ],
            card_name: null,
            card_date: null,
            card_region: null,
            card_bio: null,
            selected_species: null,
            current_species: null,
            current_avatar: null,
            }
    },
    methods: {
        showCard(){
            // console.log(this.user)
            // this.fixed_leaderboard.map((item, index) => {
            //     item.selected = item === row

            //     this.$set(this.fixed_leaderboard, index, item)
            // })

            this.selected_species = null
            this.current_species = null
            this.card_mode = 1
            this.$store.dispatch('user/getProfileCard', this.user.id)
            .then(() => {
                this.card_name = this.profile_card.profile.name
                this.card_date = new Date(this.profile_card.profile.tos_accepted).toLocaleDateString('en-us', { month:"long", day:"numeric", year:"numeric"})
                this.card_region = this.profile_card.profile.region
                this.card_bio = this.profile_card.profile.bio

                this.$store.dispatch('spot_mapper/getSpecies')
                .then(() => {
                    // console.log(this.species)
                    this.image_overlay = true
                })
            })
            this.current_avatar = this.user.profile_image
        },
        getCardSpeciesInfo(){
            if(this.selected_species!==null && !this.loading){
                if(this.current_species!==this.selected_species.id){
                    this.loading = true
                    let user = this.user.id
                    let species = this.selected_species.id
                    this.$store.dispatch('user/getProfileCardSpecies', { user, species})
                    .then(() => {
                        // console.log(this.profile_card_species)
                        this.current_species = this.selected_species.id
                        this.loading = false
                    })
                }
            }
        },
    },
    mounted(){
        // console.log(this.user)
    }
}
</script>

<style scoped>

</style>