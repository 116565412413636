<template>
  <span>
    <l-control 
      v-show="isLoggedIn && !hide_buttons"
      class="custom-control" 
      position="topright"      
    >
      <span class="text-body-2 mx-2 mt-2" v-if="hazardMarkerMode == 1 && !is_mobile">
        {{ center.lat }}, {{ center.lng }}
      </span>
      <v-tooltip left :disabled=is_mobile v-model="help">
        <template v-slot:activator="{ on, attrs }">
          <!-- Trial Period  -->
          <v-btn
            icon @click.stop="clickHazardIcon"
            :color="hazardMarkerMode ? 'deep-orange':'teal'"
            v-bind="attrs"
            v-on="on"
          >          
          <!-- <v-btn
            icon @click.stop="clickHazardIcon"
            :color="hazardMarkerMode ? 'deep-orange':'teal'"
            :disabled="lockMapOn || ((!isProUser || !isAdmin)  && visibleLayer.id)"
            v-bind="attrs"
            v-on="on"
          > -->
            <v-icon >mdi-alert</v-icon>
          </v-btn>
          <!-- <span class="text-body-2 mx-2 mt-2" v-if="hazardMarkerMode == 1">
            {{ center.lat }}, {{ center.lng }}
          </span>-->
        </template>
        <span v-if="!hazardMarkerMode">Toggle Hazard Creation</span>
        <span v-if="hazardMarkerMode">Exit Hazard Creation</span>
      </v-tooltip>
    </l-control>
    <l-marker v-if="hazardMarkerMode" ref="hazardMarker" z-index="10000" :lat-lng="[center.lat,center.lng]" :icon="centerMarker">
      <l-popup class="py-2">
        <span class="text-body-1">Place new hazard icon here?</span>
        <v-row class="mt-1">
          <v-spacer />
          <v-btn class="text-caption ma-2" x-small dark color="blue darken-1" @click.prevent="clickHazardButton">Confirm</v-btn>
        </v-row>
      </l-popup>
    </l-marker>
    <v-dialog 
      v-model="hazardMenuDialog" 
      :fullscreen="is_mobile"
      max-width="500px" 
      transition="dialog-bottom-transition"
      mode="out-in"
      hide-overlay
      no-click-animation
      persistent      
    >
      <v-card class="pb-4">
        <v-card-actions class="pt-4">
          <v-spacer></v-spacer>
          <v-btn @click="cancelHazard" text class="grey--text">
            Close
            <v-icon right color="grey">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>         
        <v-divider></v-divider>        
        <v-card-title class="pb-1 mb-0">Add Hazard Icon</v-card-title>
        <v-card-subtitle class="py-1 my-0 px-4 d-flex align-center">
          <v-icon class="ml-1 mr-2" small>fa-crosshairs</v-icon>
            {{ center.lat }}, {{ center.lng }}
        </v-card-subtitle>
        <v-container>
          <v-form ref="form" lazy-validation>
            <v-select
              class="pb-1 mb-0 mx-1"
              v-if="hazardItems.length > 0"
              v-model="selectedHazard"
              :items="hazardItems"
              label="Hazard Type"
              @change="selectChanged"
              outlined
              read-only
              required
              :rules="[checkSelectedHazard]"
            >
              <template v-if="user.status == 1" v-slot:selection="{ item }">
                <img :src="'/img/hazard_icons/user_' + item.icon" aspect-ratio="1" width="40" class="mr-3">{{ item.name }}
              </template>
              <template v-else-if="user.status == 2" v-slot:selection="{ item }">
                <img :src="'/img/hazard_icons/pro_' + item.icon" aspect-ratio="1" width="40" class="mr-3">{{ item.name }}
              </template>
              <template v-else-if="user.status == 9" v-slot:selection="{ item }">
                <img :src="'/img/hazard_icons/admin_' + item.icon" aspect-ratio="1" width="40" class="mr-3">{{ item.name }}
              </template>

              <template v-if="user.status == 1" v-slot:item="{ item }">
                <img :src="'/img/hazard_icons/user_' + item.icon" aspect-ratio="1" width="40" class="mr-3">{{ item.name }}
              </template>
              <template v-else-if="user.status == 2" v-slot:item="{ item }">
                <img :src="'/img/hazard_icons/pro_' + item.icon" aspect-ratio="1" width="40" class="mr-3">{{ item.name }}
              </template>
              <template v-else-if="user.status == 9" v-slot:item="{ item }">
                <img :src="'/img/hazard_icons/admin_' + item.icon" aspect-ratio="1" width="40" class="mr-3">{{ item.name }}
              </template>
            </v-select>
            <v-select
              class="pb-1 mb-0 mx-1"
              v-model="selectedRegion"
              :items="regions"
              item-text="name"
              label="Region"
              outlined
              read-only
              required      
              dense      
            />     
            <v-text-field
              class="pb-1 mb-0 mx-1"
              label="Add Hazard To"
              :value="layerText"
              outlined
              readonly
              filled
              dense
              hide-details
            />
            <!-- <v-select
              v-model="selectedLayer"
              :items="layers"
              :item-text="item => item.date + ' - ' + item.name"
              item-value="id"
              label="Assocaited Layer"
              outlined
              read-only
              required         
            /> -->
            <!-- <v-select
              v-if="this.itemsWithExpiry.includes(this.selectedHazard)"
              v-model="selectedExpiry"
              label="Expiry"
              :items="expiryItems"
              outlined
              read-only
            /> -->
            <v-select
              class="pb-1 mt-6 mx-1"
              v-if="this.selectedHazard == 16"
              v-model="selectedCandling"
              :items="candlingItems"
              label="Seep Level"
              outlined
              read-only
              dense
              hide-details              
            />
            <v-row align="center">
              <v-col cols="5" sm="6">
                <v-text-field
                  v-if="this.itemsWithMeasurement.includes(this.selectedHazard)"
                  class="mt-6 ml-1"
                  v-model="iconValue" 
                  :rules="[checkNumericValue]"
                  type="number" 
                  label="Measure"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="7" sm="6">
                <v-radio-group
                  v-if="this.itemsWithMeasurement.includes(this.selectedHazard)"
                  v-model="iconUnitMeasure" 
                  mandatory
                  row
                  hide-details
                >
                  <v-spacer />
                  <v-radio label="Metres" value="m" />
                  <v-radio label="Inches" value="in" />
                  <v-spacer />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-textarea
              class="mt-6 mx-1"
              v-if="this.itemsWithDescription.includes(this.selectedHazard)"
              v-model="iconNote" 
              :rules="[checkDescription]"
              label="Description"
              rows="4"
              outlined
              autogrow
              hide-details
            />
            <v-text-field
              class="mt-6 mx-1"
              v-if="newAdvisory && this.advisoryData"
              label="Adding to Advisory"
              :value="this.advisoryData.date + ' - ' + this.advisoryData.title"
              readonly
              filled
              outlined
              dense
              hide-details
            />                
            <v-text-field
              class="mt-6 mx-1"
              v-if="newAdvisory && !this.advisoryData"
              v-model="advisoryTitle" 
              :rules="[checkAdvisoryTitle]"
              label="Advisory Title"
              outlined
              dense
            />            
            <v-textarea
              class="mt-2 mx-1"
              v-if="newAdvisory && !this.advisoryData"
              v-model="advisoryDescriptionUnsub" 
              :rules="[checkAdvisoryDescriptionUnsub]"
              label="Description (Unsubscribed)"
              rows="4"
              outlined
              autogrow
              counter
              maxlength="255"
            />
            <v-textarea
              class="mt-2 mx-1"
              v-if="newAdvisory && !this.advisoryData"
              v-model="advisoryDescriptionSub" 
              :rules="[checkAdvisoryDescriptionSub]"
              label="Description (Subscribed)"
              rows="4"
              outlined
              autogrow
              counter
              maxlength="65535"
            />       
          </v-form>
        </v-container>
        <v-card-actions class="px-4">
          <v-spacer />
          <v-checkbox
            v-if="this.isAdmin && this.visibleLayer.id"
            v-model="newAdvisory"
            :label="advisoryText"
            color="success"
            inset
          />   
          <v-divider 
            v-if="this.isAdmin"
            class="mx-4"
            inset
            vertical
          />                 
          <v-switch
            v-if="this.itemsWithPrivacy.includes(this.selectedHazard)"
            v-model="iconIsPublic"
            :label="`${iconIsPublic ? 'Public':'Private'}`"
            color="success"
            inset
          />
          <v-divider 
            v-if="this.itemsWithPrivacy.includes(this.selectedHazard)"
            class="mx-4"
            inset
            vertical
          />
          <!-- <v-btn
            color="blue darken-1" 
            text
            @click="cancelHazard"
          >
            Cancel
          </v-btn> -->
          <v-btn 
            color="blue darken-1" 
            dark
            @click.prevent="createHazard"
            x-small
          >
            Create
          </v-btn>
        </v-card-actions>
        
        <v-alert v-if="infoMessage !== ''" type="info" class="mt-8">
          {{ infoMessage }}
        </v-alert>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { LControl, LMarker, LPopup, } from 'vue2-leaflet'
import { icon } from 'leaflet';

export default {
  components: {
    LControl,
    LMarker,
    LPopup,
  },
  computed: {
    ...mapState(['regions']),
    ...mapGetters('user', { 
      user: state => state.user,
      isAdmin: 'isAdmin',
      isLoggedIn: 'isLoggedIn',
      isProUser: 'isProUser',
    }),
    ...mapState('map', {
      // hazardtypes: state => state.hazardtypes,
      footprints: state => state.footprints,
      lockMapOn: state => state.lockMapOn,
      visibleLayer: state => state.visibleLayer,
      current_region: state => state.current_region,
      current_footprint: state => state.current_footprint,
      layers: state => state.layers,
      hide_buttons: state => state.hide_buttons,
      showHelp: state => state.showHelp,
    }),
    ...mapState('user', {
      user: state => state.user
    }),
    // ...mapState(['hazardtypes']),
    checkDescription() {
      return () => this.iconNote.length > 0 || "Note description cannot be empty";
    },
    checkAdvisoryTitle() {
      return () => this.advisoryTitle.length > 0 || "Advisory title cannot be empty";
    },    
    checkAdvisoryDescriptionUnsub() {
      return () => this.advisoryDescriptionUnsub.length > 0 || "Unsubscribed description cannot be empty";
    },
    checkAdvisoryDescriptionSub() {
      return () => this.advisoryDescriptionSub.length > 0 || "Subscribed description cannot be empty";
    },        
    checkNumericValue() {
      return () => isNaN(Number.parseFloat(this.iconValue)) == 0 || "Measurement must be numeric";
    },
    checkSelectedHazard() {
      return () => this.selectedHazard.id && this.selectedHazard.id > 0 || "Select the type of hazard to create";
    },  
    // checkSelectedLayer() {
    //   return () => this.selectedLayer && this.selectedLayer > 0 || "Select the layer to associate to the hazard";
    // },      
    checkUnitMeasure() {
      return () => this.iconUnitMeasure || "Please select your unit of measure.";
    },
    is_mobile() {
      return this.$vuetify.breakpoint.mobile ? this.showHelp ? false : true : false
    }  
  },
  watch: {
    showHelp() {
      this.help = this.showHelp
    },
  },  
  created() {
    this.$store.dispatch('getHazardTypes')
    .then((resp) => {
      let hazardtypes = resp.data
      
      // Set the hazard list depends on current user status
      if (this.user.status == 1) {
        this.userItems = hazardtypes.filter(function(el) {
          return el.user_order != null
        })
        this.userItems.sort(function(a, b) {
          return parseInt(a.user_order) - parseInt(b.user_order)
        })
        this.hazardItems = this.userItems
        this.user_status_string = "user"
      } else if (this.user.status == 2) {
          this.proItems =  hazardtypes.filter(function(el) {
          return el.pro_order != null
        })
        this.proItems.sort(function(a, b) {
          return parseInt(a.pro_order) - parseInt(b.pro_order)
        })
        this.hazardItems = this.proItems
        this.user_status_string = "pro"
      } else if (this.user.status == 9) {
        this.adminItems = hazardtypes.filter(function(el) {
          return el.admin_order != null
        })
        this.adminItems.sort(function(a, b) {
          return parseInt(a.admin_order) - parseInt(b.admin_order)
        })
        this.hazardItems = this.adminItems;
        this.user_status_string = "admin"
      }
    })
  },
  data() {
    return {
      adminItems: [],
      candlingItems: [
        { text: '1/4', value: 0.25 },
        { text: '1/2', value: 0.50 },
        { text: '3/4', value: 0.75 }
      ],
      centerMarker: new icon ({
        iconUrl: "/img/crosshairs.png",
        iconSize: [64,64],
        iconAnchor: [32,64]
      }),
      // expiryItems: [
      //   { text: 'After 1 Day', value: 1 },
      //   { text: 'After 2 Days', value: 2 },
      //   { text: 'After 3 Days', value: 3 },
      //   { text: 'After 4 Days', value: 4 },
      //   { text: 'After 5 Days', value: 5 },
      //   { text: 'After 6 Days', value: 6 },
      //   { text: 'After 7 Days (default)', value: 7 },
      // ],
      hazardCreateColor: 'teal',
      hazardCreateDialog: false,
      hazardItemGroups: [ 
        { text: 'User', value: 1 },
        { text: 'Pro', value: 2 },
        { text: 'Admin', value: 9 }
      ],
      hazardItems: [],
      hazardMarkerColor: 'icetimeblue',
      hazardMarkerMode: false,
      hazardMenuDialog: false,
      hazardMenuMarker: '',
      help: false,
      iconIsPublic: true,
      iconNote: '',
      iconUnitMeasure: '',
      iconValue: '',
      infoMessage: '',
      itemsWithDescription: ['14'],
      itemsWithExpiry: ['1', '4', '5', '7', '13', '14', '15'],
      itemsWithMeasurement: ['1', '2'],
      itemsWithPrivacy: ['2', '11', '14'],
      proItems: [],
      selectedCandling: '',
      selectedExpiry: 7, // default 1 for demo
      selectedHazard: '',
      userItems: [],
      footprintItems: [],
      selectedLayer: '',
      selectedRegion: '',
      layerText: '',
      newAdvisory: false,

      advisoryData: null,
      advisoryTitle: '',
      advisoryDescriptionUnsub: '',
      advisoryDescriptionSub: '',
      advisoryText: '',
    }
  },
  methods: {
    cancelHazard() {
      this.hazardMenuDialog = false
      this.selectChanged()
    },
    clickHazardButton() {

      this.selectedHazard = ''

      var radar_id = this.current_region && this.current_region.radar_id ? this.current_region.radar_id:this.current_region

      this.selectedRegion = this.regions.find(region => {return region.radar_id === radar_id})

      // this.$store.dispatch('map/getFootprints', {id: radar_id})
      // this.footprintItems = this.footprints
      if(this.visibleLayer.id) {
        this.layerText = this.visibleLayer.date + ' - ' + this.visibleLayer.name
      }
      else {
        this.layerText = 'Region Map'
      }
      
      // this.selectedLayer = this.visibleLayer.id

      this.$refs.hazardMarker.mapObject.closePopup()

      if (this.isAdmin) {
        this.hazardMenuDialog = true

        if (this.visibleLayer.id) {
          // TODO Check for existing advisory on image, < current date and time
          
          this.$store.dispatch('ras/getAdvisory', this.visibleLayer.id)
          .then(resp => { 
            // console.log(resp.data)
            this.advisoryData = resp.data ? resp.data : null
            this.advisoryText = resp.data ? 'Add to Existing Advisory' : 'Create New Advisory'
          })
        }

      } else if (this.isLoggedIn) {
        this.hazardItems = this.userItems
        this.hazardMenuDialog = true
      }
    },
    clickHazardIcon() {
      if (this.hazardMarkerMode == 0) {
        this.hazardMarkerMode = 1
      this.hazardMarkerColor = 'green'
      } else {
        this.hazardMarkerMode = 0
        this.hazardMarkerColor = 'silver'
      }
    },
    createHazard() {      
      this.hazardCreateDialog = false
      if (this.$refs.form.validate()) {
        this.infoMessage = "Creating hazard ..."

        // Adjust the iconValue being passed
        if (this.itemsWithMeasurement.includes(this.selectedHazard)) {
          this.iconValue = this.iconUnitMeasure == "m" ? this.iconValue:(this.iconValue / 39.3701)
        } else if (this.selectedHazard == 16) {
          this.iconValue = this.selectedCandling;
        } else if (this.selectedHazard == 14) {
          this.iconValue = this.iconNote;
        } else {
          this.iconValue = '';
        }

        var selected_layer_id = this.visibleLayer.id ? this.visibleLayer.id : 'NULL'
        // var selected_layer_id = this.selectedLayer ? this.selectedLayer : 'NULL'

        // Build data payload
        let data = {
            // radar_id: this.visibleLayer.map_id, 
            radar_id: this.selectedRegion.radar_id,
            user_id: this.user.id,
            usertype_id: this.user.status,
            hazardtype_id: this.selectedHazard.id,
            is_public: this.iconIsPublic == true ? 1:0,
            value: this.iconValue,
            expiration_days: this.selectedExpiry,
            lat: this.center.lat,
            lng: this.center.lng,
            layer_id: selected_layer_id
        }        

        // HTTP to API
        this.$store.dispatch('map/createHazard', data)
        .then(() => {
          this.clickHazardIcon() // turns off hazard mode
          this.infoMessage = "Hazard has been recorded, thank you!"
          // this.populatePins()
          this.$emit('pins-changed')
          setTimeout(() => {
            this.infoMessage = "";
            this.hazardMenuDialog = false;
            this.selectChanged();
          }, 1500);
        })
        .catch((err) => {
          if (err.response.status == '403') {
            this.$store.dispatch('logout')
            this.$store.dispatch('setDialog', { 
              show: true, 
              message: err.response.data, 
              status: err.response.status
            })
          } else {
            this.infoMessage = err.response.data
            setTimeout(() => {
              this.infoMessage = "";
              this.hazardMenuDialog = false;
              this.selectChanged();
            }, 3000);
          }
        })

        if (this.newAdvisory) {
          // Add new advisory          

          let post_data = {
            footprint_id: this.visibleLayer.footprint_id,
            layer_id: this.visibleLayer.id,            
            title: this.advisoryTitle,
            ras_description: this.advisoryDescriptionUnsub,
            description: this.advisoryDescriptionSub,
            date: new Date().toISOString().split('T')[0],
          }
          if (this.advisoryData) {
            // console.log('Updating Advisory')
            post_data.id = this.advisoryData.id
            if (this.advisoryData.hazards == '') {
              post_data.hazards = this.selectedHazard.name.toString()
            }
            else {
              post_data.hazards = this.advisoryData.hazards + ',' + this.selectedHazard.name.toString()
            }            
            this.$store.dispatch('ras/updateManualAdvisory', post_data)
          }
          else {
            // console.log('Creating New Advisory')
            post_data.hazards = this.selectedHazard.name.toString()
            this.$store.dispatch('ras/createManualAdvisory', post_data)
          }
          // console.log(post_data)
          // console.log(this.visibleLayer)
          this.advisoryTitle = '',
          this.advisoryDescriptionUnsub = '',
          this.advisoryDescriptionSub = '',          
          this.newAdvisory = false
        }
      }
    },
    hazardItemGroupSelected() {
      this.selectedHazard = ''
      if (this.selectedHazardItemGroup == 1) {
        this.hazardItems = this.userItems
      } else if (this.selectedHazardItemGroup == 2) {
        this.hazardItems = this.proItems
      } else if (this.selectedHazardItemGroup == 9) {
        this.hazardItems = this.adminItems
      }
    },
    selectChanged() {
      this.selectedExpiry = 7; // default 1 for demo
      this.selectedCandling = '';
      this.iconNote = '';
      this.iconValue = '';
      this.iconUnitMeasure = '';
      this.iconValue = '';
    },
    toggle() {
      this.$store.dispatch('demo/toggleHazardMarkerTool')
      // this.$emit('hazard-marker-mode-changed')
    },
  },
  props: ['center']
}
</script>

<style scoped>
>>>.v-dialog {
  position: absolute;
  bottom: 0;
  left: 0;
}

>>>.card-outter {
  position: relative;
  padding-bottom: 1.5em;
}

>>>.card-actions {
  position: absolute;
  top: 0;
  right:0;
}
</style>