<template>
  <span>
    <l-control v-if="visibleLayer && report" class="custom-control gps-control" position="topright">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon 
            @click="toggle"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon :color="markerColor">mdi-information-outline</v-icon>
          </v-btn>
        </template>
        <span>Lake Ice Report</span>
      </v-tooltip>
    </l-control>
    <v-dialog v-if="report" v-model="infoDialog" scrollable max-width="500px" width="unset">
      <v-card class="card-outter pt-16 pb-9" style="overflow-y: auto;">
        <v-divider></v-divider>
        <v-card-title class="pt-7"><v-chip class="mr-2">{{ visibleLayer.date }}</v-chip>Ice Report <v-icon v-if="visibleLayer.status == 0" class="ml-2">mdi-eye-off</v-icon></v-card-title>
        <v-container v-if="report" class="pb-0" >
          <v-card-text v-if="report.title" class="font-weight-bold pt-2">{{ report.title ? report.title:'No report title!' }}</v-card-text>
          <v-card-text v-if="report.description" class="pb-0" style="white-space: pre-line">{{ report.description ? report.description:'No description!' }}</v-card-text>
        </v-container>
        <v-container v-else class="pb-0">
          <v-card-title>No information found for {{ visibleLayer.date }}</v-card-title>
        </v-container>
        <v-card-actions class="card-actions pt-4">
          <v-btn @click.prevent="infoDialog = false" text class="grey--text">
            Close
            <v-icon right color="grey">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { LControl } from 'vue2-leaflet'
import { mapState } from 'vuex'

export default {
  components: {
    LControl
  },
  computed: {
    ...mapState('demo', {
      visibleLayer: state => state.visibleLayer,
      demo_user_reports: state => state.demo_user_reports
    }),
    report() {
      if (this.demo_user_reports.length > 0) {
        return this.demo_user_reports.find(x => {
          return x.layer_id == this.visibleLayer.id
        })
      } else {
        return null
      }
    },
  },
  data() {
    return {
      infoDialog: false,
      markerColor: 'deep-orange',
    }
  },
  methods: {
    toggle() {
      this.infoDialog = true
    },
  },
}
</script>

<style scoped>
>>>.v-dialog {
  position: absolute;
  bottom: 0;
  right: 0;
}

>>>.card-outter {
  position: relative;
  padding-bottom: 1.5em;
}

>>>.card-actions {
  position: absolute;
  top: 0;
  right:0;
}
</style>