<template>
  <v-container>
    <p class="text-h5 pl-5 pt-8"><v-icon class="mr-3" color="teal">mdi-shimmer</v-icon>Fast Facts</p>
    <v-text-field 
      color="teal"
      class="px-4 pb-4 text-h6"
      v-model="search_string"
      prepend-inner-icon="mdi-file-search"
      placeholder="Search Fast Facts"
    />
    <v-layout class="px-4" cols="12" row>
      <v-flex v-for="fact in filtered_dyk" :key="fact.index" class="pa-4" xs12 sm6 xl4>
        <v-card class="pa-3 mb-12 card-outter" height="100%">
          <v-img 
            :src="fact.filename"
            class="mx-3 mb-4 mt-0"
          />
          <v-card-title class="text-h6">
            Fast Fact #{{ fact.filename.split('_')[1].split('.')[0] }}
          </v-card-title>
          <v-card-subtitle class="text-body-1">
            {{ fact.text }}
          </v-card-subtitle>
          <v-card-actions class="card-actions">
            <v-spacer />
            <v-btn :href="fact.filename" target="_blank" width="100%">Download Image</v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {
      dyk: [
        {
          filename: '/img/power/ff/fastfact_1.png',
          text: "In Ice Time, you can watch the ice move using time series images."
        },
        {
          filename: '/img/power/ff/fastfact_2.png',
          text: "Use Ice Time to confirm that the ice has stopped moving by comparing a sequence of radar images."
        },
        {
          filename: '/img/power/ff/fastfact_3.png',
          text: "You can see open water in the offshore zone of frozen lakes easily from home with Ice Time."
        },
        {
          filename: '/img/power/ff/fastfact_4.png',
          text: "Driving into open water in the offshore zone is one of the main causes of snowmobiling fatalities on lake ice."
        },
        {
          filename: '/img/power/ff/fastfact_5.png',
          text: "In most lakes, the ice forms in stages. Each stage has a different age and thickness that persists into the winter."
        },
        {
          filename: '/img/power/ff/fastfact_6.png',
          text: "Use Ice Time to see the boundary between ice sheets of different ages. Now you know precisely where the risk increases and the thickness changes."
        },
        {
          filename: '/img/power/ff/fastfact_7.png',
          text: "Your spud bar and an Ice Time map that shows boundaries between ice sheets of different ages helps you focus your effort where changes in ice thickness can be anticipated, often far from shore."
        },
        {
          filename: '/img/power/ff/fastfact_8.png',
          text: "Ice Time's satellite radar technology can map pressure ridges in lake ice before the ridge emerges through the snow."
        },
        {
          filename: '/img/power/ff/fastfact_9.png',
          text: "Pressure ridges  are compressional ice deformations that result from ice expansion."
        },
        {
          filename: '/img/power/ff/fastfact_10.png',
          text: "Pressure ridges have a 'sail' above the ice and a 'keel'. The keel is often larger than the sail"
        },
        {
          filename: '/img/power/ff/fastfact_11.png',
          text: "Pressure ridges crack, flood, and deform ice beside them, at least 2-3x the height of the ridge."
        },
        {
          filename: '/img/power/ff/fastfact_12.png',
          text: "Pressure ridges usually form after the lake ice has locked up from shore to shore."
        },
        {
          filename: '/img/power/ff/fastfact_13.png',
          text: "The collapse of the pressure ridge sails after melt onset creates the first open water of the year."
        },
        {
          filename: '/img/power/ff/fastfact_14.png',
          text: "Pressure ridges form barriers to travel and are sites of vehicle collision in poor weather or at night."
        },
        {
          filename: '/img/power/ff/fastfact_15.png',
          text: "Pressure ridges are always changing. They uplift and collapse all winter long."
        },
        {
          filename: '/img/power/ff/fastfact_16.png',
          text: "Pressure ridges crack open and let lake water seep into the snow pack beside it, creating a hidden slush hazard."
        },
        {
          filename: '/img/power/ff/fastfact_17.png',
          text: "An Ice Time map of pressure ridges made in winter shows, after melt onset,  where the first axes of open water of the year will be."
        },
        {
          filename: '/img/power/ff/fastfact_18.png',
          text: "Ice Time's satellite radar technology can detect and map white ice through the snow in the winter."
        },
        {
          filename: '/img/power/ff/fastfact_19.png',
          text: "White ice forms when your lake starts to freeze up. White ice usually forms from refrozen slush and pancake ice."
        },
        {
          filename: '/img/power/ff/fastfact_20.png',
          text: "White ice accumulates along shore zones and along the boundary of ice and open water during freeze up."
        },
        {
          filename: '/img/power/ff/fastfact_21.png',
          text: "White ice is a lake ice travel hazard because it has more air in it and is much weaker than black or clear ice."
        },
        {
          filename: '/img/power/ff/fastfact_22.png',
          text: "White ice is a lake travel hazard especially in the early and late ice season when the ice is thin or decaying."
        },
        {
          filename: '/img/power/ff/fastfact_23.png',
          text: "White ice forms from wave action during freeze up. It is found usually in long bands embedded within black ice."
        },
        {
          filename: '/img/power/ff/fastfact_24.png',
          text: "Ice 'candling' after melt onset creates vertical drain channels through the ice, like the reverse of a 'honey-comb' pattern."
        },
      ],
      search_string: ''
    }
  },
  computed: {
    filtered_dyk() {
      let filtered = this.dyk.filter((item) => {
        let lower_text = item.text.toLowerCase()
        let lower_str = this.search_string.toLowerCase()
        return lower_text.includes(lower_str) || item.filename.includes(lower_str)
      })
      
      return filtered.sort(function(a,b) {
        return new Date(b.date) - new Date(a.date);
      })
    }
  }
}
</script>

<style scoped>
>>>.v-input__slot {
  padding: 15px;
}

>>>.v-input__prepend-inner {
  padding-right: 15px!important;
}

>>>.card-outter {
  position: relative;
}

>>>.card-actions {
  bottom: 0;
  position: absolute;
  right:0;
  width: 100%; 
  padding: 1.5em;
}
</style>
