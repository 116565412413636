<template>
  <v-container>
    <p class="text-h5 py-4 mt-4">
      <v-icon class="mr-2">fa-tasks</v-icon>
      Subscription Plan
    </p>
    <v-alert type="info" color="grey">
      The subscription period for Manitoba, North Western Ontario, and Minnesota has ended. Southern Ontario still available.
    </v-alert>
    <v-alert v-if="loading" type="info">Loading...</v-alert>
    <v-alert v-if="info" type="info" class="mt-4">
      {{ this.info }}
    </v-alert>

    <v-row v-if="!loading || !isLoggedIn" justify="center"> 
      <v-col v-for="userType in userTypes" :key="userType.id" :selected="userType.selected" cols="12" sm="4">
          <v-card             
            :style="!userType.selected ? 'border: none;' : 'outline: 3px solid #4CAF50;'"
            class="
            mx-auto
            d-flex
            flex-column"    
            height="100%"   
            @click="revealPrices(userType)" 
          >
            <v-card-text>  
              <p class="text-h5 text--primary">
                {{ userType.title }} User
              </p>                  
              <p class="text--primary">{{userType.description}}</p>
            </v-card-text>
          </v-card>
      </v-col>
    </v-row>

    <v-row v-show="revealPrice" justify="center"> 
      <v-col cols="12" sm="8">        
          <v-card
            :style="'outline: 3px solid #4CAF50;'"
            class="
            mx-auto"  
            >    
            <v-card-text>
              <p class="text-h6 text--primary">Pricing</p>
              <p v-if="prices.length != 0" v-html="getPriceLabel()"></p>
              <v-spacer></v-spacer>
              <p class="text-h6 text--primary">Regions</p>
              <div v-if="subscriptions.length != 0">
                <div class="green--text">You are currently subscribed to
                  <span v-if="subscribedRegions.length == regions.length">all available regions</span>:
                </div>
                <br>
                <v-row v-for="item in subscribedRegions" :key="item.radar_id">
                  <v-col>
                    <span class="pt-3">
                      <v-icon size="28" color="green">mdi-checkbox-marked-outline</v-icon>
                      <span class="text-subtitle-1 px-1">{{ getRegion(item).name }}</span>
                      <v-card-text class="text-caption font-italic pt-0 pb-0">{{getRegion(item).description}}</v-card-text>     
                    </span>   
                  </v-col>    
                </v-row>  
                <br>         
              </div>              
              <div v-if="subscribedRegions.length != regions.length && !subscribedRegions.includes(3001)">
                <p class="text--primary">Available Regions</p>                
                <v-row v-for="item in regions" :key="item.radar_id">
                  <v-col v-if="!subscribedRegions.includes(item.radar_id) && item.radar_id == 3001">
                    <v-checkbox class="mt-0 mb-0" v-model="selectedRegions" :value="item.radar_id" :label="item.name" hide-details/>
                    <v-card-text class="text-caption font-italic pt-0 pb-0">{{item.description}}</v-card-text>                        
                  </v-col>
                </v-row>     
                <br>
                <div v-if="subscribedRegions.length == 0">
                  <p class="text-h5 text--primary">Total: ${{getTotal()}}</p>
                </div>              
                <div v-else-if="selectedRegions.length != 0">
                  <p class="text-h5 text--primary">Total for Additional Regions: ${{getTotal()}}</p>                
                </div>    
                <p>By clicking "Subscribe" I agree that:</p>
                <v-checkbox v-model="agreeTerms" class="agree-checkbox" hide-details>
                  <template v-slot:label>
                    <div>
                    I have read and accepted the 
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                            target="_blank"
                            href="https://my.icetime.app/termsofuse"
                            @click.stop
                            v-on="on"
                          >Terms of Use</a>
                        </template>
                        Opens in new window
                      </v-tooltip>
                      and
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                            target="_blank"
                            href="https://my.icetime.app/privacypolicy"
                            @click.stop
                            v-on="on"
                          >Privacy Policy</a>
                        </template>
                        Opens in new window
                      </v-tooltip>.                             
                    </div>                      
                  </template>
                </v-checkbox>
                <v-checkbox v-if="selectedUserType[0].title === 'Personal'" v-model="agreeEula" class="agree-checkbox" hide-details>
                  <template v-slot:label>
                    <div>
                    I have read and accepted the 
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <a
                              v-if="selectedUserType[0].title === 'Personal'"
                              target="_blank"
                              href="https://my.icetime.app/eulapersonal"
                              @click.stop
                              v-on="on"
                            >End User License Agreement</a>
                        </template>
                        Opens in new window
                      </v-tooltip>.
                    </div>               
                  </template>
                </v-checkbox>        
                <v-checkbox v-else v-model="agreeEula" class="agree-checkbox" hide-details>
                  <template v-slot:label>
                    <div>
                    I have read and accepted the 
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <a
                              target="_blank"
                              href="https://my.icetime.app/eulapower"
                              @click.stop
                              v-on="on"
                            >End User License Agreement</a>
                        </template>
                        Opens in new window
                      </v-tooltip>.
                    </div>               
                  </template>
                </v-checkbox>
                <v-checkbox class="agree-checkbox" hide-details>
                  <template v-slot:label>
                    <div>
                      I have read and accepted the 
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <router-link
                            target="_blank"
                            to="/legal/termsofservice"
                            @click.stop
                            v-on="on"
                          >
                            Customer Portal Terms of Service
                          </router-link>
                        </template>
                        Opens in new window
                      </v-tooltip>.
                    </div>
                  </template>
                </v-checkbox>
                <v-checkbox v-model="agreeCommunications" class="agree-checkbox pl-20" label="I expect to receive communications as a part of the Ice Time(TM) service." hide-details/>   
                <v-checkbox v-model="agreeAge" class="agree-checkbox" label="I am a legal adult in my area of permanent residence." />                         
                <div v-if="subscriptions.length == 0">
                  <v-btn :disabled="!agree || selectedRegions.length == 0" :loading="loadingStripe" color="primary" @click.prevent="submit()" block>Subscribe</v-btn>
                  <p class="text-caption text-center pa-2">By clicking Subscribe, you will be redirected to our Billing Portal to complete the checkout process for the selected regions (above).</p>
                </div>
                <div v-else-if="paymentError">             
                  <v-alert type="error" class="mt-4">There was an error with your payment please click "Manual Payment" to continue</v-alert>                       
                  <v-btn :disabled="!agree || selectedRegions.length == 0" :loading="loadingStripe" color="primary" @click="manualPayment()" block>Manual Payment</v-btn>
                  <p class="text-caption text-center pa-2" >
                    By clicking Manual Payment, you agree to be charged ${{getTotal()}} using your on-file payment method, which will be added to your total price at the next renewal date. You will be transferred to
                    a secure Stripe page for payment.
                  </p>                                             
                </div>                  
                <div v-else>
                  <v-btn :disabled="!agree || selectedRegions.length == 0" :loading="loadingStripe" color="primary" @click="updateSubscription()" block>Add Selected Regions</v-btn>
                  <p class="text-caption text-center pa-2" >
                    By clicking Add Selected Regions, you agree to be charged ${{getTotal()}} using your on-file payment method, which will be added to your total price at the next renewal date. 
                  </p>                                             
                </div> 
                <p class="text-caption text-center" >Prices are subject to change without notice.</p>
              </div>
            </v-card-text>   
          </v-card>
      </v-col>
    </v-row>

    <stripe-checkout
      ref="checkoutRef"
      :pk="'pk_test_51GztvnKWYkE59aPlQgNkoqPpI3ATyuf4RkWJcMYFyHvmsyaoSpC2k7esaTm9IKr0QvrL7FyPMuy60b1g99H4Ifov00kxSxzWZ2'"
      :session-id="sessionId"
    />  
  </v-container>
</template>

<script>
// @ is an alias to /src
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import { mapGetters, mapState } from 'vuex'
import axios from 'axios'

export default {
  components: {
    StripeCheckout
  },
  data() {
    return {
      agreeTerms: false,
      agreeEula: false,
      agreeAge: false,
      agreeCommunications: false,
      disableSelect: false,      
      info: '',
      lineItems: [
        // {
        //   price: 'price_1I8SyKKWYkE59aPlTFqLVMcX', // The id of the recurring price you created in your Stripe dashboard
        //   quantity: 1,
        // },
      ],
      loading: false,
      loadingStripe: false,
      manualURL: null,
      paymentError: false,
      prices: [],
      product_ids: [],
      revealPrice: false,
      regions: [],
      requiredRegions: 0,
      selectedPrice: null,
      selectedRegions: [],
      selectedUserType: null,
      sessionId: null,
      subscribedRegions: [],
      subscriptions: [],
      userPrices: [],
      // userTypes: [],
    };
  },
  computed: {
    ...mapGetters('user', {
      isLoggedIn: 'isLoggedIn'
    }),
    ...mapState(['products']),
    ...mapState('user', {
      user: state => state.user,
      userTypes: state => state.user_types,
    }),
    agree() {
      return this.agreeTerms && this.agreeEula && this.agreeCommunications && this.agreeAge
    },
    isPersonal() {
      return this.selectedUserType[0].title === 'Personal'
    },
    // isLoggedIn() {
    //   return this.$store.getters.isLoggedIn
    // },
    // user() {
    //   return this.$store.state.user
    // },      
  },
  methods: {
    getPriceLabel() {
        let tiers = this.prices
        let priceString = "Billed yearly<br/>"
        // let next_quantity = 0
        let discount = 0
        let lowest_tier_price = tiers[0].unit_amount
        //Variable Tiers
        // tiers.forEach(tier => {
        //   priceString  += "$" + tier.unit_amount / 100
        //   switch(tier.up_to){
        //     case 1:
        //       priceString += " per region up to " + tier.up_to + "<br/>"
        //       break
        //     case null:
        //       discount += lowest_tier_price - tier.unit_amount
        //       priceString += " per region above " + next_quantity + " - Save $" + discount/100 + " off the total price!<br/>"
        //       break
        //     default:
        //       discount += lowest_tier_price - tier.unit_amount
        //       priceString += " per region above " + (tier.up_to - 1) + " - Save $" + discount/100 + " off the total price!<br/>"              
        //       break           
        //   }
        //   next_quantity = tier.up_to
        // })
        //3 Successive Tiers
        tiers.forEach(tier => {
          priceString  += "$" + tier.unit_amount / 100
          switch(tier.up_to){
            case 1:
              priceString += " for your first region<br/>"
              break
            case null:
              discount += lowest_tier_price - tier.unit_amount
              priceString += " for your third region (and up) - Save $" + discount/100 + " off the total price!<br/>"
              break
            default:
              discount += lowest_tier_price - tier.unit_amount
              priceString += " for your second region - Save $" + discount/100 + " off the total price!<br/>"              
              break           
          }
        })

        return priceString
    },
    getRegion(id) {
      return this.regions.filter(function(e) {
        return e.radar_id == id
      })[0]
    },
    getTotal() {
      let price = 0
      let totalRegions = this.selectedRegions.length + this.subscribedRegions.length
      let regionCount = 0

      
      if(this.subscribedRegions.length > 0) {
        regionCount = this.subscribedRegions.length
      }
        
      this.prices.forEach(e => {{
          if(regionCount != totalRegions) {
            if(e.up_to == null) {
              price = price + (e.unit_amount * (totalRegions-regionCount))
            }
            else{
              for (regionCount; regionCount < e.up_to; regionCount++) {
                price = price + e.unit_amount          
              }     
            }   
          }
        }
      })     

      return price/100
    },
    manualPayment() {
      window.open(this.manualURL)
      window.open('https://my.icetime.app/')
    }, 
    revealPrices(item) {
      if(!this.disableSelect) {
        this.revealPrice = true

        this.userTypes.forEach(userType => {
          userType.selected = false
        });   
        item.selected = true
        
        this.selectedPrice = this.userPrices.filter(e => {
          return item.id == e.metadata.userType
        })

        this.selectedUserType = this.userTypes.filter(e => {
          return item.id == e.id
        })

        this.prices = this.selectedPrice[0].tiers
        this.prices.forEach(price => {
          price.selected = false
        })
        this.$forceUpdate() 
      }     
    },
    revealRegions(item){
      if(!this.disableSelect) {
        this.prices.forEach(price => {
          price.selected = false
        })      
        this.selectedPrice = item
        item.selected = true
        if(this.selectedPrice.up_to == null) {
          //TO-DO: Determine this value
          this.requiredRegions = 3
        }
        else {
          this.requiredRegions = this.selectedPrice.up_to
        }      
        this.$forceUpdate()  
      }
    },
    submit() {
      // You will be redirected to Stripe's secure checkout page

      if(this.selectedRegions.length != 0) {
        this.loadingStripe = true
        this.lineItems = []              

        this.lineItems.push({
            "price": this.selectedPrice[0].id,
            "quantity": this.selectedRegions.length,
        })
        let formData = new FormData()
        formData.append('line_items', JSON.stringify(this.lineItems))
        formData.append('userType', this.selectedUserType[0].id)
        formData.append('regions', this.selectedRegions.join(','))
        axios.post(
          '/purchase',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        .then(resp => {
          this.sessionId = resp.data.id
          this.$refs.checkoutRef.redirectToCheckout()
        })
        .then(() => this.$store.dispatch('user/subscribe'))
        .catch((err) => {
          this.loadingStripe = false
          this.info = err.response.data
          window.scrollTo(0,0)
        })
      }
    },
    updateSubscription() {
      this.loadingStripe = true
      this.updateInfo = []
      this.regionNames = [] 

      let formData = new FormData()
      formData.append('subscriptionId', this.subscriptions[0].id)
      formData.append('quantity', this.selectedRegions.length + this.subscribedRegions.length)
      formData.append('regions', this.selectedRegions.concat(this.subscribedRegions).join(','))
      this.selectedRegions.forEach(region => {
        this.regionNames.push(this.regions.find(r => r.radar_id === region).name)
      })
      formData.append('region_names', this.regionNames.join(', '))
      axios.post(
        '/updateSubscription',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then((resp) => {
        this.loadingStripe = false     
        if(resp.data.paid) {
          window.open('https://my.icetime.app/subscriptions', '_self')
        }
        else {
          this.paymentError = true
          this.manualURL = resp.data.hosted_invoice_url
        }
      })
      .catch((err) => {
        this.loadingStripe = false
        this.info = err.response.data
        window.scrollTo(0,0)
      })
    },
  },
  created() {
    this.loading = true
    this.$store.dispatch("user/getUserTypes")
    // .then((resp) => {
    //   this.userTypes = resp.data.filter(function(e) {
    //     return (
    //       e.title !== 'Professional' &&
    //       e.title !== 'Administrator' && 
    //       e.title !== 'Unverified')})
    //   this.userTypes.forEach(userType => {
    //     userType.selected = false
    //   })              
    // })
    this.$store.dispatch("getCurrency")
    .then((resp) => {
      this.exchangeRate = resp.data.result
    })       
    this.$store.dispatch("user/getProducts")
    .then((resp) => {      
      var thisUser = this.user

      
      var products = resp.data.data
      this.userPrices = []

      products.forEach(product => {
        var price = product.prices.filter(e => {
          return e.metadata.country == thisUser.country
        })
        this.userPrices.push(price[0])
      })

      if (!this.isLoggedIn) {
        this.loading = false
      } 
      else {
        this.$store.dispatch('user/getSubscriptions')
        .then((resp) => {
          if(resp.data.length > 0) {
            this.subscriptions = resp.data
            this.selectedUserType = this.userTypes.filter(e => {
              return e.id == this.subscriptions[0].metadata.userType
            })                     
            this.revealPrices(this.selectedUserType[0])     
            this.subscribedRegions = this.subscriptions[0].metadata.regions.split(',')
            this.revealRegions(this.selectedPrice)   
            this.disableSelect = true
          }
        })

        var radar_id = 'active'
        this.$store.dispatch('getRegion', {radar_id})
        .then((resp) => {
          if(resp.data.length > 0) {
            this.regions = resp.data
          }          
        }) 
        this.loading = false     
      }
    })
    .catch(err => {
      if(err.response.status == '403') {
        this.$store.dispatch('logout')
        this.$store.dispatch('setDialog', { 
          show: true, 
          message: err.response.data, 
          status: err.response.status
        })
      }
    })
  }
}
</script>

<style scoped>
  ::v-deep .agree-checkbox {
  padding-left: 20px;  
  }

  ::v-deep .agree-checkbox .v-label {
  font-size: 14px;  
  }
</style>