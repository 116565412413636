// import Demo from './Demo'
import DemoReports from './views/DemoReports'
import DemoMap from './views/DemoMap'

export const DemoRoutes = [
  {
    path: '/demo/reports',
    name: 'Demo Reports',
    component: DemoReports,
  },
  {
    path: '/demo/region/:id/:layer?',
    name: 'Demo Map',
    component: DemoMap,
    props: true
  },
]