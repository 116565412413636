<template>
  <v-scroll-y-reverse-transition mode="out-in" :hide-on-leave="true">
    <v-dialog
      max-width="500px"
      hide-overlay
      :fullscreen="mobile_fullscreen"
      transition="dialog-bottom-transition"
      mode="out-in"
      no-click-animation
      persistent
      value="access_point"      
    > 
      <v-card class="pb-9" style="overflow-y: auto; overflow-x: hidden;">
        <!-- <v-card-actions v-if="!this.$vuetify.breakpoint.xs" class="pt-4"> -->
          <v-card-actions class="pt-4">
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog" text class="grey--text">
            Close
            <v-icon right color="grey">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>              
        <v-divider></v-divider>
        <v-row class="pa-2 pb-0">
          <v-col class="d-flex justify-end">          
              <v-btn 
                class="mx-1" 
                color="teal"
                dark
                elevation="0"
                v-if="access_point_editing && isLoggedIn && ((id == access_point.creator_id) || isStaffUser)"
                x-small
                @click="deleteAccessPoint"
              >
                delete
              </v-btn>
              <!-- <v-spacer /> -->
              <v-btn 
                class="mx-1" 
                color="teal"
                :dark="access_point_editing"
                elevation="0"
                :loading="editing_loading"
                :outlined="!access_point_editing"
                :text="!access_point_editing"
                v-if="access_point_editing && isLoggedIn && ((id == access_point.creator_id) || isStaffUser)"
                x-small
                @click="savePoint"
              >
                save
              </v-btn>
            <v-btn 
              class="mx-1" 
              color="teal"
              :dark="access_point_editing"
              elevation="0"
              :outlined="!access_point_editing"
              :text="!access_point_editing"
              v-if="(isLoggedIn && id == access_point.creator_id) || isStaffUser"
              x-small
              @click="toggleEdit"
            >
              {{ access_point_editing ? 'cancel':'edit' }}
            </v-btn>
            <v-btn 
              class="mx-1" 
              color="deep-orange"
              :dark="voting"
              elevation="0"
              :outlined="!voting"
              :text="!voting"
              v-if="isLoggedIn"
              x-small
              @click="toggleVote"
            >
              {{ voting ? 'cancel':'vote' }}
            </v-btn>
            <v-btn 
              class="mx-1 teal--text" 
              disabled
              outlined 
              text
              v-else
              x-small
            >
              please log in to vote
            </v-btn>
          </v-col>
        </v-row>           
        <v-container class="pa-0 ma-0">


          <v-slide-x-reverse-transition :hide-on-leave="true" mode="out-in">
            <AddVote :dialog="true" v-if="voting" @new-vote="new_vote" />
          </v-slide-x-reverse-transition>
          <v-card-title class="pb-1 mb-0 mx-1" v-if="!access_point_editing">
            {{ access_point.name ? access_point.name:'Access Point' }}
          </v-card-title>
          <v-text-field 
            class="text-h6 mb-2 mt-10 mx-5 teal--text" 
            color="teal"
            dense
            hide-details
            label="Access Point Name" 
            placeholder="Access Point"
            ref="edit_name"
            v-else
            v-model="editing_point.name" 
          />
          <v-card-subtitle v-if="!access_point_editing" class="py-1 my-0 d-flex align-center">
            <v-icon class="ml-1 mr-2" small>fa-crosshairs</v-icon>
            {{ access_point.lat }}, {{ access_point.lng }}
          </v-card-subtitle>
          <v-card-subtitle v-else class="py-1 my-0 d-flex align-center teal--text">
            <v-icon class="ml-1 mr-2" color="teal" small>fa-crosshairs</v-icon>
            {{ access_point_new_location.lat }}, {{ access_point_new_location.lng }}
          </v-card-subtitle>
          <v-card-subtitle v-if="!access_point_editing" class="py-1 my-0 d-flex align-center">
            <v-icon class="mb-1 ml-1 mr-2" small>fa-clock</v-icon>
            <v-btn 
              class="mx-1" 
              :color="vote_filter == 'daily' ? 'deep-orange lighten-4':''"
              depressed
              x-small 
              @click="toggleFilter('daily')"
            >
              &#x3c; 24 hours
            </v-btn>
            <v-btn 
              class="mx-1"
              :color="vote_filter == 'weekly' ? 'deep-orange lighten-4':''"
              depressed
              x-small 
              @click="toggleFilter('weekly')"
            >
              &#x3c; 7 days
            </v-btn>
          </v-card-subtitle>
          <v-card-subtitle v-if="!access_point_editing" class="py-0 my-0">
            <v-icon class="mb-1 ml-1 mr-2" small>fa-user-check</v-icon>
            {{ access_point.votes_sum }} user votes
          </v-card-subtitle>
          <BarChart v-if="!access_point_editing" :access_point="access_point" class="px-8 py-4" />
          <v-alert class="text-caption mx-4 white--text" color="grey" rounded>
            <v-layout>
              <v-flex align-self-center class="mx-2" shrink>
                <v-icon color="white">fa-info-circle</v-icon>
              </v-flex>
              <v-flex class="ml-4">
                Hover over the category to see hidden labels and percent of votes.
              </v-flex>
            </v-layout>
          </v-alert>
        </v-container>  
        <v-divider></v-divider>
        <v-container v-if="!access_point_editing" class="pa-0 ma-0">
            <v-flex class="d-flex justify-end mx-2 mt-2 mb-0">
              <v-btn 
                class="mx-1" 
                color="grey darken-1"
                :dark="commenting"
                elevation="0"
                :outlined="!commenting"
                :text="!commenting"
                v-if="isLoggedIn"
                x-small
                @click="toggleComment"
              >
                {{ commenting ? 'cancel':'add comment' }}
              </v-btn>
              <v-btn 
                class="mx-1 grey--text darken-1" 
                disabled
                outlined 
                text
                v-else
                x-small
              >
                please log in to comment
              </v-btn>
            </v-flex>
            <AddComment v-if="commenting" @new-comment="new_comment" />
            <v-card-title>
              Comments
            </v-card-title>

            <v-card
              class="px-4 py-2"
              flat
              v-if="selected_geopost_comments.length < 1"
            >
              <v-card-text class="my-0 mx-1 pa-0 font-italic text-caption">
                No comments to display.
              </v-card-text>
            </v-card>
            
            <v-card 
              v-for="comment in selected_geopost_comments"
              class="px-4 py-2"
              flat
              :key="comment.id"
            >
              <v-card-text class="my-0 mx-1 pa-0 text-caption">
                {{ comment.alias ? comment.alias:( comment.creator_id ? ('User#' + comment.creator_id):'Unkown User') }} on {{ comment.date_created }} UTC
              </v-card-text>
              <v-card class="pa-4 black--text text-body-2" :color="comment.status == 9 ? 'amber':'grey lighten-3'" dark flat rounded>
                {{ comment.text }}
                <v-img v-if="comment.image_name" :src="(image_start + comment.image_name)" max-height="500" max-width="300"/>
              </v-card>
            </v-card>
        </v-container>  
        <!-- <v-row v-if="this.$vuetify.breakpoint.xs" class="py-8"></v-row>          -->
      </v-card>    

      <!-- <v-row>
        <v-col>
          <v-btn 
            v-if="this.$vuetify.breakpoint.xs"
            @click="closeDialog" 
            text 
            class="py-6 grey--text"
            width="100%" 
            style="position: fixed; bottom: 0; margin-left: auto; margin-right: auto; background: white"
          >Close<v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row> -->
    </v-dialog>
  </v-scroll-y-reverse-transition>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { latLng } from 'leaflet'

import BarChart from './BarChart.vue'
import AddComment from './AddComment.vue'
import AddVote from './AddVote.vue';

export default {
  data() {
    return {
      commenting: false,
      // editing: false,
      editing_loading: false,
      editing_point: {
        name: '',
        lat: '',
        lng: '',
      },
      voting: false,
      image_start: this.$apiEndpointUrl + "ap_comment_image/",
    }
  },
  components: {
    AddComment,
    AddVote,
    BarChart,
  },
  computed: {
    ...mapGetters('user', {
      isLoggedIn: 'isLoggedIn',
      isStaffUser: 'isStaffUser'
    }),
    ...mapState('access_points', {
      access_points: state => state.access_points,
      access_point_editing: state => state.access_point_editing,
      access_point_new_location: state => state.access_point_new_location,
      geopost_filter_time: state => state.geopost_filter_time,
      selected_access_point: state => state.selected_access_point,
      selected_geopost: state => state.selected_geopost,
      selected_geopost_comments: state => state.selected_geopost_comments,      
      vote_filter: state => state.vote_filter,
    }),
    ...mapState('user', {
      id: state => state.user.id,
    }),
    access_point() {
      if (this.selected_access_point) {
        return this.selected_access_point
      } else {
        return this.access_points.find(item => item.geopost_id == this.selected_geopost)
      }
    },
    mobile_fullscreen() {
      return !this.$vuetify.breakpoint.xs ? false : true
    },
  },    
  methods: {
    closeDialog() {
      this.$store.dispatch('access_points/setShowMarkerDialog',)
      this.$store.dispatch('access_points/setAccessPointEditing', false)
      this.$store.dispatch('access_points/setVoteFilter', this.geopost_filter_time ? this.geopost_filter_time:'daily')
      .then(() => {
        this.$store.dispatch('access_points/setSelectedGeopost','')
        this.$store.dispatch('access_points/setSelectedAccessPoint', '')
      })
      this.voting = false
    },
    deleteAccessPoint() {
      // console.log('delete ' + this.access_point.geopost_id)
      this.$store.dispatch('access_points/deleteAccessPoint', this.access_point.geopost_id)
      .then(() => {
        this.closeDialog()
        this.$emit('apc-updated')
      })
    },
    new_comment() {
      this.commenting = false
      if (this.selected_geopost) {
        this.$store.dispatch('access_points/getSelectedGeopostComments', this.selected_geopost)
      }
    },
    new_vote() {
      this.voting = false
      this.$emit('apc-updated')
    },
    savePoint() {
      this.editing_point.geopost_id = this.access_point.geopost_id
      if (this.access_point_new_location) {
        this.editing_point.lat = this.access_point_new_location.lat
        this.editing_point.lng = this.access_point_new_location.lng
      }

      this.editing_loading = true
      this.$store.dispatch('access_points/updateAccessPoint', this.editing_point)
      .then(() => {
        this.editing_point.lat = null
        this.editing_point.lng = null
        this.$store.dispatch('access_points/setAccessPointEditing', false)
        this.$emit('apc-updated')
      })
      .catch(() => {
        // console.log(err.message)
      })
      .finally(() => {
        this.editing_loading = false
        this.post_data = {}
        this.$store.dispatch('access_points/setAccessPointNewLocation', [])
      })
    },
    toggleComment() {
      this.commenting = !this.commenting

      if (this.voting) {
        // this.editing = false
        this.$store.dipatch('access_points/setAccessPointEditing', false)
        this.voting = false
      }
    },
    toggleEdit() {
      // this.editing = !this.editing
      this.$store.dispatch('access_points/setAccessPointEditing', !this.access_point_editing)

      // if (this.editing) {\
      if (this.access_point_editing) {
        this.voting = false
        this.commenting = false
        this.editing_point.name = this.access_point.name
        this.$nextTick(() => this.$refs.edit_name.focus())
        this.$store.dispatch('access_points/setSelectedGeopost', this.access_point.geopost_id)
        this.$emit('apc-edit-toggled', latLng(this.access_point.lat, this.access_point.lng))
      } else {
        this.editing_point.name = ''
        this.editing_point.lat = null
        this.editing_point.lat = null
        this.editing_point.geopost_id = null
        // this.$store.dispatch('spot_mapper/setSelectedGeopost', '')
        this.$store.dispatch('access_points/setAccessPointNewLocation', [])
        this.$emit('apc-untoggled')
      }
    },
    toggleFilter(filter) {
      if (filter != this.vote_filter) {
        this.$store.dispatch('access_points/setVoteFilter', filter)

        let id = this.access_point.accesspoint_id
        this.$store.dispatch('access_points/getAccessPoint', id)
      }
    },
    toggleVote() {
      this.voting = !this.voting

      if (this.voting) {
        // this.editing = false
        this.$store.dispatch('access_points/setAccessPointEditing', false)
        this.commenting = false
      }
    },
  },
  props: {
  },
  mounted() {
        // console.log(this.selected_geopost_comments)
    }
}
</script>

<style scoped>
>>>.sticky {
  position: sticky;
  top: 0;
  z-index: 2;
}

>>>.v-dialog{
  /* background-color: #fff; */
  bottom: 0; 
  left: 0;
  position: absolute;
}
>>>.card-outter {
  position: relative;
  padding-bottom: 1.5em;
}
>>>.card-actions {
  position: absolute;
  top: 0;
  right:0;
}
.unselectable {
            -webkit-user-select: none;
            /* Safari */
            -ms-user-select: none;
            /* IE 10 and IE 11 */
            user-select: none;
            /* Standard syntax */
        }
</style>