// import axios from 'axios'
// import Vue from 'vue'
// import Vuex from 'vuex'

// If API is needed
// Vue.use(Vuex)
// axios.defaults.baseUrl = Vue.$apiEndpointUrl

const state = {
  interest: 0,
};

const getters = {
};

const mutations = {
  setInterest(state, interest) {
    state.interest = interest
  },
};

const actions = {
  setInterest({commit}, interest) {
    return new Promise((resolve) => {
      commit('setInterest', interest)
      resolve(interest)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}