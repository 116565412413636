import axios from 'axios'
import { latLng, latLngBounds } from 'leaflet';
import Vue from 'vue'
import Vuex from 'vuex'

// For API dependencies
Vue.use(Vuex)
axios.defaults.baseUrl = Vue.$apiEndpointUrl

const state = {
  current_footprint: {},
  dates: [],
  demo_hazards: [],
  demo_layers: [],
  demo_user_reports: [],
  footprints: [],
  footprints_hint: '',
  gpsMode: 0,
  isAdminHazardVisible: true,
  isIceRoadVisible: true,
  isMapVisible: true,
  isPersonalHazardVisible: true,
  isProHazardVisible: true,
  isUserHazardVisible: true,
  lockMapOn: false,
  pinsAdmin: [],
  pinsPersonal: [],
  pinsPOI: [],
  pinsPro: [],
  pinsUser: [],
  range_value: [ 1, 10 ],
  range_labels: [ 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18 ],
  visibleLayer: {},
  zoom: 10,
  zoomControl: null,
};

const getters = {
  areAllHazardComponentsVisible: state => {
    return state.isPersonalHazardVisible && state.isUserHazardVisible && state.isProHazardVisible && state.isAdminHazardVisible
  },
  // areIceRoadsVisible: state => {
  // },
  areAllMapComponentsVisible: state => {
    return state.isMapVisible && state.isIceRoadVisible
  },
  filteredAdminPins: state => {
    return state.pinsAdmin.filter(function(el) {
      return el.layer_id < state.visibleLayer.id || el.layer_id == state.visibleLayer.id
    })
  },
  filteredPersonalPins() {
    return state.pinsPersonal.filter(function(el) {
      return el.layer_id < state.visibleLayer.id || el.layer_id == state.visibleLayer.id
    })
  },
  filteredPOIPins() {
    return state.pinsPOI.filter(function(el) {
      return el.layer_id < state.visibleLayer.id || el.layer_id == state.visibleLayer.id
    })
  },
  filteredProPins() {
    return state.pinsPro.filter(function(el) {
      return el.layer_id < state.visibleLayer.id || el.layer_id == state.visibleLayer.id
    })
  },
  filteredUserPins() {
    return state.pinsUser.filter(function(el) {
      return el.layer_id < state.visibleLayer.id || el.layer_id == state.visibleLayer.id
    })
  }
};

const mutations = {
  getDemoHazards(state, payload) {
    state.demo_hazards = payload
  },
  getDemoUserReports(state, payload) {
    state.demo_user_reports = payload
  },
  getFootprints(state, payload) {
    state.footprints = payload
  },
  setDates(state, payload) {
    state.dates = payload
  },
  setDemoLayers(state, payload) {
    state.demo_layers = payload
  },
  setCurrentFootprint(state, payload) {
    state.current_footprint = payload
  },
  setFootprintsHint(state, payload) {
    state.footprints_hint = payload
  },
  setGPSMode(state, payload) {
    state.gpsMode = payload
  },
  setPinsAdmin(state, payload) {
    state.pinsAdmin = payload
  },
  setPinsPersonal(state, payload) {
    state.pinsPersonal = payload
  },
  setPinsPOI(state, payload) {
    state.pinsPOI = payload
  },
  setPinsPro(state, payload) {
    state.pinsPro = payload
  },
  setPinsUser(state, payload) {
    state.pinsUser = payload
  },
  setRangeValue(state, payload) {
    state.range_value = payload
  },
  setVisibleLayer(state, payload) {
    state.visibleLayer = payload
  },
  setZoom(state, payload) {
    state.zoom = payload
  },
  setZoomControl(state, payload) {
    state.zoomControl = payload
  },
  toggleAllHazards(state) {
    if (state.isPersonalHazardVisible && state.isUserHazardVisible && state.isProHazardVisible && state.isAdminHazardVisible) {
      state.isAdminHazardVisible = false
      state.isPersonalHazardVisible = false
      state.isProHazardVisible = false
      state.isUserHazardVisible = false
    } else {
      state.isAdminHazardVisible = true
      state.isPersonalHazardVisible = true
      state.isProHazardVisible = true
      state.isUserHazardVisible = true
    }
  },
  toggleAllMapComponents(state) {
    if (state.isMapVisible && state.isIceRoadVisible) {
      state.isMapVisible = false
      state.isIceRoadVisible = false
    } else {
      state.isMapVisible = true
      state.isIceRoadVisible = true
    }
  },
  toggleHazardAdmin(state) {
    state.isAdminHazardVisible = !state.isAdminHazardVisible
  },
  toggleHazardPersonal(state) {
    state.isPersonalHazardVisible = !state.isPersonalHazardVisible
  },
  toggleHazardPro(state) {
    state.isProHazardVisible = !state.isProHazardVisible
  },
  toggleHazardUser(state) {
    state.isUserHazardVisible = !state.isUserHazardVisible
  },
  toggleIceRoad(state) {
    state.isIceRoadVisible = !state.isIceRoadVisible
  },
  toggleMap(state) {
    state.isMapVisible = !state.isMapVisible
  },
  toggleMapLock(state) {
    state.lockMapOn = !state.lockMapOn
  },
  toggleZoomControl(state) {
    state.zoomControl = !state.zoomControl
  },
};

const actions = {
  createDemoHazard({commit}, data) {
    return new Promise((resolve, reject) => {
      axios.post('demo_hazards', data)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        commit('auth_error', err)
        reject(err)
      })
    })
  },
  getDemoLayers({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get('demo_layers/' + payload.id)
      .then((layers) => {
        if(layers.data) {
          let dates = []
          layers.data.forEach((layer) => {
            if(layer.type == '1' && layer.tilemap_json !== '') {
              if(layer.overlay_geojson) {
                layer.overlay_geojson = JSON.parse(layer.overlay_geojson)
              }
              
              var info = JSON.parse(layer.tilemap_json)
              // layer.tile_url = this.$apiEndpointUrl + 'tile/' + this.id + '/' + layer.id + '/{z}/{x}/{y}.png'
              layer.tile_url = Vue.prototype.$apiEndpointUrl + 'tile/' + payload.id + '/' + layer.id + '/{z}/{x}/{y}.png'
              layer.bounds = new latLngBounds(new latLng(info.BoundingBox['@attributes'].miny,info.BoundingBox['@attributes'].minx),new latLng(info.BoundingBox['@attributes'].maxy,info.BoundingBox['@attributes'].maxx))
              layer.center = layer.bounds.getCenter()
              layer.maxNativeZoom = getMaxZoom(layer.tilemap_json)
              layer.maxZoom = 24
              layer.tms = true
              if(layer.date !== '' && layer.date !== null) {
                dates.push({
                  date: layer.date,
                  id: layer.id,
                  relative_orbit: layer.relative_orbit,
                  latitude: layer.latitude
                })
              }
            }
          })
          commit('setDemoLayers', layers.data)
          commit('setDates', dates)
        }
        resolve(layers)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getDemoHazards({commit}, data) {
    return new Promise((resolve, reject) => {
      axios.get('demo_hazards/' + data.id)
      .then(resp => {
        commit('getDemoHazards', resp.data)
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getDemoUserReports({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('demo_user_reports')
      .then(resp => {
        commit('getDemoUserReports', resp.data)
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getFootprints({commit}, data) {
    return new Promise((resolve, reject) => {
      axios.get('footprints/' + data.id)
      .then(resp => {
        commit('getFootprints', Object.values(resp.data))
        resolve(resp)
      }).catch(err => {
        reject(err)
      })
    })
  },
  setCurrentFootprint({commit}, footprint) {
    return new Promise(() => {
      commit('setCurrentFootprint', footprint)

      let x = state.demo_layers.find(demo_layer => {
        return demo_layer.footprint_id == footprint.id
      })
      x = x ? x:{}
      commit('setVisibleLayer', x)
    })
  },
  setDates({commit}, dates) {
    return new Promise(() => {
      commit('setDates', dates)
    })
  },
  setDemoLayers({commit}, layers) {
    return new Promise(() => {
      commit('setDemoLayers', layers)
    })
  },
  setGPSMode({commit}, mode) {
    return new Promise(() => {
      commit('setGPSMode', mode)
    })
  },
  setPinsAdmin({commit}, pins) {
    return new Promise(() => {
      commit('setPinsAdmin', pins)
    })
  },
  setPinsPersonal({commit}, pins) {
    return new Promise(() => {
      commit('setPinsPersonal', pins)
    })
  },
  setPinsPOI({commit}, pins) {
    return new Promise(() => {
      commit('setPinsPOI', pins)
    })
  },
  setPinsPro({commit}, pins) {
    return new Promise(() => {
      commit('setPinsPro', pins)
    })
  },
  setPinsUser({commit}, pins) {
    return new Promise(() => {
      commit('setPinsUser', pins)
    })
  },
  setRangeValue({commit}, range) {
    return new Promise(() => {
      commit('setRangeValue', range)
    })
  },
  setVisibleLayer({commit}, layer) {
    return new Promise(() => {
      commit('setVisibleLayer', layer)
    })
  },
  setZoom({commit}, zoom) {
    return new Promise(() => {
      commit('setZoom', zoom)
    })
  },
  setZoomControl({commit}, zoomControl) {
    return new Promise(() => {
      commit('setZoomControl', zoomControl)
    })
  },
  toggleAllHazards({commit}) {
    return new Promise(() => {
      commit('toggleAllHazards')
    })
  },
  toggleAllMapComponents({commit}) {
    return new Promise(() => {
      commit('toggleAllMapComponents')
    })
  },
  toggleHazardAdmin({commit}) {
    return new Promise(() => {
      commit('toggleHazardAdmin')
    })
  },
  toggleHazardPersonal({commit}) {
    return new Promise(() => {
      commit('toggleHazardPersonal')
    })
  },
  toggleHazardPro({commit}) {
    return new Promise(() => {
      commit('toggleHazardPro')
    })
  },
  toggleHazardUser({commit}) {
    return new Promise(() => {
      commit('toggleHazardUser')
    })
  },
  toggleIceRoad({commit}) {
    return new Promise(() => {
      commit('toggleIceRoad')
    })
  },
  toggleMap({commit}) {
    return new Promise(() => {
      commit('toggleMap')
    })
  },
  toggleMapLock({commit}) {
    return new Promise(() => {
      commit('toggleMapLock')
    })
  },
  toggleZoomControl({commit}) {
    return new Promise(() => {
      commit('toggleZoomControl')
    })
  }
};

function getMaxZoom(json) {
  if(json == '' || json == null) return
  var info = JSON.parse(json)
  var max = 1
  if(!info.TileSets || !info.TileSets.TileSet) return 0
  info.TileSets.TileSet.forEach(function(zoom) {
    if(parseInt(zoom['@attributes'].href) > max) max = parseInt(zoom['@attributes'].href)
    // if(parseInt(zoom['@attributes'].href) < min) min = parseInt(zoom['@attributes'].href)
  })
  return max
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}