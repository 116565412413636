import Admin from './Admin.vue'
// import AdminMailChimp from './views/AdminMailChimp'
// import AdminNotifications from './views/AdminNotifications'
import AdminReports from './views/AdminReports'
import AdminUsers from './views/AdminUsers'
import Regions from './views/Regions'

import AdvisoryMGMT from '../ras/views/AdvisoryMGMT.vue'

import store from '../../store'
import MLUploader from './views/MLUploader.vue'
import AdminStats from './views/AdminStatsPage.vue'
// import CVTest from './views/CVTest.vue'
import CVTest from '../user/views/CVTest'

export const AdminRoutes = [
  {
    path: '/admin',
    component: Admin,
    beforeEnter(to, from, next) {
      if (store.getters['user/isAdmin']) {
        next()
        return
      } else {
        next({
          name: "home" // back to safety route //
        })
      }
    },    
    meta: {
      // requiresAuth: true,
      requiresAdmin: true
    },
    children: [
      // {
      //   path: 'mailchimp',
      //   name: 'MailChimp Admin',
      //   component: AdminMailChimp
      // },
      // {
      //   path: 'notifications',
      //   name: 'Notification Admin',
      //   component: AdminNotifications
      // },
      {
        path: 'reports',
        name: 'Report Admin',
        component: AdminReports
      },
      {
        path: 'users',
        name: 'User Admin',
        component: AdminUsers
      },
      {
        path: 'uploader',
        name: 'Species Trainer',
        component: MLUploader
      },
      {
        path: 'stats',
        name: 'Training Stats',
        component: AdminStats
      },
      {
        path: 'processingviewer',
        name: 'Computer Vision Viewer',
        component: CVTest
      },
      {
        path: 'importer',
        name: 'Regions Admin',
        component: Regions
      },
      {
        path: 'advisories',
        name: 'Advisory Management',
        component: AdvisoryMGMT,
      }
    ]
  },
  // {
  //   path: '/admin/mailchimp',
  //   name: 'MailChimp Admin',
  //   component: AdminMailChimp,
  //   meta: {
  //     requiresAuth: true,
  //     requiresAdmin: true
  //   },
  // },
  // {
  //   path: '/admin/notifications',
  //   name: 'Notification Admin',
  //   component: AdminNotifications,
  //   meta: {
  //       requiresAdmin: true
  //   },
  // },
  // {
  //   path: '/admin/reports',
  //   name: 'Report Admin',
  //   component: AdminReports,
  //   meta: {
  //     requiresAuth: true,
  //     requiresAdmin: true
  //   },
  // },
  // {
  //   path: '/admin/users',
  //   name: 'User Admin',
  //   component: AdminUsers,
  //   meta: {
  //     requiresAuth: true,
  //     requiresAdmin: true
  //   },
  // },
  // {
  //   path: '/admin/regions',
  //   name: 'Regions Admin',
  //   component: Regions,
  //   meta: {
  //     requiresAuth: true,  
  //     requiresAdmin: true
  //   },
  // },
]