import Map from './Map'
import Reports from './views/Reports'
import Regions from './views/Regions'
import Radar from './views/Radar'
// import Mapper from './../spot_mapper/views/Mapper'
import GeopostMap from './../map/views/GeopostMap'
import ALB from './../leaderboard/views/ALB'
import QR from './../leaderboard/views/QRPage'
// import About from './../leaderboard/views/AboutPage'
import DailyLB from './../leaderboard/views/DailyLeaderboardPage'
import AdminLB from './../leaderboard/views/AdminLeaderboardPage'
import CompLB from './../leaderboard/views/CompLeaderboardPage'
import CompList from './../leaderboard/views/CompListPage'
import CompRoster from './../leaderboard/views/CompRosterPage'
import CompSummary from './../leaderboard/views/CompSummaryPage'
import CreateComp from './../leaderboard/views/CreateCompPage'
import EditComp from './../leaderboard/views/EditCompPage'
import FinishedLB from './../leaderboard/views/FinishedLeaderboardPage'
import FinishedList from './../leaderboard/views/FinishedListPage'
import FinishedRoster from './../leaderboard/views/FinishedRosterPage'
import FinishedComp from './../leaderboard/views/FinishedCompPage'
import RegionalLeaderboard from './../leaderboard/views/RegionalLeaderboardPage'
import TopTenLeaderboard from './../leaderboard/views/TopTenLeaderboardPage'
import PersonalLeaderboard from './../leaderboard/views/Personal'
import AllInOne from './../leaderboard/views/AllInOneLeaderboard'
// import Personal from './../leaderboard/views/Personal'

// import store from '../../store'

export const MapRoutes = [
  // {
  //   path: '/map',
  //   name: 'Map Home',
  //   components: MapHome,
  // },
  {
    path: '/regions',
    name: 'Regions and Footprints',
    component: Regions
  },
  {
    path: '/app/leaderboard',
    name: 'Leaderboard',
    component: ALB,
    // children: [
    //   {
    //     path: '/bumpboard',
    //     name: 'Bumb Board',
    //     component: QR,
    //   }
    // ]
  },
  {
    path: '/app/leaderboard/bumpboard',
    name: 'Bumb Board',
    component: QR,
  },
  // {
  //   path: '/app/leaderboard/about',
  //   name: 'About',
  //   component: About,
  // },
  {
    path: '/app/leaderboard/adminleadeboard/:id',
    name: 'Admin Leaderboard',
    component: AdminLB,
  },
  {
    path: '/app/leaderboard/dailyleaderboard',
    name: 'Daily Leaderboard',
    component: DailyLB,
  },
  {
    path: '/app/leaderboard/toptenleaderboard',
    name: 'Top 10 Leaderboard',
    component: TopTenLeaderboard,
  },
  {
    path: '/app/leaderboard/all',
    name: 'All',
    component: AllInOne,
  },
  {
    path: '/app/leaderboard/compleaderboard/:id',
    name: 'Competition Leaderboard',
    component: CompLB,
  },
  {
    path: '/app/leaderboard/complist',
    name: 'Competition List',
    component: CompList,
  },
  {
    path: '/app/leaderboard/comproster/:id',
    name: 'Competition Roster',
    component: CompRoster,
  },
  {
    path: '/app/leaderboard/compsummary/:id',
    name: 'Competition Summary',
    component: CompSummary,
  },
  {
    path: '/app/leaderboard/createcomp',
    name: 'Create Competition',
    component: CreateComp,
  },
  {
    path: '/app/leaderboard/editcomp/:id',
    name: 'Edit Competition',
    component: EditComp,
  },
  {
    path: '/app/leaderboard/finishedleaderboard/:id',
    name: 'Finished Leaderboard',
    component: FinishedLB,
  },
  {
    path: '/app/leaderboard/finishedroster/:id',
    name: 'Finished Roster',
    component: FinishedRoster,
  },
  {
    path: '/app/leaderboard/finishedlist',
    name: 'Finished List',
    component: FinishedList,
  },
  {
    path: '/app/leaderboard/finishedcomp/:id',
    name: 'Finished Summary',
    component: FinishedComp,
  },
  {
    path: '/app/leaderboard/regionalleaderboard',
    name: 'Regional Leaderboard',
    component: RegionalLeaderboard,
  },
  {
    path: '/app/leaderboard/personalleaderboard',
    name: 'Personal Leaderboard',
    component: PersonalLeaderboard,
  },

  {
    path: '/app',
    component: Map,
    children: [
      // {
      //   path: 'mapper',
      //   name: 'Spot Mapper',
      //   component: Mapper,
      //   meta: {
      //     requiresValidTrialUser: true,
      //   }
      // },
      // {
      //   path: 'leaderboard',
      //   name: 'Leaderboard',
      //   component: ALB,
      //   // children: [
      //   //   {
      //   //     path: 'personal',
      //   //     name: 'Personal Leaderboard',
      //   //     component: Personal,
      //   //   }
      //   // ]
      // },
      {
        path: 'radar/:id?/:layer?',
        name: 'Radar',
        props: true,
        component: Radar,
        // beforeEnter(to, from, next) {
        //   if (store.getters['user/isStaffUser'] || store.getters['user/isProUser']) {
        //     next()
        //     return
        //   } else {
        //     next({
        //       name: "home" // back to safety route //
        //     })
        //   }
        // },
        // meta: {
        //   requiresAuth: true,
        //   requiresProLicense: true,
        // }
      },
      {
        path: 'map',
        name: 'Map',
        component: GeopostMap
      },      
      // {
      //   path: 'map/:id?/:layer?',
      //   name: 'Map',
      //   props: true,
      //   component: GeopostMap
      // }
    ]
  },  
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    meta: {
      requiresAuth: true
    },
  },
  // {
  //   path: '/app/radar/:id/:layer?',
  //   name: 'Regional Map',
  //   component: Map,
  //   props: true,
  //   meta: {
  //     requiresAuth: true
  //   }
  // }, 
]