<template>
  <span>
    <l-control class="custom-control gps-control" position="topright">
      <span v-if="gpsMode > 0 && gps" class="text-body-2 ml-2">
        {{ gps.lat }}, {{ gps.lng }}
      </span>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click.prevent="toggle"
            :disabled="lockMapOn"
            :loading="gpsMode > 0 && !gps"
            class="pa-0 ma-0"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon :color="gpsColor">mdi-crosshairs-gps</v-icon>
          </v-btn>
        </template>
        <span v-if="gpsMode == 0">Find Your Location</span>
        <span v-else-if="gpsMode == 1">Pan to Your Location</span>
        <span v-else-if="gpsMode == 2">Close Location Tool</span>
      </v-tooltip>
    </l-control>
    <l-marker v-if="gps && gpsMode > 0" :lat-lng="gps" :zIndexOffset="100">
      <v-icon icon-url="fa-pin"/>
    </l-marker>
  </span>
</template>

<script>
import { mapState } from 'vuex'
import { LControl, LMarker } from 'vue2-leaflet'

export default {
  components: {
    LControl,
    LMarker,
  },
  computed: {
    ...mapState('demo', {
      gpsMode: state => state.gpsMode,
      lockMapOn: state => state.lockMapOn,
    })
  },
  data() {
    return {
      gpsColor: "deep-orange",
      offset: 0,
    }
  },
  methods: {
    toggle() {
      if(this.gpsMode == 0) {
        this.$store.dispatch('demo/setGPSMode', 1)
        this.gpsColor = 'blue'
      } else if(this.gpsMode == 1) {
        this.$store.dispatch('demo/setGPSMode', 2)
        this.gpsColor = 'green'
      } else if(this.gpsMode == 2) {
        this.$store.dispatch('demo/setGPSMode', 0)
        this.gpsColor = 'deep-orange'
      }

      this.$emit('gps-mode-changed')
    },
  },
  props: ['gps']
}
</script>

<style scoped>
>>>.custom-control-2:hover {
  transition-duration: 200ms;
  opacity: 1;
}

>>>.v-dialog {
  position: absolute;
  bottom: 0;
  right: 0;
}

>>>.card-outter {
  position: relative;
  padding-bottom: 1.5em;
}

>>>.card-actions {
  position: absolute;
  top: 0;
  right:0;
}
</style>