<template>
    <v-container class="ma-0 pa-0 mb-10">
        <v-card class="blue lighten-5 pb-3 mb-16">
            <v-card-title>Create New Competition</v-card-title>
            <v-card
                v-if="!created"
                :class="set_padding_margins"
            >
                <v-row class="px-4" :no-gutters="set_gutters">
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="name"
                            label="Competition Name"
                            type="text"
                            :rules="[field_rules.required]"
                        >
                        </v-text-field>                  
                    </v-col>                
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="host"
                            label="Hosted By"
                            type="text">
                        </v-text-field>
                    </v-col>
                </v-row>                
                <v-row class="px-4" :no-gutters="set_gutters">
                    <v-col cols="6" sm="6">
                        <v-menu
                            v-model="start_menu"
                            :close-on-content-click="false"
                            transition="slide-x-transition"
                            min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="start_date"
                            label="Start Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[field_rules.required]"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="start_date"
                            no-title
                            scrollable
                            @input="start_menu = false"
                        ></v-date-picker>
                        </v-menu>
                    </v-col>    
                    <v-col cols="6" sm="6">
                        <v-menu
                            ref="menu"
                            v-model="start_time_menu"
                            :close-on-content-click="false"
                            :return-value.sync="start_time"
                            transition="slide-x-transition"
                            max-width="300px"
                            min-width="300px"
                        >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="start_time"
                            label="Start Time"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[field_rules.required]"
                        ></v-text-field>
                        </template>
                        <v-time-picker
                            v-if="start_time_menu"
                            v-model="start_time"
                            full-width
                            @click:minute="$refs.menu.save(start_time)"
                        ></v-time-picker>
                    </v-menu>
                    </v-col>
                </v-row>    
                <v-row class="px-4" :no-gutters="set_gutters">
                    <v-col cols="6" sm="6">
                        <v-menu
                            v-model="end_menu"
                            :close-on-content-click="false"
                            transition="slide-x-transition"
                            min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="end_date"
                            label="End Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[field_rules.required]"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="end_date"
                            no-title
                            scrollable
                            @input="end_menu = false"
                        ></v-date-picker>
                        </v-menu>
                    </v-col> 
                    <v-col cols="6" sm="6">
                        <v-menu
                            ref="menu1"
                            v-model="end_time_menu"
                            :close-on-content-click="false"
                            :return-value.sync="end_time"
                            transition="slide-x-transition"
                            max-width="300px"
                            min-width="300px"
                        >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="end_time"
                            label="End Time"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[field_rules.required]"
                        ></v-text-field>
                        </template>
                        <v-time-picker
                            v-if="end_time_menu"
                            v-model="end_time"
                            full-width
                            @click:minute="$refs.menu1.save(end_time)"
                        ></v-time-picker>
                        </v-menu>
                    </v-col>  
                </v-row>    
                <v-row class="px-4" :no-gutters="set_gutters">
                    <v-col cols="12" sm="6">
                        <v-autocomplete
                            v-model="region"
                            label="Region"
                            :items="region_list"
                            item-text='name'
                            item-value='name'
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="location"
                            label="Body of Water/Area"
                            type="text">
                        </v-text-field>                    
                    </v-col>
                </v-row>         
                <!-- <v-row class="px-4" :no-gutters="set_gutters">
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="buy_in"
                            label="Buy-in"
                            type="number"
                            step="0.01"
                            prefix="$"
                            >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="prize_pool"
                            label="Total"
                            type="number"
                            step="0.01"
                            prefix="$"
                            >
                        </v-text-field>                    
                    </v-col>
                </v-row>     -->
                <v-row class="px-4" :no-gutters="set_gutters">
                    <v-col cols="12" sm="6">
                        <v-select
                            label="Maximum Team Members"
                            v-model="team_size"
                            :items="max_team_size"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="team_limit"
                            label="Maximum Competing Teams"
                            type="number"
                            min="0"
                            step="1"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>     
                <v-row class="px-4" :no-gutters="set_gutters">
                    <v-col cols="12" sm="6">
                        <v-select
                            v-model="type"
                            :items="type_list"
                            label="Competition Type"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-select
                            v-model="visibility"
                            :items="visibility_list"
                            label="Visibility"
                        >
                        </v-select>
                    </v-col>
                </v-row>           
                <v-row class="px-4" :no-gutters="set_gutters">
                    <v-col cols="12" sm="6">
                        <v-select
                            label="Scoring Criteria"
                            :onchange="check_if_amount_needed()"
                            v-model="sort_type"
                            :items="sort_list"
                        >
                        </v-select>
                    </v-col>
                    <v-col v-if="amount_needed" cols="12" sm="6">
                        <v-select               
                            label="Maximum Amount"         
                            v-model="number_of_fish"
                            :items="max_fish_amount"
                        >
                        </v-select>
                    </v-col>
                </v-row>          
                <v-row class="px-4" :no-gutters="set_gutters">
                    <v-col cols="12" sm="6">
                        <v-checkbox 
                            class="py-0 my-0" 
                            v-model="require_photo" 
                            label="Catches Must Have Photos" 
                            color="primary"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="6">
                    </v-col>
                </v-row>    

                <v-divider class="mx-2"></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="!expanded" icon @click="expand"><v-icon>mdi-arrow-expand</v-icon></v-btn>
                    <v-btn v-else icon @click="expand"><v-icon>mdi-arrow-collapse</v-icon></v-btn>
                </v-card-actions>

                <v-expansion-panels 
                    v-model="panel" 
                    focusable 
                    flat 
                    multiple
                >                

                    <v-expansion-panel>
                        <v-expansion-panel-header><h3>Monetary</h3></v-expansion-panel-header>
                        <v-expansion-panel-content>
                            
                            <v-container class="pa-8">
                                <v-row class="px-4" :no-gutters="set_gutters">
                                    <v-col cols="12" sm="6" align="center">
                                        <v-text-field
                                            v-model="buy_in"
                                            label="Buy-in"
                                            type="number"
                                            step="0.01"
                                            prefix="$"
                                            >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" align="center">
                                        <v-btn
                                            color="white"
                                            @click="percentage_mode = !percentage_mode"
                                        >
                                        Change to {{ percentage_mode ? "$" : "%" }} Mode
                                        </v-btn>
                                    </v-col>
                                    <!-- <v-col cols="12" sm="3" align="center">
                                        <v-btn
                                            color="white"
                                            @click="validateFormValues()"
                                        >
                                            Test Form
                                        </v-btn>
                                    </v-col> -->
                                </v-row>
                                <v-row class="px-4" :no-gutters="set_gutters">
                                    <v-col align="center">
                                        <v-btn
                                            color="white"
                                            @click="add_awards"
                                        >
                                        <v-icon left >mdi-seal</v-icon>
                                        {{ (prizes_list === null || prizes_list.length === 0) ? "Add Awards" : "Add Another Award" }}
                                        </v-btn>  
                                    </v-col>
                                </v-row>
                            </v-container>

                            <v-container
                                v-for="(item, k) in this.prizes_list"
                                :key="k"
                                class="pa-0"
                            >
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn icon large @click="remove_awards(k)"><v-icon>mdi-delete</v-icon></v-btn>
                                </v-card-actions>

                                <v-row class="px-4" :no-gutters="set_gutters">
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            v-model="item.place"
                                            label="Place"
                                            type="number"
                                            step="1"
                                        >
                                    </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                    <v-text-field
                                        v-model="item.value"
                                        label="Value"
                                        type="number"
                                        :step='(percentage_mode ? "1" : "0.01")'
                                        :prefix='(percentage_mode ? "%" : "$")'
                                        :error="value_error"
                                        :error-messages="( value_error ? 'Value cannot be over 100%' : '')"
                                        >
                                    </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" class="ml-auto">
                                        <v-textarea
                                        v-model="item.text"
                                        label="Description"
                                        rows="2"
                                        >

                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <!-- <v-row class="px-4 mt-0" :no-gutters="set_gutters">
                                    <v-col cols="12" class="ml-auto">
                                        <v-textarea
                                        v-model="item.text"
                                        label="Description"
                                        >

                                        </v-textarea>
                                    </v-col>
                                </v-row> -->
                                <v-divider v-if="k != prizes_list.length-1" class="mx-2"></v-divider> 
                            </v-container>                    
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header><h3>Criteria</h3></v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <!-- <v-row
                            justify="start"
                            >
                                <v-col sm="3">
                                    <v-card-title 
                                        class="pt-0 black--text"
                                        ref="comp_textarea"
                                    >Species Allowed:
                                    </v-card-title>
                                </v-col>
                                <v-col sm="4"
                                >
                                    <v-autocomplete
                                        v-model="species_allowed"
                                        :items="species"
                                        item-text='common_name'
                                        item-value="id"
                                        label="Species"
                                        multiple
                                        chips
                                        ></v-autocomplete>
                                </v-col>
                            </v-row> -->
                            
                            <v-container class="pa-8">
                                <v-row>
                                    <v-col align="center">
                                        <v-btn
                                            color="white"
                                            @click="add_species"
                                        >
                                        <v-icon left >mdi-fish</v-icon>
                                        {{ (species_list === null || species_list.length === 0) ? "Add Species" : "Add Another Species" }}
                                        </v-btn>  
                                    </v-col>
                                </v-row>
                            </v-container>

                            <v-container
                                v-for="(item, k) in this.species_list"
                                :key="k"
                                class="pa-0"
                            >
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn icon large @click="remove_species(k)"><v-icon>mdi-delete</v-icon></v-btn>
                                </v-card-actions>

                                <v-row class="px-4">
                                    <v-col cols="12" sm="6">
                                        <v-autocomplete
                                            v-model="item.species_id"
                                            :items="species"
                                            item-text='common_name'
                                            item-value="id"
                                            label="Species"
                                            ></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row class="px-4">
                                    <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="item.lower_slot"
                                        label="Minimum Length"
                                        type="number"
                                        step="0.01"
                                        suffix="cm"
                                        >
                                    </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            v-model="item.upper_slot"
                                            label="Maximum Length"
                                            type="number"
                                            step="0.01"
                                            suffix="cm"
                                            >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="px-4">
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            v-model="item.lower_weight"
                                            label="Minimum Weight"
                                            type="number"
                                            step="0.01"
                                            suffix="lb."
                                            >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            v-model="item.upper_weight"
                                            label="Maximum Weight"
                                            type="number"
                                            step="0.01"
                                            suffix="lb."
                                            >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="px-4 pb-4 mt-0">
                                    <v-col cols="12" sm="6">
                                    <v-radio-group
                                        v-model="item.score"
                                        label="Score By"
                                        class="mt-0"
                                        row
                                    >
                                        <v-radio label="Length" value="length"></v-radio>
                                        <v-radio label="Weight" value="weight"></v-radio>
                                    </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-divider v-if="k != species_list.length-1" class="mx-2"></v-divider> 
                            </v-container>                    
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- <v-expansion-panel 
                        :key="1"
                        class="blue lighten-4 mt-2" 
                    >
                    <v-expansion-panel-header>
                        <template v-slot>
                        <v-row no-gutters>
                            <v-col cols="4">
                            Prizes:
                            </v-col>
                        </v-row>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-textarea class="pa-4"
                        v-model="prize_list"
                        placeholder="The prizes give out for winning or placing within the competition. Example:
                1st place: 50$
                2nd Place: 25$
                3rd Place: 5$"
                        ></v-textarea>
                    </v-expansion-panel-content>
                    </v-expansion-panel> -->

                    <v-expansion-panel>
                        <v-expansion-panel-header><h3>Rules</h3></v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-container class="px-0 pt-4 pb-0">
                                <v-textarea class="px-4"
                                    v-model="rules"
                                    outlined
                                    hide-details
                                    placeholder="The host may enter rules specific to this competition here"
                                ></v-textarea>     
                            </v-container>
                        </v-expansion-panel-content>
                    </v-expansion-panel>                

                    <v-expansion-panel>
                        <v-expansion-panel-header><h3>Blackout Period</h3></v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-container class="pa-8">
                                <v-row>
                                    <v-col align="center">
                                        <v-btn
                                            color="white"
                                            @click="add_blackout"
                                        >
                                            <v-icon left >mdi-eye-off</v-icon>
                                            {{ blackout_list.length >= 1? "Add Another Blackout" : "Add Blackout"}}
                                        </v-btn>  
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-container
                                v-for="(item, k) in this.blackout_list"
                                :key="k"
                                class="pa-0"
                            >
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn icon large @click="remove_blackout(k)"><v-icon>mdi-delete</v-icon></v-btn>
                            </v-card-actions>

                            <v-row class="px-4" align="center">
                                <v-col cols="6" sm="6">
                                    <v-menu
                                        v-model="item.blackout_start_menu"
                                        :close-on-content-click="false"
                                        transition="slide-x-transition"
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="item.blackout_start_date"
                                            label="Start Date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="item.blackout_start_date"
                                            no-title
                                            scrollable
                                            @input="item.blackout_start_menu = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>                            
                                <v-col cols="6" sm="6">
                                    <input 
                                        name="shift_start_time" 
                                        type="time"
                                        class="form-input w-full relative z-10"
                                        placeholder="Start time"
                                        v-model="item.blackout_start_time"
                                    />

                                <!-- <v-menu
                                    ref="menu"
                                    v-model="item.blackout_start_time_menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    :return-value.sync="item.blackout_start_time"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="item.blackout_start_time"
                                        label="Start Time"
                                        prepend-icon="mdi-clock-time-four-outline"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-time-picker
                                    v-if="item.blackout_start_time_menu"
                                    v-model="item.blackout_start_time"
                                    full-width
                                    @click:minute="$refs.menu.save(item.blackout_start_time)"
                                    ></v-time-picker>
                                </v-menu> -->
                                <!-- @click:minute="$refs.menu3.save(item.blackout_start_time)" -->

                                </v-col>
                            </v-row>
                            <v-row class="px-4" align="center">
                                <v-col cols="6" sm="6">
                                    <v-menu
                                        v-model="item.blackout_end_menu"
                                        :close-on-content-click="false"
                                        transition="slide-x-transition"
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="item.blackout_end_date"
                                            label="End Date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="item.blackout_end_date"
                                            no-title
                                            scrollable                                    
                                            @input="item.blackout_end_menu = false"
                                        ></v-date-picker>
                                    </v-menu>   
                                </v-col>
                                <v-col cols="6" sm="6" >
                                    <input 
                                        name="blackout_end_time" 
                                        type="time"
                                        class="form-input w-full relative z-10"
                                        placeholder="End time"
                                        v-model="item.blackout_end_time"
                                    />
                                </v-col>
                            </v-row>
                            <v-divider v-if="k != blackout_list.length-1" class="mx-2"></v-divider> 
                            </v-container>
                            </v-expansion-panel-content>      
                            
                        <!-- <v-row
                            justify="start"
                        >
                        <v-col md="2">
                            <v-card-title 
                                class="pt-0 black--text"
                                ref="comp_textarea"
                            >Blackout Period:
                            </v-card-title>
                        </v-col>
                        <v-col md="2"
                        >
                            <v-text-field
                                v-model="blackout_time"
                                type="number"
                                step="1"
                                suffix="Hours"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col md="2">
                            <v-card-title 
                                class="pt-0 black--text"
                                ref="comp_textarea"
                            >Publishing Delay:
                            </v-card-title>
                        </v-col>
                        <v-col md="2"
                        >
                            <v-text-field
                                v-model="delay_time"
                                type="number"
                                step="1"
                                suffix="Hours"
                            >
                            </v-text-field>
                        </v-col>
                        </v-row> -->                            

                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header><h3>Side Pot</h3></v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-container class="pa-8">
                                <v-row>
                                    <v-col align="center">
                                        <v-btn
                                            color="white"
                                            @click="add_sidepot"
                                        >
                                            <v-icon left >mdi-cash-multiple</v-icon>
                                            {{ has_sidepot === true? "Remove Side Pot" : "Add Side Pot"}}
                                        </v-btn>  
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-container v-if="has_sidepot">
                                <v-row class="px-2">
                                    <v-col cols="12" sm="6">
                                        <v-select
                                            v-model="sidepot_scoring"
                                            :items="[
                                                {value: 1, text: 'Total Length'}, 
                                                {value: 2, text: 'Total Weight'}, 
                                                {value: 3, text: 'Total Fish Caught'},
                                                {value: 4, text: ((this.number_of_fish > 1) ? this.number_of_fish + ' Longest Fish' : 'Longest Fish')}, 
                                                {value: 5, text: ((this.number_of_fish > 1) ? this.number_of_fish + ' Heaviest Fish' : 'Heaviest Fish')}
                                                ]"
                                            label="Side Pot Scoring"
                                        ></v-select>                           
                                    </v-col>                            
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            v-model="sidepot_amount"
                                            label="Total"
                                            type="number"
                                            step="0.10"
                                            prefix="$"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-expansion-panel-content>
                    </v-expansion-panel> 

                </v-expansion-panels>
                <v-row 
                    class="mt-4 px-6" 
                    v-if="(this.region === 'Manitoba' && ((this.team_limit * this.team_size) >= 25))"
                >
                    <v-col>
                        <v-checkbox class="ma-0 mt-1 pa-0" v-model="mb_form">
                            <template v-slot:label>
                                <a
                                    @click="mb_dialog = true"
                                >
                                CFA-Competitive Fishing Event Application Submited
                                </a>
                            </template>
                        </v-checkbox>
                    </v-col>
                </v-row>
                
                <v-row class="mt-4 px-6">
                    <v-col>
                        <v-checkbox class="ma-0 mt-1 pa-0" v-model="user_agreement">
                            <template v-slot:label>
                                <a
                                    @click="agree_dialog = true"
                                >
                                User Agreement
                                </a>
                            </template>
                        </v-checkbox>
                    </v-col>
                </v-row>

                <v-card-actions class="ma-0 justify-center">           
                    <v-btn 
                        elevation="5"
                        dark
                        color="indigo"
                        :loading="loading"
                        @click="create_comp"
                    >
                        <v-icon left >mdi-clipboard-check</v-icon>
                        Create Competition
                    </v-btn>    
                </v-card-actions>                     
            </v-card>
        <v-card
            v-else
            :class="set_padding_margins"
        >
                <v-card-text>
                    <v-row class="justify-center mb-4">
                        <h2>Competition Has Been Created!</h2> 
                    </v-row>
                    <v-row class="justify-center">
                        <v-btn @click="changePage('complist')">Show Upcoming Competitions</v-btn> 
                    </v-row>   
                </v-card-text>
            </v-card>
        </v-card>
        <v-dialog
            v-model="mb_dialog"
            width="500px"
        >
            <v-card class="pa-4 pt-6">
                <v-card-text> <a href="https://www.manitobaelicensing.ca/purchase-special-license.page#:~:text=CFA%2DCompetitive%20Fishing%20Event%20Application">CFA-Competitive Fishing Event Application Form</a></v-card-text>
                <v-card-text>
                    Persons and organizations holding fishing derbies or tournaments that have 25 or more participants must obtain a free Competitive Fishing Event Licence four weeks prior to the event. A licence can be obtained online at: www.manitobaelicensing.ca or at a regional Manitoba Natural Resources and Northern Development office. Contact a regional office for more information. All participants must have a Manitoba Angling Licence unless exempt. All walleye, sauger, trout, smallmouth bass, and channel catfish entered must be released. All fish caught and retained for use in a Competitive Fishing Event are considered to be in possession and part of the angler’s daily limit. Event organizers and all participants must adhere to the Aquatic Invasive Species (AIS) Regulation under The Water Protection Act.
                </v-card-text>
            </v-card>
        </v-dialog>   
        <v-dialog
            v-model="agree_dialog"
            width="500px"
        >        
            <v-card class="pa-4 pt-6">
                <!-- <v-card-text> <a href="https://www.manitobaelicensing.ca/purchase-special-license.page#:~:text=CFA%2DCompetitive%20Fishing%20Event%20Application">CFA-Competitive Fishing Event Application Form</a></v-card-text> -->
                <v-card-text>Temp User Agreement.</v-card-text>
            </v-card>            
        </v-dialog>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
    components:{
    },
    computed: {
        ...mapState('leaderboard', {
            species: state => state.species,
            regions: state => state.regions
        }),
        font_size_title(){
            return !this.$vuetify.breakpoint.xs ? "text-h4 mb-0" : "text-h6 mb-0"
        },       
        font_size_value(){
            return !this.$vuetify.breakpoint.xs ? "text-h5" : "text-h6"
        },    
        set_gutters(){
            return !this.$vuetify.breakpoint.xs ? false : true
        },     
        set_padding_margins(){
            return !this.$vuetify.breakpoint.xs ? "pa-4 ma-3" : "ma-3"
        }, 
    },
    data() {
        return {
            name: '',
            host: null,
            start_date: null,
            end_date: null,
            location: '',
            buy_in: 10,
            prize_pool: 0,
            // prize_list:null,
            team_size:1,            
            visibility:'Public',
            type:'Open',
            species_allowed:[],            
            rules:null,
            start_menu:false,
            end_menu:false,
            visibility_list:["Public", "Private"],
            type_list:["Open", "Request", "Invite Only"],
            max_team_size:[1, 2, 3, 4, 5],
            max_fish_amount:[1, 2, 3, 4, 5],
            set_buy_ins:[10, 20, 30, 40, 50, 75, 100, 150, "Other"],
            team_limit: '10',
            loading: false,
            error: false,
            panel:[0],
            expanded: false,
            panels: 4,
            // region_list: ["Manitoba", "Alberta", "British Columbia", "Ontario", "Saskatchewan", "Ontario", "New Brunswick", "Newfoundland + Labrador", "Northwest Territories", "Nova Scotia", "Nunavut", "P.E.I.", "Yukon"],
            region_list: [],
            region: '',
            mb_dialog: false,
            mb_form: false,
            agree_dialog: false,
            user_agreement: false,
            error_msg: "",
            start_time: null,
            start_time_menu: false,
            end_time: null,
            end_time_menu: false,
            species_list: [],
            prizes_list: [],
            // {
            //     species_id: '',
            //     lower_slot:null,
            //     upper_slot:null,
            //     lower_weight:null,
            //     upper_weight:null,
            //     score_by: "length"
            // }
            created: false,
            buy_in_other:false,
            buy_in_other_value:null,
            blackout_time: "",
            delay_time: "",
            blackout_list: [],
            // {
            //     blackout_start_date: '',
            //     blackout_start_time: '',
            //     blackout_end_date: '',
            //     blackout_end_time: '',
            // }
            require_photo:false,
            sort_type:"Longest '#' Fish",
            sort_list:["Longest '#' Fish", "Total Length","Heaviest '#' Fish", "Total Weight", "Total Fish Caught"],
            number_of_fish: 1,

            sidepot_options:["Length", "Weight"],
            sidepot_scoring: null,
            has_sidepot: false,
            amount_needed: true,
            sidepot_amount: null,

            field_rules: {
                required: value => !!value || 'Required',
            },
            percentage_mode: false,
            value_error: false,
        }
    },
    methods: {
        create_comp(){
            // Validate all form data
            this.validateFormValues()
            // check if there are any errors
            // if(this.value_error){

            // }
            if(this.user_agreement){
                if((this.region === 'Manitoba' && ((this.team_limit * this.team_size) >= 25) && this.mb_form !== true)){
                    this.error = true
                    this.error_msg = "Please Submit and a CFA-Competitive Fishing Event Application Form"
                }else{
                    this.error = false
                    this.error_msg = ""
                    // this.loading = true
                    let vis_number = 0
                    switch (this.visibility){
                        case "Public":
                            vis_number = 1
                            break;
                        case "Private":
                            vis_number = 0
                            break;
                        default:
                            this.error = true
                    }
                    let type_number = 0
                    switch (this.type){
                        case "Open":
                            type_number = 1
                            break;
                        case "Request":
                            type_number = 2
                            break;
                        case "Invite Only":
                            type_number = 3
                            break;
                        default:
                            this.error = true
                    }
                    let score_type = 1
                    let amount = null
                    switch (this.sort_type){
                        case "Total Length":
                            score_type = 1
                            break;
                        case "Total Weight":
                            score_type = 2
                            break;
                        case "Total Fish Caught":
                            score_type = 3
                            break;
                        case "Longest '#' Fish":
                            score_type = 4
                            amount = this.number_of_fish
                            break;
                        case "Heaviest '#' Fish":
                            score_type = 5
                            amount = this.number_of_fish
                            break;
                        default:
                            this.error = true
                    }
                    let sidepot = null
                    if(this.has_sidepot){
                        sidepot = this.sidepot_scoring
                    }
                        // trying to solve/test null insert issue
                    let host_val = null
                    if(this.host !== null){
                        host_val = this.host
                    }
                    let buy_in_value = this.buy_in
                    if(this.buy_in_other){
                        buy_in_value = this.buy_in_other_value
                    }
                        // **TODO Need to make error checking for species list with blank id (species is not selected)
                        // **TODO Should make location before data so that can filter for null values or take in the region ID so that it can be used easier
                    let fixed_start = this.start_date + " " + this.start_time
                    let fixed_end = this.end_date + " " + this.end_time
                    
                    let db_start = this.getDBTime(fixed_start)
                    let db_end = this.getDBTime(fixed_end)

                    let fixedBlackout = []
                    this.blackout_list.forEach(element => {
                        let bl_st = this.getDBTime((element.blackout_start_date + " " + element.blackout_start_time))
                        let bl_en = this.getDBTime((element.blackout_end_date + " " + element.blackout_end_time))

                        fixedBlackout.push({
                            blackout_start_date: bl_st.substring(0,10),
                            blackout_start_time: bl_st.substring(11,19),
                            blackout_end_date: bl_en.substring(0,10),
                            blackout_end_time: bl_en.substring(11,19),
                        })
                    });
                    let photo_required = 0;
                    if(this.require_photo === true){
                        photo_required = 1;
                    }
                    let data = {
                        name: this.name,
                        host: host_val,
                        start_date: db_start,
                        end_date: db_end,
                        location: this.region + ": " + this.location,
                        buy_in: buy_in_value,
                        prize_pool: this.prize_pool,
                        team_size: this.team_size,
                        visibility: vis_number,
                        type: type_number,
                        rules: this.rules,
                        team_limit: this.team_limit,
                        species: this.species_list,
                        blackout_date: fixedBlackout,
                        require_photo: photo_required,
                        sidepot: sidepot,
                        sidepot_amount: this.sidepot_amount,
                        has_sidepot: this.has_sidepot,
                        score_type: score_type,
                        amount: amount,
                        prizes: this.prizes_list

                        // delay_date: delay_date
                    }
                    // console.log(data)
                    // **TODO, Do not list as created it error happend
                    this.$store.dispatch('leaderboard/recordCompetition', data)
                    .then(() => {
                        this.error = false
                        this.err_message = null
                    })
                    .catch((err) => {
                        this.error = true
                        this.err_message = 'Retry: ' + err
                    })
                    .finally(() => {
                        this.loading = false
                        if(!this.error){
                            this.created = true
                        }
                        
                        // console.log(data)
                    })
                }
            } else {
                this.error = true
                this.error_msg = "Please read and accept the user agreement"
            }
        },
        add_species(){
            // 
            this.species_list.push({
                species_id: '',
                lower_slot:null,
                upper_slot:null,
                lower_weight:null,
                upper_weight:null,
                score_by: "length"
            })
            // console.log(this.species_list)
        },
        add_awards(){
            // 
            this.prizes_list.push({
                place: (this.prizes_list.length + 1),
                value: 1.00,
                text: '',
            })
            // console.log(this.species_list)
        },
        check_if_amount_needed(){
            let answer = false
            if(this.sort_type === "Longest '#' Fish" || this.sort_type === "Heaviest '#' Fish"){
                answer = true
            }
            else {
                this.number_of_fish = 1
            }
            this.amount_needed = answer
        },
        add_sidepot(){
            if(this.has_sidepot){
                this.sidepot_scoring = null
                this.sidepot_amount = null
                this.has_sidepot = false
            }else{
                this.has_sidepot = true
            }
        },
        add_blackout(){
            // 
            this.blackout_list.push({
                blackout_start_date: '',
                blackout_start_time: '',
                blackout_end_date: '',
                blackout_end_time: '',
            })
            // console.log(this.blackout_list)
        },
        remove_species(item_number){
            this.species_list.splice(item_number,1)
            // console.log(this.species_list)
        },
        remove_awards(item_number){
            this.prizes_list.splice(item_number,1)
        },
        remove_blackout(item_number){
            this.blackout_list.splice(item_number,1)
        },
        expand(){
            if(!this.expanded){
                this.panel = [...Array(this.panels).keys()].map((k,i) => i)
            }else{
                this.panel = []
            }
            // console.log(this.panel)
            this.expanded = !this.expanded
        },
        validateFormValues(){
            //Check if percentage is less that 100
            if(this.percentage_mode){
                let total_percent = 0
                this.prizes_list.forEach(item => {
                    total_percent += Number(item.value)
                })
                if(total_percent > 100){
                    this.value_error = true
                }else{
                    this.value_error = false
                }
                // console.log(total_percent)
            }
        },
        buy_in_value_check(selected){
            // console.log(selcted)
            if(selected == "Other"){
                this.buy_in_other = true
            }
        },
        changePage(page){
            this.$router.push(('/app/leaderboard/' + page))
        },
        getDBTime(date){
            let localdate = new Date(date)
            return (localdate.toISOString().slice(0, -1))
        }
    },
    mounted() {
        this.$store.dispatch('leaderboard/getSpecies')
        this.$store.dispatch('leaderboard/getRegions').then(() => {
            this.region_list = Object.values(this.regions)
      })
    },
}
</script>

<style scoped>
>>>.col{
    padding: 12px;
    padding-bottom: 0px;
    padding-top: 0px;
}
>>>.card-title{
    padding: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
    margin: 0px;
}

.btn-wrap-text {
    text-align: left;
    height: auto !important;
    max-width: 100%;
    white-space: normal;
  &.v-btn:not(.v-btn--round).v-size--default {
    padding: 10px 16px;
  }
  .v-btn__content {
    flex: 1 0 fit-content;
  }
}

</style>