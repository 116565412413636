<template>
    <span>
        <l-control 
            class="custom-control"
            position="topleft"
            style="padding: 0.15em 0.15em 0.15em 0.15em !important;"
        >
            <!-- <v-tooltip right :disabled=is_mobile v-model="showHelp">
                <template v-slot:activator="{ attrs }"> -->
                    <v-btn                 
                        icon
                        small
                        @click="toggleHelp"
                        :disabled="lockMapOn"
                    >
                    <v-icon :color="'icetimeblue'">mdi-help-box</v-icon>                       
                    </v-btn>
                    <v-dialog v-model="showHelp" scrollable max-width="500px" width="unset" @click:outside="toggleHelp"/>
                <!-- </template>
            </v-tooltip> -->
        
        </l-control>
    </span>
</template>

<script>
import { LControl } from 'vue2-leaflet'
import { mapState } from 'vuex'

export default {
data() {
    return {
    }
},
components: {
    LControl,
},
computed: {
    ...mapState('map', {
        visibleLayer: state => state.visibleLayer,
        lockMapOn: state => state.lockMapOn,
        showHelp: state => state.showHelp,
    }),

    ...mapState('user', {
        user: state => state.user
    }),
    is_mobile() {
        return this.$vuetify.breakpoint.xs ? this.showHelp ? false : true : false
    }         
},
created() {
    
},
watch: {
},
methods: {
    clickCancel() {
    },
    toggleHelp() {
        this.$store.dispatch('map/setShowHelp', !this.showHelp)
    },
},
update() {
},
mounted() {
},
props: [
    'marker_location'
]
}
</script>

<style scoped>
</style>