<template>
  <v-container class="mb-15 pb-15">
    <v-layout class="mb-6" cols="12" md12 lg8 offset-lg2 xl6 offset-xl3 row>
      <v-flex xs12>
        <p class="text-h5 ma-8">
          <v-icon class="mr-2">fa-key</v-icon>
          Change Password
        </p>
      </v-flex>
      <v-flex xs12 sm5 md6 lg4>
        <v-card class="mx-5 my-4 pa-4" color="amber lighten-3" flat>
          <v-card-title class="text-subtitle-1 font-weight-bold">
            Keep your account secure with good password practices!
          </v-card-title>
          <v-card-text class="text-subtitle-2">
            <ul>
              <li>Keep it a secret</li>
              <li>Contains 10 or more characters</li>
              <li>Uses letters, numbers & symbols</li>
              <li>Not re-used from other accounts</li>
            </ul>
          </v-card-text>
          <v-alert 
            v-if="$vuetify.breakpoint.smAndUp"
            class="text-caption ma-3" 
            color="amber"
          >
            <v-layout class="px-3">
              <v-flex
                align-self-center 
                class="text-center" 
              >
                <v-icon class="pr-4 pb-3">fa-user-shield</v-icon>
              </v-flex>
              <v-flex>
                For better passwords, check out these resources from the <i>Government of Canada</i>:
              </v-flex>
            </v-layout>
            <v-layout class="font-weight-light pa-3 mt-0" row>
              <ul>
                <li><a href="https://www.getcybersafe.gc.ca/en/blogs/how-strong-your-password-five-ways-evaluate" target="_blank">How strong is your password? Five ways to evaluate</a></li>
                <li><a href="https://www.getcybersafe.gc.ca/en/blogs/good-better-best-how-you-can-make-ok-password-even-better" target="_blank">Good, better, best: How you can make an OK password even better</a></li>
              </ul>
            </v-layout>
          </v-alert>
        </v-card>
      </v-flex>
      <v-flex xs12 sm7 md6 lg8>
        <v-card class="mx-5 my-4 pa-4">
          <v-form
            lazy-validation
            ref="password_form"
          >
            <v-card-text>
              <v-text-field 
                v-model="password" 
                :append-icon="show_current ? 'mdi-eye' : 'mdi-eye-off'"
                autofocus
                label="Current Password" 
                required
                :rules="[notNullPassword]"
                :type="show_current ? 'text' : 'password'"
                validate-on-blur
                @click:append="show_current = !show_current"
              />
              <v-text-field 
                v-model="password_new"
                :append-icon="show_new ? 'mdi-eye' : 'mdi-eye-off'"
                label="New Password" 
                required
                hint="Password length must be at least 10 characters"
                :rules="[notNullPassword,tenOrGreater]"
                :type="show_new ? 'text' : 'password'"
                validate-on-blur
                @click:append="show_new = !show_new"
              />
              <v-text-field 
                v-model="password_confirm"
                :append-icon="show_new ? 'mdi-eye' : 'mdi-eye-off'"
                id="confirmPassword" 
                label="Confirm New Password" 
                :rules="[comparePasswords]"
                validate-on-blur
                :type="show_new ? 'text' : 'password'"
                @click:append="show_new = !show_new"
              />
            </v-card-text>
          </v-form>
          <v-card-actions class="ma-2">
            <v-btn :loading="loading" @click.prevent="update_password">
              Update Password
            </v-btn>
          </v-card-actions>
          <v-alert v-if="message" class="ma-4" type="info">{{ this.message }}</v-alert>
        </v-card>
      </v-flex>
    </v-layout>
    <Footer />
  </v-container>
</template>

<script>
// import { mapState } from 'vuex'
import Footer from '../../../components/Footer.vue'

export default {
  data() {
    return {
      disabled: true,
      loading: false,
      message: '',
      password: '',
      password_confirm: '',
      password_new: '',
      show_current: false,
      show_new: false
    }
  },
  components: {
    Footer,
  },
  computed: {
    comparePasswords() {
      return () => (this.password_new === this.password_confirm) || 'Passwords do not match.'
    },
    notNullPassword() {
      return () => this.password_new != "" || "This field is required."
    },
    tenOrGreater() {
      return () => this.password_new.length > 9 || "New password needs to be 10 or more characters."
    },
    includeSpecialChar() {
      return () => !this.password.match(/^[a-z0-9]+$/i) || "Password needs at least 1 special character: ~`!@#$%^&*()-_+={}[]|\\;:\"<>,./?"
    },    
  },
  methods: {
    update_password() {
      if (this.$refs.password_form.validate()) {
        let data = {
          current: this.password,
          new: this.password_new,
        }

        this.$store.dispatch('user/updatePassword', data)
        .then((resp) => {
          this.message = resp.data
        })
        .catch(err => {
          this.message = 'Update failed! Message: ' + err.response.data
          // if(err.response.status == '403') {
          //   this.$store.dispatch('logout')
          //   this.$store.dispatch('setDialog', { 
          //     show: true, 
          //     message: err.response.data, 
          //     status: err.response.status })
          // }
        })
      }
    }
  },
  mounted() {
    this.$store.dispatch('setTab', 'profile')
  }
}
</script>

<style scoped>
>>>a, >>>a:active, >>>a:visited { 
  color: #000000!important;
}

>>>.hint {
  position: absolute;
  bottom: 0;
  right:0;
}
</style>