<template>
  <v-select
    class="mx-2 my-4 text-body-2 form-label"
    :color="color"
    dense
    hide-details
    item-value="id"
    item-text="name" 
    :items="access_point_conditions"
    label="Access Point Condition"
    type="text"
    v-model="selected_condition"
    @change="setSelectedCondition"
  >
    <template slot="selection" slot-scope="data">
      <v-layout class="mx-3 my-1" row>
        <v-flex v-if="data.item.id != '3'" align-self-center justify-center xs3>
          <v-icon small>{{ getIconName(data.item.id) }}</v-icon>
        </v-flex>
        <v-flex v-else align-self-center justify-center xs3>
          <v-img contain width="16" :src="quad_icon" style="filter: invert(43%) sepia(0%) saturate(4209%) hue-rotate(98deg) brightness(103%) contrast(78%);"/>
        </v-flex>
        <v-flex align-self-center>
          {{ data.item.name }}
        </v-flex>
      </v-layout>
    </template>
    <template slot="item" slot-scope="data">
      <v-layout class="mx-3 my-1" row>
        <v-flex v-if="data.item.id != '3'" align-self-center justify-center shrink xs2>
          <v-icon small>{{ getIconName(data.item.id) }}</v-icon>
        </v-flex>
        <v-flex v-else align-self-center justify-center shrink xs2>
          <v-img contain width="16" :src="quad_icon" style="filter: invert(43%) sepia(0%) saturate(4209%) hue-rotate(98deg) brightness(103%) contrast(78%);"/>
        </v-flex>
        <v-flex align-self-center>
          {{ data.item.name }}
        </v-flex>
      </v-layout>
    </template>
  </v-select>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('access_points', {
      access_point_conditions: state => state.access_point_conditions,
    })
  },
  data() {
    return {
      quad_icon: require('/public/img/quad.svg'),
      selected_condition: '',
    }
  },
  methods: {
    getIconName(id) {
      let iconname = ''
      switch(id) {
        case '1':
          iconname = 'fa-ban'
          break
        case '2':
          iconname = 'fa-walking'
          break
        case '3':
          iconname = 'mdi-atv'
          break
        case '4':
          iconname = 'mdi-snowmobile'
          break
        case '5':
          iconname = 'fa-truck-pickup'
          break
        case '6':
          iconname = 'fa-truck-monster'
          break
      }
      return iconname
    },
    setSelectedCondition() {
      this.$store.dispatch('access_points/setSelectedCondition', this.selected_condition)
    }
  },
  mounted() {
    if (this.access_point_conditions.length < 1) {
      this.$store.dispatch('access_points/getAccessPointConditions')
    }
  },
  props: {
    color: {
      type: String,
      default: ''
    }
  }
}

</script>