<template>
  <v-card
    class="ma-5 pa-4"
  >
    <ComingSoonChip />
    <v-card-title>
      Ice Time Academy
    </v-card-title>
    <v-card-text class="text-body-1">
      Ice smart anglers and riders reduce the risk of lake ice travel by learning about what they walk and ride on.  
    </v-card-text>
    <v-card-text>
      ( Photos with hazardous ice travel here. Tire and ice photo. )
    </v-card-text>
    <v-card-text class="text-body-1">
      The Ice Time&trade; Academy will teach you about the processes of lake ice formation, deformation, and decay. You will be able to identify many types of lake ice and hazards, and the type and timing of risk factors that confront you.
    </v-card-text>
    <v-card-text class="text-body-1">
      You will learn and use the right ice feature terminology so talking about lake ice types and hazards is meaningful.
    </v-card-text>
    <v-card-text>
      ( Images of ice types and hazards here. )
    </v-card-text>
  </v-card>
</template>
  
<script>
import ComingSoonChip from './ComingSoonChip.vue';

export default {
  data() {
    return {
    };
  },
  methods: {
  },
  components: { 
    ComingSoonChip
  }
}
</script>
  