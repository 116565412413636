<template>
  <!-- <v-flex xs12 sm12 py-8 text-center> -->
  <v-footer 
    absolute
    bottom
    class="pa-6"
    color="white"
    padless
  >
    <v-card 
      flat
      width="100%"
    >
      <v-card-text class="pa-1 text-center">
        &copy; 2024 — NextGen Environmental Research Inc. — All Rights Reserved
      </v-card-text>
      <v-card-text class="pa-1 text-caption text-center">
        <router-link to="/legal/privacy">Privacy Policy</router-link> | <router-link to="/legal/termsofuse">Terms of Use</router-link>
      </v-card-text>  
    </v-card>
    
  </v-footer>
  <!-- </v-flex> -->
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>
