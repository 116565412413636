<template>
    <v-container style="height: 88%;">
        
        <Footer />
    </v-container>
  </template>
  
  <script>
//   import { mapState } from 'vuex'
  import Footer from '../components/Footer.vue'
  
  export default {
    computed: {

    },
    components: {
      Footer,

    },
    mounted() {
      this.$store.dispatch('setMode', '')
    }
  }
  </script>