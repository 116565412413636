import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

// If API is needed
Vue.use(Vuex)
axios.defaults.baseUrl = Vue.$apiEndpointUrl

const state = {  
  access_point_conditions: [],
  access_point_editing: false,
  access_point_filter_conditions: [],
  access_point_new_location: [],
  access_points: [],
  geofence_access_points: [],
  geofence_record: [], // record of geoposts that were prompted to user\
  geopost_filter_themes: [],
  geopost_filter_time: '',
  pointAddMode: 'none',
  selected_access_point: null,
  selected_condition: '',
  selectedCursor: 'default',
  selected_geopost: '',
  selected_geopost_comments: [],  
  show_marker_dialog: false,
  show_tool: false,
  vote_filter: 'daily',
};

const getters = {
};

const mutations = {
  addGeofenceRecord(state, record) {
    state.geofence_record.push(record)
  },
  setAccessPointConditions(state, conditions) {
    state.access_point_conditions = conditions
  },
  setAccessPointEditing(state, editing) {
    state.access_point_editing = editing
  },
  setAccessPointFilterConditions(state, conditions) {
    state.access_point_filter_conditions = conditions
  },  
  setAccessPointNewLocation(state, location) {
    state.access_point_new_location = location
  },
  setAccessPoints(state, access_points) {
    access_points.forEach(ap => {
      let votes = []
      ap['votes_sum'] = 0
      ap['data'] = [
        { condition_id: 1, name: 'Impassable', votes: parseInt(ap['1']) },
        { condition_id: 2, name: 'Foot', votes: parseInt(ap['2']) },  
        { condition_id: 3, name: 'Quad', votes: parseInt(ap['3']) }, 
        { condition_id: 4, name: 'Snowmobile', votes: parseInt(ap['4']) }, 
        { condition_id: 5, name: '2WD', votes: parseInt(ap['5']) }, 
        { condition_id: 6, name: '4WD', votes: parseInt(ap['6']) }
      ]
      ap['data'].forEach(x => {
        ap['votes_sum'] += x.votes
      })
      ap['data'].forEach((y, i) => {
        votes[i] = y.votes
      })

      let max = Math.max(...votes)
      let conditions = [] // condition_ids that have the most votes
      ap['data'].forEach((item) => item.votes === max ? conditions.push(item.condition_id):null)
      ap['popular_votes'] = conditions

      delete ap['1']
      delete ap['2']
      delete ap['3']
      delete ap['4']
      delete ap['5']
      delete ap['6']
    })
    state.access_points = access_points
  },
  setGeofenceAccessPoints(state, access_points) {
    state.geofence_access_points = access_points
  },
  setGeopostFilterThemes(state, themes) {
    state.geopost_filter_themes = themes
  },  
  setGeopostFilterTime(state, time) {
    state.geopost_filter_time = time
  },  
  setPointAddMode(state, mode){
    state.pointAddMode = mode
  },  
  setSelectedAccessPoint(state, access_point) {
    if (access_point) {
      let votes = []
      access_point['votes_sum'] = 0
      access_point['data'] = [
        { condition_id: 1, name: 'Impassable', votes: parseInt(access_point['1']) },
        { condition_id: 2, name: 'Foot', votes: parseInt(access_point['2']) },  
        { condition_id: 3, name: 'Quad', votes: parseInt(access_point['3']) }, 
        { condition_id: 4, name: 'Snowmobile', votes: parseInt(access_point['4']) }, 
        { condition_id: 5, name: '2WD', votes: parseInt(access_point['5']) }, 
        { condition_id: 6, name: '4WD', votes: parseInt(access_point['6']) }
      ]
      access_point['data'].forEach(x => {
        access_point['votes_sum'] += x.votes
      })
      access_point['data'].forEach((y, i) => {
        votes[i] = y.votes
      })

      let max = Math.max(...votes)
      let conditions = [] // condition_ids that have the most votes
      access_point['data'].forEach((item) => item.votes === max ? conditions.push(item.condition_id):null)
      access_point['popular_votes'] = conditions

      delete access_point['1']
      delete access_point['2']
      delete access_point['3']
      delete access_point['4']
      delete access_point['5']
      delete access_point['6']
    }

    state.selected_access_point = access_point
  },
  setSelectedCondition(state, condition) {
    state.selected_condition = condition
  },
  setSelectedCursor(state, cursor){
    state.selectedCursor = cursor
  },  
  setSelectedGeopost(state, geopost_id) {
    state.selected_geopost = geopost_id
  },  
  setSelectedGeopostComments(state, comments) {
    state.selected_geopost_comments = comments
  },  
  setShowMarkerDialog(state, show) {
    state.show_marker_dialog = show
  },
  setShowTool(state, show) {
    state.show_tool = show
  },
  setVoteFilter(state, filter) {
    state.vote_filter = filter
  }
};

const actions = {
  addGeofenceRecord({commit}, record) {
    return new Promise((resolve) => {
      commit('addGeofenceRecord', record)
      resolve(record)
    })
  },
  checkGeofence({commit}, gps) {
    return new Promise((resolve,reject) => {
      axios.get(`geofence/${gps.latitude}/${gps.longitude}`)
      .then(resp => {
        let data = resp.data ? resp.data:[]
        commit('setGeofenceAccessPoints', Object.values(data))
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  createVote(_, vote) {
    return new Promise((resolve, reject) => {
      axios.post('vote', vote)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  deleteAccessPoint(_, geopost_id) {
    return new Promise((resolve, reject) => {
      axios.delete('apc/' + geopost_id)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getAccessPointConditions({commit}) {
    return new Promise((resolve,reject) => {
      axios.get('conditions')
      .then(resp => {
        let data = resp.data ? resp.data:[]
        commit('setAccessPointConditions', Object.values(data))
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getAccessPoint({commit, state}, ap_id) {
    return new Promise((resolve,reject) => {
      let req = state.vote_filter ? ('/' + state.vote_filter):''
      axios.get(`apc/${ap_id}${req}`)
      .then(resp => {
        let data = resp.data ? resp.data:[]
        commit('setSelectedAccessPoint', Object.values(data)[0])
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  getAccessPoints({commit,state}) {
    return new Promise((resolve,reject) => {
      let req = state.geopost_filter_time ? ('/' + state.geopost_filter_time):''
      axios.get('apc' + req)
      .then(resp => {
        let data = resp.data ? resp.data:[]
        commit('setAccessPoints', Object.values(data))
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },  
  recordAccessPoint(_, apcItem) {
    return new Promise((resolve,reject) => {
      axios.post('apc', apcItem)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  },
  setAccessPointEditing({commit}, editing) {
    return new Promise((resolve) => {
      commit('setAccessPointEditing', editing)
      resolve(editing)
    })
  },
  setAccessPointFilterConditions({commit}, conditions) {
    return new Promise((resolve) => {
      commit('setAccessPointFilterConditions', conditions)
      resolve(conditions)
    })
  },  
  setAccessPointNewLocation({commit}, location) {
    return new Promise((resolve) => {
      commit('setAccessPointNewLocation', location)
      resolve(location)
    })
  },
  setGeopostFilterThemes({commit}, themes) {
    return new Promise((resolve) => {
      commit('setGeopostFilterThemes', themes)
      resolve(themes)
    })
  },  
  setGeopostFilterTime({commit}, time) {
    return new Promise((resolve) => {
      commit('setGeopostFilterTime', time)
      resolve(time)
    })
  },  
  setPointAddMode({commit}, mode) {
    return new Promise((resolve) => {
      commit('setPointAddMode', mode)
      resolve(mode)
    })
  },  
  setSelectedAccessPoint({commit}, access_point) {
    return new Promise((resolve) => {
      commit('setSelectedAccessPoint', access_point)
      resolve(access_point)
    })
  },
  setSelectedCondition({commit}, condition) {
    return new Promise((resolve) => {
      commit('setSelectedCondition', condition)
      resolve(condition)
    })
  },
  setSelectedCursor({commit}, cursor) {
    return new Promise((resolve) => {
      commit('setSelectedCursor', cursor)
      resolve(cursor)
    })
  },  
  setSelectedGeopost({commit}, geopost_id) {
    return new Promise((resolve) => {
      commit('setSelectedGeopost', geopost_id)
      resolve(geopost_id)
    })
  },
  getSelectedGeopostComments({commit}, geopost_id) {
    return new Promise((resolve, reject) => {
      axios.get('geopost_comments/' + geopost_id)
      .then(resp => {
        if (resp.data) {
          commit('setSelectedGeopostComments', Object.values(resp.data))
        } else {
          commit('setSelectedGeopostComments', [])
        }
        resolve(resp)
      })
      .catch(err => {{
        reject(err)
      }})
    })
  },    
  setShowMarkerDialog({commit}, show) {
    return new Promise((resolve) => {
      commit('setShowMarkerDialog', show)
      resolve(show)
    })
  },
  setShowTool({commit}, show) {
    return new Promise((resolve) => {
      commit('setShowTool', show)
      resolve(show)
    })
  },
  setVoteFilter({commit}, filter) {
    return new Promise((resolve) => {
      commit('setVoteFilter', filter)
      resolve(filter)
    })
  },
  updateAccessPoint(_, point_data) {
    return new Promise((resolve,reject) => {
      axios.post('apc_update', point_data)
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}