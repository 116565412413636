<template>
    <v-container class="ma-0 pa-0 mb-10">
        <v-card class="blue lighten-5 pb-3">
            <v-card-title>Competition Leaderboard</v-card-title>
            <v-card
                :class="set_padding_margins"
            >
                <!-- <v-tabs
                    fixed-tabs
                    bg-color="primary"
                    class="mb-5"
                    v-model="active_tab"
                >
                    <v-tab v-if="!$vuetify.breakpoint.xs" @click="changePage('selected_comp')">Summary</v-tab>
                    <v-tab v-else @click="changePage('selected_comp')"><v-icon>mdi-information-box</v-icon></v-tab>
                    <v-tab v-if="!$vuetify.breakpoint.xs" @click="changePage('comp_roster')">Roster</v-tab>
                    <v-tab v-else @click="changePage('comp_roster')"><v-icon>mdi-list-box</v-icon></v-tab>
                    <v-tab v-if="!$vuetify.breakpoint.xs" @click="changePage('selected_leaderboard')">Leaderboard</v-tab>
                    <v-tab v-else @click="changePage('selected_leaderboard')"><v-icon>mdi-trophy</v-icon></v-tab>
                </v-tabs>   -->
                <v-row v-if="((competition.owner === user.id || user.status >= 9))" justify="end" class="ma-1 pa-4">
                    <v-btn 
                        class="justify-center"
                        elevation="5"
                        dark
                        color="indigo"
                        @click="changeLeaderboardPage($route.params.id)"                        
                    >
                        Return to Competition Leaderboard
                    </v-btn>
                </v-row> 
                <v-row v-if="fixed_teams.length == 0">
                    <v-container>
                        <v-row :no-gutters="set_gutters">
                            <v-col cols="12">
                                <v-card-text class="py-0">
                                    <p>No catches have been recorded yet!</p>
                                </v-card-text>                                
                            </v-col>
                        </v-row>                                
                    </v-container>                
                </v-row>

                <div v-else>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                            <v-btn v-if="!$vuetify.breakpoint.xs" width="180" @click="updateCatches()" :disabled="button_disabled">Refresh<v-icon right>mdi-refresh</v-icon></v-btn>
                            <v-btn v-else icon large @click="updateCatches()" :disabled="button_disabled"><v-icon right>mdi-refresh</v-icon></v-btn>     
                         
                    </v-card-actions>

            </div>  
            <v-container>
                <div v-for="(team, k) in fixed_teams" :key="k">
                    <v-card-title>{{ convertSpecial(team.name) }}</v-card-title>
                    <v-divider></v-divider>
                    <div v-for="(member, j) in team.members" :key="j" >
                        <v-card-subtitle>
                            <v-avatar class="ma-2" @click="showCard(member.member_id, member.profile_image)">
                                <v-img
                                    v-if="member.profile_image"        
                                    :src="(image_profile_start + member.profile_image)" 
                                    alt="Avatar"/>
                                <v-icon v-else large>mdi-account</v-icon>
                            </v-avatar>
                            {{ member.alias }}
                        </v-card-subtitle>

                        <v-data-table
                            dense
                            :headers="headers"
                            :items="member.catches"
                            :items-per-page="-1"
                            class="elevation-1"
                            :sort-by.sync="sortByValue"
                            :sort-desc.sync="sortDesc"        
                            @click:row="showImage"   
                        >
                        <!-- <template v-slot:item="{ item }">
                            <tr class="red" v-if="item.">
                                <td>{{ item.common_name }}</td>
                                <td>{{ item.length }}</td>
                                <td>{{ item.ai_length }}</td>
                                <td>{{ item.board_status }}</td>
                                <td>{{ item.qr_detected }}</td>
                                <td>{{ item.ai_status }}</td>
                                <td>{{ item.image }}</td>
                            </tr>
                            <tr class="red">
                                <td>{{ item.common_name }}</td>
                                <td>{{ item.length }}</td>
                                <td>{{ item.ai_length }}</td>
                                <td>{{ item.board_status }}</td>
                                <td>{{ item.qr_detected }}</td>
                                <td>{{ item.ai_status }}</td>
                                <td>{{ item.image }}</td>
                            </tr>
                        </template> -->
                        <template v-slot:item.image="{ item }">
                                <v-img         
                                    v-if="item.image"        
                                    :src="(image_start + item.image)" 
                                    max-height="100"
                                    max-width="100"
                                    alt="Catch"/>
                                <v-icon v-else large>mdi-image</v-icon>
                        </template>
                        <template v-slot:item.ai_status="{ item }">
                            <v-icon v-if="item.ai_status==2 && item.measurement_status==2 && item.qr_status==2 && item.board_status==2 && item.fish_status==2" large color="green">mdi-checkbox-marked</v-icon>
                            <v-icon v-else-if="item.ai_status==1 || item.measurement_status==1 || item.qr_status==1 || item.board_status==1 || item.fish_status==1" large color="red">mdi-close-box</v-icon>
                            <v-icon v-else large color="black">mdi-checkbox-blank-outline</v-icon>
                        </template>
                        <template v-slot:item.board_status="{ item }">
                            <div v-if="item.length && item.ai_length">{{ Math.round((item.length - item.ai_length) * 1000) / 1000 }} cm.<v-icon v-if="(item.length-item.ai_length) > 0.635 || (item.ai_length-item.length) > 0.635" color="orange">mdi-flag-variant</v-icon></div>
                            <!-- <div v-if="item.length && item.ai_length">{{ (item.length-item.ai_length) }}...<v-icon v-if="(1-(item.length-item.ai_length)) > 0.635 || 1-(item.ai_length-item.length) > 0.635" color="orange">{{ (item.length-item.ai_length) }}</v-icon></div> -->
                            <div v-else></div>
                            
                        </template>
                        <template v-slot:item.common_name="{ item }">
                            <!-- <v-icon v-if="item.status==0" color="orange">mdi-flag-variant</v-icon> -->
                            <div class="red--text" v-if="item.status==0">{{ item.common_name }}</div>
                            <div v-else>{{ item.common_name }}</div>
                            
                        </template>
                        <template v-slot:item.status="{ item }">
                            <v-icon v-if="item.status==0" color="red">mdi-flag-variant</v-icon>
                            <!-- <div class="red--text" v-if="item.status==0">{{ item.common_name }}</div> -->
                            <!-- <div v-else>{{ item.common_name }}</div> -->
                            
                        </template>
                        <template v-slot:item.length="{ item }">
                            <div v-if="item.length">{{ Math.round((item.length) * 1000) / 1000 }} cm.</div>
                            <div v-else></div>
                            
                        </template>
                        <template v-slot:item.ai_length="{ item }">
                            <div v-if="item.ai_length">{{ Math.round((item.ai_length) * 1000) / 1000 }} cm.</div>
                            <div v-else></div>
                            
                        </template>
                    </v-data-table>
                    <v-divider class="mt-5"></v-divider>
                    
                    </div>

                </div>
            </v-container>
        </v-card>         
        <v-row v-if="competition.status != 10 || (!ended && (competition.owner === user.id || user.status >= 9))" justify="center" class="ma-1 pa-4">
            <v-btn 
                class="justify-center"
                elevation="5"
                dark
                color="indigo"
                @click="endCompPopup()"                        
            >
                End Competition
            </v-btn>
        </v-row>  
    </v-card>

    <v-dialog
        v-model="image_overlay"
        max-width="400"
    >
        <v-card max-width="400" class="pa-1">
                
                <v-card-actions class="pa-2">
                    <v-spacer></v-spacer>
                    <v-icon
                        large
                        @click="resetDefaults()"
                    >mdi-close-circle
                    </v-icon>                
                </v-card-actions>
            
                <v-img 
                    v-if="image_name" 
                    :src="(image_mask_start + 'measurement_' + image_name)" 
                    max-height="500"
                    contain
                >
                    <template v-slot:placeholder>
                        <div class="d-flex align-center justify-center fill-height">
                            <v-progress-circular
                            color="grey-lighten-4"
                            indeterminate
                            ></v-progress-circular>
                        </div>
                    </template>
                </v-img>
                <v-card-subtitle v-if="invalid_flag">
                    <v-alert 
                        color="red"
                        outlined
                        icon="mdi-flag-variant"
                        class="mt-2 mb-0" 
                        type="error" 
                        dense
                        >
                        {{ invalid_flag_reason }}
                        </v-alert>
                </v-card-subtitle>
                <v-card-actions v-if="current_status == 0">
                    <v-btn
                        v-if="!accept_mode"
                        @click="acceptCatch()"
                        color="success"
                    >
                        Set Catch Valid
                    </v-btn>
                </v-card-actions>
                <v-card-actions v-else>
                    <v-btn
                        @click="rejectCatch()"
                        color="warning"
                        v-if="!reject_mode"
                    >
                        Set Catch Contested
                    </v-btn>
                </v-card-actions>
                <v-card-actions>
                    <v-textarea
                        v-if="reject_mode"
                        v-model="reject_reason"
                        placeholder="Provide a reason that catch is contested."
                    >
                        
                    </v-textarea>
                </v-card-actions>
                <v-card-actions v-if="reject_mode">
                    <v-btn
                        color="primary"
                        @click="submitReject()"
                    >
                        Submit as contested
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="reject_mode = false"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
                <v-card-actions v-if="accept_mode"><h4>Are you sure you want to set catch back to valid?</h4></v-card-actions>
                <v-card-actions v-if="accept_mode">
                    <v-btn
                        color="primary"
                        @click="submitAccept()"
                    >
                        Submit as valid
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="accept_mode = false"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- User Card Dialog -->
    <v-dialog v-model="profile_overlay" max-width="1000">

        <v-card class="pa-4">
            <v-row >
                <v-spacer></v-spacer>
                <v-card-actions class="pb-0">
                    <v-icon
                        large
                        @click="profile_overlay = false"
                    >mdi-close-circle
                    </v-icon>                
                </v-card-actions>
            </v-row>

            <v-row>            
                <v-col cols="12" sm="4">
                    <v-card-text class="text-center">         
                        <v-avatar class="ma-2 justify-center" size="128">
                            <v-img         
                                v-if="current_avatar"        
                                :src="(image_profile_start + current_avatar)" 
                                alt="Avatar"/>
                            <v-icon v-else large>mdi-account</v-icon>
                        </v-avatar> 
                        <v-card-title class="justify-center"><h2>{{ card_name }}</h2></v-card-title>

                        <v-row no-gutters>
                            <v-col>
                                Joined: {{ card_date }}
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col>
                                Regions: {{ card_region }}
                            </v-col>
                        </v-row>  
                    </v-card-text> 
                </v-col>   
                <v-col cols="12" sm="8">
                    <v-card class="pb-4" :height="info_height" outlined>
                        <v-tabs
                            fixed-tabs
                            bg-color="primary"
                            class="mb-5"
                        >
                            <v-tab v-if="!$vuetify.breakpoint.xs">Competitions</v-tab>
                            <v-tab v-else><v-icon>mdi-trophy-outline</v-icon></v-tab>
                            <v-tab v-if="!$vuetify.breakpoint.xs">Catches</v-tab>
                            <v-tab v-else><v-icon>mdi-fish</v-icon></v-tab>
                        
                            <v-tab-item class="mt-4" align="center">
                                <v-row align="center">
                                    <v-col cols="12" sm="6" class="d-flex">
                                        <v-card-text class="py-0">
                                            <div>Total Completed Competitions</div>
                                            <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card.total }}</p>
                                            <p v-else :class="font_size_value">-</p>                                            
                                        </v-card-text>                    
                                    </v-col>

                                    <v-col cols="12" sm="6">
                                        <v-row>
                                            <v-col cols="4" sm="12">
                                                {{ profile_card.firsts }}
                                                <v-icon color="yellow" large>mdi-trophy</v-icon>
                                                <nobr v-if="!$vuetify.breakpoint.xs"> 1st Place Finishes</nobr>
                                            </v-col>
                                            <v-col cols="4" sm="12">
                                                {{ profile_card.seconds }}
                                                <v-icon color="grey" large>mdi-trophy</v-icon>
                                                <nobr v-if="!$vuetify.breakpoint.xs"> 2nd Place Finishes</nobr>
                                            </v-col>
                                            <v-col cols="4" sm="12">
                                                {{ profile_card.thirds }}
                                                <v-icon color="brown" large>mdi-trophy</v-icon>
                                                <nobr v-if="!$vuetify.breakpoint.xs"> 3rd Place Finishes</nobr>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <v-row class="mt-4 mx-4">
                                    <v-autocomplete
                                        item-text="common_name"
                                        item-value="id"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        label="Species"
                                        v-model="selected_species"
                                        :items="species"
                                        return-object
                                        :onchange="getCardSpeciesInfo()"
                                    ></v-autocomplete>                                    
                                </v-row>

                                <v-row>
                                    <v-col cols="6" sm="6">
                                        <v-card-text class="py-0">
                                            <div>Total Caught</div>
                                            <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card_species.total }}</p>
                                            <p v-else :class="font_size_value">0</p>                                            
                                        </v-card-text>                    
                                    </v-col>
                                    <v-col cols="6" sm="6">
                                        <v-card-text class="py-0">
                                            <div>Longest Caught</div>
                                            <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card_species.longest }} cm</p>
                                            <p v-else :class="font_size_value">-</p>                        
                                        </v-card-text>   
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="6" sm="6">
                                        <v-card-text class="py-0">
                                            <div>Heaviest Caught</div>
                                            <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ profile_card_species.heaviest }} lbs.</p>
                                            <p v-else :class="font_size_value">-</p>                                            
                                        </v-card-text>                    
                                    </v-col>
                                    <v-col cols="6" sm="6">
                                        <v-card-text class="py-0">
                                            <div>Average Caught per Fishing Day</div>
                                            <p v-if="profile_card_species.total > 0" :class="font_size_value">{{ Math.round((profile_card_species.total/profile_card_species.days)*100) / 100 }}</p>
                                            <p v-else :class="font_size_value">-</p>                        
                                        </v-card-text>   
                                    </v-col>
                                </v-row>                                
                            </v-tab-item>
                        </v-tabs>                          
                    </v-card>
                </v-col>    
                <v-col cols="12" sm="4">
                    <v-card class="pb-4" :height="bio_height" outlined>
                        <v-card-title class="pb-0">
                            <h5>Bio</h5>  
                            <v-card-text class="px-0">{{ convertSpecial(card_bio) }}</v-card-text>                          
                        </v-card-title>
                    </v-card>
                </v-col>          
                <v-col cols="12" sm="8">
                    <v-card class="pb-4" height="200px" outlined>
                        <v-card-title class="pb-0">
                            <h5>Badges</h5>   
                        </v-card-title>
                        <v-row class="justify-center py-8">
                            <v-slide-group class="pa-1" show-arrows>
                                <v-slide-item><v-img contain src="/img/test_badge.png" max-height="100px" max-width="100px"/></v-slide-item>
                                <v-slide-item><v-img contain src="/img/badge2.png" max-height="100px" max-width="100px"/></v-slide-item>
                                <v-slide-item><v-img contain src="/img/badge3.png" max-height="100px" max-width="100px"/></v-slide-item>
                                <v-slide-item><v-img contain src="/img/badge4.png" max-height="100px" max-width="100px"/></v-slide-item>
                                <v-slide-item><v-img contain src="/img/badge5.png" max-height="100px" max-width="100px"/></v-slide-item>                      
                            </v-slide-group>
                        </v-row>
                    </v-card>
                </v-col>                       
            </v-row>
            
        </v-card>
    </v-dialog>
    <v-dialog
        v-model="overlay"
        max-width="500"
        @click:outside="closeOverlay"
    >
        <v-card max-width="500">
            <div v-if="!comp_ended">
            <v-card-title>Ending the Competition?</v-card-title>
            <v-card-text class="pb-0">This will end the competition. Are you sure you wish to publish results?
                <v-row class="pa-4">
                    <v-container>
                        <v-row>
                            <v-col>
                                <h4>Team Placement</h4>
                                You can drag teams to re-order placement
                            </v-col>
                        </v-row>
                        
                        <draggable v-model="fixed_teams" @start="drag=true" @end="drag=false">
                            <!-- <div v-for="(element, index) in fixed_teams" :key="element.id">{{ index + 1 }} {{ element.name }}</div> -->
                            <v-row v-for="(element, index) in fixed_teams" :key="element.id">
                                <v-chip class="white--text blue lighten-1 ma-2" >
                                    <v-icon left class="white--text">
                                        mdi-drag-horizontal-variant
                                    </v-icon>
                                    <div>{{ index + 1 }}<span v-if="index === 0">st</span><span v-if="index === 1">nd</span><span v-if="index === 2">rd</span><span v-if="index > 2">th</span>: {{ convertSpecial(element.name) }}</div>
                                    
                                </v-chip>
                            </v-row>
                        </draggable>    
                    </v-container>
                </v-row>
                <v-container>
                    <v-row>
                        <v-col class="pl-0">
                            <v-btn
                            class="white--text"
                            color="blue lighten-2"
                            @click="add_delay = !add_delay"
                            
                            >
                                {{add_delay? "Remove Delay" : "Add Publishing Delay"}}
                            </v-btn>    
                        </v-col>
                    </v-row>
                </v-container>
                <v-row v-if="add_delay" no-gutters>
                    <v-col cols="12" sm="6">
                        <v-menu
                            absolute
                            v-model="delay_menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="delay_date"
                                label="Delay Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="delay_date"
                                no-title
                                scrollable
                                @input="delay_menu = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-menu
                            absolute
                            ref="menu"
                            v-model="delay_time_menu"
                            :close-on-content-click="false"
                            :return-value.sync="delay_time"
                            transition="scale-transition"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="delay_time"
                                label="Delay Time"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"                   ></v-text-field>
                            </template>
                            <v-time-picker
                                v-if="delay_time_menu"
                                v-model="delay_time"
                                @click:minute="$refs.menu.save(delay_time)"
                            ></v-time-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="pb-4 justify-center">                    
                <v-btn
                    class="white--text"
                    color="teal"
                    @click="endComp"
                >
                    Confirm
                </v-btn>
                <!-- <v-btn
                    class="white--text"
                    color="teal"
                    @click="testButton()"
                >
                    Test End
                </v-btn> -->
                <v-btn
                    class="white--text"
                    color="red darken-2"
                    @click="closeOverlay()"
                >
                    Cancel
                </v-btn>
            </v-card-actions>
        </div>
        <div v-else>
            <v-card-title>Competition Ended</v-card-title>
            <v-card-text class="pb-5">The competition has been ended, and will be viewable in the Finished competition list once the publishing delay is over.</v-card-text>
            <v-card-text v-if="!low_members" class="pb-5">You received {{ bonus_points }} bonus points for holding this competition.</v-card-text>
            <v-card-text v-else class="pb-5">You would have receieved {{ bonus_points }} bonus points for holding this competition, if you had 3 or more particiants.</v-card-text>
        </div>
        </v-card>
    </v-dialog>

    </v-container>
</template>

<script>
//import
import { mapState } from 'vuex';
import draggable  from 'vuedraggable';

export default{
    data(){
        return {
            active_tab: 2,
            fixed_teams:[],
            original_team_order: [],
            fixed_subtotals: [],
            panel:[],

            expanded: false,
            panels: 0,
            scoreBy: "total length",
            weightAsc: false,
            lengthAsc: true,
            ended: false,
            overlay:false,
            button_disabled:true,

            image_overlay:false,
            selected_catch: null,
            image_file: undefined,
            image_start: this.$apiEndpointUrl + "catch_image/",
            image_mask_start: this.$apiEndpointUrl + "catch_mask_image/",
            image_profile_start: this.$apiEndpointUrl + 'profile_image/',
            image_name: null,

            add_delay: false,
            delay_date: '',
            delay_menu: false,
            delay_time: '',
            delay_time_menu: false,

            order_mode: false,
            delay_mode: false,

            sortByValue: 'id',
            sortDesc: true,
            headers: [
                { text: 'Contested', value: 'status' },
                { text: 'Fish Name', value: 'common_name' },
                { text: 'Length', value: 'length' },
                { text: 'Calculated Length', value: 'ai_length' },
                { text: 'Length Differrence', value: 'board_status' },
                { text: 'QR Count', value: 'qr_detected' },
                { text: 'Upload Status', value: 'ai_status' },
                { text: 'Picture', value: 'image' },
            ],
            invalid_flag: false,
            invalid_flag_reason: "",
            reject_mode: false,
            accept_mode: false,
            reject_reason: "",
            current_reason: null,
            current_status: null,

            card_mode: 1,
            mode_options: [
                { text: 'Competition', value: 1},
                { text: 'Species', value: 2},
            ],
            card_name: null,
            card_date: null,
            card_region: null,
            card_bio: null,
            selected_species: null,
            current_species: null,
            current_avatar: null,
            loading: false,
            profile_overlay: false,
            comp_ended: false,
            bonus_points: 0,
            low_members: false,
        }
    },
    components:{
        draggable,
},
    computed: {
        ...mapState('leaderboard', {
            competition: state => state.competition,
            comp_teams: state => state.comp_teams,
            comp_species: state => state.comp_species,
            selected_competition: state => state.selected_competition
        }),        
        ...mapState('user',{
            user: state => state.user,
            profile_card: state => state.profile_card,
            profile_card_species: state => state.profile_card_species,
        }),
        ...mapState('spot_mapper',{
            species: state => state.species,
        }),       
        _seconds: () => 1000,
        _minutes () {
            return this._seconds * 60
        },
        _hours () {
            return this._minutes * 60
        },
        _days () {
            return this._hours * 24
        },
        set_gutters(){
            return !this.$vuetify.breakpoint.xs ? false : true
        },
        set_padding_margins(){
            return !this.$vuetify.breakpoint.xs ? "px-4 py-2 ma-3" : "ma-3"
        },
        font_size_value(){
            return !this.$vuetify.breakpoint.xs ? "text-h5" : "text-h6"
        },
        font_size_value_totals(){
            return !this.$vuetify.breakpoint.xs ? "text-h5" : "text-body-1 font-weight-medium"
        },
        info_height(){
            return !this.$vuetify.breakpoint.xs ? "300px" : "310px"
        },
        bio_height(){
            return !this.$vuetify.breakpoint.xs ? "200px" : "auto"
        }
    },
    methods: {
        changePage(page){
            this.$store.page = page
            // console.log(this.$store.page)
            this.$emit('change-page')
            // vm.$forceUpdate()
        },
        changeLeaderboardPage(id){
            window.location.href = "/app/leaderboard/compleaderboard/" + id
            this.$router.push(("/app/leaderboard/compleaderboard/" + id))
            // this.$store.page = page
            // console.log(this.$store.page)
            // this.$emit('change-page')
            // vm.$forceUpdate()
        },
        resetDefaults(){
            this.reject_mode = false
            this.accept_mode = false
            this.reject_reason = ""
            this.image_overlay = false
            this.profile_overlay = false
        },
        convertSpecial(text){  
            text = String(text).replace("&lt;" , "<")
            text = String(text).replace("&gt;" , ">")
            text = String(text).replace("&quot;" , "\"")
            text = String(text).replace("&#039;" , "'")
            text = String(text).replace("&amp;" , "&")
            // return htmlStr
            // console.log(text)
            return text
        },
        showCard(id, avatar){

            // this.fixed_leaderboard.map((item, index) => {
            //     item.selected = item === row

            //     this.$set(this.fixed_leaderboard, index, item)
            // })

            this.selected_species = null
            this.current_species = null
            this.card_mode = 1
            this.$store.dispatch('user/getProfileCard', id)
            .then(() => {
                this.card_name = this.profile_card.profile.name
                this.card_date = new Date(this.profile_card.profile.tos_accepted).toLocaleDateString('en-us', { month:"long", day:"numeric", year:"numeric"})
                this.card_region = this.profile_card.profile.region
                this.card_bio = this.profile_card.profile.bio

                this.$store.dispatch('spot_mapper/getSpecies')
                .then(() => {
                    // console.log(this.species)
                    this.profile_overlay = true
                })
            })
            this.current_avatar = avatar
        },
        getCardSpeciesInfo(){
            if(this.selected_species!==null && !this.loading){
                if(this.current_species!==this.selected_species.id){
                    this.loading = true
                    let user = this.user.id
                    let species = this.selected_species.id
                    this.$store.dispatch('user/getProfileCardSpecies', { user, species})
                    .then(() => {
                        // console.log(this.profile_card_species)
                        this.current_species = this.selected_species.id
                        this.loading = false
                    })
                }
            }
        },
        closeOverlay(){
            this.sortBy(this.competition.score_type)
            this.overlay = false
            this.fixed_teams = this.original_team_order
            this.delay_date = ""
            this.delay_time = ""
        },
        rejectCatch(){
            this.reject_mode = true
            this.reject_reason = ""
        },
        acceptCatch(){
            this.accept_mode = true
        },
        submitReject(){
            let data = {
                id: this.selected_catch,
                reason: this.reject_reason,
                status: 0,
                comp_id: this.current_comp,
            }
            // console.log(data)
            this.$store.dispatch('leaderboard/rejectCatch', data)
            .then(() => {
                this.error = false
                this.err_message = null
            })
            .catch((err) => {
                this.error = true
                this.err_message = 'Retry: ' + err
            })
            .finally(() => {
                // this.loading = false
                if(!this.error){
                    this.resetDefaults()
                }
                
                // console.log(data)
            })
        },
        submitAccept(){
            // let data = {
            //     id: this.selected_catch,
            //     status: 1,
            //     comp_id: this.current_comp,
            // }
            // console.log(data)
            // this.$store.dispatch('leaderboard/acceptCatch', data)
            // .then(() => {
            //     this.error = false
            //     this.err_message = null
            // })
            // .catch((err) => {
            //     this.error = true
            //     this.err_message = 'Retry: ' + err
            // })
            // .finally(() => {
            //     // this.loading = false
            //     if(!this.error){
            //         this.resetDefaults()
            //     }
            // })
        },
        // There may be a better way that does not need to sort as much using .reverse? 
        sort(ascending, sortBy){
            // console.log(ascending)
            if(sortBy === "length"){
                if(ascending){
                    this.fixed_teams.sort((a,b) => a.longest_catch - b.longest_catch)
                }else{
                    this.fixed_teams.sort((a,b) => b.longest_catch - a.longest_catch)
                }
                this.lengthAsc = !this.lengthAsc
                this.scoreBy = "Length"
            }else{
                if(ascending){
                    this.fixed_teams.sort((a,b) => a.heaviest_catch - b.heaviest_catch)
                }else{
                    this.fixed_teams.sort((a,b) => b.heaviest_catch - a.heaviest_catch)
                }
                this.weightAsc = !this.weightAsc
                this.scoreBy = "Weight"
            }
        },
        sortBy(score_type){
            // let temp = this.fixed_teams
            var temp = []
            if(score_type === "1"){
                this.fixed_teams.sort((a,b) => b.total_length - a.total_length)
                this.scoreBy = "Total Length"
            }else if(score_type === "2"){
                this.fixed_teams.sort((a,b) => b.total_weight - a.total_weight)
                this.scoreBy = "Total Weight"
            }else if(score_type === "3"){
                this.fixed_teams.sort((a,b) => b.total_catches - a.total_catches)
                this.scoreBy = "Total Catches"
            }else if(score_type === "4"){
                // let temp_scores = []                
                this.fixed_teams.forEach((t,j) => {
                    t.members.forEach(m => {
                        m.catches.forEach(c => {
                            let tmp_catch = {
                                name: c.common_name,
                                length: c.length
                            }
                            temp.push(tmp_catch)
                        })
                    })
                    temp.sort((a,b) => b.length - a.length)
                    let best_catches = 0
                    let c_values = ""
                    if (temp.length < this.competition.amount) {
                        temp.forEach((item, i) => {
                            c_values += item.length + "cm"
                            if (i+1 < temp.length) {
                                c_values += " , "
                            }
                        });
                    }          
                    else {
                        for (let i = 0; i < this.competition.amount; i++) {
                            // best_catches.push(temp[i])
                            best_catches += Number(temp[i].length)
                            c_values += (temp[i].length) + " cm"
                            if(!(Number(this.competition.amount) === Number(i+1))){
                                c_values += " , "
                            }
                        }
                    }         
                    this.fixed_teams[j].best_catches = c_values
                    this.fixed_teams[j].best_total = best_catches
                })
                // console.log(temp_scores)
                this.fixed_teams.sort((a,b) => b.best_total - a.best_total)
                this.scoreBy = "Longest " + this.competition.amount + " Fish"
            }else if(score_type === "5"){
                this.fixed_teams.forEach((t,j) => {
                    t.members.forEach(m => {
                        m.catches.forEach(c => {
                            let tmp_catch = {
                                name: c.common_name,
                                weight: c.weight
                            }
                            temp.push(tmp_catch)
                        })
                    })
                    temp.sort((a,b) => b.weight - a.weight)
                    let best_catches = 0
                    let c_values = ""
                    if (temp.length < this.competition.amount) {
                        temp.forEach((item, i) => {
                            c_values += item.weight + "lbs."
                            if (i+1 < temp.length) {
                                c_values += " , "
                            }
                        });
                    }
                    else {
                        for (let i = 0; i < this.competition.amount; i++) {
                            // best_catches.push(temp[i])
                            best_catches += Number(temp[i].weight)
                            c_values += (temp[i].weight) + " lbs."
                            if(!(Number(this.competition.amount) === Number(i+1))){
                                c_values += " , "
                            }
                        }
                    }
                    this.fixed_teams[j].best_catches = c_values
                    this.fixed_teams[j].best_total = best_catches
                })
                this.fixed_teams.sort((a,b) => b.best_total - a.best_total)
                this.scoreBy = "Heaviest " + this.competition.amount + " Fish"
            }

            // if(score_type === "1"){
            //     temp.sort((a,b) => b.longest_catch - a.longest_catch)
            //     this.scoreBy = "length"
            // }else if(score_type === "2"){
            //     temp.sort((a,b) => b.heaviest_catch - a.heaviest_catch)
            //     this.scoreBy = "weight"
            // }else if(score_type === "3"){
            //     temp.sort((a,b) => b.total_catches - a.total_catches)
            //     this.scoreBy = "total catches"
            // }else if(score_type === "6"){
            //     temp.sort((a,b) => b.total_length - a.total_length)
            //     this.scoreBy = "total length"
            // }else if(score_type === "7"){
            //     temp.sort((a,b) => b.total_weight - a.total_weight)
            //     this.scoreBy = "total weight"
            // }

            // this.fixed_teams = temp
            // console.log(this.fixed_teams)
        },
        testButton(){
            this.subtotal()
        },
        getOwnerBonus(){
            // console.log("test")
            let index = -1
            let found = false
            let total = 0
            let total_members = 0
            this.fixed_teams.forEach(team => {
                total_members += team.members.length
                if(!found){
                    index = team.members.findIndex(obj => obj.member_id == this.user.id)

                    if(index !== -1){
                        found = true
                        total = Math.round((team.members[index].subtotal + Number.EPSILON) * 100) / 100
                    }
                }

            });
            // console.log("found: " + found)
            // console.log("index: " + index)
            // console.log("total: " + total)
            // console.log("total participants: " + total_members)
            if(total > 0){this.bonus_points = total}
            if(total_members <= 2){
                total = -1
                this.low_members = true
            }
            // console.log(this.low_members)
            return total
        },
        getTotals(teams){
            // There might be a better way then just hoping values line up?
            // console.log(teams)
            for(let x = 0; x < this.fixed_teams.length; x++){
                let species = teams[x].species
                let keys = Object.keys(species)
                keys.forEach(key => {
                    // console.log(`${key}:`)
                    // console.log(species[key])
                    this.fixed_teams[x].subtotals.push(species[key])
                })
                // console.log(keys)
                // this.fixed_teams[x].subtotals = teams[x].species
            }
            // console.log(this.fixed_teams)
        },
        subtotal(){
            let current_team_id = null
            let teams = []
            let temp_team = []

            // console.log(this.comp_teams)
            this.comp_teams.forEach(i => {
                if(current_team_id === null){
                    //first loop, new team
                    
                    // console.log("1st run of loop: " + i.species_id + " name: " + i.common_name)
                    temp_team = {
                        team_id: i.id,
                        species: []
                    }
                    temp_team.species[i.species_id] = {total_length : Number(i.length), total_weight : Number(i.weight), species_id: i.species_id, species_name: i.common_name}
                    current_team_id = i.id
                }else if(current_team_id === i.id){
                    //same team
                    //index
                    let temp_index = Object.keys(temp_team.species).indexOf(i.species_id)
                    // console.log(temp_index)
                    if(temp_index === -1){
                        //New species, same team
                        // console.log("new Species, Same team: " + i.species_id + " name: " + i.common_name)

                        temp_team.species[i.species_id] = {total_length : Number(i.length), total_weight : Number(i.weight), species_id: i.species_id, species_name: i.common_name}
                    }else{
                        // //Species exists in object
                        let new_length = (Number(temp_team.species[i.species_id].total_length) + Number(i.length))
                        let new_weight = (Number(temp_team.species[i.species_id].total_weight) + Number(i.weight))
                        
                        // console.log(new_length)
                        // console.log(new_weight)

                        temp_team.species[i.species_id].total_length = new_length
                        temp_team.species[i.species_id].total_weight = new_weight
                        // // console.log(temp_team.species)
                    }
                }else{
                    //New team

                    //push old team
                    teams.push(temp_team)

                    temp_team = {
                        team_id: i.id,
                        species: []
                    }
                    temp_team.species[i.species_id] = {total_length : Number(i.length), total_weight : Number(i.weight), species_id: i.species_id, species_name: i.common_name}
                    current_team_id = i.id
                }
                
            })
            //save last value
            teams.push(temp_team)

            this.getTotals(teams)
        },
        endCompPopup(){
            // console.log(this.fixed_teams)
            this.original_team_order = this.fixed_teams
            this.add_delay = false
            this.overlay = true            
        },
        endComp(){
            let info = {
                comp_id: this.$route.params.id
            }

            if(this.add_delay){
                let fixed_delay = this.getDBTime((this.delay_date + " " + this.delay_time))
                info = {
                    comp_id: this.$route.params.id,
                    delay_date: fixed_delay
                }
            }
            for (let i = 0; i < this.fixed_teams.length; i++) {
                this.fixed_teams[i].placement = (i+1)
            }
            
            let data = {
                info: info,
                results: this.fixed_teams,
                sidepot: this.fixed_sidepot
            }
            
            // let comp_bonus_points = this.getOwnerBonus()
            // if(comp_bonus_points > 0){
            //     let bonus = {
            //         comp_id: this.$route.params.id,
            //         year: new Date().getFullYear(),
            //         bonus: comp_bonus_points
            //     }
            //     console.log(data)
            //     console.log(bonus)
            //     // this.$store.dispatch('leaderboard/addCompBonus', bonus)
            // }
            // this.comp_ended = true

            this.$store.dispatch('leaderboard/endComp', data)
            .then(() => {
            })
            .catch((err) => {
                this.error = true
                this.err_message = 'Retry: ' + err
            })
            .finally(() => {
                this.ended = false
                if(!this.error){
                //     this.overlay = false
                // this.changeLeaderboardPage(this.$route.params.id)
                this.comp_ended = true
                let comp_bonus_points = this.getOwnerBonus()
                if(comp_bonus_points >= 1){
                    let bonus = {
                        comp_id: this.$route.params.id,
                        year: new Date().getFullYear(),
                        bonus: comp_bonus_points
                    }
                    this.$store.dispatch('leaderboard/addCompBonus', bonus)
                }
            }
            // console.log(data)
            })
        },
        getDBTime(date){
            let localdate = new Date(date)
            return (localdate.toISOString().slice(0, -1))
        },
        updateCatches(){
            this.button_disabled = true
            this.$store.dispatch('leaderboard/getCompTeams')
            .then(() => {
                this.fixed_teams.splice(0)
                var temp_id = []
                var team = {}
                var current_member = {}
                this.comp_teams.forEach(item => {
                    if(item.id !== temp_id){
                        team = {
                            id: item.id,
                            name: item.name,
                            members:[],
                            longest_catch:0,
                            heaviest_catch:0,
                            l_leader_name: null,
                            l_leader_id: null,
                            h_leader_name: null,
                            h_leader_id: null,
                            total_length:Number(0.0),
                            total_weight:Number(0.0),
                            total_catches:0,
                            subtotals: []
                        }
                        current_member = {
                            member_id: item.member,
                            alias: item.alias,
                            profile_image: item.profile_image,
                            subtotal: Number(item.length),
                            catches:[{
                                id: item.catch_id,
                                species_id: item.species_id,
                                common_name: item.common_name,
                                length: item.length,
                                weight: item.weight,
                                date: item.date,
                                image: item.image_name,
                                ai_length: item.ai_length,
                                ai_status: item.ai_status,
                                board_status: item.board_status,
                                fish_status: item.fish_status,
                                measurement_status: item.measurement_status,
                                qr_status: item.qr_status,
                                qr_detected: item.qr_detected,
                                status: item.status,
                                reason: item.reason,
                            }]
                        }
                        team.longest_catch = item.length
                        team.heaviest_catch = item.weight
                        team.l_leader_name = item.alias
                        team.l_leader_id = item.member
                        team.h_leader_name = item.alias
                        team.h_leader_id = item.member
                        team.total_length += Number(item.length)
                        team.total_weight += Number(item.weight)
                        team.total_catches ++
                        team.members.push(current_member)
                        this.fixed_teams.push(team)
                        this.panels++
                        temp_id = item.id
                    } else if(current_member.member_id !== item.member) {
                        current_member = {
                            member_id: item.member,
                            alias: item.alias,
                            profile_image: item.profile_image,
                            subtotal: Number(item.length),
                            catches:[{
                                id: item.catch_id,
                                species_id: item.species_id,
                                common_name: item.common_name,
                                length: item.length,
                                weight: item.weight,
                                date: item.date,
                                image: item.image_name,
                                ai_length: item.ai_length,
                                ai_status: item.ai_status,
                                board_status: item.board_status,
                                fish_status: item.fish_status,
                                measurement_status: item.measurement_status,
                                qr_status: item.qr_status,
                                qr_detected: item.qr_detected,
                                status: item.status,
                                reason: item.reason,
                            }]
                        }
                        if(Number(item.length) > Number(team.longest_catch)){
                            team.longest_catch = item.length
                            team.l_leader_name = item.alias
                            team.l_leader_id = item.member
                        }if(Number(item.weight) > Number(team.heaviest_catch)){
                            team.heaviest_catch = item.weight
                            team.h_leader_name = item.alias
                            team.h_leader_id = item.member
                        }
                        team.total_length += Number(item.length)
                        team.total_weight += Number(item.weight)
                        team.total_catches ++
                        team.members.push(current_member)
                    } else {
                        current_member.subtotal += Number(item.length),
                        current_member.catches.push({
                            id: item.catch_id,
                            species_id: item.species_id,
                            common_name: item.common_name,
                            length: item.length,
                            weight: item.weight,
                            date: item.date,
                            image: item.image_name,
                            ai_length: item.ai_length,
                            ai_status: item.ai_status,
                            board_status: item.board_status,
                            fish_status: item.fish_status,
                            measurement_status: item.measurement_status,
                            qr_status: item.qr_status,
                            qr_detected: item.qr_detected,
                            status: item.status,
                            reason: item.reason,
                        })
                        if(Number(item.length) > Number(team.longest_catch)){
                            team.longest_catch = item.length
                            team.l_leader_name = item.alias
                            team.l_leader_id = item.member
                        }if(Number(item.weight) > Number(team.heaviest_catch)){
                            team.heaviest_catch = item.weight
                            team.h_leader_name = item.alias
                            team.h_leader_id = item.member
                            
                        }
                        team.total_length += Number(item.length)
                        team.total_weight += Number(item.weight)
                        team.total_catches ++
                        // console.log(current_member.catches)
                    }
                })
                // when we know better sort by we can sort at beginning?
                // this.fixed_teams.sort((a,b) => b.longest_catch - a.longest_catch)
                this.sortBy(this.competition.score_type)
                this.subtotal()
            })
            .finally(() => {
                // console.log(this.fixed_teams)
                setTimeout(()=> {
                    this.button_disabled = false
                }, 3000)
            })
        },
        showImage(row){
            this.image_name = row.image
            this.selected_catch = row.id
            //invalid_reason
            this.invalid_flag = false
            this.invalid_flag_reason = ""
            //1-(item.length/item.ai_length) > 0.02 || 1-(item.ai_length/item.length) > 0.02" color="orange">mdi-flag-variant</v-icon>
            if(row.ai_status === 1){
                this.invalid_flag = true
                this.invalid_flag_reason += "Image upload issue. "
            }
            if(row.measurement_status === 1){
                this.invalid_flag = true
                this.invalid_flag_reason += "Fish measurement issue. "
            }
            if(row.qr_status === 1){
                this.invalid_flag = true
                this.invalid_flag_reason += "QR not detected. "
            }
            if(row.board_status === 1){
                this.invalid_flag = true
                this.invalid_flag_reason += "Board not detected. "
            }
            if(row.fish_status === 1){
                this.invalid_flag = true
                this.invalid_flag_reason += "Fish not detected. "
            }
            if(row.length!== null && (1-(row.length/row.ai_length) > 0.02 || 1-(row.ai_length/row.length) > 0.02)){
                this.invalid_flag = true
                this.invalid_flag_reason += "Entered length has large difference to calculated. "
            }
            this.current_reason = row.reason
            this.current_status = row.status
            // console.log(row)
            this.image_overlay = true
        },
        scoreByValue(score_type){
            let temp = ""
            switch(score_type){
                case '1':
                    return "Total Length";
                case '2':
                    return "Total Weight";
                case '3':
                    return "Total Fish Caught";
                case '6':
                    return "Longest Fish";
                case '7':
                    return "Heaviest Fish";
                case '4':
                    temp = this.competition.amount + " Longest Fish"
                    return temp;
                case '5':
                    temp = this.competition.amount + " Heaviest Fish"
                    return temp;
            }
        },
    },
    mounted() {
        let id = this.$route.params.id
        this.$store.selected_competition = id
        window.scrollTo(0,0);

        this.$store.dispatch('leaderboard/getCompTeams')
        .then(() => {
            // console.log(this.comp_teams)
            if(this.comp_teams.length > 0){
                this.current_comp = this.comp_teams[0].id
            }
            // Building team objects to use for expansion view
            var temp_id = null
            var team = {}
            var current_member = {}
            this.comp_teams.forEach(item => {
                if(item.id !== temp_id){
                    team = {
                        id: item.id,
                        name: item.name,
                        members:[],
                        l_leader_name:null,
                        l_leader_id:null,
                        h_leader_name:null,
                        h_leader_id:null,
                        longest_catch:0,
                        heaviest_catch:0,
                        total_length:0.0,
                        total_weight:0.0,
                        total_catches:0,
                        subtotals: []
                    }
                    current_member = {
                        member_id: item.member,
                        alias: item.alias,
                        profile_image: item.profile_image,
                        subtotal: Number(item.length),
                        catches:[{
                            id: item.catch_id,
                            species_id: item.species_id,
                            common_name: item.common_name,
                            length: item.length,
                            weight: item.weight,
                            date: item.date,
                            image: item.image_name,
                            ai_length: item.ai_length,
                            ai_status: item.ai_status,
                            board_status: item.board_status,
                            fish_status: item.fish_status,
                            measurement_status: item.measurement_status,
                            qr_status: item.qr_status,
                            qr_detected: item.qr_detected,
                            status: item.status,
                            reason: item.reason,

                        }]
                    }
                    team.longest_catch = item.length
                    team.heaviest_catch = item.weight
                    team.l_leader_name = item.alias
                    team.l_leader_id = item.member
                    team.h_leader_name = item.alias
                    team.h_leader_id = item.member
                    team.total_length += Number(item.length)
                    team.total_weight += Number(item.weight)
                    team.total_catches ++
                    team.members.push(current_member)
                    this.fixed_teams.push(team)
                    this.panels++
                    temp_id = item.id
                } else if(current_member.member_id !== item.member) {
                    current_member = {
                        member_id: item.member,
                        alias: item.alias,
                        profile_image: item.profile_image,
                        subtotal: Number(item.length),
                        catches:[{
                            id: item.catch_id,
                            species_id: item.species_id,
                            common_name: item.common_name,
                            length: item.length,
                            weight: item.weight,
                            date: item.date,
                            image: item.image_name,
                            ai_length: item.ai_length,
                            ai_status: item.ai_status,
                            board_status: item.board_status,
                            fish_status: item.fish_status,
                            measurement_status: item.measurement_status,
                            qr_status: item.qr_status,
                            qr_detected: item.qr_detected,
                            status: item.status,
                            reason: item.reason,
                        }]
                    }
                    if(Number(item.length) > Number(team.longest_catch)){
                        team.longest_catch = item.length
                        team.l_leader_name = item.alias
                        team.l_leader_id = item.member
                    }if(Number(item.weight) > Number(team.heaviest_catch)){
                        team.heaviest_catch = item.weight
                        team.h_leader_name = item.alias
                        team.h_leader_id = item.member
                    }
                    team.total_length += Number(item.length)
                    team.total_weight += Number(item.weight)
                    team.total_catches ++
                    team.members.push(current_member)
                } else {
                    current_member.subtotal += Number(item.length),
                    current_member.catches.push({
                        id: item.catch_id,
                        species_id: item.species_id,
                        common_name: item.common_name,
                        length: item.length,
                        weight: item.weight,
                        date: item.date,
                        image: item.image_name,
                        ai_length: item.ai_length,
                        ai_status: item.ai_status,
                        board_status: item.board_status,
                        fish_status: item.fish_status,
                        measurement_status: item.measurement_status,
                        qr_status: item.qr_status,
                        qr_detected: item.qr_detected,
                        status: item.status,
                        reason: item.reason,
                    })
                    if(Number(item.length) > Number(team.longest_catch)){
                        team.longest_catch = item.length
                        team.l_leader_name = item.alias
                        team.l_leader_id = item.member
                    }if(Number(item.weight) > Number(team.heaviest_catch)){
                        team.heaviest_catch = item.weight
                        team.h_leader_name = item.alias
                        team.h_leader_id = item.member
                    }
                    team.total_length += Number(item.length)
                    team.total_weight += Number(item.weight)
                    team.total_catches ++
                    // console.log(current_member.catches)
                }
            })

            this.subtotal()
            this.sortBy(this.competition.score_type)
        })
        .finally(() => {
            // console.log(this.fixed_teams)
            setTimeout(()=> {
                this.button_disabled = false
            }, 3000)
        })
    }
}
</script>
