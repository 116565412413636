<template>
    <v-container class="ma-0 pa-0 mb-10">
        <v-card class="blue lighten-5 pb-3 mb-16">
            <v-container>
                <v-row>
                    <v-card-title>Personal Leaderboard</v-card-title>
                </v-row>
            </v-container>
            <v-card :class="set_padding_margins" align="center">
                <UserCard/>
                <v-row>
                    <v-col>
                        <div>Total Score</div>
                        <div class="text-h1">{{ Math.round(((this.bonus +  this.total_length*(1+(this.H/this.Hmax))) + Number.EPSILON) * 100) / 100 }}</div>                        
                    </v-col>
                </v-row>                
                <v-row>
                    <v-col cols="6" sm="2">
                        <v-card-text class="py-0">
                            <div>Total Caught</div>
                            <p :class="font_size_value">{{ this.total_caught }}</p>                        
                        </v-card-text>   
                    </v-col>
                    <v-col cols="6" sm="2">
                        <v-card-text class="py-0">
                            <div>Species Caught</div>
                            <p :class="font_size_value">{{ (this.personal != null) ? this.personal.length : 0}}/{{ this.sport_fish.length }}</p>                        
                        </v-card-text>   
                    </v-col>
                    <v-col cols="6" sm="2">
                        <v-card-text class="py-0">
                            <div>Total Length</div>
                            <p :class="font_size_value">{{ (Math.round(((this.total_length)) * 100) / 100) }} cm</p>                        
                        </v-card-text>   
                    </v-col>                      

                    <v-col cols="6" sm="2">
                        <v-card-text class="py-0">
                            <div>Diversity</div>
                            <p :class="font_size_value">{{ (Math.round(((this.H*100/this.Hmax) + Number.EPSILON) * 100) / 100) }}%</p>                        
                        </v-card-text>   
                    </v-col>
                    <v-col cols="6" sm="2">
                        <v-card-text class="py-0">
                            <div>Catch Score</div>
                            <p :class="font_size_value">{{ Math.round(((this.total_length*(1+(this.H/this.Hmax))) + Number.EPSILON) * 100) / 100  }}</p>                        
                        </v-card-text>   
                    </v-col>
                    <v-col cols="6" sm="2">
                        <v-card-text class="py-0">
                            <div>Competition Bonus</div>
                            <p :class="font_size_value">{{ this.bonus }}</p>                        
                        </v-card-text>   
                    </v-col>           
                </v-row>

                <v-data-table
                    class="py-4"
                    dense
                    :headers="headers"
                    :items="fixed_list"
                    :items-per-page="10"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    @click:row="showFull"
                    :hide-default-footer="true"
                >
                    <template v-slot:item.total_length="{ item }">
                        <div v-if="item.total_length">{{ Math.round((item.total_length) * 100) / 100 }} cm</div>
                        <div v-else></div>
                        
                    </template>
                    <template v-slot:item.longest="{ item }">
                        <div v-if="item.longest">{{ Math.round((item.longest) * 100) / 100 }} cm</div>
                        <div v-else></div>
                        
                    </template>
                </v-data-table>
            </v-card>
        </v-card>

        <v-dialog
            v-model="overlay"
            max-width="1000"
        >
            <v-card max-width="1000" class="pa-1">
                    
                <v-card-actions class="pa-2">
                    <v-spacer></v-spacer>
                    <v-icon
                        large
                        @click="overlay=false"
                    >mdi-close-circle
                    </v-icon>                
                </v-card-actions>
                <v-data-table
                    dense
                    :headers="breakdown_headers"
                    :items="full_species_stats"
                    :items-per-page="10"
                    class="elevation-1"
                    sort-by='date'
                    :sort-desc="true"        
                    >
                    <template v-slot:item.image_name="{ item }">
                            <v-img         
                                v-if="item.image_name"        
                                :src="($apiEndpointUrl + 'catch_image/' + item.image_name)" 
                                max-height="100"
                                max-width="100"
                                alt="Catch"/>
                            <v-icon v-else large>mdi-fish</v-icon>
                    </template>
                    <template v-slot:item.length="{ item }">
                        <div v-if="item.length">{{ Math.round((item.length) * 1000) / 1000 }} cm</div>
                        <div v-else></div>
                        
                    </template>
                    <template v-slot:item.ai_length="{ item }">
                        <div v-if="item.ai_length">{{ Math.round((item.ai_length) * 1000) / 1000 }} cm</div>
                        <div v-else></div>
                        
                    </template>
                </v-data-table>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';
import UserCard from '../components/UserCard';

export default {
    components: {
        UserCard
    },
    computed: {
        //will need to grab comp information
        ...mapState('leaderboard', {
            selected_region: state => state.selected_region,
            sport_fish: state => state.sport_fish,
            personal: state => state.personal,
            full_species_stats: state => state.full_species_stats,
        }),
        ...mapState('user', {
            user: state => state.user,
        }),
        // ...mapState('spot_mapper', {
        //     joined_comps: state => state.joined_comps
        // }),
        font_size_title(){
            return !this.$vuetify.breakpoint.xs ? "text-h4 mb-0" : "text-h6 mb-0"
        },       
        font_size_value(){
            return !this.$vuetify.breakpoint.xs ? "text-h5" : "text-h6"
        },    
        set_gutters(){
            return !this.$vuetify.breakpoint.xs ? false : true
        },     
        set_padding_margins(){
            return !this.$vuetify.breakpoint.xs ? "px-4 pt-6 ma-3 pb-0" : "ma-3"
        },           
    },
    data() {
        return {
            sortBy: 'caught',
            sortDesc: true,
            fixed_list:[],
            headers: [
                {
                text: 'Name',
                align: 'start',
                value: 'name',
                },
                { text: 'Total Length', value: 'total_length' },
                { text: 'Longest', value: 'longest' },
                { text: 'Caught', value: 'caught' },
            ],
            breakdown_headers: [
                { text: 'id', align: 'start', value: 'id' },
                { text: 'Species Id', value: 'species_id' },
                { text: 'Species', value: 'common_name' },
                { text: 'Length', value: 'length' },
                { text: 'AI Length', value: 'ai_length' },
                { text: 'Date', value: 'date' },
                { text: 'Image', value: 'image_name' },
            ],
            test_species:[
                {id:'429', species:'982', common_name:'walleye', length:'48.0', ai_length:'51.249794989051', image_name:'660d6c89916d8.jpg', date:'2024-04-03 14:49:39'}
            ],
            Hmax: 0,
            H: 0,
            total_caught: 0,
            total_length: 0,
            bonus:125,
            overlay:false,
        }
    },
    methods: {
        // changePage(page){
        //     this.$store.page = page
        //     // console.log(this.$store.page)
        //     this.$emit('change-page')
        //     // vm.$forceUpdate()
        // },
        showFull(row){
            // console.log(row)
            let species = row.id
            // console.log(species)
            // console.log(row)
            this.$store.dispatch('leaderboard/getFullSpeciesStatistics', { species }).then(() => {
                // console.log(this.full_species_stats)
                this.overlay = true
            })
            this.overlay = true

        },
    },
    mounted(){
        //**TODO Later: will set region to first subscribed region
        this.$store.dispatch('leaderboard/setSelectedRegion', '16')
        this.$store.dispatch('leaderboard/getPersonalLeaderboard')
        .then(() => {
            // console.log(this.personal)
            // console.log(this.sport_fish)
            var row = {}
            if (this.personal) {
                this.personal.forEach(item => {
                    this.total_caught += +item.caught
                    this.total_length += +item.total_length
                })

                // console.log(this.total_caught)
                this.sport_fish.forEach(item => {
                    // console.log(this.personal.find(e => e.common_name === item.common_name))
                    if(this.personal.find(e => e.common_name === item.common_name)){
                        var temp = this.personal.find(e => e.common_name === item.common_name)
                        var temp_length
                        var temp_total
                        if(!temp.total_length === undefined){
                            temp_total = '0'
                        }else{
                            temp_total = temp.total_length
                        }
                        if(!temp.longest === undefined){
                            temp_length = '0'
                        }else{
                            temp_length = temp.longest
                        }
                        var pi = temp.caught / this.total_caught
                        var lnpi = Math.log(pi)
                        var liXlnpi = pi * lnpi
                        row = {
                            name: item.common_name,
                            id: item.id,
                            total_length: temp_total,
                            longest: temp_length,
                            caught:temp.caught,
                            pi: pi,
                            lnpi: lnpi,
                            value: liXlnpi
                        }
                        // console.log(temp)
                    }else{
                        row = {
                            name: item.common_name,
                            id: item.id,
                            total_length: '0',
                            longest: '0',
                            caught: '0',
                            pi: '0',
                            lnpi: '0',
                            value: '0'
                        }
                    }
                    this.fixed_list.push(row)
                });
            }
        })
        .finally(() => {
            // console.log(this.fixed_list)
            this.Hmax = Math.log(this.fixed_list.length)
            // console.log(this.Hmax)

            this.fixed_list.forEach(item => {
                this.H += -(+item.value)
            })

            // console.log(this.H)
        })
        // setTimeout(() => {
        
        // }, 50) 
    }
}
</script>

<style scoped>
/* >>>div.row.pa-0.justify-center:nth-child(odd){
    background-color: #90CAF9;
    padding-top:   -5px;
} */

/* >>>div.row.my-n5:nth-child(odd){
    background-color: #90CAF9;
} */
</style>